import IconChevronRight from 'app/common/icons/IconChevronRight'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import { FormTypes } from 'lib/components/Form/Form'
import Popover from 'lib/components/Popover'
import {useNavigate} from 'react-router-dom'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Category Name',
    id: 'categoryName',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Category ID',
    id: 'categoryCode',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Partner Code',
    id: 'partnerCode',
    textAlign: 'center',
    width: '20%',
  },
  {
    title: 'Description',
    id: 'description',
    textAlign: 'left',
    width: '40%',
  },
  // {
  //   title: 'ON/OFF',
  //   id: 'enabled',
  //   textAlign: 'left',
  //   width: '15%',
  // },
  {
    title: '',
    id: 'actions',
    textAlign: 'center',
    width: '5%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  categoryResponse: ResponseTypes.PagedResponse<RewardCategoryTypes.RewardCategoryResponseBL[]>
  partnerOptions: FormTypes.Options[]
}

const SearchResults = ({isFetching, categoryResponse, partnerOptions}: SearchResultProps) => {
  const navigate = useNavigate()
  // const {roles} = useAuth()
  // console.log(roles)
  const showCategoryDetail = true // roles.includes(POINT_DETAIL) || roles.includes(POINT_ACTION_DETAIL)
  const showEditCategory =  true // roles.includes(POINT_EDIT)

  dataTableColumns = dataTableColumns.map((item) => {
    if (item.id === 'enabled' && !showEditCategory) {
      return {...item, title: ''}
    }
    return item
  })

  const handleView = (rowIndex: number) => {
    const categoryCode = categoryResponse.data[rowIndex].categoryCode
    const encodedCategoryCode = btoa(categoryCode)
    navigate(`/program/category/${encodedCategoryCode}`)
  }

  const getPartnerNameFromPartnerCode = (code: string): string => {
    return partnerOptions.find(item => item.value === code)?.label as string || ''
  }

  const renderActionList = (rowIndex: number) => {
    return (
      <>
        <div>
          <Button type="text" onClick={() => handleView(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
            Details
          </Button>
        </div>
      </>
    )
  }

  const renderRowActions = (rowIndex: number) => {
    if (showCategoryDetail) {
      return <Popover btnContent={<IconThreeDots />} content={renderActionList(rowIndex)} />
    }
    return null
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (categoryResponse && categoryResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = categoryResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          categoryName: item.categoryName,
          categoryCode: item.categoryCode,
          partnerCode: getPartnerNameFromPartnerCode(item.partnerCode),
          description: item.categoryDescription,
          //enabled: renderEnabledToggle(index),
          actions: renderRowActions(index),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
      {/* {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {renderModalContent()}
        </Modal>
      )} */}
    </>
  )
}

export default SearchResults
