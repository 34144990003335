import {TierSystemDetailSection, TierSystemInfoWrapper, ZeroHeightButton} from '../Tier.Styled'
import Typography from 'lib/components/Typography'
import dayjs from 'dayjs'
import {Col, Row} from 'antd'
import {useNavigate} from 'react-router-dom'
import {ReviewDetailsItem, StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import {CenteredAlignedLabel, StatusCircleStyled} from 'app/common/components/Styled/common.styled'
import Appconfig from 'app/common/helpers/AppConfig'
import {getColor, getTierStaus} from '../utils'
import {utcDate, utcWithTimeZone} from 'utils/convertDateToString'
import {TIER_DETAIL, TIER_ACTION_DETAIL} from 'app/common/helpers/UserFunctions'
import {useAuth} from 'app/pages/Auth/AuthContext'

interface TierSystemDetailsProps {
  tierSystemData: TierTypes.TierSystemInfo
}

export type AddNewTierParam = {
  tierId: string | 'new' | 'view'
}

const TierSystemDetails: React.FC<TierSystemDetailsProps> = ({tierSystemData}): JSX.Element => {
  const navigate = useNavigate()
  const state: TierTypes.TierSystemFormStateType = {
    tierSystemName: `${tierSystemData.tierSystemName} - ${tierSystemData.tierSystemKey}` || '',
    startDateTime: tierSystemData.startDate ? utcDate(utcWithTimeZone(tierSystemData.startDate), Appconfig.DATE_TIME_FORMAT) : null,
    endDateTime: tierSystemData.endDate ? utcDate(utcWithTimeZone(tierSystemData.endDate), Appconfig.DATE_TIME_FORMAT) : null,
    assesmentTime: tierSystemData.assesmentTime || '',
    assesmentType: tierSystemData.assesmentType || 1,
    partnerCode: tierSystemData.partnerCode || '',
  }
  const {roles} = useAuth()
  const showDetail = roles.includes(TIER_DETAIL)
  const showActionDetail = roles.includes(TIER_ACTION_DETAIL)

  const handleView = () => {
    const encodedTierSystemKey = btoa(tierSystemData.tierSystemKey || '')
    // const tierData = sampleTierData.find(item => item.tierSystemKey === tierSystemData.tierSystemKey) || sampleTierData[0];
    navigate(`/program/tiers/view/${encodedTierSystemKey}`)
  }

  return (
    <TierSystemInfoWrapper>
      <TierSystemDetailSection>
        <Typography.Title level={2}>{`${tierSystemData.tierSystemName} - ${tierSystemData.tierSystemKey}`}</Typography.Title>
        <Row gutter={32} style={{marginTop: '16px'}}>
          <Col span={4}>
            <ReviewDetailsItem>
              <StyledReviewLabel>Partner</StyledReviewLabel>
              <StyledReviewValue>{state.partnerCode}</StyledReviewValue>
            </ReviewDetailsItem>
          </Col>
          <Col span={4}>
            <ReviewDetailsItem>
              <StyledReviewLabel>Start Date</StyledReviewLabel>
              <StyledReviewValue>{dayjs(state.startDateTime).format(Appconfig.DATE_FORMAT_SLASH)}</StyledReviewValue>
            </ReviewDetailsItem>
          </Col>
          <Col span={6}>
            <ReviewDetailsItem>
              <StyledReviewLabel>End Date</StyledReviewLabel>
              <StyledReviewValue>
                {state.endDateTime ? dayjs(state.endDateTime).format(Appconfig.DATE_FORMAT_SLASH) : ' No End Date'}
              </StyledReviewValue>
            </ReviewDetailsItem>
          </Col>
          <Col span={4}>
            <ReviewDetailsItem>
              <StyledReviewLabel>Assessment Time</StyledReviewLabel>
              <StyledReviewValue>{state.assesmentTime}</StyledReviewValue>
            </ReviewDetailsItem>
          </Col>
          <Col span={6}>
            <ReviewDetailsItem>
              <StyledReviewLabel>Point Value After Assessment</StyledReviewLabel>
              <StyledReviewValue>{state.assesmentType === 1 ? 'Rollover Points' : 'Reset Points'}</StyledReviewValue>
            </ReviewDetailsItem>
          </Col>
        </Row>
      </TierSystemDetailSection>
      <CenteredAlignedLabel>
        <StatusCircleStyled color={getColor(tierSystemData.tierStatus)}></StatusCircleStyled>{' '}
        <Typography.Text>{getTierStaus(tierSystemData.tierStatus ?? '')}</Typography.Text>
      </CenteredAlignedLabel>
      {showDetail && showActionDetail && (
        <ZeroHeightButton type="link" onClick={handleView}>
          View
        </ZeroHeightButton>
      )}
    </TierSystemInfoWrapper>
  )
}

export default TierSystemDetails
