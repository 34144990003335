import styled from 'styled-components'
import Colors from 'styles/Colors'

export const StyledSpinner = styled.div`
  position: relative;
  background-color: ${Colors.PRIMARY};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: conic-gradient(${Colors.PRIMARY}, transparent);
  animation: rotate 1s linear infinite;
  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }
  &::before {
    width: 40px;
    height: 40px;
    top: 5px;
    left: 5px;
    background-color: #ffffff;
  }
  &::after {
    height: 10px;
    width: 10px;
    background-color: ${Colors.BLUE_LIGHT};
    top: 0;
    left: 10px;
  }
  @keyframes rotate {
    from {
      transform: rotateZ(0);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
`
