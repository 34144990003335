import { cloneDeep } from "lodash"

export let initialTagRequestObj: TagTypes.TagResponseBL = {
  voucherTagCode: "",
  voucherTagName: "",
  partnerCode: "",
  voucherTagImage:"",
  clientCode: "01"
}

export let getTagRequestObj: TagTypes.TagResponseBL = cloneDeep(initialTagRequestObj)

export const updateTagRequestObj = (data: TagTypes.TagResponseBL) => {
  getTagRequestObj = cloneDeep(data)
}