import React from 'react'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
//import { CenterAlignedFlex } from './Dashboard.styled'

const Dashboard: React.FC = () => {
  console.log(ServiceConfig.login)
  return (
    <h1></h1>
   // <CenterAlignedFlex>
      //<img src="https://corp.purchasingpower.com/store/_ui/addons/purchasingpowercorpaddon/responsive/common/images/purchasing-power-logo.jpg" alt="This is an img" width={'100%'} height={'60%'} />
  //  </CenterAlignedFlex>
  )
}

export default Dashboard
