import styled from 'styled-components'
import Colors from 'styles/Colors'

interface MergeAccountCardActionProps {
  color: 'green' | 'red'
}

export const MergeAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const MergeAccountCard = styled.div`
  background: ${Colors.BLUE_LIGHT};
  border-radius: 4px;
  padding: 8px 12px;
`
export const MergeAccountLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */
  text-align: center;
  letter-spacing: -0.002em;

  /* Grey 4 */
  color: #767676;
`

export const MergeAccountValue = styled(MergeAccountLabel)`
  color: #1d1d1d;
`

export const MergeAccountCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

export const MergeAccountCardAction = styled.button<MergeAccountCardActionProps>`
  color: ${(props) => (props.color === 'green' ? '#1C832C' : '#AF0909')};
  height: 28px;
  left: 1218px;
  top: 584px;
  background: #ffffff;
  border-radius: 27px;
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  border: 0;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #000000;
  }
`
export const MergeAccountCardActionLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.002em;
`
export const MergeAccountCardActionIcon = styled.span`
  margin-left: 8px;
  display: flex;
`

export const MergeIntoLabel = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #808080;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const MergeAccountBody = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.002em;
  color: #4b4b4b;
  padding-top: 12px;
  margin-bottom: 12px;
  text-align: center;
`

export const MergeAccountFooter = styled.div``
export const MergeAccountButtonContainer = styled.div`
  padding-top: 12px;
`
