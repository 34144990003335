import {Button} from 'antd'
import styled, {css} from 'styled-components'
import {BtnProps} from './Button'
import Colors from 'styles/Colors'

export const StyledButton = styled(Button)<BtnProps>`
  padding: 13.5px 20px;
  .lb-btn-loading-icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  ${(props) =>
    (props.$endIcon || props.$startIcon) &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lb-btn-end-icon {
        display: flex;
        margin-left: 10px;
      }
      .lb-btn-start-icon {
        display: flex;
        margin-right: 10px;
      }
    `}
  ${(props) =>
    props.type === 'default' &&
    css`
      border: 1px solid ${Colors.PRIMARY};
      
    `}

  ${(props) =>
    props.icon &&
    css`
      align-items: center;
      justify-content: center;
      display: inline-flex;
    `}

  ${(props) =>
    props.type === 'text' &&
    css`
      && {
        height: auto;
        padding: 0 5px;
        color: ${props.color || Colors.PRIMARY};
      }
    `}
  ${(props) =>
    props.type === 'link' &&
    css`
      span {
        text-decoration: underline;
        color: ${props.$isDark ? Colors.WHITE : Colors.PRIMARY};
      }
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}
    `}
`
