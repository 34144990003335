//import Typography from 'lib/components/Typography'
//import {StyledModalContent, MarginBottomWrapper, ModalTitle, Seperator, ExtraPaddedButton} from 'app/common/components/Styled/common.styled'
import IconChevronRight from 'app/common/icons/IconChevronRight'
//import IconSuccess from 'app/common/icons/IconSuccess'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
//import LoadingPanel from 'lib/components/LoadingPanel'
import Popover from 'lib/components/Popover'
//import ToggleButton from 'lib/components/ToggleButton'
//import {ToggleOptionType} from 'lib/components/ToggleButton/ToggleButton'
//import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
//import {Modal} from 'antd'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {ACTIVITY_LOG_ACTION_DETAIL, ACTIVITY_LOG_DETAIL} from 'app/common/helpers/UserFunctions'
import {ActivityLogTypes} from 'types/ActivityLogTypes'
import dayjs from 'dayjs'
import Appconfig from 'app/common/helpers/AppConfig'
// import {POINT_DETAIL, POINT_ACTION_DETAIL, POINT_ACTIVATE} from 'app/common/helpers/UserFunctions'
//import PointService from 'app/services/PointService'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Date',
    id: 'fromDate',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Modified By',
    id: 'modifiedBy',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Affected Module',
    id: 'affectedModule',
    textAlign: 'center',
    width: '15%',
  },
  {
    title: 'Unique ID',
    id: 'uniqueId',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Details',
    id: 'details',
    textAlign: 'left',
    width: '30%',
  },
  {
    title: '',
    id: 'actions',
    textAlign: 'center',
    width: '5%',
  },
]

// const activateOptions: ToggleOptionType[] = [
//   {
//     label: 'On',
//     value: true,
//   },
//   {
//     label: 'Off',
//     value: false,
//   },
// ]

interface SearchResultProps {
  isFetching: boolean
  activityLogResponse: ResponseTypes.PagedResponse<ActivityLogTypes.ActivityLogResponseBL[]>
}

const SearchResults = ({isFetching, activityLogResponse}: SearchResultProps) => {
  const navigate = useNavigate()
  // const [open, setOpen] = useState<boolean>(false)
  // const [selPoint, setSelPoint] = useState<PointType.PointMasterResponseBL | null>(null)
  // const [processing, setProcessing] = useState<boolean>(false)
  // const [completed, setCompleted] = useState<boolean>(false)
  const {roles} = useAuth()
  console.log(roles)
  const showActivityDetail = roles.includes(ACTIVITY_LOG_ACTION_DETAIL) || roles.includes(ACTIVITY_LOG_DETAIL)

  dataTableColumns = dataTableColumns.map((item) => {
    if (item.id === 'enabled') {
      return {...item, title: ''}
    }
    return item
  })

  const handleView = (rowIndex: number) => {
    const uniqueId = activityLogResponse.data[rowIndex].id.toString()
    const encodedPointCode = btoa(uniqueId)
    navigate(`/program/activitylog/${encodedPointCode}`)
  }

  const renderActionList = (rowIndex: number, item: ActivityLogTypes.ActivityLogResponseBL) => {
    return (
      <>
        <div>
          <Button type="text" onClick={() => handleView(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
            View Details
          </Button>
        </div>
      </>
    )
  }

  const renderRowActions = (rowIndex: number, item: ActivityLogTypes.ActivityLogResponseBL) => {
    if (showActivityDetail) {
      return <Popover btnContent={<IconThreeDots />} content={renderActionList(rowIndex, item)} />
    }
    return null
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (activityLogResponse && activityLogResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = activityLogResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          fromDate: item.insertedDate ? dayjs(item.insertedDate).format(Appconfig.DATE_FORMAT_SLASH) : '-',
          modifiedBy: item.createdby,
          affectedModule: item.modulename,
          uniqueId: item.data.entityId,
          details: item.data.action,
          //enabled: renderEnabledToggle(index),
          actions: renderRowActions(index, item),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
      {/* {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {renderModalContent()}
        </Modal>
      )} */}
    </>
  )
}

export default SearchResults
