import {MainContainer} from 'app/common/components/Styled/common.styled'
import {Navigate, Outlet, useParams} from 'react-router-dom'
import {useAuth} from '../Auth/AuthContext'
import {AddNewSegmentParam} from './AddNewSegment'
import {SEGMENT_ACTION_DETAIL, SEGMENT_CREATE, SEGMENT_DETAIL, SEGMENT_EDIT, SEGMENT_VIEW_MEMBERS} from 'app/common/helpers/UserFunctions'

const Segmentation = (): JSX.Element => {
  const {roles} = useAuth()
  const {segmentId} = useParams<AddNewSegmentParam>()
  const actualSegmentId = atob(segmentId || '')
  const pathname = window.location.pathname

  if (pathname.includes('rewards/view/') && !roles.includes(SEGMENT_DETAIL) && !roles.includes(SEGMENT_ACTION_DETAIL)) {
    return <Navigate replace to="/unauthorized" />
  } else if (actualSegmentId === 'new' && !roles.includes(SEGMENT_CREATE)) {
    return <Navigate replace to="/unauthorized" />
  } else if (
    actualSegmentId &&
    actualSegmentId !== 'new' &&
    !pathname.includes('/members') &&
    !roles.includes(SEGMENT_EDIT) &&
    !roles.includes(SEGMENT_DETAIL) &&
    !roles.includes(SEGMENT_ACTION_DETAIL)
  ) {
    return <Navigate replace to="/unauthorized" />
  } else if (actualSegmentId && actualSegmentId !== 'new' && pathname.includes('/members') && !roles.includes(SEGMENT_VIEW_MEMBERS)) {
    return <Navigate replace to="/unauthorized" />
  }
  return (
    <MainContainer>
      <Outlet />
    </MainContainer>
  )
}

export default Segmentation
