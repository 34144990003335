import {ReviewDetailsItem, StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import {getUsersRequestObj, updateUserRequestObj, userStatus} from '../../utils'
import IconUser from 'app/common/icons/IconUser'
import {QuickActionsTile, UserDetailsWrapper, UserInfoContainer, UserNameWrapper} from 'app/pages/UserManagement/UserManagementStyled'
import Typography from 'lib/components/Typography'
import Button from 'lib/components/Button'
// import IconEmail from 'app/common/icons/IconEmail'
import IconUserDeactivate from 'app/common/icons/IconUserDeactivate'
import UserManagementService from 'app/services/UserManagementService'
import {toast} from 'react-hot-toast'
import {useState} from 'react'
import {normalizePhoneNo} from 'utils/common'
import IconEmail from 'app/common/icons/IconEmail'

interface ReviewUserProps {
  isViewOnly: boolean
  showEditUser: boolean
  showActivateUser: boolean
  showSendEmail: boolean
}

const ReviewUser: React.FC<ReviewUserProps> = ({isViewOnly, showEditUser, showActivateUser, showSendEmail}) => {
  const userData = getUsersRequestObj
  const {firstName, middleName, lastName, userName, email, phoneNumber, status, roles, manager} = userData
  const [loader, setLoader] = useState(false)
  const [resetLoader, setResetLoader] = useState(false)
  //const [userResetInfo, setUserResetInfo] =useState()
  const initialResetParms: UserManagementTypes.ForgotToken = {
    userName: email,
    flow: 2,
    referralUrl: window.location.host,
  }
  const isDeactivated = status.toUpperCase() === 'Deactive'.toUpperCase()
  const handleDeactivate = () => {
    if (!isDeactivated) {
      userData.status = userStatus.DEACTIVE
    } else {
      userData.status = userStatus.ACTIVE
    }
    setLoader(true)
    UserManagementService.saveUser(userData, {userName: userName}).then((res) => {
      updateUserRequestObj(res.data.data)
      toast.success(`User ${!isDeactivated ? 'Deactivated' : 'Activated'} Successfully.`)
      setLoader(false)
    })
  }

  const resetPasswordEmail = () => {
    setResetLoader(true)
    UserManagementService.forgetToken(initialResetParms)
      .then((res) => {
        if (res.data.data) {
          toast.success('Password reset email has been sent to the user’s primary email address.')
        }
      })

      .finally(() => {
        setResetLoader(false)
      })
  }

  return (
    <>
      <UserNameWrapper>
        <span className="review-user-icon">
          <IconUser />
        </span>
        <StyledReviewLabel>{`${firstName} ${middleName || ''} ${lastName}`}</StyledReviewLabel>
      </UserNameWrapper>
      <UserDetailsWrapper>
        <UserInfoContainer>
          {/* <ReviewDetailsItem>
            <StyledReviewLabel>User Status</StyledReviewLabel>
            <StyledReviewValue>{userData?.userStatus}</StyledReviewValue>
          </ReviewDetailsItem> */}
          <ReviewDetailsItem>
            <StyledReviewLabel>Email</StyledReviewLabel>
            <StyledReviewValue>{email}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Phone</StyledReviewLabel>
            <StyledReviewValue>{normalizePhoneNo(phoneNumber)}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>User Status</StyledReviewLabel>
            <StyledReviewValue>{status}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>User ID</StyledReviewLabel>
            <StyledReviewValue>{userName}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Roles</StyledReviewLabel>
            {roles.map((item) => (
              <StyledReviewValue>{item.roleName}</StyledReviewValue>
            ))}
          </ReviewDetailsItem>
          {manager.length > 0 && (
            <ReviewDetailsItem>
              <StyledReviewLabel>Approver/Manager</StyledReviewLabel>
              <StyledReviewValue>
                {manager.length ? `${manager[0].firstName} ${manager[0].middleName ?? ''} ${manager[0].lastName}` : ''}
              </StyledReviewValue>
            </ReviewDetailsItem>
          )}
        </UserInfoContainer>
        {!isViewOnly && (
          <UserInfoContainer>
            {(showActivateUser || showSendEmail) && <Typography.Title level={2}>Quick Actions</Typography.Title>}
            {showActivateUser && (
              <QuickActionsTile>
                <StyledReviewValue>{!isDeactivated ? 'Deactivate' : 'Activate'} User</StyledReviewValue>
                {(showEditUser || showActivateUser) && (
                  <Button type="primary" $startIcon={<IconUserDeactivate />} onClick={handleDeactivate} loading={loader} disabled={loader}>
                    {!isDeactivated ? 'Deactivate' : 'Activate'}
                  </Button>
                )}
              </QuickActionsTile>
            )}
            {showSendEmail && (
              <QuickActionsTile>
                <StyledReviewValue>Send password Reset Email</StyledReviewValue>
                <Button
                  type="primary"
                  onClick={resetPasswordEmail}
                  loading={resetLoader}
                  disabled={resetLoader || isDeactivated}
                  $startIcon={<IconEmail />}
                >
                  Send Email
                </Button>
              </QuickActionsTile>
            )}
          </UserInfoContainer>
        )}
      </UserDetailsWrapper>
    </>
  )
}

export default ReviewUser
