import {Popover as AntPopover, PopoverProps} from 'antd'
import Button from '../Button'
import {useEffect, useState} from 'react'

interface customPopoverProps extends PopoverProps {
  btnContent: JSX.Element
  content: JSX.Element
  onOpenChange?(visible: boolean): void
  rowIndex?: number
  selectedRowIndex?: number
}

const Popover = ({btnContent, content, onOpenChange, rowIndex, selectedRowIndex}: customPopoverProps) => {
  const [open, setOpen] = useState(false)
  const handleVisibleChange = () => {
    onOpenChange && onOpenChange(!open)
    setOpen(!open)
  }

  useEffect(() => {
    if (selectedRowIndex !== undefined && rowIndex !== undefined) {
      setOpen(selectedRowIndex === rowIndex)
    }
  }, [selectedRowIndex])

  return (
    <AntPopover content={content} trigger="click" open={open} onOpenChange={handleVisibleChange} placement="right">
      <Button type="text">{btnContent}</Button>
    </AntPopover>
  )
}

export default Popover
