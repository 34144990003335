import { useRoutes} from 'react-router-dom'
import routes from './routes'
import antTheme from 'styles/antCustomTheme'
import { ConfigProvider } from 'antd'
import { ThemeProvider } from 'styled-components'
import { theme } from 'styles/theme'

export default function App() {
  const content = useRoutes(routes)
  return (
    <ConfigProvider theme={{hashed: false, ...antTheme}} prefixCls="lb">
      <ThemeProvider theme={theme}>
        {content}
      </ThemeProvider>
    </ConfigProvider>
  )
}
