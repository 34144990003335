import {FormTypes} from '../Form'
import {StyledAntSwitch, SwitchWrapper} from './Switch.Styled'
import FormLabel from '../FormLabel'

const Switch: React.FC<FormTypes.Switch> = (props) => {
  const handleChange = (checked: boolean) => {
    if (props.id) {
      props.onChange(checked, props.id)
    }
  }

  return (
    <SwitchWrapper>
      {props.label && props.id && <FormLabel id={props.id} label={props.label} />}
      <StyledAntSwitch {...props} onChange={handleChange} />
    </SwitchWrapper>
  )
}

export default Switch
