import dayjs, {Dayjs} from 'dayjs'

export const handleDisableFromDate = (current: Dayjs, fromDate?: string | null, toDate?: string | null) => {
  const endDateTime = toDate ? dayjs(toDate) : dayjs()
  const startDateTime = fromDate ? dayjs(fromDate) : dayjs()

  if (toDate) {
    return current < startDateTime.startOf('day') || current > endDateTime.endOf('day')
  }
  return current < dayjs().startOf('day')
}

export const handleDisableToDate = (current: Dayjs, fromDate?: string | null, toDate?: string | null) => {
  const startDateTime = fromDate ? dayjs(fromDate) : dayjs()

  if (fromDate) {
    return current < dayjs(startDateTime).startOf('day')
  }
  return current < dayjs().startOf('day')
}
