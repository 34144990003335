import { MainContainer } from 'app/common/components/Styled/common.styled'
import {Navigate, Outlet, useParams} from 'react-router-dom'
import { useAuth } from '../Auth/AuthContext'
import { AddNewProductParam } from './ProductMaster/AddNewProduct'
import { PRODUCT_ACTION_DETAIL, PRODUCT_CREATE, PRODUCT_DETAIL, PRODUCT_EDIT } from 'app/common/helpers/UserFunctions'

const ProgramConfiguration: React.FC = () => {
  const {roles} = useAuth()
  const {productId} = useParams<AddNewProductParam>()
  const actualProductId = atob(productId || '')
  if (window.location.pathname.includes('products/view/') && !roles.includes(PRODUCT_ACTION_DETAIL) && !roles.includes(PRODUCT_DETAIL)) {
    return <Navigate replace to="/unauthorized" />
  } else if (actualProductId === 'new' && !roles.includes(PRODUCT_CREATE)) {
    return <Navigate replace to="/unauthorized" />
  } else if (actualProductId && actualProductId !== 'new' && !roles.includes(PRODUCT_EDIT) && !roles.includes(PRODUCT_ACTION_DETAIL) && !roles.includes(PRODUCT_DETAIL)) {
    return <Navigate replace to="/unauthorized" />
  }

  return (
    <MainContainer>
      <Outlet />
    </MainContainer>
  )
}

export default ProgramConfiguration
