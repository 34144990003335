import {ReviewDetailsItem, StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import {getPointRequestObj} from './util'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import Typography from 'lib/components/Typography'

interface ReviewPointProps {
  isViewOnly?: boolean
  handleEdit: () => void
  showEditPoint: boolean
}

const ReviewPoint: React.FC<ReviewPointProps> = ({isViewOnly, handleEdit, showEditPoint}): JSX.Element => {
  const pointReqObj = getPointRequestObj
  const {pointName, pointCode, pointTypeCode, pointDescription, pointCurrencyValue, isRedemptionPointType, expiryTrigger} = pointReqObj
  // const PointTypeCode = pointTypeOptions.find((item) => (item.value = pointTypeCode))?.label || ''
  return (
    <>
      <MarginBottomWrapper>
        <Typography.Text color="primary" strong uppercase>
          Point Information
        </Typography.Text>
      </MarginBottomWrapper>
      {pointCode && (
        <ReviewDetailsItem>
          <StyledReviewLabel>Point ID</StyledReviewLabel>
          <StyledReviewValue>{pointCode}</StyledReviewValue>
        </ReviewDetailsItem>
      )}
      <ReviewDetailsItem>
        <StyledReviewLabel>Point Name</StyledReviewLabel>
        <StyledReviewValue>{pointName}</StyledReviewValue>
      </ReviewDetailsItem>
      <ReviewDetailsItem>
        <StyledReviewLabel>Point Description</StyledReviewLabel>
        <StyledReviewValue>{pointDescription === '' ? '-' : pointDescription}</StyledReviewValue>
      </ReviewDetailsItem>
      <ReviewDetailsItem>
        <StyledReviewLabel>Point Type</StyledReviewLabel>
        <StyledReviewValue>{pointTypeCode}</StyledReviewValue>
      </ReviewDetailsItem>
      <ReviewDetailsItem>
        <StyledReviewLabel>Redemption Settings</StyledReviewLabel>
        <StyledReviewValue>{isRedemptionPointType ? 'Redeemable' : 'Non-Redeemable'}</StyledReviewValue>
      </ReviewDetailsItem>
      <ReviewDetailsItem>
        <StyledReviewLabel>Expiry Settings</StyledReviewLabel>
        <StyledReviewValue>{expiryTrigger ? 'Expiry Details' : 'No-expiry'}</StyledReviewValue>
      </ReviewDetailsItem>
      <ReviewDetailsItem>
        <StyledReviewLabel>Cost Per Unit</StyledReviewLabel>
        <StyledReviewValue>{`${pointCurrencyValue[0].costPerPoint} Points/${pointCurrencyValue[0].currencyCode}`} </StyledReviewValue>
      </ReviewDetailsItem>
    </>
  )
}

export default ReviewPoint
