import {DefaultTheme} from 'styled-components'

const theme: DefaultTheme = {
  borderRadius: '4px',
  shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  typography: {
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '40px',
      letterSpacing: '-0.002em',
    },
    h2: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '-0.002em',
    },
    h3: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '28px',
      letterSpacing: '-0.002em',
    },
    h4: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.002em',
    },
    h5: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '-0.002em',
    },
    h6: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '-0.002em',
    },
    subtitle: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '-0.002em',
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '-0.002em',
    },
    body2: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '-0.002em',
    },
    label: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 1.538461538461538,
      letterSpacing: '-0.002em',
      textTransform: 'uppercase',
    },
    button: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '-0.002em',
    },
    link: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
  layout: {
    maxWidth: '1200px',
    sh: '20px',
  },
}

export {theme}
