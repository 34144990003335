import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Modal} from 'antd'
import {MarginBottomWrapper, ModalTitle, ReviewHeader, Seperator, StyledModalContent} from 'app/common/components/Styled/common.styled'
import PartnerService from 'app/services/PartnerService'
import {FormTypes} from 'lib/components/Form/Form'
import Typography from 'lib/components/Typography'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import {getTagRequestObj, initialTagRequestObj, updateTagRequestObj} from './utils'
import LoadingPanel from 'lib/components/LoadingPanel'
import ReviewTag from './ReviewTag'
import IconSuccess from 'app/common/icons/IconSuccess'
// import {useAuth} from 'app/pages/Auth/AuthContext'
// import {SEGMENT_CREATE, SEGMENT_EDIT} from 'app/common/helpers/UserFunctions'
import TagService from 'app/services/TagService'
import {TagFormBodyContainer, NewTagFooter} from '../Tag.Styled'
import TagHeader from './TagHeader'
import NewTagForm, {TagRefType} from './NewTagForm'

export type AddNewTagParam = {
  tagId: string | 'new' | 'view'
}

interface AddNewTagProps {
  isViewOnly?: boolean
}

const AddNewTag: React.FC<AddNewTagProps> = ({isViewOnly}) => {
  const {tagId} = useParams<AddNewTagParam>()
  const actualTagId = atob(tagId || '')
  const tagFormRef = useRef<TagRefType>(null)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(tagId === 'new' ? 0 : 1)
  const [loading, setLoading] = useState(false)
  const [loadingTag, setLoadingTag] = useState(false)
  const [individualTag, setIndividualTag] = useState<TagTypes.TagResponseBL>()
  // const [disableSave, setDisableSave] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const allowBack = activeStep === 1
  // const {roles} = useAuth()
  const [partnerCodeOptions, setPartnerCodeOptions] = useState<FormTypes.Options[]>([])
  const [partnerCode, setPartnerCode] = useState('')
  const showEditTag = true // roles.includes(SEGMENT_EDIT)
  const showCreateTag = true // roles.includes(SEGMENT_CREATE)
  const isEdit = tagId !== 'new'

  useEffect(() => {
    return () => {
      updateTagRequestObj(initialTagRequestObj)
    }
  }, [])

  useEffect(() => {
    if (tagId !== 'new') {
      setLoadingTag(true)
      TagService.getTags({voucherTagCode: actualTagId})
        .then((res) => {
          const data = res.data.data[0]
          setIndividualTag(data)
          updateTagRequestObj(data)
        })
        .finally(() => {
          setLoadingTag(false)
        })
    }
  }, [actualTagId])

  const showPreview = () => {
    if (tagFormRef.current?.validate()) {
      setActiveStep(1)
    }
  }

  const updateTagReqPartnerData = (data: PartnerTypes.PartnerBL[]) => {
    const partnerCodeFromRequest = getTagRequestObj.partnerCode
    const defaultCode = data.find((partnerOption) => partnerOption.isDefaultPartner)
    if (partnerCodeFromRequest) {
      const hasPartnerCode = data.find((partnerOption) => partnerOption.partnerCode === partnerCodeFromRequest)
      if (!hasPartnerCode) {
        getTagRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
      }
    } else {
      getTagRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
    }
    setPartnerCode(getTagRequestObj.partnerCode)
  }

  const getPartnerCodes = () => {
    PartnerService.getPartners().then((res) => {
      const data = res.data.data
      const partnerOptions = data.map((item) => ({value: item.partnerCode, label: item.partnerName}))
      setPartnerCodeOptions(partnerOptions)
      updateTagReqPartnerData(data)
    })
  }

  useEffect(() => {
    getPartnerCodes()
  }, [])

  const submitForApproval = () => {
    setLoading(true)
    setProcessing(true)
    setOpen(true)
    let saveTagPayload: TagTypes.SaveTagPayload = {
      voucherTagName: getTagRequestObj.voucherTagName,
      partnerCode: getTagRequestObj.partnerCode,
      voucherTagImage: getTagRequestObj.voucherTagImage,
    }
    if (tagId !== 'new') {
      saveTagPayload = {
        ...saveTagPayload,
        voucherTagCode: getTagRequestObj.voucherTagCode,
      }
    }
    TagService.saveTags(saveTagPayload, {
      voucherTagCode: tagId !== 'new' ? getTagRequestObj.voucherTagCode : undefined,
      partnerCode: tagId !== 'new' ? getTagRequestObj.partnerCode : undefined,
    })
      .then((res) => {
        const {data} = res.data
        setIndividualTag(data)
        updateTagRequestObj(data)
        setOpen(true)
        setIsSaved(true)
        // navigate(`/segments`)
      })
      .catch(() => {
        setOpen(false)
      })
      .finally(() => {
        setLoading(false)
        setProcessing(false)
      })
  }

  const handleEditTag = () => {
    allowBack && setActiveStep(0)
  }

  const handleCancelConfirm = () => {
    updateTagRequestObj(initialTagRequestObj)
    navigate(`/program/tag`)
  }

  const handleOk = () => {
    setOpen(false)
    handleCancelConfirm()
  }

  const handleCancelNewTag = () => {
    if (isViewOnly === true) {
      handleCancelConfirm()
    } else {
      setOpen(true)
    }
  }
  const handleCancelModal = () => {
    setOpen(false)
  }

  const renderTagBody = () => {
    if (loadingTag) {
      return <LoadingPanel />
    }
    if (tagId !== 'new' && !individualTag) {
      return <div>No Tag found with {actualTagId}</div>
    }
    if (activeStep === 0) {
      return <NewTagForm ref={tagFormRef} partnerCodeOptions={partnerCodeOptions} />
    }
    return <ReviewTag isViewOnly={isViewOnly} showEditTag={tagId !== 'new'} onEdit={handleEditTag} partnerCodeOptions={partnerCodeOptions} />
  }

  const renderSaveButton = () => {
    if (tagId === 'new') {
      return (
        <Button
          loading={loading}
          onClick={activeStep === 0 ? showPreview : submitForApproval}
          type="primary"
          disabled={partnerCode === ''}
          // disabled={disableSave}
          $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
        >
          {activeStep === 1 ? 'Save and Create Tag' : 'Preview'}
        </Button>
      )
    } else if (tagId !== 'new' && activeStep === 0) {
      return (
        <Button
          loading={loading}
          onClick={submitForApproval}
          type="primary"
          disabled={partnerCode === ''}
          // disabled={disableSave}
          $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
        >
          Save Changes
        </Button>
      )
    }
    return <></>
  }
  const showEdit = () => {
    setActiveStep(0)
  }
  const renderEditButton = () => {
    return (
      activeStep === 1 &&
      tagId === 'new' && (
        <Button
          loading={loading}
          onClick={showEdit}
          type="primary"
          // disabled={disableSave}
        >
          Back
        </Button>
      )
    )
  }

  const renderTagFooter = () => {
    return (
      !isViewOnly && (
        <>
          <NewTagFooter>
            {showEditTag && renderEditButton()}
            {(showEditTag || showCreateTag) && renderSaveButton()}
          </NewTagFooter>
        </>
      )
    )
  }

  const handleCompleted = () => {
    setOpen(false)
    setIsSaved(false)
    navigate(`/program/tag`)
  }

  const handleCloseModal = () => {
    if (isSaved) {
      navigate(`/program/tag`)
    } else {
      handleCancelModal()
    }
  }

  const renderModalBody = () => {
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (isSaved && isEdit) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Tag Edited
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                The tag named <b>{individualTag?.voucherTagName}</b> has been edited.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    if (isSaved) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              New Tag Created
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                The new tag named <b>{individualTag?.voucherTagName}</b> has been created.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">Are you sure you want to cancel adding {getTagRequestObj.voucherTagName}?</Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Continue with cancel
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs backLinkLabel="Tag Master" backLinkPath={`/program/tag`} activePageLabel={isViewOnly ? 'Tag Details' : 'Add New Tag'} />
        <Button type="link" onClick={handleCancelNewTag}>
          {tagId === 'new' ? 'Cancel New Tag' : 'Cancel'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <TagHeader activeStep={activeStep} />
      <div>
        {activeStep === 1 && (
          <ReviewHeader>
            <Typography.Title level={2}>{isEdit ? individualTag?.voucherTagName : 'Preview'}</Typography.Title>
            {/* {allowBack && showEditTag && isEdit && individualTag ? (
              <ExtraPaddedButton type="primary" onClick={handleEditTag}>
                Edit
              </ExtraPaddedButton>
            ) : (
              <></>
            )} */}
          </ReviewHeader>
        )}
        <TagFormBodyContainer>{renderTagBody()}</TagFormBodyContainer>
      </div>
      {renderTagFooter()}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCloseModal} footer={null}>
          {renderModalBody()}
        </Modal>
      )}
    </div>
  )
}

export default AddNewTag
