import {forwardRef, useImperativeHandle, useState} from 'react'
import {Col, Row} from 'antd'
import Form from 'lib/components/Form'
import {valueType} from 'antd/es/statistic/utils'
import {cloneDeep} from 'lodash'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import {FormTypes} from 'lib/components/Form/Form'
import toast from 'react-hot-toast'
import {getTagRequestObj, updateTagRequestObj} from './utils'
import {useParams} from 'react-router-dom'
import {AddNewTagParam} from '.'
import ImagePicker from 'app/common/components/ImagePicker'
import RewardService from 'app/services/RewardService'
import {ImageGallaryStateType} from 'app/pages/Rewards/AddNewRewrds/NewRewardForm'

export interface TagRefType {
  validate(): boolean
  getTagDetails(): InitialStateType
}

interface InitialStateType {
  voucherTagCode: string
  voucherTagName: string
  partnerCode: string
  voucherTagImage: string
}

interface errorStateType {
  voucherTagName: boolean
  partnerCode: boolean
}

interface NewTagFormProps {
  partnerCodeOptions: FormTypes.Options[]
}

const NewTagForm = forwardRef<TagRefType, NewTagFormProps>(({partnerCodeOptions}, ref) => {
  let updatedTagReqObj = getTagRequestObj
  const initialState: InitialStateType = {
    voucherTagCode: updatedTagReqObj.voucherTagCode || '',
    voucherTagName: updatedTagReqObj.voucherTagName || '',
    partnerCode: updatedTagReqObj.partnerCode || '',
    voucherTagImage: updatedTagReqObj.voucherTagImage || '',
  }
  const initialErrorState: errorStateType = {
    voucherTagName: false,
    partnerCode: false,
  }
  const {tagId} = useParams<AddNewTagParam>()
  const [state, setState] = useState(initialState)
  const [error, setError] = useState(initialErrorState)
  const [uploadigFor, setUploadingFor] = useState<keyof ImageGallaryStateType>()
  const handleValidate = (): boolean => {
    // if (!state.partnerCode) {
    //   setError({...error, partnerCode: true})
    //   toast.error('Partner Code is required.')
    //   return false
    // }
    if (!state.voucherTagName) {
      setError({...error, voucherTagName: true})
      toast.error('Tag Name is required.')
      return false
    }
    if (state.voucherTagName.length > 250) {
      setError({...error, voucherTagName: true})
      toast.error('Tag Name cannot be more than 250 characters.')
      return false
    }
    if (state.voucherTagName.trim().length == 0) {
      setError({...error, voucherTagName: true})
      toast.error('Tag Name cannot be null or empty.')
      return false
    }
    return true
  }

  const getTagDetails = (): InitialStateType => {
    return state
  }

  const uploadImage = (acceptedFile: File, id: keyof ImageGallaryStateType) => {
    setUploadingFor(id)
    // Create a FormData object
    const formData = new FormData()
    // Assuming you're only allowing a single file upload
    formData.append('file', acceptedFile)

    RewardService.saveVoucherImage(acceptedFile, {folderName: 'VoucherTag'})
      .then((res) => {
        const voucherImageUrl = res.data.data
        if (voucherImageUrl) {
          handleChange(voucherImageUrl, 'voucherTagImage')
        }
      })
      .finally(() => {
        setUploadingFor(undefined)
      })
  }

  const isTagPartnerDisabled = tagId !== 'new'
  useImperativeHandle(ref, () => ({validate: handleValidate, getTagDetails}))

  const handleChange = (value: valueType, id: string) => {
    const prevState = cloneDeep(state)
    setError(initialErrorState)
    setState({
      ...prevState,
      [id]: value,
    })
    updateTagRequestObj({
      ...updatedTagReqObj,
      [id]: value,
    })
  }
  const handeImageDelete = (key: string, index?: number) => {
    const prevState = cloneDeep(state)
    let filePath = updatedTagReqObj.voucherTagImage.toString().split('/')
    let lastItem = filePath[filePath.length - 1]
    setState({
      ...prevState,
      voucherTagImage: '',
    })
    RewardService.deleteImage({fileName: lastItem, folderName: 'VoucherTag'})
      .then((res) => {
        const voucherImageUrl = res.data.data
        if (!voucherImageUrl) {
        }
      })
      .finally(() => {
        setUploadingFor(undefined)
      })
    updateTagRequestObj({
      ...updatedTagReqObj,
      voucherTagImage: '',
    })
  }

  return (
    <>
      <MarginBottomWrapper>
        <Col span={10}>
          <Form.Input id="voucherTagCode" label="Tag ID" name="voucherTagCode" placeholder="Tag ID" value={state.voucherTagCode} disabled />
        </Col>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10}>
            <Form.Select
              id="partnerCode"
              label="Partner Code"
              value={state.partnerCode || getTagRequestObj.partnerCode}
              options={partnerCodeOptions}
              onChange={handleChange}
              disabled={true || isTagPartnerDisabled}
              status={error.partnerCode ? 'error' : undefined}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10}>
            <Form.Input
              id="voucherTagName"
              label="Tag Name"
              name="voucherTagName"
              placeholder="Tag Name"
              value={state.voucherTagName}
              onChange={handleChange}
              status={error.voucherTagName ? 'error' : undefined}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10}>
            <ImagePicker
              id="voucherTagImage"
              label="Tag Image"
              value={state.voucherTagImage}
              onDeleteImage={handeImageDelete}
              uploadImage={uploadImage}
              uploadingFor={uploadigFor}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
    </>
  )
})

export default NewTagForm
