import { Col } from 'antd'
import { MatchEndDateContainer, TierSystemInfoWrapper, ZeroHeightButton } from '../Tier.Styled'
import Form from 'lib/components/Form'
import { AssessmentValueOptions, PointsValueOptions, getPartnerNameFromCode, getTierRequestObj, matchEndDateOptions } from '../utils'
import { FormTypes } from 'lib/components/Form/Form'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import dayjs from 'dayjs'
import { FullWidthRow } from 'app/pages/Promotions/CreatePromotion/Steps/Trigger/Trigger.styled'
import { MarginBottomWrapper } from 'app/common/components/Styled/common.styled'
import toast from 'react-hot-toast'
import { handleDisableFromDate, handleDisableToDate } from 'utils/DatepickerUtils'
import { ReviewDetailsItem, StyledReviewLabel, StyledReviewValue } from 'app/pages/Rewards/Reward.Styled'
import { utcDate, utcWithTimeZone } from 'utils/convertDateToString'
import Appconfig from 'app/common/helpers/AppConfig'
import { useParams } from 'react-router-dom'
import { AddNewTierParam } from '.'
import { TIER_EDIT } from 'app/common/helpers/UserFunctions'
import { useAuth } from 'app/pages/Auth/AuthContext'

interface TierSystemDetailsFormProps {
  partnerCodeOptions: FormTypes.Options[]
  isViewOnly: boolean
  activeStep: number
  isLive?: boolean
  isPartnerDisabeld?: boolean
}

export interface TierSystemFormRefType {
  validate(): boolean
  getState(): TierTypes.TierSystemFormStateType
}

const TierSystemDetailsForm = forwardRef<TierSystemFormRefType, TierSystemDetailsFormProps>(
  ({ partnerCodeOptions, isViewOnly, isLive = false, isPartnerDisabeld = false }, ref) => {
    let tierReqObj = getTierRequestObj
    const { tierId } = useParams<AddNewTierParam>()
    console.log(tierId)
    const initialState: TierTypes.TierSystemFormStateType = {
      tierSystemName: tierReqObj.tierSystemName || '',
      partnerCode: tierReqObj.partnerCode || '',
      startDateTime: tierReqObj.startDate || '',
      endDateTime: tierReqObj.endDate || null,
      assesmentTime: tierReqObj.assesmentTime || '',
      assesmentType: tierReqObj.assesmentType || 1,
    }
    let isEndDateDisabeld = false
    let isCheckboxChecked = false
    isEndDateDisabeld = initialState.endDateTime ? false : true
    isCheckboxChecked = initialState.endDateTime ? false : true
    const [state, setState] = useState<TierTypes.TierSystemFormStateType>(initialState)
    const [isChecked, setIsChecked] = useState(isCheckboxChecked ? 'isChecked' : '')
    const [disabledEndDate, setEndDateDisable] = useState(isEndDateDisabeld)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const { roles } = useAuth()
    const showEdit = roles.includes(TIER_EDIT)
    useEffect(() => {
      const prevState = { ...state }
      setState({
        ...prevState,
        partnerCode: tierReqObj.partnerCode,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tierReqObj.partnerCode])

    const handleValidate = (): boolean => {
      if (!state.tierSystemName) {
        toast.error('Please enter Tier System Name.')
        return false
      }
      if (!state.startDateTime) {
        toast.error('Please enter a Start Date.')
        return false
      }
      if (!isChecked && !state.endDateTime) {
        toast.error('Please enter End Date.')
        return false
      }
      if (!state.assesmentTime) {
        toast.error('Please select Assessment Time.')
        return false
      }
      return true
    }

    const getTierSystemFormState = (): TierTypes.TierSystemFormStateType => {
      return state
    }

    useImperativeHandle(ref, () => ({ validate: handleValidate, getState: getTierSystemFormState }))

    const handleChange = (value: FormTypes.ValueType, key: string): void => {
      const prevState = { ...state }
   
      setState({
        ...prevState,
        [key]: value,
      })
    }

    const handleOnDateChange = (date: string | null, key: string) => {
      const prevState = { ...state }
      let dateTimeString = date ? dayjs(date).format(Appconfig.DATE_TIME_SERVER) : null
    if(key==='endDateTime'){
      dateTimeString = date ? dayjs(date).endOf("day").format(Appconfig.DATE_TIME_SERVER) : null
    }
      setState({
        ...prevState,
        [key]: dateTimeString,
      })
    }

    const handleMatchEndDateChange = (value: string[], id: string): void => {
      setEndDateDisable(!disabledEndDate)
      setState({ ...state, endDateTime: null })
      setIsChecked(value[0])
    }

    const handleEditTierSystem = (): void => {
      setIsEdit(!isEdit)
    }

    if (isViewOnly && !isEdit) {
      return (
        <TierSystemInfoWrapper>
          <FullWidthRow>
            <Col span={5}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Partner</StyledReviewLabel>
                <StyledReviewValue>{getPartnerNameFromCode(partnerCodeOptions, tierReqObj.partnerCode)}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={3}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Start Date</StyledReviewLabel>
                <StyledReviewValue>{utcDate(utcWithTimeZone(tierReqObj.startDate as string), Appconfig.DATE_FORMAT_MONTH_NAME)}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={3}>
              <ReviewDetailsItem>
                <StyledReviewLabel>End Date</StyledReviewLabel>
                <StyledReviewValue>
                  {tierReqObj.endDate ? utcDate(utcWithTimeZone(tierReqObj.endDate as string), Appconfig.DATE_FORMAT_MONTH_NAME) : 'No End Date'}
                </StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={4}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Assessment Time</StyledReviewLabel>
                <StyledReviewValue>{tierReqObj.assesmentTime}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={8}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Point Value After Assessment</StyledReviewLabel>
                <StyledReviewValue>{tierReqObj.assesmentType === 1 ? 'Rollover Points' : 'Reset Points'}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
          </FullWidthRow>
          {showEdit && (
            <ZeroHeightButton type="link" onClick={handleEditTierSystem}>
              {isEdit ? 'Cancel' : 'Edit'}
            </ZeroHeightButton>
          )}
        </TierSystemInfoWrapper>
      )
    }

    return (
      <TierSystemInfoWrapper>
        <div style={{ width: '100%' }}>
          <MarginBottomWrapper>
            <FullWidthRow gutter={32}>
              <Col span={12}>
                <Form.Input
                  id="tierSystemName"
                  label="Tier System Name"
                  placeholder="Enter Tier System Name"
                  value={state.tierSystemName}
                  onChange={handleChange}
                  disabled={isLive}
                />
              </Col>
              <Col span={12}>
                <Form.Select
                  id="partnerCode"
                  label="Select a Partner"
                  placeholder="Select"
                  value={state.partnerCode || tierReqObj.partnerCode}
                  onChange={handleChange}
                  options={partnerCodeOptions}
                  disabled={isLive || true}
                />
              </Col>
            </FullWidthRow>
          </MarginBottomWrapper>
          <FullWidthRow gutter={32}>
            <Col span={6}>
              <Form.DatePicker
                disabledDate={(current: dayjs.Dayjs) => handleDisableFromDate(current, state.startDateTime, state.endDateTime)}
                id="startDateTime"
                label="Start Date"
                placeholder="Start Date"
                value={state.startDateTime}
                onChange={handleOnDateChange}
                disabled={isLive}
              />
            </Col>
            <Col span={6}>
              <Form.DatePicker
                disabledDate={(current: dayjs.Dayjs) => handleDisableToDate(current, state.startDateTime, state.endDateTime)}
                id="endDateTime"
                label="End Date"
                placeholder="End Date"
                value={state.endDateTime}
                onChange={handleOnDateChange}
                disabled={disabledEndDate}
              />
            </Col>
            <Col span={5}>
              <Form.Select
                id="assesmentTime"
                label="assesment Time"
                placeholder="Select"
                value={state.assesmentTime}
                onChange={handleChange}
                options={AssessmentValueOptions}
                disabled={isLive}
              />
            </Col>
            <Col span={7}>
              <Form.Radio
                id="assesmentType"
                label="Point Value After assesment"
                value={state.assesmentType}
                options={PointsValueOptions}
                onChange={handleChange}
                disabled={isLive}
              />
            </Col>
          </FullWidthRow>
          <MatchEndDateContainer>
            <Form.Checkbox id="matchEndDate" options={matchEndDateOptions} value={[isChecked]} onChange={handleMatchEndDateChange} />
          </MatchEndDateContainer>
        </div>
        {isViewOnly && isEdit && (
          <ZeroHeightButton type="link" onClick={handleEditTierSystem}>
            Cancel
          </ZeroHeightButton>
        )}
      </TierSystemInfoWrapper>
    )
  }
)

export default TierSystemDetailsForm
