import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import { FormTypes } from 'lib/components/Form/Form'
import {FormEvent, forwardRef, useImperativeHandle, useState} from 'react'

export interface TagFormRefType {
  handleReset(): void
  getFormState(): TagTypes.TagMasterFilterState
}

interface TagFilterProps {
  handleFilter(formState: TagTypes.TagMasterFilterState): void
  isFetching: boolean
  partnerOptions: FormTypes.Options[]
}

type KeyTypes = keyof TagTypes.TagMasterFilterState

const TagFilter = forwardRef<TagFormRefType, TagFilterProps>(({handleFilter, isFetching, partnerOptions}, ref): JSX.Element => {
  const initialState: TagTypes.TagMasterFilterState = {
    voucherTagName: '',
    voucherTagCode: '',
    partnerCode: '',
  }
  const [state, setInitialState] = useState<TagTypes.TagMasterFilterState>(initialState)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleReset = () => {
    setInitialState(initialState)
  }

  const getFormState = (): TagTypes.TagMasterFilterState => {
    return state
  }

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = {...state}
    setInitialState({
      ...prevState,
      [key]: value,
    })
  }

  useImperativeHandle(ref, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.voucherTagName} name="voucherTagName" id="voucherTagName" placeholder="Tag Name" onChange={handleOnChange} isDark />
      <Form.Input value={state.voucherTagCode} name="voucherTagCode" id="voucherTagCode" placeholder="Tag ID" onChange={handleOnChange} isDark />
      <Form.Select
        value={state.partnerCode}
        options={partnerOptions}
        placeholder="Partner Code"
        id="partnerCode"
        onChange={handleOnChange}
        noMargin
        isDark
      />
    </DataTable.Filter>
  )
})

export default TagFilter
