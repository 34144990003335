import HttpRequest from "app/common/helpers/HttpRequest"
import {HttpRequestUpload} from 'app/common/helpers/HttpRequest/HttpRequestUpload'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import {AxiosResponse} from 'axios'

export const getImportDataList = (
  url: string,
  params: ImportExportTypes.ImportSearchParams
): Promise<AxiosResponse<ResponseTypes.PagedResponse<ImportExportTypes.ImportDataResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: url,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}
export const uploadImportFile = (url: string, params: ImportExportTypes.ImportFileParams, data: FormData) => {
  const config = {
    params,
  }

  return HttpRequestUpload({data, url, ...config})
}

export const downloadImportFile = (
  url: string,
  params: ImportExportTypes.ExportImportFileParams
): Promise<AxiosResponse<Blob>> => {
  const requestOptions: HttpRequestType = {
    url: url,
    params,
    withCredentials: false,
    responseType: 'blob',
  }
  console.log(requestOptions)
  return HttpRequest(requestOptions)
}
export const downloadSampleImportFile = (url: string, params: ImportExportTypes.SampleImportDownloadFileParams): Promise<AxiosResponse<Blob>> => {
  const requestOptions: HttpRequestType = {
    url: url,
    params,
    withCredentials: false,
    responseType: 'blob',
  }
  console.log(requestOptions)
  return HttpRequest(requestOptions)
}

const ImportExportSevice = {
  getImportDataList,
  uploadImportFile,
  downloadImportFile,
  downloadSampleImportFile,
}

export default ImportExportSevice