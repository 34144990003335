import KeyboardArrowRight from 'app/common/icons/KeyboardArrowRight'
import {Link} from 'react-router-dom'

import {BreadcrumbsWrapper, ActivePageLabel, BreadcrumbLinkRoot} from './Breadcrumbs.styled'

export interface BreadcrumbsProps {
  backLinkPath: string
  backLinkLabel: string
  activePageLabel: string
}
const Breadcrumbs: React.FC<BreadcrumbsProps> = ({backLinkPath, backLinkLabel, activePageLabel}) => {
  return (
    <BreadcrumbsWrapper>
      <Link to={backLinkPath}>
        <BreadcrumbLinkRoot>
          <span>{backLinkLabel}</span>
        </BreadcrumbLinkRoot>
      </Link>
      {activePageLabel && (
        <>
          <KeyboardArrowRight size={20} />
          <ActivePageLabel>{activePageLabel}</ActivePageLabel>
        </>
      )}
    </BreadcrumbsWrapper>
  )
}

export default Breadcrumbs
