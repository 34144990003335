import {FormEvent, forwardRef, memo} from 'react'
import {Button} from 'antd'
import IconSearch from 'app/common/icons/IconSearch'
import {Cell, CellWrapper} from '../DataTable.styled'
import {SearchForm, SearchWrapper} from './Search.styled'
import FormLabel from 'lib/components/Form/FormLabel'
import SearchInput from 'app/pages/Member/MemberSearch/SearchInput'

export type Ref = HTMLFormElement

const Search = forwardRef<Ref, DataTableTypes.SearchProps>(({columns, handleSearch, isFetching}, ref) => {
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const fieldValues = Object.fromEntries(formData.entries())
    const filledObj: {[k: string]: FormDataEntryValue} = {}
    Object.keys(fieldValues).map((key) => {
      if (fieldValues[key]) {
        filledObj[key] = fieldValues[key]
      }
      return null
    })
    handleSearch(filledObj || {})
  }

  const getSearchButton = () => {
    return (
      <CellWrapper key="search-btn">
        <Cell>
          <FormLabel id="submitButton" label="&nbsp;" />
          <Button className="search-btn" shape="circle" htmlType="submit" disabled={isFetching} loading={isFetching} icon={<IconSearch />} />
        </Cell>
      </CellWrapper>
    )
  }

  const renderColumns = () => {
    const allInputColumns = columns.map((col) => {
      return (
        <CellWrapper key={col.id} $width={col.width}>
          <Cell $textAlign={col.textAlign} key={col.id}>
            <SearchInput id={col.id} label={col.title} placeholder="Input Value" isDark />
          </Cell>
        </CellWrapper>
      )
    })
    const searchBtn = getSearchButton()
    allInputColumns.push(searchBtn)
    return allInputColumns
  }
  return (
    <SearchWrapper>
      <SearchForm ref={ref} onSubmit={handleSubmit}>
        {renderColumns()}
      </SearchForm>
    </SearchWrapper>
  )
})
export default memo(Search)
