import styled from 'styled-components'
import Colors from 'styles/Colors'
import {FormTypes} from './Form'

export const StyledLabel = styled.label<FormTypes.InputLabelProp>`
  font-size: ${(props) => props.theme.typography.label.fontSize};
  font-weight: ${(props) => props.theme.typography.label.fontWeight};
  line-height: ${(props) => props.theme.typography.label.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.label.letterSpacing};
  text-transform: ${(props) => props.theme.typography.label.textTransform};
  color: ${(props) => (props.$isDark ? Colors.WHITE : Colors.COLOR_TEXT)};
  margin: 0 0 4px;
  padding: 0;
  display: block;
`
