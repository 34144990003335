import styled from 'styled-components'

export const StyledSearchInput = styled.input`
  padding: 8px;
  border-color: transparent;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
  margin: 0;
  padding: 13.5px 21px;
  font-size: 14px;
  line-height: 1.7142857142857142;
  list-style: none;
  font-family: 'IBM Plex Sans', sans-serif;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 25px;
  transition: all 0.2s;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`
