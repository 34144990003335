import Colors from 'styles/Colors'

const IconArrowRightLong = ({size = 22, color = Colors.PRIMARY}: IconType.Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 19 12" fill="none">
      <path
        d="M17.8075 6.79246L13.4954 11.2717C13.3953 11.3758 13.2763 11.4583 13.1454 11.5147C13.0145 11.571 12.8742 11.6 12.7325 11.6C12.5909 11.6 12.4506 11.571 12.3197 11.5147C12.1888 11.4583 12.0698 11.3758 11.9696 11.2717C11.8694 11.1676 11.79 11.0441 11.7358 10.9081C11.6815 10.7721 11.6536 10.6264 11.6536 10.4792C11.6536 10.3321 11.6815 10.1863 11.7358 10.0503C11.79 9.91437 11.8694 9.79082 11.9696 9.68675L14.4401 7.11979L1.23427 7.11979C0.948359 7.11979 0.674161 7.00181 0.471993 6.7918C0.269826 6.58179 0.156249 6.29697 0.156249 5.99997C0.156249 5.70298 0.269826 5.41815 0.471994 5.20814C0.674161 4.99814 0.948359 4.88016 1.23427 4.88016L14.4414 4.88016L11.9696 2.3132C11.7673 2.10302 11.6536 1.81796 11.6536 1.52072C11.6536 1.22348 11.7673 0.938412 11.9696 0.728232C12.172 0.518052 12.4464 0.399978 12.7325 0.399978C13.0187 0.399978 13.2931 0.518052 13.4954 0.728232L17.8075 5.20749C17.9081 5.31131 17.988 5.43478 18.0425 5.57079C18.0969 5.7068 18.125 5.85266 18.125 5.99997C18.125 6.14729 18.0969 6.29315 18.0425 6.42916C17.988 6.56517 17.9081 6.68864 17.8075 6.79246Z"
        fill={color}
      />
    </svg>
  )
}

export default IconArrowRightLong
