export const MEMBER_SEARCH = 'member_search';
export const MEMBER_VIEW = 'member_view';
export const MEMBER_DETAIL = 'member_detail';
export const MEMBER_DETAIL_INFO = 'member_detail_info';
export const MEMBER_DETAIL_ADJUST = 'member_detail_adjust';
export const MEMBER_DETAIL_BALANCE = 'member_detail_balance';
export const MEMBER_DETAIL_TIER = 'member_detail_tier';
export const MEMBER_DETAIL_STATUS = 'member_detail_status';
export const MEMBER_DETAIL_EDIT = 'member_detail_edit';
export const MEMBER_DETAIL_TRANSACTION = 'member_detail_transaction';
export const MEMBER_DETAIL_AWARD = 'member_detail_award';
export const MEMBER_DETAIL_TRANSACTION_DETAIL='member_detail_transaction_Detail';
export const MEMBER_DETAIL_CHANGE_STATUS='member_detail_changestatus';
export const MEMBER_DETAIL_CHANGE_TIER='member_detail_changetier';
export const MEMBER_DETAIL_ISSUE_REWARD='member_detail_issuereward';
export const MEMBER_DETAIL_MERGE='member_detail_merge';
export const MEMBER_DETAIL_OPTIN='member_detail_optin';
export const REWARD_SEARCH = 'reward_search';
export const REWARD_DETAIL = 'reward_detail';
export const REWARD_VIEW = 'reward_view';
export const REWARD_APPROVAL = 'reward_approval';
export const REWARD_PREVIEW = 'reward_preview';
export const REWARD_CREATE = 'reward_create';
export const REWARD_ACTIVATE = 'reward_activate';
export const REWARD_EDIT = 'reward_edit';
export const PRODUCT_SEARCH = 'product_search';
export const PRODUCT_VIEW = 'product_view';
export const PRODUCT_CREATE = 'product_create';
export const PRODUCT_ACTION_DETAIL = 'product_action_detail';
export const PRODUCT_DETAIL = 'product_detail';
export const PRODUCT_EDIT = 'product_edit';
export const PRODUCT_ACTIVATE ='product_activate'
export const PROMOTION_SEARCH = 'promotion_search';
export const PROMOTION_VIEW = 'promotion_view';
export const PROMOTION_APPROVAL = 'promotion_approval';
export const PROMOTION_REVIEW = 'promotion_review';
export const PROMOTION_CREATE = 'promotion_create';
export const PROMOTION_DETAIL = 'promotion_detail';
export const PROMOTION_EDIT = 'promotion_edit';
export const ROLE_SEARCH = 'role_search';
export const ROLE_VIEW = 'role_view';
export const ROLE_CREATE = 'role_create';
export const USER_SEARCH = 'user_search';
export const USER_VIEW = 'user_view';
export const USER_CREATE = 'user_create';
export const USER_PASSWORDRESET ='user_passwordreset';
export const USER_ACTIVATE ='user_activate';
export const ROLE_ACTION_DETAIL = 'role_action_detail';
export const ROLE_DETAIL = 'role_detail';
export const ROLE_VIEW_FUNCTION = 'role_viewfunction';
export const ROLE_ACTIVATE='role_activate';
export const ROLE_EDIT_FUNCTION ='role_editfunction';
export const ROLE_EDIT_USERS ='role_editusers';
export const ROLE_EDIT = 'role_edit';
export const ROLE_VIEW_USERS = 'role_viewusers';
export const USER_ACTION_DETAIL = 'user_action_detail';
export const USER_DETAIL = 'user_detail';
export const USER_EDIT = 'user_edit';
export const FUNCTION_SEARCH = 'function_search';
export const FUNCTION_VIEW = 'function_view';
export const SEGMENT_SEARCH = 'segment_search';
export const SEGMENT_VIEW = 'segment_view'
export const SEGMENT_CREATE = 'segment_create'
export const SEGMENT_ACTIVATE = 'segment_activate'
export const SEGMENT_ACTION_DETAIL = 'segment_action_detail'
export const SEGMENT_DETAIL = 'segment_detail'
export const SEGMENT_EDIT = 'segment_edit'
export const SEGMENT_VIEW_MEMBERS = 'segment_view_members'
export const POINT_SEARCH = 'point_search';
export const POINT_VIEW = 'point_view';
export const POINT_CREATE = 'point_create';
export const POINT_ACTION_DETAIL = 'point_action_detail';
export const POINT_DETAIL = 'point_detail';
export const POINT_EDIT = 'point_edit';
export const TIER_SEARCH='tier_search';
export const TIER_VIEW='tier_view';
export const TIER_ACTION_DETAIL='tier_action_detail';
export const TIER_CREATE='tier_create';
export const TIER_VIEW_MEMBERS='tier_view_members';
export const TIER_DETAIL='tier_detail';
export const TIER_EDIT='tier_edit';
export const TIER_DELETE='tier_delete';
export const IMPORT_SEARCH='import_search';
export const IMPORT_VIEW='import_view';
export const IMPORT_ACTION_DETAIL='import_action_detail';
export const EXPORT_RECORDS='export_records';
export const IMPORT_DETAIL='import_detail';
export const IMPORT_CREATE='import_create';
export const ACTIVITY_LOG_SEARCH='activity_log_search';
export const ACTIVITY_LOG_VIEW='activity_log_view';
export const ACTIVITY_LOG_ACTION_DETAIL='activity_log_action_detail';
export const ACTIVITY_LOG_DETAIL='activity_log_detail';