import styled, {css} from 'styled-components'
import antTheme from 'styles/antCustomTheme'
import Colors from 'styles/Colors'
import {FormTypes} from '../Form'

export const InputReadOnly = styled.span<FormTypes.InputLabelProp>`
  display: inline-block;
  border: 0;
  padding: 0;
  height: auto;
  min-height: ${antTheme.components?.Input?.controlHeight}px;
  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`

export const TextInputWrapper = styled.div``

export const TextInputRoot = styled.div`
  display: inline-flex;
  position: relative;
  width: 100%;
  grid-area: field;
`

export const InputWrapper = styled.div<FormTypes.InputLabelProp>`
  width: 100%;
  .lb-input,
  .lb-input-affix-wrapper {
    ${(props) =>
      props.$isLoginInput
        ? css<FormTypes.InputLabelProp>`
            border-color: ${Colors.WHITE};
            ::placeholder {
              color: ${Colors.WHITE};
            }
          `
        : css<FormTypes.InputLabelProp>`
            border-color: ${(props) => (props.$isDark ? 'transparent' : `${Colors.PRIMARY}`)};
            ::placeholder {
              color: ${(props) => (props.$isDark ? Colors.WHITE : Colors.GREY7)};
            }
          `}
    &:hover {
      border-color: ${(props) => (props.$isLoginInput ? Colors.WHITE : Colors.PRIMARY)};
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    ${(props) =>
      props.$isDark &&
      css<FormTypes.InputLabelProp>`
        color: ${Colors.WHITE};
        background-color: ${(props) => (props.$isLoginInput ? Colors.PRIMARY_DARK : Colors.COLOR_BLUE_LIGHT_OPACITY)};
      `}
    .lb-input-suffix > button.lb-btn.lb-btn-text {
      &:hover {
        background-color: transparent;
      }
    }
  }
  .lb-input-affix-wrapper > input.lb-input {
    ${(props) =>
      props.$isDark &&
      css<FormTypes.InputLabelProp>`
        color: ${Colors.WHITE};
        background: inherit;
      `}
  }
  .lb-input-disabled {
    ${(props) =>
      css<FormTypes.InputLabelProp>`
        border-color: transparent;
        &:hover {
          border-color: transparent;

        }
      `}
  }
  .lb-input-number, .lb-input-number-group-wrapper {
    width: inherit
  }
`
