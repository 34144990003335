import {Button, Space} from 'antd'
import Typography from 'lib/components/Typography'
import {ReviewRow, RewardTypeHeader} from './Review.styled'
import Message from 'lib/components/Message'
import {IconStarWithCircle} from 'app/common/icons/IconStarWithCircle'
import {numberWithCommas} from 'utils/common'
import SegmentationService from 'app/services/SegmentationService'
import {useEffect, useState} from 'react'

interface SegmentDetailsProps {
  data: PromotionsTypes.WhoRuleData
  onEdit(stepId: number): void
  isViewOnly?: boolean
  isDisabelEdit?: boolean
  showEdit: boolean
}

const SegmentDetails: React.FC<SegmentDetailsProps> = ({data, onEdit, isViewOnly, isDisabelEdit, showEdit}) => {
  const [memberCount, setMemberCount] = useState(0)
  const getTotalCount = () => {
    if(data.promotionSegment&& data.promotionSegment?.segmentCode){
      SegmentationService.search({SegmentCode: data.promotionSegment?.segmentCode}).then((res) => {
        const {data} = res.data
        if (data.length > 0) {
          setMemberCount(data[0].memberCount)
        }
      })
    }
  }
  useEffect(() => {
    getTotalCount()
  }, [])

  const renderTotalCountMessage = () => (
    <Message inline icon={<IconStarWithCircle />}>
      <Message.Text level="body1" color="default">
        A total of <strong>{numberWithCommas(memberCount)}</strong> Members in the program would be captured by this promotion
      </Message.Text>
    </Message>
  )

  return (
    <ReviewRow>
      <Space direction="vertical" size="large">
        <Typography.Text strong uppercase>
          Members
        </Typography.Text>
        <div>
          <RewardTypeHeader>
            <Typography.Text color="default" uppercase size="small">
          {data.memberOptIns ? " Opted In Member(s)"  : "Specific segment(s)"}    
            </Typography.Text>
          </RewardTypeHeader>
          {data.memberOptIns ? (
            <></>
          ) : <div>
            {data.promotionSegment?.segmentName} ({data.promotionSegment?.segmentCode} )
          </div>}

          <Space direction="vertical">{renderTotalCountMessage()}</Space>
        </div>
      </Space>
      <div>
        {(!isViewOnly && showEdit) && (
          <Button onClick={() => onEdit(0)} size="small" type="link" disabled={isDisabelEdit}>
            Edit
          </Button>
        )}
      </div>
    </ReviewRow>
  )
}

export default SegmentDetails
