import {Modal} from 'antd'
import {forwardRef, useState, useImperativeHandle} from 'react'
import {toast} from 'react-hot-toast'
import {useNavigate, useParams} from 'react-router-dom'
import {CreatePromotionParam} from '.'
import {CancelBtnRoot, PromotionHeaderWrapper} from './CreatePromotion.styled'
import {getPromotionRequestObj, initialPromotionRequestObj, isApprovedWithPastDate, updatePromotionRequestObj, updateTierDataForReview} from './utils'
import Button from 'lib/components/Button'
import Form from 'lib/components/Form'
import Typography from 'lib/components/Typography'
import {FormTypes} from 'lib/components/Form/Form'
import LoadingPanel from 'lib/components/LoadingPanel'
import {MarginBottomWrapper, ModalTitle, StyledModalContent} from 'app/common/components/Styled/common.styled'

export interface PromotionBasicInfoTypes {
  promotionName: string
  promotionCode?: string
  partnerCode: string
}

interface PromotionHeaderProps {
  loadingPartners: boolean
  partnerCodeOptions: FormTypes.Options[]
  isHeaderDisabled: boolean
  isViewOnly?: boolean
  refreshStepsData(): void
  handlePartnerCodeChange: (value: string) => void
}
interface PromotionHeaderErrorType {
  promotionName: boolean
  promotionCode: boolean
}
export interface PromotionHeaderRefType {
  validate(): boolean
  resetPromotionName(): void
  updatePromotionName(): void
}

const PromotionHeader = forwardRef<PromotionHeaderRefType, PromotionHeaderProps>((props, forwardedRef) => {
  const {promotionId} = useParams<CreatePromotionParam>()
  const actualPromotionId = atob(promotionId || '')
  const {loadingPartners, partnerCodeOptions, isHeaderDisabled, handlePartnerCodeChange} = props
  const getPromotionResponse = getPromotionRequestObj
  const navigate = useNavigate()
  const [open, openConfirmModal] = useState(false)
  const initialState: PromotionBasicInfoTypes = {
    promotionName: getPromotionResponse.promotionName,
    promotionCode: getPromotionResponse.promotionCode,
    partnerCode: getPromotionResponse.partnerCode,
  }

  useImperativeHandle(forwardedRef, () => ({validate: handleValidate, resetPromotionName, updatePromotionName}))

  const [state, setState] = useState<PromotionBasicInfoTypes>(initialState)
  const errorState: PromotionHeaderErrorType = {
    promotionName: false,
    promotionCode: false,
  }
  const [error, setErrorState] = useState<PromotionHeaderErrorType>(errorState)

  const resetPromotionName = () => {
    const prevState = {...state}
    setState({
      ...prevState,
      promotionName: getPromotionResponse.promotionName
    })
  }

  const updatePromotionName = () => {
    getPromotionRequestObj.promotionName = state.promotionName
  }

  const handelValidateOnChange = (value: string, key: keyof PromotionBasicInfoTypes) => {
    if (value !== null) {
      setErrorState({...errorState, [key]: false})
    }
  }
  const handleOnChange = (value: string, key: keyof PromotionBasicInfoTypes) => {
    const prevState = {...state}
    prevState[key] = value
    setState(prevState)
    if (key === 'partnerCode') {
      updateTierDataForReview(true, 0, [], true)
      handleOnBlur(value, key)
      return
    }
    handelValidateOnChange(value, key)
  }

  const handleOnBlur = (value: string, key: keyof PromotionBasicInfoTypes) => {
    if (key !== 'promotionName') {
      getPromotionResponse[key] = value
    }
    // props.refreshStepsData()
    if (key === 'partnerCode') {
      handlePartnerCodeChange(value)
    }
    handelValidateOnChange(value, key)
  }

  const handleCancelConfirm = () => {
    updatePromotionRequestObj(initialPromotionRequestObj)
    navigate(-1)
  }

  const handleValidate = () => {
    const hasPromotionName = getPromotionRequestObj.promotionName
    const hasPartnerCode = getPromotionRequestObj.partnerCode

    if (hasPromotionName && hasPartnerCode) {
      setErrorState({promotionName: false, promotionCode: false})
      return true
    }
    if (!hasPromotionName) {
      setErrorState({...errorState, promotionName: true})
      toast.error('Promotion Name is required')
      return false
    }

    return false
  }
  const handleOk = () => {
    openConfirmModal(false)
    handleCancelConfirm()
  }

  const handleCancel = () => {
    if (props.isViewOnly === true) {
      handleCancelConfirm()
    } else {
      openConfirmModal(true)
    }
  }
  const handleCancelModal = () => {
    openConfirmModal(false)
  }
  const renderPartnerCodeSelect = () => {
    if (loadingPartners) {
      return <LoadingPanel />
    }
    if (partnerCodeOptions.length > 0) {
      let isPartnerCodeDisabled = isHeaderDisabled
      if (actualPromotionId !== 'new') {
        isPartnerCodeDisabled = true
      }
      if (props.isViewOnly) {
        isPartnerCodeDisabled = true
      }
      return (
        <Form.Select
          noMargin
          placeholder="Select Partner Name"
          label="Partner Name"
          options={partnerCodeOptions}
          value={state.partnerCode || getPromotionResponse.partnerCode}
          onChange={handleOnChange}
          id="partnerCode"
          disabled={isPartnerCodeDisabled}
        />
      )
    }
    return null
  }
  const renderPromotionCode = () => {
    let isPromotionCodeDisabled = isHeaderDisabled
    if (actualPromotionId !== 'new') {
      isPromotionCodeDisabled = true
    }
    if (props.isViewOnly) {
      isPromotionCodeDisabled = true
    }
    return (
      <Form.Input
        status={error.promotionCode ? 'error' : undefined}
        id="promotionCode"
        label="Promotion Code"
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        value={state.promotionCode}
        disabled={true || isPromotionCodeDisabled}
      />
    )
  }

  return (
    <>
      <PromotionHeaderWrapper>
        <Form.Input
          status={error.promotionName ? 'error' : undefined}
          id="promotionName"
          label="Promotion Name"
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          value={state.promotionName}
          disabled={props.isViewOnly || isApprovedWithPastDate(getPromotionRequestObj)}
        />
        {renderPartnerCodeSelect()}
        {renderPromotionCode()}
        <CancelBtnRoot>
          <Button onClick={handleCancel} size="small" type="link">
            {actualPromotionId === 'new' ? 'Cancel New Promotion' : 'Cancel'}
          </Button>
        </CancelBtnRoot>
      </PromotionHeaderWrapper>
      <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
        <StyledModalContent align="center">
          <ModalTitle>
            <Typography.Title level={1}>Are you sure?</Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">Are you sure you want to cancel adding {state.promotionName} promotion?</Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="submit" onClick={handleOk}>
              Continue with cancel
            </Button>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="link" key="back" onClick={handleCancelModal}>
              Back
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      </Modal>
    </>
  )
})

export default PromotionHeader
