import IconAdd from 'app/common/icons/IconAdd'
import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import Typography from 'lib/components/Typography'
import Colors from 'styles/Colors'
import ProductFilter, {ResetProductFormRefType} from './ProductFilter'
import Appconfig from 'app/common/helpers/AppConfig'
import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {cloneDeep, isEmpty} from 'lodash'
import SearchResults from './SearchResults'
import ProductService from 'app/services/ProductService'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {PRODUCT_CREATE, PRODUCT_SEARCH, PRODUCT_VIEW} from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'
import {FormTypes} from 'lib/components/Form/Form'

// import { Modal } from 'antd'

const ProductDashboard = () => {
  const initialResponse: ResponseTypes.PagedResponse<ProductMasterTypes.ProductMasterResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)

  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const initialSearchParms: ProductMasterTypes.ProductMasterSearchParam = {}
  const [isFetching, setIsFetching] = useState(true)
  const [isFormReset, setIsFormReset] = useState(true)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [categoryOptions, setCategoryOptions] = useState<FormTypes.Options[]>([])
  const [productsData, setProductsData] = useState<ResponseTypes.PagedResponse<ProductMasterTypes.ProductMasterResponseBL[]>>(initialResponse)
  const productFormRef = useRef<ResetProductFormRefType>(null)
  const navigate = useNavigate()
  const {roles} = useAuth()
  const showSearch = roles.includes(PRODUCT_SEARCH)
  const showTable = roles.includes(PRODUCT_VIEW)
  const showCreateProduct = roles.includes(PRODUCT_CREATE)

  const handleSearch = (params: ProductMasterTypes.ProductMasterSearchParam) => {
    const reqParam: ProductMasterTypes.ProductMasterSearchParam = {
      // IsCombinable: false,
      ...params,
    }
    setIsFetching(true)
    ProductService.getProducts(reqParam)
      .then((res) => {
        const data = res.data
        setProductsData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...paginationObj})
    ProductService.getProductCategory().then((res) => {
      const data = res.data.data
      const formattedOptions = data.map((item) => ({
        label: item.categoryName,
        value: item.categoryCode,
      }))
      setCategoryOptions(formattedOptions)
    })
    // updateSegmentRequestObj(initialSegmentRequestObj)
  }, [])

  const handleAddProduct = () => {
    navigate(`/program/products/new`)
  }

  const handleFilter = (data: ProductMasterTypes.ProductMasterFilterState) => {
    const filterParms: ProductMasterTypes.ProductMasterSearchParam = {}
    filterParms.ProductName = data.ProductName
    filterParms.ProductCode = data.ProductCode || undefined
    filterParms.SkuCode = data.SkuCode || undefined
    filterParms.enabled = data.enabled === 'active' ? true : data.enabled === 'inactive' ? false : undefined
    filterParms.Category = data.Category || undefined
    filterParms.CategoryValue = data.CategoryValue || undefined
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const handleResetSearch = () => {
    productFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const handleRefresh = (value: boolean, productCode: string) => {
    const updatedProductData = cloneDeep(productsData)
    const updatedRowIndex = productsData.data.findIndex((item) => item.productCode === productCode)
    updatedProductData.data[updatedRowIndex] = {
      ...updatedProductData.data[updatedRowIndex],
      enabled: value,
    }
    setProductsData(updatedProductData)
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }

    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const {count = 0, pageIndex = 0} = productsData.pageInfo
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const renderProductBody = () => {
    if ((showSearch && showTable) || showCreateProduct) {
      return (
        <DataTable>
          {showSearch && showTable && (
            <ProductFilter ref={productFormRef} isFetching={isFetching} handleFilter={handleFilter} categoryOptions={categoryOptions} />
          )}
          <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
          {showTable && (
            <>
              <SearchResults isFetching={isFetching} productsResponse={productsData} handleRefresh={handleRefresh} />
              <DataTable.Pagination
                onChange={handleOnChange}
                current={pageIndex}
                pageSizeOptions={pageSizeOptions}
                total={count}
                defaultPageSize={initialPaginationObj.PageCount}
                pageSize={paginationObj.PageCount}
                showSizeChanger
              />
            </>
          )}
        </DataTable>
      )
    }
    return <NoPermission />
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Product Master</Typography.Title>
        {showCreateProduct && (
          <Button onClick={handleAddProduct} type="primary" $startIcon={<IconAdd color={Colors.WHITE} size={20} />}>
            Add New product
          </Button>
        )}
      </StyledHeader>
      {renderProductBody()}
    </section>
  )
}

export default ProductDashboard
