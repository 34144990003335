import {useState, useEffect} from 'react'
import {CheckboxValueType} from 'antd/es/checkbox/Group'
import Form from 'lib/components/Form'
import {FormTypes} from 'lib/components/Form/Form'
import {InputButtonGroup} from 'app/pages/Promotions/CreatePromotion/Steps/Incentives/Incentives.styled'
import {limitFrequecyOptions} from './utils'
import {LimitOptionsWrapper, TriggerLimitDetails, TriggerLimitItemsWrapper} from 'app/pages/Promotions/CreatePromotion/Steps/Trigger/Trigger.styled'

interface TriggerLimitProps {
  options: FormTypes.Options[]
  setTriggerLimits(values: CheckboxValueType[], type: string): void
  setTriggerLimitsData(value: FormTypes.ValueType | null, type: string, inputType: string): void
  isReset: boolean
  data?: PromotionsTypes.LimitDataType | null
  isOtherThenToggleAndEndDateDisabled: boolean
}

export interface RewardLimitRefType {
  validate(): boolean
}

const RewardLimit: React.FC<TriggerLimitProps> = ({
  options,
  setTriggerLimits,
  setTriggerLimitsData,
  isReset,
  data,
  isOtherThenToggleAndEndDateDisabled,
}) => {
  const [limitValue, setLimitValue] = useState<CheckboxValueType[]>(data ? [options[0].value] : [])
  const [limitQty, setLimitQty] = useState<number>(data ? data.limitQuantity : 0)
  const [limitFreq, setLimitFreq] = useState<FormTypes.ValueType>(data ? data.limitFrequency : '')

  useEffect(() => {
    if (isReset) {
      setLimitValue([])
      setLimitQty(0)
      setLimitFreq('')
    }
  }, [isReset])

  const onLimitChange = (values: CheckboxValueType[]) => {
    setLimitValue(values)
    setTriggerLimits(values, options[0].value as string)
  }

  const handleQuantityChange = (value: number) => {
    setLimitQty(value || 0)
    setTriggerLimitsData(value, options[0].value as string, 'limitQuantity')
  }

  const handleFrequencyChange = (value: FormTypes.ValueType, key: string) => {
    setLimitFreq(value)
    setTriggerLimitsData(value, options[0].value as string, 'limitFrequency')
  }

  return (
    <TriggerLimitItemsWrapper>
      <Form.Checkbox
        isVerticalView
        id="selectedTier"
        onChange={onLimitChange}
        value={limitValue}
        disabled={isOtherThenToggleAndEndDateDisabled}
        options={options}
      />
      {limitValue && limitValue.length > 0 && (
        <TriggerLimitDetails>
          <LimitOptionsWrapper>
            <InputButtonGroup>
              <Form.InputNumber
                id="limitQuantity"
                label="Qty"
                value={limitQty}
                onChange={handleQuantityChange}
                min={1}
                max={99999}
                width={102}
                precision={0}
                disabled={isOtherThenToggleAndEndDateDisabled}
              />
            </InputButtonGroup>
          </LimitOptionsWrapper>
          <InputButtonGroup>
            <Form.Select
              id="limitFrequency"
              placeholder="Select Frequency"
              label="FREQUENCY"
              onChange={handleFrequencyChange}
              value={limitFreq}
              options={limitFrequecyOptions}
              disabled={isOtherThenToggleAndEndDateDisabled}
            />
          </InputButtonGroup>
        </TriggerLimitDetails>
      )}
    </TriggerLimitItemsWrapper>
  )
}

export default RewardLimit
