import React from 'react'
import Button from 'lib/components/Button'
import SectionTitle from '../../../common/components/SectionTitle'
import {useNavigate} from 'react-router-dom'

const ExportSetup: React.FC = () => {
  const navigate = useNavigate()

  const navigateTo = (): void => {
    navigate(-1)
  }

  return (
    <section>
      <SectionTitle>Export Setup</SectionTitle>
      <Button onClick={navigateTo}>{`< Back`}</Button>
    </section>
  )
}

export default ExportSetup
