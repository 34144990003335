import {Input} from 'antd'
import {FormTypes} from '../Form'
import FormLabel from '../FormLabel'
import {InputReadOnly, InputWrapper, TextInputRoot, TextInputWrapper} from './Input.Styled'
import Typography from 'lib/components/Typography'

const TextInput = (props: FormTypes.Input) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    props.onChange && props.onChange(value, props.id)
  }

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    props.onBlur && props.onBlur(value, props.id)
  }

  const renderInput = () => {
    if (props.readOnly) {
      const noWrap = props.id === 'email' || props.id === 'address'
      const text = props.value?.toString()
      return (
        <InputReadOnly title={text} noWrap={noWrap}>
          <Typography.Text ellipsis={{tooltip: text}}>{text}</Typography.Text>
        </InputReadOnly>
      )
    }
    if (props.onChange) {
      return (
        <InputWrapper $isDark={props.isDark} $isLoginInput={props.isLoginInput}>
          <Input
            type={props.type || 'text'}
            aria-labelledby={`${props.id}-label`}
            onChange={handleInputChange}
            onBlur={handleOnBlur}
            disabled={props.disabled}
            id={props.id}
            name={props.id}
            value={props.value}
            placeholder={props.placeholder}
            status={props.status}
            autoComplete="off"
            suffix={props.suffix}
          />
        </InputWrapper>
      )
    }
    return (
      <InputWrapper $isDark={props.isDark}>
        <Input
          type="text"
          aria-labelledby={`${props.id}-label`}
          disabled={props.disabled}
          id={props.id}
          name={props.id}
          placeholder={props.placeholder}
          status={props.status}
          autoComplete="off"
          suffix={props.suffix}
        />
      </InputWrapper>
    )
  }
  return (
    <TextInputWrapper>
      {props.label && props.id && <FormLabel id={props.id} label={props.label} isDark={props.isDark} />}
      <TextInputRoot>{renderInput()}</TextInputRoot>
      {props.children}
    </TextInputWrapper>
  )
}

TextInput.Label = FormLabel

export default TextInput
