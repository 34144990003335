import Appconfig from 'app/common/helpers/AppConfig'
// import {StepsStepTypes} from './Steps'
import {cloneDeep} from 'lodash'
import {FormTypes} from 'lib/components/Form/Form'
import {StyledStepsProps} from 'lib/components/Steps/Steps'

export const exclusionStatusForDelete = ['LIVE', 'ENDED', 'APPROVED']

export const ReviewSteps: StyledStepsProps[] = [
  {
    title: 'Members',
    id: 0,
    isActive: true,
    isCompleted: false,
  },
  {
    title: 'Duration',
    id: 1,
    isActive: false,
    isCompleted: false,
  },
  {
    title: 'Trigger',
    id: 2,
    isActive: false,
    isCompleted: false,
  },
  {
    title: 'Incentives',
    id: 3,
    isActive: false,
    isCompleted: false,
  },
  {
    title: 'Review',
    id: 4,
    isActive: false,
    isCompleted: false,
  },
]

export const triggerActivitiesOptions = [
  {
    label: 'Profile',
    value: 'profile',
  },
  {
    label: 'Quiz',
    value: 'quiz',
  },
  {
    label: 'Survey',
    value: 'survey',
  },
  {
    label: 'Product Review',
    value: 'productreview',
  },
  {
    label: 'Basic Referral',
    value: 'basic',
    showIcon: true,
    infoMsg: 'Award will be issued based on sent invitation.'
  },
  {
    label: 'Accepted Referral',
    value: 'accepted',
    showIcon: true,
    infoMsg: 'Award will be issued based on referee/receiver enrolling into program.'
  },
  {
    label: 'Transacted Referral',
    value: 'transacted',
    showIcon: true,
    infoMsg: 'Award will be issued once referee/receiver has enrolled and made their first transaction.'
  },
  {
    label: 'Redemption',
    value: 'Redemption',
  },
  {
    label: 'Email Opt-in',
    value: 'emailOptIn',
  },
  {
    label: 'SMS Opt-in',
    value: 'smsOptIn',
  },
  {
    label: 'Bonus Code',
    value: 'fixCode',
  }
]

// export const EnrollmentOptions = [
//   {
//     label: 'Award points upon enrollment',
//     value: 'awardOnEnrollment',
//   },
// ]

export const EnrollmentOptions = [
  {
    label: 'On Earning Points',
    value: 'earningPoints',
  },
  {
    label: 'On Promotion Completion',
    value: 'promotionCompletion',
  },
]

export const EnrollmentTypeOptions = [
  {
    label: 'Regular Enrollment',
    value: true,
  },
  {
    label: 'Conditional Enrollment',
    value: false,
  },
]

export const SpendTypeOptions = [
  {
    label: 'Spend within limited period',
    value: true,
  },
  {
    label: 'Spend & earn points within days of enrollment',
    value: false,
  },
]

// export const untilPromotionEndDateOptions: FormTypes.Options[] = [
//   {
//     label: 'Until promotion-end Date',
//     value: 'isChecked',
//   },
// ]

export const matchDateRangeCheckboxOptions: FormTypes.Options[] = [
  {
    label: 'Match date range with Promotion date range',
    value: 'isChecked',
  },
]

export const EventOptions = [
  {
    label: 'Birthday',
    value: 'membershipBirthDate',
  },
  {
    label: 'Enrollment Anniversary',
    value: 'membershipDate',
  },
  {
    label: 'Points Milestone',
    value: 'Point',
  },
  {
    label: 'Lifetime Value',
    value: 'membershipLifeTimeValue',
  },
]

export let initialPromotionRequestObj: PromotionsTypes.PromotionResponseBL = {
  rowStatus: Appconfig.promotions.defaultValus.rowStatus,
  // createdBy: '',
  // createdOn: '',
  // updatedOn: '',
  promotionName: '',
  promotionCode: '',
  promotionStatusCode: Appconfig.promotions.defaultValus.promotionStatusCode,
  isCombinable: true,
  partnerCode: '',
  startDateTime: null,
  endDateTime: null,
  ruleData: [],
  properties: {},
  promotionType: Appconfig.promotions.defaultValus.promotionType,
  promotionRuleData: {
    whoData: {
      isAllMembers: true,
      memberCode: null,
      tierData: [],
      promotionSegment: null,

      memberOptIns: false,
    },
    triggerRuleData: null,
    incentiveRuleData: null,
  },
  promotionId: 0,
  approvalManager: [],
}

export let getPromotionRequestObj: PromotionsTypes.PromotionResponseBL = cloneDeep(initialPromotionRequestObj)

export const updatePromotionRequestObj = (data: PromotionsTypes.PromotionResponseBL) => {
  getPromotionRequestObj = cloneDeep(data)
}

export const updateTriggerRuleData = (data: PromotionsTypes.TriggerRuleData | null) => {
  getPromotionRequestObj.promotionRuleData.triggerRuleData = data
}
export const updateWhoRuleData = (data: PromotionsTypes.WhoRuleData) => {
  getPromotionRequestObj.promotionRuleData.whoData = data
}

export const initialMemberCountData: PromotionsTypes.PromotionMemberCountDataType | null = null

export let memberCountData: PromotionsTypes.PromotionMemberCountDataType | null = cloneDeep(initialMemberCountData)

export const getpointsThreshold = () => {
  if (getPromotionRequestObj.promotionRuleData.triggerRuleData === null) return 0
  else if (getPromotionRequestObj.promotionRuleData.triggerRuleData.eventRuleData != null) {
    return getPromotionRequestObj.promotionRuleData.triggerRuleData.eventRuleData.eventValue
  }
  return 0
}
export const updateTierDataForReview = (
  isAllMembers: boolean,
  totalCount: number,
  tierData: PromotionsTypes.TierDataType[],

  isReset?: boolean
) => {
  memberCountData = isReset
    ? null
    : {
        isAllMembers,
        totalCount,
        tierData,
      }
}

interface PromotionapiDataTypes {
  productCategory: ProductTypes.ProductMasterCategoryResponse[] | null
}
export const PromotionApiData: PromotionapiDataTypes = {
  productCategory: null,
}

export const multiplierOptions: FormTypes.Options[] = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
]
export const isApprovedWithPastDate = (data: PromotionsTypes.PromotionResponseBL) => {
  if (data.startDateTime !== null) {
    const startDateTime = new Date(data.startDateTime)
    const currentDateTime = new Date(new Date().toISOString())

    const isDisabel =
      startDateTime < currentDateTime && data.promotionStatusCode.toUpperCase() === Appconfig.promotions.statusCodes.approved.toUpperCase()
    return isDisabel
  }
  return false
}

export const limitQuantityOptions: FormTypes.Options[] = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
]

export const limitFrequecyOptions: FormTypes.Options[] = [
  {
    label: 'Day',
    value: 'Day',
  },
  {
    label: 'Week',
    value: 'Week',
  },
  {
    label: 'Month',
    value: 'Month',
  },
  {
    label: 'Year',
    value: 'Year',
  },
  {
    label: 'Program Duration',
    value: 'Phase',
  },
]

export const limitOptions: FormTypes.Options[] = [
  {
    label: 'set USER LEVEL Limits',
    value: 'userLimit',
  },
  {
    label: 'set CAMPAIGN LEVEL Limits',
    value: 'campaignLimit',
  },
]

export enum LIMIT_TYPES {
  userLimit = 'userLimit',
  campaignLimit = 'campaignLimit',
}

export const categoryOptions = [
  {
    label: 'INCLUDE',
    value: true,
  },
  {
    label: 'EXCLUDE',
    value: false,
  },
]

export let getActiveUsers: UserManagementTypes.UserListResponseType[] = []

export const updateApprovalManagers = (data: UserManagementTypes.UserListResponseType[]) => {
  getActiveUsers = cloneDeep(data)
}

export const getApprovalManagerDetails = (value: string): PromotionsTypes.ApprovalManagerType | undefined => {
  const manager = getActiveUsers.find((item) => item.userName === value)
  if (manager) {
    return {
      userName: manager.userName,
      name: `${manager.firstName} ${manager.middleName === null ? '' : manager.middleName} ${manager.lastName}`,
      id: manager.id,
    }
  }
  return undefined
}

export const getRewardsName = (data: PromotionsTypes.ActivityRewardType): string[] => {
  return data ? [data.rewardName ?data.rewardName :""] : []
}
export const getRewardsCode = (data: PromotionsTypes.ActivityRewardType): string[] => {
  return data ? [data.rewardCode ?data.rewardCode :""] : []
}