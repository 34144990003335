import styled, { css } from "styled-components";
import PhoneInput from 'react-phone-number-input'

interface PhoneInputCustomProps {
  status?: string
}

export const StyledPhoneInput = styled(PhoneInput)<PhoneInputCustomProps>`
  input {
    margin: 0;
    padding: 13.5px 21px;
    color: #0062FF;
    font-size: 14px;
    line-height: 1.7142857142857142;
    list-style: none;
    font-family: 'IBM Plex Sans',sans-serif;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #0062FF;
    border-radius: 25px;
    transition: all 0.2s;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    ${(props) =>
    props.status === 'error' &&
    css`
      border-color: #ff7875;
    `}

  }
`