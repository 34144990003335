import styled from 'styled-components'
import Colors from 'styles/Colors'

interface MessageWrapperProps {
  $inline?: boolean
}

interface MessageTextProps {
  level?: 'h4' | 'h5' | 'body1'
  color?: 'default' | 'success'
}

export const MessageWrapper = styled.div<MessageWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => (props.$inline ? 'row' : 'column')};
  padding: 17px 12px 17px 0;
  /* max-width: 397px; */
  text-align: center;
`

export const MessageText = styled.div<MessageTextProps>`
  font-family: ${({theme, level = 'h4'}) => theme.typography[level].fontFamily};
  font-size: ${({theme, level = 'h4'}) => theme.typography[level].fontSize};
  font-weight: ${({theme, level = 'h4'}) => theme.typography[level].fontWeight};
  line-height: ${({theme, level = 'h4'}) => theme.typography[level].lineHeight};
  letter-spacing: ${({theme, level = 'h4'}) => theme.typography[level].letterSpacing};
  color: ${({color = 'success'}) => (color === 'default' ? Colors.BLACK : Colors.SUCCESS)};
  padding: 13px;
`
