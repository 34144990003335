export const formatSelectedFunction = (
  functionCode: string,
  functions: UserManagementTypes.FunctionInfoType[],
  categories: UserManagementTypes.CategoryInfoType[]
) => {
  const selectedFunction = functions.find((item) => item.functionCode === functionCode)
  return {
    functionCode: selectedFunction?.functionCode || '',
    functionName: selectedFunction?.functionName || '',
    categoryCode: selectedFunction?.categoryCode || '',
    categoryName: categories.find((item) => item.categoryCode === selectedFunction?.categoryCode)?.categoryName || '',
    isEdit: !!selectedFunction?.isEdit,
    isReadOnly: !!selectedFunction?.isReadOnly,
  }
}
