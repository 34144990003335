import Typography from 'lib/components/Typography'
import {TierAction, TierForm, ZeroHeightButton} from '../Tier.Styled'
import {getRewardsNamesByCodes, getTierRequestObj} from '../utils'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {cloneDeep} from 'lodash'
import {Col, Row} from 'antd'
import Form from 'lib/components/Form'
import {FormTypes} from 'lib/components/Form/Form'
import {FullWidthRow} from 'app/pages/Promotions/CreatePromotion/Steps/Trigger/Trigger.styled'
import {ReviewDetailsItem, StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import {numberWithCommas} from 'utils/common'
import {TIER_EDIT, TIER_DELETE, TIER_VIEW_MEMBERS} from 'app/common/helpers/UserFunctions'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {useNavigate} from 'react-router-dom'
import toast from 'react-hot-toast'

interface TierDetailsFormProps {
  rewardsDataOptions: FormTypes.Options[]
  isViewOnly: boolean
  activeStep: number
  tierData: TierTypes.TierDetailStateType
  handleFormValueChange(value: FormTypes.ValueType, key: string, tierIndex: number): void
  handleEditTier(value: boolean, index: number): void
  deleteTier(tierIndex: number): void
  tierIndex: number
  isLive?: boolean
}

export interface TierFormRefType {
  validate(): boolean
}

const TierDetailsForm = forwardRef<TierFormRefType, TierDetailsFormProps>(
  ({rewardsDataOptions, isViewOnly, activeStep, tierData, handleFormValueChange, deleteTier, handleEditTier, tierIndex, isLive = false}, ref) => {
    const tierReqObj = getTierRequestObj
    const {tierList} = tierReqObj
    const initialState: TierTypes.TierDetailStateType = {
      membershipTierName: tierData.membershipTierName || '',
      membershipTierKey: tierData.membershipTierKey || '',
      earnRate: tierData.earnRate || 0,
      rewards: tierData.rewards || [],
      fromValue: tierData.fromValue || 0,
      toValue: tierData.toValue || 0,
      isEditable: tierData.isEditable,
    }
    const initialErrorState = {
      membershipTierName: false,
      membershipTierKey: false,
      earnRate: false,
      rewards: false,
      fromValue: false,
      toValue: false,
    }
    const {roles} = useAuth()
    const showEdit = roles.includes(TIER_EDIT)
    const showTierMembers = roles.includes(TIER_VIEW_MEMBERS)
    const showDelete = roles.includes(TIER_DELETE)
    const [state, setState] = useState<TierTypes.TierDetailStateType>(initialState)
    const [error, setErrorState] = useState(initialErrorState)

    useEffect(() => {
      setState(tierData)
    }, [tierData])

    const handleValidate = (): boolean => {
      if (state.fromValue > 10000000000) {
        toast.error('From Value should be less than 10000000000.')
        setErrorState({...error, fromValue: true})
        return false
      }
      if (state.toValue > 10000000000) {
        toast.error('To Value should be less than 10000000000.')
        setErrorState({...error, toValue: true})
        return false
      }

      return true
    }

    useImperativeHandle(ref, () => ({validate: handleValidate}))

    const handleChange = (value: FormTypes.ValueType, key: string) => {
      const prevState = cloneDeep(state)

      if (key === 'rewards') {
        const values = value as string[]
        if (values.length > 5) {
          toast.error('Can not add more than 5 rewards!!!')
          return
        }
      }
      setState({
        ...prevState,
        [key]: value,
      })
      handleFormValueChange(value, key, tierIndex)
    }

    const handleEditTierDetails = () => {
      const prevState = cloneDeep(state)
      setState({
        ...prevState,
        isEditable: !prevState.isEditable,
      })
      handleEditTier(!prevState.isEditable, tierIndex)
    }

    const navigate = useNavigate()

    const handleMembers = (tierKey: string): void => {
      const membershipTierKey = btoa(tierKey)
      navigate(`/program/tiers/${membershipTierKey}/members`)
    }
    const renderTierActionBody = () => {
      if (!isLive) {
        return (
          <>
            {showDelete && (
              <ZeroHeightButton type="link" onClick={() => deleteTier(tierIndex)} disabled={isLive}>
                Delete
              </ZeroHeightButton>
            )}
            {showEdit && (
              <ZeroHeightButton type="link" onClick={handleEditTierDetails} disabled={isLive}>
                Edit
              </ZeroHeightButton>
            )}
          </>
        )
      } else {
        return (
          <>
            {showTierMembers && (
              <ZeroHeightButton type="link" onClick={() => handleMembers(tierData.membershipTierKey)}>
                Member
              </ZeroHeightButton>
            )}
          </>
        )
      }
    }

    const renderViewForm = (): JSX.Element => {
      const {membershipTierKey, membershipTierName, earnRate, rewards, fromValue, toValue} = state
      return (
        <TierForm>
          <TierAction>{renderTierActionBody()}</TierAction>
          <FullWidthRow>
            <Col span={4}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Tier ID</StyledReviewLabel>
                <StyledReviewValue>{membershipTierKey}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={4}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Tier Name</StyledReviewLabel>
                <StyledReviewValue>{membershipTierName}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={4}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Base Earn Rate</StyledReviewLabel>
                <StyledReviewValue>{earnRate}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={8}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Points Range</StyledReviewLabel>
                <StyledReviewValue>{`${numberWithCommas(fromValue)} - ${numberWithCommas(toValue)}`}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={16}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Rewards</StyledReviewLabel>
                <StyledReviewValue>{getRewardsNamesByCodes(rewardsDataOptions, rewards).join(', ')}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
          </FullWidthRow>
        </TierForm>
      )
    }

    if (!state.isEditable && isViewOnly) {
      return renderViewForm()
    }

    return (
      <TierForm>
        <TierAction>
          {showDelete && (
            <ZeroHeightButton type="link" onClick={() => deleteTier(tierIndex)} disabled={isLive}>
              Delete
            </ZeroHeightButton>
          )}
          {activeStep === 1 && tierIndex < tierList.length && (
            <ZeroHeightButton type="link" onClick={handleEditTierDetails}>
              Cancel
            </ZeroHeightButton>
          )}
        </TierAction>
        <Row gutter={[16, 32]}>
          <Col span={4}>
            <Form.Input id="membershipTierKey" label="Tier ID" placeholder="Tier ID" value={state.membershipTierKey} onChange={handleChange} />
          </Col>
          <Col span={10}>
            <Form.Input id="membershipTierName" label="Tier Name" placeholder="Tier Name" value={state.membershipTierName} onChange={handleChange} />
          </Col>
          <Col span={10}>
            <Form.InputNumber
              id="earnRate"
              type="number"
              label="Base Earn Rate"
              placeholder="Enter Multiplier"
              value={state.earnRate}
              min={0}
              max={10000}
              addOnAfter={
                <Typography.Title level={5} color="default">
                  PER $ SPENT
                </Typography.Title>
              }
              width={'100%'}
              onChange={handleChange}
            />
            {/* <Form.Input
          id="earnRate"
          type="number"
          label="Base Earn Rate"
          placeholder="Enter Multiplier"
          value={item.earnRate}
          max={100000}
          suffix={<Typography.Title level={5} color="default">PER $ SPENT</Typography.Title>}
          onChange={(value, key) => handleChange(value, key as string, tierIndex)}
        /> */}
          </Col>
          <Col span={14}>
            <Form.Select
              id="rewards"
              mode="multiple"
              label="Automatically Assigned Rewards (Optional)"
              placeholder="Select Reward"
              value={state.rewards}
              onChange={handleChange}
              options={rewardsDataOptions}
              filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Col>
          <Col span={5}>
            <Form.InputNumber
              id="fromValue"
              label="Tier Points Range"
              placeholder="From"
              min={0}
              max={10000000000}
              value={state.fromValue}
              onChange={handleChange}
              status={error.fromValue ? 'error' : undefined}
            />
          </Col>
          <Col span={5} style={{marginTop: '16px'}}>
            <Form.InputNumber
              id="toValue"
              label=" "
              placeholder="To"
              min={0}
              value={state.toValue}
              max={10000000000}
              onChange={handleChange}
              status={error.toValue ? 'error' : undefined}
            />
          </Col>
        </Row>
      </TierForm>
    )
  }
)

export default TierDetailsForm
