import {RouteObject} from 'react-router'

import ExportImportSetup from '../pages/ExportImportSetup'
import ExportSetup from '../pages/ExportImportSetup/ExportSetup'
import ImportSetup from '../pages/ExportImportSetup/ImportSetup/Dashboard'

import {ErrorBoundry} from '../common/components/Routes/Fallback'
import ImportNewData from 'app/pages/ExportImportSetup/ImportSetup/ImportNewData'

const ExportImportRoutes: RouteObject = {
  path: `export-import`,
  element: <ExportImportSetup />,
  errorElement: <ErrorBoundry />,
  children: [
    {index: true, element: <ExportSetup />},
    {path: 'export', index: true, element: <ExportSetup />},
    {path: 'import', index: true, element: <ImportSetup />},
    {path: 'import/:fileId', index: true, element: <ImportNewData />},
  ],
}

export default ExportImportRoutes
