import {Button} from 'antd'
import Form from 'lib/components/Form'
import IconDelete from 'app/common/icons/IconDelete'
import ProductService from 'app/services/ProductService'
import {useEffect, useState} from 'react'
import {categoryOptions, getPromotionRequestObj} from '../../utils'
import {CategoryDropdownCol, CategoryRowBox} from './Trigger.styled'
import {FormTypes} from 'lib/components/Form/Form'
import LoadingPanel from 'lib/components/LoadingPanel'
import ToggleButton from 'lib/components/ToggleButton'

interface CategoryRowProps {
  categories: FormTypes.Options[]
  onCategoryChange(data: PromotionsTypes.TriggerData['productCategory'], rowIndex: number): void
  onCategoryValueChange(values: string[], rowIndex: number): void
  onCategoryActionChange(value: string, rowIndex: number): void
  rowIndex: number
  triggerData: PromotionsTypes.TriggerData
  onDeleteRow(rowIndex: number): void
}

const CategoryRow = ({categories, triggerData, rowIndex, onDeleteRow, onCategoryChange, onCategoryValueChange, onCategoryActionChange}: CategoryRowProps) => {
  const {action, values} = triggerData
  const getPromotionResponse = getPromotionRequestObj
  const actionDefaultValue: boolean = !action || action === 'INCLUDE' ? true : false
  const [isValueLoading, setIsValueLoading] = useState<boolean>(false)

  const [categroyValues, setCategoryValues] = useState<FormTypes.Options[]>([])
  const [actionValue, setAction] = useState<boolean>(actionDefaultValue)

  const [selectedCategoryValues, setSelectedActionValues] = useState<string[]>([])

  const getCategoryValues = (value: string) => {
    const {partnerCode} = getPromotionResponse
    ProductService.getProductCategoryValue({CategoryCode: value, PartnerCode: partnerCode})
      .then((res) => {
        const data = res.data.data
        let options: FormTypes.Options[] = data.map((item) => ({value: item.categoryValue, label: item.categoryValue}))
        if (options.length > 1) {
          options = [{value: 'all', label: 'All'}, ...options] 
        }
        setCategoryValues(options)
        let availableOptions = options.filter((item) => values.includes(item.value as string)).map((item) => item.value as string)
        if (availableOptions.length === values.length && availableOptions.length > 1) {
          availableOptions = ['all', ...availableOptions] 
        }
        setSelectedActionValues(availableOptions)
      })
      .finally(() => {
        setIsValueLoading(false)
      })
  }

  const handleOnCategoryChange = (value: string, key: string) => {
    const selectedCategory = categories.find((category) => category.value === value)
    if (selectedCategory) {
      const data: PromotionsTypes.TriggerData['productCategory'] = {
        categoryName: selectedCategory ? (selectedCategory.label as string) : '',
        categoryCode: selectedCategory ? (selectedCategory.value as string) : '',
      }
      setIsValueLoading(true)
      getCategoryValues(value)
      onCategoryChange(data, rowIndex)
    }
  }

  useEffect(() => {
    if (triggerData.productCategory && triggerData.productCategory.categoryCode) {
      handleOnCategoryChange(triggerData.productCategory.categoryCode, `productCategory_${rowIndex}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnValueChange = (value: string[], key: string) => {
    let updatedValues = [...value]
    let actualValues = updatedValues.filter(item => item !== 'all')
    if (value.includes('all') && value[value.length - 1] === 'all' ) {
      updatedValues = categroyValues.map(item => item.value as string)
    } else if (value.includes('all') && value[0] === 'all') {
      updatedValues = actualValues
    } else if (!value.length || (selectedCategoryValues.includes('all') && (value.length === (categroyValues.length - 1)))) {
      updatedValues = []
    } else if (!value.includes('all') && (value.length === (categroyValues.length - 1))) {
      updatedValues = ['all', ...updatedValues]
    }
    actualValues = updatedValues.filter(item => item !== 'all')
    setSelectedActionValues(updatedValues)
    onCategoryValueChange(actualValues, rowIndex)
  }

  const setCategoryAction = (value: boolean) => {
    onCategoryActionChange(value ? 'INCLUDE' : 'EXCLUDE', rowIndex)
    setAction(value)
  }

  const renderButtonRadio = () => {
    return <ToggleButton id="activate" value={actionValue} values={categoryOptions} handleChange={(value, key) => setCategoryAction(value)} />
  }

  const handleDeleteRow = () => {
    onDeleteRow(rowIndex)
  }

  const renderCategoryValueDropdown = () => {
    if (isValueLoading) {
      return <LoadingPanel />
    }

    if (categroyValues && categroyValues.length > 0) {
      return (
        <Form.Select
          noMargin
          mode="multiple"
          value={selectedCategoryValues}
          id={`productCategoryValues${rowIndex}`}
          placeholder="Select Values"
          onChange={handleOnValueChange}
          options={categroyValues}
          filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      )
    }
    return null
  }

  return (
    <CategoryRowBox>
      <CategoryDropdownCol>
        <Form.Select
          noMargin
          id={`productCategory_${rowIndex}`}
          placeholder="Category"
          value={triggerData.productCategory?.categoryCode}
          onChange={handleOnCategoryChange}
          options={categories}
        />
      </CategoryDropdownCol>
      {renderButtonRadio()}
      <CategoryDropdownCol>{renderCategoryValueDropdown()}</CategoryDropdownCol>
      <CategoryDropdownCol>
        <Button onClick={handleDeleteRow} type="text" size="small">
          <IconDelete size={16} />
        </Button>
      </CategoryDropdownCol>
    </CategoryRowBox>
  )
}

export default CategoryRow
