import {Country} from 'react-phone-number-input'
import {InputReadOnly, TextInputWrapper} from '../Form/Input/Input.Styled'
import {StyledPhoneInput} from './PhoneInput.styled'
import FormLabel from '../Form/FormLabel'
import {normalizePhoneNo} from 'utils/common'
import Typography from '../Typography'

interface PhoneInputFieldProps {
  id: string
  label?: string
  value?: string
  onChange?(value: string, key: string): void
  defaultCountry?: Country
  autoFocus?: boolean
  disabled?: boolean
  placeholder?: string
  status?: string
  readOnly?: boolean
}

const PhoneInputField: React.FC<PhoneInputFieldProps> = ({
  id,
  value,
  label,
  defaultCountry = 'US',
  onChange,
  autoFocus,
  disabled,
  placeholder,
  status,
  readOnly,
}) => {
  const onPhoneNoChange = (value: string) => {
    onChange && onChange(value, id)
  }

  if (readOnly) {
    const text = value?.toString() || ''
    return (
      <InputReadOnly title={text}>
        {label && id && <FormLabel id={id} label={label} />}
        <Typography.Text ellipsis={{tooltip: normalizePhoneNo(text)}}>{normalizePhoneNo(text)}</Typography.Text>
      </InputReadOnly>
    )
  }

  return (
    <TextInputWrapper>
      {label && id && <FormLabel id={id} label={label} />}
      <StyledPhoneInput
        id={id}
        value={value}
        onChange={onPhoneNoChange}
        defaultCountry={defaultCountry}
        countrySelectComponent={() => null}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        status={status}
      />
    </TextInputWrapper>
  )
}

export default PhoneInputField
