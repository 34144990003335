import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Function Name',
    id: 'functionName',
    textAlign: 'left',
    width: '30%',
  },
  {
    title: 'Function Id',
    id: 'functionId',
    textAlign: 'left',
    width: '25%',
  },
  {
    title: 'Description',
    id: 'description',
    textAlign: 'left',
    width: '25%',
  },
  {
    title: 'Category',
    id: 'categoryCode',
    textAlign: 'left',
    width: '20%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  functionsResponse: UserManagementTypes.FunctionListResponseType[]
  // rewardsResponse: ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>
}

const SearchResults: React.FC<SearchResultProps> = ({isFetching, functionsResponse}) => {
  const generateRowData = (): DataTableTypes.Row[] => {
    if (functionsResponse.length) {
      const dataTableRows: DataTableTypes.Row[] = []
      functionsResponse.map((item, index) => {
        const obj: DataTableTypes.Row = {
          functionName: item.functionName,
          functionId: item.functionCode,
          description: item.functionDescription,
          categoryCode: item.categoryCode,
        }
        dataTableRows.push(obj)
        return null
      })
      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
    </>
  )
}

export default SearchResults
