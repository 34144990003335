import {useState, useEffect, forwardRef, useImperativeHandle} from 'react'
import {toast} from 'react-hot-toast'
import {CheckboxValueType} from 'antd/es/checkbox/Group'
import Form from 'lib/components/Form'
import {FormTypes} from 'lib/components/Form/Form'
import {LimitOptionsWrapper, TriggerLimitDetails, TriggerLimitItemsWrapper} from './Trigger.styled'
import {getPromotionRequestObj, limitFrequecyOptions} from '../../utils'
import {InputButtonGroup} from '../Incentives/Incentives.styled'

interface TriggerLimitProps {
  options: FormTypes.Options[]
  setTriggerLimits(values: CheckboxValueType[], type: string): void
  setTriggerLimitsData(value: FormTypes.ValueType | null, type: string, inputType: string): void
  isReset: boolean
  data?: PromotionsTypes.LimitDataType | null
}

export interface TriggerLimitRefType {
  validate(): boolean
}

const TriggerLimit = forwardRef<TriggerLimitRefType, TriggerLimitProps>(({options, setTriggerLimits, setTriggerLimitsData, isReset, data}, ref) => {
  const getPromotionResponse = getPromotionRequestObj
  const latestTriggerRuleData = getPromotionResponse.promotionRuleData.triggerRuleData
  const limitData = latestTriggerRuleData && latestTriggerRuleData.limitData
  const [limitValue, setLimitValue] = useState<CheckboxValueType[]>(data ? [options[0].value] : [])
  const [limitQty, setLimitQty] = useState<number>(data ? data.limitQuantity : 0)
  const [limitFreq, setLimitFreq] = useState<FormTypes.ValueType>(data ? data.limitFrequency : '')

  useEffect(() => {
    if (isReset) {
      setLimitValue([])
      setLimitQty(0)
      setLimitFreq('')
    }
  }, [isReset])

  const handleValidate = (): boolean => {
    let isValid = true
    if (limitData && limitData.campaignLimit && !limitData.campaignLimit.limitQuantity) {
      toast.error('Please select quantity')
      isValid = false
    } else if (limitData && limitData.userLimit && !limitData.userLimit.limitQuantity) {
      isValid = false
      toast.error('Please select quantity')
    }
    return isValid
  }

  useImperativeHandle(ref, () => ({validate: handleValidate}))

  const onLimitChange = (values: CheckboxValueType[]) => {
    setLimitValue(values)
    setTriggerLimits(values, options[0].value as string)
  }

  const handleQuantityChange = (value: number) => {
    setLimitQty(value || 0)
    setTriggerLimitsData(value, options[0].value as string, 'limitQuantity')
  }

  const handleFrequencyChange = (value: FormTypes.ValueType, key: string) => {
    setLimitFreq(value)
    setTriggerLimitsData(value, options[0].value as string, 'limitFrequency')
  }

  return (
    <TriggerLimitItemsWrapper>
      <Form.Checkbox isVerticalView id="selectedTier" onChange={onLimitChange} value={limitValue} options={options} />
      {limitValue && limitValue.length > 0 && (
        <TriggerLimitDetails>
          <LimitOptionsWrapper>
            <InputButtonGroup>
              <Form.InputNumber
                id="limitQuantity"
                label="Qty"
                value={limitQty}
                onChange={handleQuantityChange}
                min={1}
                max={99999}
                width={102}
                precision={0}
              />
            </InputButtonGroup>
          </LimitOptionsWrapper>
          <InputButtonGroup>
            <Form.Select
              id="limitFrequency"
              placeholder="Select Frequency"
              label="FREQUENCY"
              onChange={handleFrequencyChange}
              value={limitFreq}
              options={limitFrequecyOptions}
            />
          </InputButtonGroup>
        </TriggerLimitDetails>
      )}
    </TriggerLimitItemsWrapper>
  )
})

export default TriggerLimit
