import TextArea from 'antd/es/input/TextArea'
import styled from 'styled-components'
import Colors from 'styles/Colors'

interface StyledTextAreaProps {
  $isDark?: boolean
}

export const StyledTextArea = styled(TextArea)<StyledTextAreaProps>`
  min-height: 90px;
  padding: 12px;
  box-sizing: border-box;
  margin: 0;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.002em;
  color: ${({$isDark}) => ($isDark ? '#FFFFFF' : Colors.PRIMARY)};
  background-color: ${(props) => (props.$isDark ? `${Colors.COLOR_BLUE_LIGHT_OPACITY}` : '#FFFFFF')};
  border-color: ${(props) => (props.$isDark ? 'transparent' : `${Colors.PRIMARY}`)};
  resize: none;
  border-radius: 25px;
  &.lb-input {
    resize: none;
  }
  &::placeholder {
    color: ${({$isDark}) => ($isDark ? '#FFFFFF' : '#1D1D1D')};
  }
`
