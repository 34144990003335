import {Col, Row} from 'antd'
import Form from 'lib/components/Form'
import TextArea from 'lib/components/TextArea'
import {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import {getFormattedProperties, getProductRequestObj, updateProductRequestObj} from './utils'
import {FormTypes} from 'lib/components/Form/Form'
import CategoryForm, {CategoryFormRefType} from './CategoryForm'
import {cloneDeep} from 'lodash'
import {valueType} from 'antd/es/statistic/utils'
import {toast} from 'react-hot-toast'
import isEmptyOrSpaces from 'utils/EmptyOrBlank'

export interface ProductRefType {
  validate(): boolean
  getProductDetails(): any
}

interface initialStateType {
  productCode: string
  partnerCode: string
  skuCode: string
  productName: string
  productDescription: string
}

interface ProductBasicDetailsFormProps {
  isEdit: boolean
  partnerCodeOptions: FormTypes.Options[]
}

// eslint-disable-next-line no-empty-pattern
const ProductBasicDetailsForm = forwardRef<ProductRefType, ProductBasicDetailsFormProps>(({isEdit, partnerCodeOptions}, ref) => {
  let productData = getProductRequestObj

  const initialState: initialStateType = {
    productCode: productData.productCode || '',
    productName: productData.productName || '',
    partnerCode: productData.partnerCode || '',
    skuCode: productData.skuCode || '',
    productDescription: productData.productDescription || '',
  }
  const errorInititalState = {
    productName: false,
    skuCode: false,
  }
  const [state, setInitialState] = useState(initialState)
  const [error, setError] = useState(errorInititalState)
  const categoryFormRef = useRef<CategoryFormRefType>(null)

  const handleValidate = (): boolean => {
    let isValid = true
    if (!state.productName || isEmptyOrSpaces(state.productName)) {
      isValid = false
      setError({
        ...error,
        productName: true,
      })
      toast.error('Please Enter a Product Name')
    } else if (!categoryFormRef.current?.validate()) {
      isValid = false
      toast.error('Please select a valid category or category value')
    }

    return isValid
  }

  const getProductDetails = () => {
    let attributes = categoryFormRef.current?.getAttributes()
    if (attributes) {
      attributes = getFormattedProperties(attributes)
    }
    return {...state, properties: attributes}
  }

  useImperativeHandle(ref, () => ({validate: handleValidate, getProductDetails}))

  const handleChange = (value: valueType, key: string) => {
    const prevState = cloneDeep(state)

    productData = {
      ...productData,
      [key]: value,
    }

    if (key === 'partnerCode') {
      categoryFormRef.current?.resetCategories()
    }
    updateProductRequestObj(productData)
    setInitialState({
      ...prevState,
      [key]: value,
    })
  }

  return (
    <>
      <Row style={{marginBottom: '16px'}}>
        <Col span={8}>
          <Form.Input
            id="productCode"
            name="productCode"
            label="Product ID"
            placeholder="Product ID"
            value={state.productCode}
            onChange={handleChange}
            disabled
          />
        </Col>
      </Row>
      <Row style={{marginBottom: '16px'}}>
        <Col span={8}>
          <Form.Select
            id="partnerCode"
            options={partnerCodeOptions}
            label="Partner Name"
            value={state.partnerCode || getProductRequestObj.partnerCode}
            onChange={handleChange}
            disabled={true}
          />
        </Col>
      </Row>
      <Row style={{marginBottom: '16px'}}>
        <Col span={8}>
          <Form.Input
            id="skuCode"
            name="skuCode"
            label="Product SKU"
            placeholder="Product SKU"
            value={state.skuCode}
            onChange={handleChange}
            maxLength={20}
            disabled={isEdit}
            // status={error.skuCode ? 'error' : undefined}
            // disabled={isRewardAndPartnerDisabled || isOtherThenToggleAndEndDateDisabled}
          />
        </Col>
      </Row>
      <Row style={{marginBottom: '16px'}}>
        <Col span={8}>
          <Form.Input
            id="productName"
            name="productName"
            label="Product Name"
            placeholder="Product Name"
            value={state.productName}
            onChange={handleChange}
            status={error.productName ? 'error' : undefined}
            // disabled={isRewardAndPartnerDisabled || isOtherThenToggleAndEndDateDisabled}
            maxLength={100}
          />
        </Col>
      </Row>
      <Row style={{marginBottom: '16px'}}>
        <Col span={24}>
          <TextArea
            id="productDescription"
            name="productDescription"
            label="Description"
            placeholder="Description"
            value={state.productDescription}
            handleChange={handleChange}
            // disabled={isRewardAndPartnerDisabled || isOtherThenToggleAndEndDateDisabled}
            maxLength={500}
          />
        </Col>
      </Row>
      {state.partnerCode && <CategoryForm partnerCode={state.partnerCode} ref={categoryFormRef} />}
    </>
  )
})

export default ProductBasicDetailsForm
