import {RouteObject} from 'react-router-dom'

import {ErrorBoundry} from '../common/components/Routes/Fallback'
import MemberRoutes from './memberRoutes'
import ExportImportRoutes from './exportImportRoutes'
import ProgramConfigurationRoutes from './programConfigurationRoutes'
import PromotionsRoutes from './promotionsRoutes'
import ReportingRoutes from './reportingRoutes'
import UserManagementRoutes from './userManagementRoutes'

import NoMatch from '../common/components/NoMatch'
import RewardsRoutes from './rewardRoutes'
import SegmentationRoutes from './segmentationRoutes'
import Dashboard from 'app/pages/Dashboard'
import Layout from 'app/common/components/Layout'
import DashboardRoutes from './dashboardRoutes'

const AdminRoutes: RouteObject = {
    path: '',
    element: <Layout />,
    errorElement: <ErrorBoundry />,
    children: [
      { index: true, element: <Dashboard /> },
      DashboardRoutes,
      PromotionsRoutes,
      MemberRoutes,
      RewardsRoutes,
      SegmentationRoutes,
      UserManagementRoutes,
      ProgramConfigurationRoutes,
      ReportingRoutes,
      ExportImportRoutes,
      {path: '*', element: <NoMatch />},
    ],
  }

export default AdminRoutes
