import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import Typography from 'lib/components/Typography'
import Appconfig from 'app/common/helpers/AppConfig'
import {useEffect, useRef, useState} from 'react'
import {isEmpty} from 'lodash'
import {ActivityLogTypes} from 'types/ActivityLogTypes'
import ActivityLogFilter, {ResetActivityLogFormRefType} from './ActivityLogFilter'
import SearchResults from './SearchResults'
import AcitivityService from 'app/services/ActivityService'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {ACTIVITY_LOG_SEARCH, ACTIVITY_LOG_VIEW} from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);
//import { sampleActivityData } from '../utils'
//import {pointsResult} from '../AddNewPoint/util'

// import { Modal } from 'antd'

const ActivityLog = () => {
  const initialResponse: ResponseTypes.PagedResponse<ActivityLogTypes.ActivityLogResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: 100,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: 100,
    PageNumber: 1,
    SortColumn: 'updatedDate',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)
  const {roles} = useAuth()
  const showSearch = roles.includes(ACTIVITY_LOG_SEARCH)
  const showTable = roles.includes(ACTIVITY_LOG_VIEW)
  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const initialSearchParms: ActivityLogTypes.ActivityLogSearchParam = {}
  const [isFetching, setIsFetching] = useState(true)
  const [isFormReset, setIsFormReset] = useState(true)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [activityLogData, setActivityLogData] = useState<ResponseTypes.PagedResponse<ActivityLogTypes.ActivityLogResponseBL[]>>(initialResponse)
  const pointFormRef = useRef<ResetActivityLogFormRefType>(null)
  const handleSearch = (params: ActivityLogTypes.ActivityLogSearchParam) => {
    const reqParam: ActivityLogTypes.ActivityLogSearchParam = {
      // IsCombinable: false,
      ...params,
    }
    console.log(reqParam)
    setIsFetching(true)
    //  setIsFetching(false)
    //setActivityLogData({...activityLogData, data: });
    AcitivityService.getActivities(reqParam)
      .then((res) => {
        const data = res.data
        console.log('data', data)
        setActivityLogData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...paginationObj})
    // updateSegmentRequestObj(initialSegmentRequestObj)
  }, [])

  const handleFilter = (data: ActivityLogTypes.ActivityLogFilterState) => {
    const filterParms: ActivityLogTypes.ActivityLogSearchParam = {}

    filterParms.CreatedBy = data.modifiedBy || undefined
    filterParms.ModuleName = data.affectedModule
    filterParms.EntityId = data.uniqueId
    if (data.dateRange != null) {
      if (data.dateRange.length > 0) {
        const date = dayjs.utc(data.dateRange[0], 'MMM-DD-YYYY');
        const startOfDay = date.startOf('day').toString();
        filterParms.StartDateTime = startOfDay
      }
      if (data.dateRange.length > 1) {
        const date = dayjs.utc(data.dateRange[1], 'MMM-DD-YYYY');
        const endOfDay = date.endOf('day').toString();
        filterParms.EndDateTime = endOfDay
      }
    }
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const handleResetSearch = () => {
    pointFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }

    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const {count = 0, pageIndex = 0} = activityLogData.pageInfo
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const renderActivityLogBody = () => {
    if (showSearch && showTable) {
      return (
        <DataTable>
          <ActivityLogFilter ref={pointFormRef} isFetching={isFetching} handleFilter={handleFilter} />
          <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch} showTable={showTable} />
          <SearchResults isFetching={isFetching} activityLogResponse={activityLogData} />
          <DataTable.Pagination
            onChange={handleOnChange}
            current={pageIndex}
            pageSizeOptions={pageSizeOptions}
            total={count}
            defaultPageSize={initialPaginationObj.PageCount}
            pageSize={paginationObj.PageCount}
            showSizeChanger
          />
        </DataTable>
      )
    }
    return <NoPermission />
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Activity Log</Typography.Title>
      </StyledHeader>
      {renderActivityLogBody()}
    </section>
  )
}

export default ActivityLog
