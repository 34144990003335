import {useRef, useState} from 'react'
import isEmpty from 'lodash/isEmpty'
import DataTable from 'lib/components/DataTable'
import Appconfig from 'app/common/helpers/AppConfig'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import Button from 'lib/components/Button'
import {normalizePhoneNo} from 'utils/common'

interface SearchResultProps {
  handleSearch(searchFields?: MemebershipTypes.MembershipDetail): void
  isFetching: boolean
  memberResponse: ResponseTypes.PagedResponse<MemebershipTypes.MembershipDataBL[]>
  rowClick(row: MemebershipTypes.MembershipDataBL): void
  handlePaginationChange: (page: number, pageSize: number) => void
  pageSizeOptions?: number[]
  paginationObj: PaginationType.PaginationDetails
  showSearch?: boolean
  showTable?: boolean
  showMemberDetail?: boolean
}

const searchColumns: DataTableTypes.Column[] = [
  {
    title: 'Member Name',
    id: 'MembershipFullName',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Account Id',
    id: 'MembershipCustomerId',
    textAlign: 'left',
    width: '20%',
  },

  {
    title: 'Status',
    id: 'MembershipStatusKey',
    textAlign: 'left',
    width: '18%',
  },
  {
    title: 'Email ID',
    id: 'MembershipEmailId',
    textAlign: 'left',
    width: '18%',
    wrapText: true,
  },
  {
    title: 'Phone No.',
    id: 'MembershipPhone',
    textAlign: 'left',
    width: '18%',
  },
]
const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Member Name',
    id: 'name',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Account ID',
    id: 'accountId',
    textAlign: 'left',
    width: '20%',
  },

  {
    title: 'Status',
    id: 'status',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Email ID',
    id: 'emailId',
    textAlign: 'left',
    width: '23%',
    wrapText: true,
  },
  {
    title: 'Phone No.',
    id: 'phone',
    textAlign: 'left',
    width: '14%',
  },
]

const SearchResult: React.FC<SearchResultProps> = ({
  handleSearch,
  rowClick,
  memberResponse,
  isFetching,
  pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS,
  handlePaginationChange,
  paginationObj,
  showSearch,
  showTable,
  showMemberDetail,
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const [isFormReset, setIsFormReset] = useState(true)
  const handleFormSearch = (searchData?: MemebershipTypes.MembershipDetail) => {
    const hasSearchData = isEmpty(searchData)
    setIsFormReset(hasSearchData)
    if (!hasSearchData) {
      handleSearch({...searchData, PageNumber: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER})
    }
  }
  const handleOnChange = (page: number, pageSize: number) => {
    const formData = new FormData(formRef.current ?? undefined)
    const fieldValues = Object.fromEntries(formData.entries())
    const filledObj: {[k: string]: FormDataEntryValue} = {}
    Object.keys(fieldValues).map((key) => {
      if (fieldValues[key]) {
        filledObj[key] = fieldValues[key]
      }
      return null
    })

    if (pageSize !== Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE || page !== Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filledObj)
      setIsFormReset(hasSearchData)
    }

    handleSearch({
      ...(filledObj || {}),
      PageCount: pageSize,
      PageNumber: page,
    })
    handlePaginationChange(page, pageSize)
  }

  const getNameComponent = (title: string) => <span title={title}>{title}</span>

  const generateRowData = (): DataTableTypes.Row[] => {
    if (memberResponse && memberResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = memberResponse
      data.map((item) => {
        const obj: DataTableTypes.Row = {
          name: getNameComponent(item.membershipFullName ?? '-'),
          accountId: item.membershipCustomerId ?? '-',

          status: item.membershipStatusKey ?? '-',
          emailId: item.membershipEmailId ?? '-',
          phone: normalizePhoneNo(item.membershipPhone) ?? '-',
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const handleResetSearch = () => {
    if (!isFormReset) {
      setIsFormReset(!isFormReset)
      formRef.current?.reset()

      const newPaginationObj = {
        PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
        PageNumber: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER,
      }
      handlePaginationChange(Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER, Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE)
      handleSearch(newPaginationObj)
    } else {
      formRef.current?.reset()
    }
    console.log(formRef.current)
  }

  const handleRowClick = (index: number) => {
    if (memberResponse) {
      rowClick(memberResponse.data[index])
    }
  }

  const renderResetSearch = () => {
    return (
      <Button color="primary" onClick={handleResetSearch} type="text">
        Reset Search
      </Button>
    )
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  const {totalPages, pageIndex, pageSize, count} = memberResponse.pageInfo
  return (
    <DataTable>
      {showSearch && showTable && (
        <DataTable.Search ref={formRef} isFetching={isFetching} handleSearch={handleFormSearch} columns={searchColumns} isDark={true} />
      )}
      <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
      {showTable && (
        <div>
          <DataTable.Header columns={dataTableColumns} />
          <DataTable.Rows
            noRecordFoundComponent={renderNoRecordFound()}
            loading={isFetching}
            columns={dataTableColumns}
            rows={generateRowData()}
            rowActions={{rowClick: handleRowClick}}
            isClickable={showMemberDetail}
          />

          <DataTable.Pagination
            onChange={handleOnChange}
            current={pageIndex}
            pageSizeOptions={pageSizeOptions}
            total={totalPages * pageSize}
            defaultPageSize={Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER}
            pageSize={paginationObj.PageCount}
            showSizeChanger
          />
        </div>
      )}
    </DataTable>
  )
}

export default SearchResult
