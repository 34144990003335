import {BtnProps} from './Button'
import {StyledButton} from './Button.Styled'

const Button: React.FC<BtnProps> = (props) => {
  return (
    <StyledButton {...props}>
      {props.$startIcon && <span className="lb-btn-start-icon">{props.$startIcon}</span>}
      {props.children}
      {props.$endIcon && <span className="lb-btn-end-icon">{props.$endIcon}</span>}
    </StyledButton>
  )
}

export default Button
