import {getSessionToken} from 'app/services/LoginService'
import axios, {AxiosResponse} from 'axios'
import {handleHttpErrors} from '.'
import {HttpRequestGenericError, HttpRequestType} from './types'
import {setRedirectPath} from 'utils/common'

const redirectForAuth = () => {
  setRedirectPath(window.location.pathname)
  window.location.pathname = '/login'
}
export const HttpRequestUpload = async ({url = '', data, showActual = false, ...config}: HttpRequestType): Promise<AxiosResponse<any, any>> => {
  if (getSessionToken()) {
    config.headers = {
      'content-type': 'multipart/form-data',
      'x-api-key': window.env.X_API_KEY,
      Authorization: `Bearer ${getSessionToken()}`,
    }
  } else {
    redirectForAuth()
  }
  console.log(config)
  try {
    const response = await axios.post(url, data, config)
    return Promise.resolve(response)
  } catch (error) {
    const errors = error as HttpRequestGenericError
    if (!showActual) {
      handleHttpErrors(errors, showActual)
    }
    return Promise.reject(errors)
  }
}
