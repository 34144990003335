import {Col, Row} from 'antd'
import Form from 'lib/components/Form'
import TextArea from 'lib/components/TextArea'
import {forwardRef, useImperativeHandle, useState} from 'react'
import {
  activityRuleDefaultValue,
  genderOptions,
  getSegmentRequestObj,
  initialSegmentRequestObj,
  segmentCategoryOptions,
  stateToggleOptions,
  updateSegmentRequestObj,
} from './utils'
import {toast} from 'react-hot-toast'
import {AddMembersWrapper, CheckboxMarginContainer, GenderCheckboxWrapper, MemberTabsDetailsWrapper} from '../Segment.styled'
import Typography from 'lib/components/Typography'
import Tabs from 'app/common/components/Tabs'
import ToggleButton from 'lib/components/ToggleButton'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import {getZipcodes, states} from 'utils/common'
import {FormTypes} from 'lib/components/Form/Form'
import Slider from 'lib/components/Slider'
import {StyledCol} from 'app/pages/Promotions/CreatePromotion/Steps/Steps.styled'
import dayjs from 'dayjs'
import {handleDisableToDate} from 'utils/DatepickerUtils'
import {useParams} from 'react-router-dom'
import {AddNewSegmentParam} from '.'
import Appconfig from 'app/common/helpers/AppConfig'

//import PartnerService from 'app/services/PartnerService'

export interface SegmentRefType {
  validate(): boolean
}

const noDateCheckboxOptions: FormTypes.Options[] = [
  {
    label: 'No End Date',
    value: 'isChecked',
  },
]

interface InitialStateType {
  partnerCode: string
  segmentCode: string
  segmentName: string
  segmentDescription: string
  isAll: boolean
  state: string[]
  zipcode: string[]
  minAge: number
  maxAge: number
  gender: FormTypes.OptionValueType[]
  startDate: SegmentationTypes.DateTimeStringType
  endDate: SegmentationTypes.DateTimeStringType
  segmentCategory: string
  segmentType: string
}

interface NewSegmentFormProps {
  partnerCodeOptions: FormTypes.Options[]
  handleUpdateSave(type: number): void
}

const NewSegmentForm = forwardRef<SegmentRefType, NewSegmentFormProps>(({partnerCodeOptions, handleUpdateSave}, ref) => {
  let segmentData = getSegmentRequestObj
  const {segmentId} = useParams<AddNewSegmentParam>()
  const actualSegmentId = atob(segmentId || '')
  const {activityRule, demographicRule} = segmentData.segmentRuleData || {activityRule: null, demographicRule: null}

  const initialState: InitialStateType = {
    partnerCode: segmentData.partnerCode,
    segmentCode: segmentData.segmentCode,
    segmentName: segmentData.segmentName,
    segmentType: segmentData.segmentType,
    segmentDescription: segmentData.segmentDescription,
    segmentCategory: segmentData.segmentCategory,
    isAll: demographicRule ? demographicRule.isAll : false,
    state: demographicRule && demographicRule.type === 'STATE' ? demographicRule.values : [],
    zipcode: demographicRule && demographicRule.type === 'ZIPCODE' ? demographicRule.values : [],
    minAge: demographicRule ? demographicRule.min : 0,
    maxAge: demographicRule ? demographicRule.max : 0,
    gender: demographicRule && demographicRule.type === 'GENDER' ? demographicRule.values : [],
    startDate: activityRule && activityRule.startDate ? activityRule.startDate : null,
    endDate: activityRule && activityRule.endDate ? activityRule.endDate : null,
  }
  const errorInitialState = {
    segmentCode: false,
    segmentName: false,
    segmenDescription: false,
  }

  const getActiveParentTab = (): number => {
    switch (segmentData.segmentType) {
      case 'ACTIVITY':
        return 1
      case 'MANUAL':
        return 2
      default:
        return 0
    }
  }

  const getActiveChildTab = (): number => {
    if (segmentData.segmentType === 'DEMOGRAPHIC' && demographicRule) {
      switch (demographicRule.type) {
        case 'STATE':
          return 0
        case 'ZIPCODE':
          return 1
        case 'AGE':
          return 2
        default:
          return 3
      }
    } else if (segmentData.segmentType === 'ACTIVITY' && activityRule) {
      switch (activityRule.type) {
        case 'PURCHASE':
          return 0
        case 'ENROLLMENT':
          return 1
      }
    }
    return 0
  }
  const [state, setInitialState] = useState(initialState)

  const isNewSegment = actualSegmentId === 'new'
  let isEndDateDisabeld = false
  let isCheckboxChecked = false
  if (isNewSegment) {
    isEndDateDisabeld = false
    isCheckboxChecked = false
  } else {
    if (!state.startDate) {
      isEndDateDisabeld = false
      isCheckboxChecked = false
    } else {
      isEndDateDisabeld = state.endDate ? false : true
      isCheckboxChecked = state.endDate ? false : true
    }
  }

  const [error, setErrorState] = useState(errorInitialState)
  const [activeTabId, setActiveTabId] = useState(getActiveParentTab())
  const [activeDemographicTab, setDemoTab] = useState(getActiveChildTab())
  const [disabledEndDate, setDisableEndDate] = useState(isEndDateDisabeld)
  const [isChecked, setIsChecked] = useState(isCheckboxChecked ? 'isChecked' : '')
  let zipCodeOptions = getZipcodes()
  const handleValidate = () => {
    let isValid = true
    if (!state.segmentName) {
      isValid = false
      setErrorState({...error, segmentName: true})
      toast.error('Segment Name is required')
    } else if (demographicRule) {
      if (!demographicRule.isAll && !demographicRule.values.length && demographicRule.type !== 'AGE') {
        isValid = false
        toast.error('Please select at least one state.')
      } else if (demographicRule.type === 'ZIPCODE' && !demographicRule.values.length) {
        isValid = false
        toast.error('Please select at least one zipcode.')
      } else if (demographicRule.type === 'GENDER' && !demographicRule.values.length) {
        isValid = false
        toast.error('Please select at least one gender.')
      } else if (demographicRule.type === 'AGE' && (!demographicRule.max || !demographicRule.min)) {
        isValid = false
        toast.error('Min or max value should not be zero.')
      }
    } else if (activityRule) {
      if (!activityRule.startDate && !activityRule.endDate) {
        isValid = false
        toast.error('Please select a Start Date.')
      } else if (actualSegmentId === 'new' && !activityRule.endDate && isCheckboxChecked) {
        isValid = false
        toast.error('Please select an End Date.')
      } else if (activityRule.startDate && activityRule.endDate) {
        if (dayjs(activityRule.startDate) > dayjs(activityRule.endDate)) {
          isValid = false
          toast.error('Please select an End Date greater than start date.')
        }
      }
    }
    return isValid
  }

  useImperativeHandle(ref, () => ({validate: handleValidate}))

  const handleChange = (value: FormTypes.ValueType, id: string) => {
    const prevState = {...state}
    if (id === 'zipcode') {
      value = value as string[]
      let updatedValues = [...value]
      const zipCodeList = [{value: 'all', label: 'All'}, ...zipCodeOptions]

      let actualValues = updatedValues.filter((item) => item !== 'all')
      if (value.includes('all') && value[value.length - 1] === 'all') {
        updatedValues = zipCodeList.map((item) => item.value as string)
      } else if (value.includes('all') && value[0] === 'all') {
        updatedValues = actualValues
      } else if (!value.length || (prevState.zipcode.includes('all') && value.length === prevState.zipcode.length - 1)) {
        updatedValues = []
      } else if (!value.includes('all') && value.length === zipCodeList.length - 1) {
        updatedValues = ['all', ...updatedValues]
      }
      actualValues = updatedValues.filter((item) => item !== 'all')
      value = updatedValues
    }
    setInitialState({
      ...prevState,
      [id]: value,
    })
    if (id === 'state' || id === 'zipcode') {
      const demographicData = {
        isAll: id === 'zipcode' ? false : !!demographicRule?.isAll,
        type: id.toUpperCase(),
        min: 0,
        max: 0,
        values: id === 'zipcode' ? (value as string[]).filter((item) => item !== 'all') : (value as string[]),
      }
      segmentData = {
        ...segmentData,
        segmentRuleData: {
          demographicRule: demographicData,
          activityRule: null,
        },
      }
    } else {
      segmentData = {
        ...segmentData,
        [id]: value,
      }
    }
    if (id === 'segmentCategory' && segmentData.segmentType.toUpperCase() === 'MANUAL') {
      const demographicData = {
        type: 'STATE',
        isAll: true,
        values: [],
        min: 0,
        max: 0,
      }
      segmentData = {
        ...segmentData,
        segmentType: 'DEMOGRAPHIC',
        segmentRuleData: {
          demographicRule: demographicData,
          activityRule: null,
        },
      }
      updateSegmentRequestObj(segmentData)
      setActiveTabId(0)
    }
    updateSegmentRequestObj(segmentData)
  }

  const setDefaultState = () => {
    const defaultState = {
      ...state,
      isAll: true,
      state: [],
      zipcode: [],
      minAge: 0,
      maxAge: 0,
      gender: [],
      startDate: null,
      endDate: null,
    }
    setInitialState(defaultState)
  }

  const handleOnTabChange = (tabId: number) => {
    segmentData = {
      ...segmentData,
      segmentType: tabId === 0 ? 'DEMOGRAPHIC' : tabId === 1 ? 'ACTIVITY' : 'MANUAL',
      segmentRuleData:
        tabId === 2
          ? null
          : {
              demographicRule: tabId === 0 ? initialSegmentRequestObj.segmentRuleData?.demographicRule : null,
              activityRule: tabId === 1 ? activityRuleDefaultValue : null,
            },
    }
    updateSegmentRequestObj(segmentData)

    setActiveTabId(tabId)
    setDefaultState()
    setDemoTab(0)
    handleUpdateSave(tabId)
  }

  const handleOnDemoTabChange = (tabId: number, type: string) => {
    setDemoTab(tabId)
    const demographicData: SegmentationTypes.DemographicsType | null =
      activeTabId === 0
        ? {
            isAll: true,
            type,
            min: 0,
            max: 0,
            values: [],
          }
        : null
    const activityData =
      activeTabId === 1
        ? {
            type,
            startDate: '',
            endDate: '',
          }
        : null

    segmentData = {
      ...segmentData,
      segmentRuleData: {
        demographicRule: demographicData,
        activityRule: activityData,
      },
    }
    updateSegmentRequestObj(segmentData)
    setDefaultState()
  }

  const handleSetState = (value: boolean, id: string) => {
    const prevState = {...state}
    setInitialState({
      ...prevState,
      isAll: value,
      state: value ? [] : state.state,
    })
    const demographicData = {
      type: 'STATE',
      isAll: value,
      values: value ? [] : state.state,
      min: 0,
      max: 0,
    }
    segmentData = {
      ...segmentData,
      segmentRuleData: {
        demographicRule: demographicData,
        activityRule: null,
      },
    }
    updateSegmentRequestObj(segmentData)
  }

  const handleRangeChange = (value: number[], id: string) => {
    if (value[1] >= 1 && value[1] - value[0] >= 1) {
      const prevState = {...state}
      setInitialState({
        ...prevState,
        minAge: value[0] < value[1] && value[1] - value[0] > 1 ? value[0] : value[1] - 1,
        maxAge: value[1] >= 1 ? value[1] : 1,
      })
      const demographicsData = {
        isAll: false,
        type: 'AGE',
        min: value[0],
        max: value[1],
        values: [],
      }
      segmentData = {
        ...segmentData,
        segmentRuleData: {
          demographicRule: demographicsData,
          activityRule: null,
        },
      }
      updateSegmentRequestObj(segmentData)
    }
  }

  const handleGenderChange = (value: FormTypes.OptionValueType[], key: string) => {
    const prevState = {...state}
    const demographicsData = {
      isAll: false,
      type: 'GENDER',
      min: 0,
      max: 0,
      values: value && !value.length ? [] : (value as string[]),
    }
    setInitialState({
      ...prevState,
      gender: value && !value.length ? [] : value,
    })
    segmentData = {
      ...segmentData,
      segmentRuleData: {
        demographicRule: demographicsData,
        activityRule: null,
      },
    }
    updateSegmentRequestObj(segmentData)
  }

  const handleOnDateChange = (date: string | null, id: keyof SegmentationTypes.ActivityDetailsType) => {
    const prevState = {...state}
    setInitialState({
      ...prevState,
      [id]: date,
    })

    if (!date) {
      return
    }
    const dateTimeString =
      id === 'startDate' ? dayjs(new Date(date)).format(Appconfig.DATE_TIME_SERVER) : dayjs(date).endOf('day').format(Appconfig.DATE_TIME_SERVER)
    const activityData = {
      type: activeTabId === 1 && activeDemographicTab === 0 ? 'PURCHASE' : 'ENROLLMENT',
      startDate: id === 'startDate' ? dateTimeString : segmentData.segmentRuleData?.activityRule?.startDate || null,
      endDate: id === 'endDate' ? dateTimeString : segmentData.segmentRuleData?.activityRule?.endDate || null,
    }
    segmentData = {
      ...segmentData,
      segmentRuleData: {
        demographicRule: null,
        activityRule: activityData,
      },
    }
    updateSegmentRequestObj(segmentData)
  }

  const onNoDataClick = (value: string[], key: string) => {
    const checkedValue = value[0]
    setDisableEndDate(!disabledEndDate)
    const activityData = {
      type: activeTabId === 1 && activeDemographicTab === 0 ? 'PURCHASE' : 'ENROLLMENT',
      startDate: segmentData.segmentRuleData?.activityRule?.startDate || null,
      endDate: null,
    }
    segmentData = {
      ...segmentData,
      segmentRuleData: {
        demographicRule: null,
        activityRule: activityData,
      },
    }
    updateSegmentRequestObj(segmentData)
    setInitialState({...state, endDate: null})
    setIsChecked(checkedValue)
  }

  const renderGenderDetails = () => {
    return (
      <MemberTabsDetailsWrapper>
        <MarginBottomWrapper>
          <Typography.Title level={5}>Select Gender(s)</Typography.Title>
        </MarginBottomWrapper>
        <GenderCheckboxWrapper>
          <Form.Checkbox id="gender" value={state.gender} options={genderOptions} onChange={handleGenderChange} />
        </GenderCheckboxWrapper>
      </MemberTabsDetailsWrapper>
    )
  }

  const renderAgeRangeDetails = () => {
    return (
      <MemberTabsDetailsWrapper>
        <MarginBottomWrapper>
          <Typography.Title level={5}>Select Age Range</Typography.Title>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Col span={4} style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <Typography.Title level={4} uppercase>
                Min
              </Typography.Title>
              <Typography.Text>{state.minAge}</Typography.Text>
            </div>
            <div>
              <Typography.Title level={4} uppercase>
                Max
              </Typography.Title>
              <Typography.Text>{state.maxAge}</Typography.Text>
            </div>
          </Col>
          <Col span={5}>
            <Slider range value={[state.minAge, state.maxAge]} id="ageRange" handleChange={handleRangeChange} />
          </Col>
        </MarginBottomWrapper>
      </MemberTabsDetailsWrapper>
    )
  }

  const renderZipcodeDetails = () => {
    return (
      <MemberTabsDetailsWrapper>
        <MarginBottomWrapper>
          <Typography.Title level={5}>Select Zip Code(s)</Typography.Title>
        </MarginBottomWrapper>
        <Row>
          <Col span={6}>
            <Form.Select
              id="zipcode"
              mode="multiple"
              value={state.zipcode}
              options={[{value: 'all', label: 'All'}, ...zipCodeOptions]}
              onChange={handleChange}
              filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Col>
        </Row>
      </MemberTabsDetailsWrapper>
    )
  }

  const renderStateDetails = () => {
    return (
      <MemberTabsDetailsWrapper>
        <MarginBottomWrapper>
          <Typography.Title level={5}>Select State(s)</Typography.Title>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <ToggleButton id="states" value={state.isAll} values={stateToggleOptions} handleChange={handleSetState}></ToggleButton>
        </MarginBottomWrapper>
        {!state.isAll && (
          <Row>
            <Col span={6}>
              <Form.Select
                id="state"
                mode="multiple"
                value={state.state}
                options={states}
                onChange={handleChange}
                filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Col>
          </Row>
        )}
      </MemberTabsDetailsWrapper>
    )
  }

  const renderActivityDetails = (activityType: string) => {
    const {startDate, endDate} = state
    return (
      <MemberTabsDetailsWrapper>
        <Row gutter={40}>
          <StyledCol span={7}>
            <Form.DatePicker
              label="Start Date"
              placeholder="Select Start Date"
              id="startDate"
              value={startDate}
              onChange={handleOnDateChange}
              // status={startDateEror ? 'error' : undefined}
              // disabled={isStartDateDisabel}
            />
          </StyledCol>
          <StyledCol span={7}>
            <Form.DatePicker
              disabledDate={(current: dayjs.Dayjs) => handleDisableToDate(current, startDate, endDate)}
              label="End Date"
              placeholder="Select End Date"
              id="endDate"
              value={endDate}
              onChange={handleOnDateChange}
              disabled={disabledEndDate}
              // status={endDateError ? 'error' : undefined}
            />
          </StyledCol>
          <Col>
            <CheckboxMarginContainer>
              <Form.Checkbox id="noenddate" options={noDateCheckboxOptions} value={[isChecked]} onChange={onNoDataClick} />
            </CheckboxMarginContainer>
          </Col>
        </Row>
      </MemberTabsDetailsWrapper>
    )
  }

  const renderDemographicContent = () => {
    return (
      <MemberTabsDetailsWrapper>
        <Tabs>
          <Tabs.Tab
            label="State"
            id={0}
            color="#02a19a"
            onTabChange={(tabId: number) => handleOnDemoTabChange(tabId, 'STATE')}
            activeTabId={activeDemographicTab}
          />
          <Tabs.Tab
            label="Zipcode"
            id={1}
            color="#02a19a"
            onTabChange={(tabId: number) => handleOnDemoTabChange(tabId, 'ZIPCODE')}
            activeTabId={activeDemographicTab}
          />
          <Tabs.Tab
            label="Age Range"
            id={2}
            color="#02a19a"
            onTabChange={(tabId: number) => handleOnDemoTabChange(tabId, 'AGE')}
            activeTabId={activeDemographicTab}
          />
          <Tabs.Tab
            label="Gender"
            id={3}
            color="#02a19a"
            onTabChange={(tabId: number) => handleOnDemoTabChange(tabId, 'GENDER')}
            activeTabId={activeDemographicTab}
          />
        </Tabs>
        {activeDemographicTab === 0 && renderStateDetails()}
        {activeDemographicTab === 1 && renderZipcodeDetails()}
        {activeDemographicTab === 2 && renderAgeRangeDetails()}
        {activeDemographicTab === 3 && renderGenderDetails()}
      </MemberTabsDetailsWrapper>
    )
  }

  const renderActivityContent = () => {
    return (
      <MemberTabsDetailsWrapper>
        <Tabs>
          <Tabs.Tab
            label="Purchase"
            id={0}
            color="#02a19a"
            onTabChange={(tabId: number) => handleOnDemoTabChange(tabId, 'PURCHASE')}
            activeTabId={activeDemographicTab}
          />
          <Tabs.Tab
            label="Enrollment"
            id={1}
            color="#02a19a"
            onTabChange={(tabId: number) => handleOnDemoTabChange(tabId, 'ENROLLMENT')}
            activeTabId={activeDemographicTab}
          />
        </Tabs>
        {activeDemographicTab === 0 && renderActivityDetails('PURCHASE')}
        {activeDemographicTab === 1 && renderActivityDetails('ENROLLMENT')}
      </MemberTabsDetailsWrapper>
    )
  }

  const renderManualUploadDetails = () => {
    return <MemberTabsDetailsWrapper>
      <div style={{ width: '50%' }}>
        <Typography.Text>
          {`Once Manual Upload Segment is created,
          kindly Import your file using the Upload tool
        (Program Configuration > Import Data > Import New Data > Data Type = ‘Segment’)`}
        </Typography.Text>
      </div>
    </MemberTabsDetailsWrapper>
  }

  return (
    <>
      <Row style={{marginBottom: '16px'}}>
        <Col span={8}>
          <Form.Input
            id="segmentCode"
            name="segmentCode"
            label="Segment Id"
            placeholder="Segment Id"
            value={state.segmentCode}
            onChange={handleChange}
            status={error.segmentCode ? 'error' : undefined}
            disabled
            maxLength={50}
          />
        </Col>
      </Row>
      <Row style={{marginBottom: '16px'}}>
        <Col span={8}>
          <Form.Select
            id="partnerCode"
            options={partnerCodeOptions}
            label="Partner Name"
            value={state.partnerCode || segmentData.partnerCode}
            onChange={handleChange}
            // disabled={isRewardAndPartnerDisabled || isOtherThenToggleAndEndDateDisabled}
          />
        </Col>
      </Row>
      <Row style={{marginBottom: '16px'}}>
        <Col span={8}>
          <Form.Input
            id="segmentName"
            name="segmentName"
            label="Segment Name"
            placeholder="Segment Name"
            value={state.segmentName}
            onChange={handleChange}
            status={error.segmentName ? 'error' : undefined}
            // disabled={isRewardAndPartnerDisabled || isOtherThenToggleAndEndDateDisabled}
            maxLength={50}
          />
        </Col>
      </Row>
      <Row style={{marginBottom: '16px'}}>
        <Col span={24}>
          <TextArea
            id="segmentDescription"
            name="segmentDescription"
            label="Description"
            placeholder="Description"
            value={state.segmentDescription}
            handleChange={handleChange}
            status={error.segmenDescription ? 'error' : undefined}
            // disabled={isRewardAndPartnerDisabled || isOtherThenToggleAndEndDateDisabled}
            maxLength={50}
          />
        </Col>
      </Row>
      <MarginBottomWrapper>
        <Row>
          <Col span={24}>
            <Form.Radio
              id="segmentCategory"
              label="Segment Type"
              value={state.segmentCategory}
              options={segmentCategoryOptions}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      <AddMembersWrapper>
        <Typography.Title level={4} color="primary" uppercase>
          SEGMENT CRITERIA
        </Typography.Title>
        <Tabs>
          <Tabs.Tab label="Demographic" id={0} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
          <Tabs.Tab label="Activity" id={1} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
          {state.segmentCategory === 'STATIC' && <Tabs.Tab label="Manual Upload" id={2} onTabChange={handleOnTabChange} activeTabId={activeTabId} />}
        </Tabs>
        {activeTabId === 0 && renderDemographicContent()}
        {activeTabId === 1 && renderActivityContent()}
        {activeTabId === 2 && renderManualUploadDetails()}
      </AddMembersWrapper>
    </>
  )
})

export default NewSegmentForm
