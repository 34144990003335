import {MainContainer} from 'app/common/components/Styled/common.styled'
import {PROMOTION_CREATE, PROMOTION_DETAIL, PROMOTION_EDIT, PROMOTION_REVIEW} from 'app/common/helpers/UserFunctions'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {Navigate, Outlet, useParams} from 'react-router-dom'
import {CreatePromotionParam} from '../CreatePromotion'

const PromotionsLayout: React.FC = () => {
  const {roles} = useAuth()

  const {promotionId} = useParams<CreatePromotionParam>()
  const actualPromotionId = atob(promotionId || '')

  if (window.location.pathname.includes('promotions/view/') && !roles.includes(PROMOTION_REVIEW) && !roles.includes(PROMOTION_DETAIL)) {
    return <Navigate replace to="/unauthorized" />
  } else if (actualPromotionId === 'new' && !roles.includes(PROMOTION_CREATE)) {
    return <Navigate replace to="/unauthorized" />
  } else if (
    actualPromotionId &&
    actualPromotionId !== 'new' &&
    !roles.includes(PROMOTION_EDIT) &&
    !roles.includes(PROMOTION_REVIEW) &&
    !roles.includes(PROMOTION_DETAIL)
  ) {
    return <Navigate replace to="/unauthorized" />
  }

  return (
    <MainContainer>
      <Outlet />
    </MainContainer>
  )
}

export default PromotionsLayout
