import {RolesFormBodyContainer} from '../../../UserManagementStyled'
import {ReviewDetailsItem, StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import Tabs from 'app/common/components/Tabs'
import {useState} from 'react'
import {UserDetailsWrapper} from '../../../UserManagementStyled'
import {UserInfoContainer} from '../../../UserManagementStyled'
import Typography from 'lib/components/Typography'
import {QuickActionsTile} from '../../../UserManagementStyled'
import Button from 'lib/components/Button'
import IconUserDeactivate from 'app/common/icons/IconUserDeactivate'
import FunctionDetails from './FunctionDetails'
import {getRolesRequestObj, updateRoleRequestObj} from '../../utils'
import UserDetails from './UserDetails'
import UserManagementService from 'app/services/UserManagementService'
import {toast} from 'react-hot-toast'
import {Modal} from 'antd'
import {MarginBottomWrapper, ModalTitle, StyledModalContent} from 'app/common/components/Styled/common.styled'
import LoadingPanel from 'lib/components/LoadingPanel'
import IconSuccess from 'app/common/icons/IconSuccess'

interface ReviewRoleProps {
  isViewOnly: boolean
  userList: UserManagementTypes.UserInfoType[]
  setUsers(users: UserManagementTypes.UserInfoType[]): void
  showEditRole: boolean
  showRoleUsers: boolean
  showRoleFunctions: boolean
  showRoleActivate: boolean
  showAssignFunction: boolean
  showRemoveUsers: boolean
}

const ReviewRoles: React.FC<ReviewRoleProps> = ({
  isViewOnly,
  userList,
  setUsers,
  showEditRole,
  showRoleUsers,
  showRoleFunctions,
  showRoleActivate,
  showAssignFunction,
  showRemoveUsers,
}) => {
  const roleReqObj = getRolesRequestObj
  const [activeTabId, setActiveTabId] = useState(showRoleFunctions ? 0 : showRoleUsers ? 1 : -1)
  const [loader, setLoader] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [removalType, setRemovalType] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [isProcessed, setIsProcessed] = useState(false)
  const [typeId, setTypeId] = useState('')
  const [name, setName] = useState('')
  const handleOnTabChange = (tabId: number) => {
    setActiveTabId(tabId)
  }

  const handleDeactivate = () => {
    roleReqObj.status = isDeactivated ? 'Active' : 'Deactive'
    setLoader(true)
    UserManagementService.saveRole(roleReqObj, {roleCode: roleReqObj.roleCode})
      .then((res) => {
        toast.success(`Role ${isDeactivated ? 'Activated' : 'Deactivated'} Successfully.`)
        updateRoleRequestObj(res.data.data)
      })
      .finally(() => {
        setLoader(false)
      })
  }
  const isDeactivated = roleReqObj.status.toUpperCase() === 'Deactive'.toUpperCase()

  const handleDeleteModal = (type: string, id: string, name: string) => {
    setRemovalType(type)
    setTypeId(id)
    setName(name)
    setOpenRemoveModal(true)
  }

  const handleCancelModal = () => {
    setOpenRemoveModal(false)
    setIsProcessed(false)
  }

  const handleOk = () => {
    setIsProcessing(true)
    if (removalType === 'USER') {
      UserManagementService.deleteUserRole({userName: typeId, roleCode: roleReqObj.roleCode})
        .then((res) => {
          setUsers(userList.filter((item) => item.userName !== typeId))
          setIsProcessed(true)
        })
        .finally(() => {
          setIsProcessing(false)
        })
    } else if (removalType === 'FUNCTION') {
      UserManagementService.deleteRoleFunction({functionCode: typeId, roleCode: roleReqObj.roleCode})
        .then((res) => {
          const updatedFuncList = roleReqObj.functions.filter((item) => item.functionCode !== typeId)
          updateRoleRequestObj({
            ...roleReqObj,
            functions: updatedFuncList,
          })
          setIsProcessed(true)
        })
        .finally(() => {
          setIsProcessing(false)
        })
    }
  }

  const getModalContent = () => {
    if (isProcessing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (isProcessed) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              {removalType === 'USER' ? 'User' : 'Function'} Removed
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">
              {name} has been removed from {roleReqObj.roleName}
            </Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="back" onClick={handleCancelModal}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">Are you sure you want to remove {name} from this role?</Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Remove
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  return (
    <RolesFormBodyContainer>
      <UserDetailsWrapper>
        <UserInfoContainer>
          <ReviewDetailsItem>
            <StyledReviewLabel>Role Status</StyledReviewLabel>
            <StyledReviewValue>{roleReqObj.status}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Role ID</StyledReviewLabel>
            <StyledReviewValue>{roleReqObj.roleCode}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Role Name</StyledReviewLabel>
            <StyledReviewValue>{roleReqObj.roleName}</StyledReviewValue>
          </ReviewDetailsItem>
        </UserInfoContainer>
        {!isViewOnly ||
          (showRoleActivate && (
            <UserInfoContainer>
              <Typography.Title level={2}>Quick Actions</Typography.Title>
              <QuickActionsTile>
                <div>
                  <StyledReviewLabel>Role Status</StyledReviewLabel>
                  <StyledReviewValue>{roleReqObj.status}</StyledReviewValue>
                </div>
                {(showEditRole || showRoleActivate) && (
                  <Button type="primary" $startIcon={<IconUserDeactivate />} onClick={handleDeactivate} loading={loader} disabled={loader}>
                    {isDeactivated ? 'Activated' : 'Deactivated'} Role
                  </Button>
                )}
              </QuickActionsTile>
            </UserInfoContainer>
          ))}
      </UserDetailsWrapper>
      {(showRoleFunctions || showRoleUsers) && (
        <Tabs>
          {showRoleFunctions && <Tabs.Tab label="Functions" id={0} onTabChange={handleOnTabChange} activeTabId={activeTabId} />}
          {showRoleUsers && <Tabs.Tab label="Users" id={1} onTabChange={handleOnTabChange} activeTabId={activeTabId} />}
        </Tabs>
      )}
      {showRoleFunctions && activeTabId === 0 && (
        <FunctionDetails
          functionsList={roleReqObj.functions}
          isViewOnly={isViewOnly}
          handleDelete={handleDeleteModal}
          showAssignFunction={showAssignFunction}
        />
      )}
      {showRoleUsers && activeTabId === 1 && (
        <UserDetails userList={userList} isViewOnly={isViewOnly} handleDelete={handleDeleteModal} showRemoveUsers={showRemoveUsers} />
      )}
      {openRemoveModal && (
        <Modal open={openRemoveModal} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {getModalContent()}
        </Modal>
      )}
    </RolesFormBodyContainer>
  )
}

export default ReviewRoles
