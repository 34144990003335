import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import RewardsHeader from './RewardsHeader'
import NewRewardForm, {RewardRefType} from './NewRewardForm'
import {NewRewardFooter, RewardsFormBodyContainer} from '../Reward.Styled'
import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import RewardService from 'app/services/RewardService'
import {
  getRewardsRequestObj,
  initialRewardsRequestObj,
  rewardRuleDataInitialState,
  rewardStatus,
  updateRewardTiersData,
  updateRewardsRequestObj,
} from './utils'
import LoadingPanel from 'lib/components/LoadingPanel'
import ReviewRewards from './ReviewRewards'
import PartnerService from 'app/services/PartnerService'
import {FormTypes} from 'lib/components/Form/Form'
import MembershipService from 'app/services/MemberService'
import {toast} from 'react-hot-toast'
import {Modal} from 'antd'
import Typography from 'lib/components/Typography'
import {MarginBottomWrapper, ModalTitle, StyledModalContent} from 'app/common/components/Styled/common.styled'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {REWARD_CREATE, REWARD_EDIT} from 'app/common/helpers/UserFunctions'
import Appconfig from 'app/common/helpers/AppConfig'
import TagService from 'app/services/TagService'
import RewardCategoryService from 'app/services/RewardCategoryService'

export type AddNewRewardParam = {
  rewardId: string | 'new' | 'view'
}

interface AddNewRewardProps {
  isViewOnly?: boolean
}

const AddNewRewards: React.FC<AddNewRewardProps> = ({isViewOnly}) => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const navigate = useNavigate()
  const {rewardId} = useParams<AddNewRewardParam>()
  const actualRewardId = atob(rewardId || '')
  const rewardFormRef = useRef<RewardRefType>(null)
  const [activeStep, setActiveStep] = useState<number>(rewardId === 'new' ? 0 : 1)
  const [loadingRewards, setLoadingRewards] = useState(false)
  const [open, openConfirmModal] = useState(false)
  const [tiersData, setTiersData] = useState<MemebershipTypes.TierCountType[]>([])
  const [individualReward, setIndividualReward] = useState<RewardsTypes.RewardResponseBL>()
  const [partnerCodeOptions, setPartnerCodeOptions] = useState<FormTypes.Options[]>([])
  const [rewardDataOptions, setRewardsDataOptions] = useState<FormTypes.Options[]>([])
  const [tagsDataOptions, setTagsDataOptions] = useState<FormTypes.Options[]>([])
  const [categoryDataOptions, setCategoryDataOptions] = useState<FormTypes.Options[]>([])
  const [currentqty, setCurrentqyt] = useState(0)
  const allowBack = activeStep === 1
  const [loading, setLoading] = useState(false)
  const [showFirst, setShowFirst] = useState(true)
  const {roles} = useAuth()
  const showRewardEdit = roles.includes(REWARD_EDIT)
  const showRewardCreate = roles.includes(REWARD_CREATE)
  const updateRewardsReqPartnerData = (data: PartnerTypes.PartnerBL[]) => {
    const partnerCodeFromRequest = getRewardsRequestObj.partnerCode
    const defaultCode = data.find((partnerOption) => partnerOption.isDefaultPartner)
    if (partnerCodeFromRequest) {
      const hasPartnerCode = data.find((partnerOption) => partnerOption.partnerCode === partnerCodeFromRequest)
      if (!hasPartnerCode) {
        getRewardsRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
      }
    } else {
      getRewardsRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
    }
    getMemberCount(getRewardsRequestObj.partnerCode)
  }

  const getPartnerCodes = () => {
    PartnerService.getPartners().then((res) => {
      const data = res.data.data
      const partnerOptions = data.map((item) => ({value: item.partnerCode, label: item.partnerName}))
      updateRewardsReqPartnerData(data)
      setPartnerCodeOptions(partnerOptions)
    })
    // getTags({...initialPaginationObj, isAll: true})
  }

  const getMemberCount = (partnerCode: string) => {
    MembershipService.memberCount({partnerCode}).then((res) => {
      const {tierCount} = res.data.data
      updateRewardTiersData(tierCount)
      setTiersData(tierCount)
    })
  }
  const getCurrentInventory = async (voucherTypeCode: string) => {
    const reqParam: RewardsTypes.RewardInfoSearchParam = {
      voucherStatus: 'AVAILABLE',
      voucherTypeCode: voucherTypeCode,
      Strict: true,
    }
    RewardService.searchRewards(reqParam)
      .then((res) => {
        const data = res.data
        setCurrentqyt(data.pageInfo.count)
      })
      .finally(() => {})
  }

  const getApprovedRewards = (params: RewardsTypes.RewardSearchParam): void => {
    const reqParam: RewardsTypes.RewardSearchParam = {
      ...params,
      isLimitedInventory: true,
      voucherTypeGroup: 2,
      voucherStatus: 'APPROVED',
      isAll: true,
      enabled: true,
    }
    RewardService.search(reqParam)
      .then((res) => {
        const data = res.data
        const updatedData = data.data.map((item) => ({
          label: `${item.voucherTypeName}-${item.voucherTypeCode}`,
          value: item.voucherTypeCode,
        }))
        setRewardsDataOptions([...updatedData])
      })
      .finally(() => {})
  }

  const getTags = (params: TagTypes.TagSearchParam): void => {
    TagService.getTags(params)
      .then((res) => {
        const data = res.data.data
        setTagsDataOptions(
          data.map((item) => ({
            label: item.voucherTagName,
            value: item.voucherTagCode,
          }))
        )
      })
      .finally(() => {})
  }
  const getCategories = (params: RewardCategoryTypes.RewardCatgSearchParam): void => {
    RewardCategoryService.getRewardCategories(params)
      .then((res) => {
        const data = res.data
        setCategoryDataOptions(
          data.data.map((item) => ({
            label: item.categoryName,
            value: item.categoryCode,
          }))
        )
      })
      .finally(() => {})
  }

  useEffect(() => {
    getPartnerCodes()
    getApprovedRewards(initialPaginationObj)
    getTags({...initialPaginationObj, isAll: true})
    getCategories({...initialPaginationObj, isAll: true})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getTags({...initialPaginationObj, isAll: true, partnerCode: getRewardsRequestObj.partnerCode})
  }, [getRewardsRequestObj.partnerCode])
  useEffect(() => {
    if (rewardId !== 'new') {
      setLoadingRewards(true)
      setLoadingRewards(false)
      RewardService.searchDetail({voucherTypeCode: actualRewardId})
        .then((res) => {
          const data = res.data.data
          if (data.rewardRuleData === null) {
            data.rewardRuleData = rewardRuleDataInitialState
          }
          setIndividualReward(data)
          updateRewardsRequestObj(data)
        })
        .finally(() => {
          setLoadingRewards(false)
        })
    }
  }, [actualRewardId])

  const handleEditReward = () => {
    setActiveStep(0)
    getCurrentInventory(actualRewardId)
  }

  const showPreview = () => {
    if (rewardFormRef.current?.validate()) {
      setLoading(true)
      if (getRewardsRequestObj.rewardRuleData === null) {
        getRewardsRequestObj.rewardRuleData = rewardRuleDataInitialState
      }
      RewardService.saveRewards(getRewardsRequestObj, {voucherTypeCode: rewardId !== 'new' ? getRewardsRequestObj.voucherTypeCode : undefined})
        .then((res) => {
          const {data} = res.data
          updateRewardsRequestObj(data)
          const encodedVoucherCode = btoa(data.voucherTypeCode)
          navigate(`/rewards/${encodedVoucherCode}`)
          setActiveStep(1)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const submitForApproval = () => {
    getRewardsRequestObj.voucherStatus = rewardStatus.APPROVED
    RewardService.saveRewards(getRewardsRequestObj, {voucherTypeCode: getRewardsRequestObj.voucherTypeCode})
      .then((res) => {
        toast.success('Data Saved Sucessfully !')
        navigate(`/rewards`)
      })
      .catch(() => {
        toast.error('Something went wrong!')
      })
  }

  const handleCancelConfirm = () => {
    updateRewardsRequestObj(initialRewardsRequestObj)
    navigate(-1)
  }

  const handleOk = () => {
    openConfirmModal(false)
    handleCancelConfirm()
  }

  const handleCancelNewReward = () => {
    if (isViewOnly === true) {
      handleCancelConfirm()
    } else {
      openConfirmModal(true)
    }
  }
  const handleCancelModal = () => {
    openConfirmModal(false)
  }

  const handleTierTypeChange = (id: keyof RewardsTypes.TriggerRulesData, value: boolean) => {
    let updatedTierType = getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.TriggerRulesData]
    if (updatedTierType) {
      updatedTierType = {
        ...updatedTierType,
        triggerData: {
          ...updatedTierType.triggerData,
          isAllTiers: value,
          tierData: value ? null : [],
        },
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedTierType,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const onTierSelection = (id: keyof RewardsTypes.TriggerRulesData, tierData: RewardsTypes.RewardTierInfo[]) => {
    let updatedTierType = getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.TriggerRulesData]
    if (updatedTierType) {
      updatedTierType = {
        ...updatedTierType,
        triggerData: {
          ...updatedTierType.triggerData,
          tierData,
        },
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedTierType,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const onRestrictionToggle = (id: keyof RewardsTypes.RestrictionKeyType, value: boolean) => {
    let updatedRestrictionData =
      getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.RestrictionKeyType]
    if (updatedRestrictionData) {
      updatedRestrictionData = {
        ...updatedRestrictionData,
        isRestrictionAvaliable: value,
        state: value ? [] : null,
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedRestrictionData,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const onStateChange = (id: string, values: string[]) => {
    let updatedRestrictionData =
      getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.RestrictionKeyType]
    if (updatedRestrictionData) {
      updatedRestrictionData = {
        ...updatedRestrictionData,
        state: [...values],
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedRestrictionData,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const updateRewardTriggerToggle = (id: string, value: boolean) => {
    let updatedTierType = getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.TriggerRulesData]
    if (updatedTierType) {
      updatedTierType = {
        ...updatedTierType,
        triggerData: {
          ...updatedTierType.triggerData,
          isAllTiers: true,
          tierData: null,
        },
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          isRedemption: id === 'pointRedemption' ? value : getRewardsRequestObj.isRedemption,
          isTierBenefit: id === 'tierBenefit' ? value : getRewardsRequestObj.isTierBenefit,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedTierType,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const renderSaveButton = () => {
    return (
      <Button
        loading={loading}
        onClick={activeStep === 0 ? showPreview : submitForApproval}
        type="primary"
        disabled={false}
        $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
      >
        {activeStep === 1 ? 'Submit for approval' : 'Preview'}
      </Button>
    )
  }

  const renderRewardsFooter = () => {
    return (
      !isViewOnly && (
        <NewRewardFooter>
          {allowBack && showRewardEdit ? (
            <Button type="primary" onClick={handleEditReward}>
              Edit
            </Button>
          ) : (
            <></>
          )}

          {(showRewardEdit || showRewardCreate) && renderSaveButton()}
        </NewRewardFooter>
      )
    )
  }

  const renderRewardsBody = () => {
    if (loadingRewards) {
      return <LoadingPanel />
    }
    if (rewardId !== 'new' && !individualReward) {
      setTimeout(() => {
        setShowFirst(false)
      }, 2000)
      return <div>{showFirst ? <LoadingPanel /> : <div>No Reward found with {actualRewardId}</div>}</div>
    }
    if (activeStep === 0) {
      return (
        <NewRewardForm
          ref={rewardFormRef}
          partnerCodeOptions={partnerCodeOptions}
          tiersData={tiersData}
          handleTierTypeChange={handleTierTypeChange}
          onTierSelection={onTierSelection}
          onRestrictionToggle={onRestrictionToggle}
          onStateChange={onStateChange}
          updateRewardTriggerToggle={updateRewardTriggerToggle}
          getMemberCount={getMemberCount}
          currentqty={currentqty}
          rewardDataOptions={rewardDataOptions}
          tagsDataOptions={tagsDataOptions}
          categoryDataOptions={categoryDataOptions}
        />
      )
    }
    return <ReviewRewards tagDataOptions={tagsDataOptions} categoryDataOptions={categoryDataOptions} />
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs backLinkLabel="Rewards" backLinkPath={`/rewards`} activePageLabel="Add New Rewards" />
        <Button type="link" onClick={handleCancelNewReward}>
          {rewardId === 'new' ? 'Cancel New Reward' : 'Cancel'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <RewardsHeader activeStep={activeStep} />
      {renderRewardsFooter()}
      <RewardsFormBodyContainer>{renderRewardsBody()}</RewardsFormBodyContainer>
      {renderRewardsFooter()}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          <StyledModalContent align="center">
            <ModalTitle>
              <Typography.Title level={1}>Are you sure?</Typography.Title>
            </ModalTitle>
            <MarginBottomWrapper>
              <Typography.Paragraph size="medium">
                Are you sure you want to cancel adding {getRewardsRequestObj.voucherTypeName}?
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" key="submit" onClick={handleOk}>
                Continue with cancel
              </Button>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="link" key="back" onClick={handleCancelModal}>
                Back
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </Modal>
      )}
    </div>
  )
}

export default AddNewRewards
