import FormLabel from 'lib/components/Form/FormLabel'
import {SearchInputProps} from './SearchInputTypes'
import {StyledSearchInput} from './SearchInput.Styled'

const SearchInput = ({id, placeholder, label, isDark}: SearchInputProps) => {
  return (
    <>
      {label && id && <FormLabel id={id} label={label} isDark={isDark} />}
      <StyledSearchInput type="text" id={id} placeholder={placeholder} name={id} aria-labelledby={`${id}-label`} />
    </>
  )
}

export default SearchInput
