import NoMatch from 'app/common/components/NoMatch'
import Unauthorized from 'app/common/components/Unauthorized'
import UnprotectedLayout from 'app/common/components/UnprotectedLayout'
import Login from 'app/pages/Login'
import CreatePassword from 'app/pages/Login/CreatePassword'
import ForgotPassword from 'app/pages/Login/ForgotPassword'
import ResetPassword from 'app/pages/Login/ResetPassword'
import { Navigate, RouteObject } from 'react-router-dom'

const publicRoutes: RouteObject= {
  path: '/',
  element: <UnprotectedLayout />,
  errorElement: <NoMatch />,
  children: [
    { path: 'login', element: <Login />, index: true },
    { path: 'forgot-pass', element: <ForgotPassword /> },
    { path: 'ResetPassword', element: <ResetPassword /> },
    { path: 'CreatePassword', element: <CreatePassword /> },
    { path: 'unauthorized', element: <Unauthorized /> },
    { path: '/', element: <Navigate to="/login" replace /> },
    // { path: '404', element: <NotFound /> },
    { path: '*', element: <NoMatch /> },
  ],
}

export default publicRoutes