import React, {useEffect} from 'react'
import Button from 'lib/components/Button'
import SectionTitle from '../../../common/components/SectionTitle'
import {useNavigate} from 'react-router-dom'
import HttpRequest from 'app/common/helpers/HttpRequest'

const ClientSetup: React.FC = () => {
  const navigate = useNavigate()

  const navigateTo = (): void => {
    navigate(-1)
  }

  useEffect(() => {
    HttpRequest({
      url: 'http://demo3685445.mockable.io/test',
    })
  }, [])

  return (
    <section>
      <SectionTitle>Client Setup</SectionTitle>
      <Button onClick={navigateTo}>Back</Button>
    </section>
  )
}

export default ClientSetup
