import {TabButton} from './Tabs.styled'

interface TabProps {
  label: string | JSX.Element
  onTabChange(id: number): void
  activeTabId: number
  id: number
  disabled?: boolean
  color?: string
}

const Tab = ({label, onTabChange, activeTabId, id, disabled, color}: TabProps) => {
  const isActive = activeTabId === id
  const onTabClick = () => {
    onTabChange(id)
  }
  return (
    <TabButton
      type="button"
      role="tab"
      aria-selected={isActive ? 'true' : 'false'}
      id={`tab_${id}`}
      onClick={onTabClick}
      isActive={isActive}
      disabled={disabled}
      color={color}
    >
      {label}
      {/* <TabIndicator isActive={isActive} /> */}
    </TabButton>
  )
}

export default Tab
