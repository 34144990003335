import Cookies from 'js-cookie'

interface ObjectType {
  [key: string]: string
}
interface WriteCookieType {
  name: string
  value: ObjectType
  expiryDays?: number
}

export const _hasLocalStorageSupport = (): boolean => {
  try {
    return 'localStorage' in window && window.localStorage !== null
  } catch (e) {
    return false
  }
}

export const _hasSessionStorageSupport = (): boolean => {
  try {
    return 'sessionStorage' in window && window.sessionStorage !== null
  } catch (e) {
    return false
  }
}

export const _convertObjectToString = (value: ObjectType): string => {
  if (value !== null && typeof value === 'object') {
    return JSON.stringify(value)
  }
  return value
}

export const _convertStringToObject = (value: string): ObjectType | string => {
  let obj = null
  try {
    obj = JSON.parse(value)
  } catch (error) {
    obj = null
  }
  return obj ? obj : value
}

export const _writeToCookie = (prop: WriteCookieType): void => {
  const jsonVal = _convertObjectToString(prop.value)
  Cookies.set(prop.name, jsonVal, { expires: prop.expiryDays, path: '/' })
}

export const _readFromCookie = (name: string): ObjectType | string => {
  const cookieValue = Cookies.get(name)
  if (cookieValue) {
    return _convertStringToObject(cookieValue)
  }

  return name
}

export const lStorage = {
  _set: (name: string, value: ObjectType): void => {
    if (_hasLocalStorageSupport()) {
      const jsonVal = _convertObjectToString(value)
      localStorage.setItem(name, jsonVal)
    } else {
      _writeToCookie({ name, value, expiryDays: 365 })
    }
  },

  _get: (name: string): any => {
    if (_hasLocalStorageSupport()) {
      const value = localStorage.getItem(name) as string
      return _convertStringToObject(value)
    }
    return _readFromCookie(name)
  },
  _remove: (key: string): void => {
    localStorage.removeItem(key)
  },
}

export const sStorage = {
  _set: (name: string, value: ObjectType): void => {
    if (_hasSessionStorageSupport()) {
      const jsonVal = _convertObjectToString(value)
      sessionStorage.setItem(name, jsonVal)
    } else {
      _writeToCookie({ name, value, expiryDays: 0 })
    }
  },
  _get: (name: string): any => {
    if (_hasSessionStorageSupport()) {
      const value = sessionStorage.getItem(name) as string
      return _convertStringToObject(value)
    }
    return _readFromCookie(name)
  },
  _remove: (key: string): void => {
    sessionStorage.removeItem(key)
  },
}
