import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'
import {ActivityLogTypes} from 'types/ActivityLogTypes'

export const getActivities = (
  params?: ActivityLogTypes.ActivityLogSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<ActivityLogTypes.ActivityLogResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.activityLog,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

const AcitivityService = {
  getActivities,
}

export default AcitivityService
