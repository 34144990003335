import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {MouseEvent} from 'react'
import {Col, Divider, Input, Row, Space, Select} from 'antd'
import Form from 'lib/components/Form'
import {valueType} from 'antd/es/statistic/utils'
import {cloneDeep} from 'lodash'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import {FormTypes} from 'lib/components/Form/Form'
import toast from 'react-hot-toast'
import {getCategoryRequestObj, updateCategoryRequestObj} from './utils'
import TextArea from 'lib/components/TextArea'

import Colors from 'styles/Colors'
import Button from 'lib/components/Button'
import RewardCategoryService from 'app/services/RewardCategoryService'
import FormLabel from 'lib/components/Form/FormLabel'
import {useParams} from 'react-router-dom'
import {AddNewCategroyParam} from '.'

export interface CategoryRefType {
  validate(): boolean
  getCategoryDetails(): InitialStateType
}

interface InitialStateType {
  categoryCode: string
  categoryName: string
  partnerCode: string
  categoryDescription: string
  categoryGroup: string
  // properties?: CommonTypes.Properties | null
}

interface errorStateType {
  categoryName: boolean
  partnerCode: boolean
  categoryDescription: boolean
  categoryGroup: boolean
  categoryCode: boolean
}

interface NewCategoryFormProps {
  partnerCodeOptions: FormTypes.Options[]
}

const NewCategoryForm = forwardRef<CategoryRefType, NewCategoryFormProps>(({partnerCodeOptions}, ref) => {
  let updatedCategoryReqObj = getCategoryRequestObj
  // const inititalAttributes = getFormattedAttributesFromProperties(updatedCategoryReqObj.properties)
  // const defaultRow: FormTypes.Options = {label: '', value: ''}
  const initialState: InitialStateType = {
    categoryCode: updatedCategoryReqObj.categoryCode || '',
    categoryName: updatedCategoryReqObj.categoryName || '',
    partnerCode: updatedCategoryReqObj.partnerCode || '',
    categoryDescription: updatedCategoryReqObj.categoryDescription || '',
    categoryGroup: updatedCategoryReqObj.categoryGroup || '',
    // properties: updatedCategoryReqObj.properties || null,
  }
  const initialErrorState: errorStateType = {
    categoryName: false,
    categoryDescription: false,
    partnerCode: false,
    categoryGroup: false,
    categoryCode: false,
  }
  const {categoryId} = useParams<AddNewCategroyParam>()
  const [state, setState] = useState(initialState)
  const [error, setError] = useState(initialErrorState)
  // const [attributes, setAttributes] = useState<FormTypes.Options[]>(inititalAttributes.length ? inititalAttributes : [defaultRow])
  const [categoryGroupOptions, setcategoryGroupOptions] = useState<FormTypes.Options[]>([])
  let index = 0
  const [name, setName] = useState('')
  const inputRef = useRef(null)
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }
  const addItem = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    let newlist: FormTypes.Options[] = [
      {
        label: name || `New item ${index++}`,
        value: name || `New item ${index++}`,
      },
    ]
    if (name.length > 50) {
      setError({...error, categoryGroup: true})
      toast.error('Voucher Category Group cannot exceed 50 characters.')
      return
    }
    const isDuplicate = categoryGroupOptions.some((option) => option.value === name)
    if (isDuplicate) {
      setError({...error, categoryGroup: true})
      toast.error('Item already exists in the category group.')
      return
    }
    const combinedOptions = [...categoryGroupOptions, ...newlist]
    const uniqueOptionsMap = new Map(combinedOptions.map((option) => [option.value, option]))
    const uniqueOptions = Array.from(uniqueOptionsMap.values())
    setcategoryGroupOptions(uniqueOptions)
    setName('')
  }
  const handleValidate = (): boolean => {
    // if (!state.partnerCode) {
    //   setError({...error, partnerCode: true})
    //   toast.error('Partner Code is required.')
    //   return false
    // }
    if (!state.categoryName) {
      setError({...error, categoryName: true})
      toast.error('Voucher Category Name is required.')
      return false
    }
    if (state.categoryName.length > 250) {
      setError({...error, categoryName: true})
      toast.error('Voucher Category Name cannot exceed 250 characters.')
      return false
    }
    if (state.categoryCode.length > 50) {
      setError({...error, categoryCode: true})
      toast.error('Voucher Category ID cannot exceed 50 characters.')
      return false
    }
    if (state.categoryDescription.length > 500) {
      setError({...error, categoryDescription: true})
      toast.error('Voucher Category Description cannot exceed 500 characters.')
      return false
    }
    if (state.categoryGroup.length > 50) {
      setError({...error, categoryGroup: true})
      toast.error('Voucher Category Group cannot exceed 50 characters.')
      return false
    }
    // if (!attributes.length || !validateAttributes(attributes)) {
    //   toast.error('Key - value of properties canot be null or empty.')
    //   return false
    // }
    return true
  }

  const getCategoryDetails = (): InitialStateType => {
    return state
  }

  useImperativeHandle(ref, () => ({validate: handleValidate, getCategoryDetails}))

  const handleChange = (value: valueType, id: string) => {
    const prevState = cloneDeep(state)
    setError(initialErrorState)
    setState({
      ...prevState,
      [id]: value,
    })
    updateCategoryRequestObj({
      ...updatedCategoryReqObj,
      [id]: value,
    })
  }
  const handleCategoryChange = (value: string) => {
    const prevState = cloneDeep(state)
    setError(initialErrorState)
    setState({
      ...prevState,
      categoryGroup: value,
    })
    updateCategoryRequestObj({
      ...updatedCategoryReqObj,
      categoryGroup: value,
    })
  }

  const getCategoryGroupData = () => {
    RewardCategoryService.getRewardCategories().then((res) => {
      const data = res.data.data
      const formattedCategoryGroupOptions = Array.from(
        new Set(data.filter((item) => item.categoryGroup !== null && item.categoryGroup.trim() !== '').map((item) => item.categoryGroup))
      ).map((categoryGroup) => ({
        label: categoryGroup,
        value: categoryGroup,
      }))
      const combinedOptions = [...categoryGroupOptions, ...formattedCategoryGroupOptions]
      const uniqueOptionsMap = new Map(combinedOptions.map((option) => [option.value, option]))
      const uniqueOptions = Array.from(uniqueOptionsMap.values())
      setcategoryGroupOptions(uniqueOptions)
    })
  }
  useEffect(() => {
    getCategoryGroupData()
  }, [])

  // const handleDeleteAttribute = (index: number) => {
  //   const updatedAttributes = cloneDeep(attributes)
  //   updatedAttributes.splice(index, 1)
  //   setAttributes(updatedAttributes)
  //   const formattedAttributes = getFormattedProperties(updatedAttributes)
  //   updatedCategoryReqObj = {
  //     ...updatedCategoryReqObj,
  //     properties: formattedAttributes,
  //   }

  //   updateCategoryRequestObj(updatedCategoryReqObj)
  // }

  // const handleAddAttribute = () => {
  //   const updatedAttributes = cloneDeep(attributes)
  //   updatedAttributes.push(defaultRow)
  //   setAttributes(updatedAttributes)
  // }
  // const onCategoryDataChange = (attr: FormTypes.Options, rowIndex: number) => {
  //   const updatedAttributes = cloneDeep(attributes)
  //   updatedAttributes[rowIndex] = attr
  //   setAttributes(updatedAttributes)
  //   const formattedAttributes = getFormattedProperties(updatedAttributes)
  //   updatedCategoryReqObj = {
  //     ...updatedCategoryReqObj,
  //     properties: formattedAttributes,
  //   }
  //   updateCategoryRequestObj(updatedCategoryReqObj)
  // }

  return (
    <>
      <MarginBottomWrapper>
        <Col span={10}>
          <Form.Input
            id="categoryCode"
            label="Category ID"
            name="categoryCode"
            placeholder="Category ID"
            value={state.categoryCode}
            onChange={handleChange}
            disabled={categoryId !== 'new'}
          />
        </Col>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10}>
            <Form.Select
              id="partnerCode"
              label="Partner Code"
              value={state.partnerCode || updatedCategoryReqObj.partnerCode}
              options={partnerCodeOptions}
              onChange={handleChange}
              disabled={true}
              status={error.partnerCode ? 'error' : undefined}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10}>
            <Form.Input
              id="categoryName"
              label="Category Name"
              name="categoryName"
              placeholder="Attribute Name"
              value={state.categoryName}
              onChange={handleChange}
              status={error.categoryName ? 'error' : undefined}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10}>
            <TextArea
              id="categoryDescription"
              name="categoryDescription"
              label="Description (Max. 500 Characters)"
              placeholder="Description"
              value={state.categoryDescription}
              handleChange={handleChange}
              maxLength={500}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10} sm="10">
            <FormLabel id="categoryGroup" label="Category Group" />
            <Select
              style={{
                width: '100%',
                color: Colors.PRIMARY,
              }}
              id="categoryGroup"
              onChange={handleCategoryChange}
              placeholder="Search & enter"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <Space
                    style={{
                      padding: '0 8px 4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Input
                      placeholder="Please enter item"
                      ref={inputRef}
                      value={name}
                      onChange={onNameChange}
                      onKeyDown={(e) => e.stopPropagation()}
                      style={{color: Colors.PRIMARY}}
                    />
                    <Button type="text" onClick={(e) => addItem(e)}>
                      Add item
                    </Button>
                  </Space>
                </>
              )}
              options={categoryGroupOptions}
              value={state.categoryGroup}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      {/* <MarginBottomWrapper>
        <Typography.Text color="primary" strong uppercase>
          CHOOSE CATEGORY ATTRIBUTES AND VALUES
        </Typography.Text>
      </MarginBottomWrapper> */}
      {/* <MarginBottomWrapper>
        <Row>
          <Col>
            {attributes.map((attr, index) => (
              <Row style={{alignItems: 'center'}} gutter={16}>
                <Col span={10}>
                  <Form.Input
                    key={index}
                    id="attribute"
                    label="Select Attribute"
                    placeholder="Attribute"
                    value={attr?.label?.toString() || ''}
                    onChange={(e) => onCategoryDataChange({label: e, value: attr.value}, index)}
                  />
                </Col>
                <Col span={1} style={{textAlign: 'center', alignItems: 'center', fontSize: '16px'}}>
                  =
                </Col>
                <Col span={10}>
                  <Form.Input
                    id="attributeValue"
                    label="Select Value"
                    placeholder="Value"
                    value={attr.value}
                    onChange={(e) => onCategoryDataChange({label: attr.label, value: e}, index)}
                  />
                </Col>
                {attributes.length >= 1 && (
                  <Col span={1}>
                    <Button type="text" onClick={() => handleDeleteAttribute(index)}>
                      <IconDelete size={16} />
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
            {attributes.length < 5 && (
              <>
                <MarginTopWrapper>
                  <Button type="text" onClick={handleAddAttribute} color={Colors.BLACK} $startIcon={<IconAdd color={Colors.BLACK} />}>
                    Add Attribute
                  </Button>
                </MarginTopWrapper>
              </>
            )}
          </Col>
        </Row>
      </MarginBottomWrapper> */}
    </>
  )
})
{
  /* <ProductCategoryRow
                key={`${attr.label}-${index}`}
                attr={attr}
                rowIndex={index}
                categoryOptions={categoryList}
                handleDeleteAttribute={handleDeleteAttribute}
                onCategoryDataChange={onCategoryDataChange}
                totalRows={attributes.length}
              /> */
}
export default NewCategoryForm
