import {AwardModalHeader, StyleActionModalBodyWrapper} from './ServiceActions.styled'
import AdjustPoints from './AdjustPoints'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {MEMBER_DETAIL_BALANCE} from 'app/common/helpers/UserFunctions'
import Typography from 'lib/components/Typography'
import { PointTypeData } from '../index'

interface ServiceActionsProps {
  memberBalance: number
  refreshMemberBalance: (closeModal?: boolean) => void
  handleCloseModal(): void
  pointTypeOptions: PointTypeData[]
}

const ServiceActions: React.FC<ServiceActionsProps> = ({memberBalance, refreshMemberBalance, handleCloseModal, pointTypeOptions }) => {
  const {roles} = useAuth()
  const showMemberDetailBalance = roles.includes(MEMBER_DETAIL_BALANCE)

  // const showMemberStatus = roles.includes(MEMBER_DETAIL_STATUS)
  // const showMemberTier = roles.includes(MEMBER_DETAIL_TIER)

  // const [memberBalance, setMemberBalance] = useState(0)
  // const {membershipCardNumber} = memberDetail

  return (
    <>
      {showMemberDetailBalance && (
        <StyleActionModalBodyWrapper>
          <AwardModalHeader>
            <Typography.Title level={1}>Adjust Points</Typography.Title>
          </AwardModalHeader>
          <AdjustPoints refreshMemberBalance={refreshMemberBalance} handleCloseModal={handleCloseModal} pointTypeOptions={pointTypeOptions} />
        </StyleActionModalBodyWrapper>
      )}
    </>
  )
}

export default ServiceActions
