import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Typography from 'lib/components/Typography'

const ImportHeader: React.FC = () => {
  return (
    <StyledHeader>
      <Typography.Title level={2}>Import New Data</Typography.Title>
    </StyledHeader>
  )
}

export default ImportHeader
