import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const getPoints = (params?: PointType.PointDetials): Promise<AxiosResponse<ResponseTypes.ResponseData<PointType.PointBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getPoints,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const getAllPoints = (
  params?: PointType.PointMasterSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<PointType.PointMasterResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getAllPoints,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const savePoint = (
  data: PointType.PointMasterResponseBL,
  params: PointType.PointDetails
): Promise<AxiosResponse<ResponseTypes.PagedResponse<PointType.PointMasterResponseBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getPoints,
    data,
    params,
    withCredentials: false,
    method: params.pointCode ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

export const getCurrencies = (
  params: PointType.CurrencyDetails
): Promise<AxiosResponse<ResponseTypes.ResponseData<PointType.CurrencyResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getCurrenies,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const getPointTypeCodes = (
  params: PointType.PointTypeDetails
): Promise<AxiosResponse<ResponseTypes.ResponseData<PointType.PointTypeResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getPointTypeCodes,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

const PointService = {
  getPoints,
  getAllPoints,
  savePoint,
  getCurrencies,
  getPointTypeCodes
}

export default PointService
