import styled from 'styled-components'
import Colors from 'styles/Colors'

export const PromotionHeaderWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 0.9fr 140px 200px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
`

export const CancelBtnRoot = styled.div`
  margin-left: auto;
`

export const StyleActivityInfo = styled.span`
  position: absolute;
  left: 32px;
  top: -16px;
  display: flex;
  align-items: center;
`

export const InfoMessageContainer = styled.span`
  padding: 8px 4px 16px 24px;
  font-size: 12px;
  background-color: ${Colors.PRIMARY_DARK};
  color: ${Colors.WHITE};
  display: flex;
  width: 300px;
  border-radius: 4px;
  margin-left: -8px;
`
