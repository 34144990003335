import { Modal, Space } from 'antd'
import Button from 'lib/components/Button'
import styled from 'styled-components'
import Colors from 'styles/Colors'

export const MainContainer = styled.div`
  padding-top: 34px;
  padding-bottom: 34px;
`
export const StyledPageHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const UrlLink = styled.a`
  font-size: ${(props) => props.theme.typography.fontSize}px;
`

export const UnderlineUrl=styled.a`
  text-decoration: underline;
`

interface ModalContentProps {
  align: string
}

export const StyledModalContent = styled.div<ModalContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: ${(props) => props.align};
  padding: 32px;
`

export const ModalTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 32px;
`

export interface MarginWrapperProps {
  width?: string
  textAlign?: string
}

export const MarginBottomWrapper = styled.div<MarginWrapperProps>`
  margin-bottom: 16px;
  width: ${(props) => props.width || 'auto'};
  text-align: ${(props) => props.textAlign || 'left'};
`

interface SepratorProps {
  width?: string
}

export const Seperator = styled.div<SepratorProps>`
  border-bottom: 1px solid ${Colors.BORDER2};
  width: ${(props) => (props.width ? props.width : '80px')};
`

export const UrlContainer = styled.span`
  word-break: break-all;
`

export const HorizontallyCenter = styled.div`
  display: flex;
  justify-content: center;
`

export const CenteredAlignedLabel = styled.div`
  display: flex;
  align-items: center;
`

export const ExtraPaddedButton = styled(Button)`
  padding: 4px 96px;
  height: 40px;
`

export const SmallHeightButtonsForIcon = styled(Button)`
  height: 20px !important;
  padding: 4px;
`

export const NoPermissionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
  font-size: 24px;
`

export const StyleModalWrapper = styled(Modal)`
  max-height: 600px;
`

export const StyledMenuItem = styled.div`
  > button {
    width: 100%;
  }
`

export const FullWidthCenterAlignedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

interface MarginProps {
  value: string
}

export const MarginRightWrapper = styled.div<MarginProps>`
  margin-right: ${(props) => props.value};
`

export const MarginLeftWrapper = styled.div<MarginProps>`
  margin-left: ${(props) => props.value};
`

export const MarginTopWrapper = styled(Space)`
  margin-top: 8px;
`

export const ReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid ${Colors.PRIMARY};
  border-left: 10px solid ${Colors.PRIMARY};
  margin-bottom: 32px;
`

interface statusCircleProps {
  color: string
}

export const StatusCircleStyled = styled.div<statusCircleProps>`
  height:12px;
  width:12px;
  background:green;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-right: 4px;
  background-color: ${({color}): string => color};
`

interface FilterItemProp {
  width: string
}

export const FilterItem = styled.div<FilterItemProp>`
  width: ${(props): string => props.width};
`