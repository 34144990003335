import Colors from 'styles/Colors'

const IconReadOnly = ({size = 24, color = 'currentColor'}: IconType.Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ic_fluent_read_only_24_filled" fill={Colors.PRIMARY} fillRule="nonzero">
          <path d="M2.21968,2.21967 C2.48594364,1.95340636 2.90261223,1.92920884 3.19622222,2.14706241 L3.28034,2.21968 L21.7801,20.7198 C22.073,21.0127 22.073,21.4876 21.7801,21.7805 C21.5138273,22.0467727 21.0971413,22.0709793 20.8035207,21.8531198 L20.7194,21.7805 L14,15.0609 L9.0621,19.9987 C8.83136667,20.22945 8.55379306,20.4066583 8.24906088,20.5189245 L8.06312,20.5784 L2.94743,21.9736 C2.42049353,22.1173176 1.93442848,21.6707294 2.00636631,21.1509001 L2.02652,21.0527 L3.42171,15.937 C3.50756833,15.62225 3.66064194,15.330625 3.86951301,15.0819745 L4.00144,14.9381 L8.9392,10.0000075 L2.21966,3.28033 C1.92677,2.98743 1.92678,2.51256 2.21968,2.21967 Z M13.9393,4.99963 L19.0003,10.0606 L16.1207,12.9402 L11.0599,7.87922 L13.9393,4.99963 Z M21.0304,2.96973 C22.4279,4.36719 22.4279,6.63293 21.0304,8.03039 L20.0603,8.99963 L14.9993,3.93963 L15.9698,2.96973 C17.3672,1.57227 19.633,1.57227 21.0304,2.96973 Z"></path>
        </g>
      </g>
    </svg>
  )
}

export default IconReadOnly
