import styled, {css} from 'styled-components'
import {Typography} from 'antd'
import Colors from 'styles/Colors'
import {TitleProps} from 'antd/es/typography/Title'
import {TextProps} from 'antd/es/typography/Text'
const {Title: AntTitle, Link: AntLink, Text} = Typography

interface StyledTitleProps extends TitleProps {
  $uppercase?: boolean
  $color?: 'default' | 'primary' | 'secondary'
}

interface StyledTextProps extends TextProps {
  $uppercase?: boolean
  $color?: 'default' | 'primary' | 'secondary'
  $size?: 'small' | 'medium'
}

export const StyledTitle = styled(AntTitle)<StyledTitleProps>`
  && {
    margin: 0;
    letter-spacing: -0.002em;
    ${(props) =>
      props.$uppercase &&
      css`
        text-transform: uppercase;
      `}

    ${(props) =>
      props.$color === 'primary' &&
      css`
        color: ${Colors.PRIMARY};
      `}

  ${(props) =>
      props.$color === 'secondary' &&
      css`
        color: ${Colors.SECONDARY};
      `}

    ${(props) =>
      (props.level === 2 || props.level === 5) &&
      css`
        font-weight: 500;
      `}
    ${(props) =>
      props.level === 3 &&
      css`
        font-weight: 600;
      `}
  }
`
export const StyledLink = styled(AntLink)`
  && {
    font-weight: 500;
    line-height: 1.2857142857142858;
  }
`
export const StyledText = styled(Text)<StyledTextProps>`
  && {
    font-weight: 400;
    letter-spacing: -0.002em;
    ${(props) =>
      props.$uppercase &&
      css`
        text-transform: uppercase;
      `}

    ${(props) =>
      props.$color === 'default' &&
      css`
        color: ${Colors.COLOR_TEXT_SECONDARY};
      `}
    ${(props) =>
      props.$color === 'primary' &&
      css`
        color: ${Colors.PRIMARY};
      `}

    ${(props) =>
      props.$color === 'secondary' &&
      css`
        color: ${Colors.COLOR_TEXT};
      `}
    ${(props) =>
      props.$size === 'small' &&
      css`
        font-size: 12px;
        line-height: 1.3333333333;
      `}
  }
`
export const StyledParagraph = styled(Text)<StyledTextProps>`
  && {
    font-weight: 400;
    letter-spacing: -0.002em;
    word-break: normal;
    ${(props) =>
      props.$uppercase &&
      css`
        text-transform: uppercase;
      `}

    ${(props) =>
      props.$color === 'default' &&
      css`
        color: ${Colors.COLOR_TEXT_SECONDARY};
      `}
    ${(props) =>
      props.$color === 'primary' &&
      css`
        color: ${Colors.PRIMARY};
      `}

    ${(props) =>
      props.$color === 'secondary' &&
      css`
        color: ${Colors.COLOR_TEXT};
      `}
    ${(props) =>
      props.$size === 'small' &&
      css`
        font-size: 12px;
        line-height: 1.3333333333;
      `}
  }
`
