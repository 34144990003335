import {Pagination as AntPagination, PaginationProps as AntPaginationProps} from 'antd'
import {PaginationBody, TableFooter} from './Pagination.styled'
import {string} from 'prop-types'

export type ActionType = 'FIRST_PAGE' | 'LAST_PAGE' | 'BACK' | 'NEXT' | 'INDIVIDIAL'

export interface PaginationProps extends AntPaginationProps {
  align?: React.CSSProperties['justifyContent']
}

const Pagination = (props: PaginationProps) => {
  return (
    <TableFooter $align={props.align}>
      <PaginationBody>
        <AntPagination hideOnSinglePage {...props} />
      </PaginationBody>
    </TableFooter>
  )
}
Pagination.defaultProps = {
  align: 'center',
}
Pagination.propTypes = {
  align: string,
}
export default Pagination
