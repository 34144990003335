import RewardCategoryService from 'app/services/RewardCategoryService'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import { FormTypes } from 'lib/components/Form/Form'
import { FormEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

export interface ResetRewardCatgFormRefType {
  handleReset(): void
  getFormState(): RewardCategoryTypes.CategoryMasterFilterState
}

interface RewardCategoryFilterProps {
  handleFilter(formState: RewardCategoryTypes.CategoryMasterFilterState): void
  isFetching: boolean
  partnerOptions: FormTypes.Options[]
}

type KeyTypes = keyof RewardCategoryTypes.CategoryMasterFilterState

const RewardCategoryFilter = forwardRef<ResetRewardCatgFormRefType, RewardCategoryFilterProps>(({handleFilter, isFetching, partnerOptions}, ref): JSX.Element => {
  const initialState: RewardCategoryTypes.CategoryMasterFilterState = {
    categoryName: '',
    categoryCode: '',
    partnerCode: '',
    categoryGroup: ''
  }
  const [state, setInitialState] = useState<RewardCategoryTypes.CategoryMasterFilterState>(initialState)
  const [categoryGroupOptions, setcategoryGroupOptions] = useState<FormTypes.Options[]>([])
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }
  const getCategoryGroupData = () => {
    RewardCategoryService.getRewardCategories().then((res) => {
      const data = res.data.data
      const formattedCategoryGroupOptions = Array.from(
        new Set(data.filter((item) => item.categoryGroup !== null && item.categoryGroup.trim() !== '').map((item) => item.categoryGroup))
      ).map((categoryGroup) => ({
        label: categoryGroup,
        value: categoryGroup,
      }))
      const combinedOptions = [...categoryGroupOptions, ...formattedCategoryGroupOptions]
      const uniqueOptionsMap = new Map(combinedOptions.map((option) => [option.value, option]))
      const uniqueOptions = Array.from(uniqueOptionsMap.values())
      setcategoryGroupOptions(uniqueOptions)
    })
  }
  const handleReset = () => {
    setInitialState(initialState)
  }

  const getFormState = (): RewardCategoryTypes.CategoryMasterFilterState => {
    return state
  }

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = {...state}
    setInitialState({
      ...prevState,
      [key]: value,
    })
  }

  useImperativeHandle(ref, () => ({handleReset, getFormState}))
  useEffect(() => { getCategoryGroupData() }, [])
  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.categoryName} name="categoryName" id="categoryName" placeholder="Category Name" onChange={handleOnChange} isDark />
      <Form.Input value={state.categoryCode} name="categoryCode" id="categoryCode" placeholder="Category ID" onChange={handleOnChange} isDark />
      <Form.Select
        value={state.partnerCode}
        options={partnerOptions}
        placeholder="Partner Code"
        id="partnerCode"
        onChange={handleOnChange}
        noMargin
        isDark
      />
      <Form.Select
        value={state.categoryGroup}
        options={categoryGroupOptions}
        placeholder="Search by Group"
        id="categoryGroup"
        onChange={handleOnChange}
        noMargin
        isDark
      />
    </DataTable.Filter>
  )
})

export default RewardCategoryFilter
