import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const searchMember = (
  params: MemebershipTypes.MembershipDetail = {}
): Promise<AxiosResponse<ResponseTypes.PagedResponse<MemebershipTypes.MembershipDataBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.memberSearch,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const MemberStatus = (
  params: MemberStatusTypes.MemberStatusDetail
): Promise<AxiosResponse<ResponseTypes.ResponseData<MemberStatusTypes.MemberStatusBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.memberStatus,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}
export const PutMemberStatus = (
  params: MemberStatusTypes.MemberChangeStatus
): Promise<AxiosResponse<ResponseTypes.ResponseData<MemberStatusTypes.MemberStatusBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.changeMemberStatus,
    method: 'Post',
    params,
    withCredentials: false,
    headers: {
      // 'Access-Control-Allow-Origin: '*',
    },
  }
  return HttpRequest(requestOptions)
}

export const postOptedMemberData = (
  body: MemebershipTypes.optedInMember
): Promise<AxiosResponse<ResponseTypes.ResponseData<MemebershipTypes.optedInMember>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.postOptedMemberData,
    method: 'post',
    data: body,
    withCredentials: false,
   
  }
  return HttpRequest(requestOptions)
}

export const PutMemberTier = (params: TierTypes.ChangeTierDetail): Promise<AxiosResponse<ResponseTypes.PagedResponse<TierTypes.TierDetails>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.changetier,
    params,

    method: 'post',
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const adjustPoints = (
  body: MemebershipTypes.AdjustPoints
): Promise<AxiosResponse<ResponseTypes.ResponseData<MemebershipTypes.AdjustPoints>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.adjustPoints,
    data: body,
    method: 'post',
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}
export const memberDetail = (
  params: MemebershipTypes.MemberDataDetialDetial
): Promise<AxiosResponse<ResponseTypes.PagedResponse<MemebershipTypes.MemberDataDetailBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.memberDetail,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const memberCount = (
  params: TierTypes.MemberCountParamType
): Promise<AxiosResponse<ResponseTypes.ResponseData<MemebershipTypes.MemberCountDetails>>> => {
  const requestOptions: HttpRequestType = {
    url: `${ServiceConfig.memberCount}=${params.partnerCode}`,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveMemberDetail = (
  data: MemebershipTypes.MembershipDataBL,
  params: MemebershipTypes.MemberDataCustomerId={}
): Promise<AxiosResponse<ResponseTypes.ResponseData<MemebershipTypes.MembershipDataBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.saveMember,
    data: data,
    params:params,
    method: 'put',
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const getPromotionsForMember = (
  params: PromotionsTypes.PromotionSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getPromotionsForMember,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}


const MembershipService = {
  searchMember,
  MemberStatus,
  PutMemberStatus,
  PutMemberTier,
  memberDetail,
  adjustPoints,
  memberCount,
  saveMemberDetail,
  postOptedMemberData,
  getPromotionsForMember,
}

export default MembershipService
