import {Space} from 'antd'
import Breadcrumbs from 'lib/components/Breadcrumbs'
import PartnerService from 'app/services/PartnerService'
import PromotionService from 'app/services/PromotionService'
import {useEffect, useRef, useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import PromotionHeader, {PromotionHeaderRefType} from './PromotionHeader'
import Steps from './Steps'
import {getPromotionRequestObj, isApprovedWithPastDate, PromotionApiData, ReviewSteps, updateApprovalManagers, updatePromotionRequestObj, updateTierDataForReview} from './utils'
import {FormTypes} from 'lib/components/Form/Form'
import {StyledStepsProps} from 'lib/components/Steps/Steps'
import LoadingPanel from 'lib/components/LoadingPanel'
import Appconfig from 'app/common/helpers/AppConfig'
import UserManagementService from 'app/services/UserManagementService'

export type CreatePromotionParam = {
  promotionId: string | 'new' | 'view'
}
interface CreatePromotionProps {
  isViewOnly?: boolean
}
const CreatePromotion: React.FC<CreatePromotionProps> = ({isViewOnly}) => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const headerRef = useRef<PromotionHeaderRefType>(null)
  const {promotionId} = useParams<CreatePromotionParam>()
  const actualPromotionId = atob(promotionId || '')
  const [params] = useSearchParams()
  const memberId = params.get('memberId') || ''
  const showBreadCrumb = Boolean(memberId)
  const [partnerCodeOptions, setPartnerCodeOptions] = useState<FormTypes.Options[]>([])
  const [loadingPartners, setLoadingPartners] = useState<boolean>(true)
  const [loadingPromotions, setLoadingPromotions] = useState<boolean>(false)

  const [individualPromotion, setIndividualPromotion] = useState<PromotionsTypes.PromotionResponseBL>()

  const [isHeaderDisabled, setDisableHeader] = useState(false)

  const [isRefreshSteps, setIsRefreshSteps] = useState(false)
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false)

  const updatePromotionRequestData = (data: PartnerTypes.PartnerBL[]) => {
    const partnerCodeFromRequest = getPromotionRequestObj.partnerCode
    const defaultCode = data.find((partnerOption) => partnerOption.isDefaultPartner)
    if (partnerCodeFromRequest) {
      const hasPartnerCode = data.find((partnerOption) => partnerOption.partnerCode === partnerCodeFromRequest)
      if (!hasPartnerCode) {
        getPromotionRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
      }
    } else {
      getPromotionRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
    }
  }

  const getPartnerCodes = () => {
    setLoadingPartners(true)
    PartnerService.getPartners()
      .then((res) => {
        const data = res.data.data
        const partnerOptions = data.map((item) => ({value: item.partnerCode, label: item.partnerName}))
        updatePromotionRequestData(data)
        setPartnerCodeOptions(partnerOptions)
      })
      .finally(() => {
        setLoadingPartners(false)
      })
  }

  const getUserDetails = (): void => {
    UserManagementService.searchUser({...initialPaginationObj, isAll: true, status: 'Active'}).then((res) => {
      const data = res.data.data
      updateApprovalManagers(data)
    }).finally(() => {
      setIsLoadingUser(false)
    })
  }

  useEffect(() => {
    getPartnerCodes()
    getUserDetails()
    return () => {
      updateTierDataForReview(true, 0, [], true)
      PromotionApiData.productCategory = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (promotionId !== 'new') {
      setLoadingPromotions(true)
      PromotionService.search({PromotionCode: actualPromotionId})
        .then((res) => {
          const data = res.data.data[0]
          setIndividualPromotion(data)
          updatePromotionRequestObj(data)
        })
        .finally(() => {
          setLoadingPromotions(false)
        })
    }
  }, [actualPromotionId])

  if (!actualPromotionId) {
    return (
      <Space size="large">
        <div>Promotion id is not valid</div>
      </Space>
    )
  }

  const handleValidateHeader = () => {
    headerRef.current?.validate()
  }

  const handleHeaderDisable = () => {
    setDisableHeader(true)
  }

  const handlePartnerCodeChange = (value: string) => {
    setIsRefreshSteps(true)
    setTimeout(() => {
      setIsRefreshSteps(false)
    }, 500)
  }

  const refreshStepsData = () => {
    // setIsRefreshSteps(true)
    getPromotionRequestObj.promotionRuleData.whoData = {
      isAllMembers: true,
      memberCode: null,
      tierData: [],
      promotionSegment: null,
      memberOptIns: false,
    }
    setTimeout(() => {
      setIsRefreshSteps(false)
    }, 1000)
  }

  const resetPromotionName = () => {
    headerRef.current?.resetPromotionName()
  }


  const updatePromotionName = () => {
    headerRef.current?.updatePromotionName()
  }

  const renderSteps = () => {
    if (loadingPromotions) {
      return <LoadingPanel />
    }
    if (promotionId !== 'new' && !individualPromotion) {
      return <div>No promotion found with {actualPromotionId}</div>
    }
    let steps = ReviewSteps
    const isDisableEdit = isApprovedWithPastDate(getPromotionRequestObj) || getPromotionRequestObj.promotionStatusCode.toUpperCase() === 'PENDING'
    if (promotionId !== 'new' || isViewOnly) {
      steps = steps.map((item) => {
        const obj: StyledStepsProps = {...item, isActive: false, isCompleted: true}
        if (item.id === 4) {
          obj.isActive = true
          obj.isCompleted = false
        } else if (isDisableEdit && item.id !==1) {
          obj.disabled = true
        }
        return obj
      })
    }
    // const isDisabelHeader = (isViewOnly: boolean | undefined) => {
    //   if (actualPromotionId !== 'new') {
    //     return true
    //   }
    //   return isViewOnly
    // }

    return (
      <>
        <PromotionHeader
          ref={headerRef}
          isHeaderDisabled={isHeaderDisabled}
          loadingPartners={loadingPartners}
          partnerCodeOptions={partnerCodeOptions}
          isViewOnly={isViewOnly}
          refreshStepsData={refreshStepsData}
          handlePartnerCodeChange={handlePartnerCodeChange}
        />
        {isRefreshSteps && isLoadingUser ? (
          <Space size="large">
            <LoadingPanel />
          </Space>
        ) : (
          <Steps
            isViewOnly={isViewOnly}
            disableHeader={handleHeaderDisable}
            validateHeader={handleValidateHeader}
            steps={steps}
            isPutCall={isHeaderDisabled}
            showSteps={!showBreadCrumb}
            resetPromotionName={resetPromotionName}
            updatePromotionName={updatePromotionName}
          />
        )}
      </>
    )
  }

  return (
    <div>
      {!showBreadCrumb && <Breadcrumbs backLinkLabel="Promotions" backLinkPath={`/promotions`} activePageLabel="Create promotion" />}
      {renderSteps()}
    </div>
  )
}

export default CreatePromotion
