import {useNavigate, useParams} from 'react-router-dom'
import {AddNewSegmentParam} from '../AddNewSegment'
import {useEffect, useRef, useState} from 'react'
import LoadingPanel from 'lib/components/LoadingPanel'
import Typography from 'lib/components/Typography'
import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import SegmentMemberFilter, {segmentMemberFormRefType} from './SegmentMemberFilter'
import Appconfig from 'app/common/helpers/AppConfig'
import SearchResults from './SearchResults'
import {isEmpty} from 'lodash'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import SegmentationService from 'app/services/SegmentationService'

const SegmentMembers = () => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const initialResponse: ResponseTypes.PagedResponse<SegmentationTypes.SegmentSearchResponse[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialSegmentResponse: ResponseTypes.PagedResponse<SegmentationTypes.SegmentResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const {segmentId} = useParams<AddNewSegmentParam>()
  const actualSegmentId = atob(segmentId || '')
  const initialSearchParms: SegmentationTypes.SegmentSearchParam = {SegmentCode: actualSegmentId}
  const segmentFormRef = useRef<segmentMemberFormRefType>(null)
  const navigate = useNavigate()
  const [loadingSegment, setLoadingSegment] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isFormReset, setIsFormReset] = useState(true)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)
  const [individualSeg, setIndividualSeg] = useState<ResponseTypes.PagedResponse<SegmentationTypes.SegmentResponseBL[]>>(initialSegmentResponse)
  const [segmentData, setSegmentData] = useState<ResponseTypes.PagedResponse<SegmentationTypes.SegmentSearchResponse[]>>(initialResponse)

  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const getSegmentName = () => {
    const reqParam: SegmentationTypes.SegmentSearchParam = {
      SegmentCode: actualSegmentId,
    }
    SegmentationService.search(reqParam).then((res) => {
      const data = res.data
      setIndividualSeg(data)
    })
  }

  const handleSearch = (params: SegmentationTypes.SegmentSearchParam) => {
    const reqParam: SegmentationTypes.SegmentSearchParam = {
      // IsCombinable: false,
      ...params,
      SegmentCode: actualSegmentId,
      Strict: false,
    }
    setIsFetching(true)
    SegmentationService.segmentMembers(reqParam)
      .then((res) => {
        const data = res.data
        setSegmentData(data)
      })
      .finally(() => {
        setLoadingSegment(false)
        setIsFetching(false)
      })
  }

  useEffect(() => {
    if (actualSegmentId) {
      setLoadingSegment(true)
      getSegmentName()
      handleSearch({
        ...initialSearchParms,
        ...initialPaginationObj,
        SegmentCode: actualSegmentId,
      })
    }
  }, [])

  const handleBack = () => {
    navigate(-1)
  }

  const handleResetSearch = () => {
    segmentFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const handleFilter = (data: SegmentationTypes.SegmentMemberFilter) => {
    const filterParms: SegmentationTypes.SegmentMemberParams = {}
    filterParms.MembershipFullName = data.memberName
    filterParms.MembershipEmailId = data.email
    filterParms.MembershipStatusKey = data.status
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterParms, ...newPaginationObj, SegmentCode: actualSegmentId})
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }
    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  const handleRefresh = (value: boolean, segmentCode: string) => {
    // const updatedRewardsData = cloneDeep(segmentData)
    // const updatedRowIndex = segmentData.data.findIndex((item) => item.segmentCode === segmentCode)
    // updatedRewardsData.data[updatedRowIndex] = {
    //   ...updatedRewardsData.data[updatedRowIndex],
    // }
    // setSegmentData(updatedRewardsData)
  }

  const {count = 0, pageIndex = 0} = segmentData.pageInfo

  if (loadingSegment) {
    return <LoadingPanel />
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Segmentation</Typography.Title>
        <Button onClick={handleBack} type="link">
          Back
        </Button>
      </StyledHeader>
      <MarginBottomWrapper>
        <Typography.Title level={4} color="default" uppercase>
          {individualSeg?.data[0]?.segmentName || ''}
        </Typography.Title>
      </MarginBottomWrapper>
      <DataTable>
        <SegmentMemberFilter ref={segmentFormRef} isFetching={isFetching} handleFilter={handleFilter} />
        <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} />
        <SearchResults isFetching={isFetching} segmentsResponse={segmentData} handleSearch={handleRefresh} />
        <DataTable.Pagination
          onChange={handleOnChange}
          current={pageIndex}
          pageSizeOptions={pageSizeOptions}
          total={count}
          defaultPageSize={initialPaginationObj.PageCount}
          pageSize={paginationObj.PageCount}
          showSizeChanger
        />
      </DataTable>
    </section>
  )
}

export default SegmentMembers
