import Appconfig from "app/common/helpers/AppConfig"
import { FormTypes } from "lib/components/Form/Form"
import { cloneDeep } from "lodash"

export const initialPointRequestObj: PointType.PointMasterResponseBL = {
  rowStatus: Appconfig.rewards.defaultValus.rowStatus,
  pointName: '',
  pointCode: '',
  pointDescription: '',
  partnerCode: '',
  pointStatus: Appconfig.segment.defaultValues.segmentStatus,
  enabled:true,
  isRedemptionPointType: true,
  pointTypeCode: '',
  isPointLocked: false,
  pointLockInDays: 0,   
  pointCurrencyValue: [
    {
      "currencyCode": "USD",
      "costPerPoint": 1
    }
  ],
  isDefaultPoint: true,
  isExpired: false,
  expiryTrigger: "",
  triggerType: null,
  expiryEndDateType: null,
  expiryFixedPeriod: 0,
  expiryHardDate: null,
  pointLife: 0,
  properties: {},
}

export let getPointRequestObj: PointType.PointMasterResponseBL = cloneDeep(initialPointRequestObj)

export const updatePointRequestObj = (data: PointType.PointMasterResponseBL) => {
  getPointRequestObj = cloneDeep(data)
}

// export const pointTypeOptions: FormTypes.Options[] = [
//   { label: 'Cashback', value: 'cashback' },
//   { label: 'Status', value: 'status'},
//   { label: 'Bonus', value: 'bonus'}
// ]

export const pointStatusOptions: FormTypes.Options[] = [
  { label: 'Redeemable', value: 'redeemable' },
  { label: 'Non-Redeemable', value: 'nonredeemable'},

]

export const pointCurrencyOptions: FormTypes.Options[] = [
  { label: 'USD', value: 'USD' },
]

// export const pointsResult = [{
//   rowStatus: Appconfig.rewards.defaultValus.rowStatus,
//   pointName: 'Point 1',
//   pointCode: 'point1',
//   pointDescription: 'This is Point 1',
//   partnerCode: '',
//   pointStatus: Appconfig.segment.defaultValues.segmentStatus,
//   enabled:true,
//   isRedemptionPointType: true,
//   pointTypeCode: 'Cashback',
//   isPointLocked: false,
//   pointLockInDays: 0,   
//   pointCurrencyValue: [
//     {
//       "currencyCode": "USD",
//       "costPerPoint": 1
//     }
//   ],
//   isDefaultPoint: true,
//   isExpired: false,
//   expiryTrigger: "",
//   triggerType: null,
//   expiryEndDateType: null,
//   expiryFixedPeriod: 0,
//   expiryHardDate: null,
//   pointLife: 0,
//   properties: {},
// }, {
//   rowStatus: Appconfig.rewards.defaultValus.rowStatus,
//   pointName: 'Point 2',
//   pointCode: 'point2',
//   pointDescription: 'This is Point 2',
//   partnerCode: '',
//   pointStatus: Appconfig.segment.defaultValues.segmentStatus,
//   enabled:true,
//   isRedemptionPointType: true,
//   pointTypeCode: 'Base',
//   isPointLocked: false,
//   pointLockInDays: 0,   
//   pointCurrencyValue: [
//     {
//       "currencyCode": "USD",
//       "costPerPoint": 1
//     }
//   ],
//   isDefaultPoint: true,
//   isExpired: false,
//   expiryTrigger: "",
//   triggerType: null,
//   expiryEndDateType: null,
//   expiryFixedPeriod: 0,
//   expiryHardDate: null,
//   pointLife: 0,
//   properties: {},
// }, {
//   rowStatus: Appconfig.rewards.defaultValus.rowStatus,
//   pointName: 'Point 3',
//   pointCode: 'point3',
//   pointDescription: 'This is Point 3',
//   partnerCode: '',
//   pointStatus: Appconfig.segment.defaultValues.segmentStatus,
//   enabled:true,
//   isRedemptionPointType: true,
//   pointTypeCode: 'Bonus',
//   isPointLocked: false,
//   pointLockInDays: 0,   
//   pointCurrencyValue: [
//     {
//       "currencyCode": "USD",
//       "costPerPoint": 1
//     }
//   ],
//   isDefaultPoint: true,
//   isExpired: false,
//   expiryTrigger: "",
//   triggerType: null,
//   expiryEndDateType: null,
//   expiryFixedPeriod: 0,
//   expiryHardDate: null,
//   pointLife: 0,
//   properties: {},
// }, {
//   rowStatus: Appconfig.rewards.defaultValus.rowStatus,
//   pointName: 'Point 4',
//   pointCode: 'point4',
//   pointDescription: 'This is Point 4',
//   partnerCode: '',
//   pointStatus: Appconfig.segment.defaultValues.segmentStatus,
//   enabled:true,
//   isRedemptionPointType: true,
//   pointTypeCode: 'Status',
//   isPointLocked: false,
//   pointLockInDays: 0,   
//   pointCurrencyValue: [
//     {
//       "currencyCode": "USD",
//       "costPerPoint": 1
//     }
//   ],
//   isDefaultPoint: true,
//   isExpired: false,
//   expiryTrigger: "",
//   triggerType: null,
//   expiryEndDateType: null,
//   expiryFixedPeriod: 0,
//   expiryHardDate: null,
//   pointLife: 0,
//   properties: {},
// }]