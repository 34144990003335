import {Button, Col, Row, Tooltip} from 'antd'
import Typography from 'lib/components/Typography'
import {ReviewRow} from './Review.styled'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import {getRewardsCode, getRewardsName} from '../../utils'

interface IncentiveDetailssProps {
  data: PromotionsTypes.IncentiveData[]
  onEdit(stepId: number): void
  isViewOnly?: boolean
  isDisabelEdit?: boolean
  showEdit: boolean
}

const IncentiveDetails: React.FC<IncentiveDetailssProps> = ({data, onEdit, isViewOnly, isDisabelEdit, showEdit}) => {
  const renderRewardValue = (action: PromotionsTypes.IncentiveActionType, fixedPoint: number, multiplier: number) => {
    if (action === 'FIXED') {
      return <div>{fixedPoint} Points</div>
    }
    return <div>{multiplier} Per dollar spent</div>
  }
  return (
    <ReviewRow>
      <div style={{width: '100%'}}>
        <Row style={{marginBottom: '24px'}}>
          <Typography.Text strong uppercase>
            Incentive details
          </Typography.Text>
        </Row>
        <>
          {data.map((incentive) => {
            const {action, pointCode, fixedPoint, multiplier, referee, receiver, rewardData} = incentive
            return (
              <MarginBottomWrapper>
                <Row style={{width: '100%'}}>
                  <Col span={6}>
                    <Typography.Text uppercase size="small">
                      Award
                    </Typography.Text>
                    {pointCode ? <div>{pointCode?.pointName}</div> : <div>Reward</div>}
                  </Col>
                  <>
                    {rewardData ? (
                      <Col span={6}>
                        <Typography.Text uppercase size="small">
                          Rewards
                        </Typography.Text>
                        <div>{`${getRewardsName(rewardData)}-${getRewardsCode(rewardData)}`}</div>
                      </Col>
                    ) : (
                      <>
                        <Col span={6}>
                          <Typography.Text uppercase size="small">
                            Type
                          </Typography.Text>
                          <div>{action}</div>
                        </Col>
                        {referee && receiver ? (
                          <>
                            <Col span={6}>
                              <Typography.Text uppercase size="small">
                                Quantity For{' '}
                                <Tooltip title="The Referrer is the party that initates the referral: the person who sends out the invitation to join the program.">
                                  Referrer
                                </Tooltip>
                              </Typography.Text>
                              <div>{referee.fixedPoint} Points</div>
                            </Col>
                            <Col span={6}>
                              <Typography.Text uppercase size="small">
                                Quantity For{' '}
                                <Tooltip title="The Referee is the party who receives the referral invitation, and can join the program as a result of the referral.">
                                  Referee
                                </Tooltip>
                              </Typography.Text>
                              <div>{receiver.fixedPoint} Points</div>
                            </Col>
                          </>
                        ) : (
                          <Col span={6}>
                            <Typography.Text uppercase size="small">
                              Quantity
                            </Typography.Text>
                            {renderRewardValue(action, fixedPoint, multiplier)}
                          </Col>
                        )}
                      </>
                    )}
                  </>
                </Row>
              </MarginBottomWrapper>
            )
          })}
        </>
      </div>
      <div>
        {!isViewOnly && showEdit && (
          <Button onClick={() => onEdit(3)} size="small" type="link" disabled={isDisabelEdit}>
            Edit
          </Button>
        )}
      </div>
    </ReviewRow>
  )
}

export default IncentiveDetails
