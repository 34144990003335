import {FormTypes} from 'lib/components/Form/Form'
import {cloneDeep} from 'lodash'

export const inititalUserRequestObj: UserManagementTypes.UserListResponseType = {
  userName: '',
  id: '',
  email: '',
  jobTitle: '',
  phoneNumber: '',
  password: '',
  roles: [],
  manager: [],
  status: 'Active',
  firstName: '',
  lastName: '',
  middleName: '',
  properties: {},
  assignedComponent: [],
  isInventoryNotification: false,
}

export let getUsersRequestObj: UserManagementTypes.UserListResponseType = cloneDeep(inititalUserRequestObj)

export const updateUserRequestObj = (data: UserManagementTypes.UserListResponseType) => {
  getUsersRequestObj = cloneDeep(data)
}

export const UserRolesOptions: FormTypes.Options[] = [
  {label: 'Role1', value: 'Role1'},
  {label: 'Role2', value: 'Role2'},
  {label: 'Role3', value: 'Role3'},
  {label: 'Role4', value: 'Role4'},
  {label: 'Role5', value: 'Role5'},
]

export const UserManagerOptions: FormTypes.Options[] = [
  {label: 'Manager1', value: 'Manager1'},
  {label: 'Manager2', value: 'Manager2'},
  {label: 'Manager3', value: 'Manager3'},
  {label: 'Manager4', value: 'Manager4'},
  {label: 'Manager5', value: 'Manager5'},
]

export let UserResponseList: UserManagementTypes.UserInfoType[] = []

export const updateUserListResponse = (data: UserManagementTypes.UserInfoType[]) => {
  UserResponseList = data
}

export const userStatus = {
  ACTIVE: 'Active',
  DEACTIVE: 'Deactive',
  REGISTERED: 'Registered',
  LOCKED: 'Locked',
}
