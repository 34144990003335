import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const memberBalance = (
  params: MemberPointManagmentTypes.MemberPointDetail
): Promise<AxiosResponse<ResponseTypes.ResponseData<MemberPointManagmentTypes.MemberPointData[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.memberBalance,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}
export const memberMerge = (
  params: MemberPointManagmentTypes.MemberMergeDetails
): Promise<AxiosResponse<ResponseTypes.ResponseData<MemberPointManagmentTypes.MemberMergeBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.memberMerge,
    params,
    method: 'post',
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

const MemberPointManagementService = {
  memberBalance,
  memberMerge,
}

export default MemberPointManagementService
