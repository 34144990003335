import {Checkbox as AntCheckbox} from 'antd'
import {FormTypes} from '../Form'
import FormLabel from '../FormLabel'
import {CheckboxGroupWrapper} from './Checkbox.Styled'
import {CheckboxValueType} from 'antd/es/checkbox/Group'

const Checkbox: React.FC<FormTypes.Checkbox> = (props) => {
  const {options} = props

  const handleOnChange = (checkedValues: CheckboxValueType[]) => {
    props.onChange(checkedValues, props.id || 'form-checkbox')
  }

  return (
    <>
      {props.label && props.id && <FormLabel id={props.id} label={props.label} />}
      <CheckboxGroupWrapper $isVertical={props.isVerticalView} $hideText={props.hideText} $isDark={props.isDark}>
        <AntCheckbox.Group
          name={props.id}
          disabled={props.disabled}
          value={props.value}
          className="checkbox-group"
          options={options}
          onChange={handleOnChange}
        />
      </CheckboxGroupWrapper>
    </>
  )
}

export default Checkbox
