import {Cell, CellWrapper, TableHeaderContainer, TableHEaderTitle} from '../DataTable.styled'

const TableHeader = ({columns}: DataTableTypes.TableHeaderProps) => {
  const renderCol = () => {
    return columns.map((col) => {
      return (
        <CellWrapper key={col.id} $width={col.width}>
          <Cell $textAlign={col.textAlign} key={col.id}>
            <TableHEaderTitle>{col.title}</TableHEaderTitle>
          </Cell>
        </CellWrapper>
      )
    })
  }
  return <TableHeaderContainer>{renderCol()}</TableHeaderContainer>
}

export default TableHeader
