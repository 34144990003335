import {DataTableContainer} from './DataTable.styled'
import Filter from './Filter'
import Pagination from './Pagination'
import Rows from './Rows'
import Search from './Search'
import TableHeader from './TableHeader'
import TotalResult from './TotalResult'

type TableSubComponents = {
  TotalResult: typeof TotalResult
  Search: typeof Search
  Rows: typeof Rows
  Pagination: typeof Pagination
  Header: typeof TableHeader
  Filter: typeof Filter
}

export interface DataTableProps {
  children?: React.ReactNode
}

type TablePropsType = DataTableProps

const DataTable: React.FunctionComponent<TablePropsType> & TableSubComponents = ({children}: DataTableProps) => {
  return <DataTableContainer>{children}</DataTableContainer>
}

DataTable.TotalResult = TotalResult
DataTable.Search = Search
DataTable.Header = TableHeader
DataTable.Rows = Rows
DataTable.Pagination = Pagination
DataTable.Filter = Filter
export default DataTable
