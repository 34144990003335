import Appconfig from 'app/common/helpers/AppConfig'
import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const searchRoles = (
  params: UserManagementTypes.RoleSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<UserManagementTypes.RoleType[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getUserRoles,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveRole = (
  data: UserManagementTypes.RoleResponseType,
  params: UserManagementTypes.RoleSearchParam | undefined
): Promise<AxiosResponse<ResponseTypes.PagedResponse<UserManagementTypes.RoleResponseType>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.saveRole,
    data,
    params,
    withCredentials: false,
    method: params?.roleCode ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

export const searchUser = (
  params: UserManagementTypes.UserSearchParams
): Promise<AxiosResponse<ResponseTypes.PagedResponse<UserManagementTypes.UserInfoType[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getUsers,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveUser = (
  data: UserManagementTypes.UserListResponseType,
  params: UserManagementTypes.UserSearchParams | undefined
): Promise<AxiosResponse<ResponseTypes.PagedResponse<UserManagementTypes.UserListResponseType>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.saveUser,
    data,
    params,
    withCredentials: false,
    method: params?.userName ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

export const searchFunctions = (
  params: UserManagementTypes.FunctionSearchParams
): Promise<AxiosResponse<ResponseTypes.PagedResponse<UserManagementTypes.FunctionListResponseType[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getFunctions,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}
export const searchCategories = (
  params: UserManagementTypes.CategorySearchParams
): Promise<AxiosResponse<ResponseTypes.ResponseData<UserManagementTypes.CategoriesResponseType[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getCategories,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}
export const forgetToken = (body: UserManagementTypes.ForgotToken): Promise<AxiosResponse<ResponseTypes.ResponseData<boolean>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.forgotToken,
    method: 'post',
    data: body,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const deleteRoleFunction = (
  params: UserManagementTypes.FunctionSearchParams
): Promise<AxiosResponse<ResponseTypes.PagedResponse<UserManagementTypes.FunctionListResponseType[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.removeRoleFunction,
    params,
    withCredentials: false,
    method: 'DELETE',
  }
  return HttpRequest(requestOptions)
}

export const deleteUserRole = (
  params: UserManagementTypes.UserSearchParams
): Promise<AxiosResponse<ResponseTypes.PagedResponse<UserManagementTypes.UserListResponseType[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.removeUserRole,
    params,
    withCredentials: false,
    method: 'DELETE',
  }
  return HttpRequest(requestOptions)
}

export const validateResetToken = (
  body: UserManagementTypes.ValidateToken
): Promise<AxiosResponse<ResponseTypes.ResponseData<UserManagementTypes.ResetTokenStatus>>> => {
  let referralUrl = window.location.host
  if (window.location.host.includes('localhost')) {
    referralUrl = Appconfig.REFERRALURL
  }
  const updatedPayload = {
    ...body,
    referralUrl,
  }
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.validateResetToken,
    method: 'post',
    data: updatedPayload,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

const UserManagementService = {
  searchRoles,
  searchFunctions,
  searchUser,
  saveRole,
  searchCategories,
  saveUser,
  deleteRoleFunction,
  deleteUserRole,
  forgetToken,
  validateResetToken,
}

export default UserManagementService
