import {MainContainer} from 'app/common/components/Styled/common.styled'
import {Navigate, Outlet, useParams} from 'react-router-dom'
import { MemberDetailParam } from './MemberDetail'
import { useAuth } from '../Auth/AuthContext'
import { MEMBER_DETAIL } from 'app/common/helpers/UserFunctions'

const Member = (): JSX.Element => {
  const {membershipId} = useParams<MemberDetailParam>()
  const membershipCustomerId = atob(membershipId || '')
  const {roles} = useAuth()
  const showMemberDetail = roles.includes(MEMBER_DETAIL)
  if (!showMemberDetail && membershipCustomerId) {
    return <Navigate replace to="/unauthorized" />
  }

  return (
    <MainContainer>
      <Outlet />
    </MainContainer>
  )
}

export default Member
