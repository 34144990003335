import {ResetSearchContainer, TotalResultContainer, TotalResultRight, TotalSearchResultsWrapper} from './TotalResult.styled'

const TotalResult = ({noOfResults, rowsPerPageOptions, resetSearch, showSearch = true, showTable = true, showFilter = false, filter}: DataTableTypes.TotalResultProps) => {
  const renderPerPageDropdown = () => {
    const options = rowsPerPageOptions?.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ))
    return (
      <select name="total-rows-per-page" id="totalRowsPerPage">
        {options}
      </select>
    )
  }
  return (
    <TotalResultContainer>
      {showTable && (
        <>
          {noOfResults ? (
            <TotalSearchResultsWrapper>
              <span>
                <strong>{noOfResults}</strong> Search results
              </span>
            </TotalSearchResultsWrapper>
          ) : (
            <TotalSearchResultsWrapper>
              <span>No Search results</span>
            </TotalSearchResultsWrapper>
          )}
        </>
      )}
      {showSearch && <TotalResultRight>
        {resetSearch && <ResetSearchContainer>{resetSearch}</ResetSearchContainer>}
        {showFilter && <>{filter}</>}
        {rowsPerPageOptions && (
          <TotalSearchResultsWrapper>
            <label htmlFor="total-rows-per-page">Results per page</label>
            {renderPerPageDropdown()}
          </TotalSearchResultsWrapper>
        )}
      </TotalResultRight>}
    </TotalResultContainer>
  )
}

export default TotalResult
