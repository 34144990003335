import {RouteObject} from 'react-router'
import {ErrorBoundry} from '../common/components/Routes/Fallback'
import CreatePromotion from 'app/pages/Promotions/CreatePromotion'
import PromotionsLayout from 'app/pages/Promotions/PromotionsLayout'
import Dashboard from 'app/pages/Promotions/Dashboard'

const PromotionsRoutes: RouteObject = {
  path: `promotions`,
  element: <PromotionsLayout />,
  errorElement: <ErrorBoundry />,
  children: [
    {index: true, element: <Dashboard />},
    {path: ':promotionId', element: <CreatePromotion />},
    {path: 'view/:promotionId', element: <CreatePromotion isViewOnly />},
  ],
}

export default PromotionsRoutes
