import { cloneDeep } from "lodash"

export let initialCategoryRequestObj: RewardCategoryTypes.RewardCategoryResponseBL = {
  categoryName: '',
  categoryCode: '',
  categoryDescription: '',
  partnerCode: '',
  categoryGroup:'',
  clientCode: "1",
 // properties: {},
}

export let getCategoryRequestObj: RewardCategoryTypes.RewardCategoryResponseBL = cloneDeep(initialCategoryRequestObj)

export const updateCategoryRequestObj = (data: RewardCategoryTypes.RewardCategoryResponseBL) => {
  getCategoryRequestObj = cloneDeep(data)
}