const IconStatus = ({size = 16}: IconType.Props) => {
  return (
    <svg width={size - 3} height={size}>
      <g transform="matrix(1 0 0 1 -287 -25 )">
        <path
          d="M 5.813664596273292 7.992000000000001  C 6.193167701863355 7.612  6.806832298136646 7.612  7.186335403726709 7.9879999999999995  L 12.677018633540373 13.427999999999999  C 13.056521739130435 13.803999999999998  13.056521739130435 14.412  12.677018633540373 14.783999999999999  L 11.764596273291923 15.687999999999999  C 11.385093167701863 16.064  10.771428571428569 16.064  10.395962732919255 15.687999999999999  L 6.5 11.835999999999999  L 2.608074534161491 15.691999999999998  C 2.228571428571428 16.067999999999998  1.6149068322981364 16.067999999999998  1.2394409937888196 15.691999999999998  L 0.32298136645962733 14.788  C -0.056521739130434796 14.412  -0.056521739130434796 13.803999999999998  0.32298136645962733 13.432  L 5.813664596273292 7.992000000000001  Z M 7.178260869565218 0.30799999999999955  C 6.806832298136646 -0.0680000000000004  6.193167701863355 -0.0680000000000004  5.813664596273292 0.30799999999999955  L 0.32298136645962733 5.747999999999999  C -0.056521739130434796 6.124  -0.056521739130434796 6.731999999999999  0.32298136645962733 7.104  L 1.2354037267080746 8.008  C 1.6149068322981366 8.383999999999999  2.2285714285714286 8.383999999999999  2.604037267080745 8.008  L 6.495962732919255 4.151999999999999  L 10.387888198757764 8.008  C 10.767391304347825 8.383999999999999  11.38105590062112 8.383999999999999  11.756521739130434 8.008  L 12.668944099378884 7.104  C 13.048447204968944 6.728  13.048447204968944 6.12  12.668944099378884 5.747999999999999  L 7.178260869565218 0.30799999999999955  Z "
          fill-rule="nonzero"
          fill="#000000"
          stroke="none"
          transform="matrix(1 0 0 1 287 25 )"
        />
      </g>
    </svg>
  )
}

export default IconStatus
