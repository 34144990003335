import HttpRequest from 'app/common/helpers/HttpRequest'
import { HttpRequestType } from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import { AxiosResponse } from 'axios'

export const getTiers = (params?: TierTypes.TierSearch): Promise<AxiosResponse<ResponseTypes.ResponseData<TierTypes.TierSystemInfo[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.tier,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveTierSystem = (
  data: TierTypes.TierMasterResponseBL,
  params: TierTypes.TierRequestParams
): Promise<AxiosResponse<ResponseTypes.PagedResponse<TierTypes.TierMasterResponseBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.saveTier,
    params,
    data,
    withCredentials: false,
    method: params.tierSystemKey ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

export const deleteTier = (
  params: TierTypes.TierRequestParams
): Promise<AxiosResponse<ResponseTypes.PagedResponse<boolean>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.tier,
    params,
    withCredentials: false,
    method: 'DELETE',
  }
  return HttpRequest(requestOptions)
}

const TierService = {
  getTiers,
  saveTierSystem,
  deleteTier
}

export default TierService
