import {RouteObject} from 'react-router'
import {ErrorBoundry} from '../common/components/Routes/Fallback'
import Segmentation from 'app/pages/Segmentation'
import SegmentationDashboard from 'app/pages/Segmentation/Dashboard'
import AddNewSegment from 'app/pages/Segmentation/AddNewSegment'
import SegmentMembers from 'app/pages/Segmentation/SegmentMembers'

const SegmentationRoutes: RouteObject = {
  path: `segments`,
  element: <Segmentation />,
  errorElement: <ErrorBoundry />,
  children: [
    {index: true, element: <SegmentationDashboard />},
    {path: ':segmentId', element: <AddNewSegment />},
    {path: 'view/:segmentId', element: <AddNewSegment isViewOnly={true} />},
    {path: ':segmentId/members', element: <SegmentMembers />},
  ],
}

export default SegmentationRoutes
