const IconFilter = ({size = 20, color = 'currentColor'}: IconType.Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g transform="matrix(1 0 0 1 -60 -34 )">
        <path
          d="M 19.06515580736544 0  C 19.46175637393768 0  19.740321057601513 0.18465909090909172  19.900849858356942 0.5539772727272729  C 20.06137865911237 0.9422348484848464  19.995278564683666 1.2736742424242409  19.702549575070822 1.5482954545454541  L 12.71954674220963 8.551136363636365  L 12.71954674220963 19.090909090909093  C 12.71954674220963 19.488636363636363  12.535410764872521 19.767992424242426  12.167138810198301 19.928977272727273  C 12.044381491973562 19.976325757575758  11.926345609065157 20  11.813031161473088 20  C 11.558073654390935 20  11.34560906515581 19.910037878787882  11.175637393767705 19.730113636363637  L 7.549575070821529 16.09375  C 7.3701605288007555 15.913825757575758  7.280453257790368 15.700757575757574  7.280453257790368 15.454545454545453  L 7.280453257790368 8.551136363636365  L 0.2974504249291785 1.5482954545454541  C 0.004721435316336162 1.2736742424242409  -0.061378659112370164 0.9422348484848464  0.09915014164305949 0.5539772727272729  C 0.25967894239848915 0.18465909090909172  0.5382436260623229 0  0.9348441926345609 0  L 19.06515580736544 0  Z "
          fillRule="nonzero"
          fill="#0062ff"
          stroke="none"
          transform="matrix(1 0 0 1 60 34 )"
        />
      </g>
    </svg>
  )
}

export default IconFilter
