import { useAuth } from 'app/pages/Auth/AuthContext'
import { Fragment, memo, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { setRedirectPath } from 'utils/common'


interface AuthGuardProps {
  children: React.ReactNode
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const { isLoggedIn } = useAuth()
  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

  if (!isLoggedIn) {
    if (pathname !== requestedLocation) {
      setRedirectPath(pathname)
      setRequestedLocation(pathname)
    }
    return <Navigate replace to={`/login`} />
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate replace to={requestedLocation} />
  }
  return <Fragment>{children}</Fragment>
}

export default memo(AuthGuard)
