import {DatePicker as AntDatePicker} from 'antd'
import Appconfig from 'app/common/helpers/AppConfig'
import dayjs, {Dayjs} from 'dayjs'
import {DatePickerWrapper as DateRangePickerWrapper} from '../DatePicker/DatePicker.Styled'
import {FormTypes} from '../Form'
import FormLabel from '../FormLabel'

const {RangePicker} = AntDatePicker

const DateRangePicker: React.FC<FormTypes.DateRangePickerTypes> = (props) => {
  const dateFormat = Appconfig.DATE_FORMAT
  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      let startDate = dateStrings[0] || dayjs().format(dateFormat)
      let endDate = dateStrings[1] || dayjs().format(dateFormat)
      props.rangePickerProps?.onChange([startDate, endDate], props.id)
    } else {
      props.rangePickerProps?.onChange([], props.id)
    }
  }

  // const value :null | (Dayjs | null)[] =[]
  // let startDate = null;
  // let endDate = null;

  // if (props.rangePickerProps && props.rangePickerProps.value) {
  //   startDate = dayjs(props.rangePickerProps.value[0], dateFormat)
  //   endDate = dayjs(props.rangePickerProps.value[1], dateFormat)
  // }
  // console.log(props)

  return (
    <DateRangePickerWrapper>
      {props.label && props.id && <FormLabel id={props.id} label={props.label} isDark={props.isDark} />}
      <RangePicker
        allowEmpty={[true, true]}
        allowClear
        format={dateFormat}
        value={
          props.rangePickerProps && props.rangePickerProps.value.length > 0
            ? [dayjs(props.rangePickerProps.value[0]), dayjs(props.rangePickerProps.value[1])]
            : [null, null]
        }
        status={props.status}
        placeholder={['From', 'To']}
        onChange={onRangeChange}
      ></RangePicker>
    </DateRangePickerWrapper>
  )
}

export default DateRangePicker
