import {StyledSpinner} from './LoadingPanel.Styled'

const LoadingPanel = () => {
  return (
    <div>
      <StyledSpinner className='styled-spinner'></StyledSpinner>
    </div>
  )
}

export default LoadingPanel
