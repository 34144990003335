import {Col, Row} from 'antd'
import {RewardDetailsWrapper} from '../Reward.Styled'
import Typography from 'lib/components/Typography'
import ImagePicker from 'app/common/components/ImagePicker'
import FormLabel from 'lib/components/Form/FormLabel'
import {getRewardsRequestObj, updateRewardsRequestObj} from './utils'
import {ImageGallaryStateType} from './NewRewardForm'
import {cloneDeep} from 'lodash'
import {useState} from 'react'
import RewardService from 'app/services/RewardService'

const RewardImageGallary = () => {
  let rewardReqObj = getRewardsRequestObj
  const initalImageGallaryState: ImageGallaryStateType = {
    rewardThumbnail: rewardReqObj.rewardThumbnail,
    rewardPrimaryImage: rewardReqObj.rewardPrimaryImage,
    rewardAdditionalImage: rewardReqObj.rewardAdditionalImage,
  }

  const [imageGallaryState, setImageGallaryData] = useState<ImageGallaryStateType>(initalImageGallaryState)

  const [uploadigFor, setUploadingFor] = useState<keyof ImageGallaryStateType>()

  const uploadImage = (acceptedFile: File, id: keyof ImageGallaryStateType) => {
    setUploadingFor(id)
    // Create a FormData object
    const formData = new FormData()
    // Assuming you're only allowing a single file upload
    formData.append('file', acceptedFile)

    RewardService.saveVoucherImage(acceptedFile, {folderName: 'VoucherCode'})
      .then((res) => {
        const voucherImageUrl = res.data.data
        if (voucherImageUrl) {
          handleImageSelect(voucherImageUrl, id)
        }
      })
      .finally(() => {
        setUploadingFor(undefined)
      })
  }

  const handleImageSelect = (value: string, key: keyof ImageGallaryStateType) => {
    const prevState = cloneDeep(imageGallaryState)
    if (key === 'rewardAdditionalImage') {
      const rewardAdditionalImageAr = prevState.rewardAdditionalImage as string[]
      if (rewardAdditionalImageAr.length <= 3) {
        rewardAdditionalImageAr.push(value as string)
        prevState.rewardAdditionalImage = rewardAdditionalImageAr
      }
    } else {
      prevState[key] = value
    }
    rewardReqObj = {
      ...rewardReqObj,
      [key]: prevState[key],
    }
    updateRewardsRequestObj(rewardReqObj)
    setImageGallaryData(prevState)
  }

  const handeImageDelete = (key: keyof ImageGallaryStateType, index?: number) => {
    const prevState = cloneDeep(imageGallaryState)
    let filePath = []
    let lastItem = ''
    if (key === 'rewardAdditionalImage' && index) {
      filePath = rewardReqObj[key][index - 1].toString().split('/')
      lastItem = filePath[filePath.length - 1]
      prevState.rewardAdditionalImage.splice(index - 1, 1)
    } else if (key !== 'rewardAdditionalImage') {
      filePath = rewardReqObj[key].toString().split('/')
      lastItem = filePath[filePath.length - 1]
      prevState[key] = ''
    }
    RewardService.deleteImage({fileName: lastItem, folderName: 'VoucherCode'})
      .then((res) => {
        const voucherImageUrl = res.data.data
        if (!voucherImageUrl) {
        }
      })
      .finally(() => {
        setUploadingFor(undefined)
      })
    rewardReqObj = {
      ...rewardReqObj,
      [key]: prevState[key],
    }
    updateRewardsRequestObj(rewardReqObj)
    setImageGallaryData(prevState)
  }

  const renderAdditioanlImages = () => {
    const renderHtml = []

    if (imageGallaryState.rewardAdditionalImage.length > 0) {
      imageGallaryState.rewardAdditionalImage.map((image, index) => {
        renderHtml.push(
          <Col span={5} key={index}>
            <ImagePicker
              id={`rewardAdditionalImage_${index}`}
              value={image}
              onDeleteImage={() => handeImageDelete('rewardAdditionalImage', index + 1)}
              uploadImage={(file, key) => uploadImage(file, 'rewardAdditionalImage')}
              uploadingFor={uploadigFor}
            />
          </Col>
        )
        return null
      })
    }
    if (imageGallaryState.rewardAdditionalImage.length !== 4) {
      renderHtml.push(
        <Col span={imageGallaryState.rewardAdditionalImage.length === 0 ? 10 : 5} key="default">
          <ImagePicker
            id={'rewardAdditionalImage_4'}
            onDeleteImage={handeImageDelete}
            uploadImage={(file, key) => uploadImage(file, 'rewardAdditionalImage')}
            uploadingFor={uploadigFor}
          />
        </Col>
      )
    }
    return renderHtml
  }

  return (
    <RewardDetailsWrapper style={{paddingTop: 12, borderBottom: 0}}>
      <Typography.Title level={3} color="primary">
        IMAGE GALLERY
      </Typography.Title>
      <Row gutter={8}>
        <Col span={5}>
          <ImagePicker
            id="rewardThumbnail"
            label="Thumbnail"
            value={imageGallaryState.rewardThumbnail}
            onDeleteImage={handeImageDelete}
            uploadImage={uploadImage}
            uploadingFor={uploadigFor}
          />
        </Col>
        <Col span={5}>
          <ImagePicker
            id="rewardPrimaryImage"
            label="Primary Image"
            value={imageGallaryState.rewardPrimaryImage}
            onDeleteImage={handeImageDelete}
            uploadImage={uploadImage}
            uploadingFor={uploadigFor}
          />
        </Col>
      </Row>
      <FormLabel id="rewardAdditionalImage" label="Additional Images (Maximum of 4 Image)" />
      <Row gutter={8}>{renderAdditioanlImages()}</Row>
    </RewardDetailsWrapper>
  )
}

export default RewardImageGallary
