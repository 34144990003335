import {Button} from 'antd'
import {FormEvent, forwardRef} from 'react'
import {FilterExtendedFormWrapper, FilterFormWrapper, FilterWrapper} from './Filter.styled'
import IconSearch from 'app/common/icons/IconSearch'
export interface FilterProps {
  children: React.ReactNode
  btnLabel?: string
  isFetching: boolean
  handleSubmit(event: FormEvent<HTMLFormElement>): void
  extendedFilters?: boolean
}

export type Ref = HTMLFormElement
const Filter = forwardRef<Ref, FilterProps>(({children, handleSubmit, btnLabel = 'Search', isFetching, extendedFilters}, ref) => {
  if (extendedFilters) {
    return (
      <FilterWrapper>
        <FilterExtendedFormWrapper ref={ref} onSubmit={handleSubmit}>
          {children}
          <Button className="search-btn" shape="circle" htmlType="submit" disabled={isFetching} loading={isFetching} icon={<IconSearch />} />
        </FilterExtendedFormWrapper>
      </FilterWrapper>
    )
  }
  return (
    <FilterWrapper>
      <FilterFormWrapper ref={ref} onSubmit={handleSubmit}>
        {children}
        <Button className="search-btn" shape="circle" htmlType="submit" disabled={isFetching} loading={isFetching} icon={<IconSearch />} />
      </FilterFormWrapper>
    </FilterWrapper>
  )
})

export default Filter
