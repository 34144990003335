import Button from 'lib/components/Button'
import {MergeAccountBody, MergeAccountCard, MergeAccountFooter} from './MergeAccounts.styled'
interface MergeCardProps {
  showAllAccount(): void
}
const MergeCard = ({showAllAccount}: MergeCardProps) => {
  return (
    <MergeAccountCard>
      <MergeAccountBody>Search for main account to merge</MergeAccountBody>
      <MergeAccountFooter>
        <Button onClick={showAllAccount} type="primary" block>
          Select main account to merge
        </Button>
      </MergeAccountFooter>
    </MergeAccountCard>
  )
}

export default MergeCard
