import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const getPartners = (params?: PartnerTypes.PartnerDetail): Promise<AxiosResponse<ResponseTypes.ResponseData<PartnerTypes.PartnerBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getPartners,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

const PartnerService = {
  getPartners,
}

export default PartnerService
