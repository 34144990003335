import styled, {css} from 'styled-components'
import {SectionTitleSpacingTypes} from '.'

export const TitleRoot = styled.div<SectionTitleSpacingTypes>`
  margin: 0;
  display: block;
  ${(props) =>
    props.spacingV === 'large' &&
    css`
      padding-top: 22px;
      padding-bottom: 22px;
    `}
  ${(props) =>
    props.spacingV === 'medium' &&
    css`
      padding-top: 12px;
      padding-bottom: 12px;
    `}
  ${(props) =>
    props.spacingV === 'small' &&
    css`
      padding-top: 6px;
      padding-bottom: 6px;
    `}
  ${(props) =>
    props.spacingV === 'none' &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
  ${(props) =>
    props.spacingH === 'large' &&
    css`
      padding-left: 22px;
      padding-right: 22px;
    `}
  ${(props) =>
    props.spacingH === 'medium' &&
    css`
      padding-left: 12px;
      padding-right: 12px;
    `}
  ${(props) =>
    props.spacingH === 'small' &&
    css`
      padding-left: 6px;
      padding-right: 6px;
    `}
  ${(props) =>
    props.spacingH === 'none' &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
`
