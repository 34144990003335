import IconDelete from 'app/common/icons/IconDelete'
import IconEdit from 'app/common/icons/IconEdit'
import IconReadOnly from 'app/common/icons/IconReadOnly'
import IconSettings from 'app/common/icons/IconSettings'
import {RoleDetailsHeader, RoleDetailsHeaderInfo} from 'app/pages/UserManagement/UserManagementStyled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import Typography from 'lib/components/Typography'
import {useState} from 'react'
import Colors from 'styles/Colors'

interface FunctionDetailsProps {
  functionsList: UserManagementTypes.FunctionReqType[]
  isViewOnly: boolean
  handleDelete(type: string, id: string, name: string): void
  showAssignFunction: boolean
}

const FunctionDetails: React.FC<FunctionDetailsProps> = ({functionsList = [], isViewOnly, handleDelete, showAssignFunction}) => {
  const count = functionsList.length
  const [isEditable, setIsEditable] = useState<boolean>(false)

  const handleEditFunctions = () => {
    setIsEditable(!isEditable)
  }
  return (
    <>
      <RoleDetailsHeader>
        <RoleDetailsHeaderInfo style={{display: 'flex', alignItems: 'center'}}>
          <IconSettings />
          &nbsp;&nbsp;
          <Typography.Title level={4}>Functions Included in this Role</Typography.Title>
          {(!isViewOnly || showAssignFunction) && (
            <Button type="link" onClick={handleEditFunctions}>
              {isEditable ? 'Finished Editing' : 'Edit Functions'}
            </Button>
          )}
        </RoleDetailsHeaderInfo>
      </RoleDetailsHeader>
      <DataTable.TotalResult noOfResults={count}></DataTable.TotalResult>
      <div style={{display: 'flex', flexFlow: 'wrap', gap: 24}}>
        {functionsList.map((func) => (
          <div
            style={{
              display: 'flex',
              padding: '16px',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '48%',
              borderRadius: '10px',
              marginBottom: '16px',
              border: `1px solid ${Colors.BORDER1}`,
            }}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span style={{marginRight: '8px'}}>{func.isEdit ? <IconEdit size={16} /> : <IconReadOnly size={16} />}</span>
              <Typography.Text>{func.functionName}</Typography.Text>
            </div>
            {isEditable && (
              <Button type="text" onClick={() => handleDelete('FUNCTION', func.functionCode, func.functionName)}>
                <IconDelete size={16} />
              </Button>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default FunctionDetails
