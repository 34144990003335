import {Outlet } from 'react-router-dom'
import AuthGaurd from '../Auth/AuthGaurd'
import Navigation from '../Navigation'
import { AppContainer } from './Layout.styled'

const Layout = () => {
  return (
    <AuthGaurd>
      <Navigation />
      <AppContainer>
        <Outlet />
      </AppContainer>
    </AuthGaurd>
  )
}

export default Layout
