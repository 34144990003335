import Typography from 'lib/components/Typography'
import MemberSearchTable from './MemberSearchTable'
import {Space} from 'antd'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {MEMBER_DETAIL, MEMBER_SEARCH, MEMBER_VIEW} from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'

const MemberSearch: React.FC = () => {
  const {roles} = useAuth()
  const showSearch = roles.includes(MEMBER_SEARCH)
  const showTable = roles.includes(MEMBER_VIEW)
  const showMemberDetail = roles.includes(MEMBER_DETAIL)

  if (!showSearch && !showTable) {
    return <NoPermission />
  }

  return (
    <section>
      <Space direction="vertical" size="middle" style={{width: showSearch ? '' : '95%'}}>
        <Typography.Title>Program members</Typography.Title>
        <MemberSearchTable showSearch={showSearch} showTable={showTable} showMemberDetail={showMemberDetail} />
      </Space>
    </section>
  )
}

export default MemberSearch
