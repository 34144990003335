import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Modal} from 'antd'
import {MarginBottomWrapper, ModalTitle, ReviewHeader, Seperator, StyledModalContent} from 'app/common/components/Styled/common.styled'
import PartnerService from 'app/services/PartnerService'
import {FormTypes} from 'lib/components/Form/Form'
import Typography from 'lib/components/Typography'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import {getCategoryRequestObj, initialCategoryRequestObj, updateCategoryRequestObj} from './utils'
import LoadingPanel from 'lib/components/LoadingPanel'
import ReviewCategory from './ReviewCategory'
import IconSuccess from 'app/common/icons/IconSuccess'
// import {useAuth} from 'app/pages/Auth/AuthContext'
// import {SEGMENT_CREATE, SEGMENT_EDIT} from 'app/common/helpers/UserFunctions'
import RewardCategoryService from 'app/services/RewardCategoryService'
import {CategoryFormBodyContainer, NewCategoryFooter} from '../Category.Styled'
import CategoryHeader from './CategoryHeader'
import NewCategoryForm, {CategoryRefType} from './NewCategoryForm'

export type AddNewCategroyParam = {
  categoryId: string | 'new' | 'view'
}

interface AddNewCategoryProps {
  isViewOnly?: boolean
}

const AddNewRewardCategory: React.FC<AddNewCategoryProps> = ({isViewOnly}) => {
  const {categoryId} = useParams<AddNewCategroyParam>()
  const actualCategoryId = atob(categoryId || '')
  const categoryFormRef = useRef<CategoryRefType>(null)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(categoryId === 'new' ? 0 : 1)
  const [loading, setLoading] = useState(false)
  const [loadingCategory, setLoadingCategory] = useState(false)
  const [individualCategory, setIndividualCategory] = useState<RewardCategoryTypes.RewardCategoryResponseBL>()
  // const [disableSave, setDisableSave] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const allowBack = activeStep === 1
  // const {roles} = useAuth()
  const [partnerCodeOptions, setPartnerCodeOptions] = useState<FormTypes.Options[]>([])
  const [partnerCode, setPartnerCode] = useState('')
  const showEditCategory = true // roles.includes(SEGMENT_EDIT)
  const showCreateCategory = true // roles.includes(SEGMENT_CREATE)
  const isEdit = categoryId !== 'new'

  useEffect(() => {
    return () => {
      updateCategoryRequestObj(initialCategoryRequestObj)
    }
  }, [])

  useEffect(() => {
    if (categoryId !== 'new') {
      setLoadingCategory(true)
      RewardCategoryService.getRewardCategories({categoryCode: actualCategoryId, Strict: true})
        .then((res) => {
          const data = res.data.data[0]
          setIndividualCategory(data)
          updateCategoryRequestObj(data)
        })
        .finally(() => {
          setLoadingCategory(false)
        })
    }
  }, [actualCategoryId])

  const showPreview = () => {
    if (categoryFormRef.current?.validate()) {
      setActiveStep(1)
    }
  }

  const updateCategoryReqPartnerData = (data: PartnerTypes.PartnerBL[]) => {
    const partnerCodeFromRequest = getCategoryRequestObj.partnerCode
    const defaultCode = data.find((partnerOption) => partnerOption.isDefaultPartner)
    if (partnerCodeFromRequest) {
      const hasPartnerCode = data.find((partnerOption) => partnerOption.partnerCode === partnerCodeFromRequest)
      if (!hasPartnerCode) {
        getCategoryRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
      }
    } else {
      getCategoryRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
    }
    setPartnerCode(getCategoryRequestObj.partnerCode)
  }

  const getPartnerCodes = () => {
    PartnerService.getPartners().then((res) => {
      const data = res.data.data
      const partnerOptions = data.map((item) => ({value: item.partnerCode, label: item.partnerName}))
      setPartnerCodeOptions(partnerOptions)
      updateCategoryReqPartnerData(data)
    })
  }

  useEffect(() => {
    getPartnerCodes()
  }, [])

  const submitForApproval = () => {
    setLoading(true)
    setProcessing(true)
    setOpen(true)
    let saveCategoryPayload: RewardCategoryTypes.SaveCategoryPayload = {
      categoryName: getCategoryRequestObj.categoryName,
      categoryDescription: getCategoryRequestObj.categoryDescription,
      partnerCode: getCategoryRequestObj.partnerCode,
      // properties: getCategoryRequestObj.properties,
      categoryGroup: getCategoryRequestObj.categoryGroup,
      categoryCode: getCategoryRequestObj.categoryCode,
    }
    if (categoryId !== 'new') {
      saveCategoryPayload = {
        ...saveCategoryPayload,
        categoryCode: getCategoryRequestObj.categoryCode,
      }
    }
    RewardCategoryService.saveCategory(saveCategoryPayload, {
      categoryCode: categoryId !== 'new' ? getCategoryRequestObj.categoryCode : undefined,
      partnerCode: categoryId !== 'new' ? getCategoryRequestObj.partnerCode : undefined,
    })
      .then((res) => {
        const {data} = res.data
        setIndividualCategory(data)
        updateCategoryRequestObj(data)
        setOpen(true)
        setIsSaved(true)
        // navigate(`/segments`)
      })
      .catch(() => {
        setOpen(false)
      })
      .finally(() => {
        setLoading(false)
        setProcessing(false)
      })
  }

  const handleEditCategory = () => {
    allowBack && setActiveStep(0)
  }

  const handleCancelConfirm = () => {
    updateCategoryRequestObj(initialCategoryRequestObj)
    navigate(`/program/category`)
  }

  const handleOk = () => {
    setOpen(false)
    handleCancelConfirm()
  }

  const handleCancelNewCategory = () => {
    if (isViewOnly === true) {
      handleCancelConfirm()
    } else {
      setOpen(true)
    }
  }
  const handleCancelModal = () => {
    setOpen(false)
  }

  const renderCategoryBody = () => {
    if (loadingCategory) {
      return <LoadingPanel />
    }
    if (categoryId !== 'new' && !individualCategory) {
      return <div>No Category found with {actualCategoryId}</div>
    }
    if (activeStep === 0) {
      return <NewCategoryForm ref={categoryFormRef} partnerCodeOptions={partnerCodeOptions} />
    }
    return (
      <ReviewCategory
        isViewOnly={isViewOnly}
        showEditCategory={categoryId !== 'new'}
        onEdit={handleEditCategory}
        partnerCodeOptions={partnerCodeOptions}
      />
    )
  }

  const renderSaveButton = () => {
    if (categoryId === 'new') {
      return (
        <Button
          loading={loading}
          onClick={activeStep === 0 ? showPreview : submitForApproval}
          type="primary"
          disabled={partnerCode === ''}
          // disabled={disableSave}
          $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
        >
          {activeStep === 1 ? 'Save and Create Category' : 'Preview'}
        </Button>
      )
    } else if (categoryId !== 'new' && activeStep === 0) {
      return (
        <Button
          loading={loading}
          onClick={submitForApproval}
          type="primary"
          disabled={partnerCode === ''}
          // disabled={disableSave}
          $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
        >
          Save Changes
        </Button>
      )
    }
    return <></>
  }
  const showEdit = () => {
    setActiveStep(0)
  }
  const renderEditButton = () => {
    return (
      activeStep === 1 &&
      categoryId === 'new' && (
        <Button
          loading={loading}
          onClick={showEdit}
          type="primary"
          // disabled={disableSave}
        >
          Back
        </Button>
      )
    )
  }

  const renderCategoryFooter = () => {
    return (
      !isViewOnly && (
        <>
          <NewCategoryFooter>
            {showEditCategory && renderEditButton()}
            {(showEditCategory || showCreateCategory) && renderSaveButton()}
          </NewCategoryFooter>
        </>
      )
    )
  }

  const handleCompleted = () => {
    setOpen(false)
    setIsSaved(false)
    navigate(`/program/category`)
  }

  const handleCloseModal = () => {
    if (isSaved) {
      navigate(`/program/category`)
    } else {
      handleCancelModal()
    }
  }

  const renderModalBody = () => {
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (isSaved) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              {categoryId === 'new' ? 'New Category Created' : 'New Category Edited'}
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                The new category named <b>{individualCategory?.categoryName}</b> has been {categoryId === 'new' ? ' created' : 'edited'}.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">Are you sure you want to cancel adding {getCategoryRequestObj.categoryName}?</Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Continue with cancel
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs
          backLinkLabel="Category Master"
          backLinkPath={`/program/category`}
          activePageLabel={isViewOnly ? 'Category Details' : 'Add New Category'}
        />
        <Button type="link" onClick={handleCancelNewCategory}>
          {categoryId === 'new' ? 'Cancel New Category' : 'Cancel'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <CategoryHeader activeStep={activeStep} />
      <div>
        {activeStep === 1 && isEdit && (
          <ReviewHeader>
            <Typography.Title level={2}>{individualCategory?.categoryName}</Typography.Title>
            {/* {allowBack && showEditCategory && isEdit && individualCategory ? (
              <ExtraPaddedButton type="primary" onClick={handleEditCategory}>
                Edit
              </ExtraPaddedButton>
            ) : (
              <></>
            )} */}
          </ReviewHeader>
        )}
        <CategoryFormBodyContainer>{renderCategoryBody()}</CategoryFormBodyContainer>
      </div>
      {renderCategoryFooter()}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCloseModal} footer={null}>
          {renderModalBody()}
        </Modal>
      )}
    </div>
  )
}

export default AddNewRewardCategory
