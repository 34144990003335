import {Modal} from 'antd'
import IconClose from 'app/common/icons/IconClose'
import MemberSearchTable from 'app/pages/Member/MemberSearch/MemberSearchTable'
import Typography from 'lib/components/Typography'

interface AccountListModalProps {
  setOpen(): void
  rowClick(row: MemebershipTypes.MembershipDataBL): void
  showMemberMerge: boolean
}

const AccountListModal = ({setOpen, rowClick, showMemberMerge}: AccountListModalProps) => {
  return (
    <Modal
      title={<Typography.Title level={4}>Search the main account to merge</Typography.Title>}
      centered
      open
      onCancel={setOpen}
      width={1000}
      closeIcon={<IconClose />}
      footer={null}
    >
      <MemberSearchTable rowClick={rowClick} pageSize={10} pageSizeOptions={[10, 50, 100, 200]} showTable={true} showMemberDetail={showMemberMerge} />
    </Modal>
  )
}

export default AccountListModal
