import {Col, InputProps, Row} from 'antd'
import {forwardRef, useImperativeHandle, useState} from 'react'
import {MemberInfoFormContainer} from './MemberInfo.styled'
import Form from 'lib/components/Form'
import Appconfig from 'app/common/helpers/AppConfig'
import dayjs from 'dayjs'
import {FormTypes} from 'lib/components/Form/Form'
import {emailRegex, getStateNameByCode, getZipcodes, states, validatePhone} from 'utils/common'
import TextArea from 'lib/components/TextArea'
import {toast} from 'react-hot-toast'
import PhoneInputField from 'lib/components/PhoneInput'
import {isEmpty} from 'lodash'
import Typography from 'lib/components/Typography'
import Button from 'lib/components/Button'
import IconEdit from 'app/common/icons/IconEdit'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import {ShowPointTypeData} from '..'

interface MemberInfoFormProps {
  isEdit: boolean
  memberDetail: MemebershipTypes.MembershipDataBL
  isMemberEdit: boolean
  onEdit(isEdit: boolean): void
  showPointDetails: ShowPointTypeData
}

export interface MemberInfoRefType {
  getState(): FormInitialState<string>
  validate(): boolean
}

type MemberInfoTypes =
  | 'address'
  | 'email'
  | 'birthDay'
  | 'gender'
  | 'phone'
  | 'address2'
  | 'state'
  | 'referralCustomerId'
  | 'city'
  | 'zipcode'
  | 'firstName'
  | 'lastName'

const genderOptions: FormTypes.Options[] = [
  {label: 'Male', value: 'Male'},
  {label: 'Female', value: 'Female'},
  {label: 'Other', value: 'Other'},
  {label: 'Prefer not to say', value: 'Prefer not to say'},
]

interface MemberInfoErrorType {
  email: boolean
  phone: boolean
  firstName: boolean
  lastName: boolean
}

type FormInitialState<T> = {[type in MemberInfoTypes]: T}

const MemberInfoForm = forwardRef<MemberInfoRefType, MemberInfoFormProps>(({isEdit, memberDetail, isMemberEdit, onEdit, showPointDetails}, ref) => {
  // const {membershipCustomerId = '', membershipEmailId = ''} = memberDetail

  let address = [
    memberDetail.membershipAddress1,
    memberDetail.membershipAddress2,
    memberDetail.membershipCity || '',
    getStateNameByCode(memberDetail.membershipState),
    memberDetail.membershipZipCode || '',
  ]
    .filter((x) => x != null && !isEmpty(x))
    .join(',')

  address = isEmpty(address) ? '-' : address
  const initialState: FormInitialState<string> = {
    // accountNumber: memberDetail.membershipCustomerId,
    firstName: memberDetail.membershipFirstName ?? '',
    lastName: memberDetail.membershipLastName ?? '',
    address: isEdit ? memberDetail.membershipAddress1 || '-' : address,
    address2: memberDetail.membershipAddress2 ?? (isEdit ? '' : '-'),
    city: memberDetail.membershipCity ?? '',
    state: memberDetail.membershipState ?? '',
    zipcode: memberDetail.membershipZipCode ?? '',
    gender: memberDetail.membershipGender ?? (isEdit ? '' : '-'),
    phone: memberDetail.membershipPhone ?? (isEdit ? '' : '-'),
    email: memberDetail.membershipEmailId ?? (isEdit ? '' : '-'),
    birthDay: memberDetail.membershipBirthDate ? dayjs(memberDetail.membershipBirthDate).format(Appconfig.DATE_FORMAT_SLASH) : '-',
    referralCustomerId: memberDetail.referralCustomerId ?? (isEdit ? '' : '-'),
  }

  const infoErrorState: MemberInfoErrorType = {
    email: false,
    phone: false,
    firstName: false,
    lastName: false,
  }

  const [state, setState] = useState<FormInitialState<string>>(initialState)
  const [errorState, setErrorState] = useState(infoErrorState)

  const getMemberInfoState = () => {
    return state
  }

  const hanldeSetState = (value: string, key: MemberInfoTypes) => {
    const prevState = {...state}

    prevState[key] = value
    setState(prevState)
    setErrorState(infoErrorState)
  }

  const handleValidate = () => {
    if (!state.firstName) {
      setErrorState({
        ...errorState,
        firstName: true,
      })
      toast.error('Please enter member first name.')
      return false
    }
    if (!state.lastName) {
      setErrorState({
        ...errorState,
        lastName: true,
      })
      toast.error('Please enter member first name.')
      return false
    }
    if (state.email.trim() && !emailRegex.test(state.email)) {
      setErrorState({
        ...errorState,
        email: true,
      })
      toast.error('Please enter valid email')
      return false
    }
    if (!state.phone && !validatePhone(state.phone)) {
      setErrorState({
        ...errorState,
        phone: true,
      })
      toast.error('Please enter valid phone')
      return false
    }
    return true
  }

  useImperativeHandle(ref, () => ({
    getState: getMemberInfoState,
    validate: handleValidate,
  }))

  const getLabel = (key: MemberInfoTypes) => {
    switch (key) {
      case 'address':
        return isEdit ? 'Street Address - (No PO Boxes)' : 'Address'
      case 'email':
        return 'Email ID'
      case 'firstName':
        return 'First Name'
      case 'lastName':
        return 'Last Name'
      case 'birthDay':
        return 'Birth Day'
      case 'gender':
        return 'Gender'
      case 'phone':
        return 'Phone'
      case 'address2':
        return 'Apt/Suite - (Optional)'
      case 'city':
        return 'City'
      case 'state':
        return 'State'
      case 'zipcode':
        return 'Zip Code'
      case 'referralCustomerId':
        return 'Referral Code'
      default:
        return ''
    }
  }

  const getDropdownOptions = (type: string): FormTypes.Options[] => {
    switch (type) {
      case 'gender':
        return genderOptions
      case 'state':
        return states
      case 'zipcode':
        return getZipcodes()
    }
    return []
  }

  const getErrorStatus = (key: string): InputProps['status'] => {
    if (key === 'email' && errorState.email) {
      return 'error'
    } else if (key === 'firstName' && errorState.firstName) {
      return 'error'
    } else if (key === 'lastName' && errorState.lastName) {
      return 'error'
    }
    return undefined
  }

  const getHeaderAction = () => {
    if (isEdit) {
      return (
        <Button name="save" type="text" onClick={() => onEdit(false)}>
          <span>Cancel</span>
        </Button>
      )
    }
    if (isMemberEdit) {
      return (
        <Button onClick={() => onEdit(true)} type="text" name="edit" htmlType="button" $endIcon={<IconEdit size={16} />}>
          <span>Edit</span>
        </Button>
      )
    }
    return null
  }

  const renderFormHeader = (): JSX.Element => {
    return (
      <>
        <Col span={isEdit ? 10 : 12}>
          <Typography.Title level={4}>Profile Information</Typography.Title>
          {!isEdit && (
            <>
              <div>
                <Typography.Text>{memberDetail.membershipFullName}</Typography.Text>
              </div>
              <div>
                <Typography.Text>{state.address}</Typography.Text>
              </div>
              <div>
                <Typography.Text>{state.phone}</Typography.Text>
              </div>
            </>
          )}
        </Col>
        <Col span={isEdit ? 8 : 6}>
          <Typography.Title level={4}>Email Address</Typography.Title>
          <div>
            <Typography.Text>{state.email}</Typography.Text>
          </div>
        </Col>
        <Col span={6}>{getHeaderAction()}</Col>
      </>
    )
  }

  const renderInputs = (type: MemberInfoTypes): JSX.Element | null => {
    const prevState = {...state}
    let value = prevState[type]
    if (type === 'referralCustomerId') {
      if (isEdit) {
        return (
          <Col span={8} key={type}>
            <Form.Input
              label={getLabel(type)}
              id={type}
              name={type}
              value={value}
              placeholder={value}
              disabled={memberDetail.referralCustomerId != null}
              onChange={hanldeSetState}
            />
          </Col>
        )
      }
    }

    // if (type === 'gender') {
    //   value = value === 'm' ? 'Male' : 'Female'
    // }
    if (!isEdit && (type === 'address2' || type === 'state')) {
      return null
    }
    if (type === 'gender' || type === 'state' || type === 'zipcode') {
      return (
        <Col span={12} key={type}>
          <Form.Select
            label={getLabel(type)}
            id={type}
            value={value}
            options={getDropdownOptions(type)}
            filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
            onChange={hanldeSetState}
            readOnly={!isEdit}
          />
        </Col>
      )
    }
    if (isEdit && (type === 'address' || type === 'address2')) {
      return (
        <Col span={12} key={type}>
          <TextArea label={getLabel(type)} id={type} value={value} handleChange={hanldeSetState} readOnly={!isEdit} maxLength={500} />
        </Col>
      )
    }
    if (type === 'birthDay') {
      return (
        <Col span={12} key={type}>
          <Form.DatePicker
            disabledDate={(current: dayjs.Dayjs) => current > dayjs()}
            label={getLabel(type)}
            id={type}
            value={value === '-' ? null : value}
            onChange={hanldeSetState}
            format={Appconfig.DATE_FORMAT_SLASH}
            readOnly={!isEdit}
          />
        </Col>
      )
    }
    if (type === 'phone') {
      return (
        <Col span={12} key={type}>
          <PhoneInputField
            label={getLabel(type)}
            id={type}
            value={value}
            onChange={hanldeSetState}
            readOnly={!isEdit}
            status={errorState.phone ? 'error' : undefined}
          />
        </Col>
      )
    }

    return (
      <Col span={12} key={type}>
        <Form.Input
          label={getLabel(type)}
          id={type}
          name={type}
          value={value}
          onChange={hanldeSetState}
          readOnly={!isEdit}
          status={getErrorStatus(type)}
        />
      </Col>
    )
  }

  const renderMemberForm = () => {
    const allKeys = Object.keys(state) as Array<MemberInfoTypes>
    return allKeys.map((type) => {
      return <Row style={{width: '100%'}}>{renderInputs(type)}</Row>
    })
  }

  const renderPointDetails = () => {
    return (
      <>
        {/* <Col span={8}>
          <Typography.Title level={4}>Next Point Expiration</Typography.Title>
          <div><Typography.Text>{state.email}</Typography.Text></div>
        </Col>
        <Col span={8}>
          <Typography.Title level={4}>YTD Points</Typography.Title>
          <div><Typography.Text>{state.email}</Typography.Text></div>
        </Col> */}
        <Col span={8}>
          <Typography.Title level={4}>Lifetime Points</Typography.Title>
          <div>
            <Typography.Text>{showPointDetails.membershipLifeTimeValue}</Typography.Text>
          </div>
        </Col>
      </>
    )
  }

  return (
    <MemberInfoFormContainer>
      {isEdit ? (
        <>
          <Row gutter={[12, 18]}>{renderFormHeader()}</Row>
          <MarginBottomWrapper>
            <Row gutter={[12, 18]}>{renderMemberForm()}</Row>
          </MarginBottomWrapper>
          <div>
            <Button htmlType="submit" type="primary">
              Save Changes
            </Button>
          </div>
        </>
      ) : (
        <>
          <Row gutter={[12, 24]}>
            {renderFormHeader()}
            <Col span={12}>
              <Typography.Title level={4}>Gender</Typography.Title>
              <div>
                <Typography.Text>{state.gender}</Typography.Text>
              </div>
            </Col>
            <Col span={12}>
              <Typography.Title level={4}>Date of Birth</Typography.Title>
              <div>
                <Typography.Text>{state.birthDay}</Typography.Text>
              </div>
            </Col>

            {renderPointDetails()}
          </Row>
        </>
      )}
    </MemberInfoFormContainer>
  )
})

export default MemberInfoForm
