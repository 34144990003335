import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Typography from 'lib/components/Typography'

interface RolesHeaderProps {
  activeStep: number
  roleName: string
}

const RolesHeader: React.FC<RolesHeaderProps> = ({activeStep, roleName}) => {
  return (
    <StyledHeader>
      <Typography.Title level={2}>{activeStep === 0 ? 'Add New Role' : roleName}</Typography.Title>
    </StyledHeader>
  )
}

export default RolesHeader
