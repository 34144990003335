import Colors from 'styles/Colors'

export const IconLock= ({size = 10, color = Colors.TEXT_COLOR_SECONDARY}: IconType.Props): JSX.Element => {
  return (
    <svg width={size - 1} height={size}>
      <g transform="matrix(1 0 0 1 -82 -91 )">
        <path
          d="M 9 5.3125  C 9 4.794921875  8.568080357142858 4.375  8.035714285714286 4.375  L 7.553571428571429 4.375  L 7.553571428571429 2.96875  C 7.553571428571429 1.33203125  6.183482142857144 0  4.5 0  C 2.8165178571428573 0  1.4464285714285716 1.33203125  1.4464285714285716 2.96875  L 1.4464285714285716 4.375  L 0.9642857142857142 4.375  C 0.4319196428571429 4.375  0 4.794921875  0 5.3125  L 0 9.0625  C 0 9.580078125  0.4319196428571429 10  0.9642857142857142 10  L 8.035714285714286 10  C 8.568080357142858 10  9 9.580078125  9 9.0625  L 9 5.3125  Z M 5.946428571428571 2.96875  L 5.946428571428571 4.375  L 3.053571428571429 4.375  L 3.053571428571429 2.96875  C 3.053571428571429 2.193359375  3.702455357142857 1.5625  4.5 1.5625  C 5.297544642857143 1.5625  5.946428571428571 2.193359375  5.946428571428571 2.96875  Z "
          fill-rule="nonzero"
          fill={color}
          stroke="none"
          transform="matrix(1 0 0 1 82 91 )"
        />
      </g>
    </svg>
  )
}
