import Checkbox from './Checkbox'
import Input from './Input'
import Select from './Select'
import Radio from './Radio'
import Switch from './Switch'
import DatePicker from './DatePicker'
import DateRangePicker from './DateRangePicker'
import InputNumber from './InputNumber'

type FormSubComponents = {
  Input: typeof Input
  Select: typeof Select
  Checkbox: typeof Checkbox
  Radio: typeof Radio
  Switch: typeof Switch
  DatePicker: typeof DatePicker
  DateRangePicker: typeof DateRangePicker
  InputNumber: typeof InputNumber
}

export interface FormProps {
  children?: React.ReactNode
}

type FormPropsType = FormProps

const Form: React.FunctionComponent<FormPropsType> & FormSubComponents = ({children}: FormProps) => <>{children}</>

Form.Input = Input
Form.Select = Select
Form.Checkbox = Checkbox
Form.Radio = Radio
Form.Switch = Switch
Form.DatePicker = DatePicker
Form.DateRangePicker = DateRangePicker
Form.InputNumber = InputNumber
export default Form
