import Appconfig from 'app/common/helpers/AppConfig'
import {FormTypes} from 'lib/components/Form/Form'
import {isEmpty} from 'lodash'
import cloneDeep from 'lodash/cloneDeep'

export const initialProductRequestObj: ProductMasterTypes.ProductMasterResponseBL = {
  rowStatus: Appconfig.rewards.defaultValus.rowStatus,
  productName: '',
  productCode: '',
  productDescription: '',
  partnerCode: '',
  productStatus: Appconfig.segment.defaultValues.segmentStatus,
  skuCode: '',
  enabled:true,
  properties: {},
}

export let getProductRequestObj: ProductMasterTypes.ProductMasterResponseBL = cloneDeep(initialProductRequestObj)

export const updateProductRequestObj = (data: ProductMasterTypes.ProductMasterResponseBL) => {

  getProductRequestObj = cloneDeep(data)
}

export const validateAttributes = (properties: FormTypes.Options[]): boolean => {
  if (!properties.length || (properties.length === 1 && (!properties[0].label || !properties[0].value))) {
    return false
  } else if (properties.length > 1) {
    const isValid = properties.some((item) => isEmpty(item.label) || isEmpty(item.value))
    return !isValid
  }
  return true
}

export const getFormattedProperties = (properties: FormTypes.Options[]) => {
  let payloadProperties: any = {}
  properties.forEach((item) => {
    if (item.label) {
      const obj = {
        [item.label as string]: item.value,
      }
      payloadProperties = {
        ...payloadProperties,
        ...obj,
      }
    }
  })
  return payloadProperties
}

export const getFormattedAttributesFromProperties = (properties: any) => {
  if (isEmpty(properties)) {
    return []
  }
  const resultAttrs = Object.keys(properties).map((item) => ({
    label: item,
    value: properties[item],
  }))
  return resultAttrs
}

export const productStatusOptions: FormTypes.Options[] = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
]
