import Button from 'lib/components/Button'
import styled from 'styled-components'
import Colors from 'styles/Colors'

export const LoginContainer = styled.div`
  height: 100vh;
  background-image: url('/assets/login-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: cetner;
  overflow: auto;
`

export const LoginLogoWrapper = styled.div`
  padding-left: 40px;
  padding-top: 40px;
  width: 50%;
`

export const LoginForm = styled.div`
  width: 75%;
  padding-top: 56px;
  padding-right: 24px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
    background-color: transparent !important;
  }
`

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  margin-left: 174px;
`

export const StyledWelcomeText = styled.div`
  font-size: 36px;
  font-weight: 600;
  color: ${Colors.WHITE};
`

export const LoginButton = styled(Button)`
  background-color: #fa5743;
  color: ${Colors.WHITE};
  width: 100%;
  border: 0;
  &:hover {
    && {
      opacity: 0.85;
      color: ${Colors.WHITE};
    }
  }
`

export const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5%;
  height: 100vh;
`

export const ForgotPasswordContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 35%;
`

export const StyledBurstLoginTitle = styled.div`
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0.08rem;
  color: ${Colors.PRIMARY};
  margin-bottom: 32px;
`

export const FullWidthButton = styled(Button)`
  background-color: ${Colors.PRIMARY};
  width: 100%;
  &:hover {
    && {
      background-color: ${(props) => (props.type === 'primary' ? 'rgb(0, 98, 255)' : 'rgb(255, 175, 162, 0.95)')};
    }
  }
`

export const StyledPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
