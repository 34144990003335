import Typography from 'lib/components/Typography'
import {StyledModalContent, MarginBottomWrapper, ModalTitle, Seperator, ExtraPaddedButton} from 'app/common/components/Styled/common.styled'
import IconChevronRight from 'app/common/icons/IconChevronRight'
import IconSuccess from 'app/common/icons/IconSuccess'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import LoadingPanel from 'lib/components/LoadingPanel'
import Popover from 'lib/components/Popover'
import ToggleButton from 'lib/components/ToggleButton'
import {ToggleOptionType} from 'lib/components/ToggleButton/ToggleButton'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Modal} from 'antd'
import ProductService from 'app/services/ProductService'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {PRODUCT_ACTION_DETAIL, PRODUCT_DETAIL, PRODUCT_ACTIVATE} from 'app/common/helpers/UserFunctions'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Name',
    id: 'productName',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Code',
    id: 'productCode',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'SKU',
    id: 'skuCode',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Description',
    id: 'description',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'ACTIONS',
    id: 'enabled',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: '',
    id: 'actions',
    textAlign: 'center',
    width: '10%',
  },
]

const activateOptions: ToggleOptionType[] = [
  {
    label: 'On',
    value: true,
  },
  {
    label: 'Off',
    value: false,
  },
]

interface SearchResultProps {
  isFetching: boolean
  productsResponse: ResponseTypes.PagedResponse<ProductMasterTypes.ProductMasterResponseBL[]>
  handleRefresh: (value: boolean, productCode: string) => void
}

const SearchResults = ({isFetching, productsResponse, handleRefresh}: SearchResultProps) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)
  const [selProduct, setSelProduct] = useState<ProductMasterTypes.ProductMasterResponseBL | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const [completed, setCompleted] = useState<boolean>(false)
  const {roles} = useAuth()
  const showProductDetail = roles.includes(PRODUCT_DETAIL) || roles.includes(PRODUCT_ACTION_DETAIL)
  const showEditProduct = roles.includes(PRODUCT_ACTIVATE)

  dataTableColumns = dataTableColumns.map((item) => {
    if (item.id === 'enabled' && !showEditProduct) {
      return {...item, title: ''}
    }
    return item
  })

  const handleView = (rowIndex: number) => {
    const productCode = productsResponse.data[rowIndex].productCode
    const encodedProductCode = btoa(productCode)
    navigate(`/program/products/view/${encodedProductCode}`)
  }

  const modalOpen = (data: ProductMasterTypes.ProductMasterResponseBL) => {
    setOpen(true)
    setSelProduct(data)
  }
  
  const handleActivate = () => {
    if (selProduct) {
      setProcessing(true)
      const product: ProductMasterTypes.ProductMasterResponseBL = {
        ...selProduct,
        enabled: !selProduct.enabled,
      }

      ProductService.saveProduct(product, {
        productCode: selProduct.productCode,
        partnerCode: selProduct.partnerCode,
      })
        .then((res) => {
          setCompleted(true)
          handleRefresh(product.enabled, selProduct.productCode)
        })
        .catch(() => {
          setOpen(false)
        })
        .finally(() => {
          setProcessing(false)
        })
    }
  }

  const renderEnabledToggle = (rowIndex: number) => {
    if (showEditProduct) {
      return (
        <>
          <span>
            <ToggleButton
              id="activate"
              value={productsResponse.data[rowIndex].enabled}
              values={activateOptions}
              handleChange={() => modalOpen(productsResponse.data[rowIndex])}
            />
          </span>
        </>
      )
    }
    return null
  }

  const renderActionList = (rowIndex: number, item: ProductMasterTypes.ProductMasterBasicInfo) => {
    return (
      <>
        <div>
          <Button type="text" onClick={() => handleView(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
            View Details
          </Button>
        </div>
      </>
    )
  }

  const renderModalContent = () => {
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (completed) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={2} uppercase>
              {selProduct?.enabled ? 'PRODUCT IS NOW INACTIVE' : 'PRODUCT IS NOW ACTIVE'}
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Seperator />
          </MarginBottomWrapper>
          <MarginBottomWrapper width="80%">
            <Typography.Paragraph size="medium">
              <strong>{selProduct?.productName}</strong> {selProduct?.enabled ? ' cannot' : ' can now'} be used.
            </Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" onClick={handleCancelModal}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1} uppercase>
            Are You Sure?
          </Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Seperator />
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">
            Are you sure you want to {selProduct?.enabled ? 'turn off ' : 'turn on '}
            <strong>{selProduct?.productName}</strong>?
          </Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <ExtraPaddedButton type="primary" key="submit" onClick={handleActivate}>
            {selProduct?.enabled ? 'Turn off' : 'Turn on'}
          </ExtraPaddedButton>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  const handleCancelConfirm = () => {
    navigate(-1)
  }

  const handleOk = () => {
    setOpen(false)
    handleCancelConfirm()
  }

  const handleCancelModal = () => {
    if (completed) {
      setOpen(false)
      setCompleted(false)
    }
    setSelProduct(null)
    setOpen(false)
  }

  const renderRowActions = (rowIndex: number, item: ProductMasterTypes.ProductMasterBasicInfo) => {
    if (showProductDetail) {
      return <Popover btnContent={<IconThreeDots />} content={renderActionList(rowIndex, item)} />
    }
    return null
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (productsResponse && productsResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = productsResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          productName: item.productName,
          productCode: item.productCode,
          skuCode: item.skuCode,
          description: item.productDescription,
          enabled: renderEnabledToggle(index),
          actions: renderRowActions(index, item),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {renderModalContent()}
        </Modal>
      )}
    </>
  )
}

export default SearchResults
