import Button from 'lib/components/Button'
import {NavLink as RRDNavLink} from 'react-router-dom'
import styled from 'styled-components'
import Colors from 'styles/Colors'

export const HeaderContainer = styled.header`
  max-width: 100%;
  background-color: ${Colors.BLUE_LIGHT};
`

export const BrandHeaderContainer = styled.div`
  max-width: 100%;
  background-color: ${Colors.PRIMARY};
`

export const HeaderContent = styled.nav`
  max-width: ${(props) => props.theme.layout.maxWidth};
  padding: 0 ${(props) => props.theme.layout.sh};
  margin: 0 auto;
  display: flex;
`

export const BrandHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-left: 24px;
`

export const CenteredBlock = styled.div`
  display: flex;
  align-items: center;
`

export const BrandLogoSeperator = styled.span`
  margin: 0 24px;
  display: inline-block;
  width: 1px;
  height: 30px;
  background-color: rgba(168, 168, 168, 0.53);
`

export const NavLinkWrapper = styled.div`
  position: relative;
  &:hover {
    & > .nav-dropdown {
      display: flex;
      flex-direction: column;
    }
  }
`

export const NavLink = styled(RRDNavLink)`
  
  color: ${Colors.PRIMARY_DARK};
  font-family: ${(props) => props.theme.typography.body1.fontFamily};
  font-weight: 500;
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  line-height: ${(props) => props.theme.typography.body1.lineHeight};
  text-decoration: none;
  padding: 21px 23px;
  display: inline-block;
  border-bottom: 2px solid transparent;
  &:hover,
  &.active {
    color: ${Colors.PRIMARY};
    border-color: inherit;
  }
  &:focus {
    color: ${Colors.PRIMARY};
  }
`

export const NavButton = styled(Button)`
  margin-left: 16px;
  color: ${Colors.WHITE};
  && {
    padding: 4px 8px;
    &:hover {
      color: ${Colors.WHITE};
      background-color: transparent;
    }
  }
`

export const NavChildrenWrapper = styled.div`
  position: absolute;
  display: none;
  min-width: 112px;
  width: 100%;
  top: 64px;
  left: 0;
  border: 1px solid rgba(228, 228, 228, 0.5);
  border-radius: 2px;
  z-index: 1;
  background-color: ${Colors.WHITE};
  transition: 500ms;
`

export const NavChildrenLink = styled(NavLink)`
  padding: 16px 8px;
  color: #333333;
  border-bottom: 1px solid rgba(228, 228, 228, 0.5);
  &:last-child {
    border-bottom: none;
  }
  &.active {
    color: ${Colors.PRIMARY};
    border-color: inherit;
  }
`

export const ClientName = styled.span`
  margin-left: 8px;
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  font-weight: ${(props) => props.theme.typography.h3.fontWeight};
  line-height: 21px;
  color: ${Colors.WHITE};
`
