import {Col, Modal} from 'antd'
import LibButton from 'lib/components/Button'
import Button from 'lib/components/Button'
import Form from 'lib/components/Form'
import TextArea from 'lib/components/TextArea'
import Typography from 'lib/Typography'
import {useEffect, useState} from 'react'
import {UpdateContainer} from './QuickActions.styled'
import MembershipService from 'app/services/MemberService'
import {toast} from 'react-hot-toast'

// import {AxiosError} from 'axios'

const propsObject: MemberStatusTypes.MemberStatusDetail = {}

interface optionsProps extends MemberStatusTypes.MemberStatusBL {
  value: string
  label: string
}

const initialOptions: optionsProps[] = []

interface InitialStateProps {
  statusName: string
  remarks: string
}
interface UpdateStatusProps {
  memberDetail: MemebershipTypes.MemberDataDetailBL
  handleToggleAccordion: (name: string) => void
  memberUpdate: () => void
}

const UpdateStatus: React.FC<UpdateStatusProps> = ({memberDetail, handleToggleAccordion, memberUpdate}) => {
  const initialState = {
    statusName: memberDetail.membershipStatusData.membershipStatusName ?? ' ',
    remarks: '',
  }

  const [state, setState] = useState(initialState)
  const [open, openConfirmModal] = useState(false)
  const [options, setOptions] = useState(initialOptions)
  // const [selectedStatus, setSelectedStatus] = useState<optionsProps>()
  const [isLoading, setIsLoading] = useState(false)
  const {partnerCode} = memberDetail

  const handleStateUpdate = (value: string, key: keyof InitialStateProps) => {
    const prevState = {...state}
    prevState[key] = value
    setState(prevState)
  }

  const handleUpdate = () => {
    const payload = {...state}
    console.log(payload)
    const filterSelectedStatus = options.find((item) => item.membershipStatusKey.toUpperCase() === state.statusName.toUpperCase())
    // setSelectedStatus(filterSelectedStatus)

    if (filterSelectedStatus && filterSelectedStatus.isCancelled) {
      openConfirmModal(true)
    } else {
      handelMemberPost()
    }
  }

  const handleOk = () => {
    console.log('confirmed')
    openConfirmModal(false)
    handelMemberPost()
  }
  const handleCancel = () => {
    openConfirmModal(false)
    console.log('handle cancel')
  }

  const handelMemberPost = () => {
    const selectedStatus = options.find((item) => item.membershipStatusKey.toUpperCase() === state.statusName.toUpperCase())
    if (selectedStatus) {
      setIsLoading(true)

      const {membershipStatusKey} = selectedStatus
      const selectedMembershipStatus: MemberStatusTypes.MemberChangeStatus = {
        Remarks: state.remarks,
        MembershipCustomerId: memberDetail.membershipCustomerId,
        MembershipStatus: membershipStatusKey,
      }

      MembershipService.PutMemberStatus(selectedMembershipStatus)
        .then((res) => {
          toast.success('Status level has been changed.')
          handleToggleAccordion('')
          memberUpdate()
        })
        // .catch((error) => {
        //   const {response} = error
        //   console.log(response?.data)
        //   if (response?.data && response.data.data.length) {
        //     toast.error(response.data.data[0].message)
        //   }
        //   // toast.error(response?.data?.)
        // })
        .finally(() => {
          setIsLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        })
    }
  }

  const getMemeberStatus = (propsObject: MemberStatusTypes.MemberStatusDetail) => {
    MembershipService.MemberStatus(propsObject).then((res) => {
      const {data} = res.data
      let responseOptions: optionsProps[] = []
      const filterData = data.filter((item) => item.partnerCode.toUpperCase() === partnerCode.toUpperCase())
      responseOptions = filterData.map((item) => {
        return {
          value: item.membershipStatusKey,
          label: item.membershipStatusName,
          ...item,
        }
      })
      // const currentStatus = responseOptions.find((item) => item.membershipStatusKey.toUpperCase() === memberDetail.membershipStatusKey.toUpperCase())

      setOptions(responseOptions)
    })
  }

  useEffect(() => {
    getMemeberStatus(propsObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderModalFooter = () => {
    return (
      <Col style={{textAlign: 'center'}}>
        <Button color="white" key="back" onClick={handleCancel}>
          Cancel
        </Button>
        <Button key="submit" type="primary" onClick={handleOk}>
          Change status
        </Button>
      </Col>
    )
  }

  return (
    <>
      <UpdateContainer>
        <Form.Select id="statusName" label="Change To" options={options} onChange={handleStateUpdate} value={state.statusName} />
        <TextArea
          textAreaProps={{placeholder: 'Remarks', rows: 4, value: state.remarks, onChange: (e) => handleStateUpdate(e.target.value, 'remarks')}}
          id="statusRemarks"
        />
        <LibButton onClick={handleUpdate} loading={isLoading} disabled={isLoading} block>
          Update Status
        </LibButton>
      </UpdateContainer>
      <Modal open={open} onOk={handleOk} onCancel={handleCancel} footer={renderModalFooter()}>
        <Typography marginBottom={60} variant="h2" textAlign="center" component="p">
          This will be terminate the account and delete significance information
        </Typography>
      </Modal>
    </>
  )
}

export default UpdateStatus
