import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import {FormEvent, forwardRef, useState, useImperativeHandle} from 'react'

interface RolesFilterProps {
  handleFilter(formState: RolesFilterState): void
  isFetching: boolean
}

export interface RolesFilterState {
  roleName: string
  roleCode: string
  // category: string
}

type KeyTypes = keyof RolesFilterState

export interface ResetFormRefType {
  handleReset(): void
  getFormState(): RolesFilterState
}

const RolesFilter = forwardRef<ResetFormRefType, RolesFilterProps>(({handleFilter, isFetching}, forwardedRef) => {
  const inititalState: RolesFilterState = {
    roleName: '',
    roleCode: '',
    // category: '',
  }
  const [state, setInitialState] = useState(inititalState)

  const handleReset = () => {
    setInitialState(inititalState)
  }

  const getFormState = () => {
    return state
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = {...state}
    prevState[key] = value
    setInitialState(prevState)
  }

  useImperativeHandle(forwardedRef, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.roleName} name="roleName" id="roleName" placeholder="Search by Role Name" onChange={handleOnChange} isDark />
      <Form.Input value={state.roleCode} name="roleCode" id="roleCode" placeholder="Search by Role ID" onChange={handleOnChange} isDark />
      {/* <Form.Input value={state.category} name="tag" id="tag" placeholder="Search by Tag" onChange={handleOnChange} isDark /> */}
    </DataTable.Filter>
  )
})

export default RolesFilter
