const IconEdit = ({size = 19, color = 'currentColor'}: IconType.Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none">
      {/* <path d="M11 18.3333H19.25" stroke="#0062FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> */}
      <path
        d="M15.125 3.20834C15.4897 2.84366 15.9843 2.63879 16.5 2.63879C16.7554 2.63879 17.0082 2.68909 17.2441 2.78681C17.4801 2.88454 17.6944 3.02777 17.875 3.20834C18.0556 3.38891 18.1988 3.60327 18.2965 3.83919C18.3942 4.07512 18.4445 4.32798 18.4445 4.58334C18.4445 4.8387 18.3942 5.09156 18.2965 5.32748C18.1988 5.5634 18.0556 5.77777 17.875 5.95834L6.41667 17.4167L2.75 18.3333L3.66667 14.6667L15.125 3.20834Z"
        fill="#0062FF"
        stroke="#0062FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconEdit
