import styled from "styled-components";
import Colors from "styles/Colors";

export const CategoryFormBodyContainer = styled.div`
border: 1px solid ${Colors.BORDER1};
background-color: ${Colors.WHITE};
padding: 34px;
`

export const NewCategoryFooter = styled.div`
padding-top: 15px;
display: flex;
justify-content: flex-end;
.lb-btn {
  min-width: 150px;
  margin-left: 8px;
}
`