import Button from 'lib/components/Button'
import StyledSteps from '../../../../../lib/components/Steps'
import PromotionService from 'app/services/PromotionService'
import {useRef, useState} from 'react'
import {toast} from 'react-hot-toast'
import {getActiveUsers, getApprovalManagerDetails, getPromotionRequestObj, isApprovedWithPastDate, updatePromotionRequestObj} from '../utils'
import Incentives, {IncentivesRefType} from './Incentives'
import Review from './Review'
import {StepFooter, StepsBodyContainer, StepsNavigationWrapper, StyledModal} from './Steps.styled'
import Trigger, {TriggerRefType} from './Trigger'
import When, {WhenRefType} from './When'
import Who, {WhoRefType} from './Who'
import {useNavigate, useParams} from 'react-router-dom'
import {CreatePromotionParam} from '..'
import Appconfig from 'app/common/helpers/AppConfig'
import {StyledStepsProps} from 'lib/components/Steps/Steps'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import {Col, Row} from 'antd'
import {MarginBottomWrapper, ModalTitle, Seperator, StyledModalContent} from 'app/common/components/Styled/common.styled'
import Typography from 'lib/components/Typography'
import {FormTypes} from 'lib/components/Form/Form'
import Form from 'lib/components/Form'
import TextArea from 'lib/components/TextArea'
import IconSuccess from 'app/common/icons/IconSuccess'
import LoginService from 'app/services/LoginService'
import {parseUserDetailsFromJwt} from 'utils/common'
import {PROMOTION_APPROVAL, PROMOTION_CREATE, PROMOTION_EDIT} from 'app/common/helpers/UserFunctions'
import {useAuth} from 'app/pages/Auth/AuthContext'
import dayjs from 'dayjs'

export interface StepsStepTypes {
  label: string
  id: number
  isActive: boolean
  isCompleted: boolean
}

interface StepsProps {
  steps: StyledStepsProps[]
  validateHeader(): void
  disableHeader(): void
  resetPromotionName(): void
  updatePromotionName(): void
  isViewOnly?: boolean
  isPutCall?: boolean
  showSteps: boolean
}

const Steps = ({steps, validateHeader, disableHeader, isViewOnly, isPutCall, showSteps, resetPromotionName, updatePromotionName}: StepsProps) => {
  const getPromotionResponse = getPromotionRequestObj
  const {roles} = useAuth()
  const token = LoginService.getSessionToken()
  const decoded: any = parseUserDetailsFromJwt(token)
  const whoRef = useRef<WhoRefType>(null)
  const whenRef = useRef<WhenRefType>(null)
  const triggerRef = useRef<TriggerRefType>(null)
  const incentivesRef = useRef<IncentivesRefType>(null)
  const {promotionId} = useParams<CreatePromotionParam>()
  const actualPromotionId = atob(promotionId || '')
  const [stepsData, setSteps] = useState(steps)
  const [isSaving, setSaveLoading] = useState(false)
  const [openApproval, setOpenApproval] = useState(false)
  const [managerOptions, setManagerOptions] = useState<FormTypes.Options[]>([])
  const [approvalManager, setApprovalManager] = useState<string>('')
  const [sentForApproval, setSentForApproval] = useState(false)
  const [approved, setApproved] = useState(false)
  const [rejected, setRejected] = useState(false)
  const [comments, setComments] = useState<string>('')
  const [confirmRejection, setConfirmRejection] = useState(false)
  const navigate = useNavigate()
  const {user} = useAuth()
  let isManager = false
  if (getPromotionRequestObj.approvalManager && getPromotionRequestObj.approvalManager.length) {
    isManager = decoded.email === getPromotionResponse.approvalManager[0].userName
  }

  const loggedInUserId = decoded.email

  const params: PromotionsTypes.PromotionDetails = {
    PromotionCode: getPromotionRequestObj.promotionCode,
  }
  const getActiveStepId = () => {
    let activeStepId: number = 1
    stepsData.filter((step) => {
      if (step.isActive) {
        activeStepId = step.id || 0
      }
      return null
    })
    return activeStepId
  }

  const getStepObj = (isBack?: boolean, stepId?: number) => {
    const activeStepIndex = getActiveStepId()

    const nextActiveStepId = stepId || stepId === 0 ? stepId : isBack ? activeStepIndex - 1 : activeStepIndex + 1
    const prevStepData = [...stepsData]
    const newStepObj = prevStepData.map((step) => {
      const obj = {...step}
      obj.isActive = step.id === nextActiveStepId
      if (!isBack || stepId || stepId === 0) {
        obj.isCompleted = (step.id || 0) < nextActiveStepId
      }
      return obj
    })
    return newStepObj
  }
  const handelNextStep = () => {
    const {statusCodes} = Appconfig.promotions
    const stepId = getActiveStepId()
    if (isApprovedWithPastDate(getPromotionResponse) === true) {
      if (stepId === 1) {
        const updatedStepData = getStepObj(false, 4)
        setSteps(updatedStepData)
        return
      }

      if (stepId === 4) {
        toast.success('Data Saved Sucessfully !')
        navigate(`/promotions`)
        return
      }
    } else {
      if (stepId === 4) {
        if (getPromotionRequestObj.promotionStatusCode.toUpperCase() === statusCodes.pending) {
          setSentForApproval(true)
          setComments('')
        } else if (getPromotionRequestObj.promotionStatusCode.toUpperCase() === statusCodes.rejected) {
          setConfirmRejection(false)
          setRejected(true)
        } else {
          setApproved(true)
          setOpenApproval(true)
        }
        return
      } else {
        const updatedStepData = getStepObj()
        setSteps(updatedStepData)
      }
    }
  }
  const handelBackStep = () => {
    const updatedStepData = getStepObj(true)
    setSteps(updatedStepData)
  }

  const handlePutPromotion = (data: PromotionsTypes.PromotionResponseBL, params: PromotionsTypes.PromotionDetails, isBack = false) => {
    setSaveLoading(true)
    PromotionService.savePromotion(data, params)
      .then((res) => {
        const {data} = res.data
        updatePromotionRequestObj(data)
        if (isBack === true) {
          handelBackStep()
        } else {
          handelNextStep()
        }
        disableHeader()
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }

  const handleNext = () => {
    updatePromotionName()
    const whenRefState = whenRef.current
    const stepId = getActiveStepId()
    const {statusCodes} = Appconfig.promotions
    const isApproved = getPromotionRequestObj.promotionStatusCode.toUpperCase() === statusCodes.approved.toUpperCase()

    if (!getPromotionResponse.promotionName || !getPromotionResponse.partnerCode) {
      validateHeader()
      setSaveLoading(false)
      return
    }
    if (stepId === 0) {
      if (actualPromotionId !== atob('new')) {
        params.PromotionCode = actualPromotionId
      } else if (isPutCall) {
        params.PromotionCode = getPromotionRequestObj.promotionCode
      } else {
        params.PromotionCode = undefined
      }
    }

    if (stepId === 1) {
      // if (!getPromotionResponse.startDateTime) {
      //   whenRefState?.validateStartDate()
      //   toast.error('From Date is required')
      //   setSaveLoading(false)
      //   return
      // }
      const isValidated = whenRefState?.validateEndDate()
      if (!isValidated) {
        toast.error('To Date is required')
        setSaveLoading(false)
        return
      }
    }

    if (stepId === 2) {
      const isValidate = triggerRef.current?.validateTrigger()
      if (!isValidate) {
        setSaveLoading(false)
        return
      }
    }
    if (stepId === 3) {
      const isValidate = incentivesRef.current?.validateTrigger()
      if (!isValidate) {
        setSaveLoading(false)
        return
      }
    }
    if (stepId === 4 && !isApproved) {
      const updatedManagerOptions = getActiveUsers
        .map((item) => {
          const name = []
          if (item.firstName) {
            name.push(item.firstName)
          }
          if (item.middleName) {
            name.push(item.middleName)
          }
          if (item.lastName) {
            name.push(item.lastName)
          }
          return {
            label: name.join(' '),
            value: item.userName,
          }
        })
        .filter((item) => item.value !== loggedInUserId)
      const loggedUserDetails = getActiveUsers.find((item) => item.userName === loggedInUserId)
      const isManagerSameAsUser = loggedUserDetails?.manager[0]?.userName === loggedInUserId
      setManagerOptions(updatedManagerOptions)
      setApprovalManager(isManagerSameAsUser ? updatedManagerOptions[0].value : loggedUserDetails?.manager[0].userName || '')
      setOpenApproval(true)
    }

    if (stepId === 1) {
      const whenData: PromotionsTypes.WhenData | undefined = whenRefState?.getSavedData()
      if (whenData) {
        const {startDateTime, endDateTime} = whenData
        getPromotionRequestObj['startDateTime'] = startDateTime ? dayjs(startDateTime).format(Appconfig.DATE_TIME_SERVER) : null
        getPromotionRequestObj['endDateTime'] = endDateTime ? dayjs(endDateTime).format(Appconfig.DATE_TIME_SERVER) : null
      }
    } else if (stepId === 2) {
      const triggerRuleData: PromotionsTypes.TriggerRuleData | undefined | null = triggerRef.current?.getState()
      if (triggerRuleData) {
        getPromotionRequestObj.promotionRuleData.triggerRuleData = triggerRuleData
      }
    } else if (stepId === 3) {
      const incentiveData: PromotionsTypes.IncentiveData[] | undefined = incentivesRef.current?.getIncentiveData()
      if (incentiveData) {
        getPromotionRequestObj.promotionRuleData.incentiveRuleData = {
          incentiveData,
        }
      }
    }

    ;(stepId !== 4 || isApproved) && handlePutPromotion(getPromotionRequestObj, params)
  }

  const handleBack = () => {
    if (isApprovedWithPastDate(getPromotionResponse) === true) {
      const stepId = getActiveStepId()
      if (stepId === 1) {
        const updatedStepData = getStepObj(false, 4)
        setSteps(updatedStepData)
        return
      }

      if (stepId === 4) {
        toast.success('Data Saved Sucessfully !')
        navigate(`/promotions`)
        return
      }
    } else {
      if (!getPromotionResponse.promotionName || !getPromotionResponse.promotionCode || !getPromotionResponse.partnerCode) {
        validateHeader()
        setSaveLoading(false)
        return
      }
      resetPromotionName()
      handelBackStep()
    }
  }

  const handleCancelApproval = () => {
    setOpenApproval(false)
    setComments('')
    setSaveLoading(false)
    setApprovalManager(getActiveUsers[0].userName)
  }

  const handleSubmitForApproval = () => {
    if (!approvalManager) {
      toast.error('Please select approval Manager.')
      return
    }
    setSaveLoading(true)
    const stepId = getActiveStepId()
    if (!getPromotionResponse.promotionName || !getPromotionResponse.promotionCode || !getPromotionResponse.partnerCode) {
      validateHeader()
      setSaveLoading(false)
      return
    }
    if (stepId === 4) {
      getPromotionRequestObj.promotionStatusCode = Appconfig.promotions.statusCodes.pending.toUpperCase()
      const updatedReqObj: PromotionsTypes.PromotionResponseBL = {
        ...getPromotionRequestObj,
        properties: {
          Comment: comments,
          CreatedBy: user?.firstName + ' ' + user?.lastName,
        },
      }

      updatePromotionRequestObj(updatedReqObj)
      const approvalManagerObj = getApprovalManagerDetails(approvalManager)
      if (approvalManagerObj) {
        getPromotionRequestObj.approvalManager = [approvalManagerObj]
      }
      handlePutPromotion(getPromotionRequestObj, params)
    }
  }

  const handleSuccessApproval = () => {
    setOpenApproval(false)
    setSentForApproval(false)
    toast.success('Data Saved Sucessfully !')
    navigate(`/promotions`)
  }

  const confirmRejectApproval = () => {
    setConfirmRejection(true)
    setOpenApproval(true)
  }

  const approvePromotion = () => {
    const reqObj = {
      ...getPromotionRequestObj,
      promotionStatusCode: Appconfig.promotions.statusCodes.approved.toUpperCase(),
    }
    handlePutPromotion(reqObj, params)
  }

  const handleEdit = (stepId: number) => {
    const updatedStepData = getStepObj(false, stepId)
    setSteps(updatedStepData)
  }

  const handleStepChange = (stepId: number): void => {
    if (stepsData.find((item) => item.id === stepId && item.isCompleted === true)) {
      const updatedSteps = getStepObj(false, stepId)
      setSteps(updatedSteps)
      resetPromotionName()
    }
  }

  const renderSteps = () => (
    // <StepsNavigationItem key={step.id} isCompleted={step.isCompleted} active={step.isActive}>
    //   {step.label}
    // </StepsNavigationItem>
    <StyledSteps items={stepsData} current={stepsData.find((item) => item.isActive === true)?.id} onChange={handleStepChange} />
  )

  const renderStepsBody = () => {
    const activeStepIndex = getActiveStepId()

    switch (activeStepIndex) {
      case 0:
        return <Who ref={whoRef} partnerCode={getPromotionResponse.partnerCode} />
      case 1:
        return <When ref={whenRef} />
      case 2:
        return <Trigger ref={triggerRef} />
      case 3:
        return <Incentives ref={incentivesRef} />
      case 4:
        return <Review isViewOnly={isViewOnly} onEdit={handleEdit} partnerCode={getPromotionResponse.partnerCode} />
      default:
        return <Who ref={whoRef} partnerCode={getPromotionResponse.partnerCode} />
    }
  }
  const renderSaveButton = () => {
    const {statusCodes} = Appconfig.promotions
    let isDisabel = false
    const stepId = getActiveStepId()
    const isApproved = getPromotionRequestObj.promotionStatusCode.toUpperCase() === statusCodes.approved.toUpperCase()
    if (
      (getPromotionRequestObj.promotionStatusCode.toUpperCase() === statusCodes.inComplete.toUpperCase() ||
        getPromotionRequestObj.promotionStatusCode.toUpperCase() === statusCodes.pending.toUpperCase()) &&
      stepId === 4
    ) {
      isDisabel = true
    }

    return (
      <Button
        loading={isSaving}
        onClick={handleNext}
        type="primary"
        disabled={isDisabel}
        $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
      >
        {activeStepIndex === 4 ? (isApproved ? 'Submit' : 'Submit for approval') : 'Save & Next'}
      </Button>
    )
  }

  const handleChange = (value: string, id: string) => {
    if (id === 'comments') {
      setComments(value)
    } else {
      setApprovalManager(value)
    }
  }

  const handleCancelRejection = () => {
    setOpenApproval(false)
    setComments('')
  }

  const handleSubmitForRejection = () => {
    if (!comments) {
      toast.error('Please enter rejection comments')
      return
    }
    getPromotionRequestObj.promotionStatusCode = Appconfig.promotions.statusCodes.rejected.toUpperCase()
    getPromotionRequestObj.properties = {
      ...getPromotionRequestObj.properties,
      RejectReason: comments,
    }

    handlePutPromotion(getPromotionRequestObj, params)
  }

  const handleApproved = () => {
    setOpenApproval(false)
    setApproved(false)
    navigate(`/promotions`)
  }

  const handleRejectionSuccess = () => {
    setOpenApproval(false)
    setRejected(false)
    setConfirmRejection(false)
    toast.success('Data Saved Sucessfully !')
    navigate(`/promotions`)
  }

  const handleClosePopup = () => {
    if (sentForApproval) {
      handleSuccessApproval()
    } else if (confirmRejection) {
      handleCancelRejection()
    } else if (rejected) {
      handleRejectionSuccess()
    } else if (approved) {
      handleApproved()
    } else {
      handleCancelApproval()
    }
  }

  const getModalWidth = () => {
    if (confirmRejection) {
      return '650px'
    }
    if (!sentForApproval && !confirmRejection && !rejected && !approved && openApproval) {
      return '650px'
    }
    return undefined
  }

  const renderApprovalModal = () => {
    if (sentForApproval) {
      const managerObj = getApprovalManagerDetails(approvalManager)
      const managerName = managerObj?.name || ''
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Submitted for Approval
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                New promotion named, {getPromotionRequestObj.promotionName} has been submitted for {managerName} approval.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleSuccessApproval}>
                Close
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    if (confirmRejection) {
      return (
        <>
          <ModalTitle>
            <Typography.Title level={1} uppercase>
              Manager Rejection Reason
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="flex-start">
            <MarginBottomWrapper width="100%">
              <Typography.Paragraph size="medium">
                <Row>
                  <Col span={6}>Promotion Id</Col>
                  <Col span={18}>{getPromotionRequestObj.promotionCode}</Col>
                </Row>
                <Row>
                  <Col span={6}>Promotion Name</Col>
                  <Col span={18}>{getPromotionRequestObj.promotionName}</Col>
                </Row>
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper width="100%">
              <TextArea id="comments" label="Reject Description" maxLength={100} value={comments} handleChange={handleChange} />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="100%">
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button type="link" onClick={handleCancelRejection}>
                  Cancel
                </Button>
                <Button type="primary" loading={isSaving} onClick={handleSubmitForRejection}>
                  Send
                </Button>
              </div>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    if (rejected) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Rejected
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">Promotion is Rejected.</Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleRejectionSuccess}>
                Close
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    if (approved) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Approved
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">Promotion is Approved.</Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleApproved}>
                Close
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    const hasManagersList = managerOptions && managerOptions.length > 0
    return (
      <>
        <ModalTitle>
          <Typography.Title level={1} uppercase>
            Submit for Manager Approval
          </Typography.Title>
        </ModalTitle>
        <StyledModalContent align="flex-start">
          <MarginBottomWrapper width="100%">
            <Typography.Paragraph size="medium">
              <Row>
                <Col span={6}>Promotion Id</Col>
                <Col span={18}>{getPromotionRequestObj.promotionCode}</Col>
              </Row>
              <Row>
                <Col span={6}>Promotion Name</Col>
                <Col span={18}>{getPromotionRequestObj.promotionName}</Col>
              </Row>
            </Typography.Paragraph>
          </MarginBottomWrapper>
          {!hasManagersList && <Typography.Paragraph>No Manager assigned.</Typography.Paragraph>}
          {hasManagersList && (
            <MarginBottomWrapper width="90%">
              <Form.Select id="manager" label="Select Manager" options={managerOptions} value={approvalManager} onChange={handleChange} />
            </MarginBottomWrapper>
          )}
          {hasManagersList && (
            <MarginBottomWrapper width="100%">
              <TextArea id="comments" label="Comments (Optional)" maxLength={100} value={comments} handleChange={handleChange} />
            </MarginBottomWrapper>
          )}
          <MarginBottomWrapper width="100%">
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button type="link" onClick={handleCancelApproval}>
                Cancel
              </Button>
              <Button type="primary" disabled={!hasManagersList} loading={isSaving} onClick={handleSubmitForApproval}>
                Submit
              </Button>
            </div>
          </MarginBottomWrapper>
        </StyledModalContent>
      </>
    )
  }

  const activeStepIndex = getActiveStepId()
  const allowBack = activeStepIndex > 0 && activeStepIndex < 4
  let isDisabelBack = false
  if (isApprovedWithPastDate(getPromotionResponse) && activeStepIndex === 4) {
    isDisabelBack = true
  }

  return (
    <>
      {showSteps && !isViewOnly ? <StepsNavigationWrapper>{renderSteps()}</StepsNavigationWrapper> : <MarginBottomWrapper />}
      {getActiveStepId() === 4 &&
        getPromotionRequestObj.promotionStatusCode.toUpperCase() === Appconfig.promotions.statusCodes.pending.toUpperCase() &&
        isManager &&
        roles.includes(PROMOTION_APPROVAL) && (
          <MarginBottomWrapper>
            <StepsBodyContainer>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography.Text>Manager Review</Typography.Text>
                <div>
                  <Button type="link" onClick={confirmRejectApproval}>
                    Reject
                  </Button>
                  <Button type="primary" onClick={approvePromotion} disabled={isSaving}>
                    Approve
                  </Button>
                </div>
              </div>
            </StepsBodyContainer>
          </MarginBottomWrapper>
        )}
      <StepsBodyContainer>{renderStepsBody()}</StepsBodyContainer>
      {!isViewOnly && (
        <StepFooter>
          {allowBack ? (
            <Button onClick={handleBack} type="default" disabled={isDisabelBack}>
              Back
            </Button>
          ) : (
            <div>&nbsp;</div>
          )}

          {(roles.includes(PROMOTION_CREATE) || roles.includes(PROMOTION_EDIT)) && renderSaveButton()}
        </StepFooter>
      )}
      {openApproval && (
        <StyledModal width={getModalWidth()} open={openApproval} footer={null} onCancel={handleClosePopup}>
          {renderApprovalModal()}
        </StyledModal>
      )}
    </>
  )
}

export default Steps
