import {Col, Row} from 'antd'
import Form from 'lib/components/Form'
import Appconfig from 'app/common/helpers/AppConfig'
import dayjs from 'dayjs'
import Typography from 'lib/Typography'
import {forwardRef, useImperativeHandle, useState} from 'react'
import {utcDate, utcWithTimeZone} from 'utils/convertDateToString'
import {FormTypes} from 'lib/components/Form/Form'
import {getPromotionRequestObj, isApprovedWithPastDate} from '../../utils'

import {CheckboxContainer, SetpsBody, StepTitle, StyledBorderRow, StyledCol} from '../Steps.styled'
import {handleDisableFromDate, handleDisableToDate} from 'utils/DatepickerUtils'

export interface WhenRefType {
  getSavedData(): PromotionsTypes.WhenData
  setData(data: PromotionsTypes.WhenData): void
  validateStartDate(): void
  validateEndDate(): boolean
}

const When = forwardRef<WhenRefType>((props, forwardedRef) => {
  // const {promotionId} = useParams<CreatePromotionParam>()
  // const isNewPromotion = promotionId === 'new'
  const promotionRequestObj = getPromotionRequestObj
  const initialState: PromotionsTypes.WhenData = {
    startDateTime: promotionRequestObj.startDateTime
      ? utcDate(utcWithTimeZone(promotionRequestObj.startDateTime), Appconfig.DATE_TIME_FORMAT)
      : utcDate(utcWithTimeZone(new Date().toISOString()), Appconfig.DATE_TIME_FORMAT),
    endDateTime: promotionRequestObj.endDateTime ? utcDate(utcWithTimeZone(promotionRequestObj.endDateTime), Appconfig.DATE_TIME_FORMAT) : null,
  }

  let isEndDateDisabeld = false
  let isCheckboxChecked = false
  // if (isNewPromotion) {
  //   isEndDateDisabeld = false
  //   isCheckboxChecked = false
  // } else {
  isEndDateDisabeld = initialState.endDateTime ? false : true
  isCheckboxChecked = initialState.endDateTime ? false : true
  // }

  const [state, setState] = useState(initialState)
  const [disabledEndDate, setEndDateDisable] = useState(isEndDateDisabeld)
  const [startDateEror, isStartDateError] = useState<boolean>(false)
  const [endDateError, isEndDateError] = useState<boolean>(false)

  const [isChecked, setIsChecked] = useState(isCheckboxChecked ? 'isChecked' : '')
  const getSavedData = (): PromotionsTypes.WhenData => {
    return state
  }

  const setData = (data: PromotionsTypes.WhenData) => {
    setState(data)
  }

  const validateStartDate = () => {
    isStartDateError(!state.startDateTime)
  }

  const validateEndDate = () => {
    if (!isChecked && !state.endDateTime) {
      isEndDateError(true)
      return false
    }
    if (isChecked) {
      return true
    }
    return true
  }

  useImperativeHandle(forwardedRef, () => ({getSavedData, setData, validateStartDate, validateEndDate}))

  const handleOnDateChange = (date: string | null, key: keyof PromotionsTypes.WhenData) => {
    const prevState = {...state}
    const dateTimeString = date ? dayjs(date).format() : null
    prevState[key] = dateTimeString
    setState(prevState)
  }

  const onNoDataClick = (value: string[], key: string) => {
    const checkedValue = value[0]
    setEndDateDisable(!disabledEndDate)
    setState({...state, endDateTime: null})
    setIsChecked(checkedValue)
  }
  const isStartDateDisabel = isApprovedWithPastDate(getPromotionRequestObj)

  const noDateCheckboxOptions: FormTypes.Options[] = [
    {
      label: 'No end date for this promotion',
      value: 'isChecked',
    },
  ]

  return (
    <div>
      <StepTitle>
        <Typography uppercase variant="h6">
          Set Duration
        </Typography>
      </StepTitle>
      <SetpsBody>
        <Row gutter={40}>
          <StyledCol span={7}>
            <Form.DatePicker
              disabledDate={(current: dayjs.Dayjs) => handleDisableFromDate(current, state.startDateTime, state.endDateTime)}
              showTime
              label="From"
              placeholder="Select From Date"
              id="startDateTime"
              value={state.startDateTime}
              onChange={handleOnDateChange}
              status={startDateEror ? 'error' : undefined}
              disabled={isStartDateDisabel}
            />
          </StyledCol>
          <StyledCol span={7}>
            <Form.DatePicker
              disabledDate={(current: dayjs.Dayjs) => handleDisableToDate(current, state.startDateTime, state.endDateTime)}
              showTime
              label="To"
              placeholder="Select To Date"
              id="endDateTime"
              value={state.endDateTime}
              onChange={handleOnDateChange}
              disabled={disabledEndDate}
              status={endDateError ? 'error' : undefined}
            />
          </StyledCol>
        </Row>
        <StyledBorderRow>
          <Col>
            <CheckboxContainer>
              <Form.Checkbox id="noenddate" options={noDateCheckboxOptions} value={[isChecked]} onChange={onNoDataClick} />
            </CheckboxContainer>
          </Col>
        </StyledBorderRow>
      </SetpsBody>
    </div>
  )
})

export default When
