import Appconfig from 'app/common/helpers/AppConfig'
import HttpRequest, { _removeAuthTokenToHttpHeader, _setAuthTokenToHttpHeader } from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'
import { lStorage } from 'utils/BrowserStorage'

const sessionTokenKey = Appconfig.AUTH_TOKEN_LOCAL_STORAGE_KEY

export const login = (body: AuthServiceTypes.Request): Promise<AxiosResponse<ResponseTypes.LoginResponseType>> => {
  let referralUrl = window.location.host
  if (window.location.host.includes('localhost')) {
    referralUrl = Appconfig.REFERRALURL
  }
  const updatedBody = {
    username: btoa(body.username),
    password: btoa(body.password),
    grant_type: 'password',
    accessTokenLifetime: window.env.REACT_APP_API_ACCESSTOKENLIFETIME,
    refreshTokenLifetime: window.env.REACT_APP_API_REFRESHTOKENLIFETIME,
    referralUrl
  }
  const requestOptions: HttpRequestType = {
    url: `${ServiceConfig.login}`,
    data: updatedBody,
    method: 'post',
    headers: { "Content-Type": 'application/x-www-form-urlencoded' },
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const logout = () => {
  removeSessionToken()
}

export const forgotPassword = (body: AuthServiceTypes.ForgetPasswordPaylodType): Promise<AxiosResponse<ResponseTypes.ForgotPasswordResponseType>> => {
  let referralUrl = window.location.host
  if (window.location.host.includes('localhost')) {
    referralUrl = Appconfig.REFERRALURL
  }
  const updatedPayload = {
    ...body,
    referralUrl
  }
  const requestOptions: HttpRequestType = {
    url: `${ServiceConfig.forgotToken}`,
    data: updatedPayload,
    method: 'post',
    headers: { "content-type": 'application/json' },
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const resetPassword = (body: AuthServiceTypes.ResetPasswordPaylodType): Promise<AxiosResponse<ResponseTypes.ForgotPasswordResponseType>> => {
  let referralUrl = window.location.host
  if (window.location.host.includes('localhost')) {
    referralUrl = Appconfig.REFERRALURL
  }
  const updatedPayload = {
    ...body,
    referralUrl
  }
  const requestOptions: HttpRequestType = {
    url: `${ServiceConfig.resetPassword}`,
    data: updatedPayload,
    method: 'post',
    headers: { "content-type": 'application/json' },
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const createPassword = (body: AuthServiceTypes.ResetPasswordPaylodType): Promise<AxiosResponse<ResponseTypes.ForgotPasswordResponseType>> => {
  let referralUrl = window.location.host
  if (window.location.host.includes('localhost')) {
    referralUrl = Appconfig.REFERRALURL
  }
  const updatedPayload = {
    ...body,
    referralUrl
  }
  const requestOptions: HttpRequestType = {
    url: `${ServiceConfig.resetPassword}`,
    data: updatedPayload,
    method: 'post',
    headers: { "content-type": 'application/json' },
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const removeSessionToken = () => {
  lStorage._remove(sessionTokenKey)
  _removeAuthTokenToHttpHeader()
}

export const getSessionToken = () => {
  const { authToken } = lStorage._get(sessionTokenKey) || { authToken: '' }
  return authToken
}

export const setSessionToken = (authToken: string) => {
  lStorage._set(sessionTokenKey, { authToken })
  _setAuthTokenToHttpHeader(authToken)
}

const LoginService = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  createPassword,
  removeSessionToken,
  getSessionToken,
  setSessionToken
}

export default LoginService
