// import IconSuccess from 'app/common/icons/IconSuccess'
import {MessageWrapper, MessageText} from './Message.styled'

type MessageSubComponents = {
  Text: typeof MessageText
}

interface MessageProps {
  children: React.ReactNode
  icon: JSX.Element
  inline?: boolean
}

type MessagePropsType = MessageProps

const Message: React.FunctionComponent<MessagePropsType> & MessageSubComponents = ({children, inline, icon}: MessageProps) => {
  return (
    <MessageWrapper $inline={inline}>
      {/* <IconSuccess /> */}
      {icon}
      <div>{children}</div>
    </MessageWrapper>
  )
}

Message.Text = MessageText

export default Message
