import styled from 'styled-components'

export const BreadcrumbsWrapper = styled.div`
  padding: 6px 0 40px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
`

export const ActivePageLabel = styled.span`
  text-decoration: none;
`
export const Seprator = styled(ActivePageLabel).attrs({
  as: 'span',
})`
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #5e5e5e;
  }
  padding: 0 5px;
`

export const BreadcrumbLinkRoot = styled.span`
  display: inline-flex;
  align-items: center;
`

export const BreadcrumbsHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
