import {Typography as AntTypography} from 'antd'
import Link from './Link'
import Text from './Text'
import Title from './Title'
import Paragraph from './Paragraph'

type TypographyComponents = {
  Title: typeof Title
  Link: typeof Link
  Text: typeof Text
  Paragraph: typeof Paragraph
}

export interface TypographyProps {
  children?: React.ReactNode
}

type TypographyPropsType = TypographyProps

const Typography: React.FunctionComponent<TypographyPropsType> & TypographyComponents = (props: TypographyProps) => (
  <AntTypography>{props.children}</AntTypography>
)

Typography.Title = Title
Typography.Link = Link
Typography.Text = Text
Typography.Paragraph = Paragraph

export default Typography
