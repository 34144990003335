import styled from 'styled-components'
import Colors from 'styles/Colors'

export const FilterWrapper = styled.div`
  background-color: ${Colors.BLUE_DARK};
  padding: 20px;
  display: flex;
  width: 100%;
  min-height: 68px;
  .search-btn {
    background-color: ${Colors.SECONDARY};
    border: 0;
    color: ${Colors.BLUE_DARK};
  }
  .lb-picker {
    color: ${Colors.WHITE};
    background-color: ${Colors.COLOR_BLUE_LIGHT_OPACITY};
    border-color: transparent;
  }
  .lb-picker .lb-picker-input > input {
    color: ${Colors.WHITE};
    border-color: ${Colors.COLOR_BLUE_LIGHT_OPACITY};
    &::placeholder {
      color: ${Colors.WHITE};
      font-size: 14px;
      font-weight: 500;
    }
  }
  .lb-picker .lb-picker-separator,
  .lb-picker .lb-picker-suffix {
    color: ${Colors.WHITE};
  }
`

export const FilterFormWrapper = styled.form`
  display: flex;
  column-gap: 2%;
  flex: 1;
  & > div {
    display: flex;
    flex: 1;
  }
`

export const FilterExtendedFormWrapper = styled.form`
  & > div {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 8px;
  }
`
