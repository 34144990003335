import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const getTags = (
  params?: TagTypes.TagSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<TagTypes.TagResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getTags,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveTags = (
  data: TagTypes.SaveTagPayload,
  params: TagTypes.TagDetails
): Promise<AxiosResponse<ResponseTypes.PagedResponse<TagTypes.TagResponseBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getTags,
    data,
    params,
    withCredentials: false,
    method: params.voucherTagCode ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

const TagService = {
  getTags,
  saveTags
}

export default TagService
