import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import {ReviewDetailsItem, StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import Typography from 'lib/components/Typography'
import {getProductRequestObj} from './utils'
import {isEmpty} from 'lodash'
import {ReviewRow} from 'app/pages/Segmentation/Segment.styled'
import Button from 'lib/components/Button'
import {FormTypes} from 'lib/components/Form/Form'

interface ReviewProductProps {
  handleEdit(): void
  showEditProduct: boolean
  isViewOnly?: boolean
  partnerCodeOptions: FormTypes.Options[]
}

const ReviewProduct: React.FC<ReviewProductProps> = ({handleEdit, showEditProduct, partnerCodeOptions}) => {
  const productData = getProductRequestObj
  const {productCode, productDescription, productName, skuCode, properties, partnerCode} = productData
  const partnerCodes = partnerCodeOptions.find((item) => item.value == partnerCode)?.label ?? ''
  return (
    <>
      <ReviewRow>
        <div>
          <MarginBottomWrapper>
            <Typography.Text color="primary" strong uppercase>
              Product Information
            </Typography.Text>
          </MarginBottomWrapper>
          {productCode && (
            <ReviewDetailsItem>
              <StyledReviewLabel>Product ID</StyledReviewLabel>
              <StyledReviewValue>{productCode}</StyledReviewValue>
            </ReviewDetailsItem>
          )}
          <ReviewDetailsItem>
            <StyledReviewLabel>Partner Name</StyledReviewLabel>
            <StyledReviewValue>{partnerCodes}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Product SKU</StyledReviewLabel>
            <StyledReviewValue>{skuCode}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Product Name</StyledReviewLabel>
            <StyledReviewValue>{productName}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Description</StyledReviewLabel>
            <StyledReviewValue>{productDescription}</StyledReviewValue>
          </ReviewDetailsItem>
        </div>
        {showEditProduct && (
          <div>
            <Button onClick={handleEdit} size="small" type="link" disabled={false}>
              Edit
            </Button>
          </div>
        )}
      </ReviewRow>
      <ReviewRow>
        <div>
          <MarginBottomWrapper>
            <Typography.Text color="primary" strong uppercase>
              Attributes and Values
            </Typography.Text>
          </MarginBottomWrapper>
          {!isEmpty(properties) ? (
            <>
              {Object.entries(properties).map((attr, index) => (
                <ReviewDetailsItem key={`${attr}-${index}`}>
                  <StyledReviewLabel>{attr[0]}</StyledReviewLabel>
                  <StyledReviewValue>{attr[1]}</StyledReviewValue>
                </ReviewDetailsItem>
              ))}
            </>
          ) : (
            <>No Attributes available</>
          )}
        </div>
        {showEditProduct && (
          <div>
            <Button onClick={handleEdit} size="small" type="link" disabled={false}>
              Edit
            </Button>
          </div>
        )}
      </ReviewRow>
    </>
  )
}

export default ReviewProduct
