import {FormEvent, useRef, useState} from 'react'
import MemberInfoForm, {MemberInfoRefType} from './MemberInfoForm'
// import InfoHeader from './MemberInfoHeader'
import MembershipService from 'app/services/MemberService'
import dayjs from 'dayjs'
import {toast} from 'react-hot-toast'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {MEMBER_DETAIL_EDIT} from 'app/common/helpers/UserFunctions'
import Appconfig from 'app/common/helpers/AppConfig'
import { ShowPointTypeData } from '..'

interface MemberInfoProps {
  memberDetail: MemebershipTypes.MembershipDataBL
  memberUpdate: () => void
  showPointDetails: ShowPointTypeData
}

const MemberInfo = ({memberDetail, memberUpdate, showPointDetails}: MemberInfoProps) => {
  const [updatedMemberDetail, setMemberDetail] = useState<MemebershipTypes.MembershipDataBL>(memberDetail)
  const [isEdit, setIsEdit] = useState(false)
  const memberInfoFormRef = useRef<MemberInfoRefType>(null)
  const {roles} = useAuth()
  const isMemberEdit = roles.includes(MEMBER_DETAIL_EDIT)

  const handleEdit = (edit: boolean) => {
    setIsEdit(edit)
  }

  const updateMemberInfo = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const memberFormData = memberInfoFormRef.current?.getState()
    if (!memberInfoFormRef.current?.validate()) {
      return
    }
    if (memberFormData) {
      const savePayload: MemebershipTypes.MembershipDataBL = {
        ...memberDetail,
        membershipFirstName: memberFormData.firstName,
        membershipLastName: memberFormData.lastName,
        membershipEmailId: memberFormData.email,
        membershipBirthDate: memberFormData.birthDay === '-' ? null : dayjs(memberFormData.birthDay).format(Appconfig.DATE_TIME_BIRTHDAY_DATE) + 'Z',
        membershipAddress1: memberFormData.address,
        membershipAddress2: memberFormData.address2,
        membershipGender: memberFormData.gender,
        membershipPhone: memberFormData.phone,
        membershipState: memberFormData.state,
        membershipCity: memberFormData.city,
        membershipZipCode: memberFormData.zipcode,
        referralCustomerId: memberFormData.referralCustomerId,
      }
      const parameters: MemebershipTypes.MemberDataCustomerId = {
        membershipCustomerId: memberDetail.membershipCustomerId,
      }
      MembershipService.saveMemberDetail(savePayload, parameters)
        .then((res) => {
          setMemberDetail(res.data.data)
          setIsEdit(false)
          toast.success('Data Saved Sucessfully !')
          memberUpdate()
        })
        .finally(() => {})
    }
  }
  const renderFormChild = () => {
    return (
      <>
        {/* <InfoHeader isEdit={isEdit} onEdit={handleEdit} isMemberEdit={isMemberEdit} /> */}
        <MemberInfoForm ref={memberInfoFormRef} isEdit={isEdit} memberDetail={updatedMemberDetail} isMemberEdit={isMemberEdit} onEdit={handleEdit}  showPointDetails={showPointDetails}/>
      </>
    )
  }
  const renderForm = () => {
    return <form onSubmit={updateMemberInfo}>{renderFormChild()}</form>
  }

  if (isEdit) {
    return renderForm()
  }
  return renderFormChild()
}

export default MemberInfo
