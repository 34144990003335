import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import {NewSegmentFooter, SegmentsFormBodyContainer} from '../Segment.styled'
import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import SegmentsHeader from './SegmentsHeader'
import {Modal} from 'antd'
import {MarginBottomWrapper, ModalTitle, Seperator, StyledModalContent} from 'app/common/components/Styled/common.styled'
import PartnerService from 'app/services/PartnerService'
import {FormTypes} from 'lib/components/Form/Form'
import Typography from 'lib/components/Typography'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import {getSegmentRequestObj, initialSegmentRequestObj, updateSegmentRequestObj} from './utils'
import LoadingPanel from 'lib/components/LoadingPanel'
import ReviewSegments from './ReviewSegments'
import NewSegmentForm, {SegmentRefType} from './NewSegmentForm'
import SegmentationService from 'app/services/SegmentationService'
import IconSuccess from 'app/common/icons/IconSuccess'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {SEGMENT_CREATE, SEGMENT_EDIT} from 'app/common/helpers/UserFunctions'

export type AddNewSegmentParam = {
  segmentId: string | 'new' | 'view'
}

interface AddNewSegmentProps {
  isViewOnly?: boolean
}

const AddNewSegment: React.FC<AddNewSegmentProps> = ({isViewOnly}) => {
  const {segmentId} = useParams<AddNewSegmentParam>()
  const actualSegmentId = atob(segmentId || '')
  const segmentFormRef = useRef<SegmentRefType>(null)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(segmentId === 'new' ? 0 : 1)
  const [loading, setLoading] = useState(false)
  const [loadingSegment, setLoadingSegment] = useState(false)
  const [individualSegment, setIndividualSegment] = useState<SegmentationTypes.SegmentResponseBL>()
  // const [disableSave, setDisableSave] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const allowBack = activeStep === 1
  const {roles} = useAuth()
  const [partnerCodeOptions, setPartnerCodeOptions] = useState<FormTypes.Options[]>([])
  const [partnerCode, setPartnerCode] = useState('')
  const showEditSegment = roles.includes(SEGMENT_EDIT)
  const showCreateSegment = roles.includes(SEGMENT_CREATE)

  useEffect(() => {
    if (segmentId !== 'new') {
      setLoadingSegment(true)
      SegmentationService.search({SegmentCode: actualSegmentId})
        .then((res) => {
          const data = res.data.data[0]
          const updatedData = {
            ...data,
            segmentCategory: data.segmentCategory || 'STATIC'
          }
          setIndividualSegment(updatedData)
          updateSegmentRequestObj(updatedData)
        })
        .finally(() => {
          setLoadingSegment(false)
        })
    }
  }, [actualSegmentId])

  const showPreview = () => {
    if (segmentFormRef.current?.validate()) {
      setActiveStep(1)
    }
  }

  const updateSegmentReqPartnerData = (data: PartnerTypes.PartnerBL[]) => {
    const partnerCodeFromRequest = getSegmentRequestObj.partnerCode
    const defaultCode = data.find((partnerOption) => partnerOption.isDefaultPartner)
    if (partnerCodeFromRequest) {
      const hasPartnerCode = data.find((partnerOption) => partnerOption.partnerCode === partnerCodeFromRequest)
      if (!hasPartnerCode) {
        getSegmentRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
      }
    } else {
      getSegmentRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
    }
    setPartnerCode(getSegmentRequestObj.partnerCode)
  }

  const getPartnerCodes = () => {
    PartnerService.getPartners().then((res) => {
      const data = res.data.data
      const partnerOptions = data.map((item) => ({value: item.partnerCode, label: item.partnerName}))
      setPartnerCodeOptions(partnerOptions)
      updateSegmentReqPartnerData(data)
    })
  }

  useEffect(() => {
    getPartnerCodes()
  }, [])

  const submitForApproval = () => {
    setLoading(true)
    setProcessing(true)
    setOpen(true)
    SegmentationService.saveSegment(getSegmentRequestObj, {segmentCode: segmentId !== 'new' ? getSegmentRequestObj.segmentCode : undefined})
      .then((res) => {
        const {data} = res.data
        setIndividualSegment(data)
        updateSegmentRequestObj(data)
        setOpen(true)
        setIsSaved(true)
        // navigate(`/segments`)
      })
      .catch(() => {
        setOpen(false)
      })
      .finally(() => {
        setLoading(false)
        setProcessing(false)
      })
  }

  const handleEditSegment = () => {
    allowBack && setActiveStep(0)
  }

  const handleCancelConfirm = () => {
    updateSegmentRequestObj(initialSegmentRequestObj)
    navigate(`/segments`)
  }

  const handleOk = () => {
    setOpen(false)
    handleCancelConfirm()
  }

  const handleCancelNewSegment = () => {
    if (isViewOnly === true) {
      handleCancelConfirm()
    } else {
      setOpen(true)
    }
  }
  const handleCancelModal = () => {
    setOpen(false)
  }

  const isSaveAllowed = (tabId: number) => {
    // setDisableSave(tabId === 2)
  }

  const renderSegmentsBody = () => {
    if (loadingSegment) {
      return <LoadingPanel />
    }
    if (segmentId !== 'new' && !individualSegment) {
      return <div>No Segment found with {actualSegmentId}</div>
    }
    if (activeStep === 0) {
      return <NewSegmentForm ref={segmentFormRef} handleUpdateSave={isSaveAllowed} partnerCodeOptions={partnerCodeOptions} />
    }
    return <ReviewSegments isViewOnly={isViewOnly} handleEdit={handleEditSegment} />
  }

  const renderSaveButton = () => {
    return (
      <Button
        loading={loading}
        onClick={activeStep === 0 ? showPreview : submitForApproval}
        type="primary"
        disabled={partnerCode == ''}
        // disabled={disableSave}
        $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
      >
        {activeStep === 1 ? 'Save and Create New Segment' : 'Preview'}
      </Button>
    )
  }
  const showEdit = () => {
    setActiveStep(0)
  }
  const renderEditButton = () => {
    return (
      activeStep === 1 && (
        <Button
          loading={loading}
          onClick={showEdit}
          type="primary"
          // disabled={disableSave}
        >
          Back
        </Button>
      )
    )
  }

  const renderSegmentsFooter = () => {
    return (
      !isViewOnly && (
        <>
          <NewSegmentFooter>
            {showEditSegment && renderEditButton()}
            {(showEditSegment || showCreateSegment) && renderSaveButton()}
          </NewSegmentFooter>
        </>
      )
    )
  }

  const handleCompleted = () => {
    setOpen(false)
    setIsSaved(false)
    navigate(`/segments`)
  }

  const handleCloseModal = () => {
    if (isSaved) {
      navigate(`/segments`)
    } else {
      handleCancelModal()
    }
  }

  const renderModalBody = () => {
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (isSaved) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              New Segment Created
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                The new segment named <b>{individualSegment?.segmentName}</b> has been created.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">Are you sure you want to cancel adding {getSegmentRequestObj.segmentName}?</Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Continue with cancel
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs backLinkLabel="Segments" backLinkPath={`/segments`} activePageLabel={isViewOnly ? 'Segment Detail' : 'Add New Segments'} />
        <Button type="link" onClick={handleCancelNewSegment}>
          {segmentId === 'new' ? 'Cancel New Segment' : 'Cancel'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <SegmentsHeader activeStep={activeStep} />
      <SegmentsFormBodyContainer>{renderSegmentsBody()}</SegmentsFormBodyContainer>
      {renderSegmentsFooter()}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCloseModal} footer={null}>
          {renderModalBody()}
        </Modal>
      )}
    </div>
  )
}

export default AddNewSegment
