import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Typography from 'lib/components/Typography'

interface SegmentHeaderProps {
  activeStep: number
}

const SegmentsHeader: React.FC<SegmentHeaderProps> = ({activeStep}) => {
  return (
    <StyledHeader>
      <Typography.Title level={2}>{activeStep === 0 ? 'Add New Segment' : 'Preview'}</Typography.Title>
    </StyledHeader>
  )
}

export default SegmentsHeader
