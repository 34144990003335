const Colors = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  RED: '#AF0909',
  ERROR: '#AF0909',
  GREEN: '#1C832C',

  SUCCESS: '#1C832C',
  BLUE: '#0078E8',
  LINK: '#0078E8',
  GREY1: '#000000',
  GREY2: '#1D1D1D',
  GREY3: '#4B4B4B',
  GREY4: '#767676',
  GREY5: '#A5A5A5',
  GREY6: '#B1B1B1',
  GREY7: '#D9D9D9',
  GREY8: '#EEEEEE',
  GREY9: '#F9F9F9',
  GREY10: '#DEDEDE',
  GREY11: '#F8F8F8',
  GREY12: '#F3F3F3',
  GREY13: '#AEAEAE',
  GREY14: '#CCCCCC',
  BORDER1: '#BEBEBE',
  BORDER2: '#CFCFCF',
  BORDER3: '#A8A8A8',

  GREEN2: '#3AAD38',
  PRIMARY: '#0062FF',
  PRIMARY_DARK: '#001797',
  SECONDARY: '#FFAFA2',
  TEXT_COLOR_PRIMARY: '#050B1D',
  TEXT_COLOR_SECONDARY: '#47637C',
  BLUE_LIGHT: '#EEF7FF',
  BLUE_DARK: '#001797',
  COLOR_TEXT: '#050B1D',
  COLOR_TEXT_SECONDARY: '#47637C',
  COLOR_BLUE_LIGHT_OPACITY: 'rgba(255, 255, 255, 0.15)',
  COLOR_YELLOW: '#FFFF00',
}

export default Colors
