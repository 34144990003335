import Typography from 'lib/components/Typography'
import {StyledHeaderSection} from '../UserManagementStyled'
import Tabs from 'app/common/components/Tabs'
import Roles from '../Roles'
import Users from '../Users'
import Functions from '../Functions'
import {useEffect, useState} from 'react'

const Dashboard = () => {
  const active = sessionStorage.getItem('activeTab')
  const [activeTabId, setActiveTabId] = useState(active ? Number(active) : 0)

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('activeTab')
    }
  }, [])

  const handleOnTabChange = (tabId: number) => {
    setActiveTabId(tabId)
  }

  return (
    <section>
      <StyledHeaderSection>
        <Typography.Title>User Management</Typography.Title>
      </StyledHeaderSection>
      <Tabs>
        <Tabs.Tab label="Roles" id={0} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
        <Tabs.Tab label="Users" id={1} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
        <Tabs.Tab label="Functions" id={2} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
      </Tabs>
      {activeTabId === 0 && <Roles />}
      {activeTabId === 1 && <Users />}
      {activeTabId === 2 && <Functions />}
    </section>
  )
}

export default Dashboard
