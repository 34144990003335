import IconAdd from 'app/common/icons/IconAdd'
import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import Typography from 'lib/components/Typography'
import Colors from 'styles/Colors'
import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {isEmpty} from 'lodash'
// import { POINT_CREATE } from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'
import SearchResults from './SearchResults'
import TierSystemDetails from '../TierSystemDetails'
//import { sampleTierData } from '../utils'
import {MainContainer} from 'app/common/components/Styled/common.styled'
import TierService from 'app/services/TierService'
import LoadingPanel from 'lib/components/LoadingPanel'
import {TIER_SEARCH, TIER_VIEW, TIER_CREATE} from 'app/common/helpers/UserFunctions'
import TierFilter, {ResetTierFormRefType} from './TierFilter'

// import { Modal } from 'antd'

const Tiers = () => {
  const initialResponse: ResponseTypes.ResponseData<TierTypes.TierMasterResponseBL[]> = {
    data: [],
    message: '',
  }

  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const initialSearchParms: TierTypes.TierSearch = {Strict: false}
  const [isFetching, setIsFetching] = useState(true)
  const [tierCount, setTierCount] = useState(0)
  const [isFormReset, setIsFormReset] = useState(true)
  const [tiersSystemData, setTiersSystemData] = useState<ResponseTypes.ResponseData<TierTypes.TierMasterResponseBL[]>>(initialResponse)
  const navigate = useNavigate()
  const {roles} = useAuth()
  const showSearch = roles.includes(TIER_SEARCH)
  const showTable = roles.includes(TIER_VIEW)
  const showCreateTier = roles.includes(TIER_CREATE)
  const rewardFormRef = useRef<ResetTierFormRefType>(null)

  const handleSearch = (params: TierTypes.TierSearch) => {
    const reqParam: TierTypes.TierSearch = {
      // IsCombinable: false,
      ...params,
    }
    setIsFetching(true)
    TierService.getTiers(reqParam)
      .then((res) => {
        const {data} = res
        setTiersSystemData({...data})
        setTierCount(data.data.length)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    handleSearch({...initialSearchParms})
  }, [])

  const handleAddTier = () => {
    navigate(`/program/tiers/new`)
  }

  const getTierSystemInfo = (tier: TierTypes.TierMasterResponseBL): TierTypes.TierSystemInfo => {
    const {tierSystemKey, tierSystemName, partnerCode, startDate, endDate, assesmentTime, assesmentType, tierList, tierStatus} = tier
    return {
      tierSystemKey,
      tierSystemName,
      startDate,
      endDate,
      assesmentTime,
      tierStatus,
      partnerCode,
      assesmentType,
      tierList,
    }
  }

  const handleFilter = (data: TierTypes.TierFilterSearch) => {
    const filterParms: TierTypes.TierSearch = {}
    filterParms.TierSystemKey = data.tierSystemKey
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    handleSearch({...initialSearchParms, ...filterParms})
  }

  const handleResetSearch = () => {
    rewardFormRef.current?.handleReset()
    if (!isFormReset) {
      handleSearch({...initialSearchParms})
      setIsFormReset(!isFormReset)
    }
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const renderTierBody = () => {
    // if (isFetching) {
    //   return <LoadingPanel />
    // }
    if ((showSearch && showTable) || showCreateTier) {
      return (
        <DataTable>
          {showSearch && showTable && <TierFilter ref={rewardFormRef} isFetching={isFetching} handleFilter={handleFilter} />}

          {isFetching && <LoadingPanel />}
          {!isFetching && showTable && (
            <>
              <DataTable.TotalResult
                resetSearch={renderResetSearch()}
                showSearch={showSearch && showTable}
                showTable={showTable}
                noOfResults={tierCount}
              />
              {tiersSystemData.data.map((tier) => (
                <MainContainer>
                  <TierSystemDetails tierSystemData={getTierSystemInfo(tier)} />
                  <SearchResults isFetching={isFetching} tierList={tier.tierList} tierStatus={tier.tierStatus} tierSystemKey={tier.tierSystemKey} />
                </MainContainer>
              ))}
            </>
          )}
        </DataTable>
      )
    }
    return <NoPermission />
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Tier Master</Typography.Title>
        {showCreateTier && (
          <Button onClick={handleAddTier} type="primary" $startIcon={<IconAdd color={Colors.WHITE} size={20} />}>
            Create New Tier System
          </Button>
        )}
      </StyledHeader>
      {renderTierBody()}
    </section>
  )
}

export default Tiers
