const IconDownload = ({size = 16, color = '#021797'}: IconType.Props) => {
  return (
    <svg width={size} height={size}>
      <g transform="matrix(1 0 0 1 -957 -273 )">
        <path
          d="M 6 0.75  C 6 0.334375  6.334375 0  6.75 0  L 9.25 0  C 9.665625 0  10 0.334375  10 0.75  L 10 6  L 12.740625 6  C 13.296875 6  13.575 6.671875  13.18125 7.065625  L 8.428125 11.821875  C 8.19375 12.05625  7.809374999999999 12.05625  7.574999999999999 11.821875  L 2.815625 7.065625  C 2.421875 6.671875  2.6999999999999997 6  3.2562499999999996 6  L 6 6  L 6 0.75  Z M 15.25 11  C 15.665625 11  16 11.334375  16 11.75  L 16 15.25  C 16 15.665625  15.665625 16  15.25 16  L 0.75 16  C 0.334375 16  0 15.665625  0 15.25  L 0 11.75  C 0 11.334375  0.334375 11  0.75 11  L 5.334375 11  L 6.865625 12.53125  C 7.4937499999999995 13.159375  8.50625 13.159375  9.134374999999999 12.53125  L 10.665624999999999 11  L 15.25 11  Z M 11.5 15.125  C 11.84375 15.125  12.125 14.84375  12.125 14.5  C 12.125 14.15625  11.84375 13.875  11.5 13.875  C 11.15625 13.875  10.875 14.15625  10.875 14.5  C 10.875 14.84375  11.15625 15.125  11.5 15.125  Z M 13.5 15.125  C 13.84375 15.125  14.125 14.84375  14.125 14.5  C 14.125 14.15625  13.84375 13.875  13.5 13.875  C 13.15625 13.875  12.875 14.15625  12.875 14.5  C 12.875 14.84375  13.15625 15.125  13.5 15.125  Z "
          fill-rule="nonzero"
          fill={color}
          stroke="none"
          transform="matrix(1 0 0 1 957 273 )"
        />
      </g>
    </svg>
  )
}

export default IconDownload
