import {Col, Row} from 'antd'
import IconDelete from 'app/common/icons/IconDelete'
import Button from 'lib/components/Button'
import Form from 'lib/components/Form'
import {FormTypes} from 'lib/components/Form/Form'
import {useEffect, useState} from 'react'
import {getProductRequestObj} from './utils'
import ProductService from 'app/services/ProductService'
import LoadingPanel from 'lib/components/LoadingPanel'

interface ProductCategoryRowProps {
  attr: FormTypes.Options
  rowIndex: number
  categoryOptions: FormTypes.Options[]
  handleDeleteAttribute(rowIndex: number): void
  onCategoryDataChange(value: FormTypes.Options, rowIndex: number): void
  totalRows: number
}

const ProductCategoryRow: React.FC<ProductCategoryRowProps> = ({
  attr,
  rowIndex,
  categoryOptions,
  handleDeleteAttribute,
  onCategoryDataChange,
  totalRows,
}) => {
  const [categoryValues, setCategoryValues] = useState<FormTypes.Options[]>([])
  const [isCategoryValueLoading, setIsCategoryValueLoading] = useState<boolean>(false)
  const [selectedKey, setSelectedKey] = useState<string>(attr.label as string)
  const [selectedValue, setSelectedValue] = useState<string>(attr.value as string)

  const getCategoriesValues = (value: string) => {
    const {partnerCode} = getProductRequestObj
    setIsCategoryValueLoading(true)
    ProductService.getProductCategoryValue({CategoryCode: value, PartnerCode: partnerCode})
      .then((res) => {
        const data = res.data.data
        const options: FormTypes.Options[] = data.map((item) => ({value: item.categoryValue, label: item.categoryValue}))
        setCategoryValues(options)
        if (attr.value && options.some((k) => k.value === attr.value)) {
          setSelectedValue(attr.value as string)
        }
      })
      .finally(() => {
        setSelectedKey(value)
        setIsCategoryValueLoading(false)
      })
  }

  useEffect(() => {
    if (attr.label) {
      getCategoriesValues(attr.label as string)
      setSelectedValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnCategoryChange = (value: string, id: string) => {
    onCategoryDataChange({label: value, value: ''}, rowIndex)
  }

  const handleChange = (value: string, id: string) => {
    setSelectedValue(value)
    onCategoryDataChange({label: attr.label, value}, rowIndex)
  }

  return (
    <>
      <Row style={{alignItems: 'center'}} gutter={16}>
        <Col span={8}>
          <Form.Select
            id="attribute"
            options={categoryOptions}
            label="Select Attribute"
            placeholder="Select"
            value={selectedKey}
            onChange={handleOnCategoryChange}
            showSearch
            // disabled={isRewardAndPartnerDisabled || isOtherThenToggleAndEndDateDisabled}
          />
        </Col>
        <Col span={1} style={{textAlign: 'center', fontSize: '16px'}}>
          =
        </Col>
        <Col span={8}>
          {isCategoryValueLoading ? (
            <LoadingPanel />
          ) : (
            <Form.Select
              id="attributeValue"
              options={categoryValues}
              label="Select Value"
              placeholder="Select"
              value={selectedValue}
              onChange={handleChange}
              disabled={!selectedKey}
              showSearch
            />
          )}
        </Col>
        {totalRows > 1 && (
          <Col span={1}>
            <Button type="text" onClick={() => handleDeleteAttribute(rowIndex)}>
              <IconDelete size={16} />
            </Button>
          </Col>
        )}
      </Row>
    </>
  )
}

export default ProductCategoryRow
