import {TypographyTypes} from './Typography'
import {StyledParagraph} from './Typography.styled'

const Text: React.FC<TypographyTypes.Paragraph> = (props) => {
  return (
    <StyledParagraph $size={props.size} $uppercase={props.uppercase} $color={props.color} {...props}>
      {props.children}
    </StyledParagraph>
  )
}

Text.defaultProps = {
  color: 'default',
  size: 'medium',
}

export default Text
