import React, {useCallback, useState} from 'react'
import {useDropzone, FileRejection, DropzoneOptions} from 'react-dropzone'
import {ImagePickerContainer, ImagePickerPreview, ImagePickerRoot, ImagePickerbody} from './ImagePicker.styled'
import FormLabel from 'lib/components/Form/FormLabel'
import IconUpload from 'app/common/icons/IconUpload'
import Button from 'lib/components/Button'
import IconDelete from 'app/common/icons/IconDelete'
// import RewardService from 'app/services/RewardService'
import LoadingPanel from 'lib/components/LoadingPanel'
import {encodeDecodeImageUrl} from 'app/pages/Rewards/AddNewRewrds/utils'

interface ImagePickerProps {
  uploadImage: (file: File, id: any) => void
  onDeleteImage: (id: any) => void
  uploadingFor?: string
  placeholder?: string
  label?: string
  id: string
  dropzoneOptions?: DropzoneOptions
  value?: File | string
}

type ImagePreviewProps = {
  uploadingFor?: string
  label?: string
  id: string
  imageUrl: string
  handleRemoveImage?: () => void
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({label, uploadingFor, id, imageUrl, handleRemoveImage}) => {
  const isUploading = uploadingFor ? true : false
  return (
    <ImagePickerRoot style={{opacity: isUploading ? 0.5 : 1}}>
      {label && <FormLabel id={id} label={label} />}
      <ImagePickerContainer className="preview">
        <ImagePickerPreview data-test="testimage" style={{backgroundImage: `url(${encodeDecodeImageUrl(imageUrl)})`}} />
        {handleRemoveImage && (
          <Button disabled={isUploading} className="btn-delete" size="small" onClick={handleRemoveImage}>
            <IconDelete />
          </Button>
        )}
      </ImagePickerContainer>
    </ImagePickerRoot>
  )
}

const ImagePicker: React.FC<ImagePickerProps> = ({uploadImage, uploadingFor, onDeleteImage, label, value, placeholder, id, dropzoneOptions}) => {
  const [isError, setIsError] = useState(false)
  const [errormessage, setErrormessage] = useState<FileRejection[]>()
  const uploadingForKey = id.split('_')[0]
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setIsError(false)
      if (rejectedFiles.length > 0) {
        setIsError(true)
        setErrormessage(rejectedFiles)
      } else {
        const file = acceptedFiles[0]
        if (file) {
          uploadImage(file, id)
        }
      }
    },
    [uploadImage, id]
  )

  const isDisabled = uploadingFor ? uploadingFor !== uploadingForKey : false

  const {getRootProps, getInputProps} = useDropzone({
    ...dropzoneOptions,
    onDrop,
    disabled: isDisabled,
  })

  const removeImage = () => {
    // onImageSelect('')
    onDeleteImage(id)
    setIsError(false)
  }

  if (value && typeof value === 'string') {
    return <ImagePreview uploadingFor={uploadingFor} id={id} label={label} imageUrl={value} handleRemoveImage={removeImage} />
  }
  if (value && typeof value !== 'string') {
    return <ImagePreview uploadingFor={uploadingFor} id={id} label={label} imageUrl={URL.createObjectURL(value)} handleRemoveImage={removeImage} />
  }

  if (uploadingFor === uploadingForKey) {
    return (
      <ImagePickerRoot>
        {label && <FormLabel id={id} label={label} />}
        <ImagePickerContainer>
          <ImagePickerbody>
            <LoadingPanel />
            <p>uploading...</p>
          </ImagePickerbody>
        </ImagePickerContainer>
      </ImagePickerRoot>
    )
  }

  return (
    <ImagePickerRoot>
      {label && <FormLabel id={id} label={label} />}
      <ImagePickerContainer style={{opacity: isDisabled ? 0.5 : 1}} {...getRootProps()}>
        <input {...getInputProps()} />

        <ImagePickerbody>
          <IconUpload />
          <p>{placeholder}</p>
        </ImagePickerbody>
      </ImagePickerContainer>
      {isError && (
        <p style={{color: 'red'}}>
          {errormessage !== undefined &&
            (errormessage[0].file.size > 5242880 ? 'File size exceeds the 5 MB limit.' : errormessage[0].errors[0].message)}
        </p>
      )}
    </ImagePickerRoot>
  )
}

ImagePicker.defaultProps = {
  placeholder: 'Max file size can be 5MB',
  dropzoneOptions: {
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
    },
    maxSize: 5 * 1024 * 1024, // 5MB
    maxFiles: 1,
  },
}

export default ImagePicker
