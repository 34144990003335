import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const search = (
  params: PromotionsTypes.PromotionSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.searchPromotions,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const savePromotion = (
  data: PromotionsTypes.PromotionResponseBL,
  params: PromotionsTypes.PromotionDetails = {}
): Promise<AxiosResponse<ResponseTypes.ResponseData<PromotionsTypes.PromotionResponseBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.promotion,
    data,
    params,
    withCredentials: false,
    method: params.PromotionCode ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

export const deletePromotion = (
  params: PromotionsTypes.PromotionSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<boolean>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.removePromotion,
    params,
    withCredentials: false,
    method: 'DELETE',
  }
  return HttpRequest(requestOptions)
}

const PromotionService = {
  search,
  savePromotion,
  deletePromotion
}

export default PromotionService
