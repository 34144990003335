import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const getRewardCategories = (
  params?: RewardCategoryTypes.RewardCatgSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<RewardCategoryTypes.RewardCategoryResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getVoucherCategory,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveCategory = (
  data: RewardCategoryTypes.SaveCategoryPayload,
  params: RewardCategoryTypes.RewardCategoryDetails
): Promise<AxiosResponse<ResponseTypes.PagedResponse<RewardCategoryTypes.RewardCategoryResponseBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getVoucherCategory,
    data,
    params,
    withCredentials: false,
    method: params.categoryCode ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

const RewardCategoryService = {
  getRewardCategories,
  saveCategory
}

export default RewardCategoryService
