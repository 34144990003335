import {Col, Modal} from 'antd'
import Typography from 'lib/Typography'
import Button from 'lib/components/Button'
interface MergeConfirmationModalProps {
  closeModal(): void
  handleMergeAccount(): void
}
const MergeConfirmationModal: React.FC<MergeConfirmationModalProps> = ({closeModal, handleMergeAccount}) => {
  const renderModalFooter = () => {
    return (
      <Col style={{textAlign: 'center'}}>
        <Button key="back" onClick={closeModal}>
          Cancel merge
        </Button>
        <Button type="primary" key="submit" onClick={handleMergeAccount}>
          Merge Accounts
        </Button>
      </Col>
    )
  }
  return (
    <Modal open onOk={closeModal} onCancel={closeModal} footer={renderModalFooter()}>
      <Typography marginBottom={20} variant="h2" textAlign="center" component="p">
        Only main account will stay, are you sure you want to merge accounts?
      </Typography>
      <Typography marginBottom={60} variant="body1" textAlign="center" component="p">
        This action cannot be reversed
      </Typography>
    </Modal>
  )
}

export default MergeConfirmationModal
