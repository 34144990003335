import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import AppConfig from '../app/common/helpers/AppConfig'

dayjs.extend(utc)

export const utcDate = (date: string, format?: string) => {
  return dayjs(date).utc().format(format)
}

export const utcToLocalDate = (date: string, format = AppConfig.DATE_TIME_FORMAT) => {
  return dayjs(date).format(format)
}

export const utcWithTimeZone = (date: string) => {
  return dayjs.utc(date).format()
}

export default function convertDateToString(date?: Date) {
  if (!date) {
    return ''
  }

  return new Date(date).toLocaleDateString('en-US')
}
