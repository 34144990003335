import {TypographyTypes} from './Typography'
import {StyledTitle} from './Typography.styled'

const Title: React.FC<TypographyTypes.Title> = (props) => {
  return (
    <StyledTitle $color={props.color} $uppercase={props.uppercase} ellipsis={props.ellipsis} level={props.level}>
      {props.children}
    </StyledTitle>
  )
}

Title.defaultProps = {
  color: 'default',
}

export default Title
