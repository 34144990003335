import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import {Tooltip} from 'antd'
import Button from 'lib/components/Button'
import IconEdit from 'app/common/icons/IconEdit'
import IconEye from 'app/common/icons/IconEye'
import {useNavigate} from 'react-router-dom'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {USER_DETAIL, USER_EDIT} from 'app/common/helpers/UserFunctions'
import Colors from 'styles/Colors'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'User Name',
    id: 'userName',
    textAlign: 'left',
    width: '35%',
  },
  {
    title: 'User Id',
    id: 'userId',
    textAlign: 'left',
    width: '25%',
  },
  {
    title: 'Status',
    id: 'status',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Actions',
    id: 'actions',
    textAlign: 'center',
    width: '25%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  usersResponse: UserManagementTypes.UserListResponseType[]
  // rewardsResponse: ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>
}

const SearchResults: React.FC<SearchResultProps> = ({isFetching, usersResponse}) => {
  const navigate = useNavigate()
  const {roles} = useAuth()
  const showUserEdit = roles.includes(USER_EDIT)
  const showUserView = roles.includes(USER_DETAIL)
  if (!showUserEdit && !showUserView) {
    dataTableColumns = dataTableColumns.map((item) => {
      if (item.title === 'actions') {
        return {...item, title: ''}
      }
      return item
    })
  }

  const handleEdit = (rowIndex: number) => {
    const userName = usersResponse[rowIndex].userName
    const encodedUserName = btoa(userName)
    navigate(`/user-management/user/${encodedUserName}`)
  }

  const handleView = (rowIndex: number) => {
    const userName = usersResponse[rowIndex].userName
    const encodedUserName = btoa(userName)
    navigate(`/user-management/user/view/${encodedUserName}`)
  }

  const renderRowActions = (rowIndex: number) => {
    return (
      <>
        {showUserEdit && (
          <Tooltip title="Edit">
            <Button shape="circle" size="small" ghost icon={<IconEdit size={16} />} onClick={() => handleEdit(rowIndex)} />
          </Tooltip>
        )}
        {showUserView && (
          <Tooltip title="View">
            <Button shape="circle" size="small" ghost icon={<IconEye color={Colors.PRIMARY} size={16} />} onClick={() => handleView(rowIndex)} />
          </Tooltip>
        )}
      </>
    )
  }

  const getName = (item: UserManagementTypes.UserListResponseType) => {
    return item.firstName + ' ' + (item.middleName ? item.middleName + ' ' : '') + (item.lastName ? item.lastName : '')
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (usersResponse.length) {
      const dataTableRows: DataTableTypes.Row[] = []
      usersResponse.map((item, index) => {
        const obj: DataTableTypes.Row = {
          userName: getName(item),
          userId: item.userName ?? '-',
          status: item.status,
          actions: renderRowActions(index),
        }
        dataTableRows.push(obj)
        return null
      })
      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
    </>
  )
}

export default SearchResults
