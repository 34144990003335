import {
  ReviewDetailsItem,
  RewardImageBox,
  RewardImageList,
  RewardImageListContainer,
  StyledReviewLabel,
  StyledReviewValue,
} from 'app/pages/Rewards/Reward.Styled'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import Typography from 'lib/components/Typography'
import {getTagRequestObj} from './utils'
import {ReviewRow} from 'app/pages/Segmentation/Segment.styled'
import Button from 'lib/components/Button'
import {FormTypes} from 'lib/components/Form/Form'
import {encodeDecodeImageUrl} from 'app/pages/Rewards/AddNewRewrds/utils'

interface ReviewTagProps {
  isViewOnly?: boolean
  showEditTag: boolean
  onEdit(): void
  partnerCodeOptions: FormTypes.Options[]
}

const ReviewTag: React.FC<ReviewTagProps> = ({isViewOnly, showEditTag, onEdit, partnerCodeOptions}): JSX.Element => {
  const tagReqObj = getTagRequestObj
  const {voucherTagName, voucherTagCode, partnerCode, voucherTagImage} = tagReqObj
  // const PointTypeCode = pointTypeOptions.find((item) => (item.value = pointTypeCode))?.label || ''
  const partnerCodes = partnerCodeOptions.find((item) => item.value == partnerCode)?.label ?? ''
  return (
    <ReviewRow>
      <div>
        <MarginBottomWrapper>
          <Typography.Text color="primary" strong uppercase>
            Tag Information
          </Typography.Text>
        </MarginBottomWrapper>
        {voucherTagCode && (
          <ReviewDetailsItem>
            <StyledReviewLabel>Tag ID</StyledReviewLabel>
            <StyledReviewValue>{voucherTagCode}</StyledReviewValue>
          </ReviewDetailsItem>
        )}
        <ReviewDetailsItem>
          <StyledReviewLabel>Partner Code</StyledReviewLabel>
          <StyledReviewValue>{partnerCodes}</StyledReviewValue>
        </ReviewDetailsItem>
        <ReviewDetailsItem>
          <StyledReviewLabel>Tag Name</StyledReviewLabel>
          <StyledReviewValue>{voucherTagName}</StyledReviewValue>
        </ReviewDetailsItem>
        <RewardImageListContainer>
          {voucherTagImage && (
            <RewardImageList>
              <StyledReviewLabel>Tag Image</StyledReviewLabel>
              <RewardImageBox style={{backgroundImage: `url(${encodeDecodeImageUrl(voucherTagImage)})`}} />
            </RewardImageList>
          )}
        </RewardImageListContainer>
      </div>
      {!isViewOnly && showEditTag && (
        <div>
          <Button onClick={() => onEdit()} size="small" type="link" disabled={false}>
            Edit
          </Button>
        </div>
      )}
    </ReviewRow>
  )
}

export default ReviewTag
