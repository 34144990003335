import LoginService from 'app/services/LoginService'
import UserManagementService from 'app/services/UserManagementService'
import {createContext, useContext, useEffect, useReducer} from 'react'
import LoadingPanel from 'lib/components/LoadingPanel'
import AuthReducer, { AuthTypes } from './AuthReducer'
import Appconfig from 'app/common/helpers/AppConfig'
import { getFormattedUserRoles, parseUserDetailsFromJwt } from 'utils/common'

export type AuthStateType = {
  isLoggedIn: boolean
  isInitialized: boolean
  roles: string[]
  user: UserManagementTypes.UserInfoType | null
  loginToken: string
}

const initialState: AuthStateType = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  loginToken: '',
  roles: []
}

const setSession = (authToken?: string | null) => {
  if (authToken) {
    LoginService.setSessionToken(authToken)
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    LoginService.removeSessionToken()
    // delete axios.defaults.headers.common.Authorization
  }
}

const AuthContext = createContext({
  ...initialState,
  login: (payload: AuthServiceTypes.Request) => Promise.resolve(),
  logout: () => {},
  getUser: (token: string) => {},
})

export const AuthProvider = ({children}: {children: JSX.Element}) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  const logout = () => {
    LoginService.logout()
    setSession(null)
    dispatch({ type: AuthTypes.Logout })
  }

  const getUserDetails = (token: string, rememberMe?: boolean) => {
    Appconfig.AUTH_TOKEN = token
    const userDetail = parseUserDetailsFromJwt(token)
    const userId = userDetail.email
    const savedUserName = localStorage.getItem(Appconfig.SAVED_USERNAME_KEY)
    return UserManagementService.searchUser({userName: userId})
      .then((res) => {
        const user = res.data.data
        if (user && user.length) {
          const userRoles = getFormattedUserRoles(user[0].assignedComponent || [])
          dispatch({
            type: AuthTypes.Init,
            payload: {
              user: user[0],
              roles: userRoles,
              isLoggedIn: true,
              loginToken: token,
            },
          })
          if (savedUserName !== userId && rememberMe) {
            localStorage.setItem(Appconfig.SAVED_USERNAME_KEY, rememberMe ? userId : '')
          }
        }
      }).catch(error => {
        console.error(error)
        LoginService.removeSessionToken()
      })
  }

  const proceedToLogin = (token: string, rememberMe?: boolean): Promise<void> => {
    setSession(token)
    // dispatch({
    //   type: AuthTypes.Login,
    //   payload: {
    //     loginToken: token,
    //   },
    // })
    return getUserDetails(token, rememberMe)
  }

  const login = async (payload: AuthServiceTypes.Request): Promise<void> => {
    const { username, password, rememberMe } = payload
    const updatedPayload = {username, password}
    const response = await LoginService.login(updatedPayload)
    if (response) {
      const data = response.data
      const token = data.access_token
      LoginService.setSessionToken(token)
      localStorage.setItem(Appconfig.SAVED_PASSWORD_KEY, rememberMe ? password : '')
      return proceedToLogin(token, rememberMe)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const authToken = LoginService.getSessionToken()

        if (authToken) {
          setSession(authToken)
          getUserDetails(authToken)
        } else {
          dispatch({
            type: AuthTypes.Init,
            payload: {
              user: null,
              roles: [],
              isLoggedIn: false,
              loginToken: '',
            },
          })
        }
      } catch (err) {
        console.error(err)
        logout()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!state.isInitialized || (state.loginToken && !state.user)) {
    return (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LoadingPanel />
      </div>
    )
  }

  return <AuthContext.Provider value={{...state, logout, login, getUser: getUserDetails,}}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
