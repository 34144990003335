import Colors from 'styles/Colors'

const IconLeftFilledArrow = ({size = 22, color = Colors.PRIMARY_DARK}: IconType.Props) => {
  return (
    <svg width={size} height="13px" style={{ rotate: '270deg' }}>
      <g transform="matrix(1 0 0 1 5 -24 )">
        <path d="M -5 37  L 6 24  L 17 37  L -5 37  Z " fillRule="nonzero" fill={color} stroke="none"/>
      </g>
    </svg>
  )
}

export default IconLeftFilledArrow

