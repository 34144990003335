import {Col} from 'antd'
import Form from 'lib/components/Form'
import {forwardRef, useImperativeHandle, useState} from 'react'
import {StyledRow} from '../../UserManagementStyled'
import {UserResponseList, getUsersRequestObj, updateUserRequestObj} from '../utils'
import {FormTypes} from 'lib/components/Form/Form'
import {emailRegex, validatePhone} from 'utils/common'
import {toast} from 'react-hot-toast'
import PhoneInputField from 'lib/components/PhoneInput'
import { CheckboxContainer } from 'app/pages/Promotions/CreatePromotion/Steps/Steps.styled'
import { StyledLabel } from 'lib/components/Form/FormStyled'
import { useAuth } from 'app/pages/Auth/AuthContext'
import { REWARD_ACTIVATE, REWARD_APPROVAL, REWARD_CREATE, REWARD_EDIT } from 'app/common/helpers/UserFunctions'

interface UserInfoInitialStateType {
  //userName: string
  // password: string
  firstName: string
  lastName: string
  middleName: string
  email: string
  jobTitle: string
  phoneNumber: string
  roles: string[]
  manager: string, isInventoryNotification: boolean
}

interface UserErrorType {
  firstName: boolean
  lastName: boolean
  userName: boolean
  email: boolean
  // password: boolean
  jobTitle: boolean
  phoneNumber: boolean
  roles: boolean
  manager: boolean
}

export interface UserRefType {
  validate(): boolean
}

interface NewUserFormProps {
  managers: FormTypes.Options[]
  roles: FormTypes.Options[]
  userId: string | undefined
}

const NewUserForm = forwardRef<UserRefType, NewUserFormProps>(({managers, roles, userId}, ref) => {

  const userRoles = useAuth().roles;
  let isInventoryNotificationDisable = !(userRoles.includes(REWARD_EDIT) || userRoles.includes(REWARD_CREATE) || userRoles.includes(REWARD_ACTIVATE) || userRoles.includes(REWARD_APPROVAL))
  let userRequestObj = getUsersRequestObj
  const getManagerCode = (data: UserManagementTypes.UserInfoType[]): string => {
    if (!data.length) return ''
    return data[0].userName
  }
  const getStringArrForSelect = (roles: UserManagementTypes.UserRoleType[]) => {
    return roles.map((item) => item.roleCode)
  }
  const initialState: UserInfoInitialStateType = {
    //userName: userRequestObj.userName,
    // password: userRequestObj.password,
    firstName: userRequestObj.firstName,
    lastName: userRequestObj.lastName,
    middleName: userRequestObj.middleName,
    email: userRequestObj.email,
    jobTitle: userRequestObj.jobTitle,
    phoneNumber: userRequestObj.phoneNumber,
    roles: getStringArrForSelect(userRequestObj.roles),
    manager: getManagerCode(userRequestObj.manager),
    isInventoryNotification: userRequestObj.isInventoryNotification
  }
  const initialErrorState: UserErrorType = {
    firstName: false,
    lastName: false,
    userName: false,
    email: false,
    //password: false,
    jobTitle: false,
    phoneNumber: false,
    roles: false,
    manager: false,
  }

  const [state, setInitialState] = useState(initialState)
  const [error, setErrorState] = useState<UserErrorType>(initialErrorState)

  const handleValidate = (): boolean => {
    if (!state.firstName) {
      toast.error('Please enter First Name.')
      setErrorState({...error, firstName: true})
      return false
    }
    if (!state.lastName) {
      toast.error('Please enter Last Name.')
      setErrorState({...error, lastName: true})
      return false
    }
    if (!state.phoneNumber || !validatePhone(state.phoneNumber)) {
      toast.error('Please enter a valid Phone Number.')
      setErrorState({...error, phoneNumber: true})
      return false
    }

    if (!emailRegex.test(state.email)) {
      toast.error('Please enter valid email.')
      setErrorState({...error, email: true})
      return false
    }

    if (!state.jobTitle) {
      toast.error('Please enter Job Title.')
      setErrorState({...error, jobTitle: true})
      return false
    }
    if (!state.roles || !state.roles.length) {
      toast.error('Please select role(s).')
      setErrorState({...error, roles: true})
      return false
    }
    if (!state.manager.length) {
      toast.error('Please select a manager.')
      setErrorState({...error, manager: true})
      return false
    }
    return true
  }

  const handleChange = (value: FormTypes.ValueType, key: string) => {
    let prevState = {...state}
    let updatedState = {
      ...prevState,
      [key]: value,
    }
    setErrorState({
      ...error,
      [key]: false,
    })
    userRequestObj = {
      ...userRequestObj,
      [key]: value,
    }
    if (key === 'roles') {
      const selectedVals = value as string[]
      if (!selectedVals.length) {
        userRequestObj = {...userRequestObj, roles: []}
      } else {
        const roleValues: UserManagementTypes.UserRoleType[] = roles
          .filter((item) => selectedVals.includes(item.value as string))
          .map((role) => ({
            roleName: role.label as string,
            roleCode: role.value as string,
          }))
        userRequestObj = {
          ...userRequestObj,
          [key]: roleValues,
        }
      }
    }
    if (key === 'manager') {
      const managerData = UserResponseList.find((item) => item.userName === value)
      if (managerData) {
        userRequestObj = {...userRequestObj, [key]: [managerData]}
      }
    }
    setInitialState(updatedState)
    updateUserRequestObj(userRequestObj)
  }

  useImperativeHandle(ref, () => ({validate: handleValidate}))

  // const isPasswordDisabeld = userId !== 'new'
  const noDateCheckboxOptions: FormTypes.Options[] = [
    {
      label: 'Send notification updates for Reward’s inventory quantities',
      value: 'isChecked',

    },
  ]
  const onNoDataClick = (value: string[], key: string) => {


    let prevState = { ...state }
    let updatedState = {
      ...prevState,
      [key]: value.includes("isChecked"),
    }
    userRequestObj = {
      ...userRequestObj,
      [key]: value.includes("isChecked"),
    }

    setInitialState(updatedState)
    updateUserRequestObj(userRequestObj)
   
  }
 
  return (
    <>
      <StyledRow>
        <Col span={8}>
          <Form.Input
            id="firstName"
            label="First Name"
            placeholder="First Name"
            value={state.firstName}
            status={error.firstName ? 'error' : undefined}
            onChange={handleChange}
            maxLength={250}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span={8}>
          <Form.Input
            id="middleName"
            label="Middle Name"
            placeholder="Middle Name"
            value={state.middleName}
            onChange={handleChange}
            maxLength={250}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span={8}>
          <Form.Input
            id="lastName"
            label="Last Name"
            placeholder="Last Name"
            value={state.lastName}
            onChange={handleChange}
            status={error.lastName ? 'error' : undefined}
            maxLength={250}
          />
        </Col>
      </StyledRow>
      {/* <StyledRow>
        <Col span={8}>
          <Form.Input
            id="userName"
            label="User Name"
            placeholder="User Name"
            value={state.userName}
            onChange={handleChange}
            status={error.userName ? 'error' : undefined}
            maxLength={250}
          />
        </Col>
      </StyledRow> */}
      <StyledRow>
        <Col span={12}>
          <Form.Input
            id="email"
            label="Email Address"
            placeholder="Email Address"
            value={state.email}
            status={error.email ? 'error' : undefined}
            onChange={handleChange}
          />
        </Col>
      </StyledRow>
      {/* <StyledRow>
        <Col span={8}>
          <Form.Input
            type="password"
            id="password"
            label="password"
            placeholder="Password"
            value={state.password}
            status={error.password ? 'error' : undefined}
            onChange={handleChange}
            minLength={8}
            maxLength={20}
            disabled={isPasswordDisabeld}
          />
        </Col>
      </StyledRow> */}
      <StyledRow>
        <Col span={12}>
          <Form.Input
            id="jobTitle"
            label="Job Title"
            placeholder="Job Title"
            value={state.jobTitle}
            onChange={handleChange}
            maxLength={250}
            status={error.jobTitle ? 'error' : undefined}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span={12}>
          <PhoneInputField
            id="phoneNumber"
            label="Phone Number"
            placeholder="+1 (415) 555‑0132"
            value={state.phoneNumber}
            defaultCountry="US"
            onChange={handleChange}
            status={error.phoneNumber ? 'error' : undefined}
          />
        </Col>
      </StyledRow>
      <StyledRow gutter={16}>
        <Col span={6}>
          <Form.Select
            mode="multiple"
            id="roles"
            label="Role"
            value={state.roles}
            status={error.roles ? 'error' : undefined}
            placeholder="Select Role"
            options={roles}
            onChange={handleChange}
          />
        </Col>
        <Col span={6}>
          <Form.Select
            id="manager"
            label="Manager"
            placeholder="Select a Manager"
            value={state.manager}
            options={managers.filter((item) => item.value !== userId)}
            onChange={handleChange}
            status={error.manager ? 'error' : undefined}
          />
        </Col>
      </StyledRow>

      <StyledRow>

        <Col>
          <StyledLabel>
            Select Notification Updates
          </StyledLabel>
          <CheckboxContainer>
            <Form.Checkbox id="isInventoryNotification" options={noDateCheckboxOptions} value={[state.isInventoryNotification ? 'isChecked' : '']} onChange={onNoDataClick} disabled={isInventoryNotificationDisable} />
          </CheckboxContainer>

        </Col>
      </StyledRow>
    </>
  )
})

export default NewUserForm
