//import Typography from 'lib/components/Typography'
//import {StyledModalContent, MarginBottomWrapper, ModalTitle, Seperator, ExtraPaddedButton} from 'app/common/components/Styled/common.styled'
import IconChevronRight from 'app/common/icons/IconChevronRight'
//import IconSuccess from 'app/common/icons/IconSuccess'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
//import LoadingPanel from 'lib/components/LoadingPanel'
import Popover from 'lib/components/Popover'
//import ToggleButton from 'lib/components/ToggleButton'
//import {ToggleOptionType} from 'lib/components/ToggleButton/ToggleButton'
//import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
//import {Modal} from 'antd'
import {useAuth} from 'app/pages/Auth/AuthContext'
import { POINT_DETAIL, POINT_ACTION_DETAIL, POINT_EDIT } from 'app/common/helpers/UserFunctions'
// import {POINT_DETAIL, POINT_ACTION_DETAIL, POINT_ACTIVATE} from 'app/common/helpers/UserFunctions'
//import PointService from 'app/services/PointService'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Name',
    id: 'pointName',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Point Type',
    id: 'pointTypeCode',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'ID',
    id: 'pointCode',
    textAlign: 'center',
    width: '20%',
  },
  {
    title: 'Description',
    id: 'description',
    textAlign: 'left',
    width: '40%',
  },
  // {
  //   title: 'ON/OFF',
  //   id: 'enabled',
  //   textAlign: 'left',
  //   width: '15%',
  // },
  {
    title: '',
    id: 'actions',
    textAlign: 'center',
    width: '5%',
  },
]

// const activateOptions: ToggleOptionType[] = [
//   {
//     label: 'On',
//     value: true,
//   },
//   {
//     label: 'Off',
//     value: false,
//   },
// ]

interface SearchResultProps {
  isFetching: boolean
  pointsResponse: ResponseTypes.PagedResponse<PointType.PointMasterResponseBL[]>
  handleRefresh: (value: boolean, pointCode: string) => void
}

const SearchResults = ({isFetching, pointsResponse, handleRefresh}: SearchResultProps) => {
  const navigate = useNavigate()
  // const [open, setOpen] = useState<boolean>(false)
  // const [selPoint, setSelPoint] = useState<PointType.PointMasterResponseBL | null>(null)
  // const [processing, setProcessing] = useState<boolean>(false)
  // const [completed, setCompleted] = useState<boolean>(false)
  const {roles} = useAuth()
  console.log(roles)
  const showPointDetail = roles.includes(POINT_DETAIL) || roles.includes(POINT_ACTION_DETAIL)
  const showEditPoint =  roles.includes(POINT_EDIT)

  dataTableColumns = dataTableColumns.map((item) => {
    if (item.id === 'enabled' && !showEditPoint) {
      return {...item, title: ''}
    }
    return item
  })

  const handleView = (rowIndex: number) => {
    const pointCode = pointsResponse.data[rowIndex].pointCode
    const encodedPointCode = btoa(pointCode)
    navigate(`/program/points/view/${encodedPointCode}`)
  }

  // const modalOpen = (data: PointType.PointMasterResponseBL) => {
  //   setOpen(true)
  //   setSelPoint(data)
  // }
  // console.log(selPoint)

  // const handleActivate = () => {
  //   if (selPoint) {
  //     setProcessing(true)
  //     const point: PointType.PointMasterResponseBL = {
  //       ...selPoint,
  //       enabled: !selPoint.enabled,
  //     }

  //     PointService.savePoint(point, {
  //       pointCode: selPoint.pointCode,
  //     })
  //       .then((res) => {
  //         setCompleted(true)
  //         handleRefresh(point.enabled, selPoint.pointCode)
  //       })
  //       .catch(() => {
  //         setOpen(false)
  //       })
  //       .finally(() => {
  //         setProcessing(false)
  //       })
  //   }
  // }

  // const renderEnabledToggle = (rowIndex: number) => {
  //   if (showEditPoint) {
  //     return (
  //       <>
  //         <span>
  //           <ToggleButton
  //             id="activate"
  //             value={pointsResponse.data[rowIndex].enabled}
  //             values={activateOptions}
  //             handleChange={() => modalOpen(pointsResponse.data[rowIndex])}
  //           />
  //         </span>
  //       </>
  //     )
  //   }
  //   return null
  // }

  const renderActionList = (rowIndex: number, item: PointType.PointMasterBasicInfo) => {
    return (
      <>
        <div>
          <Button type="text" onClick={() => handleView(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
            View Details
          </Button>
        </div>
      </>
    )
  }

  // const renderModalContent = () => {
  //   if (processing) {
  //     return (
  //       <StyledModalContent align="center">
  //         <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
  //         <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
  //       </StyledModalContent>
  //     )
  //   }
  //   if (completed) {
  //     return (
  //       <StyledModalContent align="center">
  //         <ModalTitle>
  //           <IconSuccess />
  //           &nbsp;
  //           <Typography.Title level={2} uppercase>
  //             {selPoint?.enabled ? 'POINT IS NOW INACTIVE' : 'POINT IS NOW ACTIVE'}
  //           </Typography.Title>
  //         </ModalTitle>
  //         <MarginBottomWrapper>
  //           <Seperator />
  //         </MarginBottomWrapper>
  //         <MarginBottomWrapper width="80%">
  //           <Typography.Paragraph size="medium">
  //             <strong>{selPoint?.pointName}</strong> {selPoint?.enabled ? ' cannot' : ' can now'} be used.
  //           </Typography.Paragraph>
  //         </MarginBottomWrapper>
  //         <MarginBottomWrapper>
  //           <Button type="primary" onClick={handleCancelModal}>
  //             Close
  //           </Button>
  //         </MarginBottomWrapper>
  //       </StyledModalContent>
  //     )
  //   }
  //   return (
  //     <StyledModalContent align="center">
  //       <ModalTitle>
  //         <Typography.Title level={1} uppercase>
  //           Are You Sure?
  //         </Typography.Title>
  //       </ModalTitle>
  //       <MarginBottomWrapper>
  //         <Seperator />
  //       </MarginBottomWrapper>
  //       <MarginBottomWrapper>
  //         <Typography.Paragraph size="medium">
  //           Are you sure you want to {selPoint?.enabled ? 'turn off ' : 'turn on '}
  //           <strong>{selPoint?.pointName}</strong>?
  //         </Typography.Paragraph>
  //       </MarginBottomWrapper>
  //       <MarginBottomWrapper>
  //         <ExtraPaddedButton type="primary" key="submit" onClick={handleActivate}>
  //           {selPoint?.enabled ? 'Turn off' : 'Turn on'}
  //         </ExtraPaddedButton>
  //       </MarginBottomWrapper>
  //       <MarginBottomWrapper>
  //         <Button type="link" onClick={handleCancelModal}>
  //           Back
  //         </Button>
  //       </MarginBottomWrapper>
  //     </StyledModalContent>
  //   )
  // }

  // const handleCancelConfirm = () => {
  //   navigate(-1)
  // }

  // const handleOk = () => {
  //   setOpen(false)
  //   handleCancelConfirm()
  // }

  // const handleCancelModal = () => {
  //   if (completed) {
  //     setOpen(false)
  //     setCompleted(false)
  //   }
  //   setSelPoint(null)
  //   setOpen(false)
  // }

  const renderRowActions = (rowIndex: number, item: PointType.PointMasterBasicInfo) => {
    if (showPointDetail) {
      return <Popover btnContent={<IconThreeDots />} content={renderActionList(rowIndex, item)} />
    }
    return null
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (pointsResponse && pointsResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = pointsResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          pointName: item.pointName,
          pointTypeCode: item.pointTypeCode,
          pointCode: item.pointCode,
          description: item.pointDescription,
          //enabled: renderEnabledToggle(index),
          actions: renderRowActions(index, item),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
      {/* {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {renderModalContent()}
        </Modal>
      )} */}
    </>
  )
}

export default SearchResults
