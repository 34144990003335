import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import Form from 'lib/components/Form'
import {FormTypes} from 'lib/components/Form/Form'
import Message from 'lib/components/Message'
import Typography from 'lib/components/Typography'
import {getPromotionRequestObj, memberCountData, updateTierDataForReview} from '../../utils'
import {numberWithCommas} from 'utils/common'

import {StepTitle, TierDataWrapper, SetpsBody, WhoBodyMessage, WhoRadioContainer, TierText, SegmentSelectWrapper} from '../Steps.styled'
import MembershipService from 'app/services/MemberService'
import {IconStarWithCircle} from 'app/common/icons/IconStarWithCircle'
import LoadingPanel from 'lib/components/LoadingPanel'
import SegmentationService from 'app/services/SegmentationService'

const radioOptions: FormTypes.Options[] = [
  {
    label: 'All',
    value: 'isAllMembers',
  },
  {
    label: 'Tiers',
    value: 'tiers',
  },
  {
    label: 'Specific Segments',
    value: 'segments',
  },
  {
    label: 'Opted In Members',
    value: 'optIn',
  },
]

interface InitialStateType {
  rewardType: string | number
  selectedTier: FormTypes.Options[]
  promotionSegment: PromotionsTypes.SegmentInfo | null
}

export interface WhoRefType {
  getSavedData(): PromotionsTypes.WhoRuleData
  setData(data: InitialStateType): void
}

export interface WhoPropTypes {
  partnerCode: string
}

const Who = forwardRef<WhoRefType, WhoPropTypes>((props, forwardedRef) => {
  const {promotionRuleData} = getPromotionRequestObj
  const {whoData} = promotionRuleData

  const latestMemberCountData = memberCountData
  const initialState: InitialStateType = {
    rewardType:
      whoData && whoData.isAllMembers
        ? radioOptions[0].value
        : whoData.tierData && whoData.tierData.length > 0
        ? radioOptions[1].value
        : whoData.promotionSegment
        ? radioOptions[2].value
        : radioOptions[3].value,
    selectedTier: [],
    promotionSegment: whoData.promotionSegment,
  }
  if (initialState.rewardType === 'optIn') {
    getPromotionRequestObj.promotionRuleData.whoData.memberOptIns = true
  }

  const [state, setState] = useState(initialState)

  const [fetchTier, setFetchTier] = useState(false)
  const [fetchSeg, setFetchSeg] = useState(false)
  const [tierData, setTierData] = useState<PromotionsTypes.TierDataType[]>([])
  const [segmentData, setSegmentData] = useState<SegmentationTypes.SegmentResponseBL[]>([])
  const [numberOfAllMembers, setNumberOfAllMembers] = useState<number>(0)
  // const [isFetching, setIsFetching] = useState(true)

  const getSavedData = (): PromotionsTypes.WhoRuleData => {
    const tierData: PromotionsTypes.TierRuleData[] = state.selectedTier.map((item) => ({
      membershipTierKey: item.value,
      membershipTierName: item.label,
    }))

    return {
      isAllMembers: state.rewardType === 'isAllMembers',
      tierData,
      memberCode: [],
      promotionSegment: null,
      memberOptIns: false,
    }
  }

  useEffect(() => {
    setTierDataToStateAndRequest(tierData)
  }, [tierData])

  const getSegmentData = () => {
    setFetchSeg(true)
    SegmentationService.search({isAll: true, SegmentByEnableDisable: 'ENABLE'})
      .then((res) => {
        const {data} = res.data
        setSegmentData(data)
        if (whoData.promotionSegment != null) {
          const segment = segmentData.filter((seg) => seg.segmentCode.toUpperCase() === state.promotionSegment?.segmentCode)
          if (segment.length > 0) {
            setNumberOfAllMembers(segment[0].memberCount)
          }
        }
      })
      .finally(() => {
        setFetchSeg(false)
      })
  }

  useEffect(() => {
    getSegmentData()
  }, [])

  const setData = (data: InitialStateType) => {
    setState(data)
  }

  useImperativeHandle(forwardedRef, () => ({getSavedData, setData}))

  const handleChange = (value: string | number | FormTypes.Options[] | PromotionsTypes.SegmentInfo, key: keyof InitialStateType) => {
    const prevState = {...state}

    if (key === 'selectedTier') {
      prevState[key] = value as FormTypes.Options[]
      setTierDataToRequest(value as FormTypes.Options[])
      const selectedValues = value as FormTypes.Options[]
      const selectedTiers = tierData.map((item) => {
        if (selectedValues.findIndex((tier) => tier.value === item.tierCode) !== -1) {
          return {...item, isChecked: true}
        }
        return {...item, isChecked: false}
      })
      updateTierDataForReview(false, numberOfAllMembers, selectedTiers)
    } else if (key === 'promotionSegment') {
      prevState[key] = value as PromotionsTypes.SegmentInfo
      // const segments = segmentData.filter((seg) => seg.segmentCode.toUpperCase() === prevState[key]?.segmentCode)
      // let membercount = 0
      // if (segments.length > 0) {
      //   membercount = segments[0].memberCount as number
      // }
      // setNumberOfAllMembers(membercount)
      //updateTierDataForReview(false, membercount, [])
    } else {
      prevState[key] = value as string | number
      prevState.selectedTier = []
      prevState.promotionSegment = null
      getPromotionRequestObj.promotionRuleData.whoData.isAllMembers = value === 'isAllMembers'
      const intitalTierData = tierData.map((item) => ({...item, isChecked: false}))
      if (value === 'isAllMembers') {
        setTierDataToRequest([])
        updateTierDataForReview(true, numberOfAllMembers, intitalTierData)
      } else {
        updateTierDataForReview(false, numberOfAllMembers, intitalTierData)
      }
    }
    setDataToRequest(prevState)
    setState(prevState)
  }
  const getTotalMembers = () => {
    setFetchTier(true)
    MembershipService.memberCount({partnerCode: props.partnerCode})
      .then((res) => {
        const {totalCount, tierCount} = res.data.data
        setTierData(tierCount.map((item) => ({...item, isChecked: false})))
        setNumberOfAllMembers(totalCount)
        updateTierDataForReview(
          true,
          totalCount,
          tierCount.map((item) => ({...item, isChecked: false}))
        )
      })
      .finally(() => {
        setFetchTier(false)
      })
  }
  useEffect(() => {
    if (props.partnerCode && !latestMemberCountData) {
      getTotalMembers()
    } else {
      if (latestMemberCountData) {
        setTierData(latestMemberCountData.tierData)
        setNumberOfAllMembers(latestMemberCountData.totalCount)
      }
    }
  }, [props.partnerCode])

  // useEffect(() => {
  //   if (!tierData && state.rewardType === 'tiers') {
  //     getTotalMembers()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tierData])

  const setTierDataToRequest = (selectedTierOptions: FormTypes.Options[]) => {
    const tierData = selectedTierOptions.map(
      (item): PromotionsTypes.TierRuleData => ({membershipTierKey: item.value, membershipTierName: item.label})
    )
    getPromotionRequestObj.promotionRuleData.whoData.tierData = tierData
  }
  const setDataToRequest = (state: InitialStateType) => {
    if (state.rewardType === 'segments') {
      getPromotionRequestObj.promotionRuleData.whoData = {
        isAllMembers: false,
        tierData: [],
        promotionSegment: state.promotionSegment,
        memberCode: null,
        memberOptIns: false,
      }
    } else if (state.rewardType === 'optIn') {
      getPromotionRequestObj.promotionRuleData.whoData = {
        isAllMembers: false,
        tierData: [],
        promotionSegment: null,
        memberCode: null,
        memberOptIns: true,
      }
    } else {
      getPromotionRequestObj.promotionRuleData.whoData = {
        ...getPromotionRequestObj.promotionRuleData.whoData,
        memberOptIns: false,
        promotionSegment: null,
      }
    }
  }

  const setTierDataToStateAndRequest = (tierData: PromotionsTypes.TierDataType[]) => {
    const allExistingTierDataKey = getPromotionRequestObj.promotionRuleData.whoData.tierData?.map((item) => item.membershipTierKey)
    if (allExistingTierDataKey && allExistingTierDataKey?.length > 0) {
      const existingTierData = tierData.filter((item) => allExistingTierDataKey.includes(item.tierCode))
      if (existingTierData.length > 0) {
        const selectedTierOptions = existingTierData.map((item): FormTypes.Options => ({value: item.tierCode, label: item.tierName}))
        setTierDataToRequest(selectedTierOptions)
        handleChange(selectedTierOptions, 'selectedTier')
      }
    }
  }

  const getTotalCount = () => {
    let total = 0
    if (state.rewardType === 'tiers') {
      const selectedTiers = tierData.filter((item) => whoData.tierData?.findIndex((tier) => tier.membershipTierKey === item.tierCode) !== -1)
      selectedTiers.forEach((tier) => {
        total += tier.tierCount
      })
    } else if (state.rewardType === 'segments') {
      total = 0
      const segments = segmentData.filter((seg) => seg.segmentCode.toUpperCase() === state.promotionSegment?.segmentCode.toUpperCase())

      if (segments.length > 0) {
        total = segments[0].memberCount as number
      }
      updateTierDataForReview(false, latestMemberCountData?.totalCount || 0, latestMemberCountData?.tierData || [])
    } else if (state.rewardType === 'optIn') {
      total = 0
      updateTierDataForReview(false, latestMemberCountData?.totalCount || 0, latestMemberCountData?.tierData || [])
    } else {
      total = numberOfAllMembers
    }
    return numberWithCommas(total)
  }

  const loadingPannel = () => {
    return (
      <div style={{padding: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <LoadingPanel />
      </div>
    )
  }

  const getTierDataOptions = () => {
    if (tierData && tierData.length > 0) {
      const listButtonOptions: FormTypes.Options[] = []

      tierData.map((tier) => {
        const tierPartnerCode = props.partnerCode.toUpperCase()
        const selectedPartnerCode = getPromotionRequestObj.partnerCode.toUpperCase()
        if (tierPartnerCode === selectedPartnerCode) {
          const obj: FormTypes.Options = {
            label: `${tier.tierName} (${numberWithCommas(tier.tierCount)} members)`,
            value: tier.tierCode,
          }
          listButtonOptions.push(obj)
        }
      })
      return listButtonOptions
    }
    return null
  }
  const getSegmentDataOptions = () => {
    if (segmentData && segmentData.length > 0) {
      const listButtonOptions: FormTypes.Options[] = []

      segmentData.map((seg) => {
        const obj: FormTypes.Options = {
          label: seg.segmentName,
          value: seg.segmentCode,
        }
        listButtonOptions.push(obj)
      })

      return listButtonOptions
    }
    return null
  }

  const onTierChange = (option: FormTypes.OptionValueType[], key: string) => {
    const options = getTierDataOptions()

    if (options) {
      const filterData: FormTypes.Options[] = options
        .filter((item) => option.includes(item.value as FormTypes.OptionValueType))
        .map((item) => {
          return {
            ...item,
            label: item.label?.toString().split(' ')[0],
          }
        })

      handleChange(filterData, 'selectedTier')
    }
  }

  const renderTierData = () => {
    const options = getTierDataOptions()

    if (options) {
      return (
        <TierDataWrapper>
          <TierText>TIERS (Select all that apply)</TierText>
          <Form.Checkbox
            isVerticalView
            id="selectedTier"
            onChange={onTierChange}
            value={state.selectedTier.map((item) => item.value)}
            options={options}
          />
        </TierDataWrapper>
      )
    }
    return <div style={{padding: 40}}>No Tier Data found.</div>
  }

  const onSegmentChange = (value: FormTypes.OptionValueType, key: string) => {
    const options = getSegmentDataOptions()
    const filterOptions = options?.filter((item) => item.value === value)
    const segmentName = (filterOptions ? filterOptions[0].label : '') as string
    const data: PromotionsTypes.SegmentInfo = {
      segmentName: segmentName,
      segmentCode: value as string,
    }
    handleChange(data, 'promotionSegment')
  }
  const renderSegmentData = () => {
    const options = getSegmentDataOptions()
    if (options) {
      return (
        <TierDataWrapper>
          <TierText>Segments</TierText>
          <SegmentSelectWrapper>
            <Form.Select
              id="selectedSegment"
              showSearch
              options={options}
              value={state.promotionSegment?.segmentCode}
              onChange={onSegmentChange}
              placeholder="Select a segment"
              filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </SegmentSelectWrapper>
        </TierDataWrapper>
      )
    }
    return <div style={{padding: 40}}>No Segment Data found.</div>
  }

  const renderBody = () => {
    return (
      <>
        {state.rewardType === 'tiers' && renderTierData()}
        {state.rewardType === 'segments' && renderSegmentData()}
        <WhoBodyMessage>
          <Message inline icon={<IconStarWithCircle />}>
            <Message.Text level="body1" color="default">
              A total of <strong>{getTotalCount()}</strong> Members in the program would be captured by this promotion
            </Message.Text>
          </Message>
        </WhoBodyMessage>
      </>
    )
  }

  return (
    <div>
      <StepTitle>
        <Typography.Title level={5} uppercase>
          Select Members
        </Typography.Title>
      </StepTitle>
      <WhoRadioContainer>
        <Form.Radio boxed id="rewardType" value={state.rewardType} onChange={handleChange} options={radioOptions} />
      </WhoRadioContainer>
      <SetpsBody>{fetchTier || fetchSeg ? loadingPannel() : renderBody()}</SetpsBody>
    </div>
  )
})

export default Who
