import {Col, Modal, Row} from 'antd'
import {MarginWrapperProps} from 'app/common/components/Styled/common.styled'
import styled, {css} from 'styled-components'
import Colors from 'styles/Colors'

interface StepsNavigationItemProps {
  active?: boolean
  isCompleted?: boolean
}

export const StepsNavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 64px 96px;
`

export const StepsNavigationItem = styled.div<StepsNavigationItemProps>`
  border: 1px solid ${Colors.GREY7};
  background-color: ${(props) => (props.active ? Colors.GREY8 : Colors.WHITE)};
  height: 60px;
  padding-left: 44px;
  font-size: ${({theme}) => theme.typography.h6.fontSize};
  font-weight: ${({theme}) => theme.typography.h6.fontWeight};
  line-height: ${({theme}) => theme.typography.h6.lineHeight};
  letter-spacing: ${({theme}) => theme.typography.h6.letterSpacing};
  text-transform: uppercase;
  color: ${Colors.BLACK};
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
  z-index: 1;
  & + div {
    border-left: 0;
  }
  ${(props) =>
    props.isCompleted &&
    css`
      border-top: 2px solid ${Colors.GREEN};
    `}
  ${(props) =>
    props.active &&
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 23px);
        bottom: 0;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-bottom-color: ${Colors.GREY11};
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 22px);
        bottom: 0;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: ${Colors.BORDER1};
      }
    `}
`

export const StepsBodyContainer = styled.div`
  border: 1px solid ${Colors.BORDER1};
  background-color: ${Colors.WHITE};
  padding: 34px;
`

export const StepTitle = styled.div`
  margin-bottom: 26px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${Colors.BORDER2};
`
export const WhoRadioContainer = styled.div`
  padding-bottom: 26px;
  border-bottom: 1px solid ${Colors.BORDER2};
`

export const SetpsBody = styled.div``

export const WhoBodyMessage = styled.div`
  display: flex;
`

export const StepFooter = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  .lb-btn {
    min-width: 150px;
    margin-left: 8px;
  }
`

export const TierDataWrapper = styled.div`
  padding-top: 33px;
  padding-bottom: 33px;
  border-bottom: 1px solid ${Colors.BORDER2};
`

export const IconArrowContainer = styled.div`
  padding-top: 28px;
`
export const CheckboxContainer = styled.div`
  padding: 24px 0;
`

export const StyledBorderRow = styled(Row)`
  margin-top: 32px;
  border-top: 1px solid ${Colors.BORDER2};
`

export const StyledCol = styled(Col)`
  padding-right: 0 !important;
`

export const TierText = styled.div`
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  font-weight: ${(props) => props.theme.typography.body1.fontWeight};
  line-height: ${(props) => props.theme.typography.body1.lineHeight};
  margin-bottom: 18px;
`

export const StyledModal = styled(Modal)<MarginWrapperProps>`
  && {
    width: ${(props) => props.width || '520px'} !important;
  }
`
export const SegmentSelectWrapper = styled.div`
width: 50%;
`
