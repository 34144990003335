import IconDelete from 'app/common/icons/IconDelete'

import IconSettings from 'app/common/icons/IconSettings'
import {RoleDetailsHeader, RoleDetailsHeaderInfo} from 'app/pages/UserManagement/UserManagementStyled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import Typography from 'lib/components/Typography'
import {useState} from 'react'
import Colors from 'styles/Colors'

interface UserDetailsProps {
  userList: UserManagementTypes.UserInfoType[]
  isViewOnly: boolean
  handleDelete(type: string, id: string, name: string): void
  showRemoveUsers: boolean
}

const UserDetails: React.FC<UserDetailsProps> = ({userList = [], isViewOnly, handleDelete, showRemoveUsers}) => {
  const [isEditable, setIsEditable] = useState<boolean>(false)

  const handleEditUsers = () => {
    setIsEditable(!isEditable)
  }

  return (
    <>
      <RoleDetailsHeader>
        <RoleDetailsHeaderInfo>
          <IconSettings />
          &nbsp;&nbsp;
          <Typography.Title level={4}>Users who are assigned this Role</Typography.Title>
          {(!isViewOnly || showRemoveUsers) && (
            <Button type="link" onClick={handleEditUsers}>
              {isEditable ? 'Finished Editing' : 'Edit Users'}
            </Button>
          )}
        </RoleDetailsHeaderInfo>
      </RoleDetailsHeader>
      <DataTable.TotalResult noOfResults={userList.length}></DataTable.TotalResult>
      <div style={{display: 'flex', flexFlow: 'wrap', gap: 24}}>
        {userList.map((user) => (
          <div
            style={{
              display: 'flex',
              padding: '16px',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '48%',
              borderRadius: '10px',
              marginBottom: '16px',
              border: `1px solid ${Colors.BORDER1}`,
            }}
          >
            <div>
              <Typography.Text>{`${user.firstName} ${user.middleName ?? ''} ${user.lastName}`}</Typography.Text>
            </div>
            {isEditable && (
              <Button type="text" onClick={() => handleDelete('USER', user.userName, `${user.firstName} ${user.lastName}`)}>
                <IconDelete size={16} />
              </Button>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default UserDetails
