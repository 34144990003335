import {useState, FormEvent, useImperativeHandle, forwardRef} from 'react'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import {productStatusOptions} from '../AddNewProduct/utils'
import {FormTypes} from 'lib/components/Form/Form'
import ProductService from 'app/services/ProductService'

interface ProductFilterProps {
  handleFilter(formState: ProductMasterTypes.ProductMasterFilterState): void
  isFetching: boolean
  categoryOptions: FormTypes.Options[]
}

type KeyTypes = keyof ProductMasterTypes.ProductMasterFilterState

export interface ResetProductFormRefType {
  handleReset(): void
  getFormState(): ProductMasterTypes.ProductMasterFilterState
}

const ProductFilter = forwardRef<ResetProductFormRefType, ProductFilterProps>(({isFetching, handleFilter, categoryOptions = []}, ref) => {
  const initialState: ProductMasterTypes.ProductMasterFilterState = {
    ProductName: '',
    ProductCode: '',
    SkuCode: '',
    enabled: 'all',
    Category: '',
    CategoryValue: '',
  }

  const [state, setInitialState] = useState<ProductMasterTypes.ProductMasterFilterState>(initialState)
  const [catgValuesOptions, setCatgValuesOptions] = useState<FormTypes.Options[]>([])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleReset = () => {
    setInitialState(initialState)
  }

  const getFormState = (): ProductMasterTypes.ProductMasterFilterState => {
    return {
      ProductName: state.ProductName,
      ProductCode: state.ProductCode,
      SkuCode: state.SkuCode,
      enabled: state.enabled,
      Category: state.Category,
      CategoryValue: state.CategoryValue,
    }
  }

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = {...state}
    prevState[key] = value
    if (key === 'Category') {
      ProductService.getProductCategoryValue({CategoryCode: value}).then((res) => {
        const data = res.data.data
        setCatgValuesOptions(
          data.map((item) => ({
            label: item.categoryValue,
            value: item.categoryValue,
          }))
        )
      })
    }
    setInitialState(prevState)
  }

  useImperativeHandle(ref, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.ProductName} name="ProductName" id="ProductName" placeholder="Name" onChange={handleOnChange} isDark />
      <Form.Input value={state.ProductCode} name="ProductCode" id="ProductCode" placeholder="Product ID" onChange={handleOnChange} isDark />
      <Form.Input value={state.SkuCode} name="SkuCode" id="SkuCode" placeholder="Product Sku" onChange={handleOnChange} isDark />
      <Form.Select value={state.enabled} options={productStatusOptions} id="enabled" onChange={handleOnChange} noMargin isDark />
      <Form.Select value={state.Category} options={categoryOptions} id="Category" onChange={handleOnChange} noMargin isDark />
      <Form.Select value={state.CategoryValue} options={catgValuesOptions} id="CategoryValue" onChange={handleOnChange} noMargin isDark />
    </DataTable.Filter>
  )
})

export default ProductFilter
