import {CheckboxOptionType} from 'antd'
import Appconfig from 'app/common/helpers/AppConfig'
// import dayjs from 'dayjs'
import {ToggleOptionType} from 'lib/components/ToggleButton/ToggleButton'
import {cloneDeep} from 'lodash'

export const segmentStatus = {
  INPROGRESS: 'INPROGRESS',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  LIVE: 'LIVE',
  ENDED: 'ENDED',
  REJECTED: 'REJECTED',
}

export const activityRuleDefaultValue = {
  type: 'PURCHASE',
  startDate: null,
  endDate: null,
}

export const initialSegmentRequestObj: SegmentationTypes.SegmentResponseBL = {
  rowStatus: Appconfig.rewards.defaultValus.rowStatus,
  segmentId: 0,
  segmentName: '',
  segmentCode: '',
  segmentDescription: '',
  partnerCode: '',
  segmentStatus: Appconfig.segment.defaultValues.segmentStatus,
  isEnabled: true,
  segmentType: 'DEMOGRAPHIC',
  segmentCategory: 'STATIC',
  segmentRuleData: {
    demographicRule: {
      isAll: true,
      type: 'STATE',
      min: 0,
      max: 0,
      values: [],
    },
    activityRule: null,
  },
  properties: {},
  assignedPromotion:[],
  memberCount:0
}
export const initialSegmentSearchObj: SegmentationTypes.SegmentSearchParam={
  SegmentName:'',
  SegmentCode:''
}

export let getSegmentRequestObj: SegmentationTypes.SegmentResponseBL = cloneDeep(initialSegmentRequestObj)
export let getSegmentSearchObj: SegmentationTypes.SegmentSearchParam = cloneDeep(initialSegmentSearchObj)


export const updateSegmentRequestObj = (data: SegmentationTypes.SegmentResponseBL) => {
  getSegmentRequestObj = cloneDeep(data)
}

export const updateSegmentSearchObj = (data: SegmentationTypes.SegmentSearchParam) => {
  getSegmentSearchObj = cloneDeep(data)
}

export const stateToggleOptions: ToggleOptionType[] = [
  {
    label: 'All States',
    value: true,
  },
  {
    label: 'Specific States',
    value: false,
  },
]

export const genderOptions: CheckboxOptionType[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Prefer Not to Say',
    value: 'prefer not to say',
  },
  {
    label: 'Non Binary',
    value: 'non binary',
  },
  {
    label: 'Transgender',
    value: 'transgender',
  },
]

export const segmentCategoryOptions: CheckboxOptionType[] = [
  {
    label: 'Static',
    value: 'STATIC',
  },
  {
    label: 'Real-Time',
    value: 'REALTIME',
  },
]

export const getSegmentCategoryLabel = (value: string): string => {
  return segmentCategoryOptions.find(item => item.value === value)?.label as string || '';
}

// export const SegmentsDummyData: ResponseTypes.PagedResponse<SegmentationTypes.SegmentSearchResponse[]> = {
//   // hasNextPage: false,
//   // hasPreviousPage: false,
//   message: '',
//   // pageIndex: 1,
//   // pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
//   // totalPages: 0,
//   // count: 32,
//   data: [],
//   pageInfo: {
//     count: 32,
//     hasNextPage: false,
//     hasPreviousPage: false,
//     pageIndex: 1,
//     pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
//     totalPages: 0,
//   }
//   links: {
//     first:"",
//     prev:"",

//   }
// }
