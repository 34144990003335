import {Slider} from 'antd'
import styled from 'styled-components'

export const StyledSlider = styled(Slider)`
  && {
    &:hover {
      .lb-slider-rail {
        background-color: rgba(30, 152, 215, 0.26);
      }
      .lb-slider-track {
        background-color: #0062ff;
      }
      .lb-slider-handle::after {
        box-shadow: 2px 2px 4px 4px #f6f2f2;
      }
    }
  }
  .lb-slider-rail {
    background-color: rgba(30, 152, 215, 0.26);
  }
  .lb-slider-track {
    background-color: #0062ff;
  }
  .lb-slider-horizontal .lb-slider-handle {
    inset-block-start: -7px;
  }
  .lb-slider-handle {
    ::after {
      box-shadow: 2px 2px 4px 4px #f6f2f2;
      width: 25px;
      height: 25px;
      &:hover {
        && {
          box-shadow: 2px 2px 4px 4px #f6f2f2;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .lb-slider-handle:hover::after,
  .lb-slider-handle:active::after,
  .lb-slider-handle:focus::after {
    box-shadow: 2px 2px 4px 4px #f6f2f2;
    width: 25px;
    height: 25px;
    inset-inline-start: 0px;
    inset-block-start: 0px;
  }
  .lb-slider-handle {
    inset-block-start: -7px;
  }
`
