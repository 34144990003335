const IconPlus = ({size = 24, color = 'currentColor'}: IconType.Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" focusable="false" aria-hidden="true" role="img">
      <path d="M12 5V19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 12H19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default IconPlus
