import {RouteObject} from 'react-router'

import Reporting from '../pages/Reporting'
import RealTimeReporting from '../pages/Reporting/RealTimeReporting'

import {ErrorBoundry} from '../common/components/Routes/Fallback'

const ReportingRoutes: RouteObject = {
  path: `reporting`,
  element: <Reporting />,
  errorElement: <ErrorBoundry />,
  children: [
    {index: true, element: <RealTimeReporting />},
    {path: 'real-time', index: true, element: <RealTimeReporting />},
  ],
}

export default ReportingRoutes
