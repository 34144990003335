import {SliderRangeProps} from 'antd/es/slider'
import {StyledSlider} from './Slider.styled'

interface customSliderProps extends SliderRangeProps {
  handleChange: (value: number[], id: string) => void
  value: [number, number]
}

const Slider = (props: customSliderProps) => {
  const onChange = (value: number[]) => {
    props.handleChange(value, props.id || '')
  }

  return <StyledSlider range value={props.value} id={props.id} onChange={onChange} tipFormatter={null} />
}

export default Slider
