import IconAdd from 'app/common/icons/IconAdd'
import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import Typography from 'lib/components/Typography'
import Colors from 'styles/Colors'
import PointFilter, {ResetPointFormRefType} from './PointFilter'
import Appconfig from 'app/common/helpers/AppConfig'
import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {cloneDeep, isEmpty} from 'lodash'
import SearchResults from './SearchResults'
import {useAuth} from 'app/pages/Auth/AuthContext'
// import { POINT_CREATE } from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'
import PointService from 'app/services/PointService'
import { POINT_SEARCH, POINT_VIEW, POINT_CREATE } from 'app/common/helpers/UserFunctions'
//import {pointsResult} from '../AddNewPoint/util'

// import { Modal } from 'antd'

const Points = () => {
  const initialResponse: ResponseTypes.PagedResponse<PointType.PointMasterResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)

  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const initialSearchParms: PointType.PointMasterSearchParam = {}
  const [isFetching, setIsFetching] = useState(true)
  const [isFormReset, setIsFormReset] = useState(true)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [pointsData, setPointsData] = useState<ResponseTypes.PagedResponse<PointType.PointMasterResponseBL[]>>(initialResponse)
  const pointFormRef = useRef<ResetPointFormRefType>(null)
  const navigate = useNavigate()
  const {roles} = useAuth()
  const showSearch = roles.includes(POINT_SEARCH)
  const showTable = roles.includes(POINT_VIEW)
  const showCreatePoint = roles.includes(POINT_CREATE)
  const handleSearch = (params: PointType.PointMasterSearchParam) => {
    const reqParam: PointType.PointMasterSearchParam = {
      // IsCombinable: false,
      ...params,
    }
    setIsFetching(true)
    PointService.getAllPoints(reqParam)
      .then((res) => {
        const data = res.data
        console.log('data', data)
        setPointsData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...paginationObj})
    // updateSegmentRequestObj(initialSegmentRequestObj)
  }, [])

  const handleAddPoint = () => {
    navigate(`/program/points/new`)
  }

  const handleFilter = (data: PointType.PointMasterFilterState) => {
    const filterParms: PointType.PointMasterSearchParam = {}
    filterParms.pointName = data.pointName
    filterParms.pointCode = data.pointCode || undefined
    filterParms.isRedemptionPointType = data.isRedemptionPointType
    filterParms.pointTypeCode = data.pointTypeCode
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const handleResetSearch = () => {
    pointFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const handleRefresh = (value: boolean, pointCode: string) => {
    const updatedPointData = cloneDeep(pointsData)
    const updatedRowIndex = pointsData.data.findIndex((item) => item.pointCode === pointCode)
    updatedPointData.data[updatedRowIndex] = {
      ...updatedPointData.data[updatedRowIndex],
      enabled: value,
    }
    setPointsData(updatedPointData)
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }

    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const {count = 0, pageIndex = 0} = pointsData.pageInfo
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const renderPointBody = () => {
    if ((showSearch && showTable) || showCreatePoint) {
      return (
        <DataTable>
          {showSearch && showTable && <PointFilter ref={pointFormRef} isFetching={isFetching} handleFilter={handleFilter} />}
          <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
          {showTable && (
            <>
              <SearchResults isFetching={isFetching} pointsResponse={pointsData} handleRefresh={handleRefresh} />
              <DataTable.Pagination
                onChange={handleOnChange}
                current={pageIndex}
                pageSizeOptions={pageSizeOptions}
                total={count}
                defaultPageSize={initialPaginationObj.PageCount}
                pageSize={paginationObj.PageCount}
                showSizeChanger
              />
            </>
          )}
        </DataTable>
      )
    }
    return <NoPermission />
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Point Master</Typography.Title>
        {showCreatePoint && (
          <Button onClick={handleAddPoint} type="primary" $startIcon={<IconAdd color={Colors.WHITE} size={20} />}>
            Add New Point
          </Button>
        )}
      </StyledHeader>
      {renderPointBody()}
    </section>
  )
}

export default Points
