import Appconfig from 'app/common/helpers/AppConfig'
import RewardService from 'app/services/RewardService'
import {useState, useEffect} from 'react'
import SearchPriceResult from './SearchPriceResults'
import {getRewardsRequestObj} from '../AddNewRewrds/utils'

interface RewardPriceSearchTableProps {
  showSearch?: boolean
  showTable?: boolean
  pageSize?: number
  pageSizeOptions?: number[]
}

const RewardPriceHistorySearch: React.FC<RewardPriceSearchTableProps> = ({
  pageSize = Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
  pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS,
  showSearch,
  showTable,
}) => {
  const initialMembershipResponse: ResponseTypes.PagedResponse<RewardsTypes.RewardPriceHistoryState[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }

  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: pageSize,
    PageNumber: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }

  let voucherData = getRewardsRequestObj
  const [isFetching, setIsFetching] = useState(true)
  const [priceHistoryResponse, setPriceHistoryResponse] =
    useState<ResponseTypes.PagedResponse<RewardsTypes.RewardPriceHistoryState[]>>(initialMembershipResponse)
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)

  const getPriceHistoryList = (params: RewardsTypes.RewardPriceSearchParam) => {
    params = {
      ...params,
      voucherTypeCode: voucherData.voucherTypeCode,
    }
    RewardService.searchPriceDetail(params)
      .then((res) => {
        const data = res.data
        setPriceHistoryResponse(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPaginationObj({...paginationObj, PageCount: pageSize, PageNumber: page})
  }

  useEffect(() => {
    if (showTable) getPriceHistoryList(paginationObj)
  }, [])

  const handleSearch = (searchData?: MemebershipTypes.MembershipDetail) => {
    setIsFetching(true)

    getPriceHistoryList({
      ...paginationObj,
      ...searchData,
    })
  }

  //   const handleRowClick = (row: RewardsTypes.RewardPriceHistoryState) => {
  //     if (rowClick) {
  //       rowClick(row)
  //       return
  //     }
  //     // const encodedMemberShipId = btoa(row.membershipCustomerId)
  //     // navigate(`/member/${encodedMemberShipId}`)
  //   }

  return (
    <SearchPriceResult
      handleSearch={handleSearch}
      isFetching={isFetching}
      priceHistoryResponse={priceHistoryResponse}
      pageSizeOptions={pageSizeOptions}
      handlePaginationChange={handlePaginationChange}
      paginationObj={paginationObj}
      showSearch={showSearch}
      showTable={showTable}
      getPriceHistoryList={getPriceHistoryList}
    />
  )
}
// MemberSearchTable.defaultProps = {
//   pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
//   pageSizeOptions: Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS,
// }

// MemberSearchTable.propTypes = {
//   pageSize: number,
//   rowClick: func,
// }

export default RewardPriceHistorySearch
