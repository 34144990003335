import { useState, FormEvent, useImperativeHandle, forwardRef } from 'react'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'

interface TierFilterProps {
  handleFilter(formState: SegmentationTypes.SegmentMemberFilter): void
  isFetching: boolean
}

type KeyTypes = keyof SegmentationTypes.SegmentMemberFilter

export interface TierMemberFormRefType {
  handleReset(): void
  getFormState(): SegmentationTypes.SegmentMemberFilter
}

const TierMemberFilter = forwardRef<TierMemberFormRefType, TierFilterProps>(({ isFetching, handleFilter }, ref) => {
  const initialState: SegmentationTypes.SegmentMemberFilter = {
    memberName: '',
    email: '',
    status: ''
  }
  const [state, setInitialState] = useState<SegmentationTypes.SegmentMemberFilter>(initialState)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleReset = () => {
    setInitialState(initialState)
  }

  const getFormState = (): SegmentationTypes.SegmentMemberFilter => {
    return {
      memberName: state.memberName,
      email: state.email,
      status: state.status
    }
  }

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = { ...state }
    prevState[key] = value
    setInitialState(prevState)
  }

  useImperativeHandle(ref, () => ({ handleReset, getFormState }))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.memberName} name="memberName" id="memberName" placeholder="Search by Name" onChange={handleOnChange} isDark />
      <Form.Input value={state.email} name="email" id="email" placeholder="Search by email" onChange={handleOnChange} isDark />
      <Form.Input value={state.status} name="status" id="status" placeholder="Search by status" onChange={handleOnChange} isDark />
    </DataTable.Filter>
  )
})

export default TierMemberFilter
