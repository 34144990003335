import Navigation from 'app/common/components/Navigation'
import {ForgotPasswordContainer, ForgotPasswordContent, FullWidthButton, StyledBurstLoginTitle, StyledPasswordForm} from './Login.styled'
import {MarginBottomWrapper, Seperator} from 'app/common/components/Styled/common.styled'
import Typography from 'lib/components/Typography'
import Form from 'lib/components/Form'
import {FormEvent, useEffect, useState} from 'react'
import {toast} from 'react-hot-toast'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {passwordRegex} from 'utils/common'
import LoginService from 'app/services/LoginService'
import isEmptyOrSpaces from 'utils/EmptyOrBlank'
import Appconfig from 'app/common/helpers/AppConfig'
import UserManagementService from 'app/services/UserManagementService'
import LoadingPanel from 'lib/components/LoadingPanel'

const ResetPassword = () => {
  const [params] = useSearchParams()
  console.log('params', params)
  let token = params.get('token') || ''
  token = token.replace(/ /g, '+')
  let username = params.get('userName') || ''
  username = username.replace(/ /g, '+')

  const initialState = {
    newPassword: '',
    confPassword: '',
  }
  const errorState = {
    newPassword: false,
    confPassword: false,
  }
  const [state, setInitialState] = useState(initialState)
  const [error, setError] = useState(errorState)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [resetTokneStatus, setTokneStatus] = useState<UserManagementTypes.ResetTokenStatus>({
    status: Appconfig.tokenStaus.VALID,
  })
  const [loadingTokenStatus, setLoadingTokenStatus] = useState(false)

  const handleChange = (value: string, id: string) => {
    const prevState = {...state}
    setInitialState({
      ...prevState,
      [id]: value,
    })
  }

  const handleResetPassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (isEmptyOrSpaces(state.newPassword)) {
      setError({
        ...error,
        newPassword: true,
      })
      toast.error(' New password  can not be null or blank.')
    } else if (isEmptyOrSpaces(state.confPassword)) {
      setError({
        ...error,
        confPassword: true,
      })
      toast.error('Confirm Password can not be null or blank.')
    } else if (state.newPassword !== state.confPassword) {
      setError({
        ...error,
        confPassword: true,
      })
      toast.error('The password you entered do not match.')
    } else if (!passwordRegex.test(state.newPassword)) {
      setError({
        ...error,
        newPassword: true,
        confPassword: true,
      })
      toast.error('The password must meet password requirements.')
    } else {
      setLoading(true)
      LoginService.resetPassword({
        username,
        password: state.newPassword,
        token,
        flow: 2,
      })
        .then((res) => {
          setIsSubmitted(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const checkTokenStatus = () => {
    setLoadingTokenStatus(true)
    UserManagementService.validateResetToken({
      userName: username,

      token,
      referralUrl: '',
    })
      .then((res) => {
        const {data} = res.data
        setTokneStatus(data)
      })
      .finally(() => {
        setLoadingTokenStatus(false)
      })
  }
  useEffect(() => {
    if (token && username) {
      checkTokenStatus()
    }
  }, [])
  const handleSignIn = () => {
    navigate(`/login`)
  }

  const handelContinue = () => {
    navigate(`/forgot-pass`)
  }
  const renderBody = () => {
    if (loadingTokenStatus) {
      return <LoadingPanel />
    } else if (resetTokneStatus.status === Appconfig.tokenStaus.VALID) {
      return (
        <>
          <MarginBottomWrapper>
            <Typography.Title level={2}>Reset Password</Typography.Title>
          </MarginBottomWrapper>
          {!isSubmitted ? (
            <StyledPasswordForm onSubmit={handleResetPassword}>
              <MarginBottomWrapper width="80%">
                <Form.Input
                  id="newPassword"
                  label="Create New Password"
                  placeholder="Create New Password"
                  value={state.newPassword}
                  status={error.newPassword ? 'error' : undefined}
                  onChange={handleChange}
                  type="password"
                  autoComplete="off"
                />
              </MarginBottomWrapper>
              <MarginBottomWrapper width="80%">
                <ul>
                  <li>
                    <Typography.Paragraph size="medium">At least 8 character</Typography.Paragraph>
                  </li>
                  <li>
                    <Typography.Paragraph size="medium">At least 1 number</Typography.Paragraph>
                  </li>
                  <li>
                    <Typography.Paragraph size="medium">At least 1 Special character</Typography.Paragraph>
                  </li>
                  <li>
                    <Typography.Paragraph size="medium">At least one uppercase letter</Typography.Paragraph>
                  </li>
                </ul>
              </MarginBottomWrapper>
              <MarginBottomWrapper width="80%">
                <Form.Input
                  id="confPassword"
                  label="Re-enter New Password"
                  type="password"
                  placeholder="Re-enter New Password"
                  value={state.confPassword}
                  status={error.confPassword ? 'error' : undefined}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </MarginBottomWrapper>
              <MarginBottomWrapper width="80%">
                <FullWidthButton type="primary" loading={loading} htmlType="submit">
                  Submit
                </FullWidthButton>
              </MarginBottomWrapper>
            </StyledPasswordForm>
          ) : (
            <>
              <MarginBottomWrapper>
                <Typography.Title level={1}>Password Reset Successfully</Typography.Title>
              </MarginBottomWrapper>
              <FullWidthButton type="primary" onClick={handleSignIn}>
                Sign In
              </FullWidthButton>
            </>
          )}
        </>
      )
    } else if (resetTokneStatus.status === Appconfig.tokenStaus.INVALID) {
      return (
        <>
          <MarginBottomWrapper>
            <Typography.Title level={2}>The reset link is Invalid</Typography.Title>
          </MarginBottomWrapper>

          <MarginBottomWrapper>
            <Typography.Paragraph>Please make a new request to change your password.</Typography.Paragraph>
          </MarginBottomWrapper>
          <FullWidthButton type="primary" onClick={handelContinue}>
            Continue
          </FullWidthButton>
        </>
      )
    } else if (resetTokneStatus.status === Appconfig.tokenStaus.USED || resetTokneStatus.status === Appconfig.tokenStaus.EXPRIED) {
      return (
        <>
          <MarginBottomWrapper>
            <Typography.Title level={2}>
              {resetTokneStatus.status === Appconfig.tokenStaus.EXPRIED
                ? ' The reset link has expired'
                : ' The password has already been reset with this link.'}
            </Typography.Title>
          </MarginBottomWrapper>

          <MarginBottomWrapper>
            <Typography.Paragraph>Please make a new request to change your password.</Typography.Paragraph>
          </MarginBottomWrapper>
          <FullWidthButton type="primary" onClick={handelContinue}>
            Continue
          </FullWidthButton>
        </>
      )
    }

    return
  }

  return (
    <>
      <Navigation isBlankNav />
      <ForgotPasswordContainer>
        <ForgotPasswordContent>
          <MarginBottomWrapper>
            <StyledBurstLoginTitle>Loyalty Burst Login</StyledBurstLoginTitle>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Seperator></Seperator>
          </MarginBottomWrapper>
          {renderBody()}
        </ForgotPasswordContent>
      </ForgotPasswordContainer>
    </>
  )
}

export default ResetPassword
