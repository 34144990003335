import {lighten} from 'polished'
import styled, {css} from 'styled-components'
import Colors from 'styles/Colors'
export interface CellType {
  $width?: string
  $textAlign?: string
  actionOnhover?: boolean
  showElipsis?: boolean
  isClickable?: boolean
}

export const DataTableContainer = styled.div`
  position: relative;
`

export const CellWrapper = styled.div<CellType>`
  padding: 14px 3px;
  flex: ${(props) => (props.$width ? `0 0 ${props.$width}` : 1)};
  max-width: ${(props) => props.$width || '100%'};
  display: flex;
`
export const Cell = styled.div<CellType>`
  text-align: ${(props) => props.$textAlign || 'left'};
  width: 100%;
  button {
    &:hover {
      svg > path {
        stroke: ${Colors.BLACK};
      }
    }
  }
  .lb-typography {
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    letter-spacing: ${(props) => props.theme.typography.body1.letterSpacing};
    font-weight: ${(props) => props.theme.typography.body1.fontWeight};
    line-height: ${(props) => props.theme.typography.body1.lineHeight};
    color: ${Colors.GREY3};
    max-width: 99%;
  }
  ${(props) =>
    props.showElipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`

export const DataRow = styled.div<CellType>`
  display: flex;
  width: 100%;
  padding: 0 9px;
  background-color: ${Colors.WHITE};
  align-items: center;
  .action-cell {
    opacity: ${(props) => (props.actionOnhover ? 0 : 1)};
  }
  &:nth-child(even) {
    background-color: ${Colors.BLUE_LIGHT};
  }
  &:hover {
    background-color: ${lighten(0.99, Colors.GREY4)};
    ${(props) =>
      props.actionOnhover &&
      css`
        .action-cell {
          opacity: 1;
        }
      `}
  }
  ${(props) =>
    props.isClickable &&
    css`
      cursor: pointer;
    `}
`
export const CellTitle = styled.div`
  font-size: ${(props) => props.theme.typography.subtitle.fontSize};
  line-height: ${(props) => props.theme.typography.subtitle.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.subtitle.letterSpacing};
  text-transform: uppercase;
  color: ${Colors.GREY3};
`

export const TableRowWrapper = styled.div`
  position: relative;
`
export const TableCircularProgress = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export const TableHEaderTitle = styled.span`
  display: inline-flex;
  font-weight: ${(props) => props.theme.typography.h6.fontWeight};
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  line-height: ${(props) => props.theme.typography.h6.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.h6.letterSpacing};
  text-transform: uppercase;
  color: ${Colors.WHITE};
`
export const TableHeaderContainer = styled.div`
  background-color: ${Colors.PRIMARY_DARK};
  display: flex;
  padding: 0 9px;
`

export const NoRecordFound = styled.div`
  padding: 100px 30px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.002em;

  color: #000000;
`
