import {Radio} from 'antd'
import styled from 'styled-components'

export const RadioGroupWrapper = styled.div``

export const VerticalRadioWrapper = styled.div`
  width: 40%;
  && {
    div > div {
      display: flex;
      flex-flow: column wrap;
      max-height: 134px;
      .lb-radio-group {
        margin-inline-start: 0;
        margin-bottom: 8px;
      }
      .lb-space-item {
        display: flex;
        justify-content: space-between;
        button {
          /* position: relative; */
          height: 21px;
          padding: 10px 7px;
        }
      }
      .radio-label-wrapper {
        flex-flow: row;
        justify-content: space-between;
      }
    }
  }
`

export const StyledAntRadio = styled(Radio)`
  &.lb-radio-wrapper .lb-radio-inner {
    border-width: 3px;
  }
`
