import Tab from './Tab'
import TabPanel from './TabPanel'
import {TabsContainer, TabsListRoot, TabsRoot} from './Tabs.styled'

type TabsSubComponents = {
  Tab: typeof Tab
  TabPanel: typeof TabPanel
}

export interface TabsProps {
  children?: React.ReactNode
}

type TabsPropsType = TabsProps

const Tabs: React.FunctionComponent<TabsPropsType> & TabsSubComponents = ({children}: TabsProps) => {
  return (
    <TabsRoot>
      <TabsContainer>
        <TabsListRoot role="tablist">{children}</TabsListRoot>
      </TabsContainer>
    </TabsRoot>
  )
}

Tabs.Tab = Tab
Tabs.TabPanel = TabPanel
export default Tabs
