// All types
// =============================================
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export type AuthUser = null | Record<string, any>

export type AuthState = {
  isLoggedIn: boolean
  isInitialized: boolean
  user: UserManagementTypes.UserInfoType | null
  loginToken: string
  roles: string[]
}

export enum AuthTypes {
  Init = 'INIT',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
}

type AuthPayload = {
  [AuthTypes.Init]: {
    isLoggedIn: boolean
    user: UserManagementTypes.UserInfoType | null
    roles: string[]
    loginToken: string
  }
  [AuthTypes.Logout]: undefined
  [AuthTypes.Login]: { loginToken: string }
}

type Actions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>]

const AuthReducer = (state: AuthState, action: Actions) => {
  switch (action.type) {
    case 'INIT': {
      return {
        isInitialized: true,
        user: action.payload.user,
        roles: action.payload.roles,
        isLoggedIn: action.payload.isLoggedIn,
        loginToken: action.payload.loginToken,
      }
    }

    case 'LOGIN': {
      return {
        ...state,
        isLoggedIn: true,
        loginToken: action.payload.loginToken,
      }
    }

    case 'LOGOUT': {
      return {
        ...state,
        user: null,
        roles: [],
        isLoggedIn: false,
        loginToken: '',
      }
    }

    default: {
      return state
    }
  }
}

export default AuthReducer
