const IconBrand = (): JSX.Element => {
  return (
    <svg width="41" height="26" viewBox="0 0 41 26" fill="none">
      <rect x="2.5" y="2.5" width="36" height="21" rx="10.5" stroke="url(#paint0_linear_6_30)" strokeWidth="5" />
      <defs>
        <linearGradient id="paint0_linear_6_30" x1="7" y1="2.5" x2="41" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IconBrand
