import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import {Tooltip} from 'antd'
import Button from 'lib/components/Button'
import IconEdit from 'app/common/icons/IconEdit'
import IconEye from 'app/common/icons/IconEye'
import {useNavigate} from 'react-router-dom'
import { useAuth } from 'app/pages/Auth/AuthContext'
import { ROLE_DETAIL, ROLE_EDIT } from 'app/common/helpers/UserFunctions'
import Colors from 'styles/Colors'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Role Name',
    id: 'roleName',
    textAlign: 'left',
    width: '40%',
  },
  {
    title: 'Role Id',
    id: 'roleCode',
    textAlign: 'left',
    width: '15%',
  },
  // {
  //   title: 'Category',
  //   id: 'category',
  //   textAlign: 'left',
  //   width: '30%',
  // },
  {
    title: 'Actions',
    id: 'actions',
    textAlign: 'center',
    width: '55%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  rolesResponse: UserManagementTypes.RoleType[]
  // rewardsResponse: ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>
}

const SearchResults: React.FC<SearchResultProps> = ({isFetching, rolesResponse}) => {
  const navigate = useNavigate()
  const {roles} = useAuth()
  const showRolesEdit = roles.includes(ROLE_EDIT)
  const showRolesView = roles.includes(ROLE_DETAIL)
  if (!showRolesEdit && !showRolesView) {
    dataTableColumns = dataTableColumns.map(item => {
      if (item.id === 'actions') {
        return { ...item, title: ''}
      }
      return item
    })
  }

  const handleEdit = (rowIndex: number) => {
    const roleCode = rolesResponse[rowIndex].roleCode
    const encodedRoleCode = btoa(roleCode)
    navigate(`/user-management/role/${encodedRoleCode}`)
  }

  const handleView = (rowIndex: number) => {
    const roleCode = rolesResponse[rowIndex].roleCode
    const encodedRoleCode = btoa(roleCode)
    navigate(`/user-management/role/view/${encodedRoleCode}`)
  }

  const renderRowActions = (rowIndex: number) => {
    return (
      <>
        {showRolesEdit && <Tooltip title="Edit">
          <Button shape="circle" size="small" ghost icon={<IconEdit size={16} />} onClick={() => handleEdit(rowIndex)} />
        </Tooltip>}
        {showRolesView && <Tooltip title="View Detail">
          <Button shape="circle" size="small" ghost icon={<IconEye size={16} color={Colors.PRIMARY} />} onClick={() => handleView(rowIndex)} />
        </Tooltip>}
      </>
    )
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (rolesResponse.length) {
      const dataTableRows: DataTableTypes.Row[] = []
      rolesResponse.map((item, index) => {
        const obj: DataTableTypes.Row = {
          roleCode: item.roleCode,
          roleName: item.roleName,
          // category: item.category,
          actions: renderRowActions(index),
        }
        dataTableRows.push(obj)
        return null
      })
      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
    </>
  )
}

export default SearchResults
