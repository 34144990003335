import {TabPanelRoot} from './Tabs.styled'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  activeIndex: number
}

const TabPanel = ({children, activeIndex, index}: TabPanelProps) => {
  if (activeIndex === index) {
    return (
      <TabPanelRoot role="tabpanel" id={`tab-panel-${index}`}>
        {children}
      </TabPanelRoot>
    )
  }
  return null
}

export default TabPanel
