import {Outlet} from 'react-router-dom'
import { MainContainer } from 'app/common/components/Styled/common.styled'

const ExportImportSetup: React.FC = () => {
  return (
    <MainContainer>
      <Outlet />
    </MainContainer>
  )
}

export default ExportImportSetup
