import Colors from '../../../styles/Colors'

const IconSuccess = ({size = 33, color = Colors.GREEN2}: IconType.Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 33 33" fill="none" focusable="false" aria-hidden="true" role="img">
      <circle cx="16.5" cy="16.5" r="16.5" fill={color} fillOpacity="0.17" />
      <path d="M9 16.7368L14.1724 22L24 12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default IconSuccess
