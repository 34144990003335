import styled, {css} from 'styled-components'
import Colors from 'styles/Colors'

interface SelectWrapperPops {
  $isDark?: boolean
  $noMargin?: boolean
}

export const SelectWrapper = styled.div<SelectWrapperPops>`
  margin-bottom: ${(props) => (props.$noMargin ? 0 : '12px')};
  .lb-single {
    width: 100%;
  }
  .lb-multiple {
    width: 100%;
  }
  .lb-single .lb-selector {
    color: ${Colors.PRIMARY};
    ${(props) =>
      props.$isDark &&
      css`
        color: ${Colors.WHITE};
      `};
  }
  .lb-single.lb-open .lb-selection-item {
    margin-right: 16px;
    ${(props) =>
      props.$isDark &&
      css`
        color: ${Colors.WHITE};
      `}
  }
  .lb .lb-arrow {
    ${(props) =>
      props.$isDark &&
      css`
        color: ${Colors.WHITE};
      `}
  }
  .lb:not(.lb-customize-input) .lb-selector {
    ${(props) =>
      props.$isDark &&
      css`
        background-color: ${Colors.COLOR_BLUE_LIGHT_OPACITY};
        border-color: transparent;
        &:hover {
          border-color: transparent !important;
          box-shadow: none !important;
        }
      `}
  }

  .lb-selection-placeholder {
    margin-right: 16px;
    ${(props) =>
      props.$isDark &&
      css`
        color: ${Colors.WHITE};
      `}
  }

  .lb-disabled.lb:not(.lb-customize-input) .lb-selector {
    border-color: transparent
  }

  .lb:not(.lb-disabled):not(.lb-customize-input):not(.lb-pagination-size-changer):hover .lb-selector {
    ${(props) =>
      props.$isDark &&
      css`
        border-color: ${Colors.GREY6};
      `}
  }
  .lb-focused.lb:not(.lb-disabled):not(.lb-customize-input):not(.lb-pagination-size-changer) .lb-selector {
    box-shadow: none;
    ${(props) =>
      props.$isDark &&
      css`
        border-color: ${Colors.GREY6};
      `}
  }
  .lb-multiple .lb-selection-item .lb-selection-item-content {
    font-size: ${(props) => props.theme.typography.subtitle.fontSize};
    line-height: ${(props) => props.theme.typography.subtitle.lineHeight};
    font-weight: ${(props) => props.theme.typography.subtitle.fontWeight};
    letter-spacing: ${(props) => props.theme.typography.subtitle.letterSpacing};
    display: flex;
    align-items: center;
  }
  .lb-multiple .lb-selection-item {
    background-color: ${Colors.BLUE_LIGHT};
    border-radius: 27px;
    border-color: ${Colors.GREY8};
    padding: 1px 8px;
    &:first-child {
      margin-left: 4px;
    }
  }
`
