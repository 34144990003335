export const IconStarWithCircle = (): JSX.Element => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none">
      <circle cx="16.5" cy="16.5" r="16.5" fill="#FFAFA2" />
      <svg width="17" height="16" x={8} y={8} viewBox="0 0 17 16" fill="none">
        <path
          d="M8.5 0L10.4084 5.87336L16.584 5.87336L11.5878 9.50329L13.4962 15.3766L8.5 11.7467L3.50383 15.3766L5.41219 9.50329L0.416019 5.87336L6.59163 5.87336L8.5 0Z"
          fill="white"
        />
      </svg>
    </svg>
  )
}
