import styled from 'styled-components'

export const QuickActionContainer = styled.div`
  margin-bottom: 40px;
`

export const QuickActionsTitle = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(94, 94, 94, 0.19);
`

export const QuickActionList = styled.div`
  border-bottom: 1px solid rgba(94, 94, 94, 0.19);
  padding: 12px 0;
`
export const QuickActionListItem = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  background-color: #ffffff;
  align-items: center;
`

export const UpdateContainer = styled.div`
  padding-top: 16px;
`
