import { NoPermissionWrapper } from "../Styled/common.styled"

const NoPermission = () => {
  return (
    <NoPermissionWrapper>
      You don't have permission to this module. Please contact administrator.
    </NoPermissionWrapper>
  )
}

export default NoPermission