import {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import Typography from 'lib/components/Typography'
import Colors from 'styles/Colors'
import RewardsFilter, {ResetRewardFormRefType} from './RewardsFilter'
import Appconfig from 'app/common/helpers/AppConfig'
import RewardService from 'app/services/RewardService'
import SearchResults from './SearchResults'
import {cloneDeep, isEmpty} from 'lodash'
import {initialRewardsRequestObj, updateRewardsRequestObj} from '../AddNewRewrds/utils'
import IconAdd from 'app/common/icons/IconAdd'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {REWARD_CREATE, REWARD_SEARCH, REWARD_VIEW} from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'
import {Modal} from 'antd'
import {
  FullWidthCenterAlignedContainer,
  HorizontallyCenter,
  MarginBottomWrapper,
  ModalTitle,
  StyledModalContent,
} from 'app/common/components/Styled/common.styled'
import LoadingPanel from 'lib/components/LoadingPanel'
import IconSuccess from 'app/common/icons/IconSuccess'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import RewardCategoryService from 'app/services/RewardCategoryService'
import {FormTypes} from 'lib/components/Form/Form'

const RewardDashboard = () => {
  const initialResponse: ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)

  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const initialSearchParms: RewardsTypes.RewardSearchParam = {}
  const [isFetching, setIsFetching] = useState(true)
  const [isFormReset, setIsFormReset] = useState(true)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [rewardsData, setRewardsData] = useState<ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>>(initialResponse)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isProcessed, setIsProcessed] = useState(false)
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [selectedReward, setSelectedReward] = useState<RewardsTypes.RewardResponseBL>()
  const [categoryDataOptions, setCategoryDataOptions] = useState<FormTypes.Options[]>([])
  const rewardFormRef = useRef<ResetRewardFormRefType>(null)
  const navigate = useNavigate()
  const {roles} = useAuth()
  const showSearch = roles.includes(REWARD_SEARCH)
  const showTable = roles.includes(REWARD_VIEW)
  const showCreateReward = roles.includes(REWARD_CREATE)

  const handleSearch = (params: RewardsTypes.RewardSearchParam) => {
    const reqParam: RewardsTypes.RewardSearchParam = {
      // IsCombinable: false,
      ...params,
    }
    setIsFetching(true)
    RewardService.search(reqParam)
      .then((res) => {
        const data = res.data
        setRewardsData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const getCategories = (params: RewardCategoryTypes.RewardCatgSearchParam): void => {
    RewardCategoryService.getRewardCategories(params)
      .then((res) => {
        const data = res.data
        setCategoryDataOptions(
          data.data.map((item) => ({
            label: item.categoryName,
            value: item.categoryCode,
          }))
        )
      })
      .finally(() => {})
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...paginationObj})
    updateRewardsRequestObj(initialRewardsRequestObj)
    getCategories({...initialPaginationObj, isAll: true})
  }, [])

  const handleResetSearch = () => {
    rewardFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)
      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const handleFilter = (data: RewardsTypes.RewardsFilterState) => {
    const filterParms: RewardsTypes.RewardSearchParam = {}

    filterParms.voucherTypeName = data.voucherTypeName
    filterParms.voucherTypeCode = data.voucherTypeCode
    filterParms.voucherStatus = data.voucherStatus
    filterParms.voucherTypeSubCategory = data.voucherTypeSubCategory
    filterParms.CategoryList = data.CategoryList.join(',')
    filterParms.voucherTypeGroup = data.voucherTypeGroup
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }
    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  const handleRefresh = (value: boolean, voucherTypeCode: string) => {
    const updatedRewardsData = cloneDeep(rewardsData)
    const updatedRowIndex = rewardsData.data.findIndex((item) => item.voucherTypeCode === voucherTypeCode)
    updatedRewardsData.data[updatedRowIndex] = {
      ...updatedRewardsData.data[updatedRowIndex],
      enabled: value,
    }
    setRewardsData(updatedRewardsData)
  }

  const handleAddNewRewards = () => {
    navigate(`/rewards/new`)
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const handleDeleteReward = (reward: RewardsTypes.RewardResponseBL) => {
    setSelectedReward(reward)
    setOpenDeleteModal(true)
  }

  const handleOk = () => {
    setIsProcessing(true)
    RewardService.deleteReward({voucherTypeCode: selectedReward?.voucherTypeCode})
      .then((res) => {
        const data = res.data
        if (data) {
          setIsProcessed(true)
          setTriggerSearch(true)
        }
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const handleCancelModal = () => {
    setOpenDeleteModal(false)
    setIsProcessed(false)
    if (triggerSearch) {
      handleSearch({...filterSearch, ...paginationObj})
      setTriggerSearch(false)
    }
  }

  const handleRefreshData = (): void => {
    handleSearch({...filterSearch, ...paginationObj})
  }

  const {count = 0, pageIndex = 0} = rewardsData.pageInfo
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const getModalContent = () => {
    if (isProcessing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (isProcessed) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Confirmation
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">{selectedReward?.voucherTypeName} has been deleted.</Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="back" onClick={handleCancelModal}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper width="20%">
          <hr />
        </MarginBottomWrapper>
        <MarginBottomWrapper textAlign="center">
          <Typography.Paragraph size="medium">
            Are you sure you want to permanently delete {selectedReward?.voucherTypeName || ''}?
          </Typography.Paragraph>
          <HorizontallyCenter>
            <Typography.Paragraph size="medium">This cannot be reversed.</Typography.Paragraph>
          </HorizontallyCenter>
        </MarginBottomWrapper>
        <FullWidthCenterAlignedContainer>
          <Button width="50%" type="primary" key="submit" onClick={handleOk} $endIcon={<IconArrowRightLong color={Colors.WHITE} />}>
            Delete
          </Button>
        </FullWidthCenterAlignedContainer>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Cancel
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  const renderRewardsBody = () => {
    if ((showSearch && showTable) || showCreateReward) {
      return (
        <DataTable>
          {showSearch && showTable && (
            <RewardsFilter
              ref={rewardFormRef}
              isFetching={isFetching}
              handleFilter={handleFilter}
              categoryDataOptions={categoryDataOptions}
              isVouchergroupFilterAllowed={false}
            />
          )}
          <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
          {showTable && (
            <>
              <SearchResults
                isFetching={isFetching}
                rewardsResponse={rewardsData}
                handleSearch={handleRefresh}
                handleDeleteReward={handleDeleteReward}
                categoryDataOptions={categoryDataOptions}
                handleRefreshData={handleRefreshData}
              />
              <DataTable.Pagination
                onChange={handleOnChange}
                current={pageIndex}
                pageSizeOptions={pageSizeOptions}
                total={count}
                defaultPageSize={initialPaginationObj.PageCount}
                pageSize={paginationObj.PageCount}
                showSizeChanger
              />
            </>
          )}
        </DataTable>
      )
    }
    return <NoPermission />
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Rewards</Typography.Title>
        {showCreateReward && (
          <Button onClick={handleAddNewRewards} type="primary" $startIcon={<IconAdd color={Colors.WHITE} size={20} />}>
            Add New Reward
          </Button>
        )}
      </StyledHeader>
      {renderRewardsBody()}
      {openDeleteModal && (
        <Modal open={openDeleteModal} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {getModalContent()}
        </Modal>
      )}
    </section>
  )
}

export default RewardDashboard
