import { StyledMenuItem } from 'app/common/components/Styled/common.styled'
import { TIER_VIEW_MEMBERS } from 'app/common/helpers/UserFunctions'
import IconChevronRight from 'app/common/icons/IconChevronRight'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import { useAuth } from 'app/pages/Auth/AuthContext'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import { NoRecordFound } from 'lib/components/DataTable/DataTable.styled'
import Popover from 'lib/components/Popover'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { numberWithCommas } from 'utils/common'

interface TierSystemResultProps {
  tierList: TierTypes.TierDetails[]
  isFetching: boolean
  tierStatus: string
  tierSystemKey: string | null
}

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Name',
    id: 'tierName',
    textAlign: 'left',
    width: '30%',
  },
  {
    title: 'ID',
    id: 'tierCode',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Base Earn Rate',
    id: 'baseEarnRate',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Point Range',
    id: 'pointRange',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: '',
    id: 'actions',
    textAlign: 'right',
    width: '10%',
  },
]

const SearchResults: React.FC<TierSystemResultProps> = ({ tierList, isFetching, tierStatus, tierSystemKey }): JSX.Element => {
  const [selectedTierIndex, setSelectedTierIndex] = useState<number>(-1)
  const navigate = useNavigate()
  const isLive = tierStatus === 'APPROVED'

  const handleMembers = (index: number): void => {
    const membershipTierKey = btoa(tierList[index].membershipTierKey)
    navigate(`/program/tiers/${membershipTierKey}/members`)
  }
  const { roles } = useAuth()
  const viewMembers = roles.includes(TIER_VIEW_MEMBERS)

  const handleOpenPopover = (visible: boolean, rowIndex: number): void => {
    setSelectedTierIndex(visible ? rowIndex : -1)
  }
  const handleView = () => {
    const encodedTierSystemKey = btoa(tierSystemKey || '')
    // const tierData = sampleTierData.find(item => item.tierSystemKey === tierSystemData.tierSystemKey) || sampleTierData[0];
    navigate(`/program/tiers/view/${encodedTierSystemKey}`)
  }
  const renderActionList = (rowIndex: number, item: TierTypes.TierDetails) => {
    return (
      <>
        <StyledMenuItem title="View Promotion">
          <Button type="text" onClick={() => handleView()} $endIcon={<IconChevronRight size={16} />}>
            Details
          </Button>
        </StyledMenuItem>
        <StyledMenuItem title="View Promotion">
          <Button type="text" onClick={() => handleMembers(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
            Members
          </Button>
        </StyledMenuItem>
      </>
    )
  }

  const renderRowActions = (rowIndex: number, item: TierTypes.TierDetails) => {
    // if (roles.includes(PROMOTION_REVIEW) || roles.includes(PROMOTION_DETAIL) || roles.includes(PROMOTION_EDIT)) {
    if (viewMembers) {
      return (
        <Popover
          btnContent={<IconThreeDots />}
          content={renderActionList(rowIndex, item)}
          onOpenChange={(visible: boolean) => handleOpenPopover(visible, rowIndex)}
          rowIndex={rowIndex}
          selectedRowIndex={selectedTierIndex}
        />
      )
    }
    return null
    // }
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (tierList) {
      const dataTableRows: DataTableTypes.Row[] = []
      tierList.map((item, index) => {
        const { fromValue, toValue } = item.tierMasterRuleData.tierMasterWhoRule
        const obj: DataTableTypes.Row = {
          tierName: item.membershipTierName,
          tierCode: item.membershipTierKey ?? '-',
          baseEarnRate: `${item.earnRate} Times`,
          pointRange: `${numberWithCommas(fromValue)} - ${numberWithCommas(toValue)}`,
          actions: isLive ? renderRowActions(index, item) : <></>,
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No tier has been assigned to tier system</NoRecordFound>
  }
  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
    </>
  )
}

export default SearchResults
