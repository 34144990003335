import {Col, Row, Upload, UploadProps, message} from 'antd'
import {ExtraPaddedButton, MarginBottomWrapper, SmallHeightButtonsForIcon} from 'app/common/components/Styled/common.styled'
//import { baseUrl } from 'app/common/helpers/ServiceConfig'
import IconDelete from 'app/common/icons/IconDelete'
import IconDownload from 'app/common/icons/IconDownload'
import IconUpload from 'app/common/icons/IconUpload'
import Button from 'lib/components/Button'
import Form from 'lib/components/Form'
import {FormTypes} from 'lib/components/Form/Form'
import Typography from 'lib/components/Typography'
import {forwardRef, useImperativeHandle, useState} from 'react'
import toast from 'react-hot-toast'
import {ImportNewRefType, sampleDownloadType} from '.'
import {checkIfValidDataType, fileTypeOptions, getModuleNameByImportType} from '../importUtils'
import {isEmpty} from 'lodash'
import { useSearchParams } from 'react-router-dom'

// export interface PointRefType {
//   validate(): boolean,
//   getAttributes: () => ImportTypeAttributes
// }

interface NewPointFormProps {
  dataTypeOptions: FormTypes.Options[]
  handleSampleDownload: (params: sampleDownloadType) => void
}

export interface ImportTypeAttributes {
  dataType: string
  fileName: string
  file: File | null
  segmentId?: number
}

const NewImportForm = forwardRef<ImportNewRefType, NewPointFormProps>(({dataTypeOptions, handleSampleDownload}, ref) => {
  const [params] = useSearchParams()
  let dataTypeParam = params.get('dataType') || ''
  let dataTypeIdParam = params.get('dataTypeId') || ''
  console.log(dataTypeIdParam)
  const updatedDataType = checkIfValidDataType(dataTypeParam)
  const importTypeModule = getModuleNameByImportType(updatedDataType)
  const initialSampleDownload = {fileType: 'CSV', dataType: ''}
  const [dataType, setDataType] = useState<string>(importTypeModule as string || '')
  const [fileName, setFileName] = useState<string>('')
  const [uploaded, setUploaded] = useState<boolean>(false)
  const [showDownloadSampleList, setShowDownloadSampleList] = useState<boolean>(false)
  const [sampleDownload, setSampleDownload] = useState<sampleDownloadType>(initialSampleDownload)
  const [segmentId, setSegmentId] = useState(isNaN(Number(dataTypeIdParam)) ? 0 : Number(dataTypeIdParam))
  const [file, setFile] = useState<File | null>(null)
  const props: UploadProps = {
    showUploadList: false,
    maxCount: 1,
    // headers: {
    //   authorization: 'authorization-text',
    // },
    // action: baseUrl,
    accept: '.csv, .json',
    beforeUpload: (file) => {
      console.log(file)
      let isElsx = file.name.includes('csv')
      let isJson = file.name.includes('json')
      if (!isElsx && !isJson) {
        message.error(`${file.name} is not a .csv or .json file`)
      } else if ((file.size || 1) / 1048576 > 5) {
        message.error(`${file.name} file size should be less than 5MB.`)
        isElsx = false
      }

      return isElsx || isJson || Upload.LIST_IGNORE
    },
    onChange(info) {
      if (info.file.status === 'done') {
        setUploaded(true)
        setFileName(info.file.name)
        setFile(info.file.originFileObj as File)
        message.success(`${info.file.name} file loaded successfully`)
      } else if (info.file.status === undefined || info.file.status === 'error') {
        message.error(`${info.file.name} file load failed.`)
      }
    },
    customRequest(options) {
      const {onSuccess} = options
      onSuccess && onSuccess('ok')
    },
  }

  const handleValidate = (): boolean => {
    if (!dataType) {
      toast.error('Please select a Data type to import.')
      return false
    }
    if (dataType === 'segmentId') {
      toast.error('Please  select Segment ID.')
      return false
    }
    if (!file) {
      toast.error('Please upload a file to import.')
      return false
    }
    return true
  }

  const getImportDetails = () => {
    return {
      dataType,
      fileName,
      file,
      segmentId,
    }
  }

  useImperativeHandle(ref, () => ({getAttributes: getImportDetails, validate: handleValidate}))

  const handleChange = (value: FormTypes.ValueType, key: string) => {
    setDataType(value as string)
    setSegmentId(0)
  }
  const handleSegementIdChange = (value: FormTypes.ValueType, key: string) => {
    setSegmentId(value as number)
  }

  const handleDeleteFile = () => {
    setUploaded(false)
    setFileName('')
  }

  const handleDownloadSample = () => {
    setShowDownloadSampleList(true)
  }

  const setSampleDownloadFile = (value: FormTypes.ValueType, key: keyof ImportExportTypes.ExportImportDownloadFileBL) => {
    console.log(key, value)
    setSampleDownload({...sampleDownload, [key]: value})
  }
  const downloadSampleData = () => {
    if (isEmpty(sampleDownload.dataType)) {
      toast.error('Please select a Data type to download sample import.')
      return
    }
    if (isEmpty(sampleDownload.fileType)) {
      toast.error('Please select a file type to download sample import.')
      return
    }
    handleSampleDownload(sampleDownload)
  }
  const renderSegmentId = () => {
    if (dataType === 'MEMBER_SEGMENT')
      return <Form.InputNumber id="segmentId" label="Segment Id" value={segmentId} onChange={handleSegementIdChange} />

    return
  }

  return (
    <>
      <MarginBottomWrapper>
        <Row>
          <Col span={6}>
            <Form.Select id="dataType" label="Data Type" value={dataType} options={dataTypeOptions} onChange={handleChange} />
            {renderSegmentId()}
          </Col>
        </Row>
      </MarginBottomWrapper>
      <Row>
        <Col span={8}>
          {uploaded ? (
            <Form.Input
              id="upload"
              label="Selected File"
              value={fileName}
              onChange={handleChange}
              disabled
              suffix={<SmallHeightButtonsForIcon size="small" ghost icon={<IconDelete size={16} />} onClick={handleDeleteFile} />}
            />
          ) : (
            <Upload {...props}>
              <ExtraPaddedButton type="default" width="100%" icon={<IconUpload />}>
                Upload
              </ExtraPaddedButton>
            </Upload>
          )}
        </Col>
      </Row>
      <MarginBottomWrapper>
        <Row>
          <Typography.Text>Supported Format: Csv, Json | Maximum File Size: 3MB</Typography.Text>
        </Row>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={8}>
            <Typography.Text>
              Before uploading a data file make sure the file matches the system required format. Not sure? Download a sample
            </Typography.Text>
          </Col>
        </Row>
      </MarginBottomWrapper>
      <Row>
        <Col>
          <Button type="link" onClick={handleDownloadSample}>
            Download Sample File
          </Button>
        </Col>
      </Row>
      {showDownloadSampleList && (
        <Row>
          <Col span={6}>
            <Form.Select id="dataType" value={sampleDownload.dataType} options={dataTypeOptions} onChange={setSampleDownloadFile} />
          </Col>
          <Col span={3}>
            <Form.Select id="fileType" value={sampleDownload.fileType} options={fileTypeOptions} onChange={setSampleDownloadFile} />
          </Col>
          <Col>
            <Button size="small" ghost icon={<IconDownload />} onClick={downloadSampleData} />
          </Col>
        </Row>
      )}
    </>
  )
})

export default NewImportForm
