import {useState, FormEvent, useImperativeHandle, forwardRef} from 'react'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import { checkIfValidDataType, dataTypeOptions, fileStatusOptions, getModuleNameByImportType } from '../importUtils'
import { useSearchParams } from 'react-router-dom'

interface ImportFilterProps {
  handleFilter(formState: ImportExportTypes.ImportFilterState): void
  isFetching: boolean
}

type KeyTypes = keyof ImportExportTypes.ImportFilterState

export interface ResetImportFormRefType {
  handleReset(): void
  getFormState(): ImportExportTypes.ImportFilterState
}

const ImportFilter = forwardRef<ResetImportFormRefType, ImportFilterProps>(({isFetching, handleFilter}, ref) => {
  const [params] = useSearchParams()
  let dataType = params.get('dataType') || ''
  const updatedDataType = checkIfValidDataType(dataType)
  const importTypeModule = getModuleNameByImportType(updatedDataType)
  const initialState: ImportExportTypes.ImportFilterState = {
    fileName: '',
    importType: importTypeModule as string,
    importState: '',
  }
  const [state, setInitialState] = useState<ImportExportTypes.ImportFilterState>(initialState)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleReset = () => {
    setInitialState(initialState)
  }

  const getFormState = (): ImportExportTypes.ImportFilterState => {
    return {
      fileName: state.fileName,
      importType: state.importType,
      importState: state.importState,
    }
  }

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = {...state}
    setInitialState({
      ...prevState,
      [key]: value,
    })
  }

  useImperativeHandle(ref, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.fileName} name="fileName" id="fileName" placeholder="File Name" onChange={handleOnChange} isDark />
      <Form.Select
        value={state.importType}
        options={dataTypeOptions}
        placeholder="Select Data Type"
        id="importType"
        onChange={handleOnChange}
        noMargin
        isDark
      />
      <Form.Select
        value={state.importState}
        options={fileStatusOptions}
        id="importState"
        onChange={handleOnChange}
        noMargin
        isDark
      />
    </DataTable.Filter>
  )
})

export default ImportFilter
