import styled, {css} from 'styled-components'
import Colors from 'styles/Colors'

interface TabButtonProps {
  isActive?: boolean
  color?: string
}

export const TabsRoot = styled.div`
  overflow: hidden;
  min-height: 50px;
  display: flex;
`

export const TabsContainer = styled.div<TabButtonProps>`
  position: relative;
  display: inline-block;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: auto hidden;
`

export const TabsListRoot = styled.div`
  display: flex;
  &::before {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border-bottom: 4px solid #f0f0f0;
    content: '';
  }
`

export const TabIndicator = styled.span<TabButtonProps>`
  position: absolute;
  height: 7px;
  bottom: 0px;
  width: 100%;
  background-color: transparent;
  ${(props) =>
    props.isActive &&
    css`
      background-color: ${Colors.PRIMARY_DARK};
    `}
`

export const TabButton = styled.button<TabButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-transform: uppercase;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 50px;
  flex-shrink: 0;
  padding: 15px 10px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  font-family: ${(props) => props.theme.typography.h6.fontFamily};
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  line-height: ${(props) => props.theme.typography.h6.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.h6.letterSpacing};
  font-weight: ${(props) => props.theme.typography.h6.fontWeight};
  color: ${Colors.GREY4};
  ${(props) =>
    props.isActive &&
    css`
      color: ${props.color ? props.color : Colors.GREY2};
      border-bottom: 4px Solid ${props.color ? props.color : Colors.PRIMARY};
    `}
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const TabPanelRoot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
