import NoPermission from "app/common/components/NoPermission"
import IconAdd from "app/common/icons/IconAdd"
import { StyledHeader } from "app/pages/Promotions/Dashboard/Dashboard.styled"
import Button from "lib/components/Button"
import DataTable from "lib/components/DataTable"
import Typography from "lib/components/Typography"
import Colors from "styles/Colors"
import TagFilter, { TagFormRefType } from "./TagFilter"
import SearchResults from "./SearchResults"
import Appconfig from "app/common/helpers/AppConfig"
import isEmpty from "lodash/isEmpty"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import PartnerService from "app/services/PartnerService"
import { FormTypes } from "lib/components/Form/Form"
import TagService from "app/services/TagService"
// import { useAuth } from "app/pages/Auth/AuthContext"

const TagDashboard: React.FC = (): JSX.Element => {
  const initialResponse: ResponseTypes.PagedResponse<TagTypes.TagResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)

  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const initialSearchParms: TagTypes.TagSearchParam = {}
  const [isFetching, setIsFetching] = useState(true)
  const [isFormReset, setIsFormReset] = useState(true)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [partnerOptions, setPartnerOptions] = useState<FormTypes.Options[]>([])
  const [tagsData, setTagsData] = useState<ResponseTypes.PagedResponse<TagTypes.TagResponseBL[]>>(initialResponse)
  const tagFormRef = useRef<TagFormRefType>(null)
  const navigate = useNavigate()
  // const {roles} = useAuth()
  const showSearch = true // roles.includes(POINT_SEARCH)
  const showTable = true //roles.includes(POINT_VIEW)
  const showCreateTag = true // roles.includes(POINT_CREATE)
  const handleSearch = (params: TagTypes.TagSearchParam) => {
    const reqParam: TagTypes.TagSearchParam = {
      // IsCombinable: false,
      ...params,
    }
    setIsFetching(true)
    TagService.getTags(reqParam)
      .then((res) => {
        const data = res.data
        setTagsData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const getPartners = () => {
    PartnerService.getPartners().then((res) => {
      const data = res.data.data
      let formattedPartnerOptions = data.map((item) => ({value: item.partnerCode, label: item.partnerName}))
      formattedPartnerOptions = [{label: "All", value: "all"}, ...formattedPartnerOptions]
      setPartnerOptions(formattedPartnerOptions)
    })
  }
  
  useEffect(() => {
    handleSearch({...initialSearchParms, ...paginationObj})
    getPartners()
  }, [])

  const handleAddTag = () => {
    navigate(`/program/tag/new`)
  }

  const handleFilter = (data: TagTypes.TagMasterFilterState) => {
    const filterParms: TagTypes.TagSearchParam = {}
    filterParms.voucherTagName= data.voucherTagName || undefined
    filterParms.voucherTagCode = data.voucherTagCode || undefined
    filterParms.partnerCode = data.partnerCode && data.partnerCode !== 'all' ? data.partnerCode : undefined
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const handleResetSearch = () => {
    tagFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }

    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const {count = 0, pageIndex = 0} = tagsData.pageInfo
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const renderTagBody = () => {
    if ((showSearch && showTable) || showCreateTag) {
      return (
        <DataTable>
          {showSearch && showTable && <TagFilter ref={tagFormRef} isFetching={isFetching} handleFilter={handleFilter} partnerOptions={partnerOptions} />}
          <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
          {showTable && (
            <>
              <SearchResults isFetching={isFetching} tagResponse={tagsData} partnerOptions={partnerOptions} />
              <DataTable.Pagination
                onChange={handleOnChange}
                current={pageIndex}
                pageSizeOptions={pageSizeOptions}
                total={count}
                defaultPageSize={initialPaginationObj.PageCount}
                pageSize={paginationObj.PageCount}
                showSizeChanger
              />
            </>
          )}
        </DataTable>
      )
    }
    return <NoPermission />
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Tags Master</Typography.Title>
        {showCreateTag && (
          <Button onClick={handleAddTag} type="primary" $startIcon={<IconAdd color={Colors.WHITE} size={20} />}>
            Add New tag
          </Button>
        )}
      </StyledHeader>
      {renderTagBody()}
    </section>
  )
}

export default TagDashboard