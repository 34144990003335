import {DatePicker as AntdDatepicker} from 'antd'
import dayjs from 'dayjs'
import type {DatePickerProps as AntdDatePickerProps} from 'antd/es/date-picker'
import {useEffect, useState} from 'react'
import Appconfig from 'app/common/helpers/AppConfig'
import {FormTypes} from '../Form'
import FormLabel from '../FormLabel'
import {DatePickerWrapper} from './DatePicker.Styled'
import IconCalendar from 'app/common/icons/IconCalendar'
import {InputReadOnly} from '../Input/Input.Styled'
import Typography from 'lib/components/Typography'

export interface DisabledDatesType {
  from?: string | null
  to?: string | null
}

const DatePicker: React.FC<FormTypes.AntDatePickerProps> = (props) => {
  const {value, label, disabled, disabledDate, onChange, showTime, format, id, status, showToday, placeholder, allowClear, readOnly} = props

  const [dateValue, setDateValue] = useState<AntdDatePickerProps['value']>()

  useEffect(() => {
    if (value || value === null) {
      setDateValue(value ? dayjs(value) : null)
    }
  }, [value])

  const handleOnChange = (value: AntdDatePickerProps['value'], dateString: string) => {
    setDateValue(value)
    onChange(dateString, id)
  }

  let dateFormat = format

  if (showTime) {
    dateFormat = Appconfig.DATE_TIME_FORMAT
  }

  const datepickerOptions: AntdDatePickerProps = {
    showTime,
    onChange: handleOnChange,
    format: dateFormat,
    showToday,
    id,
    value: dateValue,
    placeholder,
    disabledDate,
    disabled,
    status,
    allowClear,
  }

  if (readOnly) {
    if (props.readOnly) {
      const text = value ? dayjs(value).format(dateFormat) : '-'

      return (
        <DatePickerWrapper>
          {props.label && <FormLabel id={props.id} label={props.label} />}
          <InputReadOnly title={text}>
            <Typography.Text ellipsis={{tooltip: text}}>{text}</Typography.Text>
          </InputReadOnly>
        </DatePickerWrapper>
      )
    }
  }

  return (
    <DatePickerWrapper>
      {label && <FormLabel id={props.id} label={props.label} />}
      <AntdDatepicker {...datepickerOptions} onChange={handleOnChange} suffixIcon={<IconCalendar />} />
    </DatePickerWrapper>
  )
}

DatePicker.defaultProps = {
  showToday: false,
  placeholder: 'Select Date',
  format: Appconfig.DATE_FORMAT,
}

export default DatePicker
