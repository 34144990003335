import React, {useRef, useState, useEffect} from 'react'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import FunctionsFilter, {ResetFormRefType} from './FunctionsFilter'
// import {FunctionsList} from './utils'
import SearchResults from './SearchResults'
import {FunctionDasboardContainer} from '../UserManagementStyled'
import Appconfig from 'app/common/helpers/AppConfig'
import {isEmpty} from 'lodash'
import UserManagementService from 'app/services/UserManagementService'
import { useAuth } from 'app/pages/Auth/AuthContext'
import { FUNCTION_SEARCH, FUNCTION_VIEW } from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'

const Functions: React.FC = () => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const initialSearchParms: UserManagementTypes.FunctionSearchParams = {
    functionName: '',
    functionCode: '',
    categoryCode: '',
    categoryCodeMultiple: '',
  }
  const initialFunctionsResponse: ResponseTypes.PagedResponse<UserManagementTypes.FunctionInfoType[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }

  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)
  const [isFormReset, setIsFormReset] = useState(true)
  const functionFilterFormRef = useRef<ResetFormRefType>(null)
  const [isFetching, setIsFetching] = useState(false)
  const [functionsData, setFunctionsData] = useState<ResponseTypes.PagedResponse<UserManagementTypes.FunctionInfoType[]>>(initialFunctionsResponse)
  const {roles} = useAuth()
  const showSearch = roles.includes(FUNCTION_SEARCH)
  const showTable = roles.includes(FUNCTION_VIEW)

  const count = functionsData.pageInfo.count

  const handleSearch = (params: UserManagementTypes.FunctionSearchParams) => {
    const reqParams: UserManagementTypes.FunctionSearchParams = {
      ...params,
    }
    setIsFetching(true)
    UserManagementService.searchFunctions(reqParams)
      .then((res) => {
        const {data} = res
        setFunctionsData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const handleFilter = (data: UserManagementTypes.FunctionSearchParams) => {
    const filterParms: UserManagementTypes.FunctionSearchParams = filterSearch
    filterParms.functionName = data.functionName
    filterParms.functionCode = data.functionCode
    filterParms.categoryCode = data.categoryCode
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const handleResetSearch = () => {
    functionFilterFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }
    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...initialPaginationObj})
  }, [])

  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  if (!showTable) {
    return (
      <NoPermission />
    )
  }

  return (
    <section>
      <FunctionDasboardContainer>
        <DataTable>
          {showSearch && showTable && <FunctionsFilter ref={functionFilterFormRef} isFetching={isFetching} handleFilter={handleFilter} />}
          <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
          {showTable && (
            <>
              <SearchResults isFetching={isFetching} functionsResponse={functionsData.data} />
              <DataTable.Pagination
                onChange={handleOnChange}
                current={functionsData.pageInfo.pageIndex}
                pageSizeOptions={pageSizeOptions}
                total={count}
                defaultPageSize={initialPaginationObj.PageCount}
                pageSize={paginationObj.PageCount}
                showSizeChanger
              />
            </>
          )}
        </DataTable>
      </FunctionDasboardContainer>
    </section>
  )
}

export default Functions
