import {useState, FormEvent, useImperativeHandle, forwardRef} from 'react'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'

interface SegmentFilterProps {
  handleFilter(formState: SegmentationTypes.SegmentFilterState): void
  isFetching: boolean
}

type KeyTypes = keyof SegmentationTypes.SegmentFilterState

export interface ResetSegmentFormRefType {
  handleReset(): void
  getFormState(): SegmentationTypes.SegmentFilterState
}

let segmentStatusOptions = [
  {label: 'All', value: 'ALL'},
  {label: 'On', value: 'ENABLE'},
  {label: 'Off', value: 'DISABLE'},
  // {label: 'APPROVED', value: 'APPROVED'},
  // {label: 'REJECTED', value: 'REJECTED'},
]

let segmentCategoryOptions = [
  {label: 'STATIC', value: 'STATIC'},
  {label: 'REALTIME', value: 'REALTIME'},
]

const SegmentFilter = forwardRef<ResetSegmentFormRefType, SegmentFilterProps>(({isFetching, handleFilter}, ref) => {
  const initialState: SegmentationTypes.SegmentFilterState = {
    segmentName: '',
    segmentCode: '',
    segmentByEnableDisable: '',
    segmentCategory: '',
  }
  const [state, setInitialState] = useState<SegmentationTypes.SegmentFilterState>(initialState)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleReset = () => {
    setInitialState(initialState)
  }

  const getFormState = (): SegmentationTypes.SegmentFilterState => {
    return {
      segmentName: state.segmentName,
      segmentCode: state.segmentCode,
      segmentByEnableDisable: state.segmentByEnableDisable,
      segmentCategory: state.segmentCategory,
    }
  }
  console.log(state)

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = {...state}
    prevState[key] = value
    setInitialState(prevState)
  }

  useImperativeHandle(ref, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.segmentName} name="segmentName" id="segmentName" placeholder="Search by Name" onChange={handleOnChange} isDark />
      <Form.Input value={state.segmentCode} name="segmentCode" id="segmentCode" placeholder="Segment ID" onChange={handleOnChange} isDark />
      <Form.Select
        value={state.segmentCategory}
        options={segmentCategoryOptions}
        placeholder="Search by Type"
        id="segmentCategory"
        onChange={handleOnChange}
        noMargin
        isDark
      />
      <Form.Select
        value={state.segmentByEnableDisable}
        options={segmentStatusOptions}
        placeholder="Search by Status"
        id="segmentByEnableDisable"
        onChange={handleOnChange}
        noMargin
        isDark
      />
    </DataTable.Filter>
  )
})

export default SegmentFilter
