import {useState, FormEvent, useEffect, useImperativeHandle, forwardRef} from 'react'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import MembershipService from 'app/services/MemberService'

interface SegmentFilterProps {
  handleFilter(formState: SegmentationTypes.SegmentMemberFilter): void
  isFetching: boolean
}

type KeyTypes = keyof SegmentationTypes.SegmentMemberFilter

interface optionsProps {
  value: string
  label: string
}

export interface segmentMemberFormRefType {
  handleReset(): void
  getFormState(): SegmentationTypes.SegmentMemberFilter
}

// let statusOptions = [
//   {label: 'Active', value: 'active'},
//   {label: 'Suspended', value: 'suspended'},
//   {label: 'Deactivated', value: 'deactivated'},
// ]

const SegmentMemberFilter = forwardRef<segmentMemberFormRefType, SegmentFilterProps>(({isFetching, handleFilter}, ref) => {
  const initialState: SegmentationTypes.SegmentMemberFilter = {
    memberName: '',
    email: '',
    status: '',
  }
  const reqStatusParams: MemberStatusTypes.MemberStatusDetail = {}
  const [state, setInitialState] = useState<SegmentationTypes.SegmentMemberFilter>(initialState)
  const [statusOptions, setStatusOptions] = useState<optionsProps[]>([])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleReset = () => {
    setInitialState(initialState)
  }

  const getStatus = (reqParams: MemberStatusTypes.MemberStatusDetail) => {
    MembershipService.MemberStatus(reqParams).then((res) => {
      const {data} = res.data
      let statusOption = data
        .filter((item, index) => data.findIndex((x) => x.membershipStatusKey.toUpperCase() === item.membershipStatusKey.toUpperCase()) === index)
        .map((item) => ({
          label: item.membershipStatusName,
          value: item.membershipStatusKey,
        }))
      setStatusOptions(statusOption)
    })
  }

  useEffect(() => {
    getStatus(reqStatusParams)
  }, [])

  const getFormState = (): SegmentationTypes.SegmentMemberFilter => {
    return {
      memberName: state.memberName,
      email: state.email,
      status: state.status,
    }
  }

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = {...state}
    prevState[key] = value
    setInitialState(prevState)
  }

  useImperativeHandle(ref, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.memberName} name="memberName" id="memberName" placeholder="Search by Name" onChange={handleOnChange} isDark />
      <Form.Input value={state.email} name="email" id="email" placeholder="Search by email" onChange={handleOnChange} isDark />
      <Form.Select isDark value={state.status} options={statusOptions} placeholder="Select status" id="status" onChange={handleOnChange} noMargin />
    </DataTable.Filter>
  )
})

export default SegmentMemberFilter
