import {Button, Space} from 'antd'
import Typography from 'lib/components/Typography'
import {ReviewRow, RewardTypeHeader} from './Review.styled'
import Message from 'lib/components/Message'
import {IconStarWithCircle} from 'app/common/icons/IconStarWithCircle'
import {numberWithCommas} from 'utils/common'

interface TierDetailsProps {
  data: PromotionsTypes.PromotionMemberCountDataType | null
  onEdit(stepId: number): void
  isViewOnly?: boolean
  isDisabelEdit?: boolean
  showEdit: boolean
}

const TierDetails: React.FC<TierDetailsProps> = ({data, onEdit, isViewOnly, isDisabelEdit, showEdit}) => {
  const getTotalCount = () => {
    let total = 0
    if (data && !data.isAllMembers) {
      data.tierData.forEach((tier) => {
        if (tier.isChecked) {
          total += tier.tierCount
        }
      })
    } else {
      total = data ? data.totalCount : 0
    }
    return numberWithCommas(total)
  }

  const renderTotalCountMessage = () => (
    <Message inline icon={<IconStarWithCircle />}>
      <Message.Text level="body1" color="default">
        A total of <strong>{getTotalCount()}</strong> Members in the program would be captured by this promotion
      </Message.Text>
    </Message>
  )

  const renderTierInfo = () => {
    let tierData: PromotionsTypes.TierDataType[] = []
    if (data && data.tierData && data.tierData.length > 0) {
      tierData = data.tierData
    }

    const isAllMember = data && data.isAllMembers

    if (isAllMember) {
      return (
        <>
          <div>All Member</div>
          {renderTotalCountMessage()}
        </>
      )
    }

    if (tierData && tierData.length > 0) {
      return (
        <>
          {tierData.map((tier, index) => {
            return (
              tier.isChecked && (
                <div key={index}>
                  {tier.tierName} {`(${numberWithCommas(tier.tierCount)} members)`}
                </div>
              )
            )
          })}
          {renderTotalCountMessage()}
        </>
      )
    }
    return null
  }
  return (
    <ReviewRow>
      <Space direction="vertical" size="large">
        <Typography.Text strong uppercase>
          Members
        </Typography.Text>
        <div>
          <RewardTypeHeader>
            <Typography.Text color="default" uppercase size="small">
              Specific tier(s)
            </Typography.Text>
          </RewardTypeHeader>

          <Space direction="vertical">{renderTierInfo()}</Space>
        </div>
      </Space>
      <div>
        {(!isViewOnly && showEdit) && (
          <Button onClick={() => onEdit(0)} size="small" type="link" disabled={isDisabelEdit}>
            Edit
          </Button>
        )}
      </div>
    </ReviewRow>
  )
}

export default TierDetails
