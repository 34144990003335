import {Col, Divider, Modal, Row} from 'antd'
import Button from 'lib/components/Button'
import TextArea from 'lib/components/TextArea'
import Form from 'lib/components/Form'
import Appconfig from 'app/common/helpers/AppConfig'
// import IconClose from 'app/common/icons/IconClose'
import {useState, useLayoutEffect, useEffect} from 'react'
// import Colors from 'styles/Colors'
import {
  AdjustPointsContainer,
  AdjustPointsForm,
  // AdjustPointsHeader,
  // BadgeButton,
  // FixedPointsContainer,
  // FixedPointsGroup,
  // PointsInput,
} from './AdjustPoints.styled'

import PartnerService from 'app/services/PartnerService'
import PointService from 'app/services/PointService'
import MembershipService from 'app/services/MemberService'
import {toast} from 'react-hot-toast'
import {useParams} from 'react-router-dom'
import {MemberDetailParam, PointTypeData} from '../../index'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'

import {isEmpty} from 'lodash'
import Colors from 'styles/Colors'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {MEMBER_DETAIL_ADJUST} from 'app/common/helpers/UserFunctions'
import {PointsBtn} from '../ServiceActions.styled'
import IconPlus from 'app/common/icons/IconPlus'
import IconMinus from 'app/common/icons/IconMimus'
import Typography from 'lib/components/Typography'
import {MarginBottomWrapper, MarginTopWrapper} from 'app/common/components/Styled/common.styled'
import {valueType} from 'antd/es/statistic/utils'
// import { Btn } from 'lib/components/Button/Button.Styled'

interface Option {
  value: string
  label: string
}

const partnerDetial: PartnerTypes.PartnerDetail = {
  partnerCode: undefined,
}
interface PointsFormType {
  ticketNo: string
  partner: string
  points: string | number
  remarks: string
  pointTypeCode: string
  pointCode: string
}
interface InitialState {
  points: string | number
  pointsForm: PointsFormType
}

type AdjustPointsType = 'plus' | 'minus' | null

interface AdjustPointsProps {
  refreshMemberBalance: (closeModal?: boolean) => void
  handleCloseModal(): void
  pointTypeOptions: PointTypeData[]
}

const AdjustPoints = ({refreshMemberBalance, handleCloseModal, pointTypeOptions}: AdjustPointsProps) => {
  const {membershipId} = useParams<MemberDetailParam>()
  const membershipCustomerId = atob(membershipId || '')
  const formattedPointTypeOptions = pointTypeOptions.map((item) => ({
    label: item.pointTypeName,
    value: item.pointTypeCode,
  }))

  const initialState: InitialState = {
    points: Appconfig.MEMBER.DEFAULT_POINT,
    pointsForm: {
      ticketNo: '',
      partner: '',
      points: '',
      remarks: '',
      pointTypeCode: '',
      pointCode: '',
    },
  }
  const {roles} = useAuth()
  const [state, setState] = useState<InitialState>(initialState)
  // const [partners, setPartners] = useState<PartnerTypes.PartnerBL[]>([])
  const [partnerOptions, setPartnerOptions] = useState<Option[]>([])
  const [pointOptions, setPointOptions] = useState<Option[]>([])
  // const [pointTypeOptions, setPointTypeOptions] = useState<FormTypes.Options[]>([])
  // const [isPointTypeLoading, setIsPointtypeLoading] = useState(true)

  const [pointOptionsResponse, setPointOptionsResponse] = useState<PointType.PointBL[]>([])
  const [open, openConfirmModal] = useState(false)
  const [adjustPointsEnable, toggleAdjustPoints] = useState<AdjustPointsType>('plus')
  const showAdjustPoints = roles.includes(MEMBER_DETAIL_ADJUST)

  const handleAdjustPoint = (pointType: AdjustPointsType) => {
    if (pointType === adjustPointsEnable) {
      closeUpdatePoints()
    } else {
      toggleAdjustPoints(pointType)
    }
  }

  const closeUpdatePoints = () => {
    toggleAdjustPoints(null)
  }

  const updatePointDetails = (v: string, key: keyof PointsFormType) => {
    const prevState = {...state}
    prevState.pointsForm[key] = v
    setState(prevState)
  }

  const setPoints = (value: valueType, key: string) => {
    const prevState = {...state}
    const reg = /^\d*(\.\d*)?$/
    if (reg.test(value.toString()) || value === '') {
      prevState.points = value
      setState(prevState)
    }
  }

  const filterPoints = (data: PointType.PointBL[]): PointType.PointBL[] => {
    if (state.pointsForm.partner === '') {
      return data
    }
    return data.filter((item) => item.partnerCode.toUpperCase() === state.pointsForm.partner.toLocaleUpperCase())
  }
  // const obj: PointType.PointBL = {}

  const upadatAdjustPoint = (propObj: MemebershipTypes.AdjustPoints) => {
    MembershipService.adjustPoints(propObj).then((res) => {
      // console.log(res)
      //const {data} = res.data
      // let {points} = data
      // points = Number(points)
      // // console.log(adjustType)

      // updateBalance(points)
      refreshMemberBalance(true)
      closeUpdatePoints()
      toast.success('Points Update Succesfully')
    })
  }
  const validateInfo = () => {
    if (isEmpty(state.points)) {
      toast.error('Points can not be null or blank ')
      return false
    }
    if (state.points.toString().length > 5) {
      toast.error('Points can not more than 99999.')
      return false
    }

    return true
  }

  const handleUpdatePoints = () => {
    if (validateInfo()) {
      openConfirmModal(true)
    }
  }

  const updatePointType = (v: string, key: keyof PointsFormType) => {
    const prevState = {...state}
    prevState.pointsForm[key] = v
    setState(prevState)
    getPoints(v)
  }

  // const updatePoints = (point: string) => {
  //   const prevState = {...state}
  //   // const points = Number(prevState.points)
  //   const updatedPoints = point
  //   prevState.points = updatedPoints
  //   setState(prevState)
  // }

  const convertResponseToOptios = (response: PartnerTypes.PartnerBL[]): Option[] => {
    return response.map((item) => ({
      label: item.partnerName,
      value: item.partnerCode,
    }))
  }
  const convertPointResponseToOptios = (response: PointType.PointBL[]): Option[] => {
    return response.map((item) => ({
      label: item.pointName,
      value: item.pointCode,
    }))
  }

  // const renderPointsRange = () => {
  //   const pointsRange = Appconfig.MEMBER.DEFAULT_POINTS_RANGE
  //   return pointsRange.map((point) => (
  //     <BadgeButton onClick={() => updatePoints(`${point}`)} key={point}>
  //       {point}
  //     </BadgeButton>
  //   ))
  // }

  const updatePartnerData = (data: PartnerTypes.PartnerBL[]) => {
    const partnerCodeFromRequest = state.pointsForm.partner
    const defaultCode = data.find((partnerOption) => partnerOption.isDefaultPartner)
    let partnerCode = partnerCodeFromRequest
    if (partnerCodeFromRequest) {
      const hasPartnerCode = data.find((partnerOption) => partnerOption.partnerCode === partnerCodeFromRequest)
      if (!hasPartnerCode) {
        partnerCode = defaultCode ? defaultCode.partnerCode : ''
      }
    } else {
      partnerCode = defaultCode ? defaultCode.partnerCode : ''
    }
    updatePointDetails(partnerCode, 'partner')
  }

  const getPartners = () => {
    PartnerService.getPartners(partnerDetial).then((res) => {
      const {data} = res.data
      // setPartners(data)
      setPartnerOptions(convertResponseToOptios(data))
      updatePartnerData(data)
    })
  }
  // const getPointType = () => {
  //   setIsPointtypeLoading(true)
  //   PointService.getPointTypeCodes({pointTypeCode: undefined})
  //     .then((res) => {
  //       const data = res.data.data
  //       const formattedOptions = data.map((item) => ({
  //         label: item.pointTypeName,
  //         value: item.pointTypeCode,
  //       }))
  //       setPointTypeOptions(formattedOptions)
  //       // getPoints(undefined)
  //     })
  //     .finally(() => {
  //       setIsPointtypeLoading(false)
  //     })
  // }

  const getPoints = (pointTypeCode: string | undefined) => {
    PointService.getPoints({pointTypeCode: pointTypeCode}).then((res) => {
      const {data} = res.data
      setPointOptionsResponse(data)
    })
  }

  const handleOk = () => {
    openConfirmModal(false)

    const {pointsForm} = state
    let points = state.points as number
    if (adjustPointsEnable === 'minus') {
      points = -1 * points
    }

    const {partner, remarks, ticketNo} = pointsForm
    if (membershipCustomerId) {
      const adjustPointBody: MemebershipTypes.AdjustPoints = {
        membershipCode: membershipCustomerId,
        pointCode: pointsForm.points,
        points: points,
        transactionDate: null,
        transactionCode: ticketNo,
        partnerCode: partner,
        transactionDescription: remarks,
        source: Appconfig.SOURCE,
      }
      upadatAdjustPoint(adjustPointBody)
    }
    // handelMemberPost()
  }
  const handleCancel = () => {
    openConfirmModal(false)
  }
  const renderModalFooter = () => {
    return (
      <Col style={{textAlign: 'center'}}>
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="primary" key="submit" onClick={handleOk}>
          Confirm
        </Button>
      </Col>
    )
  }

  useLayoutEffect(() => {
    getPartners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const points = filterPoints(pointOptionsResponse)
    setPointOptions(convertPointResponseToOptios(points))
    updatePointDetails('', 'points')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointOptionsResponse, state.pointsForm.partner])

  return (
    <AdjustPointsContainer>
      <Row gutter={9}>
        <Col span={12}>
          <Form.Select
            id="pointTypeCode"
            placeholder="Point Type"
            label="Select Point Type"
            onChange={updatePointType}
            options={formattedPointTypeOptions}
            filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            id="partner"
            label="Select Partner"
            placeholder="Select partner"
            value={state.pointsForm.partner}
            onChange={updatePointDetails}
            options={partnerOptions}
            disabled
          />
        </Col>
      </Row>
      <MarginBottomWrapper>
        <Row>
          {showAdjustPoints && (
            <>
              <Col span={12}>
                <Typography.Text>Add/Subtract</Typography.Text>
                <MarginTopWrapper>
                  <PointsBtn className={adjustPointsEnable === 'plus' ? 'active' : undefined} onClick={() => handleAdjustPoint('plus')}>
                    <IconPlus />
                  </PointsBtn>
                  <PointsBtn className={adjustPointsEnable === 'minus' ? 'active' : undefined} onClick={() => handleAdjustPoint('minus')}>
                    <IconMinus />
                  </PointsBtn>
                </MarginTopWrapper>
              </Col>
              <Col span={12}>
                <Form.Input
                  id="quantity"
                  label="Quantity"
                  value={state.points}
                  placeholder="Quantity"
                  onChange={setPoints}
                  suffix={<Typography.Title level={4}>Points</Typography.Title>}
                />
              </Col>
            </>
          )}
        </Row>
      </MarginBottomWrapper>
      {/* <FixedPointsContainer>
        <div style={{marginRight: '32px'}}>
          <Typography.Title level={4}>
            <strong> {adjustPointsEnable === 'plus' ? 'Adding points' : 'Subtracting points'}</strong>
          </Typography.Title>
        </div>
        <PointsInput id="adding-points" name="adding-points" value={state.points} onChange={setPoints} />
      </FixedPointsContainer> */}
      {/* <FixedPointsGroup>{renderPointsRange()}</FixedPointsGroup> */}
      <Divider />
      <AdjustPointsForm>
        <Row gutter={9}>
          <Col span={12}>
            <Form.Input
              id="ticketNo"
              placeholder="Ticket Number"
              label="Ticket Number"
              value={state.pointsForm.ticketNo}
              onChange={updatePointDetails}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              id="points"
              placeholder="Select points"
              label="Points"
              value={state.pointsForm.points}
              onChange={updatePointDetails}
              options={pointOptions}
              filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Select
              id="partner"
              label="Select Partner"
              placeholder="Select partner"
              onChange={updatePointDetails}
              options={partnerOptions}
            />
          </Col> */}
        </Row>
        {/* <div>
          <Form.Select id="points" placeholder="Select points" label="Points" onChange={updatePointDetails} options={pointOptions} />
        </div> */}
        <div>
          <TextArea
            id="remarks"
            label="Comments - (Optional)"
            textAreaProps={{
              rows: 4,
              value: state.pointsForm.remarks,
              onChange: (e) => updatePointDetails(e.target.value, 'remarks'),
            }}
          />
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button type="link" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button onClick={handleUpdatePoints} type="primary" $endIcon={<IconArrowRightLong color={Colors.WHITE} />}>
            Update Points
          </Button>
        </div>
      </AdjustPointsForm>
      <Modal open={open} onOk={handleOk} onCancel={handleCancel} footer={renderModalFooter()}>
        <Typography.Title level={2}>Are you sure you want to proceed with this Details?</Typography.Title>
      </Modal>
    </AdjustPointsContainer>
  )
}

export default AdjustPoints
