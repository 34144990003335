import styled from 'styled-components'
import Colors from 'styles/Colors'

export const MemberInfoHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #cecece;
  align-items: center;
`

export const MemberInfoHeaderAction = styled.div`
  margin-left: auto;
  margin-bottom: 4px;
`

export const MemberInfoFormContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 32px;
`

export const MemberNameContainer = styled.div`
  max-width: 580px;
  overflow: hidden;
`

export const MemberDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid ${Colors.PRIMARY};
  border-left: 10px solid ${Colors.PRIMARY};
  margin-bottom: 32px;
`

export const BorderedSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(94, 94, 94, 0.19);
  border-bottom: 1px solid rgba(94, 94, 94, 0.19);
  padding: 16px 0;
`

export const AwardPointWrapper = styled.div`
  background-color: rgba(243, 243, 243, 0.5);
  padding: 24px 0px 12px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PointsDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const NextPointExpirationDetails = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`
