const IconDelete = ({size = 24, color = '#0062FF'}: IconType.Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 20" fill="none">
      <g clipPath="url(#clip0_710_1599)">
        <path
          d="M5.13036 0.691406C5.33527 0.265625 5.75647 0 6.21563 0H10.7844C11.2435 0 11.6647 0.265625 11.8696 0.691406L12.1429 1.25H15.7857C16.4574 1.25 17 1.80859 17 2.5C17 3.19141 16.4574 3.75 15.7857 3.75H1.21429C0.542634 3.75 0 3.19141 0 2.5C0 1.80859 0.542634 1.25 1.21429 1.25H4.85714L5.13036 0.691406ZM1.21429 5H15.7857V17.5C15.7857 18.8789 14.6967 20 13.3571 20H3.64286C2.30335 20 1.21429 18.8789 1.21429 17.5V5ZM4.85714 7.5C4.52321 7.5 4.25 7.78125 4.25 8.125V16.875C4.25 17.2188 4.52321 17.5 4.85714 17.5C5.19107 17.5 5.46429 17.2188 5.46429 16.875V8.125C5.46429 7.78125 5.19107 7.5 4.85714 7.5ZM8.5 7.5C8.16607 7.5 7.89286 7.78125 7.89286 8.125V16.875C7.89286 17.2188 8.16607 17.5 8.5 17.5C8.83393 17.5 9.10714 17.2188 9.10714 16.875V8.125C9.10714 7.78125 8.83393 7.5 8.5 7.5ZM12.1429 7.5C11.8089 7.5 11.5357 7.78125 11.5357 8.125V16.875C11.5357 17.2188 11.8089 17.5 12.1429 17.5C12.4768 17.5 12.75 17.2188 12.75 16.875V8.125C12.75 7.78125 12.4768 7.5 12.1429 7.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_710_1599">
          <rect width="17" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconDelete
