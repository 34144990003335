import styled from 'styled-components'
import Colors from 'styles/Colors'

export const TotalResultContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
  margin-bottom: 10px;
  align-items: center;
`

export const TotalSearchResultsWrapper = styled.div`
  font-weight: ${(props) => props.theme.typography.body1.fontSize};
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  line-height: ${(props) => props.theme.typography.body1.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.body1.letterSpacing};

  color: ${Colors.GREY3};
  display: flex;
  align-items: center;
`

export const ResetSearchContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0078e8;
`

export const TotalResultRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
  div + div {
    margin-left: 10px;
  }
`
