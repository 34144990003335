import {Radio, RadioChangeEvent, RadioGroupProps} from 'antd'
import FormLabel from '../Form/FormLabel'
import {ToggleOptionType} from './ToggleButton'
import {StyledToggleButton} from './ToggleButtonStyled'

interface ToggleButtonProps extends RadioGroupProps {
  id: string
  value?: boolean
  values: ToggleOptionType[]
  label?: React.ReactNode
  disabled?: boolean
  handleChange?(value: boolean, key: string): void
  useAsButton?: boolean
}

const ToggleButton = ({id, values, label, handleChange, value, disabled, useAsButton}: ToggleButtonProps) => {
  const handleToggle = (e: RadioChangeEvent) => {
    handleChange && handleChange(e.target.value, id)
  }

  return (
    <>
      {label && <FormLabel id={id} label={label} />}
      <StyledToggleButton value={value} onChange={handleToggle} disabled={disabled} useAsButton={useAsButton}>
        {values.map((item, index) => (
          <Radio.Button key={index} value={item.value}>
            {item.label}
          </Radio.Button>
        ))}
      </StyledToggleButton>
    </>
  )
}

export default ToggleButton
