import {MarginBottomWrapper, Seperator} from 'app/common/components/Styled/common.styled'
import {ForgotPasswordContainer, ForgotPasswordContent, FullWidthButton, StyledBurstLoginTitle} from './Login.styled'
import Typography from 'lib/components/Typography'
import Form from 'lib/components/Form'
import {FormEvent, useState} from 'react'
import Navigation from 'app/common/components/Navigation'
import { toast } from 'react-hot-toast'
import { emailRegex } from 'utils/common'
import LoginService from 'app/services/LoginService'

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('')
  const [isResetLinkSent, setIsResetLinkSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChange = (value: string, id: string) => {
    setEmail(value)
  }

  const validate = () : boolean => {
    if (!email || (!emailRegex.test(email))) {
      toast.error(!email ? 'Please enter an email address.' : 'Please enter a valid email address.')
      return false
    }
    return true
  }

  const handleForgotPassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (validate()) {
      setLoading(true)
      LoginService.forgotPassword({ username: email.trim(), flow: 2 }).then(res => {
        setIsResetLinkSent(true)
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  return (
    <>
      <Navigation isBlankNav />
      <ForgotPasswordContainer>
        <ForgotPasswordContent>
          <MarginBottomWrapper>
            <StyledBurstLoginTitle>Loyalty Burst Login</StyledBurstLoginTitle>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Seperator></Seperator>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Typography.Title level={2}>Forgot Password</Typography.Title>
          </MarginBottomWrapper>
          {!isResetLinkSent ? (
            <MarginBottomWrapper width='80%'>
              <form onSubmit={handleForgotPassword}>
                <MarginBottomWrapper width="100%">
                  <Form.Input id="email" label="Email Address" placeholder="Email Address" value={email} onChange={handleChange} />
                </MarginBottomWrapper>
                <FullWidthButton htmlType="submit" type="primary" loading={loading}>
                  Submit
                </FullWidthButton>
              </form>
            </MarginBottomWrapper>
          ) : (
            <Typography.Paragraph size="medium">
              If a user exists with the email entered, please expect an email from your admin with a reset password link.
            </Typography.Paragraph>
          )}
        </ForgotPasswordContent>
      </ForgotPasswordContainer>
    </>
  )
}

export default ForgotPassword
