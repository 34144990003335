import {Modal} from 'antd'
import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import {useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import ImportHeader from './ImportHeader'
import {ImportFormBodyContainer, NewImportFooter} from '../ImportStyled'
import { MarginBottomWrapper, ModalTitle, Seperator, StyledModalContent } from 'app/common/components/Styled/common.styled'
import NewImportForm, {ImportTypeAttributes} from './NewImportForm'
import {DownLoadFile, dataTypeOptions, getUrlAndParamsImportFile, getUrlSampleImportFile} from '../importUtils'
import LoadingPanel from 'lib/components/LoadingPanel'
import Typography from 'lib/components/Typography'
import IconSuccess from 'app/common/icons/IconSuccess'
import ImportExportSevice from 'app/services/ImportExportService'

export type ImportNewDataParam = {
  fileId: string | 'new' | 'view'
}

export interface ImportNewRefType {
  validate(): boolean
  getAttributes: () => ImportTypeAttributes
}
export interface sampleDownloadType {
  dataType: string
  fileType: string
}

const ImportNewData: React.FC = () => {
  const {fileId} = useParams<ImportNewDataParam>()
  const importNewFormRef = useRef<ImportNewRefType>(null)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [processed, setProcessed] = useState(false)

  const handleCancelNewImport = () => {
    setOpen(true)
  }

  const handleOk = () => {
    navigate(-1)
    setOpen(false)
  }

  const handleCancelModal = () => {
    setOpen(false)
    setProcessed(false)
  }

  const handleCompleted = () => {
    handleCancelModal()
    const data = importNewFormRef.current?.getAttributes()
    if (data) {
      const splittedImportType = data.dataType.split('_')[1]
      navigate(`/program/import?dataType=${splittedImportType}`)
    }
  }

  const handleSubmit = () => {
    if (importNewFormRef.current?.validate()) {
      const data = importNewFormRef.current?.getAttributes()
      const url = getUrlAndParamsImportFile(data.dataType)
      const splittedImportType = data.dataType.split('_')[1]
      const updatedParams: ImportExportTypes.ImportFileParams = {
        ImportBatchCode: data.fileName,
        ImportType: splittedImportType,
        SegmentId: data.segmentId,
      }
      const formData = new FormData()
      if (data.file) {
        formData.append('file', data.file)
      }

      setProcessing(true)
      ImportExportSevice.uploadImportFile(url, updatedParams, formData)
        .then((res) => {
          setProcessing(false)
          setOpen(true)
          setProcessed(true)
        })
        .finally(() => {
          setProcessing(false)
        })
    }
  }
  const handleSampleDownload = (data: sampleDownloadType) => {
    const url = getUrlSampleImportFile(data.dataType)
    const splittedImportType = data.dataType.split('_')[1]
    const params: ImportExportTypes.SampleImportDownloadFileParams = {
      FileType: data.fileType,
      ImportType: splittedImportType,
    }
    ImportExportSevice.downloadSampleImportFile(url, params).then((res) => {
      console.log(res)
      const currentDate = new Date().getTime().toString()

      const fileName = params.ImportType + currentDate + '.' + params.FileType.toLocaleLowerCase()
      DownLoadFile(res.data, fileName)
    })
  }

  const renderImportFooter = () => {
    return (
      <NewImportFooter>
        <Button type="default" onClick={handleCancelNewImport}>
          Cancel
        </Button>
        <Button loading={processing} onClick={handleSubmit} type="primary">
          Submit
        </Button>
      </NewImportFooter>
    )
  }

  const renderNewImportBody = () => {
    return <NewImportForm ref={importNewFormRef} dataTypeOptions={dataTypeOptions} handleSampleDownload={handleSampleDownload} />
  }

  const renderModalBody = () => {
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (processed) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={2} uppercase>
              File Submitted For Processing
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                File has been submitted for processing and it could take several minutes. You can check back status of the data import here (Import
                Data Table).
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Close
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">Are you sure you want to cancel importing a file?</Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Continue with cancel
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs
          backLinkLabel="Import Data Home"
          backLinkPath={`/program/import`}
          activePageLabel={'Import New Data'}
        />
        <Button type="link" onClick={handleCancelNewImport}>
          {fileId === 'new' ? 'Cancel New Import' : 'Back'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <ImportHeader />
      <ImportFormBodyContainer>{renderNewImportBody()}</ImportFormBodyContainer>
      {renderImportFooter()}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null} maskClosable={false}>
          {renderModalBody()}
        </Modal>
      )}
    </div>
  )
}

export default ImportNewData
