import {TypographyTypes} from './Typography'
import {StyledText} from './Typography.styled'

const Text: React.FC<TypographyTypes.Text> = (props) => {
  return (
    <StyledText $size={props.size} $uppercase={props.uppercase} $color={props.color} {...props}>
      {props.children}
    </StyledText>
  )
}

Text.defaultProps = {
  color: 'default',
  size: 'medium',
}

export default Text
