import React from 'react'
import {RadioChangeEvent, Space} from 'antd'
import {Radio as AntRadio} from 'antd'
import {RadioGroupWrapper, StyledAntRadio} from './Radio.Styled'
import {FormTypes} from '../Form'
import FormLabel from '../FormLabel'
import IconEye from 'app/common/icons/IconEye'
import Button from 'lib/components/Button'
import { InfoMessageContainer, StyleActivityInfo } from 'app/pages/Promotions/CreatePromotion/CreatePromotion.styled'
import IconClose from 'app/common/icons/IconClose'
import Colors from 'styles/Colors'
import IconLeftFilledArrow from 'app/common/icons/IconLeftFilledArrow'

const Radio: React.FC<FormTypes.Radio> = (props) => {
  const {options, value, disabled, onShowInfo, activeItem} = props

  const onRadioChange = (e: RadioChangeEvent) => {
    const radioValue = e.target.value
    if (props.id) {
      props.onChange(radioValue, props.id)
    }
  }

  const renderRadio = () => {
    if (options && options.length > 0) {
      return options.map((option) => {
        const optionValues = option as FormTypes.customCheckboxOptionType
        return (
          <Space direction='horizontal' className='radio-label-wrapper'>
            <StyledAntRadio key={`${optionValues.value}`} value={optionValues.value}>
              {optionValues.label}
            </StyledAntRadio>
            {optionValues.showIcon && (
              <span style={{ position: 'relative', display: 'flex' }}>
                <Button onClick={() =>  onShowInfo && onShowInfo(optionValues.value as string)} ghost size='small' icon={<IconEye size={16}/>}>
                </Button>
                {activeItem === optionValues.value &&  (
                    <StyleActivityInfo>
                      <IconLeftFilledArrow />
                      <InfoMessageContainer>
                        <span>{optionValues.infoMsg}</span>
                        <Button onClick={() =>  onShowInfo && onShowInfo('')} ghost size='small' icon={<IconClose color={Colors.WHITE} size={16}/>} />
                      </InfoMessageContainer>
                    </StyleActivityInfo>
                )}
              </span>
            )}
          </Space>
        )
      })
    }
    return null
  }

  return (
    <>
      {props.label && props.id && <FormLabel id={props.id} label={props.label} />}
      <RadioGroupWrapper>
        <AntRadio.Group className="radio-group" disabled={disabled} onChange={onRadioChange} value={value}>
          {props.align === 'vertical' ? <Space direction={props.align}>{renderRadio()}</Space> : renderRadio()}
        </AntRadio.Group>
      </RadioGroupWrapper>
    </>
  )
}

export default Radio
