//import jwt_decode from 'jwt-decode'
import Appconfig from 'app/common/helpers/AppConfig'
import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import UserManagementHeader from './UserManagementHeader'
import {RolesFormBodyContainer, UserManagementFooter} from '../../UserManagementStyled'
import {Modal} from 'antd'
import Typography from 'lib/components/Typography'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import LoadingPanel from 'lib/components/LoadingPanel'
import NewUserForm, {UserRefType} from './NewUserForm'
import ReviewUser from './ReviewUser'
import {getUsersRequestObj, inititalUserRequestObj, updateUserListResponse, updateUserRequestObj} from '../utils'
import {MarginBottomWrapper, ModalTitle, StyledModalContent} from 'app/common/components/Styled/common.styled'
import UserManagementService from 'app/services/UserManagementService'
import {FormTypes} from 'lib/components/Form/Form'
import {toast} from 'react-hot-toast'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {USER_ACTIVATE, USER_CREATE, USER_EDIT, USER_PASSWORDRESET} from 'app/common/helpers/UserFunctions'

export type AddNewUserParam = {
  userId: string | 'new' | 'view'
}

interface AddNewUserProps {
  isViewOnly?: boolean
}

const AddNewUser: React.FC<AddNewUserProps> = ({isViewOnly}) => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const userRequestObj = getUsersRequestObj
  const navigate = useNavigate()
  //const token = process.env.REACT_APP_API_TOKEN
  //const decoded: any = jwt_decode(token || '')
  const {userId} = useParams<AddNewUserParam>()
  const actualUserId = atob(userId || '')
  const [activeStep, setActiveStep] = useState(userId === 'new' ? 0 : 1)
  const [loading, setLoading] = useState(false)
  const [open, openConfirmModal] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [individualUser, setIndividualUser] = useState<UserManagementTypes.UserInfoType>(userRequestObj)
  const [managers, setmanagers] = useState<FormTypes.Options[]>([])
  const [userRoles, setRoles] = useState<FormTypes.Options[]>([])
  const userFormRef = useRef<UserRefType>(null)
  const isEditing = userId !== 'new'
  const {roles} = useAuth()
  const showEditUser = roles.includes(USER_EDIT)
  const showCreateUser = roles.includes(USER_CREATE)
  const showActivateUser = roles.includes(USER_ACTIVATE)
  const showSendEmail = roles.includes(USER_PASSWORDRESET)

  const getManagers = (params: UserManagementTypes.UserSearchParams) => {
    UserManagementService.searchUser({...params, isAll: true, status: 'Active'}).then((res) => {
      const data = res.data.data
      updateUserListResponse(data)

      setmanagers(
        data.map((item) => ({
          label: `${item.firstName} ${item.middleName ?? ''} ${item.lastName}`,
          value: item.userName,
        }))
      )
    })
  }

  const getRoles = (params: UserManagementTypes.RoleSearchParam) => {
    UserManagementService.searchRoles({...params, isAll: true}).then((res) => {
      const data = res.data.data
      setRoles(
        data.map((item) => ({
          label: item.roleName,
          value: item.roleCode,
        }))
      )
    })
  }

  useEffect(() => {
    if (userId !== 'new') {
      setLoadingUser(true)
      UserManagementService.searchUser({userName: actualUserId})
        .then((res) => {
          const userData = res.data.data[0]
          setIndividualUser(userData)
          updateUserRequestObj(userData)
        })
        .finally(() => {
          setLoadingUser(false)
        })
    }
    getManagers(initialPaginationObj)
    getRoles(initialPaginationObj)
    // set initial req obj after leaving the page
    return () => {
      updateUserRequestObj(inititalUserRequestObj)
    }
  }, [])

  const allowBack = activeStep === 1

  const renderUsersBody = () => {
    if (loadingUser) {
      return <LoadingPanel />
    }
    if (userId !== 'new' && !individualUser) {
      return <div>No User found with {actualUserId}</div>
    }
    if (activeStep === 0) {
      return <NewUserForm ref={userFormRef} managers={managers} roles={userRoles} userId={actualUserId} />
    }
    return <ReviewUser isViewOnly={!!isViewOnly} showEditUser={showEditUser} showActivateUser={showActivateUser} showSendEmail={showSendEmail} />
  }

  const showUserPreview = () => {
    if (userFormRef.current?.validate()) {
      setLoading(true)
      openConfirmModal(true)
      const params = userId !== 'new' ? {userName: actualUserId} : undefined
      UserManagementService.saveUser(getUsersRequestObj, params)
        .then((res) => {
          const data = res.data.data
          updateUserRequestObj(data)
          sessionStorage.setItem('activeTab', '1')
          toast.success('Data Saved Successfully!!!')
          navigate(`/user-management`)
        })
        .finally(() => {
          setLoading(false)
          openConfirmModal(false)
        })
    }
  }

  const handleEditRole = () => {
    setActiveStep(0)
  }

  const handleCancelConfirm = () => {
    // updateRewardsRequestObj(initialRewardsRequestObj)
    sessionStorage.setItem('activeTab', '1')
    navigate(-1)
  }

  const handleOk = () => {
    openConfirmModal(false)
    handleCancelConfirm()
  }

  const handleCancelNewUser = () => {
    if (isViewOnly === true) {
      handleCancelConfirm()
    } else {
      openConfirmModal(true)
    }
  }

  const handleCancelModal = () => {
    openConfirmModal(false)
  }

  const renderModalFooter = () => {
    return null
  }

  const renderSaveButton = () => {
    return (
      <Button
        loading={loading}
        onClick={showUserPreview}
        type="primary"
        disabled={false}
        $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
      >
        Save
      </Button>
    )
  }

  const renderUsersFooter = () => {
    return (
      !isViewOnly && (
        <UserManagementFooter>
          {allowBack && showEditUser ? (
            <Button type="primary" onClick={handleEditRole}>
              Edit
            </Button>
          ) : (
            <></>
          )}

          {(showEditUser || showCreateUser) && activeStep === 0 && renderSaveButton()}
        </UserManagementFooter>
      )
    )
  }

  const getModalContent = () => {
    if (loading) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">
            Are you sure you want to cancel {isEditing ? 'editing' : 'adding'}{' '}
            {`${individualUser.firstName} ${individualUser.middleName ?? ''} ${individualUser.lastName}`} user?
          </Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Continue with cancel
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs backLinkLabel="User Management" backLinkPath={`/user-management`} activePageLabel="Add New User" />
        <Button type="link" onClick={handleCancelNewUser}>
          {userId === 'new' ? 'Cancel New User' : 'Cancel'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <UserManagementHeader
        activeStep={activeStep}
        userName={`${individualUser.firstName} ${individualUser.middleName ?? ''} ${individualUser.lastName}`}
      />
      {renderUsersFooter()}
      <RolesFormBodyContainer>{renderUsersBody()}</RolesFormBodyContainer>

      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={renderModalFooter()}>
          {getModalContent()}
        </Modal>
      )}
    </div>
  )
}

export default AddNewUser
