import { RouteObject} from 'react-router-dom'

// import RootLayout from '../common/components/Layout/RootLayout'
import {ErrorBoundry} from '../common/components/Routes/Fallback'
import NoMatch from '../common/components/NoMatch'
import PublicRoutes from './publicRoutes'
import AdminRoutes from './adminRoutes'
import AppLayout from 'app/common/components/AppLayout'
const routes: RouteObject[] = [
  {
    element: <AppLayout />,
    errorElement: <ErrorBoundry />,
    children: [
      PublicRoutes,
      AdminRoutes,
      {path: '*', element: <NoMatch />},
    ],
  },
]


export default routes
