import Form from 'lib/components/Form'
import {LoginButton, LoginContainer, LoginForm, LoginFormContainer, LoginLogoWrapper, StyledWelcomeText} from './Login.styled'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import Button from 'lib/components/Button'
import IconEye from 'app/common/icons/IconEye'
import {FormEvent, useState} from 'react'
import {CheckboxValueType} from 'antd/es/checkbox/Group'
import Colors from 'styles/Colors'
import {emailRegex} from 'utils/common'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-hot-toast'
import {useAuth} from '../Auth/AuthContext'
import Appconfig from 'app/common/helpers/AppConfig'

const loginRememberOptions = [
  {
    label: 'Remember Me',
    value: 'remember',
  },
]

interface errorStateType {
  emailAddress: boolean
  password: boolean
}

const errorState: errorStateType = {
  emailAddress: false,
  password: false,
}

const Login: React.FC = () => {
  const navigate = useNavigate()
  const savedUserName = localStorage.getItem(Appconfig.SAVED_USERNAME_KEY)
  const savedPassword = localStorage.getItem(Appconfig.SAVED_PASSWORD_KEY)
  const initialState = {
    emailAddress: savedUserName || '',
    password: savedPassword || '',
    rememberMe: !!savedUserName,
  }
  const [state, setInitialState] = useState(initialState)
  const [error, setErrorState] = useState(errorState)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [rememberMe, setRememberMe] = useState<CheckboxValueType[]>(savedUserName ? ['remember'] : [])
  const [loading, setLoading] = useState(false)
  const auth = useAuth()

  const handleChange = (value: string, id: string) => {
    const prevState = {...state}
    setInitialState({
      ...prevState,
      [id]: value,
    })
    setErrorState({
      ...error,
      [id]: false,
    })
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleRememberMe = (value: CheckboxValueType[], id: string) => {
    console.log(value)
    setRememberMe(value)
    if (value.length) {
      setInitialState({
        ...state,
        rememberMe: true,
      })
    } else {
      setInitialState({
        ...state,
        rememberMe: false,
      })
    }
  }

  const validateLogin = () => {
    if (!emailRegex.test(state.emailAddress)) {
      setErrorState(() => ({
        ...error,
        emailAddress: true,
      }))
      toast.error('Please enter a valid email address')
      return false
    }
    if (!state.password) {
      setErrorState(() => ({
        ...error,
        password: true,
      }))
      toast.error('Please enter a password')
      return false
    }
    return true
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (validateLogin()) {
      setLoading(true)
      auth.login({username: state.emailAddress, password: state.password, rememberMe: state.rememberMe}).finally(() => {
        setLoading(false)
      })
    }
  }

  const forgotPassword = () => {
    navigate(`/forgot-pass`)
  }

  return (
    <LoginContainer>
      <LoginLogoWrapper>
        <img src="/assets/logoImg.png" width={180} height={53} alt="This is a logo" />
        <LoginFormContainer>
          <StyledWelcomeText>Welcome to Loyalty Burst</StyledWelcomeText>
          <LoginForm>
            <form onSubmit={handleSubmit}>
              <MarginBottomWrapper>
                <Form.Input
                  id="emailAddress"
                  name="emailAddress"
                  placeholder="Email Address"
                  label="Email Address"
                  value={state.emailAddress}
                  onChange={handleChange}
                  status={error.emailAddress ? 'error' : undefined}
                  maxLength={250}
                  isDark
                  isLoginInput
                />
              </MarginBottomWrapper>
              <MarginBottomWrapper>
                <Form.Input
                  id="password"
                  name="password"
                  placeholder="Password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={state.password}
                  onChange={handleChange}
                  status={error.password ? 'error' : undefined}
                  maxLength={20}
                  isDark
                  isLoginInput
                  suffix={
                    <Button type="text" onClick={handleShowPassword}>
                      <IconEye size={16} color={Colors.WHITE} />
                    </Button>
                  }
                />
              </MarginBottomWrapper>
              <MarginBottomWrapper>
                <Form.Checkbox id="rememberMe" value={rememberMe} options={loginRememberOptions} onChange={handleRememberMe} isDark />
              </MarginBottomWrapper>
              <MarginBottomWrapper>
                <LoginButton name="login" loading={loading} htmlType="submit">
                  Login
                </LoginButton>
              </MarginBottomWrapper>
              <MarginBottomWrapper style={{textAlign: 'center'}}>
                <Button type="link" $isDark onClick={forgotPassword}>
                  Forgot Password?
                </Button>
              </MarginBottomWrapper>
            </form>
          </LoginForm>
        </LoginFormContainer>
      </LoginLogoWrapper>
    </LoginContainer>
  )
}

export default Login
