import Axios, {AxiosInstance, AxiosResponse} from 'axios'
import {toast} from 'react-hot-toast'
import Appconfig from '../AppConfig'
import {HttpRequestGenericError, HttpRequestType} from './types'
import {isEmpty} from 'lodash'
import { setRedirectPath } from 'utils/common'
import LoginService, { getSessionToken } from 'app/services/LoginService'

const defaultHeaders = {
  Accept: 'application/json',
  'x-api-key':window.env.X_API_KEY,
  'Content-Type': 'application/json',
}

const api: AxiosInstance = Axios.create({headers: defaultHeaders})

const redirectForAuth = () => {
  setRedirectPath(window.location.pathname)
  window.location.pathname = '/login'
}

const showErrorToast = (msg = Appconfig.DEFAULT_SERVER_ERROR) => {
  toast.error(msg)
}

export const handleHttpErrors = (error: HttpRequestGenericError, showActual: boolean) => {
  const errorData: any = error.response?.data
  const {data}: ResponseTypes.ResponseData<ErrorType.ErroDetails[]> = errorData as ResponseTypes.ResponseData<ErrorType.ErroDetails[]>
  switch (error.response?.status) {
    case 0: {
      break
    }
    case 401:
      toast.error("Your session has expired. Please log in again!")
      setTimeout(() => {
        LoginService.removeSessionToken()
        redirectForAuth()
      }, 1000)
      break
    case 403:
      LoginService.removeSessionToken()
      redirectForAuth()
      break
    case 451:
    case 203:
    case 412: {
      LoginService.removeSessionToken()
      redirectForAuth()
      break
    }
    case 400: {
      if (!Array.isArray(data)) {
        showErrorToast(errorData.error_description)
      } else {
        const erromsg = data[0].detail
        showErrorToast(isEmpty(erromsg) ? error.message: erromsg)
      }
      break
    }
    case 404:
    case 500: {
      showErrorToast(data[0].detail ?? error.message)
      break
    }
    default: {
      showErrorToast()
    }
  }
}

export const _setAuthTokenToHttpHeader = (token: string): void => {
  api.defaults.headers['Authorization'] = `Bearer ${token}`
}

export const _removeAuthTokenToHttpHeader = (): void => {
  delete api.defaults.headers['Authorization']
}

const HttpRequest = async ({method = 'GET', showActual = false, ...config}: HttpRequestType): Promise<AxiosResponse<any, any>> => {
  if (getSessionToken()) {
    _setAuthTokenToHttpHeader(LoginService.getSessionToken())
  } else {
    _removeAuthTokenToHttpHeader()
  }
  // const token = process.env.REACT_APP_API_TOKEN
  // const authToken = `Bearer ${token}`

  // if (authToken) {
  //   api.defaults.headers['Authorization'] = authToken
  // } else {
  //   delete api.defaults.headers['Authorization']
  // }

  if (config.url) {
    const m = config.url.indexOf('json') > 1 ? 'GET' : method
    try {
      const response = await api({
        method: m,
        ...config,
      })
      return Promise.resolve(response)
    } catch (error) {
      const errors = error as HttpRequestGenericError
      if (!showActual) {
        handleHttpErrors(errors, showActual)
      }
      return Promise.reject(errors)
    }
  }
  return Promise.reject({message: 'API URL is not defined'})
}

export default HttpRequest
