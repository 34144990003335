import {useState, FormEvent, useImperativeHandle, forwardRef} from 'react'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import {ActivityLogTypes} from 'types/ActivityLogTypes'
import {affectedModuleOptions} from '../utils'

interface PointFilterProps {
  handleFilter(formState: ActivityLogTypes.ActivityLogFilterState): void
  isFetching: boolean
}

type KeyTypes = keyof ActivityLogTypes.ActivityLogFilterState

export interface ResetActivityLogFormRefType {
  handleReset(): void
  getFormState(): ActivityLogTypes.ActivityLogFilterState
}

const ActivityLogFilter = forwardRef<ResetActivityLogFormRefType, PointFilterProps>(({isFetching, handleFilter}, ref) => {
  const initialState: ActivityLogTypes.ActivityLogFilterState = {
    dateRange: [],
    modifiedBy: '',
    affectedModule: '',
    uniqueId: '',
  }
  const [state, setInitialState] = useState<ActivityLogTypes.ActivityLogFilterState>(initialState)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleReset = () => {
    setInitialState(initialState)
  }

  const getFormState = (): ActivityLogTypes.ActivityLogFilterState => {
    return {
      dateRange: state.dateRange,
      modifiedBy: state.modifiedBy,
      affectedModule: state.affectedModule,
      uniqueId: state.uniqueId,
    }
  }

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = {...state}
    setInitialState({
      ...prevState,
      [key]: value,
    })
  }

  useImperativeHandle(ref, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <div style={{flexGrow: 1.2}}>
        <Form.DateRangePicker
          id="dateRange"
          rangePickerProps={{
            onChange: handleOnChange,
            value: state.dateRange,
          }}
        />
      </div>
      <Form.Input value={state.modifiedBy} name="modifiedBy" id="modifiedBy" placeholder="Search By Name" onChange={handleOnChange} isDark />
      <Form.Select
        value={state.affectedModule}
        options={affectedModuleOptions}
        placeholder="Select Module"
        id="affectedModule"
        onChange={handleOnChange}
        noMargin
        isDark
      />
      <Form.Input value={state.uniqueId} name="uniqueId" id="uniqueId" placeholder="Unique ID" onChange={handleOnChange} isDark />
    </DataTable.Filter>
  )
})

export default ActivityLogFilter
