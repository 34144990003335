import dayjs from 'dayjs'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import Button from 'lib/components/Button'
import Appconfig from 'app/common/helpers/AppConfig'

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Name',
    id: 'promotionName',
    textAlign: 'left',
    width: '30%',
  },
  {
    title: 'Starts',
    id: 'startDate',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Ends',
    id: 'endDate',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Actions',
    id: 'actions',
    textAlign: 'center',
    width: '30%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  handleSelect(rowIndex: number): void
  promotionsResponse: ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]>
}

const SearchResult: React.FC<SearchResultProps> = ({isFetching, promotionsResponse, handleSelect}) => {
  const renderRowActions = (rowIndex: number) => {
    return (
      <Button type="primary" onClick={() => handleSelect(rowIndex)}>
        Select
      </Button>
    )
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (promotionsResponse && promotionsResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = promotionsResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          promotionName: item.promotionName,
          type: item.promotionType ?? '-',
          startDate: item.startDateTime ? dayjs(item.startDateTime).format(Appconfig.DATE_FORMAT_SLASH) : '-',
          endDate: item.endDateTime ? dayjs(item.endDateTime).format(Appconfig.DATE_FORMAT_SLASH) : '-',
          status: item.promotionStatusCode ?? '-',
          actions: renderRowActions(index),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }
  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
    </>
  )
}

export default SearchResult