import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import {Modal} from 'antd'
import PointHeader from './PointHeader'
import {MarginBottomWrapper, ModalTitle, Seperator, StyledModalContent} from 'app/common/components/Styled/common.styled'
import Typography from 'lib/components/Typography'
import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import LoadingPanel from 'lib/components/LoadingPanel'
import ReviewPoint from './ReviewPoint'
import IconSuccess from 'app/common/icons/IconSuccess'
import {useAuth} from 'app/pages/Auth/AuthContext'
// import {POINT_CREATE, POINT_EDIT} from 'app/common/helpers/UserFunctions'
import {getPointRequestObj, initialPointRequestObj, updatePointRequestObj} from './util'
import PointService from 'app/services/PointService'
import NewPointForm, {PointRefType, AddPointType} from './NewPointForm'
import {NewPointFooter, PointFormBodyContainer} from '../PointsMaster.styled'
import {FormTypes} from 'lib/components/Form/Form'
import {POINT_CREATE, POINT_EDIT} from 'app/common/helpers/UserFunctions'

interface AddNewPointProps {
  isViewOnly?: boolean
}

// interface AddPointType{
//   pointTypeName:string,
//   pointTypeCode:string,
//   isredeemable:boolean
// }

export type AddNewPointParam = {
  pointId: string | 'new' | 'view'
}

const AddNewPoint: React.FC<AddNewPointProps> = ({isViewOnly}) => {
  let pointData = getPointRequestObj
  const {pointId} = useParams<AddNewPointParam>()
  const actualPointId = atob(pointId || '')
  const pointFormRef = useRef<PointRefType>(null)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPoint, setLoadingPoint] = useState(false)
  const [individualPoint, setIndividualPoint] = useState<PointType.PointMasterResponseBL>()
  const [activeStep, setActiveStep] = useState(pointId === 'new' ? 0 : 1)
  const [processing, setProcessing] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [currencies, setCurrencies] = useState<FormTypes.Options[]>([])
  const [pointTypes, setPointTypes] = useState<AddPointType[]>([])
  const allowBack = activeStep === 1
  const isEdit = pointId !== 'new'
  const {roles} = useAuth()
  console.log(roles)
  const showEditPoint = roles.includes(POINT_EDIT)
  const showCreatePoint = roles.includes(POINT_CREATE)

  useEffect(() => {
    PointService.getPointTypeCodes({pointTypeCode: undefined}).then((res) => {
      const {data = []} = res.data
      const formattedPointTypes: AddPointType[] = data.map((item) => ({
        pointTypeCode: item.pointTypeCode,
        pointTypeName: item.pointTypeName,
        isRedemptionPointType: item.isRedemptionPointType,
      }))
      setPointTypes(formattedPointTypes)
    })
    PointService.getCurrencies({currencyCode: undefined}).then((res) => {
      const {data = []} = res.data
      const formattedCurrencies: FormTypes.Options[] = data.map((item) => ({
        label: item.name,
        value: item.code,
      }))
      setCurrencies(formattedCurrencies)
    })
  }, [])

  useEffect(() => {
    if (isEdit) {
      setLoadingPoint(true)
      PointService.getAllPoints({pointCode: actualPointId})
        .then((res) => {
          const data = res.data.data[0]
          setIndividualPoint(data)
          updatePointRequestObj(data)
        })
        .finally(() => {
          setLoadingPoint(false)
        })
    }
  }, [actualPointId])

  const handleCancelConfirm = () => {
    updatePointRequestObj(initialPointRequestObj)
    navigate(-1)
  }

  const handleOk = () => {
    setOpen(false)
    handleCancelConfirm()
  }

  const handleCancelNewPoint = () => {
    if (isViewOnly === true) {
      handleCancelConfirm()
    } else {
      setOpen(true)
    }
  }
  const handleCancelModal = () => {
    if (isSaved) {
      handleOk()
    }
    setOpen(false)
  }

  const handleEditPoint = () => {
    setActiveStep(0)
  }

  const showPreview = () => {
    if (pointFormRef.current?.validate()) {
      const pointDetails = pointFormRef.current.getPointDetails()
      const {pointName, pointDescription, pointTypeCode, isRedemptionPointType, costPerPointValue, costPerPointCurrency} = pointDetails
      pointData = {
        ...pointData,
        pointName,
        pointDescription,
        pointTypeCode,
        isRedemptionPointType,
        pointCurrencyValue: [{costPerPoint: costPerPointValue, currencyCode: costPerPointCurrency}],
      }
      console.log('Points Data', pointData)
      updatePointRequestObj(pointData)
      setActiveStep(1)
    }
  }

  const handleCompleted = () => {
    setOpen(false)
    setIsSaved(false)
    updatePointRequestObj(initialPointRequestObj)
    navigate(`/program/points`)
  }

  const submitForApproval = () => {
    setLoading(true)
    setProcessing(true)
    setOpen(true)
    PointService.savePoint(getPointRequestObj, {
      pointCode: pointId !== 'new' ? getPointRequestObj.pointCode : undefined,
    })
      .then((res) => {
        const {data} = res.data
        setIndividualPoint(data)
        updatePointRequestObj(data)
        setIsSaved(true)
      })
      .catch(() => {
        setOpen(false)
      })
      .finally(() => {
        setLoading(false)
        setProcessing(false)
      })
  }

  const renderSaveButton = () => {
    return (
      <Button
        loading={loading}
        onClick={activeStep === 0 ? showPreview : submitForApproval}
        type="primary"
        disabled={isEdit && !individualPoint}
        $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
      >
        {activeStep === 1 ? (pointId === 'new' ? 'Save and Create Point' : 'Submit') : 'Preview'}
      </Button>
    )
  }

  const renderPointsFooter = () => {
    return (
      <NewPointFooter>
        {allowBack && showEditPoint && isEdit && individualPoint ? (
          <Button type="primary" onClick={handleEditPoint}>
            Edit
          </Button>
        ) : (
          <></>
        )}

        {((showCreatePoint && !isViewOnly) || showEditPoint) && renderSaveButton()}
      </NewPointFooter>
    )
  }

  const renderModalBody = () => {
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (isSaved) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              {pointId === 'new' ? 'New Point Created' : 'New Point Edited'}
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                The new Point named <b>{individualPoint?.pointName}</b> has been {pointId === 'new' ? ' Created' : 'Edited'} .
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">Are you sure you want to cancel adding {getPointRequestObj.pointName}?</Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Continue with cancel
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  const renderPointBody = () => {
    if (loadingPoint) {
      return <LoadingPanel />
    }
    if (pointId !== 'new' && !individualPoint) {
      return <div>No Point found with {actualPointId}</div>
    }
    if (activeStep === 0) {
      return <NewPointForm ref={pointFormRef} currencyOptions={currencies} pointTypeOptions={pointTypes} />
    }
    return <ReviewPoint isViewOnly={isViewOnly} handleEdit={handleEditPoint} showEditPoint={showEditPoint} />
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs
          backLinkLabel="Points Master"
          backLinkPath={`/program/points`}
          activePageLabel={pointId === 'new' ? 'Add New Point' : 'Point Details'}
        />
        <Button type="link" onClick={handleCancelNewPoint}>
          {pointId === 'new' ? 'Cancel New Point' : 'Back'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <PointHeader activeStep={activeStep} />
      {renderPointsFooter()}
      <div>
        {/* {activeStep === 1 && (
          <ReviewHeader>
            <Typography.Title level={2}>Preview</Typography.Title>
            {allowBack && showEditPoint && isEdit && individualPoint ? (
              <ExtraPaddedButton type="primary" onClick={handleEditPoint}>
                Edit
              </ExtraPaddedButton>
            ) : (
              <></>
            )}
          </ReviewHeader>
        )} */}
        <PointFormBodyContainer>{renderPointBody()}</PointFormBodyContainer>
      </div>
      {renderPointsFooter()}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null} maskClosable={false}>
          {renderModalBody()}
        </Modal>
      )}
    </div>
  )
}

export default AddNewPoint
