import Colors from "styles/Colors"

const IconUpload = ({size = 16, color = Colors.PRIMARY}: IconType.Props) => {
  return (
    <svg width={size} height={size}>
      <g transform="matrix(1 0 0 1 -92 -388 )">
        <path
          d="M 10 11.25  C 10 11.665625  9.665625 12  9.25 12  L 6.75 12  C 6.334375 12  6 11.665625  6 11.25  L 6 6  L 3.259375 6  C 2.703125 6  2.425 5.328125  2.81875 4.934375  L 7.571875 0.178125  C 7.80625 -0.056249999999999994  8.190625 -0.056249999999999994  8.425 0.178125  L 13.18125 4.934374999999999  C 13.575000000000001 5.328124999999999  13.296875 5.999999999999999  12.740625 5.999999999999999  L 10 5.999999999999999  L 10 11.25  Z M 15.25 11  C 15.665625 11  16 11.334375  16 11.75  L 16 15.25  C 16 15.665625  15.665625 16  15.25 16  L 0.75 16  C 0.334375 16  0 15.665625  0 15.25  L 0 11.75  C 0 11.334375  0.334375 11  0.75 11  L 5 11  L 5 11.25  C 5 12.215625  5.784375 13  6.75 13  L 9.25 13  C 10.215625 13  11 12.215625  11 11.25  L 11 11  L 15.25 11  Z M 11.5 15.125  C 11.84375 15.125  12.125 14.84375  12.125 14.5  C 12.125 14.15625  11.84375 13.875  11.5 13.875  C 11.15625 13.875  10.875 14.15625  10.875 14.5  C 10.875 14.84375  11.15625 15.125  11.5 15.125  Z M 13.5 15.125  C 13.84375 15.125  14.125 14.84375  14.125 14.5  C 14.125 14.15625  13.84375 13.875  13.5 13.875  C 13.15625 13.875  12.875 14.15625  12.875 14.5  C 12.875 14.84375  13.15625 15.125  13.5 15.125  Z "
          fill-rule="nonzero"
          fill={color}
          stroke="none"
          transform="matrix(1 0 0 1 92 388 )"
        />
      </g>
    </svg>
  )
}

export default IconUpload
