import Colors from 'styles/Colors'

const IconCalendar = ({size = 22, color = Colors.PRIMARY}: IconType.Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 26" fill="none">
      <path
        d="M0 22.9062C0 24.2002 1.0498 25.25 2.34375 25.25H19.5312C20.8252 25.25 21.875 24.2002 21.875 22.9062V9.625H0V22.9062ZM15.625 13.3359C15.625 13.0137 15.8887 12.75 16.2109 12.75H18.1641C18.4863 12.75 18.75 13.0137 18.75 13.3359V15.2891C18.75 15.6113 18.4863 15.875 18.1641 15.875H16.2109C15.8887 15.875 15.625 15.6113 15.625 15.2891V13.3359ZM15.625 19.5859C15.625 19.2637 15.8887 19 16.2109 19H18.1641C18.4863 19 18.75 19.2637 18.75 19.5859V21.5391C18.75 21.8613 18.4863 22.125 18.1641 22.125H16.2109C15.8887 22.125 15.625 21.8613 15.625 21.5391V19.5859ZM9.375 13.3359C9.375 13.0137 9.63867 12.75 9.96094 12.75H11.9141C12.2363 12.75 12.5 13.0137 12.5 13.3359V15.2891C12.5 15.6113 12.2363 15.875 11.9141 15.875H9.96094C9.63867 15.875 9.375 15.6113 9.375 15.2891V13.3359ZM9.375 19.5859C9.375 19.2637 9.63867 19 9.96094 19H11.9141C12.2363 19 12.5 19.2637 12.5 19.5859V21.5391C12.5 21.8613 12.2363 22.125 11.9141 22.125H9.96094C9.63867 22.125 9.375 21.8613 9.375 21.5391V19.5859ZM3.125 13.3359C3.125 13.0137 3.38867 12.75 3.71094 12.75H5.66406C5.98633 12.75 6.25 13.0137 6.25 13.3359V15.2891C6.25 15.6113 5.98633 15.875 5.66406 15.875H3.71094C3.38867 15.875 3.125 15.6113 3.125 15.2891V13.3359ZM3.125 19.5859C3.125 19.2637 3.38867 19 3.71094 19H5.66406C5.98633 19 6.25 19.2637 6.25 19.5859V21.5391C6.25 21.8613 5.98633 22.125 5.66406 22.125H3.71094C3.38867 22.125 3.125 21.8613 3.125 21.5391V19.5859ZM19.5312 3.375H17.1875V1.03125C17.1875 0.601562 16.8359 0.25 16.4062 0.25H14.8438C14.4141 0.25 14.0625 0.601562 14.0625 1.03125V3.375H7.8125V1.03125C7.8125 0.601562 7.46094 0.25 7.03125 0.25H5.46875C5.03906 0.25 4.6875 0.601562 4.6875 1.03125V3.375H2.34375C1.0498 3.375 0 4.4248 0 5.71875V8.0625H21.875V5.71875C21.875 4.4248 20.8252 3.375 19.5312 3.375Z"
        fill={color}
      />
    </svg>
  )
}

export default IconCalendar
