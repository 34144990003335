import React, {useRef} from 'react'
import Button from 'lib/components/Button'
import {useNavigate, useSearchParams} from 'react-router-dom'
import IconAdd from 'app/common/icons/IconAdd'
import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import DataTable from 'lib/components/DataTable'
import Typography from 'lib/components/Typography'
import Colors from 'styles/Colors'
import Appconfig from 'app/common/helpers/AppConfig'
import {useEffect, useState} from 'react'
import SearchResults from './SearchResults'
import {useAuth} from 'app/pages/Auth/AuthContext'
// import { POINT_CREATE } from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'
import {IMPORT_CREATE, IMPORT_SEARCH, IMPORT_VIEW} from 'app/common/helpers/UserFunctions'
import {checkIfValidDataType, getModuleNameByImportType, getUrlAndParams} from '../importUtils'
import ImportFilter, {ResetImportFormRefType} from './ImportFilter'
import {isEmpty} from 'lodash'
import ImportExportSevice from 'app/services/ImportExportService'

const ImportSetup: React.FC = () => {
  const initialResponse: ResponseTypes.PagedResponse<ImportExportTypes.ImportDataResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const [params] = useSearchParams()
  let dataType = params.get('dataType') || ''
  const updatedDataType = checkIfValidDataType(dataType)
  const importTypeModule = getModuleNameByImportType(updatedDataType)
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)

  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const initialSearchParms: ImportExportTypes.ImportSearchParams = {importType: importTypeModule as string}
  const [isFetching, setIsFetching] = useState(true)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [isFormReset, setIsFormReset] = useState(true)
  const [importsData, setImportsData] = useState<ResponseTypes.PagedResponse<ImportExportTypes.ImportDataResponseBL[]>>(initialResponse)
  const pointFormRef = useRef<ResetImportFormRefType>(null)
  const navigate = useNavigate()
  const {roles} = useAuth()
  const showSearch = roles.includes(IMPORT_SEARCH)
  const showTable = roles.includes(IMPORT_VIEW)
  //const showCreatePoint = roles.includes(EXPORT_RECORDS) && roles.includes(IMPORT_DETAIL)
  const showCreatePoint = roles.includes(IMPORT_CREATE)

  const handleSearch = (params: ImportExportTypes.ImportSearchParams) => {
    const url = getUrlAndParams(params.importType)
    const splittedImportType = params.importType.split('_')[1]
    const updatedParams: ImportExportTypes.ImportSearchParams = {
      ...params,
      importType: splittedImportType,
    }
    if (url) {
      setIsFetching(true)
      ImportExportSevice.getImportDataList(url, updatedParams)
        .then((res) => {
          const data = res.data
          console.log('data', data)
          setImportsData(data)
        })
        .finally(() => {
          setIsFetching(false)
        })
    }
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...paginationObj})
    // updateSegmentRequestObj(initialSegmentRequestObj)
  }, [])

  const handleFilter = (data: ImportExportTypes.ImportFilterState) => {
    const filterParms: ImportExportTypes.ImportSearchParams = {...filterSearch}
    filterParms.importBatchCode = data.fileName
    filterParms.importType = data.importType
    filterParms.status = data.importState || undefined
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const handleResetSearch = () => {
    pointFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const handleImportNewData = () => {
    navigate(`/program/import/new`)
  }

  const handleRefresh = (value: boolean, fileName: string) => {}

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }

    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const {count = 0, pageIndex = 0} = importsData.pageInfo
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const renderImportBody = () => {
    if ((showSearch && showTable) || showCreatePoint) {
      return (
        <DataTable>
          {showSearch && showTable && <ImportFilter ref={pointFormRef} isFetching={isFetching} handleFilter={handleFilter} />}
          <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
          {showTable && (
            <>
              <SearchResults isFetching={isFetching} importsDataResponse={importsData} handleRefresh={handleRefresh} />
              <DataTable.Pagination
                onChange={handleOnChange}
                current={pageIndex}
                pageSizeOptions={pageSizeOptions}
                total={count}
                defaultPageSize={initialPaginationObj.PageCount}
                pageSize={paginationObj.PageCount}
                showSizeChanger
              />
            </>
          )}
        </DataTable>
      )
    }
    return <NoPermission />
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Import Data</Typography.Title>
        {showCreatePoint && (
          <Button onClick={handleImportNewData} type="primary" $startIcon={<IconAdd color={Colors.WHITE} size={20} />}>
            Import New Data
          </Button>
        )}
      </StyledHeader>
      {renderImportBody()}
    </section>
  )
}

export default ImportSetup
