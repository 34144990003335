import DataTable from 'lib/components/DataTable'
import Appconfig from 'app/common/helpers/AppConfig'
import BookingService from 'app/services/BookingService'
import {useEffect, useState} from 'react'
import convertDateToString from 'utils/convertDateToString'
import {Modal, Tooltip} from 'antd'
import Button from 'lib/components/Button'
import IconEye from 'app/common/icons/IconEye'
import Typography from 'lib/components/Typography'
import {StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import {ModalTitle, StyledModalContent, UrlContainer} from 'app/common/components/Styled/common.styled'
import {
  BoldLabel,
  ProductNameStyled,
  TableFilterOptionWrapper,
  TransactionBasicInfo,
  TransactionInfoBlock,
} from '../ServiceActions/ServiceActions.styled'
import dayjs from 'dayjs'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {MEMBER_DETAIL_TRANSACTION_DETAIL} from 'app/common/helpers/UserFunctions'
import Form from 'lib/components/Form'
import {FormTypes} from 'lib/components/Form/Form'
// import PointService from 'app/services/PointService'
import {PointTypeData} from '../index'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Date',
    id: 'date',
    textAlign: 'left',
    width: '14%',
  },
  {
    title: 'Activity Type',
    id: 'activityType',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Description',
    id: 'description',
    textAlign: 'left',
    width: '27%',
  },
  {
    title: 'Ticket No',
    id: 'uuid',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: '+/- POINTS',
    id: 'points',
    textAlign: 'center',
    width: '12%',
  },
  {
    title: 'Point Type',
    id: 'pointsBalance',
    textAlign: 'center',
    width: '12%',
  },
  {
    title: '',
    id: 'actions',
    textAlign: 'right',
    width: '5%',
  },
]

interface TransactionProps {
  pageSize?: number

  pageSizeOptions?: number[]
  membershipCardNumber: string | undefined
  memberBalance: number
  isMemberUpdate: boolean
  pointTypeOptions: PointTypeData[]
}

const Transactions: React.FC<TransactionProps> = ({
  pageSize = Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
  pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS,
  membershipCardNumber,
  isMemberUpdate,
  pointTypeOptions = [],
}) => {
  const initialVoucherResponse: ResponseTypes.PagedResponse<TransactionTypes.TransactionDataBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }

  let updatedPointTypeOptions: FormTypes.Options[] = pointTypeOptions.map((item) => ({
    label: item.pointTypeName,
    value: item.pointTypeCode,
  }))
  updatedPointTypeOptions = [{label: 'All', value: 'all'}, ...updatedPointTypeOptions]

  const {roles} = useAuth()
  const memberDetailTransactionDetail = roles.includes(MEMBER_DETAIL_TRANSACTION_DETAIL)
  const [isFetching, setIsFetching] = useState(true)
  const [pointType, setPointType] = useState<string>('All')
  const [transactionResponse, setTransactionResponse] =
    useState<ResponseTypes.PagedResponse<TransactionTypes.TransactionDataBL[]>>(initialVoucherResponse)
  const [selTransaction, setSelTransaction] = useState<TransactionTypes.TransactionDataBL>()
  const [open, setOpen] = useState(false)
  const paginationObj: TransactionTypes.TransactionDetail = {
    PageCount: pageSize,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
    MembershipCode: membershipCardNumber,
  }

  // useEffect(() => {
  //   PointService.getPointTypeCodes({}).then((res) => {
  //     const data = res.data.data
  //     const formattedOptions = data.map((item) => ({
  //       label: item.pointTypeName,
  //       value: item.pointTypeCode,
  //     }))
  //     setPointTypeOptions([{label: 'All', value: 'all'}, ...formattedOptions])
  //   })
  // }, [])

  const getTransactions = (params: VoucherTypes.VocherDetail) => {
    setIsFetching(true)
    BookingService.bookingAggregatedSearch({...params, Strict: true})
      .then((res) => {
        const data = res.data
        // console.log(data)
        setTransactionResponse(data)
      })
      .finally(() => {
        setIsFetching(false)
        //memberBalanceUpdate(false)
      })
  }
  const handleView = (item: TransactionTypes.TransactionDataBL) => {
    setSelTransaction(item)
    setOpen(true)
  }

  const renderActions = (item: TransactionTypes.TransactionDataBL) => {
    const IsEyeButtonDisabled = item && item.records && item?.records[0]?.rewardDetails[0]?.isReferralType

    return (
      <>
        {memberDetailTransactionDetail &&
          (IsEyeButtonDisabled ? (
            <Button shape="circle" size="small" ghost icon={<IconEye size={16} />} onClick={() => handleView(item)} disabled={IsEyeButtonDisabled} />
          ) : (
            <Tooltip title="View">
              <Button
                shape="circle"
                size="small"
                ghost
                icon={<IconEye size={16} />}
                onClick={() => handleView(item)}
                disabled={IsEyeButtonDisabled}
              />
            </Tooltip>
          ))}
      </>
    )
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (transactionResponse && transactionResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = transactionResponse
      console.log('pointType', pointType)
      data.map((item) => {
        const obj: DataTableTypes.Row = {
          activityType: item.bookingTypeCode,
          uuid: item.transactionCode,
          date: convertDateToString(item.bookingDate),
          pointsBalance: `${
            pointType.toUpperCase() === 'ALL'
              ? `${
                  item.pointTypeCode === null
                    ? ''
                    : updatedPointTypeOptions.filter((i) => i.value?.toString().toUpperCase() === item.pointTypeCode.toUpperCase())[0]?.label
                }`
              : item.balance
          }`,
          points: item.totalPoint,
          description: item.records && item.records.length && item.records[0].bookingDescription,
          actions: renderActions(item),
        }
        dataTableRows.push(obj)
        return null
      })
      return dataTableRows
    }
    return []
  }
  const handleOnChange = (page: number, pageSize: number) => {
    getTransactions({...paginationObj, PageCount: pageSize, PageNumber: page})
  }

  const handleCancelModal = () => {
    setOpen(false)
  }

  const handlePointTypeChange = (value: FormTypes.ValueType, key: string) => {
    setPointType(value as string)
    const updatedPointType = value === 'all' ? undefined : value
    dataTableColumns = dataTableColumns.map((item) => {
      if (item.id === 'pointsBalance') {
        return {
          ...item,
          title: value === 'all' ? 'Point Type' : 'Points Balance',
        }
      }
      return item
    })
    getTransactions({...paginationObj, PointTypeCode: updatedPointType as string})
  }

  useEffect(() => {
    getTransactions(paginationObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMemberUpdate])

  const {pageIndex, count} = transactionResponse.pageInfo

  const renderFilter = (): React.ReactNode => {
    return (
      <TableFilterOptionWrapper>
        <Form.Select id="PointTypeCode" value={pointType} options={updatedPointTypeOptions} onChange={handlePointTypeChange} />
      </TableFilterOptionWrapper>
    )
  }

  const checkIfProductNameExist = (item: TransactionTypes.TransactionDataBL): boolean => {
    const isProductNameExist = item.records[0].rewardDetails.some((item) => item.productName !== null)
    return isProductNameExist
  }

  const renderModal = (item: TransactionTypes.TransactionDataBL | undefined) => {
    if (!item) {
      return
    }

    const record = item && item.records && item.records.length > 0 ? item.records[0] : null
    if (
      item.bookingTypeCode.toUpperCase() === 'MEMBERSHIPENROLLMENT' ||
      item.bookingTypeCode.toUpperCase() === 'MEMBERACCOUNTMERGE' ||
      item.bookingTypeCode.toUpperCase() === 'MEMBERSHIPUPDATE' ||
      item.bookingTypeCode.toUpperCase() === 'MEMBERTIERCHANGE' ||
      item.bookingTypeCode.toUpperCase() === 'MEMBERSTATUSCHANGE' ||
      item.bookingTypeCode.toUpperCase() === 'CONVERT' ||
      item.bookingTypeCode.toUpperCase() === 'OPTINTOAPROMOTION'
    ) {
      return (
        <StyledModalContent align="flex-start">
          <ModalTitle>
            <Typography.Title level={2}>Account Activity</Typography.Title>
          </ModalTitle>
          {record && record.bookingDescription && (
            <TransactionBasicInfo noFlex>
              <StyledReviewLabel>Description</StyledReviewLabel>
              {<StyledReviewValue>{record.bookingDescription}</StyledReviewValue>}
            </TransactionBasicInfo>
          )}
          <TransactionBasicInfo noFlex>
            <StyledReviewLabel>Reason</StyledReviewLabel>
            <StyledReviewValue>{item.bookingTypeCode}</StyledReviewValue>
          </TransactionBasicInfo>

          <TransactionBasicInfo>
            <TransactionInfoBlock>
              <StyledReviewLabel>Date</StyledReviewLabel>
              <StyledReviewValue>{dayjs(record?.createdOn).format(Appconfig.DATE_FORMAT_SLASH)} </StyledReviewValue>
            </TransactionInfoBlock>
            <TransactionInfoBlock>
              <StyledReviewLabel>Time</StyledReviewLabel>
              <StyledReviewValue>{dayjs(record?.createdOn).format(Appconfig.DATE_TIME_SERVER).slice(11)} </StyledReviewValue>
            </TransactionInfoBlock>
          </TransactionBasicInfo>
          <TransactionBasicInfo>
            <TransactionInfoBlock fullwidth>
              <StyledReviewLabel>Admin User Name</StyledReviewLabel>
              <StyledReviewValue>{item.createdBy}</StyledReviewValue>
            </TransactionInfoBlock>
          </TransactionBasicInfo>
        </StyledModalContent>
      )
    } else if (item.bookingTypeCode.toUpperCase() === 'REWARD') {
      return (
        <StyledModalContent align="flex-start">
          <ModalTitle>
            <Typography.Title level={2}>Points Earned</Typography.Title>
          </ModalTitle>

          <TransactionBasicInfo>
            <TransactionInfoBlock>
              <StyledReviewLabel>Date</StyledReviewLabel>
              <StyledReviewValue>{dayjs(item?.createdOn).format(Appconfig.DATE_FORMAT_SLASH)} </StyledReviewValue>
            </TransactionInfoBlock>
            <TransactionInfoBlock>
              <StyledReviewLabel>Time</StyledReviewLabel>
              <StyledReviewValue>{dayjs(item?.createdOn).format(Appconfig.DATE_TIME_SERVER).slice(11)} </StyledReviewValue>
            </TransactionInfoBlock>
          </TransactionBasicInfo>

          <TransactionBasicInfo>
            <TransactionInfoBlock fullwidth>
              <ProductNameStyled>
                Transaction ID{' '}
                <BoldLabel>
                  <UrlContainer>#{item.transactionCode}</UrlContainer>
                </BoldLabel>
              </ProductNameStyled>
            </TransactionInfoBlock>
            <TransactionInfoBlock fullwidth>
              <ProductNameStyled>
                Total Transaction Value&nbsp;
                <BoldLabel>
                  {item?.records[0]?.rewardDetails[0]?.promotionType?.toUpperCase() === 'TRANSACTION'
                    ? `${item?.records[0]?.value === 0 ? `n/a` : `$${item?.records[0]?.value}`}`
                    : `n/a`}
                </BoldLabel>
              </ProductNameStyled>
            </TransactionInfoBlock>
          </TransactionBasicInfo>

          <TransactionBasicInfo noBorder>
            {checkIfProductNameExist(item) && (
              <TransactionInfoBlock>
                <ProductNameStyled>Item</ProductNameStyled>
              </TransactionInfoBlock>
            )}

            <TransactionInfoBlock>
              <ProductNameStyled>Promotion</ProductNameStyled>
            </TransactionInfoBlock>
            {/* {pointTypeOptions
              .filter((item) => item.value !== 'all')
              .map((item) => (
                <TransactionInfoBlock>
                  <ProductNameStyled>{item.label}</ProductNameStyled>
                </TransactionInfoBlock>
              ))} */}
            <TransactionInfoBlock>
              <ProductNameStyled>Points Balance</ProductNameStyled>
            </TransactionInfoBlock>
            {/* <TransactionInfoBlock>
              <ProductNameStyled>
                {pointTypeOptions.filter((item) => item.value.toString().toUpperCase() === 'STATUS POINT')[0].label}
              </ProductNameStyled>
            </TransactionInfoBlock> */}
          </TransactionBasicInfo>
          {item?.records.map((record, indx) => {
            // const reward = record.rewardDetails.length > 0 ? record.rewardDetails[0] : null

            return (
              <>
                {record.rewardDetails.map((rec, i) => (
                  <TransactionBasicInfo noBorder={indx === 0 && i === 0}>
                    {checkIfProductNameExist(item) && (
                      <TransactionInfoBlock>
                        <StyledReviewValue>{rec.productName} </StyledReviewValue>
                      </TransactionInfoBlock>
                    )}
                    <TransactionInfoBlock>
                      <StyledReviewLabel>
                        {rec.promotionName}
                        <br />
                        {rec.promotionCode === null ? '' : '('}
                        {rec.promotionCode}
                        {rec.promotionCode === null ? '' : ')'}
                      </StyledReviewLabel>
                    </TransactionInfoBlock>
                    <TransactionInfoBlock textAlign="center">
                      <StyledReviewValue>+{rec.points}</StyledReviewValue>
                    </TransactionInfoBlock>
                    {/* <TransactionInfoBlock textAlign="center">
                      <StyledReviewValue>{rec.pointTypeCode.toUpperCase() === 'STATUS POINT' ? <>+{rec.points}</> : <>-</>}</StyledReviewValue>
                    </TransactionInfoBlock> */}
                  </TransactionBasicInfo>
                ))}
              </>
            )
          })}

          <TransactionBasicInfo noBorder>
            <TransactionInfoBlock fullwidth highlight textAlign="end">
              <StyledReviewLabel>Total Points Earned</StyledReviewLabel>
              <StyledReviewValue>+{item.totalPoint}</StyledReviewValue>
            </TransactionInfoBlock>
          </TransactionBasicInfo>
        </StyledModalContent>
      )
    } else if (
      item.bookingTypeCode.toUpperCase() === 'REDEEM' ||
      item.bookingTypeCode.toUpperCase() === 'ADJUST' ||
      item.bookingTypeCode.toUpperCase() === 'EXPIRY' ||
      item.bookingTypeCode.toUpperCase() === 'REDEEMREVERSAL'
    ) {
      return (
        <StyledModalContent align="flex-start">
          <ModalTitle>
            <Typography.Title level={2}>
              {' '}
              {item.bookingTypeCode.toUpperCase() === 'ADJUST' ? 'Adjustment' : 'Redemption'} Transaction
            </Typography.Title>
          </ModalTitle>
          {record && record.bookingDescription && (
            <TransactionBasicInfo noFlex>
              <StyledReviewLabel>Description</StyledReviewLabel>
              {<StyledReviewValue>{record.bookingDescription}</StyledReviewValue>}
            </TransactionBasicInfo>
          )}
          <TransactionBasicInfo noFlex>
            <StyledReviewLabel>Reason</StyledReviewLabel>
            <StyledReviewValue>{item.bookingTypeCode}</StyledReviewValue>
          </TransactionBasicInfo>
          <TransactionBasicInfo>
            <TransactionInfoBlock>
              <StyledReviewLabel>Date</StyledReviewLabel>
              <StyledReviewValue>{dayjs(item?.createdOn).format(Appconfig.DATE_FORMAT_SLASH)} </StyledReviewValue>
            </TransactionInfoBlock>
            <TransactionInfoBlock>
              <StyledReviewLabel>Time</StyledReviewLabel>
              <StyledReviewValue>{dayjs(item?.createdOn).format(Appconfig.DATE_TIME_SERVER).slice(11)} </StyledReviewValue>
            </TransactionInfoBlock>
          </TransactionBasicInfo>
          <TransactionBasicInfo>
            <TransactionInfoBlock>
              <StyledReviewLabel> Point Type </StyledReviewLabel>
              <StyledReviewValue>{updatedPointTypeOptions.filter((x) => x.value === item.pointTypeCode)[0].label}</StyledReviewValue>
            </TransactionInfoBlock>
          </TransactionBasicInfo>
          <TransactionBasicInfo>
            <TransactionInfoBlock>
              <StyledReviewLabel> Points </StyledReviewLabel>
              <StyledReviewValue>{item.totalPoint}</StyledReviewValue>
            </TransactionInfoBlock>
          </TransactionBasicInfo>

          <TransactionBasicInfo>
            <TransactionInfoBlock fullwidth>
              <StyledReviewLabel>Admin User Name</StyledReviewLabel>
              <StyledReviewValue>{item.createdBy}</StyledReviewValue>
            </TransactionInfoBlock>
          </TransactionBasicInfo>
        </StyledModalContent>
      )
    }

    return
  }

  return (
    <div>
      <DataTable>
        <DataTable.TotalResult noOfResults={count} showFilter={true} filter={renderFilter()} />
        <DataTable.Header columns={dataTableColumns} />
        <DataTable.Rows loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
        <DataTable.Pagination
          onChange={handleOnChange}
          current={pageIndex}
          pageSizeOptions={pageSizeOptions}
          total={count}
          defaultPageSize={pageSize}
          showSizeChanger
        />
      </DataTable>
      {open && selTransaction && (
        <Modal open={open} onCancel={handleCancelModal} footer={null}>
          {renderModal(selTransaction)}
        </Modal>
      )}
    </div>
  )
}

export default Transactions
