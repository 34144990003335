import React from 'react'
import Button from 'lib/components/Button'
import SectionTitle from '../../../common/components/SectionTitle'
import {useNavigate} from 'react-router-dom'

const CurrencySetup: React.FC = () => {
  const navigate = useNavigate()

  const navigateTo = (): void => {
    navigate(-1)
  }

  return (
    <section>
      <SectionTitle>Currency Setup</SectionTitle>
      <Button onClick={navigateTo}>Back</Button>
    </section>
  )
}

export default CurrencySetup
