import IconChevronRight from 'app/common/icons/IconChevronRight'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import { FormTypes } from 'lib/components/Form/Form'
import Popover from 'lib/components/Popover'
import {useNavigate} from 'react-router-dom'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Tag Name',
    id: 'voucherTagName',
    textAlign: 'left',
    width: '30%',
  },
  {
    title: 'Tag ID',
    id: 'voucherTagCode',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Partner Code',
    id: 'partnerCode',
    textAlign: 'center',
    width: '20%',
  },
  // {
  //   title: 'ON/OFF',
  //   id: 'enabled',
  //   textAlign: 'left',
  //   width: '15%',
  // },
  {
    title: '',
    id: 'actions',
    textAlign: 'right',
    width: '35%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  tagResponse: ResponseTypes.PagedResponse<TagTypes.TagResponseBL[]>
  partnerOptions: FormTypes.Options[]
}

const SearchResults = ({isFetching, tagResponse, partnerOptions}: SearchResultProps) => {
  const navigate = useNavigate()
  // const {roles} = useAuth()
  // console.log(roles)
  const showTagDetail = true // roles.includes(POINT_DETAIL) || roles.includes(POINT_ACTION_DETAIL)
  const showEditTag =  true // roles.includes(POINT_EDIT)

  dataTableColumns = dataTableColumns.map((item) => {
    if (item.id === 'enabled' && !showEditTag) {
      return {...item, title: ''}
    }
    return item
  })

  const handleView = (rowIndex: number) => {
    const voucherTagCode = tagResponse.data[rowIndex].voucherTagCode
    const encodedTagCode = btoa(voucherTagCode)
    navigate(`/program/tag/${encodedTagCode}`)
  }

  const getPartnerNameFromPartnerCode = (code: string): string => {
    return partnerOptions.find(item => item.value === code)?.label as string || ''
  }

  const renderActionList = (rowIndex: number) => {
    return (
      <>
        <div>
          <Button type="text" onClick={() => handleView(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
            Details
          </Button>
        </div>
      </>
    )
  }

  const renderRowActions = (rowIndex: number) => {
    if (showTagDetail) {
      return <Popover btnContent={<IconThreeDots />} content={renderActionList(rowIndex)} />
    }
    return null
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (tagResponse && tagResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = tagResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          voucherTagName: item.voucherTagName,
          voucherTagCode: item.voucherTagCode,
          partnerCode: getPartnerNameFromPartnerCode(item.partnerCode),
          //enabled: renderEnabledToggle(index),
          actions: renderRowActions(index),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
      {/* {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {renderModalContent()}
        </Modal>
      )} */}
    </>
  )
}

export default SearchResults
