import {ThemeConfig} from 'antd'
import Colors from './Colors'

export const BASE_FONT_FAMILY = "'IBM Plex Sans', sans-serif"
const CONTROL_HEIGHT_MD = 53
const CONTROL_HEIGHT_SM = 48
const CONTROL_HEIGHT_XS = 40
const BORDER_RADIUS = 25

const antTheme: ThemeConfig = {
  token: {
    borderRadius: BORDER_RADIUS,
    colorPrimary: Colors.PRIMARY,
    colorWarning: Colors.SECONDARY,
    colorText: Colors.COLOR_TEXT,
    colorTextBase: Colors.COLOR_TEXT,
    colorTextSecondary: Colors.COLOR_TEXT_SECONDARY,
    colorBorder: Colors.PRIMARY,
    colorFill: 'rgba(5, 11, 29, 0.15)',
    colorBgSpotlight: Colors.PRIMARY_DARK,
    colorSuccess: Colors.GREEN2,
    fontSizeHeading1: 32,
    fontSizeHeading2: 24,
    fontSizeHeading3: 18,
    fontSizeHeading4: 16,
    lineHeight: 1.7142857142857142,
    lineHeightSM: 1.3333333333333333,
    lineHeightHeading1: 1.25,
    lineHeightHeading2: 1.3333333333333333,
    lineHeightHeading3: 1.5555555555555556,
    lineHeightHeading4: 1.5,
    fontFamily: BASE_FONT_FAMILY,
    colorPrimaryText: Colors.PRIMARY,
    colorTextHeading: Colors.PRIMARY,
  },
  components: {
    Typography: {
      // sizeMarginHeadingVerticalStart: '1.2em',
      // sizeMarginHeadingVerticalEnd: '0.5em',
      colorLink: Colors.PRIMARY,
      colorLinkActive: Colors.PRIMARY,
      colorTextDescription: Colors.COLOR_TEXT_SECONDARY,
      colorTextHeading: Colors.COLOR_TEXT,
      fontFamilyCode: BASE_FONT_FAMILY,
      fontWeightStrong: 700,
      linkDecoration: 'underline',
    },
    Button: {
      colorBorder: 'transparent',
      controlHeight: CONTROL_HEIGHT_MD,
      borderRadius: BORDER_RADIUS,
      colorText: Colors.PRIMARY,
      colorBgTextActive: Colors.BLUE_LIGHT,
      colorBgTextHover: Colors.BLUE_LIGHT,
      controlHeightSM: CONTROL_HEIGHT_SM,
      controlHeightXS: CONTROL_HEIGHT_XS,
      controlOutline: 'rgba(5, 145, 255, 0.1)',
     // defaultGhostBorderColor: 'transparent',
      //defaultGhostColor: Colors.PRIMARY,
      fontSizeLG: 14,
      algorithm: true,
    },
    Menu: {
      colorItemBgSelected: Colors.BLUE_LIGHT,
      colorItemTextSelected: Colors.PRIMARY,
      colorItemBg: Colors.BLUE_LIGHT,
      colorItemBgActive: Colors.BLUE_LIGHT,
      colorSubItemBg: Colors.BLUE_LIGHT,
      colorItemText: Colors.PRIMARY_DARK,
      colorItemTextHover: Colors.PRIMARY,
      colorGroupTitle: Colors.PRIMARY_DARK,
      fontFamily: BASE_FONT_FAMILY,
      fontSize: 16,
      fontSizeLG: 18,
      lineHeight: 1.3,
    },
    Pagination: {
      borderRadius: 3,
      colorBgContainer: Colors.BLUE_LIGHT,
      fontFamily: BASE_FONT_FAMILY,
      fontSize: 16,
      lineHeight: 1.3,
      controlHeight: 38,
      colorText: Colors.PRIMARY,
    },
    Checkbox: {
      borderRadiusSM: 2,
      fontFamily: BASE_FONT_FAMILY,
      controlInteractiveSize: 16,
      lineHeight: 1.4285714286,
    },
    Form: {
      fontFamily: BASE_FONT_FAMILY,
    },
    Input: {
      borderRadius: BORDER_RADIUS,
      controlHeight: CONTROL_HEIGHT_MD,
      controlHeightSM: CONTROL_HEIGHT_SM,
      controlHeightXS: CONTROL_HEIGHT_XS,
      borderRadiusSM: BORDER_RADIUS,
      colorText: Colors.PRIMARY,
      fontFamily: BASE_FONT_FAMILY,
      paddingSM: 22,
    
    },
    InputNumber: {
      controlHeight: CONTROL_HEIGHT_MD,
      controlHeightSM: CONTROL_HEIGHT_SM,
      controlHeightXS: CONTROL_HEIGHT_XS,
      borderRadius: BORDER_RADIUS,
      colorText: Colors.PRIMARY,
      fontFamily: BASE_FONT_FAMILY,
      paddingXS: 18,
      paddingSM: 18,
      controlWidth: 120,
    
    },
    Radio: {
      colorBorder: Colors.PRIMARY,
      controlOutlineWidth: 2,
      borderRadius: BORDER_RADIUS,
      wireframe: true,
    },
    Select: {
      controlHeight: CONTROL_HEIGHT_MD,
      controlHeightSM: CONTROL_HEIGHT_SM,
      controlHeightXS: CONTROL_HEIGHT_XS,
      borderRadius: BORDER_RADIUS,
      colorText: Colors.COLOR_TEXT,
      paddingSM: 25,
      fontFamily: BASE_FONT_FAMILY,
    
    },
    Switch: {
      colorPrimary: Colors.BLUE_LIGHT,
      colorBgContainer: Colors.BLUE_LIGHT,
      colorPrimaryBorder: Colors.PRIMARY,
      colorPrimaryHover: Colors.BLUE_LIGHT,
      colorWhite: Colors.PRIMARY,
      controlHeight: 27,
      colorTextTertiary: 'rgba(5, 11, 29, 0.45)',
      colorTextQuaternary: Colors.WHITE,
    
    },
    DatePicker: {
      controlHeight: CONTROL_HEIGHT_MD,
      borderRadius: BORDER_RADIUS,
      paddingSM: 25,
    },
  },
}

export default antTheme
