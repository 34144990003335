import DataTable from 'lib/components/DataTable'
import PromotionStatusService from 'app/services/PromotionStatusService'
import Form from 'lib/components/Form'
import {FormEvent, forwardRef, useImperativeHandle, useState, useEffect} from 'react'
import {FormTypes} from 'lib/components/Form/Form'

interface PromotionsFilterProps {
  handleFilter(formState: PromotionsFilterState): void
  isFetching: boolean
}

// type ValueType = string[]

export interface PromotionsFilterState {
  promotionType: string
  dateRange: string[]
  status: string
}

type KeyTypes = keyof PromotionsFilterState

const promotionOptions = [
  {label: 'Transaction', value: 'Transaction'},
  {label: 'Activity', value: 'Activity'},
  {label: 'Enrollment', value: 'Enrollment'},
  {label: 'Event', value: 'Event'},
  {label: 'Multicounter', value: 'Multicounter'},
  {label: 'Spend', value: 'Spend'},
]
let initialStatusOptions: FormTypes.Options[] = []

export interface ResetFormRefType {
  handleReset(): void
}

const PromotionsFilter = forwardRef<ResetFormRefType, PromotionsFilterProps>(({handleFilter, isFetching}, forwardedRef) => {
  const initialState: PromotionsFilterState = {
    promotionType: '',
    dateRange: [],
    status: '',
  }
  const reqStatusParams: PromotionStatusType.PromotionStatusDetails = {}

  const [state, setState] = useState<PromotionsFilterState>(initialState)
  const [statusOptions, setStatusOptions] = useState(initialStatusOptions)

  const handleReset = () => {
    setState(initialState)
  }

  useImperativeHandle(forwardedRef, () => ({handleReset}))

  const handleOnChange = (value: string & string[], key: KeyTypes) => {
    const prevState = {...state}
    prevState[key] = value
    setState(prevState)
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }
  const getStatus = (reqParams: PromotionStatusType.PromotionStatusDetails) => {
    PromotionStatusService.getStatus(reqParams).then((res) => {
      const {data} = res.data

      let statusOptionsData = data
        .filter((item, index) => data.findIndex((x) => x.promotionStatusCode.toUpperCase() === item.promotionStatusCode.toUpperCase()) === index)
        .map((item) => ({
          label: item.promotionStatusName,
          value: item.promotionStatusCode,
        }))
      setStatusOptions(statusOptionsData)
    })
  }

  useEffect(() => {
    getStatus(reqStatusParams)
  }, [])

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Select
        value={state.promotionType}
        options={promotionOptions}
        placeholder="Select promotion type"
        id="promotionType"
        onChange={handleOnChange}
        noMargin
        isDark
      />
      <Form.DateRangePicker
        id="dateRange"
        rangePickerProps={{
          onChange: handleOnChange,
          value: state.dateRange,
        }}
      />
      <Form.Select isDark value={state.status} options={statusOptions} placeholder="Select status" id="status" onChange={handleOnChange} noMargin />
    </DataTable.Filter>
  )
})

export default PromotionsFilter
