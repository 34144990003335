import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Button from 'lib/components/Button'

import DataTable from 'lib/components/DataTable'
import Appconfig from 'app/common/helpers/AppConfig'
import PromotionService from 'app/services/PromotionService'
import {StyledHeader} from './Dashboard.styled'
import PromotionsFilter, {PromotionsFilterState, ResetFormRefType} from './PromotionsFilter'
import SearchResult from './SearchResult'
import {initialPromotionRequestObj, updatePromotionRequestObj} from '../CreatePromotion/utils'
import Typography from 'lib/components/Typography'
import Colors from 'styles/Colors'
import IconAdd from 'app/common/icons/IconAdd'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {PROMOTION_CREATE, PROMOTION_SEARCH, PROMOTION_VIEW} from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'
import {Modal} from 'antd'
import {
  FullWidthCenterAlignedContainer,
  HorizontallyCenter,
  MarginBottomWrapper,
  ModalTitle,
  StyledModalContent,
} from 'app/common/components/Styled/common.styled'
import LoadingPanel from 'lib/components/LoadingPanel'
import IconSuccess from 'app/common/icons/IconSuccess'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import dayjs from 'dayjs'

const Dashboard: React.FC = () => {
  const navigate = useNavigate()
  const FilterFormRef = useRef<ResetFormRefType>(null)
  const [isFetching, setIsFetching] = useState(true)
  const {roles} = useAuth()

  const showPromotionSearch = roles.includes(PROMOTION_SEARCH)
  const showPromotionTable = roles.includes(PROMOTION_VIEW)
  const showPromotionCreate = roles.includes(PROMOTION_CREATE)

  const initialResponse: ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)

  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const initialSearchParms: PromotionsTypes.PromotionSearchParam = {}

  const [promotionData, setPromotionData] = useState<ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]>>(initialResponse)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [isFormReset, setIsFormReset] = useState<boolean>(true)
  const [selectedPromotion, setSelectedPromotion] = useState<PromotionsTypes.PromotionResponseBL>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isProcessed, setIsProcessed] = useState(false)
  const [triggerSearch, setTriggerSearch] = useState(false)

  const handleSearch = (params: PromotionsTypes.PromotionSearchParam) => {
    const reqParam: PromotionsTypes.PromotionSearchParam = {
      // IsCombinable: false,
      ...params,
    }

    setIsFetching(true)
    PromotionService.search(reqParam)
      .then((res) => {
        const data = res.data
        setPromotionData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...paginationObj})
    updatePromotionRequestObj(initialPromotionRequestObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isEmpty = (value: PromotionsTypes.PromotionSearchParam) => {
    if (value.PromotionType || value.StartDateTime || value.EndDateTime || value.PromotionStatusCode) {
      return false
    }
    return true
  }
  const handleFilter = (data: PromotionsFilterState) => {
    // setIsFetching(true)
    const filterParms: PromotionsTypes.PromotionSearchParam = {}
    filterParms.PromotionType = data.promotionType
    filterParms.PromotionStatusCode = data.status
    if (data.dateRange != null) {
      if (data.dateRange.length > 0) {
        const date = dayjs.utc(data.dateRange[0], 'MMM-DD-YYYY')
        const startOfDay = date.startOf('day').toString()
        filterParms.StartDateTime = startOfDay
      }
      if (data.dateRange.length > 1) {
        const date = dayjs.utc(data.dateRange[1], 'MMM-DD-YYYY')
        const endOfDay = date.endOf('day').toString()
        filterParms.EndDateTime = endOfDay
      }
    }

    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const handleResetSearch = () => {
    FilterFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }

    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const handleCreateNewPromotion = () => {
    navigate(`/promotions/new`)
  }

  const handleDeletePromotion = (promotion: PromotionsTypes.PromotionResponseBL): void => {
    setSelectedPromotion(promotion)
    setOpenDeleteModal(true)
  }

  const handleOk = () => {
    setIsProcessing(true)
    PromotionService.deletePromotion({PromotionCode: selectedPromotion?.promotionCode})
      .then((res) => {
        const data = res.data
        if (data) {
          setIsProcessed(true)
          setTriggerSearch(true)
        }
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const handleCancelModal = () => {
    setOpenDeleteModal(false)
    setIsProcessed(false)
    if (triggerSearch) {
      handleSearch({...filterSearch, ...paginationObj})
      setTriggerSearch(false)
    }
  }

  const {pageIndex = 0, count = 0} = promotionData.pageInfo
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const getModalContent = () => {
    if (isProcessing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (isProcessed) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Confirmation
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">{selectedPromotion?.promotionName} has been deleted.</Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="back" onClick={handleCancelModal}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper width="20%">
          <hr />
        </MarginBottomWrapper>
        <MarginBottomWrapper textAlign="center">
          <Typography.Paragraph size="medium">
            Are you sure you want to permanently delete {selectedPromotion?.promotionName || ''}?
          </Typography.Paragraph>
          <HorizontallyCenter>
            <Typography.Paragraph size="medium">This cannot be reversed.</Typography.Paragraph>
          </HorizontallyCenter>
        </MarginBottomWrapper>
        <FullWidthCenterAlignedContainer>
          <Button width="50%" type="primary" key="submit" onClick={handleOk} $endIcon={<IconArrowRightLong color={Colors.WHITE} />}>
            Delete
          </Button>
        </FullWidthCenterAlignedContainer>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Cancel
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  const renderPromotionBody = () => {
    if ((showPromotionSearch && showPromotionTable) || showPromotionCreate) {
      return (
        <DataTable>
          {showPromotionSearch && showPromotionTable && <PromotionsFilter ref={FilterFormRef} isFetching={isFetching} handleFilter={handleFilter} />}
          <DataTable.TotalResult
            resetSearch={renderResetSearch()}
            noOfResults={count}
            showSearch={showPromotionSearch && showPromotionTable}
            showTable={showPromotionTable}
          />
          {showPromotionTable && (
            <>
              <SearchResult isFetching={isFetching} promotionsResponse={promotionData} handleDeletePromotion={handleDeletePromotion} />
              <DataTable.Pagination
                onChange={handleOnChange}
                current={pageIndex}
                pageSizeOptions={pageSizeOptions}
                total={count}
                defaultPageSize={initialPaginationObj.PageCount}
                pageSize={paginationObj.PageCount}
                showSizeChanger
              />
            </>
          )}
        </DataTable>
      )
    } else {
      return <NoPermission />
    }
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Promotions</Typography.Title>
        {showPromotionCreate && (
          <Button onClick={handleCreateNewPromotion} type="primary" $startIcon={<IconAdd color={Colors.WHITE} size={20} />}>
            Create new promotion
          </Button>
        )}
      </StyledHeader>
      {renderPromotionBody()}
      {openDeleteModal && (
        <Modal open={openDeleteModal} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {getModalContent()}
        </Modal>
      )}
    </section>
  )
}

export default Dashboard
