import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const bookingAggregatedSearch = (
  params: TransactionTypes.TransactionDetail
): Promise<AxiosResponse<ResponseTypes.PagedResponse<TransactionTypes.TransactionDataBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.aggregatedSearch,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

const BookingService = {
  bookingAggregatedSearch,
}

export default BookingService
