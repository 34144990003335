import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import {NewProductFooter, ProductFormBodyContainer} from '../../ProductMaster.styled'
import {Modal} from 'antd'
import ProductHeader from './ProductHeader'
import {MarginBottomWrapper, ModalTitle, Seperator, StyledModalContent} from 'app/common/components/Styled/common.styled'
import Typography from 'lib/components/Typography'
import {getProductRequestObj, initialProductRequestObj, updateProductRequestObj} from './utils'
import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import LoadingPanel from 'lib/components/LoadingPanel'
import ReviewProduct from './ReviewProduct'
import {ProductRefType} from './ProductBasicDetailsForm'
import ProductService from 'app/services/ProductService'
import IconSuccess from 'app/common/icons/IconSuccess'
import ProductBasicDetailsForm from './ProductBasicDetailsForm'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {PRODUCT_CREATE, PRODUCT_EDIT} from 'app/common/helpers/UserFunctions'
import {FormTypes} from 'lib/components/Form/Form'
import PartnerService from 'app/services/PartnerService'

interface AddNewProductProps {
  isViewOnly?: boolean
}

export type AddNewProductParam = {
  productId: string | 'new' | 'view'
}

const AddNewProduct: React.FC<AddNewProductProps> = ({isViewOnly}) => {
  let productData = getProductRequestObj
  const {productId} = useParams<AddNewProductParam>()
  const actualProductId = atob(productId || '')
  const productFormRef = useRef<ProductRefType>(null)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [individualProduct, setIndividualProduct] = useState<ProductMasterTypes.ProductMasterResponseBL>()
  const [activeStep, setActiveStep] = useState(productId === 'new' ? 0 : 1)
  const [processing, setProcessing] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [partnerCodeOptions, setPartnerCodeOptions] = useState<FormTypes.Options[]>([])
  const [partnerLoading, setPartnerLoading] = useState(false)
  const [partnerCode, setPartnerCode] = useState('')
  const allowBack = activeStep === 1
  const isEdit = productId !== 'new'
  const {roles} = useAuth()
  const showEditProduct = roles.includes(PRODUCT_EDIT)
  const showCreateProduct = roles.includes(PRODUCT_CREATE)

  const updateRewardsReqPartnerData = (data: PartnerTypes.PartnerBL[]) => {
    const partnerCodeFromRequest = productData.partnerCode
    const defaultCode = data.find((partnerOption) => partnerOption.isDefaultPartner)
    if (partnerCodeFromRequest) {
      const hasPartnerCode = data.find((partnerOption) => partnerOption.partnerCode === partnerCodeFromRequest)
      if (!hasPartnerCode) {
        getProductRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
      }
    } else {
      getProductRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
    }
    setPartnerCode(getProductRequestObj.partnerCode)
  }

  const getPartnerCodes = () => {
    setPartnerLoading(true)
    PartnerService.getPartners()
      .then((res) => {
        const data = res.data.data
        const partnerOptions = data.map((item) => ({value: item.partnerCode, label: item.partnerName}))
        setPartnerCodeOptions(partnerOptions)
        updateRewardsReqPartnerData(data)
      })
      .finally(() => {
        setPartnerLoading(false)
      })
  }

  useEffect(() => {
    getPartnerCodes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isEdit) {
      setLoadingProduct(true)
      ProductService.getProducts({ProductCode: actualProductId})
        .then((res) => {
          const data = res.data.data[0]
          setIndividualProduct(data)
          updateProductRequestObj(data)
        })
        .finally(() => {
          setLoadingProduct(false)
        })
    }
  }, [actualProductId])

  const handleCancelConfirm = () => {
    updateProductRequestObj(initialProductRequestObj)
    navigate(-1)
  }

  const handleOk = () => {
    setOpen(false)
    handleCancelConfirm()
  }

  const handleCancelNewProduct = () => {
    if (isViewOnly === true) {
      handleCancelConfirm()
    } else {
      setOpen(true)
    }
  }
  const handleCancelModal = () => {
    if (isSaved) {
      handleOk()
    }
    setOpen(false)
  }

  const handleEditProduct = () => {
    setActiveStep(0)
  }

  const showPreview = () => {
    if (productFormRef.current?.validate()) {
      const productDetails = productFormRef.current.getProductDetails()
      productData = {
        ...productData,
        ...productDetails,
      }
      updateProductRequestObj(productData)
      setActiveStep(1)
    }
  }
  if (partnerLoading) {
    return <LoadingPanel />
  }

  const handleCompleted = () => {
    setOpen(false)
    setIsSaved(false)
    updateProductRequestObj(initialProductRequestObj)
    navigate(`/program`)
  }

  const submitForApproval = () => {
    setLoading(true)
    setProcessing(true)
    setOpen(true)
    ProductService.saveProduct(getProductRequestObj, {
      productCode: productId !== 'new' ? getProductRequestObj.productCode : undefined,
      partnerCode: productId !== 'new' ? getProductRequestObj.partnerCode : undefined,
    })
      .then((res) => {
        const {data} = res.data
        setIndividualProduct(data)
        updateProductRequestObj(data)
        setIsSaved(true)
      })
      .catch(() => {
        setOpen(false)
      })
      .finally(() => {
        setLoading(false)
        setProcessing(false)
      })
  }

  const renderSaveButton = () => {
    return (
      <Button
        loading={loading}
        onClick={activeStep === 0 ? showPreview : submitForApproval}
        type="primary"
        disabled={partnerCode === ''}
        $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
      >
        {activeStep === 1 ? (productId === 'new' ? 'Submit and Create Product' : 'Submit') : 'Preview'}
      </Button>
    )
  }

  const renderProductsFooter = () => {
    return (
      <NewProductFooter>
        {allowBack && showEditProduct ? (
          <Button type="primary" onClick={handleEditProduct}>
            Edit
          </Button>
        ) : (
          <></>
        )}

        {((showCreateProduct && !isViewOnly) || showEditProduct) && renderSaveButton()}
      </NewProductFooter>
    )
  }

  const renderModalBody = () => {
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (isSaved) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              {productId === 'new' ? 'New Product Created' : 'New Product Edited'}
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                The new product named <b>{individualProduct?.productName}</b> has been {productId === 'new' ? ' Created' : 'Edited'} .
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">Are you sure you want to cancel adding {getProductRequestObj.productName}?</Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Continue with cancel
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  const renderProductBody = () => {
    if (loadingProduct) {
      return <LoadingPanel />
    }
    if (productId !== 'new' && !individualProduct) {
      return <div>No Product found with {actualProductId}</div>
    }
    if (activeStep === 0) {
      return <ProductBasicDetailsForm ref={productFormRef} isEdit={isEdit} partnerCodeOptions={partnerCodeOptions} />
    }
    return (
      <ReviewProduct
        isViewOnly={isViewOnly}
        handleEdit={handleEditProduct}
        showEditProduct={showEditProduct}
        partnerCodeOptions={partnerCodeOptions}
      />
    )
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs
          backLinkLabel="Product Master"
          backLinkPath={`/program`}
          activePageLabel={productId === 'new' ? 'Add New Product' : 'Product Details'}
        />
        <Button type="link" onClick={handleCancelNewProduct}>
          {productId === 'new' ? 'Cancel New Product' : 'Back'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <ProductHeader activeStep={activeStep} />
      {renderProductsFooter()}
      <ProductFormBodyContainer>{renderProductBody()}</ProductFormBodyContainer>
      {renderProductsFooter()}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null} maskClosable={false}>
          {renderModalBody()}
        </Modal>
      )}
    </div>
  )
}

export default AddNewProduct
