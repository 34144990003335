import Button from 'lib/components/Button'
import {useNavigate} from 'react-router-dom'
import {UserManagementHeader} from '../UserManagementStyled'
// import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import DataTable from 'lib/components/DataTable'
import RolesFilter, {ResetFormRefType, RolesFilterState} from './RolesFilter'
import {useRef, useState, useEffect} from 'react'
import Appconfig from 'app/common/helpers/AppConfig'
// import {RolesList} from './utils'
import SearchResults from './SearchResults'
import {isEmpty} from 'lodash'
import IconAdd from 'app/common/icons/IconAdd'
import UserManagementService from 'app/services/UserManagementService'
import { useAuth } from 'app/pages/Auth/AuthContext'
import { ROLE_CREATE, ROLE_SEARCH, ROLE_VIEW } from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'

const Roles = () => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const initialUserRoleResponse: ResponseTypes.PagedResponse<UserManagementTypes.RoleType[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const navigate = useNavigate()
  const initialSearchParms: UserManagementTypes.RoleSearchParam = {
    roleCode: '',
    roleName: '',
    //category: '',
  }
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)
  const roleFilterFormRef = useRef<ResetFormRefType>(null)
  const [isFormReset, setIsFormReset] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [rolesData, setRolesData] = useState<ResponseTypes.PagedResponse<UserManagementTypes.RoleType[]>>(initialUserRoleResponse)
  const {roles} = useAuth()
  const showSearch = roles.includes(ROLE_SEARCH)
  const showTable = roles.includes(ROLE_VIEW)
  const showCreateRole = roles.includes(ROLE_CREATE)

  const handleCreateNewRole = () => {
    navigate(`/user-management/role/new`)
  }

  const handleSearch = (params: UserManagementTypes.RoleSearchParam) => {
    const reqParams: UserManagementTypes.RoleSearchParam = {
      ...params,
    }
    setIsFetching(true)
    UserManagementService.searchRoles(reqParams)
      .then((res) => {
        setRolesData(res.data)
      })
      .finally(() => {
        setIsFetching(false)
      })
    console.log(reqParams)
  }

  const handleFilter = (data: RolesFilterState) => {
    const filterParms: UserManagementTypes.RoleSearchParam = filterSearch
    console.log(filterParms)
    filterParms.roleName = data.roleName
    filterParms.roleCode = data.roleCode
    // filterParms.category = data.category
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const count = rolesData.pageInfo.count

  const handleResetSearch = () => {
    roleFilterFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
      // setRolesData(RolesList)
      // setIsFetching(false)
    }
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }
    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...initialPaginationObj})
  }, [])

  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const renderRoleBody = () => {
    if ((showSearch && showTable) || showCreateRole) {
      return (
        <DataTable>
          {showSearch && showTable && <RolesFilter ref={roleFilterFormRef} isFetching={isFetching} handleFilter={handleFilter} />}
          <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
          {showTable && (
            <>
              <SearchResults isFetching={isFetching} rolesResponse={rolesData.data} />
              <DataTable.Pagination
                onChange={handleOnChange}
                current={rolesData.pageInfo.pageIndex}
                pageSizeOptions={pageSizeOptions}
                total={rolesData.pageInfo.count}
                defaultPageSize={initialPaginationObj.PageCount}
                pageSize={paginationObj.PageCount}
                showSizeChanger
              />
            </>
          )}
        </DataTable>
      )
    }
    return (
      <NoPermission />
    )
  }

  return (
    <section>
      <UserManagementHeader>
        {showCreateRole && <Button onClick={handleCreateNewRole} type="primary" $startIcon={<IconAdd color={Colors.WHITE} size={20} />}>
          Add New Role
        </Button>}
      </UserManagementHeader>
      {renderRoleBody()}
    </section>
  )
}

export default Roles
