import Colors from 'styles/Colors'

export const IconStarFilled = ({size = 16, color = Colors.BLACK}: IconType.Props): JSX.Element => {
  return (
    <svg width={size} height={size - 1}>
      <g transform="matrix(1 0 0 1 -55 -27 )">
        <path
          d="M 8.856716417910448 0.5486381322957199  C 8.507462686567164 -0.14007782101167318  7.4955223880597 -0.14883268482490267  7.143283582089553 0.5486381322957199  L 5.1940298507462686 4.412451361867704  L 0.8328358208955223 5.034046692607004  C 0.050746268656716394 5.1449416342412455  -0.2626865671641792 6.087548638132295  0.3044776119402985 6.6274319066147855  L 3.4597014925373135 9.633268482490273  L 2.7134328358208957 13.879377431906615  C 2.5791044776119403 14.646887159533076  3.405970149253731 15.221789883268482  4.098507462686568 14.86284046692607  L 8 12.857976653696499  L 11.901492537313432 14.86284046692607  C 12.594029850746267 15.21887159533074  13.420895522388058 14.646887159533076  13.286567164179104 13.879377431906615  L 12.540298507462685 9.633268482490273  L 15.6955223880597 6.627431906614786  C 16.262686567164177 6.087548638132296  15.949253731343282 5.1449416342412455  15.167164179104475 5.034046692607005  L 10.805970149253731 4.412451361867704  L 8.856716417910448 0.5486381322957199  Z "
          fill-rule="nonzero"
          fill={color}
          stroke="none"
          transform="matrix(1 0 0 1 55 27 )"
        />
      </g>
    </svg>
  )
}
