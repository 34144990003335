import {Steps} from 'antd'
import styled from 'styled-components'
import Colors from 'styles/Colors'
import {StyledStepsProps} from './Steps'

export const StyledAntSteps = styled(Steps)<StyledStepsProps>`
  .lb-steps-item-container .lb-steps-item-icon {
    position: relative;
    border: 3px solid ${Colors.PRIMARY_DARK};
    &::after {
      position: absolute;
      top: 11px;
      inset-inline-start: 112%;
      display: block;
      width: 9999px;
      height: 7px;
      background-color: ${Colors.BLUE_LIGHT};
      content: '';
    }
  }
  .lb-steps-item {
    padding-inline-start: 0 !important;
    &:last-child {
      .lb-steps-item-container .lb-steps-item-icon {
        &::after {
          display: none;
        }
      }
    }
  }
  // cuurent status related styles
  .lb-steps-item-finish {
    .lb-steps-item-icon {
      background-color: ${Colors.PRIMARY_DARK};
      &::after {
        background-color: ${Colors.PRIMARY_DARK};
      }
    }
    .lb-steps-finish-icon {
      svg {
        fill: ${Colors.WHITE};
      }
    }
  }
  .lb-steps-item-active {
    .lb-steps-item-icon {
      background-color: ${Colors.WHITE};
      border-color: ${Colors.PRIMARY_DARK};
      .lb-steps-icon {
        display: none;
      }
    }
  }
  .lb-steps-item-wait {
    .lb-steps-item-icon {
      background-color: ${Colors.BLUE_LIGHT};
      border-color: transparent !important;
      .lb-steps-icon {
        display: none;
      }
    }
  }
  .lb-steps-item-container {
    display: flex;
    flex-direction: column-reverse;
    .lb-steps-item-content .lb-steps-item-title {
      font-size: ${(props) => props.theme.typography.h4.fontSize};
      font-weight: ${(props) => props.theme.typography.h4.fontWeight};
      line-height: 1.5;
      color: ${Colors.PRIMARY_DARK} !important;
      text-transform: uppercase;
      &::after {
        display: none;
      }
    }
    .lb-steps-item-content .lb-steps-item-description {
      display: ${(props) => (props.showDesc ? 'block' : 'none')};
    }
    &:hover {
      .lb-steps-item-content {
        .lb-steps-item-description {
          color: inherit !important;
        }
      }
    }
  }
  .lb-steps-item-wait {
    .lb-steps-item-container {
      cursor: default !important;
      .lb-steps-item-title {
        color: ${Colors.GREY13} !important;
      }
    }
  }
`
