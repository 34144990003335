const IconThreeDots = () => {
  return (
    <svg
      width="24"
      height="24"
      fill="currentColor"
      // className="bi bi-three-dots-vertical"
      viewBox="0 0 16 16"
      id="IconChangeColor"
    >
      {' '}
      <path
        d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
        id="mainIconPathAttribute"
        fill="#0062ff"
      ></path>{' '}
    </svg>
  )
}

export default IconThreeDots
