import {StyledStepsProps} from './Steps'
import {StyledAntSteps} from './Steps.Styled'

const Steps = (props: StyledStepsProps) => {
  const handleOnChange = (value: number) => {
    props.onChange && props.onChange(value)
  }

  return <StyledAntSteps {...props} onChange={handleOnChange} />
}

export default Steps
