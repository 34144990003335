import Button from 'lib/components/Button'
import SectionTitle from 'app/common/components/SectionTitle'
import {Outlet, useNavigate} from 'react-router-dom'

const Reporting: React.FC = () => {
  const navigate = useNavigate()
  const navigateTo = (path: string): void => {
    navigate(path)
  }
  return (
    <section>
      <SectionTitle>Reporting Setup</SectionTitle>
      <Button onClick={() => navigateTo('/real-time-reporting')}>Real Time Reporting</Button>
      <Outlet />
    </section>
  )
}

export default Reporting
