import {ReviewDetailsItem, StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import Typography from 'lib/components/Typography'
import {getCategoryRequestObj} from './utils'
import {ReviewRow} from 'app/pages/Segmentation/Segment.styled'
import Button from 'lib/components/Button'
import {FormTypes} from 'lib/components/Form/Form'

interface ReviewCategoryProps {
  isViewOnly?: boolean
  showEditCategory: boolean
  onEdit(): void
  partnerCodeOptions: FormTypes.Options[]
}

const ReviewCategory: React.FC<ReviewCategoryProps> = ({isViewOnly, showEditCategory, onEdit, partnerCodeOptions}): JSX.Element => {
  const categoryReqObj = getCategoryRequestObj
  const {categoryName, categoryCode, categoryDescription, partnerCode, categoryGroup} = categoryReqObj
  // const PointTypeCode = pointTypeOptions.find((item) => (item.value = pointTypeCode))?.label || ''
  const partnerCodes = partnerCodeOptions.find((item) => item.value == partnerCode)?.label ?? ''
  return (
    <ReviewRow>
      <div>
        <MarginBottomWrapper>
          <Typography.Text color="primary" strong uppercase>
            Category Information
          </Typography.Text>
        </MarginBottomWrapper>
        {categoryCode && (
          <ReviewDetailsItem>
            <StyledReviewLabel>Category ID</StyledReviewLabel>
            <StyledReviewValue>{categoryCode}</StyledReviewValue>
          </ReviewDetailsItem>
        )}
        <ReviewDetailsItem>
          <StyledReviewLabel>Partner Code</StyledReviewLabel>
          <StyledReviewValue>{partnerCodes}</StyledReviewValue>
        </ReviewDetailsItem>
        <ReviewDetailsItem>
          <StyledReviewLabel>Category Name</StyledReviewLabel>
          <StyledReviewValue>{categoryName}</StyledReviewValue>
        </ReviewDetailsItem>
        <ReviewDetailsItem>
          <StyledReviewLabel>Description</StyledReviewLabel>
          <StyledReviewValue>{categoryDescription}</StyledReviewValue>
        </ReviewDetailsItem>
        <ReviewDetailsItem>
          <StyledReviewLabel>Category Group</StyledReviewLabel>
          <StyledReviewValue>{categoryGroup}</StyledReviewValue>
        </ReviewDetailsItem>
        {/* <MarginBottomWrapper>
          <Typography.Text color="primary" strong uppercase>
            Attributes and Values
          </Typography.Text>
        </MarginBottomWrapper> */}
        {/* {!isEmpty(properties) ? (
          <>
            {Object.entries(properties).map((attr, index) => (
              <ReviewDetailsItem key={`${attr}-${index}`}>
                <StyledReviewLabel>{attr[0]}</StyledReviewLabel>
                <StyledReviewValue>{attr[1]}</StyledReviewValue>
              </ReviewDetailsItem>
            ))}
          </>
        ) : (
          <>No Attributes available</>
        )} */}
      </div>

      {!isViewOnly && showEditCategory && (
        <div>
          <Button onClick={() => onEdit()} size="small" type="link" disabled={false}>
            Edit
          </Button>
        </div>
      )}
    </ReviewRow>
  )
}

export default ReviewCategory
