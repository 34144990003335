import {useState, FormEvent, useImperativeHandle, forwardRef, useEffect} from 'react'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import {pointStatusOptions} from '../AddNewPoint/util'
import {FormTypes} from 'lib/components/Form/Form'
import PointService from 'app/services/PointService'

interface PointFilterProps {
  handleFilter(formState: PointType.PointMasterFilterState): void
  isFetching: boolean
}

type KeyTypes = keyof PointType.PointMasterFilterState

export interface ResetPointFormRefType {
  handleReset(): void
  getFormState(): PointType.PointMasterFilterState
}

const PointFilter = forwardRef<ResetPointFormRefType, PointFilterProps>(({isFetching, handleFilter}, ref) => {
  const initialState: PointType.PointMasterFilterState = {
    pointName: '',
    pointCode: '',
    pointTypeCode: '',
    isRedemptionPointType: null,
  }
  const [state, setInitialState] = useState<PointType.PointMasterFilterState>(initialState)
  const [pointTypeOptions, setPointTypeOptions] = useState<FormTypes.Options[]>([])
  const [redeem, setRedeem] = useState('')

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleReset = () => {
    setInitialState(initialState)
  }

  const getFormState = (): PointType.PointMasterFilterState => {
    return {
      pointName: state.pointName,
      pointCode: state.pointCode,
      isRedemptionPointType: state.isRedemptionPointType,
      pointTypeCode: state.pointTypeCode,
    }
  }

  const getPointType = () => {
    PointService.getPointTypeCodes({pointTypeCode: undefined})
      .then((res) => {
        const data = res.data.data
        const formattedOptions = data.map((item) => ({
          label: item.pointTypeCode,
          value: item.pointTypeCode,
        }))
        setPointTypeOptions(formattedOptions)
      })
      .finally(() => {})
  }
  useEffect(() => {
    getPointType()
  }, [])

  const handleOnChange = (value: string & string[], key: KeyTypes): void => {
    const prevState = {...state}
    setInitialState({
      ...prevState,
      [key]: value,
    })
  }

  const handleRedeemableChange = (value: string, key: string): void => {
    const prevState = {...state}
    setRedeem(value)
    console.log('key', key)
    console.log('value', value)
    setInitialState({
      ...prevState,
      isRedemptionPointType: value === 'redeemable',
    })
  }

  useImperativeHandle(ref, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.pointName} name="pointName" id="pointName" placeholder="Point Name" onChange={handleOnChange} isDark />
      <Form.Select
        value={state.pointTypeCode}
        options={pointTypeOptions}
        placeholder="Point Type"
        id="pointTypeCode"
        onChange={handleOnChange}
        noMargin
        isDark
      />
      <Form.Input value={state.pointCode} name="PointCode" id="pointCode" placeholder="Point ID" onChange={handleOnChange} isDark />
      <Form.Select
        value={redeem}
        options={pointStatusOptions}
        placeholder="Is Redeemable"
        id="isRedemptionPointType"
        onChange={handleRedeemableChange}
        noMargin
        isDark
      />
    </DataTable.Filter>
  )
})

export default PointFilter
