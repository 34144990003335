import {Switch} from 'antd'
import styled from 'styled-components'
import Colors from 'styles/Colors'

export const SwitchWrapper = styled.div``

export const StyledAntSwitch = styled(Switch)`
  && .lb-switch-inner {
    border: 3px solid ${Colors.PRIMARY};
  }
  &:hover:not(.lb-switch-disabled) {
    background-color: ${Colors.WHITE};
  }
`
