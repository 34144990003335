import {MainContainer} from 'app/common/components/Styled/common.styled'
import {Navigate, Outlet, useParams} from 'react-router-dom'
import {useAuth} from '../Auth/AuthContext'
import {AddNewRewardParam} from './AddNewRewrds'
import {REWARD_CREATE, REWARD_DETAIL, REWARD_EDIT, REWARD_PREVIEW} from 'app/common/helpers/UserFunctions'

const Rewards = (): JSX.Element => {
  const {roles} = useAuth()
  const {rewardId} = useParams<AddNewRewardParam>()
  const actualRewardId = atob(rewardId || '')
  if (window.location.pathname.includes('rewards/view/') && !roles.includes(REWARD_PREVIEW) && !roles.includes(REWARD_DETAIL)) {
    return <Navigate replace to="/unauthorized" />
  } else if (actualRewardId === 'new' && !roles.includes(REWARD_CREATE)) {
    return <Navigate replace to="/unauthorized" />
  } else if (actualRewardId && actualRewardId !== 'new' && !roles.includes(REWARD_EDIT) && !roles.includes(REWARD_PREVIEW) && !roles.includes(REWARD_DETAIL)) {
    return <Navigate replace to="/unauthorized" />
  }
  return (
    <MainContainer>
      <Outlet />
    </MainContainer>
  )
}

export default Rewards
