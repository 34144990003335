import {MainContainer} from 'app/common/components/Styled/common.styled'
import {Outlet} from 'react-router-dom'

const UserManagement: React.FC = () => {
  return (
    <MainContainer>
      <Outlet />
    </MainContainer>
  )
}

export default UserManagement
