// import Button from 'lib/components/Button'
// import {NavLink as RRDNavLink} from 'react-router-dom'
import styled from 'styled-components'
// import Colors from 'styles/Colors'

export const ImagePickerRoot = styled.div`
  max-width: 100%;
`

export const ImagePickerbody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .styled-spinner {
    height: 30px;
    width: 30px;
    &::before {
      width: 20px;
      height: 20px;
    }
    &::after {
      height: 5px;
      width: 5px;
    }
  }
`
export const ImagePickerPreview = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  height: 100px;
  min-height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 2px;
  overflow: hidden;
`

export const ImagePickerContainer = styled.div`
  text-align: 'center';
  border-radius: 2px;
  border: 1px dashed #d7d7d7;
  padding: 2px 2px 2px 2px;
  background-color: #FFF;
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaaaaa;
  font-style: italic;
  input {
    background-color: transparent;
    border:  0;
  }
  &.preview {
    position: relative;
    padding: 5px;
  }
  .btn-delete {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0;
    height: 24px;
    width: 24px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 16px;
        height: 16px;
    }
  }
`