import {Row} from 'antd'
import Button from 'lib/components/Button'
import styled, {css} from 'styled-components'
import Colors from 'styles/Colors'

export const StyledUserSubMenu = styled(Button)`
  padding: 16px 32px;
  margin-right: 16px;
`

export const StyledRow = styled(Row)`
  margin-bottom: 16px;
`

export const UserManagementHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const StyledHeaderSection = styled.div`
  margin: 32px 0 48px;
`

export const UserManagementFooter = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  .lb-btn {
    min-width: 150px;
    margin-left: 8px;
  }
`

export const RolesFormBodyContainer = styled.div`
  border: 1px solid ${Colors.BORDER1};
  background-color: ${Colors.WHITE};
  padding: 16px 34px;
  margin-bottom: 16px;
`

export const BasicRoleInfoWrapper = styled.div`
  margin-bottom: 32px;
`

export const RoleInputWrapper = styled.div`
  margin-bottom: 16px;
  width: 30%;
`

export const CopyFunctionsWrapper = styled.div`
  padding: 30px;
  border: 1px solid ${Colors.BORDER1};
  margin-bottom: 32px;
`

export const CopyFunctionTitle = styled.div`
  font-size: ${(props) => props.theme.typography.h3.fontSize};
  font-weight: ${(props) => props.theme.typography.h2.fontWeight};
  line-height: ${(props) => props.theme.typography.h6.lineHeight};
  margin-bottom: 20px;
`

export const StyledCopyButton = styled(Button)`
  padding: 13.5px 70px;
  margin-top: 22px;
`

export const FunctionFlexWrapper = styled.div`
  display: flex;
`

export const FunctionActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface FuncButtonProps {
  active: boolean
}

export const FunctionActionButtons = styled(Button)<FuncButtonProps>`
  display: flex;
  align-items: center;
  color: #333333;
  border-radius: 0;
  padding: 32px 20px;
  border: none;
  box-shadow: none;
  border-right: 1px sold ${Colors.BORDER1};
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  line-height: ${(props) => props.theme.typography.h6.lineHeight};
  ${(props) =>
    props.active &&
    css`
      border-top: 1px solid ${Colors.BORDER1};
      border-left: 1px solid ${Colors.BORDER1};
      border-bottom: 1px solid ${Colors.BORDER1};
      border-right: none;
      &::after {
        content: '';
        z-index: 1;
        display: block;
        height: 4.5em;
        width: 2px;
        background: ${Colors.WHITE};
        position: absolute;
        right: -1px;
      }
      &:hover {
        && {
          border-top-color: ${Colors.BORDER1};
          border-left-color: ${Colors.BORDER1};
          border-bottom-color: ${Colors.BORDER1};
        }
      }
    `}
`

export const FunctionActionLabel = styled.div`
  margin-left: 8px;
`

export const FunctionDetailsWrapper = styled.div`
  padding: 34px 60px;
  width: 100%;
  border: 1px solid ${Colors.BORDER1};
`

export const FunctionActionHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const FunctionDasboardContainer = styled.div`
  margin-top: 88px;
`

export const UserNameWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${Colors.BORDER1};
  margin-bottom: 32px;
  .review-user-icon {
    margin-right: 10px;
  }
`

export const UserDetailsWrapper = styled.div`
  display: flex;
`

export const UserInfoContainer = styled.div`
  width: 50%;
`

export const QuickActionsTile = styled.div`
  padding: 18px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${Colors.BORDER1};
  margin-top: 32px;
`

export const RoleDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`

export const RoleDetailsHeaderInfo = styled.div`
  display: flex;
  align-items: center;
`
