import ServiceConfig from "app/common/helpers/ServiceConfig";
import { FormTypes } from "lib/components/Form/Form"
import { cloneDeep } from "lodash";

export const initialImportData: ImportExportTypes.ImportDataResponseBL = {
  importType: 'MEMBERSHIP',
  importState: '',
  importBatchId: 0,
  fileName: '',
  dataType: null,
  currentStatus: null,
  recordCount: 0,
  successRecordCount: 0,
  failedRecordCount: 0,
  cancelRecordCount: 0,
  clientCode: "1",
}

export let getImportDataResponseObj: ImportExportTypes.ImportDataResponseBL = cloneDeep(initialImportData)

export const updateImportDataResponseObj = (data: ImportExportTypes.ImportDataResponseBL): void => {
  getImportDataResponseObj = cloneDeep(data)
}

export const dataTypeList = [
  {moduleName: "TRANSACTION", importType: "TRANSACTION"},
  {moduleName: "TRANSACTION", importType: "BOOKING"},
  {moduleName: "MEMBER", importType: "MEMBERSHIP"},
  {moduleName: "MEMBER", importType: "SEGMENT"},
  {moduleName: "PRODUCT", importType: "PRODUCT"},
  {moduleName: "VOUCHER", importType: "VOUCHERTYPE"},
  {moduleName: "VOUCHER", importType: "VOUCHERCODE"}
]

export const dataTypeOptions: FormTypes.Options[] = dataTypeList.map(item => ({
  label: item.importType,
  value: `${item.moduleName}_${item.importType}`
}));
export const fileTypeOptions: FormTypes.Options[] = [
  {
    label: 'CSV',
    value: 'CSV',
  },
  {
    label: 'JSON',
    value: 'JSON',
  },
]

export const getModuleNameByImportType = (importType: string) => {
  const dataType = dataTypeOptions.find((item) => item.label === importType)?.value
  return dataType || ''
}

export const fileStatusOptions: FormTypes.Options[] = [
  {label: 'Cancelled', value: 'CANCELLED'},
  {label: 'Completed', value: 'COMPLETED'},
  {label: 'Not Started', value: 'NOTSTARTED'},
  {label: 'Failed', value: 'FAILED'},
  {label: 'In Process', value: 'INPROCESS'},
  // {label: 'Partial Imported', value: 'PARTIALIMPORTED'},
]

export const getUrlAndParams = (importType: string): string => {
  const splittedImportType = importType.split('_')
  const moduleName = splittedImportType[0]
  switch (moduleName) {
    case 'MEMBER':
      return ServiceConfig.memberImportDetail
    case 'TRANSACTION':
      return ServiceConfig.transactionImportDetail
    case 'VOUCHER':
      return ServiceConfig.voucherImportDetail
    case 'PRODUCT':
      return ServiceConfig.productImportDetail
    default:
      return ''
  }
}

export const getUrlAndParamsImportFile = (importType: string): string => {
  const splittedImportType = importType.split('_')
  const moduleName = splittedImportType[0]
  switch (moduleName) {
    case 'MEMBER':
      return ServiceConfig.memberImportFile
    case 'TRANSACTION':
      return ServiceConfig.transactionImportFile
    case 'VOUCHER':
      return ServiceConfig.voucherImportFile
    case 'PRODUCT':
      return ServiceConfig.productImportFile
    default:
      return ''
  }
}
export const getUrlDownloadImportFile = (importType: string): string => {
  switch (importType.toUpperCase()) {
    case 'MEMBERSHIP':
      return ServiceConfig.memberImportFileDownload
    case 'SEGMENT':
      return ServiceConfig.memberImportFileDownload

    case 'TRANSACTION':
      return ServiceConfig.transactionImportFileDownload

    case 'BOOKING':
      return ServiceConfig.transactionImportFileDownload
    case 'VOUCHERTYPE':
      return ServiceConfig.voucherImportFileDownload
    case 'VOUCHERCODE':
      return ServiceConfig.voucherImportFileDownload
    case 'PRODUCT':
      return ServiceConfig.productImportFileDownload
    default:
      return ''
  }
}
export const getUrlSampleImportFile = (importType: string): string => {
  const splittedImportType = importType.split('_')
  const moduleName = splittedImportType[0]
  switch (moduleName) {
    case 'MEMBER':
      return ServiceConfig.memberSampleImportFileDownload
    case 'TRANSACTION':
      return ServiceConfig.transactionSampleImportFileDownload
    case 'VOUCHER':
      return ServiceConfig.voucherSampleImportFileDownload
    case 'PRODUCT':
      return ServiceConfig.productSampleImportFileDownload
    default:
      return ''
  }
}
// export const getImportBatchCode = (importType: string): string => {
//   const splittedImportType = importType.split('_');
//   const moduleName = splittedImportType[0]
//   switch (moduleName) {
//     case 'MEMBER':
//       return 'ImportMember'
//     case
//     default:
//       return ''
//   }
// }

export const sampleImportData = [
  {
    fileName: 'ABC',
    fileStatus: 'Processing',
    recordCount: 233412,
    successfulRecords: 23123,
    failedRecords: 190,
  },
  {
    fileName: 'DEF',
    fileStatus: 'Success',
    recordCount: 3213,
    successfulRecords: 2345,
    failedRecords: 789,
  },
  {
    fileName: 'GHI',
    fileStatus: 'Failed',
    recordCount: 948,
    successfulRecords: 533,
    failedRecords: 234,
  },
  {
    fileName: 'JKL',
    fileStatus: 'Partially Imported',
    recordCount: 23,
    successfulRecords: 16,
    failedRecords: 7,
  },
]

export function DownLoadFile(blob: Blob, fileName: string): void {
 // const blob = new Blob([JSON.stringify(data)], {type: 'application/json'})
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url

  link.setAttribute('download', `${fileName}`)
  document.body.appendChild(link)
  link.click()
}

export const checkIfValidDataType = (dataType: string): string => {
  const updatedValue = dataTypeList.find(item => item.importType === dataType)?.importType
  return updatedValue || 'MEMBERSHIP'
}