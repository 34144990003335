import styled from 'styled-components'
import Colors from 'styles/Colors'

export const DatePickerWrapper = styled.div`
  width: 100%;
  .lb-picker {
    width: 100%;
    .lb-picker-input > input {
      color: ${Colors.PRIMARY};
    }
  }
`
