import {Col, Row} from 'antd'
import Breadcrumbs from 'lib/components/Breadcrumbs'
import Tabs from 'app/common/components/Tabs'
import TabPanel from 'app/common/components/Tabs/TabPanel'
import MembershipService from 'app/services/MemberService'
import {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Awards from './Awards'
import Communication from './Communication'
import EarnSources from './EarnSources'
import Hierarchy from './Hierarchy'
import MemberInfo from './MemberInfo'
import Promotions from './Promotions'
import ServiceActions from './ServiceActions'
import QuickActions from './ServiceActions/QuickActions'
import Transactions from './Transactions'
import LoadingPanel from 'lib/components/LoadingPanel'
import IssueReward from './ServiceActions/IssueReward'
import OptIn from './ServiceActions/OptIn'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {MEMBER_DETAIL, MEMBER_DETAIL_AWARD, MEMBER_DETAIL_INFO, MEMBER_DETAIL_TRANSACTION} from 'app/common/helpers/UserFunctions'
import {AwardPointWrapper, BorderedSection, MemberDetailsHeader, PointsDetailsWrapper} from './MemberInfo/MemberInfo.styled'
import Typography from 'lib/components/Typography'
import {numberWithCommas} from 'utils/common'
import Button from 'lib/components/Button'
import {MarginBottomWrapper, StyleModalWrapper, StyledModalContent} from 'app/common/components/Styled/common.styled'
import MemberPointManagementService from 'app/services/MemberPointManagementService'
import {IconStarFilled} from 'app/common/icons/IconStarFilled'
//import IconStatus from 'app/common/icons/IconStatus'
import {IconLock} from 'app/common/icons/IconLock'
import PointService from 'app/services/PointService'
import IconStatus from 'app/common/icons/IconStatus'

export type MemberDetailParam = {
  membershipId: string
}

export type BalanceDataParam = {
  pointTypeCode: string
  balance: number
  lockedBalance: number
}

export type PointTypeData = {
  pointTypeCode: string
  pointTypeName: string
  isRedemptionPointType: boolean
}
export type ShowPointTypeData = {
  membershipLifeTimeValue: number
}

const MemberDetail = () => {
  const {roles} = useAuth()
  const isMemberDetail = roles.includes(MEMBER_DETAIL)
  const memberBasicInfo = isMemberDetail && roles.includes(MEMBER_DETAIL_INFO)
  const initialBalanceDetail: BalanceDataParam = {
    pointTypeCode: '',
    balance: 0,
    lockedBalance: 0,
  }
  const initialShowPointDetails = {
    membershipLifeTimeValue: 0,
  }
  const showTransactionHistory = roles.includes(MEMBER_DETAIL_TRANSACTION)
  const showAwards = roles.includes(MEMBER_DETAIL_AWARD)
  const {membershipId} = useParams<MemberDetailParam>()
  const membershipCustomerId = atob(membershipId || '')
  const [memberDetail, setMemberDetail] = useState<MemebershipTypes.MemberDataDetailBL>()
  const [isFetching, setIsFetching] = useState(true)
  const [memberBalance, setMemberBalance] = useState(0)
  // const [lockedBalance, setLockedBalance] = useState(0)
  const [trigerMemberUpdate, setMemberBalanceUpdate] = useState(false)
  const [activeTabId, setActiveTabId] = useState(showTransactionHistory ? 0 : showAwards ? 3 : -1)
  const [showIssueReward, setShowIssueReward] = useState(false)
  const [showOptIn, setShowOptIn] = useState(false)
  const [openAwardModal, setopenAwardModal] = useState(false)
  const [balanceData, setBalanceData] = useState<BalanceDataParam[]>([initialBalanceDetail])
  const [pointTypeOptions, setPointTypeOptions] = useState<PointTypeData[]>([])
  const [showPointDetails, setShowPointDetails] = useState<ShowPointTypeData>(initialShowPointDetails)

  const memberUpdate = () => {
    setMemberBalanceUpdate(true)
  }
  const getMemebers = useCallback(() => {
    if (!membershipCustomerId) {
      return
    }
    const params: MemebershipTypes.MemberDataDetialDetial = {
      membershipCustomerId: membershipCustomerId,
    }
    MembershipService.memberDetail(params)
      .then((res) => {
        const data = res.data.data
        // console.log(data)
        setMemberDetail(data)
        // if (data.length > 0) {
        // }
        setMemberBalanceUpdate(false)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }, [membershipCustomerId])

  const totalBalancePoint = (data: MemberPointManagmentTypes.MemberPointData[]) => {
    const redeemedData = data.filter((item, index) => {
      const matchingOption = pointTypeOptions.find((i) => i.pointTypeCode === item.pointTypeCode)
      return matchingOption?.isRedemptionPointType
    })
    return redeemedData
  }

  const updateLifeTimeValue = (data: MemberPointManagmentTypes.MemberPointData[]) => {
    let lifeTimeValue = 0
    if (data.length === 0) {
      lifeTimeValue = 0
    }

    const lifeTimePoints = data.filter((x) => x.isMembershipLifeTimeValue)
    if (lifeTimePoints.length === 0) {
      lifeTimeValue = 0
    }

    lifeTimeValue = lifeTimePoints[0].membershipLifeTimeValue
    setShowPointDetails({...showPointDetails, membershipLifeTimeValue: lifeTimeValue})
  }

  const getMemeberBalance = (props: MemberPointManagmentTypes.MemberPointDetail) => {
    MemberPointManagementService.memberBalance(props).then((res) => {
      const {data} = res.data

      if (data.length) {
        let formattedOptions: BalanceDataParam[] = data.map((item) => ({
          pointTypeCode: item.pointTypeCode,
          balance: item.balance,
          lockedBalance: item.lockedBalance,
        }))
        formattedOptions = BalancePerPointtype(formattedOptions)
        if (formattedOptions.length > 2) {
          setBalanceData(formattedOptions.slice(0, 2))
        } else {
          setBalanceData(formattedOptions)
        }

        const totalBalance = totalBalancePoint(data)
        if (totalBalance.length) {
          setMemberBalance(totalBalance[0].balance)
        }
        updateLifeTimeValue(data)
        //setMemberBalance(data[0].balance)
        //setLockedBalance(data[0].lockedBalance)
      } else {
        setMemberBalance(0)
        // setLockedBalance(0)
      }
    })
  }
  const BalancePerPointtype = (data: any[]): BalanceDataParam[] => {
    return data.reduce((acc, item) => {
      const existingItem = acc.find((elem: {pointTypeCode: any}) => elem.pointTypeCode === item.pointTypeCode)

      if (existingItem) {
        existingItem.balance += item.balance
        existingItem.lockedBalance += item.lockedBalance
      } else {
        acc.push({
          pointTypeCode: item.pointTypeCode,
          balance: item.balance,
          lockedBalance: item.lockedBalance,
        })
      }

      return acc
    }, [])
  }

  const refreshMemberBalance = (closeModal?: boolean) => {
    if (membershipCustomerId) {
      const MemberPointDetail = {
        MembershipCode: membershipCustomerId,
      }
      getMemeberBalance(MemberPointDetail)
      if (closeModal) {
        setMemberBalanceUpdate(true)
        setopenAwardModal(false)
      }
    }
  }
  const getPointType = () => {
    PointService.getPointTypeCodes({pointTypeCode: undefined})
      .then((res) => {
        const data = res.data.data
        const formattedOptions: PointTypeData[] = data.map((item) => ({
          pointTypeName: item.pointTypeName,
          pointTypeCode: item.pointTypeCode,
          isRedemptionPointType: item.isRedemptionPointType,
        }))
        setPointTypeOptions(formattedOptions)
      })
      .finally(() => {
        // refreshMemberBalance()
      })
  }

  useEffect(() => {
    if (membershipCustomerId && pointTypeOptions.length == 0) {
      setIsFetching(true)
      getMemebers()
      getPointType()
    }
    if (pointTypeOptions.length > 0) {
      refreshMemberBalance()
    }
    //  else {
    //   refreshMemberBalance()
    // }
  }, [pointTypeOptions])

  useEffect(() => {
    if (membershipCustomerId && trigerMemberUpdate) {
      setIsFetching(true)
      getMemebers()
    }
  }, [trigerMemberUpdate])

  const handleOnTabChange = (tabid: number) => {
    setActiveTabId(tabid)
  }

  let memberName = ''
  if (memberDetail) {
    memberName = memberDetail.membershipFullName
  }

  const renderMemeberInfo = () => {
    if (isFetching) {
      return (
        <div style={{padding: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <LoadingPanel />
        </div>
      )
    }

    if (!memberDetail) {
      return <div>no data found. Please check the Membership Card Number </div>
    }
    return <MemberInfo memberDetail={memberDetail} memberUpdate={memberUpdate} showPointDetails={showPointDetails} />
  }

  const handleIssueReward = () => {
    setShowIssueReward(true)
  }

  const handleOptIn = () => {
    setShowOptIn(true)
  }

  const handleRefreshMemberDetails = () => {
    showIssueReward && setShowIssueReward(false)
    showOptIn && setShowOptIn(false)
    setIsFetching(true)
    getMemebers()
    refreshMemberBalance()
  }

  const handleOpenAdjustModal = () => {
    setopenAwardModal(true)
  }

  const renderMemberDetails = () => {
    if (showIssueReward) {
      return (
        <>
          {memberDetail && (
            <IssueReward memberDetail={memberDetail} handleClose={handleRefreshMemberDetails} memberBalance={memberBalance}></IssueReward>
          )}
        </>
      )
    }
    if (showOptIn) {
      return <>{memberDetail && <OptIn memberDetail={memberDetail} handleClose={handleRefreshMemberDetails} memberBalance={memberBalance} />}</>
    }
    return (
      <>
        <Breadcrumbs backLinkLabel="Members" backLinkPath={`/members`} activePageLabel={memberName} />
        {isMemberDetail && (
          <>
            {memberDetail?.membershipFullName && (
              <MemberDetailsHeader>
                <Typography.Title level={1}>{memberDetail?.membershipFullName}</Typography.Title>
                <div>
                  <Typography.Title level={4}>Account Number</Typography.Title>
                  <Typography.Text>{memberDetail.membershipCustomerId}</Typography.Text>
                </div>
              </MemberDetailsHeader>
            )}
            <Row gutter={96}>
              <Col span={16}>
                <BorderedSection>
                  <MarginBottomWrapper width="100%">
                    <PointsDetailsWrapper>
                      {balanceData.map((item, index) => {
                        const asdfg = pointTypeOptions.filter((i) => i.pointTypeCode === item.pointTypeCode)[0]
                        return asdfg?.isRedemptionPointType ? (
                          <AwardPointWrapper>
                            <MarginBottomWrapper>
                              <IconStarFilled />
                              &nbsp;&nbsp;&nbsp;{asdfg?.pointTypeName}
                            </MarginBottomWrapper>
                            <MarginBottomWrapper>
                              <Typography.Title level={1}>{numberWithCommas(item.balance)}</Typography.Title>
                            </MarginBottomWrapper>
                            <Typography.Text color="default">
                              <IconLock />
                              {numberWithCommas(item.lockedBalance)}
                            </Typography.Text>
                          </AwardPointWrapper>
                        ) : (
                          <></>
                        )
                      })}
                      {balanceData.map((item, index) => {
                        const asdfg = pointTypeOptions.filter((i) => i.pointTypeCode === item.pointTypeCode)[0]
                        return asdfg?.isRedemptionPointType ? (
                          <></>
                        ) : (
                          <>
                            {' '}
                            <AwardPointWrapper>
                              <MarginBottomWrapper>
                                <IconStatus />
                                &nbsp;&nbsp;&nbsp;{asdfg?.pointTypeName}
                              </MarginBottomWrapper>
                              <MarginBottomWrapper>
                                <Typography.Title level={1}>{numberWithCommas(item.balance)}</Typography.Title>
                              </MarginBottomWrapper>
                              <Typography.Text color="default">
                                <IconLock />
                                {numberWithCommas(item.lockedBalance)}
                              </Typography.Text>
                            </AwardPointWrapper>
                          </>
                        )
                      })}
                    </PointsDetailsWrapper>
                  </MarginBottomWrapper>
                  {/* <MarginBottomWrapper width="100%">
                    <HorizontallyCenter>
                      <NextPointExpirationDetails>
                        <Typography.Title level={4}>Next Point Expiration</Typography.Title>
                     
                        <Typography.Text>300 Status points expire on MM/DD/YYYY</Typography.Text>
                        <Typography.Text>200 Bonus points expire on MM/DD/YYYY</Typography.Text>
                      </NextPointExpirationDetails>
                      <div>
                        <div>
                          <Typography.Title level={4}>YTD Points</Typography.Title>
                       
                          <Typography.Text>{numberWithCommas(memberBalance)}</Typography.Text>
                        </div>
                        <div>
                          <Typography.Title level={4}>Lifetime Points</Typography.Title>
                          <Typography.Text>{numberWithCommas(memberBalance)}</Typography.Text>
                        </div>
                      </div>
                    </HorizontallyCenter>
                  </MarginBottomWrapper> */}
                  <Button width="100%" type="primary" onClick={handleOpenAdjustModal}>
                    +/- Adjust Points
                  </Button>
                </BorderedSection>
                {memberBasicInfo && renderMemeberInfo()}
              </Col>
              <Col span={8}>
                {memberDetail && (
                  <QuickActions
                    memberDetail={memberDetail}
                    setMemberDetail={setMemberDetail}
                    memberUpdate={memberUpdate}
                    handleIssueReward={handleIssueReward}
                    handleOptIn={handleOptIn}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tabs>
                  {showTransactionHistory && <Tabs.Tab label="Account History" id={0} onTabChange={handleOnTabChange} activeTabId={activeTabId} />}
                  <Tabs.Tab label="Hierarchy" id={1} onTabChange={handleOnTabChange} activeTabId={activeTabId} disabled />
                  <Tabs.Tab label="Earn sources" id={2} onTabChange={handleOnTabChange} activeTabId={activeTabId} disabled />
                  {showAwards && <Tabs.Tab label="Awards" id={3} onTabChange={handleOnTabChange} activeTabId={activeTabId} />}
                  <Tabs.Tab label="Promotions" id={4} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
                  <Tabs.Tab label="Communication" id={5} onTabChange={handleOnTabChange} activeTabId={activeTabId} disabled />
                </Tabs>
                {memberDetail && (
                  <>
                    <TabPanel activeIndex={activeTabId} index={0}>
                      <Transactions
                        membershipCardNumber={membershipCustomerId}
                        memberBalance={memberBalance}
                        isMemberUpdate={trigerMemberUpdate}
                        pointTypeOptions={pointTypeOptions}
                      />
                    </TabPanel>
                    <TabPanel activeIndex={activeTabId} index={1}>
                      <Hierarchy />
                    </TabPanel>
                    <TabPanel activeIndex={activeTabId} index={2}>
                      <EarnSources />
                    </TabPanel>
                    <TabPanel activeIndex={activeTabId} index={3}>
                      <Awards membershipCardNumber={membershipCustomerId} />
                    </TabPanel>
                    <TabPanel activeIndex={activeTabId} index={4}>
                      <Promotions membershipCardNumber={membershipCustomerId} />
                    </TabPanel>
                    <TabPanel activeIndex={activeTabId} index={5}>
                      <Communication />
                    </TabPanel>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
      </>
    )
  }

  const handleOk = () => {}

  const handleCancelModal = () => {
    setopenAwardModal(false)
  }

  const getModalContent = () => {
    return (
      <StyledModalContent align="flex-start">
        <ServiceActions
          memberBalance={memberBalance}
          refreshMemberBalance={refreshMemberBalance}
          handleCloseModal={handleCancelModal}
          pointTypeOptions={pointTypeOptions}
        />
      </StyledModalContent>
    )
  }

  return (
    <div>
      {renderMemberDetails()}
      {openAwardModal && (
        <StyleModalWrapper open={openAwardModal} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {getModalContent()}
        </StyleModalWrapper>
      )}
    </div>
  )
}

export default MemberDetail
