import {RouteObject} from 'react-router-dom'
import ProgramConfiguration from '../pages/ProgramConfiguration'
import PartnerSetup from '../pages/ProgramConfiguration/PartnerSetup'
import ClientSetup from '../pages/ProgramConfiguration/ClientSetup'
import CurrencySetup from '../pages/ProgramConfiguration/CurrencySetup'
import PointTypeSetup from '../pages/ProgramConfiguration/PointTypeSetup'
import MemberTypeSetup from '../pages/ProgramConfiguration/MemberTypeSetup'
import MemberStatusSetup from '../pages/ProgramConfiguration/MemberStatusSetup'
import {ErrorBoundry} from '../common/components/Routes/Fallback'
import ProductDashboard from 'app/pages/ProgramConfiguration/ProductMaster/Dashboard'
import AddNewProduct from 'app/pages/ProgramConfiguration/ProductMaster/AddNewProduct'
import Points from 'app/pages/ProgramConfiguration/PointSetup/Dashboard'
import AddNewPoint from 'app/pages/ProgramConfiguration/PointSetup/AddNewPoint'
import Tiers from 'app/pages/ProgramConfiguration/TierSetup/Dashboard'
import AddNewTier from 'app/pages/ProgramConfiguration/TierSetup/AddNewTier'
import TierMembers from 'app/pages/ProgramConfiguration/TierSetup/TierMembers'
import ImportSetup from 'app/pages/ExportImportSetup/ImportSetup/Dashboard'
import ImportNewData from 'app/pages/ExportImportSetup/ImportSetup/ImportNewData'
import ActivityLog from 'app/pages/ProgramConfiguration/ActivityLog/Dashboard'
import ActivityLogDetails from 'app/pages/ProgramConfiguration/ActivityLog/ActivityLogDetails'
import RewardCategoryDashboard from 'app/pages/ProgramConfiguration/RewardCategory/Dashboard'
import AddNewRewardCategory from 'app/pages/ProgramConfiguration/RewardCategory/AddNewRewardCategory'
import TagDashboard from 'app/pages/ProgramConfiguration/TagMaster/Dashboard'
import AddNewTag from 'app/pages/ProgramConfiguration/TagMaster/AddNewTag'

const ProgramConfigurationRoutes: RouteObject = {
  path: `program`,
  element: <ProgramConfiguration />,
  errorElement: <ErrorBoundry />,
  children: [
    {index: true, element: <ProductDashboard />},
    {path: 'products', element: <ProductDashboard />},
    {path: 'products/:productId', element: <AddNewProduct />},
    {path: 'products/view/:productId', element: <AddNewProduct isViewOnly/>},
    {path: 'partner-setup', index: true, element: <PartnerSetup />},
    {path: 'client-setup', element: <ClientSetup />},
    {path: 'currency-setup', element: <CurrencySetup />},
    {path: 'pointtype-setup', element: <PointTypeSetup />},
    {path: 'points', element: <Points />},
    {path: 'points/:pointId', element: <AddNewPoint />},
    {path: 'points/view/:pointId', element: <AddNewPoint isViewOnly/>},
    {path: 'tiers', element: <Tiers />},
    {path: 'tiers/:tierId', element: <AddNewTier />},
    {path: 'tiers/view/:tierId', element: <AddNewTier isViewOnly/>},
    {path: 'tiers/:tierId/members', element: <TierMembers />},
    {path: 'import', index: true, element: <ImportSetup />},
    {path: 'import/:fileId', index: true, element: <ImportNewData />},
    {path: 'activitylog', index: true, element: <ActivityLog />},
    {path: 'activitylog/:uniqueId', index: true, element: <ActivityLogDetails />},
    {path: 'membertype-setup', element: <MemberTypeSetup />},
    {path: 'memberstatus-setup', element: <MemberStatusSetup />},
    {path: 'category', element: <RewardCategoryDashboard />},
    {path: 'category/:categoryId', element: <AddNewRewardCategory />},
    {path: 'tag', element: <TagDashboard />},
    {path: 'tag/:tagId', element: <AddNewTag />},
  ],
}

export default ProgramConfigurationRoutes
