import UserManagementService from 'app/services/UserManagementService'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import {FormEvent, forwardRef, useState, useImperativeHandle, useEffect} from 'react'

interface UsersFilterProps {
  handleFilter(formState: FunctionsFilterState): void
  isFetching: boolean
}

export interface FunctionsFilterState {
  functionName: string
  functionCode: string
  categoryCode?: string
}

interface Option {
  value: string
  label: string
}

type KeyTypes = keyof FunctionsFilterState

export interface ResetFormRefType {
  handleReset(): void
  getFormState(): FunctionsFilterState
}

const FunctionsFilter = forwardRef<ResetFormRefType, UsersFilterProps>(({handleFilter, isFetching}, forwardedRef) => {
  const inititalState: FunctionsFilterState = {
    functionName: '',
    functionCode: '',
    categoryCode: '',
  }
  const [state, setInitialState] = useState(inititalState)
  const [categoryCodeOptions, setCategoryCodeOptions] = useState<Option[]>([])

  const convertPointResponseToOptions = (response: UserManagementTypes.CategoryInfoType[]): Option[] => {
    return response.map((item) => ({
      label: item.categoryName,
      value: item.categoryCode,
    }))
  }

  const category = () => {
    UserManagementService.searchCategories({}).then((res) => {
      const {data} = res.data
      setCategoryCodeOptions(convertPointResponseToOptions(data))
    })
  }

  useEffect(() => {
    category()
  }, [])

  const handleReset = () => {
    setInitialState(inititalState)
  }

  const getFormState = () => {
    return state
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleOnChange = (value: string, key: KeyTypes): void => {
    const prevState = {...state}
    prevState[key] = value
    setInitialState(prevState)
  }

  useImperativeHandle(forwardedRef, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input
        value={state.functionName}
        name="functionName"
        id="functionName"
        placeholder="Search by Function Name"
        onChange={handleOnChange}
        isDark
      />
      <Form.Input
        value={state.functionCode}
        name="functionCode"
        id="functionCode"
        placeholder="Search by Function ID"
        onChange={handleOnChange}
        isDark
      />
      <Form.Select
        value={state.categoryCode}
        id="categoryCode"
        placeholder="Search by Category code"
        options={categoryCodeOptions}
        onChange={handleOnChange}
        noMargin
        isDark
      />
    </DataTable.Filter>
  )
})

export default FunctionsFilter
