import { FormTypes } from "lib/components/Form/Form"
import { cloneDeep } from "lodash"

export const initialTierReqObj: TierTypes.TierMasterResponseBL = {
  rowStatus: 1,
  tierSystemKey: null,
  tierSystemName: '',
  startDate: null,
  endDate: null,
  assesmentTime: '',
  assesmentType: 1,
  tierStatus: 'INPROGRESS',
  partnerCode: '',
  tierList: []
}

export let getTierRequestObj: TierTypes.TierMasterResponseBL = cloneDeep(initialTierReqObj)

export const updateTierReqObj = (data: TierTypes.TierMasterResponseBL): void => {
  getTierRequestObj = cloneDeep(data)
}

export const AssessmentValueOptions: FormTypes.Options[] = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Quarterly', value: 'QUARTERLY' },
  { label: 'Year End', value: 'YEAREND' },
]
export const PointsValueOptions: FormTypes.Options[] = [
  { label: 'Rollover Points', value: 1 },
  { label: 'Reset Points', value: 2 },
]

export const matchEndDateOptions: FormTypes.Options[] = [
  {
    label: 'Match end-date with Program Period',
    value: 'isChecked',
  },
]

export const getRewardsCode = (rewards: TierTypes.TierMasterRewardRuleDataType[]): string[] => {
  return rewards.map(item => item.rewardCode)
}

export const getRewardRuleDataFromRewardCodes = (allRewardsdata: FormTypes.Options[], data: string[]): TierTypes.TierMasterRewardRuleDataType[] => {
  const updatedData: TierTypes.TierMasterRewardRuleDataType[] = allRewardsdata.filter(item => data.includes(item.value as string)).map(reward => ({
    rewardCode: reward.value as string,
    rewardName: reward.label as string
  }))
  return updatedData
}

export const getPartnerNameFromCode = (data: FormTypes.Options[], partnerCode: string): string => {
  const partnerName = data.find(item => item.value === partnerCode)?.label || ''
  return partnerName as string
}

export const getRewardsNamesByCodes = (data: FormTypes.Options[], rewards: string[]): string[] => {
  return data.filter(item => rewards.includes(item.value as string)).map(reward => reward.label as string);
}

export const getColor = (status: string): string => {
  switch (status.toUpperCase()) {
    case 'DRAFT':
      return "#ff7867"
    case 'INPROGRESS':
      return "#ff7867"
    case 'APPROVED':
      return "#21a19a"
    default:
      return "#ff7867"
  }

}
export const getTierStaus = (status: string): string => {
  switch (status.toUpperCase()) {
    case 'DRAFT':
      return "DRAFT"
    case 'INPROGRESS':
      return "INPROGRESS"
    case 'APPROVED':
      return "LIVE"
    default:
      return status
  }
}

export const validateTierName = (tierData: TierTypes.TierDetailStateType[]): boolean => {
  const isTierNameBlank = tierData.some(item => !item.membershipTierName)
  return !isTierNameBlank
}

export const validateRewards = (tierData: TierTypes.TierDetailStateType[]): boolean => {
  const isTierRewardsBlank = tierData.some(item => !item.rewards.length)
  return !isTierRewardsBlank
}

export const validatePointRange = (tierData: TierTypes.TierDetailStateType[]): TierTypes.TierToFromValuesErroType => {
  let prevToValue = 0;
  for (let i = 0; i < tierData.length; i++) {
    const {fromValue, toValue} = tierData[i]
    if (fromValue > toValue) {
      return {
        isValid: false,
        message: 'ToValue should be greater than from Value'
      }
    }
    if (i === 0) {
      prevToValue = toValue;
      continue;
    }
    if (fromValue !== prevToValue + 1) {
      return {
        isValid: false,
        message: 'Tier to and from values should be sequential'
      }
    }
    prevToValue = toValue;
  }
  return {
    isValid: true,
    message: ''
  }
}

export const sampleTierData: TierTypes.TierMasterResponseBL[] = [{
  tierSystemKey: 'tierSystemDesign1',
  tierSystemName: 'Tier Design System 1',
  startDate: '2024-01-09T18:20:00',
  endDate: '2024-01-09T18:20:00',
  assesmentTime: 'Monthly',
  assesmentType: 1,
  tierStatus: 'Draft',
  partnerCode: '01',
  tierList: [{
    membershipTierName: 'Tier 1',
    membershipTierKey: 'Tier1',
    membershipTierDescription: 'Tier 1 Description',
    earnRate: 1,
    tierLevel: 1,
    isDefaultTier: true,
    tierMasterRuleData: {
      tierMasterRewardRuleData: [],
      tierMasterWhoRule: {
        fromValue: 0,
        toValue: 100
      }
    },
    ruleData: []
  },{
    membershipTierName: 'Tier 2',
    membershipTierKey: 'Tier2',
    membershipTierDescription: 'Tier 2 Description',
    earnRate: 5,
    tierLevel: 1,
    isDefaultTier: true,
    tierMasterRuleData: {
      tierMasterRewardRuleData: [],
      tierMasterWhoRule: {
        fromValue: 101,
        toValue: 200
      }
    },
    ruleData: []
  }]
}, {
  tierSystemKey: 'tierSystemDesign2',
  tierSystemName: 'Tier Design System 2',
  startDate: '2024-01-09T18:20:00',
  endDate: '2024-01-09T18:20:00',
  assesmentTime: 'Yearly',
  assesmentType: 1,
  tierStatus: 'LIVE',
  partnerCode: '01',
  tierList: [{
    membershipTierName: 'Tier 1',
    membershipTierKey: 'Tier1',
    membershipTierDescription: 'Tier 1 Description',
    earnRate: 1,
    tierLevel: 1,
    isDefaultTier: true,
    tierMasterRuleData: {
      tierMasterRewardRuleData: [],
      tierMasterWhoRule: {
        fromValue: 0,
        toValue: 0
      }
    },
    ruleData: []
  },{
    membershipTierName: 'Tier 2',
    membershipTierKey: 'Tier2',
    membershipTierDescription: 'Tier 2 Description',
    earnRate: 5,
    tierLevel: 1,
    isDefaultTier: true,
    tierMasterRuleData: {
      tierMasterRewardRuleData: [],
      tierMasterWhoRule: {
        fromValue: 0,
        toValue: 0
      }
    },
    ruleData: []
  }]
}]
