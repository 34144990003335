import {Radio} from 'antd'
import styled from 'styled-components'
import Colors from 'styles/Colors'

interface ToggleButtonStyledProps {
  useAsButton?: boolean
}

export const StyledToggleButton = styled(Radio.Group)<ToggleButtonStyledProps>`
  .lb-radio-button-wrapper {
    padding-inline: 2px;
    color: ${Colors.TEXT_COLOR_SECONDARY};

    & > span:last-child {
      padding: 4px 16px;
      border-radius: 25px;
    }
    &:first-child {
      border-right: none;
      border-color: #d9e7f4;
      transition: 5s;
      &.lb-radio-button-wrapper-checked:not(.lb-radio-button-wrapper-disabled) {
        & span:last-child {
          background-color: #02a19a;
          color: ${Colors.WHITE};
        }
      }
    }
    &:last-child {
      border-color: #d9e7f4;
      transition: 5s;
      &.lb-radio-button-wrapper-checked:not(.lb-radio-button-wrapper-disabled) {
        & span:last-child {
          background-color: ${({ useAsButton }): string => useAsButton ? '#02a19a' : '#d9e7f4'};
          color: ${({ useAsButton }): string => useAsButton ? Colors.WHITE : '#47637c'};
        }
      }
      &::before {
        display: none;
      }
    }
  }
`
