import React, {useState} from 'react'
import dayjs from 'dayjs'

import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import {useNavigate} from 'react-router-dom'
import Button from 'lib/components/Button'
import Appconfig from 'app/common/helpers/AppConfig'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {PROMOTION_DETAIL, PROMOTION_EDIT, PROMOTION_REVIEW} from 'app/common/helpers/UserFunctions'
import IconDelete from 'app/common/icons/IconDelete'
import {exclusionStatusForDelete} from '../CreatePromotion/utils'
import IconChevronRight from 'app/common/icons/IconChevronRight'
import Popover from 'lib/components/Popover'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import {StyledMenuItem} from 'app/common/components/Styled/common.styled'

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Promotion Name',
    id: 'promotionName',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Promotion Type',
    id: 'type',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Start Date',
    id: 'startDate',
    textAlign: 'left',
    width: '12%',
  },
  {
    title: 'End Date',
    id: 'endDate',
    textAlign: 'left',
    width: '12%',
  },
  {
    title: 'Status',
    id: 'status',
    textAlign: 'left',
    width: '10%',
  },
  {
    title: 'Promotion Category',
    id: 'who',
    textAlign: 'center',
    width: '15%',
  },
  {
    title: '',
    id: 'actions',
    textAlign: 'right',
    width: '15%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  promotionsResponse: ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]>
  handleDeletePromotion(promotion: PromotionsTypes.PromotionResponseBL): void
}

const SearchResult: React.FC<SearchResultProps> = ({isFetching, promotionsResponse, handleDeletePromotion}) => {
  const navigate = useNavigate()
  const {roles} = useAuth()
  const [selectedPromotionIndex, setSelectedPromotionIndex] = useState<number>(-1)
  const handleEdit = (rowIndex: number) => {
    const promotionCode = promotionsResponse.data[rowIndex].promotionCode
    const encodedPromotionId = btoa(promotionCode)
    navigate(`/promotions/${encodedPromotionId}`)
  }
  const handleView = (rowIndex: number) => {
    const promotionCode = promotionsResponse.data[rowIndex].promotionCode
    const encodedPromotionId = btoa(promotionCode)
    navigate(`/promotions/view/${encodedPromotionId}`)
  }

  const handleDelete = (rowIndex: number): void => {
    const promotion = promotionsResponse.data[rowIndex]
    setSelectedPromotionIndex(-1)
    handleDeletePromotion(promotion)
  }

  const handleOpenPopover = (visible: boolean, rowIndex: number): void => {
    setSelectedPromotionIndex(visible ? rowIndex : -1)
  }

  const renderActionList = (rowIndex: number, promotionStatus: string, item: PromotionsTypes.PromotionResponseBL) => {
    return (
      <>
        {(roles.includes(PROMOTION_REVIEW) || roles.includes(PROMOTION_DETAIL)) && (
          <StyledMenuItem title="View Promotion">
            <Button type="text" onClick={() => handleView(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
              Details
            </Button>
          </StyledMenuItem>
        )}
        {roles.includes(PROMOTION_EDIT) && (
          <StyledMenuItem title={promotionStatus.toUpperCase() === 'PENDING' ? 'Can not edit pending promotion' : 'Edit'}>
            <Button
              type="text"
              disabled={promotionStatus.toUpperCase() === 'PENDING'}
              onClick={() => handleEdit(rowIndex)}
              $endIcon={<IconChevronRight size={16} />}
            >
              Edit
            </Button>
          </StyledMenuItem>
        )}
        {roles.includes(PROMOTION_EDIT) && !exclusionStatusForDelete.includes(item.promotionStatusCode.toUpperCase()) && (
          <StyledMenuItem title="Delete Promotion">
            <Button type="text" onClick={() => handleDelete(rowIndex)} $endIcon={<IconDelete size={14} />}>
              Delete
            </Button>
          </StyledMenuItem>
        )}
      </>
    )
  }

  const renderRowActions = (rowIndex: number, promotionStatus: string, item: PromotionsTypes.PromotionResponseBL) => {
    if (roles.includes(PROMOTION_REVIEW) || roles.includes(PROMOTION_DETAIL) || roles.includes(PROMOTION_EDIT)) {
      return (
        <Popover
          btnContent={<IconThreeDots />}
          content={renderActionList(rowIndex, promotionStatus, item)}
          onOpenChange={(visible: boolean) => handleOpenPopover(visible, rowIndex)}
          rowIndex={rowIndex}
          selectedRowIndex={selectedPromotionIndex}
        />
      )
    }
    return null
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (promotionsResponse && promotionsResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = promotionsResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          promotionName: item.promotionName,
          type: item.promotionType ?? '-',
          startDate: item.startDateTime ? dayjs(item.startDateTime).format(Appconfig.DATE_FORMAT_SLASH) : '-',
          endDate: item.endDateTime ? dayjs(item.endDateTime).format(Appconfig.DATE_FORMAT_SLASH) : '-',
          status: item.promotionStatusCode ?? '-',
          who: item.who,
          actions: renderRowActions(index, item.promotionStatusCode, item),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }
  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
    </>
  )
}

export default SearchResult
