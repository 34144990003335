import styled from 'styled-components'
import Colors from 'styles/Colors'

export const SegmentsFormBodyContainer = styled.div`
  border: 1px solid ${Colors.BORDER1};
  background-color: ${Colors.WHITE};
  padding: 34px;
`

export const NewSegmentFooter = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  .lb-btn {
    min-width: 150px;
    margin-left: 8px;
  }
`

export const AddMembersWrapper = styled.div`
  margin-top: 32px;
`

export const MemberTabsDetailsWrapper = styled.div`
  margin-top: 32px;
`

export const GenderCheckboxWrapper = styled.div`
  width: 40%;
  && {
    div > div {
      display: flex;
      flex-flow: column wrap;
      max-height: 90px;
      .lb-checkbox-wrapper {
        margin-inline-start: 0;
        margin-bottom: 8px;
      }
    }
  }
`

export const ReviewRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 22px 0;
  border-top: 1px solid ${Colors.BORDER2};
  &:first-child {
    padding-top: 0;
    border-top: 0;
  }
`

export const SegmentInformationSection = styled.section`
  display: flex;
  flex-direction: column;
`

export const CheckboxMarginContainer = styled.div`
  padding: 24px 0;
  margin-top: 16px;
`
export const SegmentMemberName = styled.div``
