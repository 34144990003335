import {FormTypes} from 'lib/components/Form/Form'
import {StyledReviewValue, StyledSectionLabel} from '../../Reward.Styled'
import {rewardTiersData} from '../utils'
import {numberWithCommas} from 'utils/common'

interface TriggerDataProps {
  triggerData: RewardsTypes.RewardTriggerData
}

const TriggerData: React.FC<TriggerDataProps> = ({triggerData}) => {
  const {isAllTiers, tierData} = triggerData
  let total = 0
  rewardTiersData.forEach((item) => {
    total += item.tierCount
  })
  let tierDataWithCount: FormTypes.Options[] = []
  if (tierData) {
    const filterData = rewardTiersData.filter((item) => tierData.findIndex((tier) => tier.membershipTierKey === item.tierCode) !== -1)
    tierDataWithCount = filterData.map((item) => ({
      label: `${item.tierName} (${numberWithCommas(item.tierCount)})`,
      value: item.tierCode,
    }))
  }

  const getTierLabel = (): string => {
    if (isAllTiers) {
      return `All Tiers (${numberWithCommas(total)})`
    }
    return 'Specific tier(s)'
  }

  return (
    <>
      <StyledSectionLabel>{getTierLabel()}</StyledSectionLabel>
      {tierData && tierData?.length > 0 && (
        <>
          {tierDataWithCount.map((item) => (
            <StyledReviewValue>{item.label}</StyledReviewValue>
          ))}
        </>
      )}
    </>
  )
}

export default TriggerData
