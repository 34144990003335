import {Col, InputNumber, Tooltip} from 'antd'
import Form from 'lib/components/Form'
import PointService from 'app/services/PointService'
import Typography from 'lib/components/Typography'
import {cloneDeep, uniqBy} from 'lodash'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {getPromotionRequestObj, multiplierOptions} from '../../utils'
import {SetpsBody, StepTitle} from '../Steps.styled'
import {ActionRadioContainer, IncentiveContainer, IncentiveWrapper, InputButton, InputButtonGroup, InputButtonWrapper} from './Incentives.styled'
import {toast} from 'react-hot-toast'
import {FormTypes} from 'lib/components/Form/Form'
import LoadingPanel from 'lib/components/LoadingPanel'
import Button from 'lib/components/Button'
import Colors from 'styles/Colors'
import IconAdd from 'app/common/icons/IconAdd'
import IconDelete from 'app/common/icons/IconDelete'
import RewardService from 'app/services/RewardService'
import Appconfig from 'app/common/helpers/AppConfig'

export interface IncentivesRefType {
  validateTrigger(): boolean
  getIncentiveData(): PromotionsTypes.IncentiveData[]
}

const radioOptions: FormTypes.Options[] = [
  {
    label: 'Fixed',
    value: 'FIXED',
  },
  {
    label: 'Multiplier',
    value: 'MULTIPLIER',
  },
]

const Incentives = forwardRef<IncentivesRefType>((props, forwardedRef) => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const promotionRequestObj = getPromotionRequestObj
  const [isPointsLoading, setIsPointsLoading] = useState<boolean>(true)
  const [updatedPointIndex, setUpdatedPointIndex] = useState<number>(-1)
  const [isPointTypeLoading, setIsPointtypeLoading] = useState(true)
  const [pointOptions, setPointOptions] = useState<FormTypes.Options[]>([])
  const [pointTypeOptions, setPointTypeOptions] = useState<FormTypes.Options[]>([])
  const [rewardsDataOptions, setRewardsDataOptions] = useState<FormTypes.Options[]>([])

  const incentiveData = promotionRequestObj.promotionRuleData.incentiveRuleData?.incentiveData
  const promotionType = promotionRequestObj.promotionType
  const referralData = promotionRequestObj.promotionRuleData.triggerRuleData?.activityRuleData?.activityCode || ''
  const isReferralData = ['basic', 'accepted', 'transacted'].includes(referralData?.toLocaleLowerCase())
  const updatedRadioOptions =
    promotionType.toUpperCase() === 'TRANSACTION' ? radioOptions : radioOptions.filter((item) => item.value !== 'MULTIPLIER')

  const initialState: PromotionsTypes.IncentiveData = {
    pointCode: null,
    rewardData: null,
    action: 'FIXED',
    fixedPoint: 0,
    multiplier: 1,
    referee: undefined,
    receiver: undefined,
  }

  const formatIncentivesData = (): PromotionsTypes.IncentiveData[] => {
    const updatedIncentiveData = incentiveData || []
    return updatedIncentiveData.map((item, index) => ({
      pointCode: item.pointCode,
      rewardData: item.rewardData,
      action: item.action,
      fixedPoint: item.fixedPoint,
      multiplier: item.multiplier,
      referee: item.referee,
      receiver: item.receiver,
    }))
  }

  const updatedIncentiveData = incentiveData ? formatIncentivesData() : [initialState]

  const [state, setState] = useState(updatedIncentiveData)

  const getIncentiveData = () => {
    return state
  }

  useImperativeHandle(forwardedRef, () => ({validateTrigger, getIncentiveData}))

  const validateTrigger = () => {
    for (let i = 0; i < state.length; i++) {
      if (!state[i].pointCode && !state[i].rewardData) {
        toast.error('Please Select AwardPoint')
        return false
      } else if (!state[i].pointCode && !state[i].rewardData?.rewardCode) {
        toast.error('Please Select atleast one reward')
        return false
      }
    }
    if (isReferralData) {
      const isRefreeOrReceiverEmpty = state.some((item) => item.pointCode && (!item.referee?.fixedPoint || !item.receiver?.fixedPoint))
      if (isRefreeOrReceiverEmpty) {
        toast.error('Incentive Referee and Receiver fixed point should be greater than 0.')
        return false
      }
    }

    return true
  }

  const handleAddIncentive = () => {
    const prevState = cloneDeep(state)
    setState([...prevState, initialState])
  }

  const handleDeleteIncentive = (index: number) => {
    const prevState = cloneDeep(state)
    prevState.splice(index, 1)
    setState(prevState)
  }

  const getPointType = () => {
    setIsPointtypeLoading(true)
    PointService.getPointTypeCodes({pointTypeCode: undefined})
      .then((res) => {
        const data = res.data.data
        const formattedOptions = data.map((item) => ({
          label: item.pointTypeName,
          value: item.pointTypeCode,
        }))
        setPointTypeOptions([{label: 'REWARD', value: 'reward'}, ...formattedOptions])
        getPoints(undefined)
      })
      .finally(() => {
        setIsPointtypeLoading(false)
      })
  }

  const getPoints = (pointTypeCode: string | undefined) => {
    setIsPointsLoading(true)
    PointService.getPoints({pointTypeCode: pointTypeCode})
      .then((res) => {
        const data = res.data.data

        const partnerCode = promotionRequestObj.partnerCode

        const partnerData = data.filter((item) => item.partnerCode.toUpperCase() === partnerCode.toUpperCase())
        const pointOptiosData: FormTypes.Options[] = partnerData.map((item): FormTypes.Options => ({label: item.pointName, value: item.pointCode}))
        const uniquepointOptiosData = uniqBy(pointOptiosData, function (e) {
          return e.value
        })

        setPointOptions(uniquepointOptiosData)
      })
      .finally(() => {
        setIsPointsLoading(false)
        setUpdatedPointIndex(-1)
      })
  }

  const getRewards = (params: RewardsTypes.RewardSearchParam): void => {
    const reqParam: RewardsTypes.RewardSearchParam = {
      ...params,
      isAll: true,
      IsTierBenefit: true,
      enabled: true,
      voucherTypeGroup: 3,
    }
    RewardService.search(reqParam)
      .then((res) => {
        const data = res.data
        const updatedData = data.data.map((item) => ({
          label: `${item.voucherTypeName}-${item.voucherTypeCode}`,
          value: item.voucherTypeCode,
        }))
        setRewardsDataOptions([...updatedData])
      })
      .finally(() => {})
  }

  useEffect(() => {
    getPointType()
    getRewards(initialPaginationObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePointTypeChange = (value: string, key: string, index: number) => {
    const prevState = cloneDeep(state)
    setUpdatedPointIndex(index)
    if (value && value !== 'reward') {
      const selectedOption = pointTypeOptions.filter((item) => item.value === value)
      if (selectedOption.length > 0) {
        getPoints(selectedOption[0].value.toString())
        const options = selectedOption[0]
        const obj: PromotionsTypes.PointData = {
          pointTypeCode: options.value as string,
          pointCode: '',
          pointName: '',
        }
        prevState[index].pointCode = obj
        prevState[index].rewardData = null
        prevState[index].action = 'FIXED'
        prevState[index].fixedPoint = 0
        prevState[index].multiplier = 1
        if (prevState[index].receiver || prevState[index].referee) {
          prevState[index].receiver = {
            fixedPoint: 0,
          }
          prevState[index].referee = {
            fixedPoint: 0,
          }
        }
      }
    } else if (value && value === 'reward') {
      const rewardDataValue: PromotionsTypes.ActivityRewardType = {
        rewardName: '',
        rewardCode: '',
      }
      prevState[index].pointCode = null
      prevState[index].rewardData = rewardDataValue
    }
    setState(prevState)
  }

  const handlePointChange = (value: string, key: string, index: number) => {
    const prevState = cloneDeep(state)
    if (value) {
      const selectedOption = pointOptions.filter((item) => item.value === value)

      if (selectedOption.length > 0) {
        const options = selectedOption[0]
        const obj: PromotionsTypes.PointData = {
          pointTypeCode: state[index].pointCode?.pointTypeCode || '',
          pointCode: options.value as string,
          pointName: options.label as string,
        }
        prevState[index].pointCode = obj
      }
    }

    setState(prevState)
  }

  const handleActionChange = (value: PromotionsTypes.IncentiveActionType, key: string, index: number) => {
    const prevState = cloneDeep(state)
    prevState[index].action = value
    prevState[index].multiplier = value === 'FIXED' ? 1 : prevState[index].multiplier
    prevState[index].fixedPoint = value === 'MULTIPLIER' ? 0 : prevState[index].fixedPoint
    setState(prevState)
  }

  const handleMultiplierChange = (value: FormTypes.ValueType, key: string, index: number) => {
    const prevState = cloneDeep(state)
    prevState[index].multiplier = value as number
    setState(prevState)
  }

  const handleOnPointChange = (value: number | null, index: number, referralType?: string) => {
    let prevState = cloneDeep(state)
    if (isReferralData && referralType) {
      prevState[index] = {
        ...prevState[index],
        [referralType]: {
          fixedPoint: value as number,
        },
      }
    } else {
      prevState[index].fixedPoint = value as number
    }
    if (!value || isNaN(value)) {
      prevState[index].fixedPoint = 0
      if (isReferralData && referralType === 'referee' && prevState[index].referee) {
        prevState[index] = {
          ...prevState[index],
          referee: {
            fixedPoint: 0,
          },
        }
      } else if (isReferralData && referralType === 'receiver' && prevState[index].receiver) {
        prevState[index] = {
          ...prevState[index],
          receiver: {
            fixedPoint: 0,
          },
        }
      }
    }
    setState(prevState)
  }

  const handleChangeRewards = (val: FormTypes.ValueType, key: string, index: number): void => {
    let prevState = cloneDeep(state)
    const values = val as string[]
    const rewardData = rewardsDataOptions
      .filter((item) => values.includes(item.value as string))
      .map((reward) => ({
        rewardName: reward.label?.toString().substring(0, reward.label?.toString().lastIndexOf('-')) as string,
        rewardCode: reward.value,
      }))
    prevState[index] = {
      ...prevState[index],
      [key]: rewardData[0],
      action: null,
      fixedPoint: 0,
      multiplier: 0,
    }
    setState(prevState)
  }

  const getRewardsValue = (rewardsData: PromotionsTypes.ActivityRewardType | null): string[] => {
    return rewardsData?.rewardCode ? [rewardsData.rewardCode] : []
  }

  const renderRewardingValues = (index: number) => {
    if (state[index].action === 'FIXED') {
      if (isReferralData) {
        return (
          <div style={{display: 'flex', justifyContent: 'space-between', width: '50%'}}>
            <InputButtonWrapper>
              <Typography.Text uppercase size="small">
                Fixed Quantity For{' '}
                <strong>
                  <Tooltip title="The Referrer is the party that initates the referral: the person who sends out the invitation to join the program.">
                    Referrer
                  </Tooltip>
                </strong>
              </Typography.Text>
              <InputButtonGroup>
                <InputNumber
                  value={state[index].referee?.fixedPoint || 0}
                  onChange={(value) => handleOnPointChange(value, index, 'referee')}
                  min={0}
                  max={10000}
                  width={102}
                  precision={0}
                />
                <InputButton>Points</InputButton>
              </InputButtonGroup>
            </InputButtonWrapper>
            <InputButtonWrapper>
              <Typography.Text uppercase size="small">
                Fixed Quantity For{' '}
                <strong>
                  <Tooltip title="The Referee is the party who receives the referral invitation, and can join the program as a result of the referral.">
                    Referee
                  </Tooltip>
                </strong>
              </Typography.Text>
              <InputButtonGroup>
                <InputNumber
                  value={state[index].receiver?.fixedPoint || 0}
                  onChange={(value) => handleOnPointChange(value, index, 'receiver')}
                  min={0}
                  max={10000}
                  width={102}
                  precision={0}
                />
                <InputButton>Points</InputButton>
              </InputButtonGroup>
            </InputButtonWrapper>
          </div>
        )
      }
      return (
        <InputButtonWrapper>
          <Typography.Text uppercase size="small">
            Quantity
          </Typography.Text>
          <InputButtonGroup>
            <InputNumber
              value={state[index].fixedPoint}
              onChange={(val: number | null) => handleOnPointChange(val, index)}
              min={0}
              max={10000}
              width={102}
              precision={0}
            />
            <InputButton>Points</InputButton>
          </InputButtonGroup>
        </InputButtonWrapper>
      )
    }
    return (
      <InputButtonWrapper>
        <Typography.Text uppercase size="small">
          multiplier value &nbsp; &nbsp; &nbsp;(Base = 1 point per dollar spent)
        </Typography.Text>
        <InputButtonGroup>
          <Form.Select
            noMargin
            id="multiplier"
            placeholder="Select an option"
            onChange={(val, key) => handleMultiplierChange(val, key, index)}
            value={state[index].multiplier}
            options={multiplierOptions}
          />
          <InputButton>Per dollar spent</InputButton>
        </InputButtonGroup>
      </InputButtonWrapper>
    )
  }

  const getAwardTypeValue = (index: number): string | undefined => {
    if (!state[index].pointCode?.pointTypeCode && !state[index].rewardData) {
      return ''
    } else if (state[index].pointCode?.pointTypeCode) {
      return state[index].pointCode?.pointTypeCode
    } else if (state[index].rewardData) {
      return 'reward'
    }
    return ''
  }

  const renderBody = () => {
    if (isPointTypeLoading) {
      return (
        <div style={{padding: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <LoadingPanel />
        </div>
      )
    }
    // if (isPointsLoading) {
    //   return (
    //     <div style={{padding: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    //       <LoadingPanel />
    //     </div>
    //   )
    // }
    if (pointTypeOptions.length === 0) {
      return <div>No points data available</div>
    }

    return (
      <>
        {state.map((item, index) => (
          <IncentiveWrapper>
            <IncentiveContainer>
              <Form.Select
                id="pointTypeCode"
                placeholder="Award Type"
                label="Award Type"
                onChange={(val, key) => handlePointTypeChange(val as string, key, index)}
                value={getAwardTypeValue(index)}
                options={pointTypeOptions}
              />
              <>
                {state[index].rewardData !== null ? (
                  <Col span={12}>
                    <Form.Select
                      id="rewardData"
                      label="Select Reward"
                      placeholder="Search & Select Reward"
                      value={getRewardsValue(state[index].rewardData)}
                      onChange={(val, key) => handleChangeRewards(val, key, index)}
                      options={rewardsDataOptions}
                      filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                  </Col>
                ) : (
                  <>
                    {isPointsLoading && updatedPointIndex === index ? (
                      <LoadingPanel />
                    ) : (
                      <Form.Select
                        id="pointCode"
                        placeholder="Select a Point"
                        label="Select a Point"
                        onChange={(val, key) => handlePointChange(val as string, key, index)}
                        value={state[index].pointCode?.pointCode}
                        options={pointOptions}
                      />
                    )}
                    <ActionRadioContainer>
                      <Form.Radio
                        boxed
                        id="action"
                        value={state[index].action}
                        onChange={(val, key) => handleActionChange(val as PromotionsTypes.IncentiveActionType, key, index)}
                        options={updatedRadioOptions}
                      />
                    </ActionRadioContainer>
                    {renderRewardingValues(index)}
                  </>
                )}
              </>
            </IncentiveContainer>
            {state.length > 1 && (
              <Button type="text" onClick={() => handleDeleteIncentive(index)}>
                <IconDelete size={16} />
              </Button>
            )}
          </IncentiveWrapper>
        ))}
      </>
    )
  }
  return (
    <div>
      <StepTitle>
        <Typography.Title uppercase level={5}>
          Incentives
        </Typography.Title>
      </StepTitle>
      <SetpsBody>{renderBody()}</SetpsBody>
      {state.length < 5 && (
        <Button type="text" onClick={handleAddIncentive} color={Colors.BLACK} $startIcon={<IconAdd color={Colors.BLACK} />}>
          Add Incentive
        </Button>
      )}
    </div>
  )
})

export default Incentives
