import styled from 'styled-components'
import Colors from 'styles/Colors'

export const IncentiveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid ${Colors.BORDER2};
  margin-bottom: 16px;
`

export const IncentiveContainer = styled.div`
  width: 100%;
  .lb-single {
    width: 310px;
  }
  .lb-radio-wrapper {
    min-width: 143px;
  }
`

export const InputButtonGroup = styled.div`
  display: flex;
  margin-bottom: 16px;
  .lb-input-number {
    width: 112px;
  }
  .lb-single {
    width: 175px;
  }
`

export const InputButton = styled.div`
  /* background-color: ${Colors.GREY7}; */
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  font-weight: ${(props) => props.theme.typography.h4.fontWeight};
  line-height: ${(props) => props.theme.typography.h4.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.h4.letterSpacing};
  color: ${Colors.GREY3};
  padding: 8px;
  display: flex;
  align-items: center;
`
export const ActionRadioContainer = styled.div`
  padding: 16px 0;
`

export const InputButtonWrapper = styled.div`
  /* min-height: 200px; */
`
