import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const search = (
  params: SegmentationTypes.SegmentSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<SegmentationTypes.SegmentResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.searchSegmentation,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const segmentMembers = (
  params: SegmentationTypes.SegmentSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<SegmentationTypes.SegmentSearchResponse[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getSegmentMembers,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}


export const issueReward = (data: RewardsTypes.IssueRewardBL): Promise<AxiosResponse<ResponseTypes.ResponseData<RewardsTypes.RewardBasicInfo>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.issueReward,
    data,
    withCredentials: false,
    method: 'POST',
  }
  return HttpRequest(requestOptions)
}

export const getActiveOffers = (
  params: RewardsTypes.RewardGetActiveOffersParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getActiveoffers,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveSegment = (
  data: SegmentationTypes.SegmentResponseBL,
  params: SegmentationTypes.SegmentDetails
): Promise<AxiosResponse<ResponseTypes.PagedResponse<SegmentationTypes.SegmentResponseBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.searchSegmentation,
    data,
    params,
    withCredentials: false,
    method: params.segmentCode ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

const SegmentationService = {
  search,
  saveSegment,
  getActiveOffers,
  issueReward,
  segmentMembers
}

export default SegmentationService
