import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Typography from 'lib/components/Typography'

interface PointHeaderProps {
  activeStep: number
}

const PointHeader: React.FC<PointHeaderProps> = ({activeStep}) => {
  return (
    <StyledHeader>
      <Typography.Title level={2}>{activeStep === 0 ? 'Add New Point' : 'Preview'}</Typography.Title>
    </StyledHeader>
  )
}

export default PointHeader
