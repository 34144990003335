import {forwardRef, useImperativeHandle, useState} from 'react'
import {getPointRequestObj} from './util'
import {Col, Row} from 'antd'
import Form from 'lib/components/Form'
import {valueType} from 'antd/es/statistic/utils'
import {cloneDeep} from 'lodash'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import {FormTypes} from 'lib/components/Form/Form'
import Typography from 'lib/components/Typography'
import toast from 'react-hot-toast'
import TextArea from 'lib/components/TextArea'

//import PartnerService from 'app/services/PartnerService'

export interface PointRefType {
  validate(): boolean
  getPointDetails(): InitialStateType
}

export interface AddPointType {
  pointTypeName: string
  pointTypeCode: string
  isRedemptionPointType: boolean
}

interface InitialStateType {
  pointCode: string
  pointName: string
  pointTypeCode: string
  pointDescription: string
  isRedemptionPointType: boolean
  costPerPointValue: number
  costPerPointCurrency: string
}

interface errorStateType {
  pointName: boolean
  pointTypeCode: boolean
  isRedemptionPointType: boolean
}

interface NewPointFormProps {
  currencyOptions: FormTypes.Options[]
  pointTypeOptions: AddPointType[]
}

// const radioOptions: FormTypes.Options[] = [
//   {
//     label: 'Redeemable',
//     value: 'redeemable',
//   },
//   {
//     label: 'Non-Redeemable',
//     value: 'nonRedeemable',
//   },
// ]

const NewPointForm = forwardRef<PointRefType, NewPointFormProps>(({currencyOptions, pointTypeOptions}, ref) => {
  let updatedPointReqObj = getPointRequestObj
  const initialState: InitialStateType = {
    pointCode: updatedPointReqObj.pointCode || '',
    pointName: updatedPointReqObj.pointName || '',
    pointTypeCode: updatedPointReqObj.pointTypeCode || '',
    pointDescription: updatedPointReqObj.pointDescription || '',
    isRedemptionPointType: updatedPointReqObj.isRedemptionPointType,
    costPerPointValue: updatedPointReqObj.pointCurrencyValue.length ? updatedPointReqObj.pointCurrencyValue[0].costPerPoint : 1,
    costPerPointCurrency: updatedPointReqObj.pointCurrencyValue.length ? updatedPointReqObj.pointCurrencyValue[0].currencyCode : '',
  }
  const initialErrorState: errorStateType = {
    pointName: false,
    pointTypeCode: false,
    isRedemptionPointType: false,
  }
  const pointTypeCodeOptions: FormTypes.Options[] = pointTypeOptions.map((item) => ({
    label: `${item.pointTypeName} (${item.isRedemptionPointType ? 'Redeemable' : 'Non-Redeemable'})`,
    value: item.pointTypeCode,
  }))

  const [state, setState] = useState(initialState)
  const [error, setError] = useState(initialErrorState)
  // const [redeem, setRedeem] = useState(updatedPointReqObj.isRedemptionPointType ? 'redeemable' : 'nonRedeemable')

  const handleValidate = (): boolean => {
    if (!state.pointName) {
      setError({...error, pointName: true})
      toast.error('Point Name is required.')
      return false
    }
    if (!state.pointTypeCode) {
      setError({...error, pointTypeCode: true})
      toast.error('Point Type is required.')
      return false
    }
    // if (redeem === '') {
    //   setError({...error, pointTypeCode: true})
    //   toast.error('Point Select one of Redeemable Option.')
    //   return false
    // }
    return true
  }

  const getPointDetails = (): InitialStateType => {
    return state
  }

  useImperativeHandle(ref, () => ({validate: handleValidate, getPointDetails: getPointDetails}))

  const handleChange = (value: valueType, id: string) => {
    const prevState = cloneDeep(state)
    setError(initialErrorState)
    if (id === 'pointTypeCode' && typeof value === 'string') {
      console.log(pointTypeOptions, value)
      console.log(pointTypeOptions.find((x) => x.pointTypeCode === value))
      setState({
        ...prevState,
        [id]: value,
        isRedemptionPointType: pointTypeOptions.find((x) => x.pointTypeCode === value)?.isRedemptionPointType || false,
      })
    } else {
      setState({
        ...prevState,
        [id]: value,
      })
    }
  }

  // const handleRedeemableChange = (value: string, key: string): void => {
  //   setRedeem(value)
  //   const prevState = cloneDeep(state)
  //   setState({
  //     ...prevState,
  //     isRedemptionPointType: value === 'redeemable',
  //   })
  // }

  return (
    <>
      <MarginBottomWrapper>
        <Col span={10}>
          <Form.Input id="pointCode" label="Point ID" name="pointCode" placeholder="Point ID" value={state.pointCode} disabled />
        </Col>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10}>
            <Form.Input
              id="pointName"
              label="Point Name"
              name="pointName"
              placeholder="Enter Point Name"
              value={state.pointName}
              onChange={handleChange}
              status={error.pointName ? 'error' : undefined}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10}>
            <Form.Select
              id="pointTypeCode"
              label="Point Type"
              value={state.pointTypeCode}
              options={pointTypeCodeOptions}
              onChange={handleChange}
              status={error.pointTypeCode ? 'error' : undefined}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Row>
          <Col span={10}>
            <TextArea
              id="pointDescription"
              name="pointDescription"
              label="Point Description"
              placeholder="Enter a Point Description"
              value={state.pointDescription}
              handleChange={handleChange}
            />
          </Col>
        </Row>
      </MarginBottomWrapper>
      {/* <MarginBottomWrapper>
        <Row>
          <Form.Radio boxed id="redeemable" value={redeem} onChange={handleRedeemableChange} options={radioOptions} />
        </Row>
      </MarginBottomWrapper> */}
      <MarginBottomWrapper>
        <Typography.Title level={4} color="primary" uppercase>
          Expiry Configurations
        </Typography.Title>
        <Typography.Text>
          As of now all the points have no expiry configurations. Reach out to support team in case you want to configure one with expiry settings.
        </Typography.Text>
      </MarginBottomWrapper>
      <MarginBottomWrapper>
        <Typography.Title level={4} color="primary" uppercase>
          Cost Per Point
        </Typography.Title>
      </MarginBottomWrapper>
      <Row gutter={9}>
        <Col span={8}>
          <Form.Input
            id="costPerPointValue"
            label="Value"
            name="cost"
            placeholder="Enter Value"
            value={state.costPerPointValue}
            onChange={handleChange}
          />
        </Col>
        <Col span={8}>
          <Form.Select
            id="costPerPointCurrency"
            label="Currency"
            value={state.costPerPointCurrency}
            options={currencyOptions}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </>
  )
})

export default NewPointForm
