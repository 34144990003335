import styled from 'styled-components'
import Colors from 'styles/Colors'

export const PointsBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 90px;
  flex: 1;
  max-width: 100%;
  height: 36px !important;
  background-color: ${Colors.BLUE_LIGHT};
  border-radius: 5px;
  border: 0;
  color: ${Colors.GREY14} !important;
  &:hover {
    background-color: ${Colors.PRIMARY};
  }
  &.active {
    color: #ffffff;
    background-color: ${Colors.PRIMARY};
    &:hover {
      background-color: ${Colors.PRIMARY};
    }
  }
  & + button {
    margin-left: 1px;
  }
`

export const ServiceActionHeaderSection = styled.div`
  padding-bottom: 6px;
`

export const BasicInfoContainer = styled.div`
  padding-top: 46px;
  display: flex;
`

export const BasicInfoCol = styled.div`
  flex: 1;
`

interface TransactionBasicInfoProps {
  noBorder?: boolean
  noFlex?: boolean
}

interface TransactionInfoBlockProps {
  highlight?: boolean
  fullwidth?: boolean
  textAlign?: string
}

export const TransactionBasicInfo = styled.div<TransactionBasicInfoProps>`
  display: ${(props) => (props.noFlex ? '' : 'flex')};
  border-top: ${(props) => (props.noBorder ? 'none' : `1px solid ${Colors.BORDER1}`)};
  padding: 16px 0;
  width: 100%;
  gap: ${(props) => (props.noFlex ? '' : '12px')};
`

export const TransactionInfoBlock = styled.div<TransactionInfoBlockProps>`
  width: ${(props) => (props.fullwidth ? '100%' : '33%')};
  background-color: ${(props) => (props.highlight ? `${Colors.COLOR_YELLOW}` : 'transparent')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  padding: ${(props) => (props.highlight ? `8px` : '')};
`

export const ProductNameStyled = styled.div`
  padding: 8px;
  background-color: ${Colors.GREY12};
  width: 100%;
  text-align: center;
`
export const BoldLabel = styled.span`
  font-size: ${(props) => props.theme.typography.fontSize}px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  color: ${Colors.TEXT_COLOR_PRIMARY};
  line-height: 1.5;
  margin-bottom: 8px;
`

export const TransactionProductDetails = styled.div``

export const AwardModalHeader = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(94, 94, 94, 0.19);
  margin-bottom: 16px;
  padding-bottom: 32px;
`

export const StyleActionModalBodyWrapper = styled.div`
  max-height: '500px';
  overflow-y: 'auto';
  padding: '0 24px';
`

export const TableFilterOptionWrapper = styled.div`
  width: 100%;
`
