import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import {FormEvent, forwardRef, useState, useImperativeHandle} from 'react'
import {userStatus} from './utils'

interface UsersFilterProps {
  handleFilter(formState: UsersFilterState): void
  isFetching: boolean
}

export interface UsersFilterState {
  userName: string
  status: string
  name: string
}

type KeyTypes = keyof UsersFilterState

export interface ResetFormRefType {
  handleReset(): void
  getFormState(): UsersFilterState
}

let statusOptions = [
  {label: 'Active', value: userStatus.ACTIVE},
  {label: 'Deactive', value: userStatus.DEACTIVE},
]

const UsersFilter = forwardRef<ResetFormRefType, UsersFilterProps>(({handleFilter, isFetching}, forwardedRef) => {
  const inititalState: UsersFilterState = {
    userName: '',
    status: '',
    name: '',
  }
  const [state, setInitialState] = useState(inititalState)

  const handleReset = () => {
    setInitialState(inititalState)
  }

  const getFormState = () => {
    return state
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleOnChange = (value: string, key: KeyTypes): void => {
    const prevState = {...state}
    prevState[key] = value
    setInitialState(prevState)
  }

  useImperativeHandle(forwardedRef, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.name} name="name" id="name" placeholder="Search by User Name" onChange={handleOnChange} isDark />
      <Form.Input value={state.userName} name="userIdName" id="userName" placeholder="Search by User Id" onChange={handleOnChange} isDark />
      <Form.Select isDark value={state.status} options={statusOptions} placeholder="Select status" id="status" onChange={handleOnChange} noMargin />
    </DataTable.Filter>
  )
})

export default UsersFilter
