import {useState, FormEvent, useImperativeHandle, forwardRef} from 'react'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'

interface TierFilterProps {
  handleFilter(formState: TierTypes.TierFilterSearch): void
  isFetching: boolean
}

type KeyTypes = keyof TierTypes.TierFilterSearch

export interface ResetTierFormRefType {
  handleReset(): void
  getFormState(): TierTypes.TierFilterSearch
}

const TierFilter = forwardRef<ResetTierFormRefType, TierFilterProps>(({isFetching, handleFilter}, ref) => {
  const initialState: TierTypes.TierFilterSearch = {
    tierSystemKey: '',
  }
  const [state, setInitialState] = useState<TierTypes.TierFilterSearch>(initialState)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }

  const handleReset = () => {
    setInitialState(initialState)
  }

  const getFormState = (): TierTypes.TierFilterSearch => {
    return {
      tierSystemKey: state.tierSystemKey,
    }
  }

  const handleOnChange = (value: string & number & string[], key: KeyTypes): void => {
    const prevState = {...state}
    prevState[key] = value
    setInitialState(prevState)
  }

  useImperativeHandle(ref, () => ({handleReset, getFormState}))

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit} extendedFilters>
      <Form.Input
        value={state.tierSystemKey}
        name="tierSystemKey"
        id="tierSystemKey"
        placeholder="Search by Tier Key"
        onChange={handleOnChange}
        isDark
      />
    </DataTable.Filter>
  )
})

export default TierFilter
