import {AssignedPromotionLabel, ReviewDetailsItem, StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import {genderOptions, getSegmentCategoryLabel, getSegmentRequestObj} from '../utils'
import Typography from 'lib/components/Typography'
import {ReviewRow} from '../../Segment.styled'
import {MarginBottomWrapper, Seperator, UnderlineUrl} from 'app/common/components/Styled/common.styled'
import Button from 'lib/components/Button'
import {Space} from 'antd'
import {states} from 'utils/common'
import Appconfig from 'app/common/helpers/AppConfig'
import {utcDate, utcWithTimeZone} from 'utils/convertDateToString'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {SEGMENT_EDIT} from 'app/common/helpers/UserFunctions'
// import dayjs from 'dayjs'

interface ReviewSegmentProps {
  handleEdit(): void
  isViewOnly?: boolean
}

const ReviewSegments: React.FC<ReviewSegmentProps> = ({isViewOnly, handleEdit}) => {
  const segmentsData = getSegmentRequestObj
  const {segmentName, segmentCode, segmentDescription, segmentRuleData, assignedPromotion, partnerCode, segmentCategory} = segmentsData
  const {activityRule, demographicRule} = segmentRuleData ? segmentRuleData : {activityRule: null, demographicRule: null}
  const showDemographic = demographicRule !== null
  const showActivityRule = activityRule !== null
  const showAssigned = assignedPromotion.length > 0
  const {roles} = useAuth()
  const showEditSegment = roles.includes(SEGMENT_EDIT)

  const onEdit = () => {
    handleEdit()
  }

  const getStates = () => {
    if (demographicRule) {
      return states
        .filter((item) => demographicRule.values.includes(item.value))
        .map((state) => state.label)
        .join(', ')
    }
    return ''
  }

  const getAssignedPromotion = () => {
    // return assignedPromotion.map((x) => x.PromotionCode)

    return assignedPromotion.map((x) => (
      <UnderlineUrl href={`/promotions/view/${x.promotionCode}`}>
        <AssignedPromotionLabel>{x.promotionName}</AssignedPromotionLabel>
      </UnderlineUrl>
    ))
  }

  const getZipcodes = () => {
    if (demographicRule) {
      return demographicRule.values.join(', ')
    }
    return ''
  }

  const getGenders = () => {
    if (demographicRule) {
      return genderOptions.filter((item) => demographicRule.values.includes(item.value as string)).map((gender) => gender.label)
    }
    return []
  }

  const getDemographicDetails = (type: string): JSX.Element => {
    switch (type) {
      case 'STATE': {
        return (
          <>
            {demographicRule && demographicRule.isAll && (
              <ReviewDetailsItem>
                <StyledReviewLabel>All States</StyledReviewLabel>
                <StyledReviewValue>Yes</StyledReviewValue>
              </ReviewDetailsItem>
            )}
            {!demographicRule?.isAll && (
              <ReviewDetailsItem>
                <StyledReviewLabel>States</StyledReviewLabel>
                <StyledReviewValue>{getStates()}</StyledReviewValue>
              </ReviewDetailsItem>
            )}
          </>
        )
      }
      case 'ZIPCODE': {
        return (
          <>
            <ReviewDetailsItem>
              <StyledReviewLabel>Zipcodes</StyledReviewLabel>
              <StyledReviewValue>{getZipcodes()}</StyledReviewValue>
            </ReviewDetailsItem>
          </>
        )
      }
      case 'AGE': {
        return (
          <>
            <Space size="middle">
              <ReviewDetailsItem>
                <StyledReviewLabel>Min Age</StyledReviewLabel>
                <StyledReviewValue>{demographicRule?.min}</StyledReviewValue>
              </ReviewDetailsItem>
              <ReviewDetailsItem>
                <StyledReviewLabel>Max Age</StyledReviewLabel>
                <StyledReviewValue>{demographicRule?.max}</StyledReviewValue>
              </ReviewDetailsItem>
            </Space>
          </>
        )
      }
      case 'GENDER': {
        return (
          <>
            <ReviewDetailsItem>
              <StyledReviewLabel>Gender</StyledReviewLabel>
              <StyledReviewValue>{getGenders().join(', ')}</StyledReviewValue>
            </ReviewDetailsItem>
          </>
        )
      }
      default:
        return <></>
    }
  }

  return (
    <>
      <ReviewRow>
        <div>
          <MarginBottomWrapper>
            <Typography.Text color="primary" strong uppercase>
              Segment Information
            </Typography.Text>
          </MarginBottomWrapper>
          {segmentCode && (
            <ReviewDetailsItem>
              <StyledReviewLabel>Segment ID</StyledReviewLabel>
              <StyledReviewValue>{segmentCode}</StyledReviewValue>
            </ReviewDetailsItem>
          )}
          <ReviewDetailsItem>
            <StyledReviewLabel>Name</StyledReviewLabel>
            <StyledReviewValue>{segmentName}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Description</StyledReviewLabel>
            <StyledReviewValue>{segmentDescription}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Segment Type</StyledReviewLabel>
            <StyledReviewValue>{getSegmentCategoryLabel(segmentCategory)}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Partner Name</StyledReviewLabel>
            <StyledReviewValue>{partnerCode}</StyledReviewValue>
          </ReviewDetailsItem>
        </div>
        {!isViewOnly && showEditSegment && (
          <div>
            <Button onClick={() => onEdit()} size="small" type="link" disabled={false}>
              {/* Edit */}
            </Button>
          </div>
        )}
      </ReviewRow>
      <Seperator width="100%" />
      <ReviewRow>
        <div>
          <MarginBottomWrapper>
            <Typography.Text color="primary" strong uppercase>
              SEGMENT CRITERIA
            </Typography.Text>
          </MarginBottomWrapper>
          <ReviewDetailsItem>
            <StyledReviewLabel>{segmentRuleData ? (showActivityRule ? 'Activity' : 'Demographic') : 'Manual'}</StyledReviewLabel>
            <StyledReviewValue>{showActivityRule ? activityRule?.type : demographicRule?.type}</StyledReviewValue>
          </ReviewDetailsItem>
          {showActivityRule && (
            <Space size="middle">
              <ReviewDetailsItem>
                <StyledReviewLabel>Start Date</StyledReviewLabel>
                <StyledReviewValue>
                  {utcDate(utcWithTimeZone(activityRule?.startDate || new Date().toISOString()), Appconfig.DATE_TIME_FORMAT)}
                </StyledReviewValue>
              </ReviewDetailsItem>
              {activityRule?.endDate && (
                <ReviewDetailsItem>
                  <StyledReviewLabel>End Date</StyledReviewLabel>
                  <StyledReviewValue>{utcDate(utcWithTimeZone(activityRule?.endDate || ''), Appconfig.DATE_TIME_FORMAT)}</StyledReviewValue>
                </ReviewDetailsItem>
              )}
            </Space>
          )}
          {showDemographic && demographicRule && getDemographicDetails(demographicRule.type)}
        </div>
        {showEditSegment && !isViewOnly && (
          <div>
            <Button onClick={() => onEdit()} size="small" type="link" disabled={false}>
              {/* Edit */}
            </Button>
          </div>
        )}
      </ReviewRow>
      {showAssigned && (
        <ReviewRow>
          <div>
            <MarginBottomWrapper>
              <Typography.Text color="primary" strong uppercase>
                THIS SEGMENT IS ASSIGNED TO THE FOLLOWING PROMOTIONS :
              </Typography.Text>
            </MarginBottomWrapper>
            <ReviewDetailsItem>
              <StyledReviewValue>{getAssignedPromotion()}</StyledReviewValue>
            </ReviewDetailsItem>
          </div>
        </ReviewRow>
      )}
    </>
  )
}

export default ReviewSegments
