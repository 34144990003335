import Button from 'lib/components/Button'
import Form from 'lib/components/Form'
import TextArea from 'lib/components/TextArea'
import {useEffect, useState} from 'react'
import {UpdateContainer} from './QuickActions.styled'
import TierService from 'app/services/TierService'
import {toast} from 'react-hot-toast'
import MembershipService from 'app/services/MemberService'
import dayjs from 'dayjs'
import Appconfig from 'app/common/helpers/AppConfig'
interface optionsProps {
  value: string
  label: string
}

interface InitialStateProps {
  tierCode: string
  remarks: string
}
interface UpdateTierProps {
  memberDetail: MemebershipTypes.MemberDataDetailBL
  handleToggleAccordion: (name: string) => void
  memberUpdate: () => void
}

const UpdateTierName: React.FC<UpdateTierProps> = ({memberDetail, handleToggleAccordion, memberUpdate}) => {
  // console.log(memberDetail)
  const initialState = {
    tierCode: memberDetail?.membershipTierData?.membershipTierCode,
    remarks: '',
  }

  const tierPropsDetail: TierTypes.TierSearch = {
    PartnerCode: memberDetail.partnerCode,
    ActiveTierDateTime:  dayjs().format(Appconfig.DATE_FORMAT),
    TierStatus: "APPROVED"
  }

  const [state, setState] = useState(initialState)
  const [options, setOptions] = useState<optionsProps[]>([])
  const [tiersResponse, setTiersResponse] = useState<TierTypes.TierDetails[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const handleStateUpdate = (value: string, key: keyof InitialStateProps) => {
    const prevState = {...state}
    prevState[key] = value
    setState(prevState)
  }

  const changeTierResponseToOptions = (tiersResponse: TierTypes.TierDetails[]) => {
    const options: optionsProps[] = tiersResponse.map((item) => ({
      value: item.membershipTierKey,
      label: item.membershipTierName,
    }))
    console.log(options)
    setOptions(options)
  }
  // const setCurrentTier = (tiersResponse: TierTypes.TierBL[]) => {
  //   const currentTier = tiersResponse.find((item) => item.membershipTierKey.toUpperCase() === memberDetail.membershipTierKey.toUpperCase())
  //   if (currentTier) setState((pre) => ({...pre, tierName: currentTier?.membershipTierName}))
  // }

  const handleUpdate = () => {
    const payload = {...state}
    const selectedTier = tiersResponse.find((item) => item.membershipTierKey.toUpperCase() === payload.tierCode.toUpperCase())
    if (!selectedTier) {
      return
    }

    const tierProps: TierTypes.ChangeTierDetail = {
      MembershipCustomerId: memberDetail.membershipCustomerId,
      Remarks: payload.remarks,
      MembershipTierKey: selectedTier.membershipTierKey,
    }
    setIsLoading(true)
    putTier(tierProps, selectedTier)
  }

  const getTiers = (propsObject: TierTypes.TierSearch) => {
    TierService.getTiers(propsObject).then((res) => {
      const {data} = res.data
     if(data.length===0){
      return;
     }
     console.log(data)
     const filterData = data[0];

      changeTierResponseToOptions(filterData.tierList )

      setTiersResponse(filterData.tierList)
    })
  }

  const putTier = (params: TierTypes.ChangeTierDetail, body: TierTypes.TierDetails) => {
    MembershipService.PutMemberTier(params)
      .then((res) => {
        console.log(res)
        const tier = tiersResponse.find((item) => item.membershipTierKey.toUpperCase() === params.MembershipTierKey.toUpperCase())
        console.log(tier)
        memberUpdate()
        handleToggleAccordion('')
        toast.success('Tier level has been updated.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getTiers(tierPropsDetail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UpdateContainer>
      <Form.Select id="tierCode" label="Change To" options={options} onChange={handleStateUpdate} value={state.tierCode} />
      <TextArea
        textAreaProps={{placeholder: 'Remarks', rows: 4, value: state.remarks, onChange: (e) => handleStateUpdate(e.target.value, 'remarks')}}
        id="tierRemarks"
      />
      <Button onClick={handleUpdate} loading={isLoading} disabled={isLoading} block>
        Update Tier
      </Button>
    </UpdateContainer>
  )
}

export default UpdateTierName
