import IconChevronDown from 'app/common/icons/IconChevronDown'
import Typography from 'lib/Typography'
import {useState} from 'react'
import {toast} from 'react-hot-toast'
import AccountInfoCard, {AccountInfoCardDataType} from './AccountInfoCard'
import AccountListModal from './AccountListModal'
import {MergeAccountButtonContainer, MergeAccountContainer, MergeIntoLabel} from './MergeAccounts.styled'
import MergeCard from './MergeCard'
import MergeConfirmationModal from './MergeConfirmationModal'
import MemberPointManagementService from 'app/services/MemberPointManagementService'
import {useLocation, useNavigate} from 'react-router-dom'
import Button from 'lib/components/Button'

interface MergeAccountsProps {
  memberDetail: MemebershipTypes.MembershipDataBL
  handleToggleAccordion: (name: string) => void
  showMemberMerge: boolean
  // setMemberDetail: React.Dispatch<React.SetStateAction<MemebershipTypes.MembershipDataBL | undefined>>
}

const MergeAccounts: React.FC<MergeAccountsProps> = ({memberDetail, handleToggleAccordion, showMemberMerge}) => {
  const navigate = useNavigate()
  const accountInfoData: AccountInfoCardDataType = {
    name: memberDetail.membershipFullName,
    accountNo: memberDetail.membershipCustomerId,

    gender: memberDetail.membershipGender,
    phone: memberDetail.membershipPhone,
    email: memberDetail.membershipEmailId,
  }

  const [isModal, showModal] = useState(false)
  const [mergeMemberData, setMergeMemberData] = useState<AccountInfoCardDataType>()
  const [mergeConfirmationModal, showMergeConfirmationModal] = useState<boolean>(false)
  const {pathname} = useLocation()

  const handleActionClick = () => {
    // console.log(types)set
    setMergeMemberData(undefined)
  }

  const handleOpenModal = () => {
    showModal(!isModal)
  }

  const isSameUser = (sourceMember: MemebershipTypes.MembershipDataBL, targetMember: MemebershipTypes.MembershipDataBL) => {
    return sourceMember.membershipCustomerId === targetMember.membershipCustomerId
  }

  // const isDeletedUser = (targetMember: MemebershipTypes.MembershipDataBL) => {
  //   return targetMember.membershipStatusKey.toUpperCase() === 'Deleted'.toUpperCase()
  // }

  const isValidUserSelected = (sourceMember: MemebershipTypes.MembershipDataBL, targetMember: MemebershipTypes.MembershipDataBL) => {
    if (isSameUser(sourceMember, targetMember)) {
      toast.error('You cannot select yourself . Please choose a different account to proceed')
      showModal(false)
      return false
    }

    // if (isDeletedUser(targetMember)) {
    //   toast.error('You can not selected Deleted User.')
    //   return false
    // }

    return true
  }

  const handleRowClick = (row: MemebershipTypes.MembershipDataBL) => {
    if (!isValidUserSelected(memberDetail, row)) {
      return
    }
    const mergingMemberData: AccountInfoCardDataType = {
      name: row.membershipFullName,
      accountNo: row.membershipCustomerId,

      gender: row.membershipGender,
      phone: row.membershipPhone,
      email: row.membershipEmailId,
    }
    setMergeMemberData(mergingMemberData)
    showModal(false)
  }

  const toggleMergeConfirmation = () => {
    showMergeConfirmationModal(!mergeConfirmationModal)
  }
  const naviationToTargetMember = (targetMember: String) => {
    return pathname.split('/').slice(0, -1).join('/') + `/${targetMember}`
  }

  const handleMergeAccount = () => {
    if (mergeMemberData) {
      const parmsObj: MemberPointManagmentTypes.MemberMergeDetails = {
        SourceMembershipCode: memberDetail.membershipCustomerId,
        TargetMembershipCode: mergeMemberData.accountNo,
      }

      MemberPointManagementService.memberMerge(parmsObj)
        .then((res) => {
          const {data} = res.data
       

          toast.success('Account has been merged')

          setMergeMemberData(undefined)
          const encodedMemberShipId = btoa(data.targetMembershipCode)

          navigate(naviationToTargetMember(encodedMemberShipId), {replace: true})
        })
        .finally(() => {
          toggleMergeConfirmation()
          handleToggleAccordion('')
        })
    }
  }

  return (
    <MergeAccountContainer>
      <AccountInfoCard handleOnClick={handleActionClick} data={accountInfoData} />
      <MergeIntoLabel>
        <Typography variant="label" component="div">
          Merge Into <IconChevronDown size={16} />
        </Typography>
      </MergeIntoLabel>
      {!mergeMemberData && <MergeCard showAllAccount={handleOpenModal} />}
      {isModal && <AccountListModal rowClick={handleRowClick} setOpen={handleOpenModal} showMemberMerge={showMemberMerge} />}
      {mergeMemberData && (
        <>
          <AccountInfoCard handleOnClick={handleActionClick} root={false} data={mergeMemberData} />
          <MergeAccountButtonContainer>
            <Button type="primary" block onClick={toggleMergeConfirmation}>
              Merge accounts
            </Button>
          </MergeAccountButtonContainer>
        </>
      )}
      {mergeConfirmationModal && <MergeConfirmationModal closeModal={toggleMergeConfirmation} handleMergeAccount={handleMergeAccount} />}
    </MergeAccountContainer>
  )
}

export default MergeAccounts
