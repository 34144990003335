import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {EXPORT_RECORDS, IMPORT_ACTION_DETAIL, IMPORT_DETAIL} from 'app/common/helpers/UserFunctions'
import IconDownload from 'app/common/icons/IconDownload'
import Popover from 'lib/components/Popover'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import {StyledMenuItem} from 'app/common/components/Styled/common.styled'
import {DownLoadFile, getUrlDownloadImportFile} from '../importUtils'
import ImportExportSevice from 'app/services/ImportExportService'
import Appconfig from 'app/common/helpers/AppConfig'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'File Name',
    id: 'fileName',
    textAlign: 'left',
    width: '22%',
  },
  {
    title: 'Data Type',
    id: 'dataType',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Status',
    id: 'importState',
    textAlign: 'left',
    width: '10%',
  },
  {
    title: 'Record Count',
    id: 'recordCount',
    textAlign: 'center',
    width: '15%',
  },
  {
    title: 'Successful Records',
    id: 'successRecordCount',
    textAlign: 'center',
    width: '15%',
  },
  {
    title: 'Failed Records',
    id: 'failedRecordCount',
    textAlign: 'center',
    width: '15%',
  },
  {
    title: '',
    id: 'actions',
    textAlign: 'left',
    width: '5%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  importsDataResponse: ResponseTypes.PagedResponse<ImportExportTypes.ImportDataResponseBL[]>
  handleRefresh: (value: boolean, pointCode: string) => void
}

const SearchResults = ({isFetching, importsDataResponse, handleRefresh}: SearchResultProps) => {
  const {roles} = useAuth()
  console.log(roles)
  // const showImportDetail = roles.includes(POINT_DETAIL) || roles.includes(POINT_ACTION_DETAIL)
  const showImportDetail = roles.includes(IMPORT_ACTION_DETAIL) && roles.includes(IMPORT_DETAIL)
  const showExportRecords = roles.includes(EXPORT_RECORDS)

  dataTableColumns = dataTableColumns.map((item) => {
    if (item.id === 'enabled') {
      return {...item, title: ''}
    }
    return item
  })

  const handleDownload = (rowIndex: number, item: ImportExportTypes.ImportDataResponseBL, status: string) => {
    console.log(item)
    const url = getUrlDownloadImportFile(item.importType)
    const params: ImportExportTypes.ExportImportFileParams = {
      ImportBatchCode: item.fileName,
      Status: status,
      FileType: Appconfig.defaultFileType,
    }

    ImportExportSevice.downloadImportFile(url, params).then((res) => {
      const currentDate = new Date().getTime().toString()

      const fileName = item.importType + currentDate + '.' + params.FileType.toLocaleLowerCase()
      DownLoadFile(res.data, fileName)
    })
  }

  const renderActionList = (rowIndex: number, item: ImportExportTypes.ImportDataResponseBL) => {
    const disabledFailedRecords = item.failedRecordCount === 0
    const disabledSuccessfullRecords = item.successRecordCount === 0
    const disabledCancelledRecords = item.cancelRecordCount === 0
    return (
      <>
        <StyledMenuItem title="Download Failed Records">
          <Button
            type="text"
            disabled={!showExportRecords || disabledFailedRecords}
            onClick={() => handleDownload(rowIndex, item, 'FAILED')}
            $startIcon={<IconDownload size={16} />}
          >
            Failed Records
          </Button>
        </StyledMenuItem>
        <StyledMenuItem title="Download Successful Records">
          <Button
            type="text"
            disabled={!showExportRecords || disabledSuccessfullRecords}
            onClick={() => handleDownload(rowIndex, item, 'SUCCESSFUL')}
            $startIcon={<IconDownload size={16} />}
          >
            Successful Records
          </Button>
        </StyledMenuItem>
        <StyledMenuItem title="Download Cancelled Records">
          <Button
            type="text"
            disabled={!showExportRecords || disabledCancelledRecords}
            onClick={() => handleDownload(rowIndex, item, 'CANCELLED')}
            $startIcon={<IconDownload size={16} />}
          >
            Cancelled Records
          </Button>
        </StyledMenuItem>
      </>
    )
  }

  const renderRowActions = (rowIndex: number, item: ImportExportTypes.ImportDataResponseBL) => {
    if (showImportDetail) {
      return <Popover btnContent={<IconThreeDots />} content={renderActionList(rowIndex, item)} />
    }
    return null
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (importsDataResponse && importsDataResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = importsDataResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          fileName: item.fileName,
          dataType: item.dataType || '',
          importState: item.importState,
          recordCount: item.recordCount,
          successRecordCount: item.successRecordCount,
          failedRecordCount: item.failedRecordCount,
          actions: renderRowActions(index, item),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
    </>
  )
}

export default SearchResults
