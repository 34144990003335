import { useAuth } from 'app/pages/Auth/AuthContext'
import LoginService from 'app/services/LoginService'
import {Navigate, Outlet} from 'react-router-dom'
import { getRedirectPath, removeRedirectPath } from 'utils/common'

const UnprotectedLayout = () => {
  const { isLoggedIn, logout } = useAuth()
  const redirectPath = getRedirectPath()
  const authToken = LoginService.getSessionToken()
  const authPaths = [
    '/',
    '/login',
    '/forgot-pass',
    '/ResetPassword',
  ]

  if (authToken && window.location.pathname === '/ResetPassword') {
    logout();
    window.location.reload()
  }

  if (isLoggedIn) {
    if (authPaths.includes(window.location.pathname)) {
      // if (user?.role === 'Birdie') {  // 
        let path = '/dashboard'
        if (redirectPath && redirectPath.pathName?.includes('/dashboard')) {
          path = redirectPath.pathName
        }
        removeRedirectPath()
        return <Navigate replace to={path} />
      // }
    }
  }

  return (
    <div>
      <Outlet />
    </div>
  )
}

export default UnprotectedLayout
