const IconRefresh = ({size = 22, color = 'currentColor'}: IconType.Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g>
        <path
          d="M 3.1963838938053097 14.095086548672567  C 2.0453265663716818 11.195239292035401  2.6117535575221242 7.99994371681416  4.494103734513274 5.6997969911504445  L 4.475897282123894 6.985372637168142  L 7.0642679546902665 7.039993030088497  L 7.162382172389381 1.3363645345132753  L 1.4597894290265485 1.2392627646017709  L 1.4061814838938056 3.827633437168142  L 2.6654749175221237 3.8448287364601774  C 0.059004475044247204 6.871296701061948  -0.7522484453097348 11.165007497522126  0.7891838909734512 15.050890860353984  C 2.4763206697345135 19.305243072743366  6.549519820176992 21.981626789557524  10.971663890973451 21.981626789557524  C 11.718130510442478 21.981626789557524  12.474721607787611 21.90475326035398  13.23131270513274 21.747976626725666  L 12.702302262654868 19.213206131150443  C 8.701968280353983 20.04667593646018  4.7034468644247776 17.895263228495576  3.196427395398229 14.09503657362832  Z M 21.209668495575222 6.947878407079646  C 19.237285557522128 1.977562477876106  14.004976106194691 -0.8383888495575225  8.76950761061947 0.2519992920353977  L 9.297508194690266 2.7867697876106186  C 13.29784217699115 1.953299982300884  17.296363592920358 4.104712690265486  18.803383061946903 7.902867840707962  C 19.955450247787613 10.80478660176991  19.38801339823009 14.003189433628314  17.503643504424783 16.303077221238937  L 17.519827132743366 15.00636723893805  L 14.930446601769916 14.957816353982297  L 14.842449093805314 20.66040909734513  L 20.545041837168146 20.748406605309732  L 20.593592722123898 18.16003593274336  L 19.343413907964603 18.143852304424776  C 21.94289302300885 15.119455844247783  22.75103868672566 10.830923808849553  21.209580456637166 6.947888764601767  Z "
          fillRule="nonzero"
          fill="#0062ff"
          stroke="none"
        />
      </g>
    </svg>
  )
}

export default IconRefresh
