import styled, {css} from 'styled-components'
import Colors from 'styles/Colors'

interface CheckboxGroupWrapperProps {
  $isBoxed?: boolean
  $isVertical?: boolean
  $hideText?: boolean
  $isDark?: boolean
}

export const CheckboxGroupWrapper = styled.div<CheckboxGroupWrapperProps>`
  ${(props) =>
    props.$isVertical &&
    css`
      .lb-checkbox-group {
        flex-direction: column;
      }
      .lb-checkbox-wrapper {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .lb-checkbox-wrapper + .lb-checkbox-wrapper {
        margin-left: 0;
      }
    `}
  ${(props) =>
    props.$hideText &&
    css`
      .lb-checkbox-group-item > span:last-child {
        display: none;
      }
    `}
  ${(props) =>
    props.$isDark &&
    css`
      .lb-checkbox-group-item > span:last-child {
        color: ${Colors.WHITE};
      }
      .lb-checkbox .lb-checkbox-inner {
        background-color: rgb(0, 23, 151);
        border-color: ${Colors.WHITE};
        transition: none !important;
      }
      .lb-checkbox-wrapper:not(.lb-checkbox-wrapper-disabled):hover .lb-checkbox:not(.lb-checkbox-disabled):after {
        /* border-color: ${Colors.WHITE} !important; */
        border: 1px solid ${Colors.WHITE} !important;
        transition: none !important;
      }
      .lb-checkbox-wrapper:not(.lb-checkbox-wrapper-disabled) {
        &:hover {
          .lb-checkbox:not(.lb-checkbox-disabled) .lb-checkbox-inner {
            background-color: rgb(0, 23, 151) !important;
            transition: none !important;
            border-color: ${Colors.WHITE};
          }
        }
      }
    `}
`
