import DataTable from 'lib/components/DataTable'
// import PromotionStatusService from 'app/services/PromotionStatusService'
import Form from 'lib/components/Form'
import {FormEvent, forwardRef, useImperativeHandle, useState} from 'react'

interface PromotionsFilterProps {
  handleFilter(formState: PromotionsFilterState): void
  isFetching: boolean
}

// type ValueType = string[]

export interface PromotionsFilterState {
  promotionName: string
  dateRange: string[]
  status: string
}

type KeyTypes = keyof PromotionsFilterState

let statusOptions = [
  {label: 'Active', value: 'ACTIVE'},
  {label: 'Approved', value: 'APPROVED'},
]

export interface ResetFormRefType {
  handleReset(): void
}

const OptInFilter = forwardRef<ResetFormRefType, PromotionsFilterProps>(({handleFilter, isFetching}, forwardedRef) => {
  const initialState: PromotionsFilterState = {
    promotionName: '',
    dateRange: [],
    status: '',
  }
  // const reqStatusParams: PromotionStatusType.PromotionStatusDetails = {}

  const [state, setState] = useState<PromotionsFilterState>(initialState)

  const handleReset = () => {
    setState(initialState)
  }

  useImperativeHandle(forwardedRef, () => ({handleReset}))

  const handleOnChange = (value: string & string[], key: KeyTypes) => {
    const prevState = {...state}
    prevState[key] = value
    setState(prevState)
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFilter(state)
  }
  // const getStatus = (reqParams: PromotionStatusType.PromotionStatusDetails) => {
  //   PromotionStatusService.getStatus(reqParams).then((res) => {
  //     const {data} = res.data

  //     statusOptions = data
  //       .filter((item, index) => data.findIndex((x) => x.promotionStatusCode.toUpperCase() === item.promotionStatusCode.toUpperCase()) === index)
  //       .map((item) => ({
  //         label: item.promotionStatusName,
  //         value: item.promotionStatusCode,
  //       }))
  //   })
  // }

  // useEffect(() => {
  //   getStatus(reqStatusParams)
  // }, [])

  return (
    <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit}>
      <Form.Input value={state.promotionName} name="promotionName" id="promotionName" placeholder="Search by Name" onChange={handleOnChange} isDark />
      <Form.DateRangePicker
        id="dateRange"
        rangePickerProps={{
          onChange: handleOnChange,
          value: state.dateRange,
        }}
      />
      <Form.Select isDark value={state.status} options={statusOptions} placeholder="Select status" id="status" onChange={handleOnChange} noMargin />
    </DataTable.Filter>
  )
})

export default OptInFilter
