import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const getProductCategory = (
  params?: ProductTypes.CategoryRequest
): Promise<AxiosResponse<ResponseTypes.ResponseData<ProductTypes.ProductMasterCategoryResponse[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getProductCategory,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const getProductCategoryValue = (
  params?: ProductTypes.CategoryValuesRequest
): Promise<AxiosResponse<ResponseTypes.ResponseData<ProductTypes.ProductCategoryValuesResponse[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getProductCategoryValue,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const getProducts = (
  params?: ProductMasterTypes.ProductMasterSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<ProductMasterTypes.ProductMasterResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getProducts,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveProduct = (
  data: ProductMasterTypes.ProductMasterResponseBL,
  params: ProductMasterTypes.ProductDetails
): Promise<AxiosResponse<ResponseTypes.PagedResponse<ProductMasterTypes.ProductMasterResponseBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getProducts,
    data,
    params,
    withCredentials: false,
    method: params.productCode ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

const ProductService = {
  getProductCategory,
  getProductCategoryValue,
  getProducts,
  saveProduct
}

export default ProductService
