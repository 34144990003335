import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import {useState} from 'react'
import Form from 'lib/components/Form'
import {CheckboxValueType} from 'antd/es/checkbox/Group'
import IconEdit from 'app/common/icons/IconEdit'
import Appconfig from 'app/common/helpers/AppConfig'
import IconReadOnly from 'app/common/icons/IconReadOnly'
import {formatSelectedFunction} from '../../Functions/utils'
import {getRolesRequestObj, updateRoleRequestObj} from '../utils'

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Select',
    id: 'selectedRow',
    textAlign: 'left',
    width: '10%',
  },
  {
    title: 'Function Name',
    id: 'functionName',
    textAlign: 'left',
    width: '50%',
  },
  {
    title: 'Edit or Read only',
    id: 'editOrReadOnly',
    textAlign: 'right',
    width: '30%',
  },
]

interface FunctionSearchResultsProps {
  isReview: boolean
  functionsData: ResponseTypes.PagedResponse<UserManagementTypes.FunctionInfoType[]>
  handleOnPageChange(page: number, pageSize: number): void
  paginationObj: PaginationType.PaginationDetails
  individualRole: UserManagementTypes.RoleResponseType
  categoriesList: UserManagementTypes.CategoryInfoType[]
}

const FunctionSearchResults: React.FC<FunctionSearchResultsProps> = ({
  isReview,
  functionsData,
  handleOnPageChange,
  paginationObj,
  individualRole,
  categoriesList,
}) => {
  let roleReqObj = getRolesRequestObj
  const [selectedFuncs, setSelectedfuncs] = useState(individualRole.functions)
  const count = isReview ? selectedFuncs.length : functionsData.pageInfo.count
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS
  //const reviewFunctionData = functionsData.data.filter((item) => selectedFuncs.findIndex((func) => func.functionCode === item.functionCode) !== -1)

  const handleSelectFunction = (value: CheckboxValueType[], selectedFunc: UserManagementTypes.FunctionInfoType) => {
    let updatedFunctionReqData: UserManagementTypes.FunctionReqType[] = []
    const isFunctionExist = selectedFuncs.findIndex((item) => selectedFunc.functionCode === item.functionCode) !== -1
    if (isFunctionExist) {
      updatedFunctionReqData = selectedFuncs.filter((item) => selectedFunc.functionCode !== item.functionCode)
    } else {
      const selectedFunction = formatSelectedFunction(value[0] as string, functionsData.data, categoriesList)
      updatedFunctionReqData = [...selectedFuncs, selectedFunction]
    }
    setSelectedfuncs(updatedFunctionReqData)
    roleReqObj = {
      ...roleReqObj,
      functions: updatedFunctionReqData,
    }
    updateRoleRequestObj(roleReqObj)
  }

  const checkIfSelectedFunction = (currFunc: any) => {
    return [selectedFuncs.find((item) => item.functionCode === currFunc.functionCode)?.functionCode || '']
  }

  const renderCheckboxes = (index: number, item: any) => {
    return (
      <Form.Checkbox
        id={`${index}`}
        value={checkIfSelectedFunction(item)}
        options={[{label: item.functionName, value: item.functionCode}]}
        onChange={(val, id) => handleSelectFunction(val, item)}
        disabled={isReview}
        hideText
      />
    )
  }

  const renderRowActions = (isEdit: boolean) => {
    return isEdit ? <IconEdit size={16} /> : <IconReadOnly size={16} />
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    const data = isReview ? selectedFuncs : functionsData.data
    if (data.length) {
      const dataTableRows: DataTableTypes.Row[] = []
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          selectedRow: renderCheckboxes(index, item),
          functionName: item.functionName,
          editOrReadOnly: renderRowActions(item.isEdit),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.TotalResult noOfResults={count} />
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={false} columns={dataTableColumns} rows={generateRowData()} />
      {!isReview && (
        <DataTable.Pagination
          onChange={handleOnPageChange}
          current={functionsData.pageInfo.pageIndex}
          pageSizeOptions={pageSizeOptions}
          total={count}
          defaultPageSize={10}
          pageSize={paginationObj.PageCount}
          showSizeChanger
        />
      )}
    </>
  )
}

export default FunctionSearchResults
