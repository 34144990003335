import {forwardRef, useImperativeHandle, useState} from 'react'
import {
  BasicRoleInfoWrapper,
  // CopyFunctionTitle,
  // CopyFunctionsWrapper,
  FunctionActionButtons,
  FunctionActionHeader,
  FunctionActionLabel,
  FunctionActionWrapper,
  FunctionDetailsWrapper,
  FunctionFlexWrapper,
  RoleInputWrapper,
  RolesFormBodyContainer,
  // StyledCopyButton,
} from '../../UserManagementStyled'
import Form from 'lib/components/Form'
import {SortFunctionsByOptions, getRolesRequestObj, updateRoleRequestObj} from '../utils'
import {Col, Row} from 'antd'
import IconFilter from 'app/common/icons/IconFilter'
import IconRefresh from 'app/common/icons/IconRefresh'
import {FormTypes} from 'lib/components/Form/Form'
import FunctionSearchResults from './FunctionSearchResults'
import Typography from 'lib/components/Typography'
import IconSettings from 'app/common/icons/IconSettings'
import Button from 'lib/components/Button'
import {CenteredAlignedLabel} from 'app/common/components/Styled/common.styled'
import IconSort from 'app/common/icons/IconSort'
import {toast} from 'react-hot-toast'

export interface RoleRefType {
  validate(): boolean
}

interface RoleInfoInitialStateType {
  roleCode: string
  roleName: string
  // category: string
  // copyFromId: string
  categories: string[]
  sortBy: string
}

interface NewRoleFormProps {
  roleData: UserManagementTypes.RoleResponseType
  functionsData: ResponseTypes.PagedResponse<UserManagementTypes.FunctionInfoType[]>
  handleOnPageChange(page: number, pageSize: number): void
  paginationObj: PaginationType.PaginationDetails
  categoriesList: UserManagementTypes.CategoryInfoType[]
  handleFilterAndSort(category: string[], sortby: string): void
}

interface RoleErrorType {
  roleCode: boolean
  roleName: boolean
}

const NewRoleForm = forwardRef<RoleRefType, NewRoleFormProps>(
  ({roleData, functionsData, handleOnPageChange, paginationObj, categoriesList, handleFilterAndSort}, ref) => {
    let roleReqObj = getRolesRequestObj
    const initialState: RoleInfoInitialStateType = {
      roleCode: roleData.roleCode || '',
      roleName: roleData.roleName || '',
      // category: '',
      // copyFromId: '',
      categories: [],
      sortBy: 'ALL,asc',
    }

    const errorInitialState: RoleErrorType = {
      roleCode: false,
      roleName: false,
    }
    //const {roleCode} = useParams<AddNewRoleParam>()
    const [state, setInititalState] = useState<RoleInfoInitialStateType>(initialState)
    const [error, setErrorState] = useState(errorInitialState)
    const [isReview, setIsReview] = useState<boolean>(false)
    // const [isCopying, setIsCopying] = useState<boolean>(false)
    // const [isCopied, setIsCopied] = useState<boolean>(false)

    const handleValidate = (): boolean => {
      if (!state.roleName) {
        setErrorState({...error, roleName: true})
        toast.error('Please enter a Role Name.')
        return false
      }
      return true
    }

    useImperativeHandle(ref, () => ({validate: handleValidate}))

    const handleChange = (value: string | number | FormTypes.ValueType, key: keyof RewardsTypes.RewardResponseBL) => {
      setInititalState({
        ...state,
        [key]: value,
      })
      roleReqObj = {
        ...roleReqObj,
        [key]: value,
      }
      updateRoleRequestObj(roleReqObj)
    }

    // const handleCopyFunction = () => {
    //   setIsCopying(true)
    //   setTimeout(() => {
    //     setIsCopying(false)
    //     setIsCopied(true)
    //   }, 2000)
    // }

    // const handleUndoAction = () => {
    //   setIsCopying(true)
    //   setTimeout(() => {
    //     setIsCopying(false)
    //     setIsCopied(false)
    //   }, 2000)
    // }

    const handleFunctionAction = (action: string) => {
      setIsReview(action === 'review')
    }
    const handleResetFunctionFilter = () => {
      setInititalState({
        ...state,
        categories: [],
        sortBy: 'ALL,asc',
      })
      handleFilterAndSort([], 'ALL,asc')
    }

    const handleOnCategoryChange = (value: string[], key: string) => {
      handleFilterAndSort(value, state.sortBy)
      setInititalState({
        ...state,
        [key]: value,
      })
    }

    const handleSortBy = (value: string, key: string) => {
      console.log(value, key)
      handleFilterAndSort(state.categories, value)
      setInititalState({
        ...state,
        [key]: value,
      })
    }

    const getCategoriesOptions = (): FormTypes.Options[] => {
      return categoriesList.map((item) => ({
        label: item.categoryName,
        value: item.categoryCode,
      }))
    }

    return (
      <>
        <RolesFormBodyContainer>
          <BasicRoleInfoWrapper>
            <RoleInputWrapper>
              <Form.Input
                id="roleCode"
                label="Role ID"
                placeholder="Role ID"
                value={state.roleCode}
                onChange={handleChange}
                maxLength={50}
                status={error.roleCode ? 'error' : undefined}
                disabled={true}
              />
            </RoleInputWrapper>
            <RoleInputWrapper>
              <Form.Input
                id="roleName"
                label="Role Name"
                placeholder="Role Name"
                value={state.roleName}
                onChange={handleChange}
                maxLength={250}
                status={error.roleName ? 'error' : undefined}
              />
            </RoleInputWrapper>
            {/* <RoleInputWrapper>
            <Form.Select
              id={'category'}
              label="Category"
              placeholder="Select Category"
              value={state.category}
              options={RoleCategoryOptions}
              onChange={handleChange}
            />
          </RoleInputWrapper> */}
          </BasicRoleInfoWrapper>
        </RolesFormBodyContainer>
        <div>
          <BasicRoleInfoWrapper>
            <Typography.Title level={2}>Assign Functions</Typography.Title>
          </BasicRoleInfoWrapper>
          {/* <CopyFunctionsWrapper>
          <CopyFunctionTitle>Copy functions from another Role - (Optional)</CopyFunctionTitle>
          <Row gutter={16} align={'middle'}>
            <Col span={8}>
              {isCopied ? (
                <Typography.Text>Functions copied from Role ID {state.roleId}</Typography.Text>
              ) : (
                <Form.Input
                  id="copyFromId"
                  label="Role ID to Copy From"
                  placeholder="Role ID to Copy From"
                  value={state.copyFromId}
                  onChange={handleChange}
                />
              )}
            </Col>
            <Col>
              <StyledCopyButton loading={isCopying} type="primary" onClick={isCopied ? handleUndoAction : handleCopyFunction}>
                {isCopied ? 'Undo this action' : 'Copy'}
              </StyledCopyButton>
            </Col>
          </Row>
        </CopyFunctionsWrapper> */}
          <FunctionFlexWrapper>
            <FunctionActionWrapper>
              <FunctionActionButtons active={!isReview} onClick={() => handleFunctionAction('select')}>
                <IconSettings />
                <FunctionActionLabel>Select functions to include</FunctionActionLabel>
              </FunctionActionButtons>
              <FunctionActionButtons active={isReview} onClick={() => handleFunctionAction('review')}>
                <IconSettings />
                <FunctionActionLabel>Review Selected functions</FunctionActionLabel>
              </FunctionActionButtons>
            </FunctionActionWrapper>
            <FunctionDetailsWrapper>
              <Row gutter={[24, 8]}>
                {!isReview && (
                  <>
                    <Col span={16}>
                      <Form.Select
                        id="categories"
                        label={
                          <CenteredAlignedLabel>
                            <IconFilter /> Select a Category
                          </CenteredAlignedLabel>
                        }
                        mode="multiple"
                        placeholder="Filter Functions by Category"
                        value={state.categories}
                        options={getCategoriesOptions()}
                        onChange={handleOnCategoryChange}
                      />
                    </Col>
                    <Col span={16}>
                      <Form.Select
                        id="sortBy"
                        label={
                          <CenteredAlignedLabel>
                            <IconSort /> Select a Sort Option
                          </CenteredAlignedLabel>
                        }
                        value={state.sortBy}
                        placeholder="Select"
                        options={SortFunctionsByOptions}
                        onChange={handleSortBy}
                      />
                    </Col>
                    <Col span={16}>
                      <FunctionActionHeader>
                        <FunctionFlexWrapper>
                          <Button type="text" $startIcon={<IconRefresh />} onClick={handleResetFunctionFilter}>
                            Reset
                          </Button>
                        </FunctionFlexWrapper>
                      </FunctionActionHeader>
                    </Col>
                  </>
                )}
                <Col span={24}>
                  <FunctionSearchResults
                    isReview={isReview}
                    functionsData={functionsData}
                    handleOnPageChange={handleOnPageChange}
                    paginationObj={paginationObj}
                    individualRole={roleData}
                    categoriesList={categoriesList}
                  />
                </Col>
              </Row>
            </FunctionDetailsWrapper>
          </FunctionFlexWrapper>
        </div>
      </>
    )
  }
)

export default NewRoleForm
