import {LIMIT_TYPES, VoucherTypeGroupOptions, encodeDecodeImageUrl, getCatgeoryNames, getRewardsRequestObj, getTagNameByCode} from '../utils'
import {
  AwardSalePriceWrapper,
  PriceHistoryRowContainer,
  PriceHistoryTitle,
  ReviewDetailsItem,
  RewardDetailsPriceRow,
  RewardDetailsRow,
  RewardImageBox,
  RewardImageList,
  RewardImageListBox,
  RewardImageListContainer,
  RewardTriggerDetailsWrapper,
  StyledReviewLabel,
  StyledReviewValue,
  StyledSectionLabel,
} from '../../Reward.Styled'
import Appconfig from 'app/common/helpers/AppConfig'
import {utcDate, utcWithTimeZone} from 'utils/convertDateToString'
import Typography from 'lib/components/Typography'
import TriggerData from './TriggerData'
import {getStateNameByCode, numberWithCommas} from 'utils/common'
import {MarginBottomWrapper, UrlLink} from 'app/common/components/Styled/common.styled'
import {Col, Space} from 'antd'
import {FormTypes} from 'lib/components/Form/Form'
import RewardService from 'app/services/RewardService'
import {useEffect, useState} from 'react'
import Button from 'lib/components/Button'
import PriceHistoryModal from './PriceHistoryModal'
import DOMPurify from 'dompurify'
interface ReviewRewardProps {
  tagDataOptions: FormTypes.Options[]
  categoryDataOptions: FormTypes.Options[]
}

const ReviewRewards: React.FC<ReviewRewardProps> = ({tagDataOptions, categoryDataOptions}) => {
  const {
    voucherTypeCode,
    voucherTypeName,
    voucherTypeSubCategory,
    voucherTypeDescription,
    validFrom,
    validUntil,
    enabled,
    amount,
    point,
    isRedemption,
    isTierBenefit,
    rewardRuleData,
    url,
    isLimitedInventory,
    // voucherLimit,
    voucherTypeSkuCode,
    voucherTypeGroup,
    isHighlightedReward,
    childRewards = [],
    value,
    voucherTypeDisclaimer,
    voucherTypeShortDescription,
    voucherTypeFullDescription,
    voucherTagCode,
    categoryList = [],
    voucherStatus,
    voucherLimit,
    rewardPrimaryImage,
    rewardAdditionalImage,
    rewardThumbnail,
    saleFrom,
    saleUntil,
    salePoint,
    rewardDynamicBody,
    rewardShippingTimeFrame,
    rewardDynamicFooter,
  } = getRewardsRequestObj
  const [currentqty, setCurrentqyt] = useState(0)
  const [isModal, showModal] = useState(false)
  const pointRedemption = rewardRuleData?.pointRedemption.triggerData
  const tierBenefit = rewardRuleData?.tierBenefit.triggerData
  const restrictionData = rewardRuleData?.stateRestriction
  const voucherLimitRule = rewardRuleData?.voucherLimitRule

  const getCurrentInventory = async (voucherTypeCode: string) => {
    const reqParam: RewardsTypes.RewardInfoSearchParam = {
      voucherStatus: 'AVAILABLE',
      voucherTypeCode: voucherTypeCode,
      Strict: true,
    }
    RewardService.searchRewards(reqParam)
      .then((res) => {
        const data = res.data
        setCurrentqyt(data.pageInfo.count)
        //setRewardsData(data)
      })
      .finally(() => {
        // setIsFetching(false)
      })
  }
  const handleOpenModal = () => {
    showModal(!isModal)
  }

  useEffect(() => {
    getCurrentInventory(voucherTypeCode)
    return () => {}
  }, [voucherTypeCode])

  const renderLimitData = () => {
    if (voucherLimitRule) {
      return Object.keys(voucherLimitRule).map((key) => {
        if (voucherLimitRule[key as keyof typeof LIMIT_TYPES] !== null) {
          return (
            <>
              <Space size={'large'}>
                <div>
                  <Typography.Text uppercase size="small">
                    Limit Type
                  </Typography.Text>
                  <div>{LIMIT_TYPES[key as keyof typeof LIMIT_TYPES].toUpperCase()}</div>
                </div>
                <div>
                  <Typography.Text uppercase size="small">
                    Limit Quantity
                  </Typography.Text>
                  <div>{voucherLimitRule[key as keyof typeof LIMIT_TYPES]?.limitQuantity}</div>
                </div>
                <div>
                  <Typography.Text uppercase size="small">
                    Limit Frequency
                  </Typography.Text>
                  <div>
                    {voucherLimitRule[key as keyof typeof LIMIT_TYPES]?.limitFrequency === 'Phase'
                      ? 'Program Duration'
                      : voucherLimitRule[key as keyof typeof LIMIT_TYPES]?.limitFrequency}
                  </div>
                </div>
              </Space>
            </>
          )
        }
        return null
      })
    }
    return null
  }

  return (
    <>
      <MarginBottomWrapper>
        <Typography.Title level={3} color="primary">
          BASIC DETAILS
        </Typography.Title>
      </MarginBottomWrapper>
      <RewardDetailsRow>
        <Col span={6}>
          <StyledReviewLabel>Reward ID</StyledReviewLabel>
          <StyledReviewValue>{voucherTypeCode}</StyledReviewValue>
        </Col>
        <Col span={6}>
          <StyledReviewLabel>Alt ID/ SKU</StyledReviewLabel>
          <StyledReviewValue>{voucherTypeSkuCode}</StyledReviewValue>
        </Col>
      </RewardDetailsRow>
      <RewardDetailsRow>
        <Col span={6}>
          <StyledReviewLabel>Reward Name</StyledReviewLabel>
          <StyledReviewValue>{voucherTypeName}</StyledReviewValue>
        </Col>
        <Col span={6}>
          <StyledReviewLabel>Reward Type</StyledReviewLabel>
          <StyledReviewValue>{voucherTypeSubCategory}</StyledReviewValue>
        </Col>
      </RewardDetailsRow>
      <RewardDetailsRow>
        <Col span={6}>
          <StyledReviewLabel>Start Date</StyledReviewLabel>
          <StyledReviewValue>{utcDate(utcWithTimeZone(validFrom as string), Appconfig.DATE_TIME_FORMAT)}</StyledReviewValue>
        </Col>
        {validUntil && (
          <Col span={6}>
            <StyledReviewLabel>End Date</StyledReviewLabel>
            <StyledReviewValue>{utcDate(utcWithTimeZone(validUntil as string), Appconfig.DATE_TIME_FORMAT)}</StyledReviewValue>
          </Col>
        )}
      </RewardDetailsRow>
      <RewardDetailsRow>
        <Col span={12}>
          <StyledReviewLabel>Voucher Group</StyledReviewLabel>
          <StyledReviewValue>{VoucherTypeGroupOptions.find((x) => x.value == voucherTypeGroup)?.label}</StyledReviewValue>
        </Col>
      </RewardDetailsRow>
      {voucherTypeGroup == 1 && (
        <RewardDetailsRow>
          <Col span={12}>
            <StyledReviewLabel>Child Rewards</StyledReviewLabel>
            <>
              {childRewards.map((reward, index) => (
                <StyledReviewValue>{`${index + 1}. ${reward.rewardName} & ${reward.rewardCode}`}</StyledReviewValue>
              ))}
            </>
          </Col>
        </RewardDetailsRow>
      )}
      <RewardDetailsRow>
        <Col span={12}>
          <StyledReviewLabel>Is this a Highlight/Spotlight?</StyledReviewLabel>
          <StyledReviewValue>{isHighlightedReward ? 'Yes' : 'No'}</StyledReviewValue>
        </Col>
      </RewardDetailsRow>
      <MarginBottomWrapper>
        <Typography.Title level={3} color="primary">
          REWARD COSTING
        </Typography.Title>
      </MarginBottomWrapper>
      <RewardDetailsRow>
        <Col span={6}>
          <StyledReviewLabel>Points Cost</StyledReviewLabel>
          <StyledReviewValue>{`${numberWithCommas(point)} Pts`}</StyledReviewValue>
        </Col>
        <Col span={6}>
          <StyledReviewLabel>Currency Cost</StyledReviewLabel>
          <StyledReviewValue>{`${amount} Dollars`}</StyledReviewValue>
        </Col>
      </RewardDetailsRow>
      {value !== undefined ? (
        <RewardDetailsRow>
          <Col span={6}>
            <StyledReviewLabel>Cost Per Point</StyledReviewLabel>
            <StyledReviewValue>{`${numberWithCommas(value)} Dollars`}</StyledReviewValue>
          </Col>
        </RewardDetailsRow>
      ) : (
        <></>
      )}
      {(voucherStatus === 'APPROVED' || voucherStatus === 'LIVE') && enabled ? (
        <AwardSalePriceWrapper>
          <PriceHistoryTitle>
            <Typography.Title level={4} color="primary">
              ONGOING SALE
            </Typography.Title>
            <div>
              <Button onClick={handleOpenModal} type="text" size="small">
                Manage All Sales
              </Button>
            </div>
            {isModal && <PriceHistoryModal setOpen={handleOpenModal} voucherData={getRewardsRequestObj} />}
          </PriceHistoryTitle>
          <PriceHistoryRowContainer>
            <RewardDetailsPriceRow>
              {saleFrom !== undefined ? (
                <Col span={10}>
                  <StyledReviewLabel>Sale From</StyledReviewLabel>
                  <StyledReviewValue>
                    {saleFrom == null ? '-' : utcDate(utcWithTimeZone(saleFrom as string), Appconfig.DATE_TIME_FORMAT)}
                  </StyledReviewValue>
                </Col>
              ) : (
                <></>
              )}
              {saleUntil !== undefined ? (
                <Col span={10}>
                  <StyledReviewLabel>Sale Until</StyledReviewLabel>
                  <StyledReviewValue>
                    {saleUntil == null ? '-' : utcDate(utcWithTimeZone(saleUntil as string), Appconfig.DATE_TIME_FORMAT)}
                  </StyledReviewValue>
                </Col>
              ) : (
                <></>
              )}
              <Col span={10}></Col>
            </RewardDetailsPriceRow>
          </PriceHistoryRowContainer>
          <PriceHistoryRowContainer>
            <RewardDetailsPriceRow>
              {salePoint !== undefined ? (
                <Col span={10}>
                  <StyledReviewLabel>Sale Point</StyledReviewLabel>
                  <StyledReviewValue>{`${numberWithCommas(salePoint)} Pts`}</StyledReviewValue>
                </Col>
              ) : (
                <></>
              )}
              {/* <Col span={10}>
                <StyledReviewLabel>Currency Cost</StyledReviewLabel>
                <StyledReviewValue>100 Dollars</StyledReviewValue>
              </Col>
              <Col span={10}>
                <StyledReviewLabel>Cost Per Point</StyledReviewLabel>
                <StyledReviewValue>0.02 Dollars</StyledReviewValue>
              </Col> */}
            </RewardDetailsPriceRow>
          </PriceHistoryRowContainer>
        </AwardSalePriceWrapper>
      ) : (
        <></>
      )}

      <MarginBottomWrapper>
        <Typography.Title level={3} color="primary">
          DISCLAIMER & DESCRIPTIONS
        </Typography.Title>
      </MarginBottomWrapper>
      {voucherTypeDisclaimer && (
        <RewardDetailsRow>
          <Col span={18}>
            <StyledReviewLabel>Disclaimer</StyledReviewLabel>
            <StyledReviewValue>{voucherTypeDisclaimer}</StyledReviewValue>
          </Col>
        </RewardDetailsRow>
      )}
      {voucherTypeShortDescription && (
        <RewardDetailsRow>
          <Col span={18}>
            <StyledReviewLabel>Breif Description</StyledReviewLabel>
            <StyledReviewValue>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(voucherTypeShortDescription)}} />
            </StyledReviewValue>
          </Col>
        </RewardDetailsRow>
      )}
      {voucherTypeFullDescription && (
        <RewardDetailsRow>
          <Col span={18}>
            <StyledReviewLabel>Full Description</StyledReviewLabel>
            <StyledReviewValue>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(voucherTypeFullDescription)}} />
            </StyledReviewValue>
          </Col>
        </RewardDetailsRow>
      )}
      {voucherTypeDescription && (
        <RewardDetailsRow>
          <Col span={18}>
            <StyledReviewLabel>Description</StyledReviewLabel>
            <StyledReviewValue>{voucherTypeDescription}</StyledReviewValue>
          </Col>
        </RewardDetailsRow>
      )}
      {rewardShippingTimeFrame && (
        <RewardDetailsRow>
          <Col span={18}>
            <StyledReviewLabel>Shipping Timeframe </StyledReviewLabel>
            <StyledReviewValue>{rewardShippingTimeFrame}</StyledReviewValue>
          </Col>
        </RewardDetailsRow>
      )}
      {rewardDynamicBody && (
        <RewardDetailsRow>
          <Col span={18}>
            <StyledReviewLabel>Reward Dynamic Body</StyledReviewLabel>
            <StyledReviewValue>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rewardDynamicBody)}} />
            </StyledReviewValue>
          </Col>
        </RewardDetailsRow>
      )}
      {rewardDynamicFooter && (
        <RewardDetailsRow>
          <Col span={18}>
            <StyledReviewLabel>Reward Dynamic Footer</StyledReviewLabel>
            <StyledReviewValue>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rewardDynamicFooter)}} />
            </StyledReviewValue>
          </Col>
        </RewardDetailsRow>
      )}
      <MarginBottomWrapper>
        <Typography.Title level={3} color="primary">
          INVENTORY, CATEGORIES & AVAILABILITY
        </Typography.Title>
      </MarginBottomWrapper>
      <RewardDetailsRow>
        <Col span={18}>
          <StyledReviewLabel>Inventory</StyledReviewLabel>
          <StyledReviewValue>{isLimitedInventory ? 'Yes' : 'No'}</StyledReviewValue>

          {voucherStatus === 'APPROVED' ? (
            <StyledReviewValue>{isLimitedInventory && currentqty}</StyledReviewValue>
          ) : (
            <StyledReviewValue>{isLimitedInventory && voucherLimit}</StyledReviewValue>
          )}
        </Col>
      </RewardDetailsRow>
      {voucherTagCode && (
        <RewardDetailsRow>
          <Col span={18}>
            <StyledReviewLabel>Availability Tag</StyledReviewLabel>
            <StyledReviewValue>{getTagNameByCode(tagDataOptions, voucherTagCode.voucherTagCode)}</StyledReviewValue>
          </Col>
        </RewardDetailsRow>
      )}
      {categoryList?.length > 0 && (
        <RewardDetailsRow>
          <Col span={18}>
            <StyledReviewLabel>Category</StyledReviewLabel>
            <>
              {getCatgeoryNames(categoryDataOptions, categoryList).map((category) => (
                <StyledReviewValue>{category}</StyledReviewValue>
              ))}
            </>
          </Col>
        </RewardDetailsRow>
      )}
      <RewardDetailsRow>
        <Col span={6}>
          <StyledReviewLabel>Active</StyledReviewLabel>
          <StyledReviewValue>{enabled ? 'Yes' : 'No'}</StyledReviewValue>
        </Col>
      </RewardDetailsRow>
      {url && (
        <ReviewDetailsItem>
          <StyledReviewLabel>Adobe Reward Url</StyledReviewLabel>
          <StyledReviewValue>
            <UrlLink href={`https://${url.slice(0, 8) === 'https://' ? url.slice(8, url.length) : url}`} target="_blank">
              {url}
            </UrlLink>
          </StyledReviewValue>
        </ReviewDetailsItem>
      )}
      {(rewardThumbnail || rewardPrimaryImage || (rewardAdditionalImage && rewardAdditionalImage.length > 0)) && (
        <>
          <MarginBottomWrapper>
            <Typography.Title level={3} color="primary">
              Images
            </Typography.Title>
          </MarginBottomWrapper>
          <RewardImageListContainer>
            {rewardThumbnail && (
              <RewardImageList>
                <StyledReviewLabel>Thumbnail</StyledReviewLabel>
                <RewardImageBox style={{backgroundImage: `url(${encodeDecodeImageUrl(rewardThumbnail)})`}} />
              </RewardImageList>
            )}
            {rewardPrimaryImage && (
              <RewardImageList>
                <StyledReviewLabel>Primary Image</StyledReviewLabel>
                <RewardImageBox style={{backgroundImage: `url(${encodeDecodeImageUrl(rewardPrimaryImage)})`}} />
              </RewardImageList>
            )}
            {rewardAdditionalImage && rewardAdditionalImage.length > 0 && (
              <RewardImageList>
                <StyledReviewLabel>Additional Images</StyledReviewLabel>
                <RewardImageListBox>
                  {rewardAdditionalImage.map((imageurl, index) => (
                    <RewardImageBox key={index} style={{backgroundImage: `url(${imageurl})`}} />
                  ))}
                </RewardImageListBox>
              </RewardImageList>
            )}
          </RewardImageListContainer>
        </>
      )}

      <RewardTriggerDetailsWrapper>
        <StyledSectionLabel>
          <Typography.Title level={3} color="primary" uppercase>
            Trigger
          </Typography.Title>
        </StyledSectionLabel>

        <ReviewDetailsItem>
          <StyledReviewLabel>Point Redemption</StyledReviewLabel>
          {!isRedemption && <StyledReviewValue>No</StyledReviewValue>}
          {isRedemption && pointRedemption && <TriggerData triggerData={pointRedemption} />}
        </ReviewDetailsItem>
        <ReviewDetailsItem>
          <StyledReviewLabel>Tier Benefit</StyledReviewLabel>
          {!isTierBenefit && <StyledReviewValue>No</StyledReviewValue>}

          {isTierBenefit && tierBenefit && <TriggerData triggerData={tierBenefit} />}
        </ReviewDetailsItem>
      </RewardTriggerDetailsWrapper>

      <RewardTriggerDetailsWrapper>
        <StyledSectionLabel>
          <Typography.Title level={3} color="primary" uppercase>
            Restrictions
          </Typography.Title>
        </StyledSectionLabel>
        {voucherLimitRule && (
          <ReviewDetailsItem>
            <Space direction="vertical" size={'large'}>
              {renderLimitData()}
            </Space>
          </ReviewDetailsItem>
        )}
        {restrictionData && (
          <>
            <StyledReviewLabel>State Restriction</StyledReviewLabel>
            {restrictionData.isRestrictionAvaliable ? (
              <ReviewDetailsItem>
                {restrictionData.state?.map((item) => <StyledReviewValue>{getStateNameByCode(item)}</StyledReviewValue>)}
              </ReviewDetailsItem>
            ) : (
              <ReviewDetailsItem>
                <StyledReviewValue>No</StyledReviewValue>
              </ReviewDetailsItem>
            )}
          </>
        )}
      </RewardTriggerDetailsWrapper>
    </>
  )
}

export default ReviewRewards
