import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import LoadingPanel from 'lib/components/LoadingPanel'
import Typography from 'lib/components/Typography'
import { StyledHeader } from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import Appconfig from 'app/common/helpers/AppConfig'
import SearchResults from './SearchResults'
import { isEmpty } from 'lodash'
import { ReviewHeader } from 'app/common/components/Styled/common.styled'
import { AddNewTierParam } from '../AddNewTier'
import TierMemberFilter, { TierMemberFormRefType } from './TierMemberFilter'
import MembershipService from 'app/services/MembershipService'

const TierMembers = () => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const initialResponse: ResponseTypes.PagedResponse<SegmentationTypes.SegmentSearchResponse[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialTierResponse: ResponseTypes.PagedResponse<TierTypes.TierMasterResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  console.log(initialTierResponse);
  const { tierId } = useParams<AddNewTierParam>()
  const actualTierId = atob(tierId || '')
  const initialSearchParms: MemebershipTypes.MembershipDetail = { MembershipTierKey: actualTierId }
  const tierFormRef = useRef<TierMemberFormRefType>(null)
  const navigate = useNavigate()
  const [loadingTier, setLoadingTier] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isFormReset, setIsFormReset] = useState(true)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)
  // const [individualTier, setIndividualTier] = useState<ResponseTypes.PagedResponse<TierTypes.TierMasterResponseBL[]>>(initialTierResponse)
  const [membersData, setMembersData] = useState<ResponseTypes.PagedResponse<MemebershipTypes.MembershipDataBL[]>>(initialResponse)

  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  // const getSegmentName = () => {
  //   const reqParam: MemebershipTypes.MembershipDataBL = {
  //     membershipTierKey: actualTierId,
  //   }
  //   SegmentationService.search(reqParam).then((res) => {
  //     const data = res.data
  //     setIndividualSeg(data)
  //   })
  // }

  const handleSearch = (params: MemebershipTypes.MembershipDetail) => {
    const reqParam: MemebershipTypes.MembershipDetail = {
      // IsCombinable: false,
      ...params,
      MembershipTierKey: actualTierId,
      Strict: false,
    }
    setIsFetching(true)
    MembershipService.searchMember(reqParam)
      .then((res) => {
        const data = res.data
        setMembersData(data)
      })
      .finally(() => {
        setLoadingTier(false)
        setIsFetching(false)
      })
  }

  useEffect(() => {
    if (actualTierId) {
      setLoadingTier(true)
      // getSegmentName()
      handleSearch({
        ...initialSearchParms,
        ...initialPaginationObj,
        MembershipTierKey: actualTierId,
      })
    }
  }, [])

  const handleBack = () => {
    navigate(-1)
  }

  const handleResetSearch = () => {
    tierFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({ ...initialSearchParms, ...initialPaginationObj })
      setIsFormReset(!isFormReset)
    }
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const handleFilter = (data: SegmentationTypes.SegmentMemberFilter) => {
    const filterParms: SegmentationTypes.SegmentMemberParams = {}
    filterParms.MembershipFullName = data.memberName
    filterParms.MembershipEmailId = data.email
    filterParms.MembershipStatusKey = data.status
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = { ...paginationObj, PageNumber: initialPaginationObj.PageNumber }
    setPaginationObj(newPaginationObj)
    handleSearch({ ...filterParms, ...newPaginationObj, MembershipTierKey: actualTierId })
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }
    const newPaginationObj = { ...paginationObj, PageCount: pageSize, PageNumber: page }
    setPaginationObj(newPaginationObj)
    handleSearch({ ...filterSearch, ...newPaginationObj })
  }

  const handleRefresh = (value: boolean, segmentCode: string) => {
    // const updatedRewardsData = cloneDeep(segmentData)
    // const updatedRowIndex = segmentData.data.findIndex((item) => item.segmentCode === segmentCode)
    // updatedRewardsData.data[updatedRowIndex] = {
    //   ...updatedRewardsData.data[updatedRowIndex],
    // }
    // setSegmentData(updatedRewardsData)
  }

  const { count = 0, pageIndex = 0 } = membersData.pageInfo

  if (loadingTier) {
    return <LoadingPanel />
  }

  return (
    <section>
      <ReviewHeader>
        <Typography.Title level={2}>{actualTierId}</Typography.Title>
      </ReviewHeader>
      <StyledHeader>
        <Typography.Title>Tier Members</Typography.Title>
        <Button onClick={handleBack} type="link">
          Back
        </Button>
      </StyledHeader>
      {/* <MarginBottomWrapper>
        <Typography.Title level={4} color="default" uppercase>
          {indi?.data[0]?.segmentName || ''}
        </Typography.Title>
      </MarginBottomWrapper> */}
      <DataTable>
        <TierMemberFilter ref={tierFormRef} isFetching={isFetching} handleFilter={handleFilter} />
        <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} />
        <SearchResults isFetching={isFetching} membersResponse={membersData} handleSearch={handleRefresh} />
        <DataTable.Pagination
          onChange={handleOnChange}
          current={pageIndex}
          pageSizeOptions={pageSizeOptions}
          total={count}
          defaultPageSize={initialPaginationObj.PageCount}
          pageSize={paginationObj.PageCount}
          showSizeChanger
        />
      </DataTable>
    </section>
  )
}

export default TierMembers
