import {Tooltip} from 'antd'
import Appconfig from 'app/common/helpers/AppConfig'
import IconEye from 'app/common/icons/IconEye'
import MembershipService from 'app/services/MemberService'
import dayjs from 'dayjs'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

interface PromotionsProps {
  membershipCardNumber?: string
}

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Promotion Name',
    id: 'promotionName',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Promotion Type',
    id: 'type',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Start Date',
    id: 'startDate',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'End Date',
    id: 'endDate',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Promotion Category',
    id: 'who',
    textAlign: 'center',
    width: '15%',
  },
  // {
  //   title: 'Status',
  //   id: 'status',
  //   textAlign: 'left',
  //   width: '20%',
  // },
  {
    title: '',
    id: 'actions',
    textAlign: 'left',
    width: '10%',
  },
]

const Promotions: React.FC<PromotionsProps> = ({membershipCardNumber}) => {
  const initialResponse: ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const [promotionData, setPromotionData] = useState<ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]>>(initialResponse)
  const [isFetching, setIsFetching] = useState(false)
  const navigate = useNavigate()

  const getPromotions = (initialPaginationObj: PaginationType.PaginationDetails) => {
    const reqParam: PromotionsTypes.PromotionSearchParam = {
      // IsCombinable: false,
      ...initialPaginationObj,
      MemberId: membershipCardNumber,
    }

    setIsFetching(true)
    MembershipService.getPromotionsForMember(reqParam)
      .then((res) => {
        const data = res.data
        setPromotionData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    getPromotions(initialPaginationObj)
  }, [])

  const handleView = (rowIndex: number) => {
    const promotionCode = promotionData.data[rowIndex].promotionCode
    const encodedPromotionId = btoa(promotionCode)
    navigate(`/promotions/view/${encodedPromotionId}?memberId=${membershipCardNumber}`)
  }

  const renderRowActions = (rowIndex: number) => {
    return (
      <>
        <Tooltip title="View">
          <Button shape="circle" size="small" ghost icon={<IconEye size={16} />} onClick={() => handleView(rowIndex)} />
        </Tooltip>
      </>
    )
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (promotionData && promotionData.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = promotionData
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          promotionName: item.promotionName,
          type: item.promotionType ?? '-',
          startDate: item.startDateTime ? dayjs(item.startDateTime).format(Appconfig.DATE_FORMAT_SLASH) : '-',
          endDate: item.endDateTime ? dayjs(item.endDateTime).format(Appconfig.DATE_FORMAT_SLASH) : '-',
          // status: item.promotionStatusCode ?? '-',
          who: item.who,
          actions: renderRowActions(index),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }
  const handleOnChange = (page: number, pageSize: number) => {
    getPromotions({...initialPaginationObj, PageCount: pageSize, PageNumber: page})
  }

  const {pageIndex, count} = promotionData.pageInfo
  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }
  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />

      <DataTable.Pagination
        onChange={handleOnChange}
        current={pageIndex}
        pageSizeOptions={Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS}
        total={count}
        defaultPageSize={Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE}
        showSizeChanger
      />
    </>
  )
}

export default Promotions
