import {FormTypes} from 'lib/components/Form/Form'
import {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import TierSystemDetailsForm, {TierSystemFormRefType} from './TierSystemDetailsForm'
import TierDetailsForm, {TierFormRefType} from './TierDetailsForm'
import {getRewardRuleDataFromRewardCodes, getRewardsCode, getTierRequestObj, initialTierReqObj, validatePointRange, validateTierName} from '../utils'
import {cloneDeep} from 'lodash'
import { TierDetailsFormWrapper } from '../Tier.Styled'
import { MarginBottomWrapper } from 'app/common/components/Styled/common.styled'
import Typography from 'lib/components/Typography'
import Button from 'lib/components/Button'
import Colors from 'styles/Colors'
import IconAdd from 'app/common/icons/IconAdd'
import toast from 'react-hot-toast'
import { TierRefType } from '../AddNewTier'

interface NewTierSystemFormProps {
  partnerCodeOptions: FormTypes.Options[]
  rewardsDataOptions: FormTypes.Options[]
  isViewOnly: boolean
  activeStep: number,
  isLive: boolean
}

const NewTierSystemForm = forwardRef<TierRefType, NewTierSystemFormProps>(({ partnerCodeOptions, rewardsDataOptions, isViewOnly, activeStep, isLive = false }, ref) => {
  const tierReqObj = getTierRequestObj
  const { tierList } = tierReqObj
  const tierDetailsFormRef = useRef<TierFormRefType>(null)
  const tierSystemFormRef = useRef<TierSystemFormRefType>(null)
  const initialState: TierTypes.TierDetailStateType = {
    membershipTierName: '',
    membershipTierKey: '',
    earnRate: 0,
    rewards: [],
    fromValue: 0,
    toValue: 0,
    isEditable: true,

  }

  const formatTierData = (): TierTypes.TierDetailStateType[] => {
    const updatedTierData = tierList || []
    return updatedTierData.map((item, index) => {
      const { tierMasterRewardRuleData, tierMasterWhoRule } = item.tierMasterRuleData
      const rewardsCode = getRewardsCode(tierMasterRewardRuleData)
      return {
        membershipTierName: item.membershipTierName || '',
        membershipTierKey: item.membershipTierKey || '',
        earnRate: item.earnRate || 0,
        rewards: rewardsCode || [],
        fromValue: tierMasterWhoRule.fromValue || 0,
        toValue: tierMasterWhoRule.toValue || 0,
        isEditable: false
      }
    })
  }

  const updatedTierData = tierList.length > 0 ? formatTierData() : []
  const [state, setState] = useState<TierTypes.TierDetailStateType[]>(updatedTierData)

  const getStateOfTierForm = (): TierTypes.TierMasterResponseBL => {
    const tierSystemFormData = tierSystemFormRef.current?.getState()
    const tierDetailsFormData = state
    let tierReqObj: TierTypes.TierMasterResponseBL = initialTierReqObj
    if (tierDetailsFormData && tierSystemFormData) {
      const { tierSystemName, startDateTime, endDateTime, assesmentTime, assesmentType, partnerCode } = tierSystemFormData
      const updatedTierData = tierDetailsFormData.map((item) => {
        const { membershipTierKey, membershipTierName, earnRate, rewards, fromValue, toValue } = item
        const rewardRuleData = getRewardRuleDataFromRewardCodes(rewardsDataOptions, rewards)
        return {
          membershipTierName,
          membershipTierKey,
          membershipTierDescription: '',
          earnRate,
          tierLevel: 1,
          isDefaultTier: false,
          tierMasterRuleData: {
            tierMasterRewardRuleData: rewardRuleData,
            tierMasterWhoRule: {
              fromValue,
              toValue,
            },
          },
          ruleData: [],
        }
      })
      tierReqObj = {
        ...tierReqObj,
        tierSystemName,
        startDate: startDateTime,
        endDate: endDateTime,
        assesmentTime,
        assesmentType,
        tierStatus: 'INPROGRESS',
        partnerCode,
        tierList: updatedTierData,
      }
    }
    return tierReqObj
  }

  const validateTierDetails = (): boolean => {
    if (!validateTierName(state)) {
      toast.error("Please Enter Valid Tier Name.")
      return false;
    }
    const validationDetails = validatePointRange(state);
    if (!validationDetails.isValid) {
      toast.error(validationDetails.message)
      return false
    }
    return true
  }

  const handleValidate = (): boolean => {
    if (validateTierDetails() && tierSystemFormRef.current?.validate()) {
      return true
    } else {
      return false
    }
  }

  useImperativeHandle(ref, () => ({ validate: handleValidate, getState: getStateOfTierForm }))

  const handleFormValueChange = (value: FormTypes.ValueType, key: string, index: number): void => {
    const prevState = cloneDeep(state)
    prevState[index] = {
      ...prevState[index],
      [key]: value,
      isEditable: true
    }
    setState(prevState)
  }

  const deleteTier = (index: number) => {
    const prevState = cloneDeep(state)
    prevState.splice(index, 1)
    setState(prevState)
  }

  const handleAddTier = (index: number): void => {
    const prevState = cloneDeep(state)
    setState([...prevState, initialState])
  }

  const handleEditTier = (value: boolean, index: number): void => {
    const prevState = cloneDeep(state)
    prevState[index] = {
      ...prevState[index],
      isEditable: value,
    }
  }

  return (
    <>
      <TierSystemDetailsForm ref={tierSystemFormRef} partnerCodeOptions={partnerCodeOptions} isViewOnly={isViewOnly} activeStep={activeStep} isLive={isLive} isPartnerDisabeld={tierReqObj.tierSystemKey != null} />
      <TierDetailsFormWrapper>
        <MarginBottomWrapper>
          <Typography.Title level={3} color="primary" uppercase>
            Tier Details
          </Typography.Title>
        </MarginBottomWrapper>
        {state.map((tier, index) => {
          return (
            <TierDetailsForm
              ref={tierDetailsFormRef}
              tierData={tier}
              rewardsDataOptions={rewardsDataOptions}
              isViewOnly={isViewOnly}
              activeStep={activeStep}
              handleFormValueChange={handleFormValueChange}
              deleteTier={deleteTier}
              tierIndex={index}
              handleEditTier={handleEditTier}
              isLive={isLive}
            />
          )
        })}
        {state.length < 10 && !isLive && (
          <Button type="text" color={Colors.BLACK} onClick={() => handleAddTier(state.length)} $startIcon={<IconAdd color={Colors.BLACK} />}>
            Add Tier
          </Button>
        )}
      </TierDetailsFormWrapper>
    </>
  )
})

export default NewTierSystemForm
