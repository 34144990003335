import {MarginBottomWrapper, ReviewHeader} from 'app/common/components/Styled/common.styled'
import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import Typography from 'lib/components/Typography'
import {ActivityLogBodyContainer} from './ActivityLogStyled'
import {useNavigate, useParams} from 'react-router-dom'
import {ReviewDetailsItem, StyledReviewLabel, StyledReviewValue} from 'app/pages/Rewards/Reward.Styled'
import {useEffect, useState} from 'react'
import {ActivityLogTypes} from 'types/ActivityLogTypes'
import AcitivityService from 'app/services/ActivityService'
import LoadingPanel from 'lib/components/LoadingPanel'
import Appconfig from 'app/common/helpers/AppConfig'
import {isEmpty} from 'lodash'
import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import DataTable from 'lib/components/DataTable'
import { NoRecordFound } from 'lib/components/DataTable/DataTable.styled'

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Property',
    id: 'propertyName',
    textAlign: 'left',
    width: '33%',
  },
  {
    title: 'Previous Value',
    id: 'oldValue',
    textAlign: 'left',
    width: '33%',
  },
  {
    title: 'Updated Value',
    id: 'newValue',
    textAlign: 'left',
    width: '34%',
  },
]

export interface ActivityLogProps {}

export type ActivityLogURLParams = {
  uniqueId: string
}

const ActivityLogDetails: React.FC<ActivityLogProps> = () => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'updatedDate',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }

  const navigate = useNavigate()
  const {uniqueId} = useParams<ActivityLogURLParams>()
  const [isFetching, setIsFetching] = useState(true)

  const [activityLogData, setActivityLogData] = useState<ActivityLogTypes.ActivityLogResponseBL | null>(null)
  const actualUniqueId = atob(uniqueId || '')

  const handleBack = () => {
    navigate(-1)
  }
  const handleSearch = (params: ActivityLogTypes.ActivityLogSearchParam) => {
    const reqParam: ActivityLogTypes.ActivityLogSearchParam = {
      // IsCombinable: false,
      AuditId: actualUniqueId,

      ...params,
    }
    setIsFetching(true)
    //  setIsFetching(false)
    //setActivityLogData({...activityLogData, data: });
    AcitivityService.getActivities(reqParam)
      .then((res) => {
        const {data} = res.data
        if (data.length > 0) {
          setActivityLogData(data[0])
        } else {
        }
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    // TODO: get details using uniqueId  from URL params
    // Make an api call using actualUniqueId  here instead of the one in url param

    handleSearch({...initialPaginationObj})
  }, [uniqueId])

  const generateRowData = (): DataTableTypes.Row[] => {
    if (activityLogData) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = activityLogData
      data.entityChanges.map((item, index) => {
        const obj: DataTableTypes.Row = {
          propertyName: item.propertyName,
          oldValue: isEmpty(item.oldValue) ? '-' : item.oldValue,
          newValue: item.newValue,
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No results were found for Change Details.</NoRecordFound>
  }

  const renderChangesDetailsBody = () => {
    return (
      <>
        <DataTable.Header columns={dataTableColumns} />
        <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
      </>
    )
  }
  // Dummy  data for now until we have real details to display
  // This component can receive a prop which will have activity details for selected row on list page
  const renderLogDetailsBody = () => {
    if (isFetching || activityLogData === null) {
      return <LoadingPanel />
    }

    const renderChangesDetails = () => {
      if (activityLogData === null || activityLogData.data === null || activityLogData.data.entityChanges.length === 0) {
        return ''
      }
      return (
        <>
          <MarginBottomWrapper>
            <Typography.Title level={3} uppercase color="primary">
              Change Details
            </Typography.Title>
          </MarginBottomWrapper>
          {renderChangesDetailsBody()}
        </>
      )
    }

    return (
      <div>
        <div>
          <MarginBottomWrapper>
            <Typography.Title level={3} uppercase color="primary">
              Basic Details
            </Typography.Title>
          </MarginBottomWrapper>
          <Row>
            <Col span={10}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Unique ID</StyledReviewLabel>
                <StyledReviewValue>{activityLogData.data.entityId}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={10}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Affected Module</StyledReviewLabel>
                <StyledReviewValue>{activityLogData.modulename}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Date</StyledReviewLabel>
                <StyledReviewValue>{dayjs(activityLogData.insertedDate).format(Appconfig.DATE_TIME_FORMAT)} </StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            <Col span={10}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Description</StyledReviewLabel>
                <StyledReviewValue>{activityLogData.data.action}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Modified By</StyledReviewLabel>
                <StyledReviewValue>{activityLogData.createdby}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
            {/* <Col span={10}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Additional Details</StyledReviewLabel>
                <StyledReviewValue>{activityLogData.data.action}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col> */}
            <Col span={10}>
              <ReviewDetailsItem>
                <StyledReviewLabel>Client Ip</StyledReviewLabel>
                <StyledReviewValue>{activityLogData.clientip ? activityLogData.clientip : '-'}</StyledReviewValue>
              </ReviewDetailsItem>
            </Col>
          </Row>
        </div>
        <div>
          {renderChangesDetails()}
          {/* <ReviewDetailsItem>
            <StyledReviewLabel>Previous Value</StyledReviewLabel>
            <StyledReviewValue>{'Lorem ipsum dolor sit amet'}</StyledReviewValue>
          </ReviewDetailsItem>
          <ReviewDetailsItem>
            <StyledReviewLabel>Updated Value</StyledReviewLabel>
            <StyledReviewValue>{'Lorem ipsum dolor sit amet'}</StyledReviewValue>
          </ReviewDetailsItem>
          <MarginBottomWrapper>
            <Typography.Title level={3} uppercase color="primary">
              Additional Details
            </Typography.Title>
          </MarginBottomWrapper>
          <StyledReviewValue>
            {
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
            }
          </StyledReviewValue> */}
        </div>
      </div>
    )
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs backLinkLabel="Activity Log" backLinkPath={`/program/activitylog`} activePageLabel="Log Details" />
        <Button type="link" onClick={handleBack}>
          Back
        </Button>
      </BreadcrumbsHeaderWrapper>
      <div>
        <ReviewHeader>
          <Typography.Title level={2}>Log Details</Typography.Title>
        </ReviewHeader>
        <ActivityLogBodyContainer>{renderLogDetailsBody()}</ActivityLogBodyContainer>
      </div>
    </div>
  )
}

export default ActivityLogDetails
