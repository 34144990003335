import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import ProductService from 'app/services/ProductService'
import {FormTypes} from 'lib/components/Form/Form'
import Typography from 'lib/components/Typography'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import ProductCategoryRow from './ProductCategoryRow'
import {cloneDeep} from 'lodash'
import Button from 'lib/components/Button'
import Colors from 'styles/Colors'
import IconAdd from 'app/common/icons/IconAdd'
import LoadingPanel from 'lib/components/LoadingPanel'
import {
  getFormattedAttributesFromProperties,
  getFormattedProperties,
  getProductRequestObj,
  updateProductRequestObj,
  validateAttributes,
} from './utils'

export interface CategoryFormRefType {
  validate(): boolean
  resetCategories(): void
  getAttributes(): FormTypes.Options[]
}

interface CategorFormProps {
  partnerCode: string
}

const CategoryForm = forwardRef<CategoryFormRefType, CategorFormProps>(({partnerCode}, ref) => {
  let productData = getProductRequestObj
  const inititalAttributes = getFormattedAttributesFromProperties(productData.properties)
  const defaultRow: FormTypes.Options = {label: '', value: ''}
  const [loadingCategory, setLoadingCategory] = useState(true)
  const [categoryListOriginal, setOriginalCategoryList] = useState<FormTypes.Options[]>([])
  const [categoryList, setCategoryList] = useState<FormTypes.Options[]>([])
  const [attributes, setAttributes] = useState<FormTypes.Options[]>(inititalAttributes.length ? inititalAttributes : [defaultRow])

  const getCategoryList = (partner: string) => {
    setLoadingCategory(true)
    if (partner) {
      ProductService.getProductCategory({PartnerCode: partner, SortColumn: 'CreatedOn'})
        .then((res) => {
          const data = res.data.data
          const options = data.map((item): FormTypes.Options => ({label: item.categoryName, value: item.categoryCode}))
          const filteredOptions = options.filter((item) => attributes.findIndex((attr) => attr.label === item.value) === -1)
          setOriginalCategoryList(options)
          setCategoryList(filteredOptions)
        })
        .finally(() => {
          setLoadingCategory(false)
        })
    }
  }

  useEffect(() => {
    if (partnerCode) {
      getCategoryList(partnerCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerCode])

  useEffect(() => {
    const filteredOptions = categoryListOriginal.filter((item) => attributes.findIndex((attr) => attr.label === item.value) === -1)
    setCategoryList(filteredOptions)
  }, [attributes])

  const handleValidate = () => {
    if (!attributes.length || !validateAttributes(attributes)) {
      return false
    }
    return true
  }

  const resetCategories = () => {
    setAttributes([defaultRow])

    updateProductRequestObj({
      ...productData,
      properties: {},
    })
  }

  const getAttributes = () => {
    return attributes
  }

  useImperativeHandle(ref, () => ({validate: handleValidate, resetCategories, getAttributes}))

  const handleDeleteAttribute = (index: number) => {
    const updatedAttributes = cloneDeep(attributes)
    updatedAttributes.splice(index, 1)
    setAttributes(updatedAttributes)
    const formattedAttributes = getFormattedProperties(updatedAttributes)
    productData = {
      ...productData,
      properties: formattedAttributes,
    }

    updateProductRequestObj(productData)
  }

  const handleAddAttribute = () => {
    const updatedAttributes = cloneDeep(attributes)
    updatedAttributes.push(defaultRow)
    setAttributes(updatedAttributes)
  }
  const onCategoryDataChange = (attr: FormTypes.Options, rowIndex: number) => {
    const updatedAttributes = cloneDeep(attributes)
    updatedAttributes[rowIndex] = attr
    setAttributes(updatedAttributes)
    const formattedAttributes = getFormattedProperties(updatedAttributes)
    productData = {
      ...productData,
      properties: formattedAttributes,
    }

    updateProductRequestObj(productData)
  }

  if (loadingCategory) {
    return <LoadingPanel />
  }

  // if (categoryList.length === 0) {
  //   return <>No Data Found.</>
  // }

  return (
    <>
      {categoryListOriginal.length > 0 ? (
        <>
          <MarginBottomWrapper>
            <Typography.Text color="primary" strong uppercase>
              CHOOSE PRODUCT ATTRIBUTES AND VALUES
            </Typography.Text>
          </MarginBottomWrapper>
          {attributes.map((attr, index) => (
            <ProductCategoryRow
              key={`${attr.label}-${index}`}
              attr={attr}
              rowIndex={index}
              categoryOptions={categoryList}
              handleDeleteAttribute={handleDeleteAttribute}
              onCategoryDataChange={onCategoryDataChange}
              totalRows={attributes.length}
            />
          ))}
          {attributes.length !== categoryListOriginal.length && (
            <>
              <MarginBottomWrapper>
                <Button type="text" onClick={handleAddAttribute} color={Colors.BLACK} $startIcon={<IconAdd color={Colors.BLACK} />}>
                  Add Attribute
                </Button>
              </MarginBottomWrapper>
            </>
          )}
        </>
      ) : (
        <>No Data Found.</>
      )}
    </>
  )
})

export default CategoryForm
