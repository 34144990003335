import styled, {css} from 'styled-components'
import {TypographyTypes} from './Typography'

export const TitleRoot = styled.span<TypographyTypes>`
  font-family: ${(props) => (props.variant ? props.theme.typography[props.variant].fontFamily : props.theme.typography.fontFamily)};
  font-size: ${(props) => (props.variant ? props.theme.typography[props.variant].fontSize : `${props.theme.typography.fontSize}px`)};
  font-weight: ${(props) =>
    props.bold
      ? props.theme.typography.fontWeightBold
      : props.variant
      ? props.theme.typography[props.variant].fontWeight
      : props.theme.typography.fontWeightMedium};
  line-height: ${(props) => (props.variant ? props.theme.typography[props.variant].lineHeight : 1)};
  letter-spacing: ${(props) => (props.variant ? props.theme.typography[props.variant].letterSpacing : 'inherit')};
  padding: 0;
  margin: 0;

  ${(props) =>
    props.color &&
    css<TypographyTypes>`
      color: ${props.color};
    `}

  ${(props) =>
    props.$isDark &&
    css<TypographyTypes>`
      color: 'inherit';
    `}
  
  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  ${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${(props) =>
    !props.uppercase &&
    css<TypographyTypes>`
      text-transform: ${(props) => (props.variant ? props.theme.typography[props.variant].textTransform : 'inherit')};
    `}

    ${(props) =>
    props.textAlign &&
    css<TypographyTypes>`
      text-align: ${props.textAlign};
    `}

    ${(props) =>
    props.marginBottom &&
    css<TypographyTypes>`
      margin-bottom: ${props.marginBottom}px;
    `}
`
