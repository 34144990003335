import { Row } from 'antd'
import Button from 'lib/components/Button'
import styled, {css} from 'styled-components'
import Colors from 'styles/Colors'

export const CategoryRowBox = styled.div`
  background-color: ${Colors.WHITE};
  padding: 12px 14px;
  display: grid;
  grid-template-columns: 0.6fr 0.39fr 1fr 0.05fr;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  align-items: center;
`

export const CategoryDropdownCol = styled.div`
  .lb-radio-button-wrapper-checked:not(.lb-radio-button-wrapper-disabled) {
    background-color: ${Colors.GREY3};
    color: ${Colors.WHITE};
  }
`

export const AddAttrWrapper = styled.div`
  padding: 32px 0;
`
interface CategoryRowWrapperProps {
  $hasError: boolean
}
export const CategoryRowWrapper = styled.div<CategoryRowWrapperProps>`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  ${(props) =>
    props.$hasError &&
    css`
      ${CategoryRowBox} {
        border-color: ${Colors.RED};
      }
    `}
`

export const PromotionTypesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.BORDER2};
  padding: 8px 0 32px;
`

interface StyledBtnProps {
  active?: boolean
}

export const StylePromotionTypeBtn = styled(Button)<StyledBtnProps>`
  width: 22%;
  background-color: ${(props) => (props.active ? `${Colors.BLUE_LIGHT}` : 'transparent')};
`

export const PromotionTypeDetailsWrapper = styled.div`
  padding: 44px 0;
  border-bottom: 1px solid ${Colors.BORDER2};
`

export const PromotionTypeDetailsBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
`

export const TriggerLimitsSection = styled.div`
  padding-top: 44px;
`

export const TriggerLimitsWrapper = styled.div`
  padding-top: 26px;
`

export const TriggerLimitItemsWrapper = styled.div`
  margin-bottom: 24px;
`

export const TriggerLimitDetails = styled.div`
  padding: 24px 0 0 24px;
  display: flex;
`

export const LimitOptionsWrapper = styled.div`
  margin-right: 24px;
`

export const AddMoreAttributesWrapper = styled.div`
  padding: 24px;
  min-height: 30%;
  border: 1px solid ${Colors.BORDER2};
  border-radius: 5px;
  width: 50%;
`

export const FullWidthRow = styled(Row)`
  flex: 1;
`

export const EventWrapper = styled.div`
  display: flex;
  padding-top: 32px;
`

export const EventPointWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: flex-end;
`
