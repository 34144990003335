import {Select as AntSelect} from 'antd'
import IconChevronDown from 'app/common/icons/IconChevronDown'
import {bool, func, instanceOf, number, oneOfType, string} from 'prop-types'
import {FormTypes} from '../Form'
import FormLabel from '../FormLabel'
import {SelectWrapper} from './Selected.styled'
import Typography from 'lib/components/Typography'
import {InputReadOnly} from '../Input/Input.Styled'

const Select = ({
  value,
  onChange,
  noMargin,
  status,
  options,
  mode,
  placeholder = 'Select an option',
  label,
  isDark,
  disabled,
  id,
  readOnly,
  filterOption,
  showSearch
}: FormTypes.SelectProps) => {
  const handleOnSelect = (val: FormTypes.ValueType) => {
    onChange && onChange(val, id)
  }

  if (readOnly) {
    const text = value?.toString()
    return (
      <SelectWrapper $noMargin={noMargin} $isDark={isDark}>
        {label && <FormLabel id={id} label={label} isDark={isDark} />}
        <InputReadOnly title={text}>
          <Typography.Text ellipsis={{tooltip: text}}>{text}</Typography.Text>
        </InputReadOnly>
      </SelectWrapper>
    )
  }

  const defaultFilterOption = (input: string, option?: { label: any; value: any }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <SelectWrapper $noMargin={noMargin} $isDark={isDark}>
      {label && <FormLabel id={id} label={label} isDark={isDark} />}
      <AntSelect
        placeholder={placeholder}
        id={id}
        suffixIcon={<IconChevronDown />}
        value={value || undefined}
        onChange={handleOnSelect}
        prefixCls="lb"
        options={options}
        status={status}
        mode={mode}
        disabled={disabled}
        filterOption={filterOption || defaultFilterOption}
        showSearch
      />
    </SelectWrapper>
  )
}
Select.defaultProps = {
  placeholder: 'Select an option',
}
Select.propTypes = {
  placeholder: string,
  value: oneOfType([string, number, instanceOf(Array)]),
  onselect: func,
  options: instanceOf(Array),
  isDark: bool,
  label: string,
  id: string,
}
export default Select
