import {FormTypes} from 'lib/components/Form/Form'
import {cloneDeep} from 'lodash'

export const RoleCategoryOptions: FormTypes.Options[] = [
  {label: 'Dashboard', value: 'Dashboard'},
  {label: 'Promotions', value: 'Promotions'},
  {label: 'Members', value: 'Members'},
  {label: 'Rewards', value: 'Rewards'},
  {label: 'Gamification', value: 'Gamification'},
  {label: 'Reporting', value: 'Reporting'},
  {label: 'Users', value: 'Users'},
  {label: 'Program Setup', value: 'Program Setup'},
]

export const SortFunctionsByOptions = [
  {label: 'All Functions Alphabetical A-Z', value: 'ALL,asc'},
  {label: 'All Functions Alphabetical Z-A', value: 'ALL,desc'},
  {label: 'Only View Access A-Z', value: 'VIEW,asc'},
  {label: 'Only View Access Z-A', value: 'VIEW,desc'},
  {label: 'Only Edit Access A-Z', value: 'EDIT,asc'},
  {label: 'Only View Access Z-A', value: 'EDIT,desc'},
]

export const inititalRoleRequestObj: UserManagementTypes.RoleResponseType = {
  roleCode: '',
  roleName: '',
  functions: [],
  status: 'Active',
  allowAccessPattern: '',
  denyAccessPattern: '',
  enforceFilter: '',
  properties: {},
}

export let getRolesRequestObj: UserManagementTypes.RoleResponseType = cloneDeep(inititalRoleRequestObj)

export const updateRoleRequestObj = (data: UserManagementTypes.RoleResponseType) => {
  getRolesRequestObj = cloneDeep(data)
}
