import {useState} from 'react'
import Typography from 'lib/components/Typography'
import {RewardTriggerSectionWrapper, TierSelectionWrapper, TriggerQuestionWrapper} from '../Reward.Styled'
import Form from 'lib/components/Form'
import {CheckboxValueType} from 'antd/es/checkbox/Group'
import {TiersOptions, toggleOptions} from './utils'
import ToggleButton from 'lib/components/ToggleButton'
import {TierDataWrapper, TierText} from 'app/pages/Promotions/CreatePromotion/Steps/Steps.styled'
import {FormTypes} from 'lib/components/Form/Form'
import {numberWithCommas} from 'utils/common'

interface RewardTrgiggerQueType {
  triggerInfo: RewardsTypes.TirggerRuleType
  id: string
  rewardTriggerData: RewardsTypes.RewardTriggerType | null
  isRedemption: boolean
  isTierBenefit: boolean
  tiersData: MemebershipTypes.TierCountType[]
  onTriggerChange(id: string, isAvailable: boolean): void
  onSelectTierType(id: string, isAllTiers: boolean): void
  onTierSelection(id: string, selectedTiers: RewardsTypes.RewardTierInfo[]): void
  disabled?: boolean
}

const RewardTriggerSection = ({
  id,
  triggerInfo,
  disabled,
  isRedemption,
  isTierBenefit,
  rewardTriggerData,
  tiersData,
  onTriggerChange,
  onSelectTierType,
  onTierSelection,
}: RewardTrgiggerQueType) => {
  const {question, subQue} = triggerInfo
  let tempIsAllTiers = false
  let tierData: RewardsTypes.RewardTierInfo[] = []
  if (rewardTriggerData?.triggerData) {
    tempIsAllTiers = !!rewardTriggerData.triggerData.isAllTiers
    tierData = rewardTriggerData.triggerData.tierData || []
  }
  const [available, setAvailable] = useState<boolean>(id === 'pointRedemption' ? isRedemption : isTierBenefit)
  const [selectedTier, setSelectedTier] = useState<CheckboxValueType>(tempIsAllTiers ? 'All' : 'tiers')
  const [selectedTierData, setSelectedTierData] = useState<RewardsTypes.RewardTierInfo[]>(tierData)

  // const [tierData, setSelectedTiersData] = useState<PromotionsTypes.TierDataType[]>([])

  // useEffect(() => {
  //   setSelectedTiersData([])
  // }, [])

  const handleOnChange = (value: boolean, key: keyof RewardsTypes.TriggerRulesData) => {
    onTriggerChange(id, value)
    setAvailable(value)
    if (!value) {
      setSelectedTier('All')
      setSelectedTierData([])
    }
  }

  const handleTierChange = (value: CheckboxValueType, key: string) => {
    setSelectedTier(value)
    setSelectedTierData([])
    onSelectTierType(id, value === 'All')
  }

  // const getTierOptions = (): FormTypes.Options[] => {
  //   return tierData.map((item) => ({
  //     label: `${item.tierName} (${item.tierCount})`,
  //     value: item.tierCode,
  //   }))
  // }

  const getTierDataOptions = () => {
    const listButtonOptions: FormTypes.Options[] = []
    tiersData.forEach((tier) => {
      const obj: FormTypes.Options = {
        label: `${tier.tierName} (${numberWithCommas(tier.tierCount)})`,
        value: tier.tierCode,
      }
      listButtonOptions.push(obj)
    })
    return listButtonOptions
  }

  const formatTierToReqObject = (data: FormTypes.Options[]): RewardsTypes.RewardTierInfo[] => {
    return data.map((item) => ({
      membershipTierKey: item.value as string,
      membershipTierName: item.label as string,
    }))
  }

  const onTierSelect = (option: FormTypes.OptionValueType[], key: string) => {
    const options = getTierDataOptions()
    if (options) {
      const filterData: FormTypes.Options[] = options
        .filter((item) => option.includes(item.value as FormTypes.OptionValueType))
        .map((item) => {
          return {
            ...item,
            label: item.label?.toString().split(' ')[0],
          }
        })
      const requestTierData = formatTierToReqObject(filterData)
      setSelectedTierData(requestTierData)
      onTierSelection(id, requestTierData)
      // handleChange(filterData, 'selectedTier')
    }
  }

  const renderTierData = () => {
    const options = getTierDataOptions()
    if (options) {
      return (
        <TierDataWrapper>
          <TierText>TIERS (Select all that apply)</TierText>
          <Form.Checkbox
            isVerticalView
            id="selectedTier"
            value={selectedTierData.map((item) => item.membershipTierKey)}
            onChange={onTierSelect}
            options={options}
            disabled={disabled}
          />
        </TierDataWrapper>
      )
    }
    return <div style={{padding: 40}}>No Tier Data found.</div>
  }

  return (
    <RewardTriggerSectionWrapper>
      <TriggerQuestionWrapper>
        <Typography.Title level={5}>{question}</Typography.Title>
      </TriggerQuestionWrapper>
      <ToggleButton id={id} values={toggleOptions} value={available} handleChange={handleOnChange} disabled={disabled} />
      {available && (
        <>
          <TierSelectionWrapper>
            {subQue && <Typography.Text>{subQue}</Typography.Text>}
            <Form.Radio id={'tierSelected'} value={selectedTier} options={TiersOptions} onChange={handleTierChange} disabled={disabled} />
          </TierSelectionWrapper>
          {selectedTier === 'tiers' && renderTierData()}
        </>
      )}
    </RewardTriggerSectionWrapper>
  )
}

export default RewardTriggerSection
