// import {Typography} from 'antd'
import {Cell, CellWrapper, DataRow, NoRecordFound, TableCircularProgress, TableRowWrapper} from '../DataTable.styled'
import LoadingPanel from 'lib/components/LoadingPanel'
import Typography from 'lib/components/Typography'

const Rows = ({columns, rows, rowActions, loading, noRecordFoundComponent, isClickable}: DataTableTypes.RowsProps) => {
  const getRowActions = (rowIndex: number) => {
    const actions = rowActions?.endActions?.map((action) => {
      if (action.component) {
        return <div key={`component_${rowIndex}`}>{action.component(rowIndex)}</div>
      } else if (action.actionTitle) {
        return <div key={`actiontitle_${rowIndex}`}>{action.actionTitle}</div>
      }
      return null
    })
    return (
      <CellWrapper key={`rowactions_${rowIndex}`}>
        <Cell className="action-cell">{actions}</Cell>
      </CellWrapper>
    )
  }
  const renderRowColumn = (row: DataTableTypes.Row, rowIndex: number) => {
    const rowColumns = columns.map((column, index) => {
      const rowValue = row[column.id]
      return (
        <CellWrapper $width={column.width} key={`${column.id}_${rowIndex}_${index}`}>
          <Cell $textAlign={column.textAlign}>
            {typeof rowValue === 'string' ? <Typography.Text ellipsis={column.wrapText ? undefined : {tooltip: rowValue}}>{rowValue}</Typography.Text> : rowValue}
          </Cell>
        </CellWrapper>
      )
    })
    if (rowActions?.endActions) {
      rowColumns.push(getRowActions(rowIndex))
    }
    return rowColumns
  }
  const renderRow = () => {
    if (rows.length === 0 && !loading) {
      return noRecordFoundComponent ? (
        noRecordFoundComponent
      ) : (
        <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
      )
    }
    return rows.map((row, index) => {
      if (rowActions?.rowClick) {
        const rowClick = isClickable ? rowActions.rowClick : () => {}
        return (
          <DataRow data-key={`row-${index}`} role={isClickable ? "button" : "div"} isClickable={isClickable} onClick={() => rowClick(index)} key={`row-${index}`}>
            {renderRowColumn(row, index)}
          </DataRow>
        )
      }
      return (
        <DataRow data-key={`row-${index}`} actionOnhover={rowActions?.hoverOnly} key={`row-${index}`}>
          {renderRowColumn(row, index)}
        </DataRow>
      )
    })
  }
  return (
    <TableRowWrapper>
      {renderRow()}
      {loading && (
        <TableCircularProgress>
          <LoadingPanel />
        </TableCircularProgress>
      )}
    </TableRowWrapper>
  )
}

export default Rows
