import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Typography from 'lib/components/Typography'

interface RewardHeaderProps {
  activeStep: number
}

const RewardsHeader: React.FC<RewardHeaderProps> = ({activeStep}) => {
  return (
    <StyledHeader>
      <Typography.Title level={2}>{activeStep === 0 ? 'Add New Reward' : 'Preview'}</Typography.Title>
    </StyledHeader>
  )
}

export default RewardsHeader
