import {CSSProperties} from 'react'
import styled from 'styled-components'
import Colors from 'styles/Colors'

interface TableFooterProps {
  $align: CSSProperties['justifyContent']
}

export const TableFooter = styled.div<TableFooterProps>`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: ${(props) => props.$align};
`

export const PaginationBody = styled.div`
  display: flex;
`

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.GREY6};
  height: 36px;
  width: 36px;
  cursor: pointer;
  &:hover,
  &.active {
    background-color: ${Colors.GREY3};
  }
  margin-left: -1px;
`

export const PaginationBtnWrapper = styled.div`
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`
