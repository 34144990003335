import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const getStatus = (
  params: PromotionStatusType.PromotionStatusDetails
): Promise<AxiosResponse<ResponseTypes.ResponseData<PromotionStatusType.PromotionStatusBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.promotionStatus,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

const PromotionStatusService = {
  getStatus,
}

export default PromotionStatusService
