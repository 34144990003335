import { FormTypes } from "lib/components/Form/Form";

export const affectedModuleOptions: FormTypes.Options[] = [
  {label: 'Background Process', value: 'BACKGROUNDPROCESS'},
  {label: 'Reward', value: 'REWARD'},
  {label: 'Promotion', value: 'PROMOTION'},
  {label: 'Segment', value: 'SEGMENT'},
  {label: 'Member', value: 'MEMBER'},
  {label: 'User', value: 'USER'},
  {label: 'Point', value: 'POINT'},
  {label: 'Tier', value: 'TIER'},
]

// export const sampleActivityData = [{
//   fromDate: '2024-02-16T10:53:13.42648',
//   toDate: '2024-02-16T10:53:13.42648',
//   affectedModule: 'Promotion',
//   uniqueId: 'Promotion_123',
//   details: "Promotion 'SummerSale2024' deactivated by Administrator ID 456",
//   modifiedBy: "Abhishek Jain"
// }, {
//   fromDate: '2024-02-16T10:53:13.42648',
//   toDate: '2024-02-16T10:53:13.42648',
//   affectedModule: 'Members',
//   uniqueId: 'Members_123',
//   details: "Members 'SummerSale2024' deactivated by Administrator ID 456",
//   modifiedBy: "Abhishek Jain"
// }, {
//   fromDate: '2024-02-16T10:53:13.42648',
//   toDate: '2024-02-16T10:53:13.42648',
//   affectedModule: 'Rewards',
//   uniqueId: 'Rewards_123',
//   details: "Rewards 'SummerSale2024' deactivated by Administrator ID 456",
//   modifiedBy: "Abhishek Jain"
// }, {
//   fromDate: '2024-02-16T10:53:13.42648',
//   toDate: '2024-02-16T10:53:13.42648',
//   affectedModule: 'Segments',
//   uniqueId: 'Segments_123',
//   details: "Segments 'SummerSale2024' deactivated by Administrator ID 456",
//   modifiedBy: "Abhishek Jain"
// }]