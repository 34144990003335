import {oneOf} from 'prop-types'
import {TitleRoot} from './SectionTitle.styled'

export interface SectionTitleSpacingTypes {
  spacingV?: 'none' | 'large' | 'medium' | 'small'
  spacingH?: 'none' | 'large' | 'medium' | 'small'
}

interface SectionTitleTypes extends SectionTitleSpacingTypes {
  children?: React.ReactNode
}

const SectionTitle: React.FC<SectionTitleTypes> = (props) => {
  return (
    <TitleRoot spacingH={props.spacingH} spacingV={props.spacingV}>
      {props.children}
    </TitleRoot>
  )
}

SectionTitle.defaultProps = {
  spacingV: 'none',
  spacingH: 'none',
}
SectionTitle.propTypes = {
  spacingV: oneOf(['none', 'large', 'medium', 'small']),
  spacingH: oneOf(['none', 'large', 'medium', 'small']),
}
export default SectionTitle
