import {bool, node, number, oneOf, string} from 'prop-types'
import {TypographyTypes} from './Typography'
import {TitleRoot} from './Typography.styled'

const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle: 'h6',
  body1: 'p',
  body2: 'p',
  inherit: 'p',
  label: 'span',
  link: 'a',
}

const Typography: React.FC<TypographyTypes> = (props) => {
  const {
    textAlign = 'inherit',
    className,
    component,
    marginBottom = false,
    noWrap = false,
    variant = 'body1',
    children,
    htmlFor,
    id,
    name,
    uppercase,
    color,
    bold,
  } = props

  const Component = component || defaultVariantMapping[variant] || 'span'

  return (
    <TitleRoot
      bold={bold}
      color={color}
      uppercase={uppercase}
      htmlFor={htmlFor}
      id={id}
      name={name}
      $isDark={props.$isDark}
      as={Component}
      variant={variant}
      textAlign={textAlign}
      marginBottom={marginBottom}
      noWrap={noWrap}
      className={className}
    >
      {children}
    </TitleRoot>
  )
}

Typography.propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Set the text-align on the component.
   * @default 'inherit'
   */
  align: oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  /**
   * The content of the component.
   */
  children: node,
  /**
   * @ignore
   */
  className: string,
  /**
   * If `true`, the text will have a bottom margin.
   * @default false
   */
  marginBottom: number,
  /**
   * If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.
   *
   * Note that text overflow can only happen with block or inline-block level elements
   * (the element needs to have a width in order to overflow).
   * @default false
   */
  noWrap: bool,

  /**
   * Applies the theme typography styles.
   * @default 'body1'
   */
  // variant: oneOfType([
  //   oneOf(['body1', 'body2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'inherit', 'subtitle', 'link', 'label']),
  // ], string)
  variant: oneOf(['body1', 'body2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle', 'link', 'label']),
  textAlign: string,
}
export default Typography
