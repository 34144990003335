import {FormTypes} from './Form'
import {StyledLabel} from './FormStyled'

const FormLabel: React.FC<FormTypes.LabelProps> = ({id, label, isDark}) => {
  return (
    <StyledLabel id={`${id}-label`} htmlFor={id} $isDark={isDark}>
      {label}
    </StyledLabel>
  )
}

export default FormLabel
