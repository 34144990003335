import {RouteObject} from 'react-router'

import UserManagement from '../pages/UserManagement'
import Functions from '../pages/UserManagement/Functions'

import {ErrorBoundry} from '../common/components/Routes/Fallback'
import AddNewRole from 'app/pages/UserManagement/Roles/AddNewRole'
import Dashboard from 'app/pages/UserManagement/Dashboard'
import AddNewUser from 'app/pages/UserManagement/Users/AddNewUser'

const UserManagementRoutes: RouteObject = {
  path: `user-management`,
  element: <UserManagement />,
  errorElement: <ErrorBoundry />,
  children: [
    {index: true, element: <Dashboard />},
    {path: 'role/:roleCode', element: <AddNewRole />},
    {path: 'role/view/:roleCode', element: <AddNewRole isViewOnly />},
    {path: 'user/:userId', element: <AddNewUser />},
    {path: 'user/view/:userId', element: <AddNewUser isViewOnly />},
    {path: 'api', element: <Functions />},
  ],
}

export default UserManagementRoutes
