import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const searchMember = (
  params: MemebershipTypes.MembershipDetail
): Promise<AxiosResponse<ResponseTypes.PagedResponse<MemebershipTypes.MembershipDataBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.memberSearch,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

const MembershipService = {
  searchMember,
}

export default MembershipService
