import { Col } from "antd"
import Form from "lib/components/Form"
import { FormTypes } from "lib/components/Form/Form"
import { useEffect, useState } from "react"
import { FullWidthRow } from "./Trigger.styled"
import Button from "lib/components/Button"
import IconDelete from "app/common/icons/IconDelete"

interface MultiCounterRowProps {
  data: FormTypes.Options[]
  originaldata: FormTypes.Options[]
  promoCounterItem: PromotionsTypes.PromoCounterType
  promoCounterData: PromotionsTypes.PromoCounterType[]
  rowIndex: number
  handleUpdateRow(row: PromotionsTypes.PromoCounterType, rowIndex: number): void
  handleDeleteRow(rowIndex: number): void
}

const MultiCounterRow: React.FC<MultiCounterRowProps> = ({ data, originaldata, promoCounterItem, rowIndex, handleUpdateRow, handleDeleteRow, promoCounterData }) => {
  const [promotion, setPromotion] = useState<string>(promoCounterItem.promotionName)
  const [counter, setCounter] = useState<number>(promoCounterItem.counter)

  useEffect(() => {
    setPromotion(promoCounterItem.promotionName)
    setCounter(promoCounterItem.counter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCounterData])

  const handleSelectPromotion = (value: FormTypes.ValueType): void => {
    const selPromotion = originaldata.find(item => item.value === value)
    if (selPromotion) {
      const promoRow: PromotionsTypes.PromoCounterType = {
        promotionCode: value as string,
        promotionName: selPromotion.label as string,
        counter
      }
      handleUpdateRow(promoRow, rowIndex)
      setPromotion(selPromotion.label as string)
    }
  }

  const handleUpdateCounter = (value: number) => {
    setCounter(value)
    if (promotion) {
      const selPromotion = originaldata.find(item => item.label === promotion)
      if (selPromotion) {
        const promoRow: PromotionsTypes.PromoCounterType = {
          promotionCode: selPromotion.value as string,
          promotionName: selPromotion.label as string,
          counter: value
        }
        handleUpdateRow(promoRow, rowIndex)
      }
    } else {
      handleUpdateRow({ ...promoCounterItem, counter: value}, rowIndex)
    }
  }

  return (
    <div>
      <FullWidthRow gutter={24} >
        <Col span={8}>
        <Form.Select
          id={`${promotion}_${rowIndex}`}
          label="Select Promotions"
          value={promotion}
          options={data}
          onChange={handleSelectPromotion}
        />
        </Col>
        <Col span={3}>
          <Form.InputNumber
            id={`counter_${rowIndex}_number`}
            name="counter"
            label="Counter"
            min={1}
            max={1000}
            width={102}
            value={counter}
            onChange={handleUpdateCounter}
            disabled={!Boolean(promotion)}
            precision={0}
          />
        </Col>
        {promoCounterData.length > 1 && <Col span={6} style={{ alignSelf: 'center' }}>
          <Button ghost size="small" icon={<IconDelete size={18} />} onClick={() => handleDeleteRow(rowIndex)} />
        </Col>}
      </FullWidthRow>
    </div>
  )
}

export default MultiCounterRow