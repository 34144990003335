// import { PopoverMenuButton } from 'app/common/components/Styled/common.styled'
import {Modal, Tooltip} from 'antd'
import {MarginBottomWrapper, ModalTitle, Seperator, StyledMenuItem, StyledModalContent} from 'app/common/components/Styled/common.styled'
import IconChevronRight from 'app/common/icons/IconChevronRight'
import IconSuccess from 'app/common/icons/IconSuccess'
// import IconEdit from 'app/common/icons/IconEdit'
// import IconEye from 'app/common/icons/IconEye'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import SegmentationService from 'app/services/SegmentationService'
import Typography from 'lib/components/Typography'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import LoadingPanel from 'lib/components/LoadingPanel'
import Popover from 'lib/components/Popover'
import ToggleButton from 'lib/components/ToggleButton'
import {ToggleOptionType} from 'lib/components/ToggleButton/ToggleButton'
import {useState} from 'react'
// import {toast} from 'react-hot-toast'
// import { toast } from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'
import {SegmentMemberName} from '../Segment.styled'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {SEGMENT_ACTION_DETAIL, SEGMENT_ACTIVATE, SEGMENT_DETAIL, SEGMENT_VIEW_MEMBERS} from 'app/common/helpers/UserFunctions'
//import {segmentStatus} from '../AddNewSegment/utils'

let dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Name',
    id: 'segmentName',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Segment ID',
    id: 'segmentCode',
    textAlign: 'left',
    width: '15%',
  },
  // {
  //   title: 'Status',
  //   id: 'segmentStatus',
  //   textAlign: 'left',
  //   width: '10%',
  // },
  {
    title: 'Description',
    id: 'description',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Category',
    id: 'segmentCategory',
    textAlign: 'left',
    width: '10%',
  },
  {
    title: 'ACTIONS',
    id: 'toggle',
    textAlign: 'center',
    width: '28%',
  },
  {
    title: '',
    id: 'actions',
    textAlign: 'center',
    width: '10%',
  },
]

const activateOptions: ToggleOptionType[] = [
  {
    label: 'On',
    value: true,
  },
  {
    label: 'Off',
    value: false,
  },
]

interface SearchResultProps {
  isFetching: boolean
  segmentsResponse: ResponseTypes.PagedResponse<SegmentationTypes.SegmentResponseBL[]>
  handleSearch: (value: boolean, voucherTypeCode: string) => void
}

const SearchResults = ({isFetching, segmentsResponse, handleSearch}: SearchResultProps) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [selectedSegment, setSelectedSegment] = useState<SegmentationTypes.SegmentResponseBL | null>(null)
  const {roles} = useAuth()
  const showDetail = roles.includes(SEGMENT_DETAIL) || roles.includes(SEGMENT_ACTION_DETAIL)
  const showActivate = roles.includes(SEGMENT_ACTIVATE)
  const viewSegmentMembers = roles.includes(SEGMENT_VIEW_MEMBERS)
  dataTableColumns = dataTableColumns.map((item) => {
    if (!showActivate && item.id === 'toggle') {
      return {...item, title: ''}
    }
    return item
  })
  // const handleEdit = (rowIndex: number) => {
  //   const segmentCode = segmentsResponse.data[rowIndex].segmentCode
  //   navigate(`/segments/${segmentCode}`)
  // }
  const handleOk = () => {
    setProcessing(true)
    if (!selectedSegment) {
      return
    }
    SegmentationService.saveSegment({...selectedSegment, isEnabled: !selectedSegment.isEnabled}, {segmentCode: selectedSegment.segmentCode})
      .then((res) => {
        handleSearch(!selectedSegment.isEnabled, selectedSegment.segmentCode)
        setOpen(true)
        setIsSaved(true)
        //  toast.success('Data Saved Successfully!')
      })
      .catch(() => {
        console.log('Error in fetching RewardService ')
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  // const handleCancelNewSegment = () => {
  //   if (isViewOnly === true) {
  //     handleCancelConfirm()
  //   } else {
  //     setOpen(true)
  //   }
  // }
  const handleCloseModal = () => {
    setOpen(false)
    setIsSaved(false)
    setProcessing(false)
  }
  const handelContinue = () => {
    handleCloseModal()
  }
  const renderModalBody = () => {
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (isSaved) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Confirmation
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                {selectedSegment?.segmentName} has been {selectedSegment?.isEnabled ? 'Deactivated' : 'Activated'}.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handelContinue}>
                Close
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">
            Are you sure you want to {selectedSegment?.isEnabled ? 'Deactivate' : 'Activate'} {selectedSegment?.segmentName} ?
          </Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            {selectedSegment?.isEnabled ? 'Deactivate' : 'Activate'}
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCloseModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  const handleView = (rowIndex: number) => {
    const segmentCode = segmentsResponse.data[rowIndex].segmentCode
    const encodedSegmentCode = btoa(segmentCode)
    navigate(`/segments/view/${encodedSegmentCode}`)
  }

  const handleActivate = (value: boolean, key: string, rowIndex: number): void => {
    let segmentData = segmentsResponse.data[rowIndex]
    segmentData = {
      ...segmentData,
    }
    !isLoading && setIsLoading(false)
    segmentsResponse.data[rowIndex] = segmentData
    setSelectedSegment(segmentData)
    setOpen(true)
    // SegmentationService.saveSegment(segmentData, {segmentCode: segmentData.segmentCode})
    //   .then((res) => {
    //     handleSearch(value, segmentData.segmentCode)
    //     toast.success('Data Saved Successfully!')
    //   })
    //   .catch(() => {
    //     console.log('Error in fetching RewardService ')
    //   })
  }

  const handleViewMembers = (rowIndex: number, item: SegmentationTypes.SegmentBasicInfo) => {
    const encodedSegmentCode = btoa(item.segmentCode)

    navigate(`/segments/${encodedSegmentCode}/members`)
  }

  const uploadMembers = (item: SegmentationTypes.SegmentBasicInfo): void => {
    navigate(`/program/import/new?dataType=SEGMENT&dataTypeId=${item.segmentId}`)
  }

  const renderActionList = (rowIndex: number, item: SegmentationTypes.SegmentBasicInfo) => {
    return (
      <>
        {showDetail && (
          <StyledMenuItem>
            <Button type="text" onClick={() => handleView(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
              View Details
            </Button>
          </StyledMenuItem>
        )}
        {viewSegmentMembers && (
          <StyledMenuItem title="View Members">
            <Button
              type="text"
              disabled={item.segmentCategory.toUpperCase() === 'REALTIME'}
              onClick={() => handleViewMembers(rowIndex, item)}
              $endIcon={<IconChevronRight size={16} />}
            >
              View Members
            </Button>
          </StyledMenuItem>
        )}
        {item.segmentType === "MANUAL" && <StyledMenuItem>
          <Button type="text" onClick={() => uploadMembers(item)} $endIcon={<IconChevronRight size={16} />}>
            Upload Members
          </Button>
        </StyledMenuItem>}
      </>
    )
  }

  const renderRowActions = (rowIndex: number, item: SegmentationTypes.SegmentBasicInfo) => {
    if (showDetail || viewSegmentMembers) {
      return <Popover btnContent={<IconThreeDots />} content={renderActionList(rowIndex, item)} />
    }
    return null
  }

  const renderToggle = (rowIndex: number) => {
    const segment = segmentsResponse.data[rowIndex]
    const isAtivationDisabled = segment?.assignedPromotion.length > 0
    if (showActivate) {
      return (
        <Tooltip title={`This segment is associated to promotion id ${segment?.assignedPromotion.map((x) => x.promotionCode).join(', ')} `}>
          {isAtivationDisabled ? (
            <span>
              <ToggleButton
                id="activate"
                value={segment.isEnabled}
                values={activateOptions}
                handleChange={(value, key) => handleActivate(value, key, rowIndex)}
                disabled={isAtivationDisabled}
              />
            </span>
          ) : (
            <ToggleButton
              id="activate"
              value={segment.isEnabled}
              values={activateOptions}
              handleChange={(value, key) => handleActivate(value, key, rowIndex)}
              disabled={isAtivationDisabled}
            />
          )}
        </Tooltip>
      )
    }
    return null
  }
  const renderSegmentName = (item: SegmentationTypes.SegmentResponseBL) => {
    return (
      <SegmentMemberName>
        {item.segmentName} <span>({item.memberCount}) </span>
      </SegmentMemberName>
    )
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (segmentsResponse && segmentsResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = segmentsResponse
      data.map((item, index) => {
        console.log('item', item.segmentCategory)
        const obj: DataTableTypes.Row = {
          segmentName: renderSegmentName(item),
          segmentCode: item.segmentCode,
          description: item.segmentDescription,
          segmentCategory: item.segmentCategory,
          segmentStatus: item.segmentStatus,
          toggle: renderToggle(index),
          actions: renderRowActions(index, item),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCloseModal} footer={null}>
          {renderModalBody()}
        </Modal>
      )}
    </>
  )
}

export default SearchResults
