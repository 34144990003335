import {RouteObject} from 'react-router'
import {ErrorBoundry} from '../common/components/Routes/Fallback'
import Dashboard from 'app/pages/Rewards/Dashboard'
import AddNewRewards from 'app/pages/Rewards/AddNewRewrds'
import Rewards from 'app/pages/Rewards'

const RewardsRoutes: RouteObject = {
  path: `rewards`,
  element: <Rewards />,
  errorElement: <ErrorBoundry />,
  children: [
    {index: true, element: <Dashboard />},
    {path: ':rewardId', element: <AddNewRewards />},
    {path: 'view/:rewardId', element: <AddNewRewards isViewOnly />},
  ],
}

export default RewardsRoutes
