import {Button, Col, Row} from 'antd'
import Typography from 'lib/components/Typography'
import {ReviewRow} from './Review.styled'
import {LIMIT_TYPES} from '../../utils'
import {FormTypes} from 'lib/components/Form/Form'
import {MarginBottomWrapper} from 'app/common/components/Styled/common.styled'
import {utcDate, utcWithTimeZone} from 'utils/convertDateToString'
import Appconfig from 'app/common/helpers/AppConfig'

interface TriggerDetailsProps {
  data:
    | PromotionsTypes.TriggerData[]
    | PromotionsTypes.ActivityData
    | PromotionsTypes.EnrollmentData
    | PromotionsTypes.EventData
    | PromotionsTypes.MultiCounterType
    | PromotionsTypes.SpendData
  onEdit(stepId: number): void
  promotionType: string
  isViewOnly?: boolean
  isDisabelEdit?: boolean
  limitData?: PromotionsTypes.LimitData | null
  showEdit: boolean
  pointTypeOptions: FormTypes.Options[]
}

const TriggerDetails: React.FC<TriggerDetailsProps> = ({
  data,
  onEdit,
  isViewOnly,
  isDisabelEdit,
  promotionType,
  limitData,
  showEdit,
  pointTypeOptions,
}) => {
  const getValues = (values: PromotionsTypes.TriggerData['values']) => values.join(', ')

  const getPointTypeName = (pointTypeCode: string): string => {
    const pointType = pointTypeOptions.find((item) => item.value === pointTypeCode)
    return pointType ? (pointType.label as string) : ''
  }

  const getTriggerValues = () => {
    switch (promotionType) {
      case 'ACTIVITY': {
        const activityData = data as PromotionsTypes.ActivityData
        return {
          label: activityData.activityName,
          value: activityData.activityCode,
          title: 'Activity',
          rewards: activityData.activityCode === 'Redemption' ? activityData.reward : null,
          bonusCode: activityData.activityCode === 'fixCode' ? activityData.bonusCode : '',
        }
      }
      case 'ENROLLMENT': {
        const enrollmentData = data as PromotionsTypes.EnrollmentData
        return {
          regularEnrollment: enrollmentData.regularRuleEnrollment,
          conditionalEnrollment: enrollmentData.condititonalRuleEnrollment,
          title:
            enrollmentData.regularRuleEnrollment != null
              ? 'Regular Enrollment'
              : `${
                  enrollmentData.condititonalRuleEnrollment != null
                    ? `Conditional Enrollment (${
                        enrollmentData.condititonalRuleEnrollment.earnPointRuleEnrollment != null ? 'On earning points' : 'On Promotion Completion'
                      })`
                    : ''
                }`,
        }
      }
      case 'EVENT': {
        const eventData = data as PromotionsTypes.EventData
        return {
          label: eventData.eventName,
          value: eventData.eventCode,
          eventValue: eventData.eventValue,
          pointCodeType: eventData.pointTypeCode,
          title: 'Event',
        }
      }
      case 'MULTICOUNTER': {
        const multiCounterData = data as PromotionsTypes.MultiCounterType
        return {
          title: 'Multi Counter',
          counterData: multiCounterData.promoCounter || [],
        }
      }
      case 'SPEND': {
        const spendData = data as PromotionsTypes.SpendData
        return {
          title: 'Spend',
          label: spendData.spendType === 'ENROLLMENTPERIOD' ? 'Enrollment Period' : 'Limited Period',
          spendData,
        }
      }
      default:
        return
    }
  }

  const renderSpendData = (spendData: PromotionsTypes.SpendData) => {
    if (spendData.spendType === 'LIMITEDPERIOD') {
      return (
        <>
          <Col span={6}>
            <Typography.Text uppercase size="small">
              SPEND VALUE MILESTONE
            </Typography.Text>
            <div>{spendData.spendValue}</div>
          </Col>
          {spendData.timePeriod && (
            <Col span={12}>
              <Typography.Text uppercase size="small">
                TIME PERIOD
              </Typography.Text>
              <div>
                {utcDate(utcWithTimeZone(spendData.timePeriod.from), Appconfig.DATE_TIME_FORMAT)} -{' '}
                {utcDate(utcWithTimeZone(spendData.timePeriod.to), Appconfig.DATE_TIME_FORMAT)}
              </div>
            </Col>
          )}
        </>
      )
    } else {
      return (
        <>
          <Col span={6}>
            <Typography.Text uppercase size="small">
              SPEND VALUE MILESTONE
            </Typography.Text>
            <div>{spendData.spendValue}</div>
          </Col>
          <Col span={6}>
            <Typography.Text uppercase size="small">
              WITHIN DAYS AFTER ENROLLMENT
            </Typography.Text>
            <div>{spendData.withiEnrollmentDays}</div>
          </Col>
        </>
      )
    }
  }

  const renderTriggerData = () => {
    const triggerValues = getTriggerValues()
    console.log(triggerValues)
    return (
      <MarginBottomWrapper>
        {promotionType !== 'MULTICOUNTER' ? (
          <>
            <MarginBottomWrapper>
              <Row>
                <Col span={8}>
                  <Typography.Text uppercase size="small">
                    {triggerValues?.title}
                  </Typography.Text>
                  <div>{triggerValues?.value === 'fixCode' ? 'Bonus Code' : triggerValues?.label}</div>
                </Col>
                {triggerValues?.pointCodeType && (
                  <Col span={6}>
                    <Typography.Text uppercase size="small">
                      Point Type
                    </Typography.Text>
                    <div>{getPointTypeName(triggerValues?.pointCodeType)}</div>
                  </Col>
                )}
                {triggerValues?.eventValue && triggerValues?.eventValue > 0 ? (
                  <Col span={6}>
                    <Typography.Text uppercase size="small">
                      Threshold
                    </Typography.Text>
                    <div>{triggerValues.eventValue}</div>
                  </Col>
                ) : (
                  <></>
                )}

                {promotionType === 'SPEND' && triggerValues?.spendData && (
                  <>
                    {renderSpendData(triggerValues?.spendData)}
                    {/* <Typography.Text uppercase size="small">
                  Rewards
                </Typography.Text> */}
                    {/* <MarginBottomWrapper>
                  {triggerValues.rewards?.map((item, index) => (
                    <div>{`${index + 1}. ${item.rewardName}`}</div>
                  ))}
                </MarginBottomWrapper> */}
                  </>
                )}
              </Row>
              {promotionType === 'ENROLLMENT' && (
                <>
                  {triggerValues?.regularEnrollment != null ? (
                    <></>
                  ) : (
                    <>
                      {triggerValues?.conditionalEnrollment?.earnPointRuleEnrollment != null ? (
                        <>
                          <Row>
                            <Col span={6}>
                              <Typography.Text uppercase size="small">
                                PointType
                              </Typography.Text>
                              <div>{triggerValues.conditionalEnrollment.earnPointRuleEnrollment?.pointTypeCode}</div>
                            </Col>
                            <Col span={6}>
                              <Typography.Text uppercase size="small">
                                Points
                              </Typography.Text>
                              <div>{triggerValues.conditionalEnrollment.earnPointRuleEnrollment?.points}</div>
                            </Col>
                            <Col span={6}>
                              <Typography.Text uppercase size="small">
                                Days
                              </Typography.Text>
                              <div>{triggerValues.conditionalEnrollment.earnPointRuleEnrollment?.days}</div>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          {triggerValues?.conditionalEnrollment?.promotionRuleEnrollment != null && (
                            <>
                              <Row>
                                <Col span={6}>
                                  <Typography.Text uppercase size="small">
                                    Promotion Name
                                  </Typography.Text>
                                  <div>{triggerValues.conditionalEnrollment.promotionRuleEnrollment.promotionName}</div>
                                </Col>

                                <Col span={6}>
                                  <Typography.Text uppercase size="small">
                                    Days
                                  </Typography.Text>
                                  <div>{triggerValues.conditionalEnrollment.promotionRuleEnrollment.totalDays}</div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </MarginBottomWrapper>
            {promotionType === 'ACTIVITY' && triggerValues?.label === 'Redemption' && (
              <>
                <Typography.Text uppercase size="small">
                  Rewards
                </Typography.Text>
                <MarginBottomWrapper>
                  {triggerValues.rewards?.map((item, index) => <div>{`${index + 1}. ${item.rewardName}-${item.rewardCode}`}</div>)}
                </MarginBottomWrapper>
              </>
            )}
            {promotionType === 'ACTIVITY' && triggerValues?.value === 'fixCode' && (
              <>
                <Typography.Text uppercase size="small">
                  Bonus Code
                </Typography.Text>
                <div>{triggerValues?.bonusCode}</div>
              </>
            )}
          </>
        ) : (
          <>
            {triggerValues?.counterData?.map((item) => (
              <MarginBottomWrapper>
                <Row>
                  <Col span={6}>
                    <Typography.Text uppercase size="small">
                      Promotion Name
                    </Typography.Text>
                    <div>{item.promotionName}</div>
                  </Col>
                  <Col span={6}>
                    <Typography.Text uppercase size="small">
                      Counter
                    </Typography.Text>
                    <div>{item.counter}</div>
                  </Col>
                </Row>
              </MarginBottomWrapper>
            ))}
          </>
        )}
      </MarginBottomWrapper>
    )
  }

  const renderLimitData = () => {
    if (limitData) {
      return Object.keys(limitData).map((key) => {
        if (limitData[key as keyof typeof LIMIT_TYPES] !== null) {
          return (
            <MarginBottomWrapper>
              <Row key={key}>
                <Col span={6}>
                  <Typography.Text uppercase size="small">
                    Limit Type
                  </Typography.Text>
                  <div>{key.toUpperCase()}</div>
                </Col>
                <Col span={6}>
                  <Typography.Text uppercase size="small">
                    Limit Quantity
                  </Typography.Text>
                  <div>{limitData[key as keyof typeof LIMIT_TYPES]?.limitQuantity}</div>
                </Col>
                <Col span={6}>
                  <Typography.Text uppercase size="small">
                    Limit Frequency
                  </Typography.Text>
                  <div>{limitData[key as keyof typeof LIMIT_TYPES]?.limitFrequency}</div>
                </Col>
              </Row>
            </MarginBottomWrapper>
          )
        }
        return null
      })
    }
    return null
  }

  const renderTriggerList = (): JSX.Element[] | null => {
    if (Array.isArray(data)) {
      return data.map((item, index) => {
        return (
          <MarginBottomWrapper>
            <Row key={index}>
              <Col span={6}>
                <Typography.Text uppercase size="small">
                  Category
                </Typography.Text>
                <div>{item.productCategory?.categoryName}</div>
              </Col>
              <Col span={6}>
                <Typography.Text uppercase size="small">
                  Action
                </Typography.Text>
                <div>{item.action}</div>
              </Col>
              <Col span={6}>
                <Typography.Text uppercase size="small">
                  Value
                </Typography.Text>
                <div>{getValues(item.values)}</div>
              </Col>
            </Row>
          </MarginBottomWrapper>
        )
      })
    }
    return null
  }
  return (
    <ReviewRow>
      <div style={{width: '100%'}}>
        <Row style={{marginBottom: '24px'}}>
          <Typography.Text strong uppercase>
            Trigger ({promotionType})
          </Typography.Text>
        </Row>
        {Array.isArray(data) ? renderTriggerList() : renderTriggerData()}
        {limitData && <div style={{width: '100%'}}>{renderLimitData()}</div>}
      </div>
      <div>
        {!isViewOnly && showEdit && (
          <Button onClick={() => onEdit(2)} size="small" type="link" disabled={isDisabelEdit}>
            Edit
          </Button>
        )}
      </div>
    </ReviewRow>
  )
}

export default TriggerDetails
