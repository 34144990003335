import {RouteObject} from 'react-router'
import {ErrorBoundry} from '../common/components/Routes/Fallback'
import Dashboard from 'app/pages/Dashboard'

const DashboardRoutes: RouteObject = {
  path: `/dashboard`,
  element: <Dashboard />,
  errorElement: <ErrorBoundry />,
  children: [{index: true, element: <Dashboard />}],
}

export default DashboardRoutes
