import Button from "lib/components/Button";
import styled from "styled-components";
import Colors from "styles/Colors";

export const TierSystemInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 8px;
  background-color: ${Colors.GREY9};
`

export const TierSystemDetailSection = styled.div`
  width: 85%;
`

export const TimePeriodWrapper = styled.div`
  width: 50%;
`

export const MatchEndDateContainer = styled.div`
  padding: 8px 0;
`

export const TierSystemTableWrapper = styled.div`
  margin-bottom: 56px;
`

export const ZeroHeightButton = styled(Button)`
  height: 0;
  padding: 0 8px;
`

export const TiersFormBodyContainer = styled.div`
  border: 1px solid ${Colors.BORDER1};
  background-color: ${Colors.WHITE};
  padding: 34px;
`

export const NewTierFooter = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  .lb-btn {
    min-width: 150px;
    margin-left: 8px;
  }
`
export const TierDetailsFormWrapper = styled.div`
  margin-top: 32px;
  padding: 8px;
`

export const TierForm = styled.div`
  border-bottom: 1px solid ${Colors.BORDER2};
  margin-bottom: 16px;
`

export const TierAction = styled.div`
  text-align: end;
`