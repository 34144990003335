import {Col, Row} from 'antd'
import IconClose from 'app/common/icons/IconClose'
import Typography from 'lib/Typography'
import {
  MergeAccountCard,
  MergeAccountCardAction,
  MergeAccountCardActionIcon,
  MergeAccountCardActionLabel,
  MergeAccountCardHeader,
  MergeAccountLabel,
  MergeAccountValue,
} from './MergeAccounts.styled'

export interface AccountInfoCardDataType {
  name?: string
  accountNo: string

  gender?: string
  phone?: string
  email?: string
}

export type AccountInfoActionTypes = 'merges' | 'stays'

export interface AccountInfoCardProps {
  data: AccountInfoCardDataType
  root?: boolean
  handleOnClick(type: AccountInfoActionTypes): void
}

const AccountInfoCard = ({data, handleOnClick, root = true}: AccountInfoCardProps) => {
  return (
    <MergeAccountCard>
      <MergeAccountCardHeader>
        <Typography variant="h4">{data.name}</Typography>
        <MergeAccountCardAction color={root ? 'red' : 'green'} onClick={() => handleOnClick(root ? 'merges' : 'stays')}>
          <MergeAccountCardActionLabel>{root ? 'Merges' : 'Stays'}</MergeAccountCardActionLabel>
          <MergeAccountCardActionIcon>
            <IconClose size={16} />
          </MergeAccountCardActionIcon>
        </MergeAccountCardAction>
      </MergeAccountCardHeader>
      <Row gutter={[8, 4]}>
        {data.accountNo && (
          <Col span={12}>
            <MergeAccountLabel>Acct# -</MergeAccountLabel> <MergeAccountValue>{data.accountNo}</MergeAccountValue>
          </Col>
        )}

        {data.gender && (
          <Col span={12}>
            <MergeAccountLabel>Gender -</MergeAccountLabel> <MergeAccountValue>{data.gender}</MergeAccountValue>
          </Col>
        )}
        {data.phone && (
          <Col span={12}>
            <MergeAccountLabel>Phone -</MergeAccountLabel> <MergeAccountValue>{data.phone}</MergeAccountValue>
          </Col>
        )}
        {data.email && (
          <Col span={24}>
            <MergeAccountLabel>Email -</MergeAccountLabel> <MergeAccountValue>{data.email}</MergeAccountValue>
          </Col>
        )}
      </Row>
    </MergeAccountCard>
  )
}

export default AccountInfoCard
