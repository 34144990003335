import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'app'
import './styles/index.css'
import '@fontsource/ibm-plex-sans/400.css'
import '@fontsource/ibm-plex-sans/500.css'
import '@fontsource/ibm-plex-sans/600.css'
import '@fontsource/ibm-plex-sans/700.css'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'app/pages/Auth/AuthContext'
import { Toaster } from 'react-hot-toast'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
      <BrowserRouter>
        <Toaster />
        <AuthProvider>
            <App />
        </AuthProvider>
      </BrowserRouter>
  </React.StrictMode>
)
