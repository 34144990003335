import {TextAreaProps} from 'antd/es/input'
import FormLabel from '../Form/FormLabel'
import {SelectWrapper} from '../Form/Select/Selected.styled'
import {StyledTextArea} from './TextArea.Styled'
import {InputReadOnly} from '../Form/Input/Input.Styled'
import Typography from '../Typography'

interface StyledTextAreaProps extends TextAreaProps {
  isDark?: boolean
  label?: React.ReactNode
  id: string
  value?: string
  disabled?: boolean
  readOnly?: boolean
  handleChange?(value: string, key?: string): void
  textAreaProps?: TextAreaProps
}

const TextArea = (props: StyledTextAreaProps) => {
  const {textAreaProps} = props

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value
    props.handleChange && props.handleChange(value, props.id)
  }

  if (props.readOnly) {
    const text = props.value?.toString()
    return (
      <SelectWrapper $isDark={props.isDark}>
        {props.label && <FormLabel id={props.id} label={props.label} />}
        <InputReadOnly title={text} noWrap={true}>
          <Typography.Text ellipsis={{tooltip: text}}>{text}</Typography.Text>
        </InputReadOnly>
      </SelectWrapper>
    )
  }

  return (
    <SelectWrapper $isDark={props.isDark}>
      {props.label && <FormLabel id={props.id} label={props.label} />}
      <StyledTextArea
        id={props.id}
        value={props.value}
        $isDark={props.isDark}
        placeholder={props.placeholder}
        onChange={handleChange}
        disabled={props.disabled}
        {...textAreaProps}
      />
    </SelectWrapper>
  )
}

export default TextArea
