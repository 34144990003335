
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import FormLabel from 'lib/components/Form/FormLabel';




interface RichTextEditorProps {
    value?: string,
    handleChange: (value: string) => void
    label: string
    id: string,
    placeholder?: string
}
const RichTextEditor = ({ value, handleChange, label, id, placeholder }: RichTextEditorProps) => {


    return (
        <div>


            <FormLabel id={id} label={label} />
            <CKEditor
                editor={ClassicEditor}
                data={value}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChange(data)
                }}
                config={{
                    toolbar: ['heading',
                        '|',
                        'bold',
                        'italic',
                        'fontSize',
                        'fontFamily',
                        'fontColor',
                        '|',
                        'link',

                        'bulletedList',
                        'numberedList',
                    ],
                    heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                        ]
                    },
                    fontFamily: {
                        options: [
                            'default',
                            'Ubuntu, Arial, sans-serif',
                            'Ubuntu Mono, Courier New, Courier, monospace'
                        ]
                    },
                    fontColor: {
                        colorPicker: {
                            // Use 'hex' format for output instead of 'hsl'.
                            format: 'hex'
                        }
                    },
                    removePlugins: ['EasyImage', 'CKFinderUploadAdapter', 'CKFinder', 'ImageUpload', 'MediaEmbed'],
                    placeholder: placeholder,

                }}

            />
        </div>
    );
};

export default RichTextEditor;
