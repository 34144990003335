import styled from 'styled-components'
import Colors from 'styles/Colors'

export const ReviewRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 22px 0;
  border-top: 1px solid ${Colors.BORDER2};
  &:first-child {
    padding-top: 0;
    border-top: 0;
  }
`

export const ReviewBody = styled.div``
export const ReviewAction = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RewardTypeHeader = styled.div`
  margin-bottom: 16px;
`
