import {Modal} from 'antd'
import IconClose from 'app/common/icons/IconClose'
import Typography from 'lib/components/Typography'
import RewardPriceHistorySearch from '../../Dashboard/RewardPriceHistorySearch'

interface AccountListModalProps {
  setOpen(): void
  voucherData: RewardsTypes.RewardResponseBL
  // rowClick(row: RewardsTypes.RewardPriceHistoryState): void
}

const PriceHistoryModal = ({setOpen, voucherData}: AccountListModalProps) => {
  return (
    <Modal
      title={<Typography.Title level={4}>All Sales for Reward {voucherData.voucherTypeName}</Typography.Title>}
      centered
      open
      onCancel={setOpen}
      width={1000}
      closeIcon={<IconClose />}
      footer={null}
    >
      <RewardPriceHistorySearch pageSize={10} pageSizeOptions={[10, 50, 100, 200]} showTable={true} />
    </Modal>
  )
}

export default PriceHistoryModal
