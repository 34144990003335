import styled from 'styled-components'
import Colors from 'styles/Colors'

export const SearchWrapper = styled.div`
  background-color: ${Colors.PRIMARY_DARK};
  border-radius: 0;
  padding: 0 16px;
  display: flex;
  width: 100%;
  min-height: 68px;
`
export const SearchForm = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  .search-btn {
    background-color: ${Colors.SECONDARY};
    border: 0;
    color: ${Colors.BLUE_DARK};
  }
`
