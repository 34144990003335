import {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import Typography from 'lib/components/Typography'
import Colors from 'styles/Colors'
import Appconfig from 'app/common/helpers/AppConfig'
import SearchResults from './SearchResults'
import {cloneDeep, isEmpty} from 'lodash'
import SegmentFilter, {ResetSegmentFormRefType} from './SegmentFilter'
import {initialSegmentRequestObj, updateSegmentRequestObj} from '../AddNewSegment/utils'
import IconAdd from 'app/common/icons/IconAdd'
import SegmentationService from 'app/services/SegmentationService'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {SEGMENT_CREATE, SEGMENT_SEARCH, SEGMENT_VIEW} from 'app/common/helpers/UserFunctions'
import NoPermission from 'app/common/components/NoPermission'

const SegmentationDashboard = () => {
  const initialResponse: ResponseTypes.PagedResponse<SegmentationTypes.SegmentResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)

  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const initialSearchParms: SegmentationTypes.SegmentSearchParam = {}
  const [isFetching, setIsFetching] = useState(true)
  const [isFormReset, setIsFormReset] = useState(true)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [segmentsData, setSegmentsData] = useState<ResponseTypes.PagedResponse<SegmentationTypes.SegmentResponseBL[]>>(initialResponse)
  const segmentFormRef = useRef<ResetSegmentFormRefType>(null)
  const navigate = useNavigate()
  const {roles} = useAuth()
  const showSearch = roles.includes(SEGMENT_SEARCH)
  const showTable = roles.includes(SEGMENT_VIEW)
  const showSegmentCreate = roles.includes(SEGMENT_CREATE)

  const handleSearch = (params: SegmentationTypes.SegmentSearchParam) => {
    const reqParam: SegmentationTypes.SegmentSearchParam = {
      // IsCombinable: false,
      ...params,
    }
    setIsFetching(true)
    SegmentationService.search(reqParam)
      .then((res) => {
        const data = res.data
        setSegmentsData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...paginationObj})
    updateSegmentRequestObj(initialSegmentRequestObj)
  }, [])

  const handleResetSearch = () => {
    segmentFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const handleFilter = (data: SegmentationTypes.SegmentFilterState) => {
    const filterParms: SegmentationTypes.SegmentSearchParam = {}
    filterParms.SegmentName = data.segmentName
    filterParms.SegmentCode = data.segmentCode || undefined
    filterParms.SegmentByEnableDisable = data.segmentByEnableDisable || undefined
    filterParms.SegmentCategory = data.segmentCategory
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...filterParms, ...newPaginationObj})
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }
    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...filterSearch, ...newPaginationObj})
  }

  const handleRefresh = (value: boolean, segmentCode: string) => {
    const updatedRewardsData = cloneDeep(segmentsData)
    const updatedRowIndex = segmentsData.data.findIndex((item) => item.segmentCode === segmentCode)
    updatedRewardsData.data[updatedRowIndex] = {
      ...updatedRewardsData.data[updatedRowIndex],
      isEnabled: value,
    }
    setSegmentsData(updatedRewardsData)
  }

  const handleAddNewSegment = () => {
    navigate(`/segments/new`)
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const {count = 0, pageIndex = 0} = segmentsData.pageInfo
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  const renderSegmentBody = () => {
    if ((showSearch && showTable) || showSegmentCreate) {
      return (
        <DataTable>
          {showSearch && showTable && <SegmentFilter ref={segmentFormRef} isFetching={isFetching} handleFilter={handleFilter} />}
          <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
          {showTable && (
            <>
              <SearchResults isFetching={isFetching} segmentsResponse={segmentsData} handleSearch={handleRefresh} />
              <DataTable.Pagination
                onChange={handleOnChange}
                current={pageIndex}
                pageSizeOptions={pageSizeOptions}
                total={count}
                defaultPageSize={initialPaginationObj.PageCount}
                pageSize={paginationObj.PageCount}
                showSizeChanger
              />
            </>
          )}
        </DataTable>
      )
    }
    return <NoPermission />
  }

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Segmentation</Typography.Title>
        {showSegmentCreate && (
          <Button onClick={handleAddNewSegment} type="primary" $startIcon={<IconAdd color={Colors.WHITE} size={20} />}>
            Add New Segment
          </Button>
        )}
      </StyledHeader>
      {renderSegmentBody()}
    </section>
  )
}

export default SegmentationDashboard
