import {RewardTriggerSectionWrapper, TriggerQuestionWrapper} from '../../Reward.Styled'
import Typography from 'lib/components/Typography'
import {useState} from 'react'
import ToggleButton from 'lib/components/ToggleButton'
import {toggleOptions} from '../utils'
import Form from 'lib/components/Form'

import {states} from 'utils/common'

interface RewardRestrictionsProps {
  rewardRestrictionInfo: RewardsTypes.RestrictedDataType
  disabled: boolean
  onRestrictionToggle(id: string, value: boolean): void
  data: RewardsTypes.RestrictionData | null
  onStateChange(id: string, values: string[]): void
  error: boolean
}

const RewardRestrictions: React.FC<RewardRestrictionsProps> = ({
  rewardRestrictionInfo,
  data,
  disabled,
  onRestrictionToggle,
  onStateChange,
  error,
}) => {
  const {id, question} = rewardRestrictionInfo
  let tempRestricted: boolean = false
  let stateList: string[] = []
  if (data) {
    tempRestricted = data.isRestrictionAvaliable
    stateList = data.state || []
  }

  const [restricted, setRestricted] = useState(tempRestricted)
  const [selectedStates, setSelectedStates] = useState<string[]>(stateList)

  const handleOnChange = (value: boolean): void => {
    if (!value) {
      setSelectedStates([])
    }

    setRestricted(value)
    onRestrictionToggle(id, value)
  }

  const handleChange = (value: string[]) => {
    let updatedValues = [...value]
    const stateList = [{value: 'all', label: 'All'}, ...states]
    let actualValues = updatedValues.filter((item) => item !== 'all')
    if (value.includes('all') && value[value.length - 1] === 'all') {
      updatedValues = stateList .map((item) => item.value as string)
    } else if (value.includes('all') && value[0] === 'all') {
      updatedValues = actualValues
    } else if (!value.length || (selectedStates.includes('all') && value.length === selectedStates.length - 1)) {
      updatedValues = []
    } else if (!value.includes('all') && value.length === stateList .length - 1) {
      updatedValues = ['all', ...updatedValues]
    }
    actualValues = updatedValues.filter((item) => item !== 'all')

    setSelectedStates(updatedValues)
    onStateChange(id, actualValues as string[])
  }

  return (
    <>
      <Typography.Title level={3}>State Restriction</Typography.Title>
      <RewardTriggerSectionWrapper>
        <TriggerQuestionWrapper>
          <Typography.Title level={5}>{question}</Typography.Title>
        </TriggerQuestionWrapper>
        <ToggleButton id={id} values={toggleOptions} value={restricted} handleChange={handleOnChange} disabled={disabled} />
        {restricted && (
          <div style={{width: '40%', marginTop: '32px'}}>
            <Form.Select
              mode="multiple"
              value={selectedStates}
              id={`restrictedByState`}
              placeholder="Select Values"
              onChange={handleChange}
              options={[{value: 'all', label: 'All'}, ...states]}
              filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
              status={error ? 'error' : undefined}
              disabled={disabled}
            />
          </div>
        )}
      </RewardTriggerSectionWrapper>
    </>
  )
}

export default RewardRestrictions
