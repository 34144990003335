import {Select} from 'antd'
import styled from 'styled-components'
import Colors from 'styles/Colors'

export const AdjustPointsContainer = styled.div`
  border-top: 1px solid ${Colors.WHITE};
  padding-top: 6px;
`

export const AdjustPointsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PointsInput = styled.input`
  border: 0;
  height: 32px;
  width: 100%;
  min-width: 80px;
  text-align: right;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0;
  font-family: ${(props) => props.theme.typography.h2.fontFamily};
  font-size: ${(props) => props.theme.typography.h2.fontSize};
  font-weight: ${(props) => props.theme.typography.h2.fontWeight};
  line-height: ${(props) => props.theme.typography.h2.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.h2.letterSpacing};
  color: inherit;
  &:foucs {
    outline: none;
    border: 0;
  }
`

export const FixedPointsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 7px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ffffff;
`
export const FixedPointsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-bottom: 16px;
  > button {
    flex: 1;
  }
  > button + button {
    margin-left: 5px;
  }
`

export const BadgeButton = styled.button`
  background-color: ${Colors.PRIMARY_DARK};
  border-radius: 27px;
  height: 28px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.002em;
  color: #ffffff;
  border: 0;
  padding: 4px 10px;
  cursor: pointer;
  outline: none;
  border: 0;
  &:hover {
    background-color: ${Colors.PRIMARY_DARK};
  }
`

export const AdjustPointsForm = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledSelect = styled(Select)`
  height: 40px;
`
